import { useIntl } from "react-intl"
import { getDefaultQuestion } from 'utils/survey'
import fixedWidthString from 'fixed-width-string'
import { makeStyles } from "@material-ui/core/styles"
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Props as CopyProps } from './Copy'

interface Props {
    pages: CopyProps['propsFetchQuestionInPage']['pages'];
    questions: CopyProps['propsFetchQuestionInPage']['questions'];
    survey_page_no: CopyProps['propsFetchQuestionInPage']['val']['survey_page_no'];
    survey_question_no: CopyProps['propsFetchQuestionInPage']['val']['survey_question_no'];
    position?: CopyProps['propsFetchQuestionInPage']['val']['position'];
    onChangePage: CopyProps['onChangePage'];
    onChangeQuestion: CopyProps['onChangeQuestion'];
    onChangePosition: CopyProps['onChangePosition'];
}

export const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    form: {
        width: '100%',
        minWidth: 150
    },
    formQuestion: {
        marginTop: 20,
        width: '100%',
        minWidth: 150
    },
    formMove: {
        marginTop: 20,
        width: '100%',
        minWidth: 150
    }
}))

function CopyItemComponent(props: Props) {
    const { pages, questions, survey_page_no, survey_question_no, position=1, onChangePage, onChangeQuestion, onChangePosition } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    // 해당페이지 문항리스트 개수
    const length = questions.length

    if (pages.length === 0) return null

    return (
        <>
        <FormControl className={classes.form}>
            <InputLabel>{f({id: 'component.Survey.Create.Module.Copy.label.0'})}</InputLabel>
            <Select value={survey_page_no} onChange={onChangePage}>
            { 
                pages.map(c => <MenuItem key={c.survey_page_no} value={c.survey_page_no}>{c.indexs}</MenuItem>)
            }
            </Select>
        </FormControl>    
        <FormControl  className={classes.formQuestion}>
            <InputLabel>{f({id: 'component.Survey.Create.Module.Copy.label.1'})}</InputLabel>
            <Select value={length === 0 || !questions.find(c => c.survey_question_no === survey_question_no) ? '' : survey_question_no} onChange={onChangeQuestion} disabled={length === 0}>
            { 
                questions.map(c => {
                    return(
                        <MenuItem key={c.survey_question_no} value={c.survey_question_no}>
                            {getDefaultQuestion({f, number: c.number, question: fixedWidthString(c.pure_question, 40), question_type: c.question_type})}
                        </MenuItem>
                    )
                    
                })
            }
            </Select>
        </FormControl>
        <FormControl  className={classes.formMove}>
            <InputLabel>{f({id: 'component.Survey.Create.Module.Copy.label.2'})}</InputLabel>
            <Select value={position} onChange={onChangePosition} disabled={length === 0}>
                <MenuItem value={0}>{f({id: 'component.Survey.Create.Module.Copy.position.0'})}</MenuItem>
                <MenuItem value={1}>{f({id: 'component.Survey.Create.Module.Copy.position.1'})}</MenuItem>
            </Select>
        </FormControl>
        </>
    )
}

export default CopyItemComponent