import { useContext } from 'react'
import { SurveyEndingProps } from 'gql/survey_ending'
import PropsContext from 'context/props'
import { MyAvatar } from 'component/Survey/Create/Menu/EndingsItem'

function LabelComponent() {
    const { rows } = useContext<{rows: SurveyEndingProps}>(PropsContext)
    const { indexs } = rows

    return (
        <MyAvatar style={{position: 'absolute', top: -5, left: -5, cursor: 'default', zIndex: 100, borderRadius: 3}}>{indexs}</MyAvatar>
    )
}

export default LabelComponent