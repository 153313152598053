import { useState } from 'react'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'
import oc from 'open-color'
import { JumpNo } from 'gql/jump'
import { OnRemoveRule } from './ItemHeart'

interface Props {
    jump_rule_no: JumpNo;
    onRemove: OnRemoveRule;
}

const MyPopover = withStyles({
    paper: {
        background: oc.gray[0]
    }
})(Popover)

function ButtonCreateRuleComponent(props: Props) {
    const { jump_rule_no, onRemove } = props

    const { formatMessage: f } = useIntl() 

    const [ target, setTarget ] = useState<HTMLElement | null>(null)

    return (
        <>
        <Button 
            style={{
                position: 'absolute',
                top: -5,
                right: 0,
                color: oc.red[9]
            }}
            variant="contained" 
            size="small" 
            disableElevation 
            onClick={(e) => setTarget(e.currentTarget)}
        >
            {f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonRemoveRule.button.remove'})}
        </Button>
        <MyPopover
            open={Boolean(target)}
            anchorEl={target}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            disablePortal={true}
        >
   
            <DialogTitle>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonRemoveRule.dialog.title'})}</DialogTitle>
            <DialogActions>
                <Button color="secondary" onClick={() => onRemove(jump_rule_no)}>
                    {f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonRemoveRule.dialog.button.remove'})}
                </Button>
                <Button onClick={() => setTarget(null)}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonRemoveRule.dialog.button.close'})}</Button>
            </DialogActions>
        </MyPopover>
        </>
    )
}

export default ButtonCreateRuleComponent