import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { UPDATE_SURVEY_MBOX_CUT_PEOPLE_OPTION } from 'gql/survey_mbox'
import { Select, MenuItem, Box, Button, TextField, FormControl, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import { getMaxlength } from 'policy'
import { numberFormat } from 'utils'

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0 10px 0',
        '& > div + div': {
            marginLeft: 15
        }
    }
})(Box)

const MySelect = withStyles({
    root: {
        fontSize: 14
    }
})(Select)


const MyTextField = withStyles({
    root: {
        fontSize: 14
    }
})(TextField)

const maxNumber = getMaxlength('component.Survey.Create.Publish.CutPeople')
const range = `1 ~ ${numberFormat(maxNumber)}`

function CutPeopleComponent(props) {
    const { survey_no, cut_people, cut_people_type, children } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ originalValue, setOriginalValue ] = useState({ cut_people, cut_people_type })
    const [ newValue, setNewValue ] = useState({ cut_people, cut_people_type })

    const [ updateSurveyMboxCutPeopleOption ] = useMutation(UPDATE_SURVEY_MBOX_CUT_PEOPLE_OPTION, {
        onCompleted: async () => {
            const { cut_people, cut_people_type } = newValue
            dispatch({ type: BACKDROPHIDE })
            setOriginalValue({ cut_people, cut_people_type })
        },
        onError: async() => {
            await dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleSave = async() => {
        const { cut_people, cut_people_type } = newValue

        
        if (cut_people <= 0 || cut_people > maxNumber) {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                message: f({id: 'component.Survey.Create.Publish.CutPeople.handleSave.error'}, { range }),
                variant: 'error'
            })
            return
        }

        await dispatch({ type: BACKDROPSHOW })
        updateSurveyMboxCutPeopleOption({
            variables: {
                survey_no,
                cut_people: Number(cut_people),
                cut_people_type
            }
        })
    }

    const handleChangeCutPeople = (e) => {
        const { value } = e.target
        setNewValue(prevState => ({
            ...prevState,
            cut_people: value
        }))
    }

    const handleChangeCutPeopleType = (e) => {
        const { value } = e.target
        setNewValue(prevState => ({
            ...prevState,
            cut_people_type: value
        }))
    }


    // 활성화 해서 고친다음에 비활성화하고 다시 활성화하면 초기값으로 준다
    // 비활성화시 초기값으로 자동 저장되기때뭄이다 (cut_people: 1000, cut_people_type: connect)
    useEffect(() => {
        setOriginalValue({ cut_people, cut_people_type })
        setNewValue({ cut_people, cut_people_type })
    }, [cut_people, cut_people_type])

    return (
        <>
            <MyBox>
            <div>
                <FormControl>
                    <MySelect value={newValue.cut_people_type} onChange={handleChangeCutPeopleType}>
                        <MenuItem value="connect">{f({id: 'component.Survey.Create.Publish.CutPeople.label.connect'})}</MenuItem>
                        <MenuItem value="start">{f({id: 'component.Survey.Create.Publish.CutPeople.label.start'})}</MenuItem>
                        <MenuItem value="completed">{f({id: 'component.Survey.Create.Publish.CutPeople.label.completed'})}</MenuItem>
                    </MySelect>
                    <FormHelperText>{f({id: 'component.Survey.Create.Publish.CutPeople.FormHelperText'})}</FormHelperText>
                </FormControl>
            </div>
            <div>
                <MyTextField 
                    type="number" 
                    value={newValue.cut_people}
                    fullWidth
                    helperText={f({id: 'component.Survey.Create.Publish.CutPeople.helperText'}, { range })}
                    onChange={handleChangeCutPeople}
                    inputProps={{
                        style: { fontSize: 14 }
                    }}
                />
            </div>
            <div>
                <Button variant="contained" size="small" disabled={isEqual(originalValue, newValue)} onClick={handleSave}>
                    {f({id: 'component.Survey.Create.Publish.CutPeople.button.save'})}
                </Button>
            </div>
            </MyBox>
            {children}
        </>
    )
}

export default CutPeopleComponent