import { memo, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { HIDE } from 'reducer/survey/create/menu/ending/menu'
import Menu from '@material-ui/core/Menu'
import Divder from '@material-ui/core/Divider'
import EndingsItemLabelComponent from './EndingsItemLabel'
import EndingsCopyComponent from './EndingsCopy'
import EndingsRemoveComponent from './EndingsRemove'

interface Props {
    survey_no: number;
    survey_ending_nos: number[];
    refetchEndings: () => void;
}

export type OnClose = () => void

function EndingsMoreComponent(props: Props) {
    const { survey_no, survey_ending_nos, refetchEndings } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const menu = useSelector((state: RootState) => state.surveyCreateMenuEndingMenu)
    const { survey_ending_no, label, anchorEl } = menu

 

    // label box view
    const [ labelBox, setLabelBox ] = useState(false)

    const handleClose = useCallback(() => {
        dispatch({ type: HIDE })
        setTimeout(() => setLabelBox(false), 200)
    }, [dispatch])


    useEffect(() => {
        return () => {
            dispatch({ type: HIDE })
        }
    }, [dispatch])

    return (
        <Menu
            id={`id-${survey_ending_no}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {
                !labelBox ? (
                    <span>
                        {
                            /** 라벨변경 미사용
                             *  <MenuItem onClick={() => setLabelBox(true)}>
                                    <Typography variant="body1">
                                        {f({id: 'component.Survey.Create.Menu.EndingsMenu.label'})}
                                    </Typography>
                                </MenuItem>
                             * 
                             */
                        }
                        <EndingsCopyComponent 
                            survey_no={survey_no} 
                            survey_ending_no={survey_ending_no} 
                            survey_ending_nos={survey_ending_nos}
                            onClose={handleClose}
                            refetch={refetchEndings}
                        />
                        {
                            (survey_ending_nos.length > 1) && (
                                <>
                                <Divder/>
                                <EndingsRemoveComponent
                                      survey_no={survey_no} 
                                      survey_ending_no={survey_ending_no} 
                                      onClose={handleClose}
                                      refetch={refetchEndings}
                                />
                                </>
                            )
                        }
                    </span>
                ) : (
                    <span>
                        <EndingsItemLabelComponent 
                            survey_ending_no={survey_ending_no} 
                            label={label} 
                            onClose={handleClose} 
                            refetchEndings={refetchEndings}
                        />
                    </span>
      
                )
            }
        </Menu>
    )
   
}

export default memo(EndingsMoreComponent)