import { useParams } from 'react-router-dom'
import DrawerOuter from 'styled/DrawerOuter'

const Component = () => {
    
    const params = useParams()

    const survey_no = Number(params.survey_no)

    return (
        <DrawerOuter kind="share" src={`/survey/create/share/frame/${survey_no}`}/>
    )
}

export default Component