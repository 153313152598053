import { memo } from 'react'
import { ModuleImg } from 'gql/survey_question_modules'
import { Props as BaseProps } from "./QuestionItemsBase"
import ItemComponent from "./Item"

type Props = Omit<BaseProps, 'componentType'>

function QuestionItemsImg(props: Props) {
    const { question, rows, onChange } = props
    const { _modules } = question

    return (
        <>
        {
            _modules.map((_module: ModuleImg) => {
                const { survey_module_no, answer, indexs, src } = _module
                const title = `${indexs}. ${answer}`

                const row = rows.find(row => row.survey_module_no === survey_module_no)

                if (!row) return null

                return <ItemComponent key={survey_module_no} answer={title} src={src} row={row} onChange={onChange}/>
            })
        }
        </>
        
    )
}

export default memo(QuestionItemsImg)