import { memo, useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { withSurveyFetchData } from 'hoc'
import { CREATE_SURVEY_ENDING, SurveyEndingProps } from 'gql/survey_ending'
import { ListSubheader, ListSubheaderBox } from './Items'
import EndingsItemComponent from './EndingsItem'
import EndingsItemRedirectComponent from './EndingsItemRedirect'
import EndingsMenuComponent from './EndingsMenu'
import QuizKindSelectComponent from './QuizKindSelect'
import { defaultColor } from './Items'
import EndingsDropComponent from './EndingsDrop';


export interface Props {
    is_headerbar: boolean;
    onChangeCategory: (key: string, value: object) => void;
    propsFetchData: {
        endings: SurveyEndingProps[];
        refetchEndings: () => void;
    }
}

export type OnChangeSort = (rows: Props['propsFetchData']['endings']) => void

const screenHeight = window.screen.height * .5

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 100,
        maxHeight: '45%', 
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: `calc(100vh - ${screenHeight}px)`
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: `calc(100vh - ${screenHeight}px)`
        },
        '&.scroll::-webkit-scrollbar': {
            width: 5
        },
        '&.scroll::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.dark,
            outline: 0
        }
    }
}))




const MyBox = withStyles(theme => ({
    root: () => {

        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: defaultColor.color,
            background: defaultColor.backgroundColor,
            width: 25,
            height: 30,
            borderRadius: 3,
            marginLeft: 5,
            transition: 'all .3s',
            cursor: 'pointer',
            '&:hover': {
                background: defaultColor.backgroundColorHover
            }
        }
      
    }
}))(Box)

function EndingsComponent(props: Props) {
    const dispath = useDispatch()
    const { formatMessage: f } = useIntl()
    const classes = useStyles()

    const refList = useRef<HTMLUListElement | null>(null)

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const { is_headerbar, onChangeCategory, propsFetchData } = props
    const { endings, refetchEndings } = propsFetchData

    const [ rows, setRows ] = useState(endings)

    const [ createSurveyEnding ] = useMutation(CREATE_SURVEY_ENDING, {
        onCompleted: () => {
            dispath({ type: BACKDROPHIDE })
            refetchEndings()
            setTimeout(() => {
                if (refList.current) {
                    refList.current.scrollTo({ top: 10000000, behavior: 'smooth' })
                }
            }, 200)
        },
        onError: () => {
            dispath({ type: BACKDROPHIDE })
            dispath({ type: ERRORPAGESHOW })
        }
    })

    function hanldeCreate() {
        dispath({ type: BACKDROPSHOW })
        createSurveyEnding({
            variables: {
                survey_no
            }
        })
    }

    const handleChangeSort: OnChangeSort = useCallback((data) => {
        setRows(data)
    }, [])

    const survey_ending_nos = rows.map(row => row.survey_ending_no)
    const len = survey_ending_nos.length

    useEffect(() => {
        setRows(endings)
    }, [endings])

    return (
        <>
        <List 
            ref={refList}
            dense 
            subheader={
                <ListSubheader is_headerbar={is_headerbar}>
                    <ListSubheaderBox>
                        <div>{f({id: 'component.Survey.Create.Menu.Endings.subheader.label'})}</div>
                        <div>
                            {
                                len > 1 && <QuizKindSelectComponent/>
                            }
                            <MyBox>
                                <AddIcon color='action' onClick={hanldeCreate} style={{ width: 18 }}/>
                            </MyBox>
                        </div>
                    </ListSubheaderBox>
                </ListSubheader>
            }
            className={`${classes.root} scroll`}
        >
            <EndingsDropComponent survey_no={survey_no} rows={rows} onChange={handleChangeSort} refetch={refetchEndings}>
            {
                rows.map((row, i) => {
                    const { survey_ending_no } = row

                    return (
                        <EndingsItemComponent key={survey_ending_no} survey_no={survey_no} i={i} len={len} row={row} onChangeContent={onChangeCategory}/>
                    )
                })
            }
            </EndingsDropComponent>
        </List>
        <EndingsItemRedirectComponent refetch={refetchEndings}/>
        <EndingsMenuComponent survey_no={survey_no} survey_ending_nos={survey_ending_nos} refetchEndings={refetchEndings}/>
        </>
    )
}

export default withSurveyFetchData('edit')({ endings: true })(false)(memo(EndingsComponent))