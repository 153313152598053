/**
 * 보기 리스트
 */

import { memo } from 'react'
import { useIntl } from 'react-intl' 
import Paper from '@material-ui/core/Paper'
import { Props as ParentProps } from './Component'
import { useStyles } from '../SelectBoxOptions'
import SelectBoxOptionComponent from '../SelectBoxOption'

interface Props extends ParentProps {
    open: boolean;
}

function ChoiceSelectBoxOptionsComponent(props: Props) {
    const { keyValue, choices, open, value, onChange } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl() 

    if (!open) return null

    // 선택 되지 않는 보기 리스트
    const newChoices = choices.filter(c => !value.includes(c.id))
    const len = newChoices.length

    if (len === 0) {
        return (
            <Paper className={classes.root} elevation={5}>
                <SelectBoxOptionComponent secondary={f({id: 'component.Survey.Create.Analysis.Filter.Item01.ChoiceSelectBoxOptions.secondary'})} indexs={0} onChange={() => {}} />
            </Paper>
        )
    }

    return (
        <Paper className={classes.root} elevation={5}>
        {
            newChoices.map((c, i) => {
                const { id, text, src } = c
                return <SelectBoxOptionComponent key={id} primary={text} src={src} indexs={i} onChange={() => onChange(keyValue, id)} />
            })
        }
        </Paper>
    )
}

export default memo(ChoiceSelectBoxOptionsComponent)