import { useState, useCallback, memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import styled from 'styled-components'
import { parseHtmlIntl } from 'ts-utils'
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider, ListSubheader as MuiListSubheader, Collapse, Typography } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { 
    ArrowLeft as ArrowLeftIcon,
    ArrowRight as ArrowRightIcon, 
    ArrowDropUp as ArrowDropUpIcon,
    ArrowDropDown as ArrowDropDownIcon, 
    HelpTwoTone as HelpTwoToneIcon,
    HelpOutline as HelpIcon,
    Add as AddIcon
} from '@material-ui/icons'
import Tooltip from 'styled/Custom/Tooltip'
import { withSurveyModuleLists } from 'hoc'
import BrandComponent from './Brand'
import StartComponent from './Start'
import EndingsComponent from './Endings'
import ModuleListsComponent from './ModueLists'
import ClassicNextButtonComponent from './ClassicNextButton'
import { SurveyCreatePagesRemoveComponent } from 'component'

// 메뉴 내 기본 색상
export const defaultColor = {
    color: '#585858',
    backgroundColor: 'rgba(180, 180, 180, .2)',
    backgroundColorHover: 'rgba(180, 180, 180, .4)',
    iconColor: '#ACB5BD',
    iconColorSelected: '#7886CB'
}

const useStyles = makeStyles(theme => ({
    modules: {
        maxHeight: '70%', 
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 360px)'
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 390px)'
        }
    },
    divider: {
        marginBottom: 5
    },
    dividerHeaderBar: props => {
        const { isHeaderBar } = props

        return isHeaderBar ? { display: 'none'} : {}
    }
}))

export const ListSubheader = withStyles(theme => ({
    root: props => {
        const { is_headerbar } = props

        let styles = {
            background: oc.gray[2],
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 7
            }
        }

        if (is_headerbar === 1) {
            styles = {
                ...styles,
                backgroundColor: `${oc.gray[2]} !important`
            }
        }

        return {
            ...styles,
            '& > div:first-child': {
                fontWeight: 700
            }
        }
    }
}))(props => <MuiListSubheader {...props}/>)

export const ListSubheaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    & svg {
        display: block;
    }
    & > div:last-child {
        display: flex;
        align-items: center;
    }
`

const Items = (props) => {
    const { 
        method, 
        isHeaderBar, 
        moduleLists, 
        selectedPageLists, 
        selectedPageMove,
        totalPage,
        pageIndex, 
        survey_page_no,
        firstPageNo,
        onCreateModules,
        onCreatePage,
        onOpenPageLists, 
        onOpenPageMove,
        onChangeCategory,
        refetchQuestions,
        refetchPages,
    } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles({ isHeaderBar })

    const is_headerbar = isHeaderBar ? 1 : 0 

    const [ removePage, setRemovePage ] = useState(false)
    
    const handleRemovePageToggle = useCallback(() => {
        setRemovePage(prevState => !prevState)
    }, [])

    const handleRemovePageClose = useCallback(() => {
        setRemovePage(false)
    }, [])
    
    return (
        <>
            <Divider light className={classes.dividerHeaderBar}/>
            <BrandComponent is_headerbar={is_headerbar} onChangeCategory={onChangeCategory}/>
            <Divider light className={classes.dividerHeaderBar}/>
            <StartComponent is_headerbar={is_headerbar} onChangeCategory={onChangeCategory}/>
            <Divider light className={`${classes.divider} ${classes.dividerHeaderBar}`}/>
            <ModuleListsComponent moduleLists={moduleLists} ListSubheader={ListSubheader} onCreateModules={onCreateModules}/>
            <Divider light className={`${classes.divider} ${classes.dividerHeaderBar}`}/>
            <EndingsComponent is_headerbar={is_headerbar} onChangeCategory={onChangeCategory}/>
            <Divider light className={`${classes.divider} ${classes.dividerHeaderBar}`}/>
            <List 
                dense 
                subheader={
                    <ListSubheader is_headerbar={is_headerbar}>
                        <ListSubheaderBox>
                            <div>{f({id: 'component.Survey.Create.Menu.Component.subheader.page'})}</div>
                            <div>
                                <Tooltip 
                                    title={f({id: `component.Survey.Create.Menu.Component.subheader.pageExplain.${method}`})} 
                                    isMobileClick={true} 
                                    delayCloseTime={5000}
                                >
                                    <HelpTwoToneIcon color="action" fontSize="small"/>
                                </Tooltip>
                            </div>
                        </ListSubheaderBox>
                    </ListSubheader>
                }
            >
                <ListItem button onClick={onOpenPageLists} selected={selectedPageLists}>
                    <ListItemText primary={parseHtmlIntl(f({id: 'component.Survey.Create.Menu.Component.page.current'}, {page: pageIndex, totalPage }))} />
                    <ListItemSecondaryAction>
                        {
                            selectedPageLists ? <ArrowRightIcon color="action"/> : <ArrowLeftIcon color="action"/>
                        }
                    </ListItemSecondaryAction>
                </ListItem>
                <Tooltip placement="top" title={method === 'one' ? f({id: `component.Survey.Create.Menu.Component.page.createTooltip`}) : ''}>
                    <ListItem button onClick={onCreatePage}>
                        <ListItemText primary={f({id: 'component.Survey.Create.Menu.Component.page.create'})}/>
                        <ListItemSecondaryAction>
                            <AddIcon color="action" fontSize='small'/>
                        </ListItemSecondaryAction>
                    </ListItem>
                 </Tooltip>
                <ListItem button onClick={onOpenPageMove} selected={selectedPageMove}>
                    <ListItemText primary={f({id: 'component.Survey.Create.Menu.Component.page.move'})} />
                    <ListItemSecondaryAction>
                        {
                            selectedPageMove ? <ArrowRightIcon color="action"/> : <ArrowLeftIcon color="action"/>
                        }
                    </ListItemSecondaryAction>
                </ListItem>
                {
                    pageIndex > 1 && (
                        <>
                        <ListItem button onClick={handleRemovePageToggle} selected={removePage}>
                            <ListItemText primary={f({id: 'component.Survey.Create.Menu.Component.page.remove'})} style={{color: oc.pink[7]}} />
                            <ListItemSecondaryAction>
                                {
                                    removePage ? <ArrowDropUpIcon color="action"/> : <ArrowDropDownIcon color="action"/>
                                }
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={removePage} timeout="auto">
                            <SurveyCreatePagesRemoveComponent 
                                index={pageIndex} 
                                survey_page_no={survey_page_no} 
                                firstPageNo={firstPageNo}
                                onClose={handleRemovePageClose}
                                refetchQuestions={refetchQuestions}
                                refetchPages={refetchPages}
                            />
                        </Collapse>
                        </>
                    )
                }
                {
                    method === 'multi' && (
                        <>
                        <ListItem style={{paddingBottom: 0}}>
                            <ListItemText primary={f({id: 'component.Survey.Create.Menu.Items.classicButton.primary'})}/>
                            <ListItemSecondaryAction>
                                <Tooltip 
                                    title={f({id: 'component.Survey.Create.Menu.Items.classicButton.tooltip'})}
                                    isMobileClick={true} 
                                    delayCloseTime={5000}
                                >
                                    <HelpIcon color="action" fontSize="small"/>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ClassicNextButtonComponent survey_page_no={survey_page_no} />
                        </ListItem>
                        </>
                    )
                }
            </List>
        </>
    )
}

export default withSurveyModuleLists('side')(memo(Items))


