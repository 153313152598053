import { useContext } from 'react'
import oc from 'open-color'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { Kind, ID, Method, JumpNo } from 'gql/jump'
import { QuestionType, ModuleType } from 'gql/survey_question_modules'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { OnChangeElseMethod, OnChangeElseSurveyQuestionNos } from './ItemHeart'
import { MyPaper, MyBox, MyTypography } from './ItemRules'
import { Refference } from './SelectActionQuestion'
import SelectMethodComponent from './SelectMethod'
import SelectSplitActionComponent from './SelectSplitAction'
import ItemFocusComponent from './ItemFocus'

interface Props {
    kind: Kind;
    id: ID;
    jump_no: JumpNo;
    method: Method;
    survey_question_nos: number[];
    rulesLength: number;
    onChangeElseMethod: OnChangeElseMethod;
    onChangeElseSurveyQuestionNos: OnChangeElseSurveyQuestionNos;
}

// 규칙 추가 활성화 상태값
export function isAddRule(question_type: QuestionType, module_type: ModuleType) {
    return (question_type === QuestionType.Question && module_type !== '_19')
}

function ItemDefaultComponent(props: Props) {
    const { kind, id, jump_no, method, survey_question_nos, rulesLength, onChangeElseMethod, onChangeElseSurveyQuestionNos } = props

    const { questionsKeyQuestionNo } = useContext(Context)

    let isExplain = false

    if (kind === Kind.Question) {
        if (questionsKeyQuestionNo) {
            const question = questionsKeyQuestionNo[id]
            if (question) {
                const [{_question}] = question
                const { question_type, module_type } = _question
                if (!isAddRule(question_type, module_type)) isExplain = true
            }
        }
    }

    return (
        <>
        {
            !isExplain && <Divider style={{marginTop: 15, marginBottom: 10, background: oc.gray[6]}}/>
        }
        <MyPaper>
            <ItemFocusComponent section="default" no={jump_no}/>
            <ListItem>
                <MyBox>
                    <div>
                        <MyTypography variant="body2"  className="conditionText">{rulesLength === 0 ? 'DEFAULT' : 'ELSE'}</MyTypography>
                        <SelectMethodComponent method={method} onChange={onChangeElseMethod}/>
                        <SelectSplitActionComponent
                            refference={Refference.Else}
                            id={id}
                            kind={kind} 
                            method={method}
                            survey_question_nos={survey_question_nos} 
                            onChange={onChangeElseSurveyQuestionNos}
                        />
                    </div>
                    <div>
                        <Button></Button>
                    </div>
                </MyBox>
            </ListItem>
        </MyPaper>
        </>
    )
}

export default ItemDefaultComponent