import { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { HelpButton } from '../Lists/ButtonHelp'
import DialogHelpComponent from '../Point/DialogHelp'

function HelpComponent() {
    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const title = f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.Help.title'}) 
    const items = [
        { primary: f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.Help.item.primary.0'}), secondary: ''},
        { primary: f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.Help.item.primary.1'}), secondary: f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.Help.item.secondary.1'})},
        { primary: f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.Help.item.primary.2'}), secondary: ''}
    ]

    return (
        <>
        <HelpButton onClick={handleOpen}/>
        <DialogHelpComponent title={title} items={items} open={open} onClose={handleClose}/>
        </>
    )
}

export default HelpComponent