import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { FacebookIcon, LineIcon, TwitterIcon, LinkedinIcon } from 'react-share'
import kakaotalkIcon from 'static/images/icon/kakaolink.png'
import styled from 'styled-components'
import oc from 'open-color'
import clipboard from 'copy-to-clipboard'
import { postMessage } from 'utils'
import { DialogContent, DialogTitle, DialogActions, Typography, Button, Grid, Divider } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    icon: {
        width: 40,
        height: 40,
        borderRadius: 3
    },
    subtitle: {
        fontSize: 15
    }
}))

const GridContainer = withStyles({
    root: {
        marginTop: 15
    }
})(props => <Grid container alignItems="center" {...props}/>)


const GridItemIcon = withStyles({

})(props => <Grid item xs={12} sm={1} {...props}/>)

const GridItemText = withStyles({

})(props => <Grid item xs={12} sm={11} {...props}/>)


const OlStyled = styled.ol`
    color: ${oc.gray[6]};
    font-size: 15px;
    word-break: break-all;
    line-height: 165%;
    padding-left: 32px;

    @media only screen and (max-width: 600px) {
        padding-left: 16px;
    };
` 

const Ustyled = styled.u`
    cursor: pointer;
`

const urls = {
    facebook: 'https://developers.facebook.com/tools/debug',
    linkedin: 'https://www.linkedin.com/post-inspector/inspect',
    twitter: 'https://cards-dev.twitter.com/validator',
    line: 'http://poker.line.naver.jp',
    kakao: 'https://developers.kakao.com/tool/clear/og'
}

function SnsCachePopupComponent(props) {
    const { onClose } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const handleClipBoard = useCallback((url) => {
        clipboard(url)
        postMessage({ 
            dispatch: { type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Share.SnsCachePopup.clipboard'}), duration: 1000 } 
        })
    }, [f])

    return (
        <>
            <DialogTitle>{f({id: 'component.Survey.Create.Share.SnsCachePopup.title'})}</DialogTitle>
            <DialogContent>
                <Typography component="div" className={classes.subtitle}>
                    {f({id: 'component.Survey.Create.Share.SnsCachePopup.subtitle'})}
                </Typography>
            </DialogContent>
            <Divider style={{marginTop: 15}}/>
            <DialogContent>
                <GridContainer>
                    <GridItemIcon><FacebookIcon className={classes.icon}/></GridItemIcon>
                    <GridItemText>
                        <OlStyled>
                            <li>
                                {f({id: 'component.Survey.Create.Share.SnsCachePopup.url'}, { 
                                    url: <Ustyled onClick={() => handleClipBoard(urls.facebook)}>{urls.facebook}</Ustyled>
                                })}
                            </li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.facebook.0'})}</li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.facebook.1'})}</li>
                        </OlStyled>
                    </GridItemText>
                </GridContainer>
                <GridContainer>
                    <GridItemIcon><LinkedinIcon className={classes.icon}/></GridItemIcon>
                    <GridItemText>
                        <OlStyled>
                            <li>
                                {f({id: 'component.Survey.Create.Share.SnsCachePopup.url'}, { 
                                    url: <Ustyled onClick={() => handleClipBoard(urls.linkedin)}>{urls.linkedin}</Ustyled>
                                })}
                            </li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.linkedin.0'})}</li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.linkedin.1'})}</li>
                        </OlStyled>
                    </GridItemText>
                </GridContainer>
                <GridContainer>
                    <GridItemIcon><TwitterIcon className={classes.icon}/></GridItemIcon>
                    <GridItemText>
                        <OlStyled>
                            <li>
                                {f({id: 'component.Survey.Create.Share.SnsCachePopup.url'}, { 
                                    url: <Ustyled onClick={() => handleClipBoard(urls.twitter)}>{urls.twitter}</Ustyled>
                                })}
                            </li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.twitter.0'})}</li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.twitter.1'})}</li>
                        </OlStyled>
                    </GridItemText>
                </GridContainer>
                <GridContainer>
                    <GridItemIcon><LineIcon className={classes.icon}/></GridItemIcon>
                    <GridItemText>
                        <OlStyled>
                            <li>
                                {f({id: 'component.Survey.Create.Share.SnsCachePopup.url'}, { 
                                    url: <Ustyled onClick={() => handleClipBoard(urls.line)}>{urls.line}</Ustyled>
                                })}
                            </li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.line.0'})}</li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.line.1'})}</li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.line.2'})}</li>
                        </OlStyled>
                    </GridItemText>
                </GridContainer>
                <GridContainer>
                    <GridItemIcon><img src={kakaotalkIcon} className={classes.icon} alt="kakao"/></GridItemIcon>
                    <GridItemText>
                        <OlStyled>
                            <li>
                                {f({id: 'component.Survey.Create.Share.SnsCachePopup.url'}, { 
                                    url: <Ustyled onClick={() => handleClipBoard(urls.kakao)}>{urls.kakao}</Ustyled>
                                })}
                            </li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.kakao.0'})}</li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.kakao.1'})}</li>
                            <li>{f({id: 'component.Survey.Create.Share.SnsCachePopup.kakao.2'})}</li>
                        </OlStyled>
                    </GridItemText>
                </GridContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <Typography variant="button">
                        {f({id: 'component.Survey.Create.Share.SnsCachePopup.close'})}
                    </Typography>
                </Button>
            </DialogActions>
        </>
    )
}

export default SnsCachePopupComponent
