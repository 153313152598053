import { memo, useState, MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { SurveyEndingProps } from 'gql/survey_ending'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { OnChange, Item } from './EndingsItem'
import { defaultColor } from './Items'

interface Props {
    item: Item;
    kind: SurveyEndingProps['kind'];
    onChange: OnChange;
}

const MyButton = withStyles(theme => ({
    root: {
        color: defaultColor.color,
        fontSize: 11, 
        backgroundColor: defaultColor.backgroundColor,
        width: 64,
        paddingTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
        borderRadius: 3,
        cursor: 'pointer',
        transition: 'all .3s',
        '&:hover': {
            backgroundColor: defaultColor.backgroundColorHover
        }
    }
}))(Box)

function EndingsItemKindComponent(props: Props) {
    const { item, kind, onChange } = props

    const { formatMessage: f } = useIntl()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    // menuItem click
    const handleChange = (event: MouseEvent<HTMLElement>, newKind:SurveyEndingProps['kind']) => {
        event.stopPropagation()
        setAnchorEl(null)
        onChange(newKind)
    }

    return (
        <>
        <MyButton onClick={handleClick}>
            {f({id: 'component.Survey.Create.Menu.EndingsItemKind.changeType'})}
        </MyButton>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem selected={kind === 'content'} onClick={(e) => handleChange(e, 'content')}>{item.content}</MenuItem>
            <MenuItem selected={kind === 'redirect'} onClick={(e) => handleChange(e, 'redirect')}>{item.redirect}</MenuItem>
       </Menu>
       </>
    )
}

export default memo(EndingsItemKindComponent)