import { useState, useEffect, useCallback } from 'react'
import { Alert } from '@material-ui/lab'
import { Typography, Collapse } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useIntl }  from 'react-intl'

const MyAlert = withStyles({
    root: {
        borderRadius: 0
    }
})((props) => <Alert severity="info" elevation={0}  {...props}/>) 

const Component = (props) => {
    const { method } = props

    const [ open, setOpen ] = useState(false)

    const changeOpen = useCallback((_open) => {
        setOpen(_open)
      
    }, [])
    
    const { formatMessage: f } = useIntl()

    useEffect(() => {
        // 한번에 한개씩 조사형식이면서, one-caution 값이 없을경우 보여준다
        if (method === 'one' && !localStorage.getItem('one-caution')) changeOpen(true)
        else changeOpen(false)
    }, [method, changeOpen])
        
    return (
        <Collapse in={open} style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <MyAlert onClose={() => {
                changeOpen(false)
                localStorage.setItem('one-caution', 1)
            }}>
                <Typography variant="body2">
                    {f({id: 'component.Survey.Create.Module.Caution.content'})}
                </Typography>
            </MyAlert>
        </Collapse>
    )
}

export default Component