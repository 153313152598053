import { useIntl } from 'react-intl'
import oc from 'open-color'
import { getMaxlength } from 'policy'
import { Box, TextField, Typography, IconButton, Paper } from '@material-ui/core'
import { withStyles, makeStyles, styled as styleds } from '@material-ui/core/styles'
import { Add as AddIcon, DeleteForever as DeleteForeverIcon, HelpOutline as HelpOutlineIcon } from '@material-ui/icons'
import OptionStyled from 'styled/Survey/Edit/Option'
import FontFamilys from 'styled/Survey/FontFamilys'
import styled from 'styled-components'
import Tooltip from 'styled/Custom/Tooltip'
import BackgroundSizeComponent from './BackgroundSize'
import BackgroundOpacityComponent from './BackgroundOpacity'
import FilterComponent, { getKind } from './Filter/Component'

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(1)
    },
    titleBox: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))

const PaperStyled = withStyles((theme) => ({
    root: {
        width: 25,
        height: 25,
        cursor: 'pointer'
    }
}))(Paper)


const MyImage = styleds(Paper)(props => {
    const { background_image } = props

    return {
        width: 50,
        height: 50,
        background: `url(${background_image}) no-repeat`
    }
})

const BoxStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`

const BoxSubStyled = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;

    & > div:first-child {
        align-self: center;
    }

    & > div:last-child {
        flex-grow: 1;
        & > div {
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            border-bottom: 1px solid ${oc.gray[3]};
            & > div:first-child {
                width: 75px;
            }
            & > div:last-child {
                flex-grow: 1;
            }
        }

        & > div + div {
            margin-top: 8px;
        }
    }
`

export default (props) => {
    const { refTitle, design, on } = props
    const { 
        title, 
        question, 
        answer, 
        answer_button, 
        answer_sbutton, 
        background_color, 
        line_color, 
        background_image, 
        background_size, 
        background_opacity, 
        background_filter, 
        font_family,
        numbering
    } = design

    const classes = useStyles()

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
             <div className='main customDesign'>
                <div>
                    <Box className={classes.titleBox}>
                        <TextField 
                            placeholder={f({id: 'component.Survey.Create.Design.Custom.Option.title'})} 
                            inputRef={refTitle} 
                            defaultValue={title} 
                            variant="outlined" 
                            size="small" 
                            margin="dense"
                            inputProps={{maxLength: getMaxlength('component.Survey.Create.Design.Custom.Option.title')}}
                        />
                        <Tooltip isMobileClick={true} title={f({id: 'component.Survey.Create.Design.Custom.Option.saveHelp'})}>
                            <HelpOutlineIcon  fontSize="small" color="action"/>
                        </Tooltip>
                    </Box>
                </div>
            </div>
            <div className='line'></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.font'})}</Typography>
                        <FontFamilys value={font_family} onChange={on.changeFontFamily}/>
                    </BoxStyled>
                </div>
            </div>
            <div className='line'></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.numbering'})}</Typography>
                        <PaperStyled name="numbering" style={{background: `rgba(${numbering})`}} onClick={(e) => on.openColorPicker(e)}></PaperStyled>
                    </BoxStyled>
                </div>
            </div>
            <div className='line'></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.question'})}</Typography>
                        <PaperStyled name="question" style={{background: `rgba(${question})`}} onClick={(e) => on.openColorPicker(e)}></PaperStyled>
                    </BoxStyled>
                </div>
            </div>
            <div className='line'></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.answer'})}</Typography>
                        <PaperStyled name="answer" style={{background: `rgba(${answer})`}} onClick={(e) => on.openColorPicker(e)}></PaperStyled>
                    </BoxStyled>
                </div>
            </div>
            <div className='line'></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.answer_button'})}</Typography>
                        <PaperStyled name="answer_button" style={{background: `rgba(${answer_button})`}} onClick={(e) => on.openColorPicker(e)}></PaperStyled>
                    </BoxStyled>
                </div>
            </div>
            <div className='line'></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <div>
                            <div style={{display: 'flex'}}>
                                <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.answer_sbutton'})}</Typography>
                                <ul style={{marginLeft: 18, fontSize: 12, color: oc.gray[7], lineHeight: '140%', listStyleType: 'none'}}>
                                    <li>{f({id: 'component.Survey.Create.Design.Custom.Option.answer_sbutton_sub.0'})}</li>
                                    <li>{f({id: 'component.Survey.Create.Design.Custom.Option.answer_sbutton_sub.1'})}</li>
                                </ul>
                            </div>

                        </div>
                        <div>
                            <PaperStyled name="answer_sbutton" style={{background: `rgba(${answer_sbutton})`}} onClick={(e) => on.openColorPicker(e)}></PaperStyled>
                        </div>
                    </BoxStyled>
                </div>
            </div>
            <div className='line'></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.background_color'})}</Typography>
                        <PaperStyled name="background_color" style={{background: `rgba(${background_color})`}} onClick={(e) => on.openColorPicker(e)}></PaperStyled>
                    </BoxStyled>
                </div>
            </div>
            <div className='line'></div>
            <div className='main' style={{display: 'none'}}>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.line_color'})}</Typography>
                        <PaperStyled name="line_color" style={{background: `rgba(${line_color})`}} onClick={(e) => on.openColorPicker(e)}></PaperStyled>
                    </BoxStyled>
                </div>
            </div>
            <div className='line' style={{display: 'none'}}></div>
            <div className='main customDesign'>
                <div>
                    <BoxStyled>
                        <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Design.Custom.Option.background_image'})}</Typography>
                        {
                            background_image ? (
                                <Tooltip title={f({id: 'component.Survey.Create.Design.Custom.Option.background_image_remove'})}>
                                    <IconButton size="small" onClick={on.removeImage} color="secondary"><DeleteForeverIcon/></IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title={f({id: 'component.Survey.Create.Design.Custom.Option.background_image_add'})}>
                                    <IconButton size="small" onClick={on.openImage}><AddIcon/></IconButton>
                                </Tooltip>
                            )
                        }
                    </BoxStyled>
                </div>
            </div>
            <div className={`sub last ${background_image && 'open'} design`} >
                    <BoxSubStyled>
                        <div>
                            <MyImage background_image={background_image} style={{backgroundSize: '100% 100%'}}/>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <Typography variant="body2">{f({id: 'component.Survey.Create.Design.Custom.Option.layout'})}</Typography>
                                </div>
                                <div>
                                    <BackgroundSizeComponent background_size={background_size} onChange={on.changeBackgroundSize}/>
                                </div>
                            </div>
                            {
                                // 리스토베이, 언스플래시 이미지만 필터 효과를 줄수가 있다.
                                getKind(background_image) && (
                                    <div>
                                        <div>
                                            <Typography variant="body2">{f({id: 'component.Survey.Create.Design.Custom.Option.filter'})}</Typography>
                                        </div>
                                        <div>
                                            <FilterComponent background_image={background_image} background_filter={background_filter} onChange={on.changeBackgroundFilter}/>
                                        </div>
                                    </div>
                                )
                            }
                            <div>
                                <div>
                                    <Typography variant="body2">{f({id: 'component.Survey.Create.Design.Custom.Option.opacity'})}</Typography>
                                </div>
                                <div style={{paddingLeft: 5}}>
                                    <BackgroundOpacityComponent background_opacity={background_opacity} onChange={on.changeBackgroundOpacity}/>
                                </div>
                            </div>
                        </div>
                    </BoxSubStyled>
            </div>
        </OptionStyled>
    )
}