import { useContext } from 'react'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import PageItemComponent from './PageItem'
import { MyList } from '../Point/Items'

function PointComponent() {
    const { pages, questionsKeyPageNo } = useContext(Context)
    const len = pages.length
    const defaultOpen = len === 1
    
    return  (
        <MyList dense disablePadding>
            {
                pages.map((page, i) => {
                    const { survey_page_no } = page

                    const questions = questionsKeyPageNo ? questionsKeyPageNo[survey_page_no] : null
                    return <PageItemComponent key={survey_page_no} page={page} len={len} i={i} questions={questions} defaultOpen={defaultOpen}/>
                    
                })
            }
        </MyList>
    )
}

export default PointComponent