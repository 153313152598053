/**
 * 그래프 more (순위형 등..)
 */

import { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    open: boolean;
    print: boolean;
    children: Element;
}

const useStyles = makeStyles({
    moreButton: {
        display: 'table',
        margin: '0 auto',
        marginTop: 10
    },
    collapse: (props: { open: Props['open']; print: Props['print']; }) => {
        const { open, print } = props

        const height = print ? '100000px' : '1000px'

        return {
            maxHeight: open ? height : 0,
            transition: 'all .6s',
            overflowX: 'hidden',
            overflowY: 'auto'
        }
    }
})

function DataItemMoreComponent(props: Props) {
    const { print, children } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(print)

    const classes = useStyles({ open, print })

    const handleChange = () => {
        setOpen(prevState => !prevState)
    }

    return (
        <>
            <div className="more">
                <Tooltip title={!open ? f({id: 'component.Survey.Create.Analysis.Summary.DataItem.AnswerMoreButton.tooltip'}) : ""}>
                    <IconButton size="small" className={classes.moreButton} onClick={handleChange}>
                        <UnfoldMoreIcon/>
                    </IconButton>
                </Tooltip>
            </div>
            <Box className={`collapse ${classes.collapse}`}>
                {children}
            </Box>
        </>
    )
}

export default memo(DataItemMoreComponent)