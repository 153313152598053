import { useEffect, memo } from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { scroller, animateScroll as scroll, Element } from 'react-scroll'
import { scrollerObj as _scrollerObj } from 'utils/survey'
import TopComponent from './Top'

const useStyles = makeStyles(theme => ({
    paper: ({ print, viewMode }) => ({
        height: viewMode === 'view' ? print ? 'initial' : 'calc(100vh - 16em)' : 'initial',
        position: 'sticky',
        overflowY: 'hidden',
        background: 'transparent',
        top: '9em',
        [theme.breakpoints.down('xs')]: {
            height: 'initial',
        }
        
    })
}))

export const scrollerObj = _scrollerObj()

const Component = (props) => {
    const { print, top,viewMode, dialog, onTop, children } = props
    
    const classes = useStyles({ print, viewMode })

    useEffect(() => {
        scroller.scrollTo('top', {
            ...scrollerObj.scrollTo,
            containerId: scrollerObj.id
        })
        
        scroll.scrollTo(0)
    }, [top])

    return (
        <Paper className={classes.paper} elevation={0}>
            {
                viewMode === 'edit' ? children : (
                    <Element id={scrollerObj.id} style={scrollerObj.style}>
                        <Element name="top"/>
                        {children}
                        {
                            dialog ? <TopComponent idd={scrollerObj.id} onClick={onTop}/> : null
                        }
                    </Element>
                ) 
            }
        </Paper>
    )
}

export default memo(Component)