import { getEndingMssage } from 'ts-utils/survey'
import { Kind, Method, JumpProps } from 'gql/jump'
import { Method as MboxMethod } from 'gql/survey_mbox'
import { SurveyEndingProps } from 'gql/survey_ending'
import { SurveyQuestionModulePropsInKey } from 'container/Survey/Create/Logic/Jump/Context'
import nodeClass, { NextItemProps } from './nodeClass'
import { NodeIDProps } from './Context'
import { ItemContextProps } from 'container/Survey/Create/Logic/Jump/Context'

function ParseNodes(
    f: any, 
    jump: JumpProps[],
    endings: SurveyEndingProps[], 
    questionsKeyQuestionNo: SurveyQuestionModulePropsInKey, 
    nodeID: NodeIDProps | null, 
    mboxMethod: MboxMethod, 
    start: ItemContextProps['start']
) {
    // nodeID가 있다면 해당 문항에서 목표문항의 목록을 담는다 (단, method가 show hide 일때만)
    function getVisibleNos() {
        let shows:number[] = []
        let hides:number[] = []

        // 설정값이 없다면 리턴
        if (!nodeID) return { shows, hides }

        const { id, kind } = nodeID

        // 해당문항값이 없다면 리턴 (버그가 아닌이상 그럴일은 음따)
        const self = jump.find(c => c.id === id && c.kind === kind)
        if (!self) return  { shows, hides }

        const { method, survey_question_nos, rules } = self
       
        // 숨김보이기시에 값을 담는다.
        if (method === Method.Show) {
            shows = [...survey_question_nos]
        } else if (method === Method.Hide) {
            hides = [...survey_question_nos]
        }

        for (let i=0; i<rules.length; i++) {
            const { method, survey_question_nos } = rules[i]
            if (method === Method.Show) {
                shows = [...shows, ...survey_question_nos]
            } else if (method === Method.Hide) {
                hides = [...hides, ...survey_question_nos]
            }
        }

        return { shows, hides }
    }

    
    function getTargetNos(method: Method, survey_question_nos: number[]) {
        // 멀티모드이면서 이동이면 survey_question_nos는 survey_page_no이다. Diagram에서는 문항만보여지므로 해당페이지의 첫번째 survey_question_no를 구해준다.
        function multiMoveNo() {
            if (!(method === Method.Move && mboxMethod === MboxMethod.Multi)) return survey_question_nos

            let flag = false
            const is = jump.find(c => {
                if (flag) return true
    
                if (c.kind === Kind.Page && c.id === survey_question_nos[0]) {
                    flag = true
                }
    
                return false
            })
    
            return is ? [is.id] : []
        }

        // 완료글 -1일경우 첫번째 엔등글에 연결한다.
        function endingNos() {
            if (survey_question_nos[0] !== -1) return survey_question_nos
            
            return [ endings[0].survey_ending_no ]
        }

        // method가 완료라면
        if (method === Method.End) return endingNos()

        // survey_question_nos[0] === 1 는 시작글이다
        return survey_question_nos[0] === 1 ? [1] : multiMoveNo()
    }

    // 완료글로 이동시 완료분기점일경우 상태값 체크
    function getEndingPoint(method: Method, survey_question_nos: number[]) {
        return  method === Method.End && survey_question_nos[0] === -1
    }

    if (!questionsKeyQuestionNo) return []

    let pageNumber = 0
    let questionNumber = 1
    let explainNumber = 1

    const visibleNos = getVisibleNos()

    // 페이지
    // const pages = jump.filter(c => c.kind === Kind.Page)

    // 마지막 번호 인덱스
    const jumpLength = jump.length
    const lastJumpIndex = jumpLength - 1

    const nodes = []
    const defaultLinks = []
    const rulesLinks = []

    // 시작글 노드
    if (start.used) {
        const { message, img_src } = start

        // node 추가
        const newNode = new nodeClass()
        const source = newNode.getID({ id: 1, kind: Kind.Question })

        const node = newNode.makeNodeObj({
            source,
            data: {
                label: f({id: 'component.Survey.Create.Menu.Component.subheader.thanks.start'}),
                message,
                img_src
            },
            visible: null
        })

        const questions = jump.filter(c => c.kind === Kind.Question)

        nodes.push(node)

        // 문항이 있을때 디폴트 링크를 준다
        if (questions.length > 0) {
             // 디폴트 링크 노드
            const newDefaultLinkNode = new nodeClass()
            // 원본노드 지정
            newDefaultLinkNode.refference = { id: 1, kind: Kind.Question, mark: `default-${Kind.Question}-1` }

            // 타겟ID만들기
            const target = newDefaultLinkNode.getID({ id: questions[0].id, kind: Kind.Question })
            const defaultLink = newDefaultLinkNode.makeLinkObj({ source, target, editButton: false, animated: false })

            defaultLinks.push(defaultLink)
        }
    }

    // 문항 노드
    for (let i=0; i<jump.length; i++) {
        const { id, kind, jump_no, method, rules, survey_question_nos } = jump[i]

         // 페이지는 보여줄필요 음따...
         if (kind === Kind.Page) {
            pageNumber += 1
            continue
        }
       
        // node 추가
        const newNode = new nodeClass()

        let nextItem:NextItemProps | undefined = [...jump.slice(i+1, jump.length)].find((c) => c.kind === kind)   // 디폴트 다음 타겟값 (문항은 문항끼리 페이지는 페이지끼리 연결..)

        // 마지막버호의 nextItem은 엔딩페이지이다.  
        if (lastJumpIndex === i) {
            nextItem = {id: endings[0].survey_ending_no, kind: Kind.End}
        }

        let nodeItem = null                          // 페이지 문항 차레'대로 담기 담기
        let defaultLinkItem = null                   // 디폴트 링크 아이템 담는 변수
        const rulesLinkItems = []                    // rules 링크 아이템 담는 변수
        const source = newNode.getID({ id, kind })   // 해당 노드 고유 아이디
        let label = ''                               // 화면 표기
        let message = ''                             // 툴팁에서 사용되는 메시지
        let img_src = ''                             // 툴팁에서 사용되는 이미지

        // 디폴트 링크 노드
        const newDefaultLinkNode = new nodeClass()
        newDefaultLinkNode.refference = { id, kind, mark: `default-${jump_no}` }

        if (kind === Kind.Page) {
            label = `${f({id: 'common.survey.itemType.page'})} ${pageNumber++}`
            const item = newDefaultLinkNode.getLinkDefaultObj({
                method, 
                source, 
                endingPoint: false,
                survey_question_nos,
                nextItem
            })

            if (item) defaultLinkItem = item
        } else {
            const question = questionsKeyQuestionNo[id]
            
            if (!question) continue

            const [{ _question }] = question
            const { question_type, question: tooltip_q, img_src: tooltip_i } = _question
            message = tooltip_q
            img_src = tooltip_i

            if (mboxMethod === MboxMethod.Multi) {
                if (question_type === 'explain') {
                    label = `Section${pageNumber}: ${f({id: 'common.survey.itemType.question_type.explain'})} ${explainNumber++}`
                } else {
                    label = `Section${pageNumber}: ${f({id: 'common.survey.itemType.question_type.question'})} ${questionNumber++}`
                }
            } else {
                if (question_type === 'explain') {
                    label = `${f({id: 'common.survey.itemType.question_type.explain'})} ${explainNumber++}`
                } else {
                    label = `${f({id: 'common.survey.itemType.question_type.question'})} ${questionNumber++}`
                }
            }
           
            const item = newDefaultLinkNode.getLinkDefaultObj({
                method, 
                source,
                endingPoint: getEndingPoint(method, survey_question_nos),
                survey_question_nos: getTargetNos(method, survey_question_nos),
                nextItem
            })

            if (item) defaultLinkItem = item
        }

        // rules 링크 노드
        const newRulesLinkNode = new nodeClass()
        for (let j=0; j<rules.length; j++) {
            const { jump_rule_no, method, survey_question_nos } = rules[j]

            newRulesLinkNode.refference = { id, kind, mark: `rules-${jump_rule_no}` }

            const item = newRulesLinkNode.getLinkObj({
                method, 
                source, 
                endingPoint: getEndingPoint(method, survey_question_nos),
                survey_question_nos: getTargetNos(method, survey_question_nos)
            })
           
            if (item) rulesLinkItems.push(item)
        }

        // 숨김, 보이기 둘다 있을경우 mix, 보이기일경우 shows 숨김일경우 hides 아무것도 아닐경우 빈값
        let visible = null
        if (visibleNos.shows.includes(id) && visibleNos.hides.includes(id)) visible = 'mix'
        else if (visibleNos.shows.includes(id)) visible = 'shows'
        else if (visibleNos.hides.includes(id)) visible = 'hides'

        nodeItem = newNode.makeNodeObj({ 
            source, 
            data: { 
                label,
                message,
                img_src, 
            },
            visible
        })

        nodes.push(nodeItem)
        if (defaultLinkItem) defaultLinks.push(defaultLinkItem)
        rulesLinks.push(...rulesLinkItems)
    }


    // 목표점이 end인 값만 담는다 
    const target_survey_ending_nos = jump.reduce((acc, c) => {
        const { method, survey_question_nos, rules } = c

        if (method !== Method.End) return acc

        let _acc = [ ...survey_question_nos ]

        for (let i=0; i<rules.length; i++) {
            const { method, survey_question_nos } = rules[i]

             // 방법이 종료인것만 담는다
            if (method !== Method.End) continue
            
            _acc = [ ..._acc, ...survey_question_nos ]
        }

        return [ ...acc, ..._acc]

    }, [])

    // 완료글 노드
    for (let i=0; i<endings.length; i++) {
        const { survey_ending_no, kind, message, redirect_url, img_src, indexs } = endings[i]
        
        const { survey_ending_no: target_survey_ending_no } = endings[i+1] || { survey_ending_no: '' }

        // node 추가
        const newNode = new nodeClass()
        const source = newNode.getID({ id: survey_ending_no, kind: Kind.End })
    
        const node = newNode.makeNodeObj({
            source,
            data: {
                label: `${f({id: 'component.Survey.Create.Logic.Jump.Diagram.ParseNodes.ending.title'})} ${indexs}`,
                message: getEndingMssage(kind, message, redirect_url),
                img_src
            },
            visible: null
        })

        // 디폴트 링크 노드
        const newDefaultLinkNode = new nodeClass()
        // 원본노드 지정
        newDefaultLinkNode.refference = { id: survey_ending_no, kind: Kind.End, mark: `default-${Kind.End}-${survey_ending_no}` }
        // 타겟ID만들기
        const target = newDefaultLinkNode.getID({ id: target_survey_ending_no, kind: Kind.End })
        // 디폴트 링크 연결
        let defaultLink = null
        if (target_survey_ending_no && !target_survey_ending_nos.includes(target_survey_ending_no)) defaultLink = newDefaultLinkNode.makeLinkObj({ source, target, editButton: false, animated: true })
        
        nodes.push(node)
        if (defaultLink) defaultLinks.push(defaultLink)
    }

    return [...nodes, ...defaultLinks, ...rulesLinks]
}

export default ParseNodes