import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { Props as ReducerProps } from 'reducer/survey/create/module/copy'
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Props as OtherCopyProps } from './OtherCopy'

interface Props {
    type: OtherCopyProps['type'];
    onSave: OtherCopyProps['onSave'];
}

function getDisabled(questions: ReducerProps['questions'], survey_page_no: ReducerProps['survey_page_no'], survey_question_no: ReducerProps['survey_question_no']) {
    if (!survey_page_no) return true
 
    if (questions.find(c => c.survey_page_no === survey_page_no)) {
        if (!survey_question_no) return true
    }

    return false
}

function CopySaveComponent(props: Props) {
    const { type, onSave } = props

    const { formatMessage: f } = useIntl()

    const states = useSelector((states: RootState) => states.surveyCreateModuleCopy)
    const { questions, survey_page_no, survey_question_no, other_survey_no } = states

    function handleSave() {
        onSave(other_survey_no)
    }

    return (
        <Button onClick={handleSave} color="primary" disabled={getDisabled(questions, survey_page_no, survey_question_no)}>
            <Typography variant="button">{f({id: `component.Survey.Create.Module.Copy.button.${type}`})}</Typography>
        </Button>
    )
}

export default CopySaveComponent