import { useContext } from 'react'
import oc from 'open-color'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import PageItemComponent from './PageItem'

export const MyList = withStyles({
    root: {
        width: '100%',
        background: oc.gray[8],
        color: 'white',
        borderRadius: 3
    }
})(List)

function PointComponent() {
    const { pages, questionsKeyPageNo } = useContext(Context)
    const defaultOpen = pages.length === 1

    return  (
        <MyList dense disablePadding>
            {
                pages.map((page) => {
                    const { survey_page_no } = page

                    const questions = questionsKeyPageNo ? questionsKeyPageNo[survey_page_no] : null
                    return <PageItemComponent key={survey_page_no} page={page} questions={questions} defaultOpen={defaultOpen}/>
                    
                })
            }
        </MyList>
    )
}

export default PointComponent