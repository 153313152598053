import { useContext, useState, useCallback } from 'react'
import PropsContext from 'context/props'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Button, Tabs, Tab, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SurveyCreateAnalysisSummaryEditPopContainer from 'container/Survey/Create/NewAnalysis/Summary/EditPop'
import ButtonNameComponent from './Ending/ButtonName'
import ButtonLinkComponent from './Ending/ButtonLink'
import ButtonAnalysisComponent from './Ending/ButtonAnalysis'

const useStyles = makeStyles({
    iconBox: {
        textAlign: 'right'
    },
    icon: {
        marginTop: 5
    },
    summaryBox: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > div:first-of-type': {
            flexGrow: 1
        },
        '& > div:last-of-type': {
            paddingTop: 2
        },
        '& > div + div': {
            marginLeft: 5
        }
    }
})

const CommentStyled = styled.div`
    display: ${props => props.is_tab ? 'block' : 'none'};
    & > div + div {
        margin-top: .5em;
    }

    & > div.divider {
        margin-top: 2em;
    }
`
function ButtonEndingComponent() {
    const { rows, subTabIndex,  onChange, onChangeSubTabIndex } = useContext(PropsContext)
  
    const { button_name, button_link, analysis_name } = rows

    const { formatMessage: f } = useIntl()
    
    const classes = useStyles()

    const [ openSummary, setOpenSummary ] = useState(false)

    const changeOpenSummary = useCallback((open) => {
        setOpenSummary(open)
    }, [])


    if (typeof rows.__typename !== 'string') return null

    return (
        <>
        <Tabs value={subTabIndex} onChange={(e, val) => onChangeSubTabIndex(val)} indicatorColor="primary" textColor="primary" style={{marginBottom: 10}}>
            <Tab label={<Typography variant="body2">{f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.tab.0'})}</Typography>} />
            <Tab label={<Typography variant="body2">{f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.tab.1'})}</Typography>} />
        </Tabs>
        <CommentStyled is_tab={subTabIndex === 0}>

            <div>
                <ButtonNameComponent button_name={button_name} onChange={onChange}/>
            </div>
            <div>
                <ButtonLinkComponent button_name={button_name} button_link={button_link} onChange={onChange}/>
            </div>
        </CommentStyled>
        <CommentStyled is_tab={subTabIndex === 1}>
            <div>
                <div className={classes.summaryBox}>
                    <div>
                        <ButtonAnalysisComponent analysis_name={analysis_name} onChange={onChange}/>
                    </div>
                    <div>
                        <Button color="primary" onClick={() => changeOpenSummary(true)}>{f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.summary'})}</Button>
                    </div>
                </div>
            </div>
        </CommentStyled>
        <SurveyCreateAnalysisSummaryEditPopContainer open={openSummary} onClose={() => changeOpenSummary(false)}/>
        </>
    )
}   

export default ButtonEndingComponent