import { SurveyModulesEndingComponent, SurveyCreateThanksPreviewComponent } from 'component'

export default ({ survey_no, rows, isChange }) => {

    return (
        <SurveyCreateThanksPreviewComponent {...rows} isChange={isChange}>
            <SurveyModulesEndingComponent survey_no={survey_no} row={rows}/>
        </SurveyCreateThanksPreviewComponent>
    )
}
