import { useContext } from 'react'
import { EndingContext } from 'container/Survey/Create/Logic/Jump/EndingContext'
import ItemComponent from './Item'

function ItemsComponent() {
    const { endings } = useContext(EndingContext)
    return (
        <>
            {
                endings.map(ending => <ItemComponent key={ending.survey_ending_no} row={ending}/>)
            }
        </>
    )
}

export default ItemsComponent