import { memo, useState, useEffect } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import oc from 'open-color'
import { JumpProps} from 'gql/jump'
import { SurveyPageProps } from 'gql/survey_page'
import styled from 'styled-components'

/*import PagesIcon from '@material-ui/icons/Pages'
import ItemHeartComponent from './ItemHeart'*/

interface Props extends SurveyPageProps {
    show: boolean;
    jumpRow: JumpProps;
}

export const WrapperStyled = styled.div`
    display: ${(props:{show:boolean}) => props.show ? 'block' : 'none'};
`

function ItemPageComponent(props: Props) {
    const { show, indexs, survey_page_no, jumpRow } = props

    /**
     * 초기값을 state에 담아놓는다
     */
    const [ stateJumpRow, setStateJumpRow ] = useState(jumpRow)

    const label = `Section ${indexs}`

    /**
     * 저장후 jumpRow이 바뀌면 다시 변경해준다.
     * 페이지 보기 값 변경시에도 변경없게 하는목적이 더크다. (state로 관리안하면 페이지값 변경시 jumpRow 값이 바뀐다(DB에 저장안된 값들..))
     */
    useEffect(() => {
        setStateJumpRow(jumpRow)
    }, [jumpRow, survey_page_no ])

    return (
        <>
        <Divider style={{background: oc.gray[1]}}/>
        <ListItem style={{background: oc.gray[3]}}>
            <ListItemText>
                <Typography style={{color: 'black'}}>{label}</Typography>
            </ListItemText>
        </ListItem>
        <Divider style={{background: oc.gray[1]}}/>
        </>
    )

    /* 미사용
    return (
        <WrapperStyled show={show}>
            <ItemHeartComponent 
                icon={PagesIcon} 
                label={label} 
                tooltip={label}
                jumpRow={stateJumpRow}
            />
        </WrapperStyled>
    )*/
}

export default memo(ItemPageComponent)