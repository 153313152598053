import { useState, useCallback } from 'react'
import { Stepper, StepButton, Step, Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { 
    LockOutlined as LockOutlinedIcon,
    LockOpen as LockOpenIcon,
    VisibilityOutlined as VisibilityOutlinedIcon
} from '@material-ui/icons'
import styled from 'styled-components'
import oc from 'open-color'
import { useIntl } from 'react-intl'
import Tooltip from 'styled/Custom/Tooltip'
import { withSurveyFetchData } from 'hoc'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { getMaxlength } from 'policy'
import { UPDATE_SURVEY_MBOX_TITLE } from 'gql/survey_mbox'
import useSurveyOpen from 'hooks/useSurveyOpen'
import { SurveyPreviewContainer } from 'container'
import ChannelComponent from './Channel'
import StepperComponent from './Stepper'

const useStyles = makeStyles({
    root: {
        height: 0,
        backgroundColor: 'transparent',
    }
})

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 3;

    & > div {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: auto 1fr auto auto;
        grid-template-rows: auto;
        grid-template-areas: "title step preview channel";
        align-items: center;

        & > div {

        }

        & > div.title {
            grid-area: title;
        }

        & > div.step {
            grid-area: step;
        }

        & > div.preview {
            grid-area: preview;
            text-align: right;
        }

        & > div.channel {
            grid-area: channel;
        }

        @media only screen and (max-width: 960px) {
            grid-template-columns: auto 1fr auto;
            grid-template-rows: auto;
            grid-template-areas: "title step preview";

            & > div.channel {
                display: none;
            }
        }

        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr auto;
            grid-template-rows: auto;
            grid-template-areas: 
                "title preview";

            & > div.step {
                display: none;
            }
        }
    }

`

const StyledInput = styled.input`
    background: transparent;
    width: 100%;
    outline: 0;
    padding-left: 0;
    border: none;
    font-size: 14px;
    color: ${oc.gray[7]};
    font-family: Roboto;

    &::placeholder {
        color: ${oc.gray[5]};
        font-size: 14px;
    }
`
// 제목 onChange 시 바로 저장하지않고 800 이후에 한다. 그사이 입력하면 timeout 다시한다
let timeout = null

const Component = props =>  {
    const { survey_no, labels, activeStep, onChangeStep, propsFetchData } = props
    const { mbox } = propsFetchData

    const dispatch = useDispatch()

    const [ title, setTitle ] = useState(mbox.title)

    const [ previewOpen, setPreivewOpen ] = useState(false)

    const [ open, setOpen ] = useSurveyOpen()

    function handlePreviewOpen() {
        setPreivewOpen(true)
    }

    const handlePreviewClose = useCallback(() => {
        setPreivewOpen(false)
    }, [])

    
    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const [ updateSurveyMboxTitle ] = useMutation(UPDATE_SURVEY_MBOX_TITLE, {
        onError: (e) => {
            console.log('e', e)
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const changeTitle = async (e) => {
        const { value } = e.target

        clearTimeout(timeout)

        await setTitle(value)

        timeout = setTimeout(async() => {
            try {
                await dispatch({ type: BACKDROPSHOW })
                await updateSurveyMboxTitle({ variables: { 
                    survey_no,
                    title: value
                }})
            } catch(e) {

            }

            dispatch({ type: BACKDROPHIDE })
        }, 1000)
    }
    return (
        <>
        <WrapperStyled activeStep={activeStep}>
            <div>
                <div className='title'>
                    <StyledInput value={title} onChange={changeTitle} placeholder={f({id: 'component.Survey.Create.Toolbar.subject'})} maxLength={getMaxlength('component.Survey.Create.Step.title')}/>
                </div>
                <div className='step'>
                    <StepperComponent labels={labels} activeStep={activeStep} onChangeStep={onChangeStep}/>
                </div>
                <div className='preview'>
                    <ToggleButtonGroup size="small" style={{height: 33, marginTop: 3}}>
                        <Tooltip title={f({id: 'component.Survey.Create.Toolbar.preview'})}>
                            <ToggleButton value='pc' onClick={handlePreviewOpen} >
                                <VisibilityOutlinedIcon fontSize='small'/>
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title={f({id: `component.Survey.Create.Toolbar.open.${open ? '0' : '1'}`})}>
                            <ToggleButton edge="start" value='pc' onClick={setOpen}>
                                {open ? <LockOpenIcon color="primary" fontSize='small'/> : <LockOutlinedIcon color="primary"  fontSize='small'/>}
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                </div>
                <div className="channel">
                    <ChannelComponent/>
                </div>
            </div>
        </WrapperStyled>
        <SurveyPreviewContainer open={previewOpen} survey_no={survey_no} onClose={handlePreviewClose}/>
        </>
    )
}

export default withSurveyFetchData('edit')({mbox: true})(false)(Component)
