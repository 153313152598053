import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyPageProps } from 'gql/survey_page'
import withFetchQuestionInPage from 'hoc/Survey/FetchQuestionInPage'
import PopoverComponent from './PopoverCopy'
import CopyItemComponent from './CopyItem'
import { Props as OtherCopyProps } from './OtherCopy'
import useCopySave from './useCopySave'

interface Props extends OtherCopyProps {
    propsFetchQuestionInPage: {
        questions: SurveyQuestionModuleProps['_question'][];
        pages: SurveyPageProps[];
        questionAll: SurveyQuestionModuleProps['_question'][];
        val: {
            survey_page_no: number;
            survey_question_no: number;
            position: 0 | 1;
        }
    }
}

const MoveComponent = (props: Props) => {
    const { 
        anchorEl, 
        type, 
        onChangePage, 
        onChangeQuestion,
        onChangePosition,
        onClose,
        onSave,
        propsFetchQuestionInPage: withs
    } = props

    const questionAll = withs.questionAll
    const survey_page_no = withs.val.survey_page_no 
    const survey_question_no = withs.val.survey_question_no

    useCopySave({
        questions: questionAll,
        survey_page_no,
        survey_question_no,
        other_survey_no: ''
    })

    return (
        <PopoverComponent anchorEl={anchorEl} type={type} onClose={onClose} onSave={onSave}>
            <CopyItemComponent
                pages={withs.pages}
                questions={withs.questions}
                survey_page_no={withs.val.survey_page_no}
                survey_question_no={withs.val.survey_question_no}
                position={withs.val.position}
                onChangePage={onChangePage}
                onChangeQuestion={onChangeQuestion}
                onChangePosition={onChangePosition}
            />
        </PopoverComponent>
    )
}

export default withFetchQuestionInPage()(MoveComponent)