// 주관식 답변 내용
import { memo, useState, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import TextField from '@material-ui/core/TextField'
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined'
import Box from '@material-ui/core/Box'
import IconButton from 'styled/Custom/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { JumpExampleCountTextProps } from 'gql/jump_example_count'
import { getMaxlength } from 'policy'
import { OnChangeText, OnRemoveText } from './QuestionItemSplit'

interface Props extends Omit<JumpExampleCountTextProps, 'survey_question_no'> {
    onChange: OnChangeText;
    onRemove: OnRemoveText;
}

const MyTextFieldBox = withStyles({
    root: {
        position: 'relative'
    }
})(Box)

const MyTextField = withStyles({
    root: {
        marginBottom: 10, 
        background: 'white', 
        borderRadius: 5
    }
})(TextField)

const RemoveIconButton = withStyles({
    root: {
        position: 'absolute',
        top: -5,
        right: -8
    }
})(IconButton)

let time: ReturnType<typeof setTimeout> 

function InputTextComponent(props: Props) {
    const { jump_example_count_text_no, value, onChange, onRemove } = props

    const { formatMessage: f } = useIntl()

    const [ stateValue, setStateValue ] = useState(value)

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { value: newValue  } = e.target

        clearTimeout(time)

        setStateValue(newValue)

        time = setTimeout(() => {
            onChange({ jump_example_count_text_no, value: newValue })
        }, 100)
    }

    return (    
        <MyTextFieldBox key={jump_example_count_text_no}>
            <MyTextField 
                variant="outlined" 
                fullWidth 
                margin="dense" 
                placeholder={f({id: 'component.Survey.Create.Logic.Jump.Point.InputText.placeholder'})}
                inputProps={{
                    maxLength: getMaxlength('component.Survey.Create.Logic.Jump.Point.InputText')
                }}
                value={stateValue} 
                onChange={handleChange}
                error={stateValue === ''}
            />
            <RemoveIconButton color="secondary" size="small" onClick={() => onRemove(jump_example_count_text_no)}><RemoveCircleOutlinedIcon/></RemoveIconButton>
        </MyTextFieldBox>
    )
}

export default memo(InputTextComponent)