import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { ModuleRank } from 'gql/survey_question_modules'

interface Props {
    _modules: ModuleRank[];
}

const MyBox = withStyles({
    root: {
        marginBottom: 5,
    }
})(Box)

const MySelect = withStyles({
    root: {
        background: oc.gray[2],
        borderTopRightRadius: 2,
        borderTopLeftRadius: 2, 
        padding: '6px 20px 6px 12px',
        fontSize: 14,

    }
})(Select)

function useSelectRank(props: Props): [number, () => JSX.Element] {
    const { _modules } = props

    const { formatMessage: f } = useIntl()

    const [ targetSurveyModuleNo, setTargetSurveyModuleNo ] = useState(Number(_modules[0].survey_module_no))

    const handleChange = useCallback((e) => {
        const { value } = e.target
        setTargetSurveyModuleNo(Number(value))
    }, [])

    const SelectComponent = useCallback(() => {
        return (
            <MyBox>
                <MySelect value={targetSurveyModuleNo} onChange={handleChange}>
                {
                    _modules.map(c =>  <MenuItem key={c.survey_module_no} value={c.survey_module_no}>{`${c.indexs} ${f({id: 'component.Survey.Create.Logic.Jump.Point.useSelectRank.rank'})}`}</MenuItem>)
                }
                </MySelect>
            </MyBox>
        )
    }, [f, targetSurveyModuleNo, _modules, handleChange])

    return [ targetSurveyModuleNo, SelectComponent ]
}

export default useSelectRank