import { MouseEvent } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import { useIntl } from "react-intl"
import { useMutation } from '@apollo/client'
import { Levels } from 'ts-policy'
import { UPDATE_SURVEY_MBOX_COVER_USED, SurveyMboxCoverProps } from "gql/survey_mbox_cover"
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import Tooltip from "styled/Custom/Tooltip"
import { MyButton, Checkbox, UnCheckbox } from './StartItemUsed'

interface Props {
    levels: Levels;
    used: SurveyMboxCoverProps['used'];
    refetch: () => void;
}

function BrandUsedComponent(props: Props) {
    const { levels, used, refetch } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const [ update ] = useMutation(UPDATE_SURVEY_MBOX_COVER_USED, {
        onCompleted: () => {
            refetch()
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function ChangeEvent(e: MouseEvent<HTMLButtonElement>) {
        update({
            variables: {
                survey_no,
                used: !used
            }
        })       
    }

    const isFree = levels === Levels.Level0

    return (
        <Tooltip title={isFree ? f({id: 'component.Survey.Create.Menu.BrandUsed.label'}) : ''} isMobileClick={true}>
            <div>
                <MyButton 
                    startIcon={(isFree || used) ? <Checkbox disabled={isFree}/> : <UnCheckbox/>}
                    onClick={ChangeEvent}
                    disabled={isFree}
                >
                    {f({id: 'component.Survey.Create.Menu.StartItemUsed.label'})}
                </MyButton>    
            </div>
        </Tooltip>
    )
}

export default BrandUsedComponent