import { useCallback, useEffect, useState, useMemo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'
import { withBreakPoints } from 'hoc'
import HelpPopupComponent from './HelpPopup'

function HelpComponent(props) {
    const { layout, breakpoints } = props
    const { xs } = breakpoints

    const [ open, setOpen ] = useState(false)

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    useEffect(() => {
        if (!xs) handleClose()
    }, [xs, handleClose])

    return (
        <>
        {
            xs && (
                <IconButton size="small" color="primary" onClick={handleOpen}>
                    <HelpTwoToneIcon/>
                </IconButton>
            )
        }
        {
            useMemo(() => (
                <HelpPopupComponent layout={layout} open={open} onClose={handleClose}/>
            ), [layout, open, handleClose])
        }
        </>
    )
}

export default withBreakPoints(HelpComponent)

