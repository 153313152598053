import { memo } from 'react'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import { JumpNo } from 'gql/jump'
import { OnRemoveProps } from './ItemRules'

interface Props extends OnRemoveProps {
    length: number;
    jump_rule_child_no: JumpNo;
}

function ButtonRemoveItemComponent(props: Props) {
    const { length, jump_rule_child_no, onRemove } = props

    const { formatMessage: f } = useIntl()

    const disabled = length === 1

    return (
        <Button 
            disabled={disabled}
            color="secondary" 
            size="small" 
            onClick={() => onRemove(jump_rule_child_no)}
        >
            {disabled ? '' : f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonRemoveItem.button.remove'})}
        </Button>
    )
}

export default memo(ButtonRemoveItemComponent)