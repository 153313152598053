import { memo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { AnimationsProps, OnChangeAnimationChoice } from '../Component'

interface Props {
    css: AnimationsProps['css'];
    title: AnimationsProps['title'];
    choice: boolean;
    onChange: OnChangeAnimationChoice;
}

const useStyles = makeStyles(theme => ({
    box: (props: { choice: Props['choice'] }) => {
        const { choice } = props

        const styles = choice ? { border: `1px solid ${theme.palette.primary.main}`} : { border: `1px solid transparent` }

        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
            ...styles    
        }
    },
    logo: {
        fontWeight: 900,
        fontFamily: 'Roboto',
        color: theme.palette.primary.dark,
        marginTop: 30,
        marginBottom: 30
    },
    text: {
    
        width: '100%',
        textAlign: 'center',
        background: theme.palette.grey[100],
        border: `2px solid ${theme.palette.grey[100]}`,
        fontWeight: 500
    }
}))

function AnimationComponent(props: Props) {
    const { css, title, choice, onChange } = props

    const [ play, setPlay ] = useState('')

    const classes = useStyles({ choice })

    const handleOver = () => setPlay('animate__animated')
    const handleOut = () => setPlay('')

    return (
        <Grid item xs={6} sm={4} md={4}>
            <Paper className={classes.box} onMouseOver={handleOver} onMouseOut={handleOut} onClick={() => onChange(css)}>
                <Typography className={`${classes.logo} ${play} ${css} animate__infinite animate__slow`}>Listovey</Typography>
                <Typography className={classes.text} variant="caption">{title}</Typography>
            </Paper>
        </Grid>
    )
}

export default memo(AnimationComponent)