/**
 * 저장
 */

import { memo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash-es'
import { RootState } from 'reducer'
import Button from '@material-ui/core/Button'
import { OnSave, QuestionOption } from './Component'

interface Props {
    defaultQuestionOptions: QuestionOption;
    onSave: OnSave;
}

// disabled 판단
const getDisabled = (defaultData: QuestionOption, newData: QuestionOption) => {
    // 같다면 disabled 처리
    if (isEqual(defaultData, newData)) return true

    let disabled = false
    const keys = Object.keys(newData)
    const len = keys.length

    for (let i=0; i<len; i++) {
        const key = keys[i]

        // 값이 있는상태에서 열었다가, 모든 문항 휴지통으로 이동해서 디폴트로 문항선택 옵션 하나만 있다면...
        if (!key && i === 0 && len === 1) {
            disabled = false
            break
        }

        if (!key) {
            disabled = true
            break
        }

        const self = newData[key]

        // 키에 대한 값이 없으면 이상하니까 
        if (!self) {
            disabled = true
            break
        }

        if (typeof self.value === 'string') {
            // 주관식 답변 빈값일 경우
            if (self.value === '') {
                disabled = true
                break
            }
        } else {
            // 객관식 답변 선택된 게 없을 경우
            if (self.value.length === 0) {
                disabled = true
                break
            }
        }
    }

    return disabled
}

function SaveComponent(props: Props) {
    const { defaultQuestionOptions, onSave } = props

    const { formatMessage: f } = useIntl() 

    const state: { data: QuestionOption } = useSelector((states: RootState) => states.analysisFilterData)
    const { data } = state

    return (
        <Button color="primary" size="large" disabled={getDisabled(defaultQuestionOptions, data)} onClick={() => onSave(data)}>
            {f({id: 'component.Survey.Create.Analysis.Filter.Component.button.save'})}
        </Button>
    )
}

export default memo(SaveComponent)