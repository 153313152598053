import { memo, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { CHANGE_LOGO, CHANGE_ANIMATION } from 'reducer/survey/modules/cover'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import { UPDATE_SURVEY_MBOX_COVER, SurveyMboxCoverProps } from 'gql/survey_mbox_cover'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CreateBox from 'styled/Survey/CreateBox'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import TabsComponent from './Edit/Tabs'
import TabComponent from './Edit/Tab'
import LogoComponent from './Edit/Logo'
import AnimationComponent from './Edit/Animation'
import { brandScrolls } from 'container/Survey/Create/Brand/Container'
import { ImageContainer } from 'container'
import {
    ScalePaper,
    ScaleTitle,
    ScaleParentGrid,
    ScaleTitleGrid,
    ScaleInputGrid
} from 'component/Survey/Edit/05/Component'

export interface Props {
    survey_no: number;
    src: SurveyMboxCoverProps['src'];
    animation: SurveyMboxCoverProps['animation'];
    question_top_used: SurveyMboxCoverProps['question_top_used'];
    question_top_align: SurveyMboxCoverProps['question_top_align'];
    onChangeCategory: (args: string) => void;
    refetch?: () => void;
    breakpoints: BreakPointsProps;
}

// 애니메이션 배열 
export type AnimationsProps = {
    css: string;
    title: string;
}

// 애니메이션 선택 함수
export type OnChangeAnimationChoice = (css: AnimationsProps['css']) => void;

// 이미지 박스 열리는 함수
export type OnOpenImage = () => void;

// 이미지 삭제 
export type OnDeleteImage = () => void;

// 탑고로 사용여부
export type OnChangeQuestionTopUsed = (used: Props['question_top_used']) => void;

// 탑고로 정렬
export type OnChangeQuestionTopAlign = (used: Props['question_top_align']) => void;


const animations: AnimationsProps[] = [
    { css: '', title: 'None'},
    { css: 'animate__zoomInDown', title: 'Zoom in down'},
    { css: 'animate__zoomInUp', title: 'Zoom in up'},
    { css: 'animate__backInDown', title: 'Back in down'},
    { css: 'animate__backInUp', title: 'Back in up'},
    { css: 'animate__fadeInDownBig', title: 'Fade in down'},
    { css: 'animate__fadeInUpBig', title: 'Fade in up'},
    { css: 'animate__bounce', title: 'Bounce'},
    { css: 'animate__rubberBand', title: 'Rubber band'},
    { css: 'animate__flip', title: 'Flip'},
    { css: 'animate__rotateIn', title: 'Roate'},
    { css: 'animate__rollIn', title: 'Roll'}
]

function BrandComponent(props: Props) {
    const { 
        survey_no,
        src,
        animation,
        question_top_used,
        question_top_align,
        breakpoints, 
        onChangeCategory,
        refetch
    } = props
    const { xs } = breakpoints
    
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ show, setShow ] = useState<'contents' | 'image'>('contents')

    // 이미지 주소
    const [ imageSrc, setImageSrc ] = useState(src)

    // 애니메이션 효과 선택
    const [ animationChoice, setAnimationChoice ] = useState(animation)

    // 탑로고 사용여부
    const [ questionTopUsed, setQuestionTopUsed ] = useState<Props['question_top_used']>(question_top_used)

    // 탑로고 정렬
    const [ questionTopAlign, setQuestionTopAlign ] = useState<Props['question_top_align']>(question_top_align)

    // 저장

    const [ update ] = useMutation(UPDATE_SURVEY_MBOX_COVER, {
        onCompleted: () => {
            if (refetch) {
                refetch()
            }
            onChangeCategory('module')
        },
        onError: () => {
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    const handleSave = useCallback(() => {
        const input = {
            survey_no,
            src: imageSrc,
            animation: animationChoice,
            question_top_used: questionTopUsed,
            question_top_align: questionTopAlign
        }

        update({
            variables: {
                input
            }
        })
    }, [survey_no, imageSrc, animationChoice, questionTopUsed, questionTopAlign, update])

    const handleClose = useCallback(() => {
        onChangeCategory('module')
    }, [onChangeCategory])

 

    const handleChangeAnimationChoice:OnChangeAnimationChoice = useCallback((css) => {
        setAnimationChoice(css)
        dispatch({ type: CHANGE_ANIMATION, animation: css })
    }, [dispatch])

    const handleOpenImage = useCallback(() => {
        setShow('image')
    }, [])

    const handleCloseImage = useCallback(() => {
        setShow('contents')
    }, [])

    const handleAppendImage = useCallback((src: string) => {
        setImageSrc(src)
        dispatch({ type: CHANGE_LOGO, src })
    }, [dispatch])

    const handleChangeImage = useCallback((args: { img_src: string }) => {
        const { img_src } = args
        setImageSrc(img_src)
        dispatch({ type: CHANGE_LOGO, src: img_src })
    }, [dispatch])

    const handleDeleteImage: OnDeleteImage = useCallback(() => {
        setImageSrc('')
        dispatch({ type: CHANGE_LOGO, src: '' })
    }, [dispatch])

    const handleChangeQuestionTopUsed: OnChangeQuestionTopUsed = useCallback((used) => {
        setQuestionTopUsed(used)
    }, [])

    const handleChangeQuestionTopAlign: OnChangeQuestionTopAlign = useCallback((align) => {
        setQuestionTopAlign(align)
    }, [])

    const handleInit = useCallback(() => {
        handleChangeImage({ img_src: src })
        handleChangeAnimationChoice(animation)
        handleChangeQuestionTopUsed(question_top_used)
        handleChangeQuestionTopAlign(question_top_align)
    }, [ src, animation, question_top_used, question_top_align, handleChangeImage, handleChangeAnimationChoice, handleChangeQuestionTopUsed, handleChangeQuestionTopAlign ]) 

    return (
        <CreateBox scrolls={brandScrolls}>
            <div style={{ display: show === 'contents' ? 'block': 'none' }}>
                <TabsComponent onSave={handleSave} onClose={handleClose} onInit={handleInit}/>
                <TabComponent index={0}>
                    <Paper style={{ padding: 10, marginBottom: 10 }}>
                        <Typography variant="body2" color="secondary" component="span">{f({id: 'component.Survey.Create.Brand.Component.tip'})}</Typography>
                    </Paper>
                    <ScalePaper>
                        <ScaleParentGrid>
                            <ScaleTitleGrid xs={4} sm={2} md={2}>
                                <ScaleTitle>
                                    {f({id: 'component.Survey.Create.Brand.Component.items.0'})}
                                </ScaleTitle>
                            </ScaleTitleGrid>
                            <ScaleInputGrid xs={8} sm={10} md={10}>
                                <LogoComponent 
                                    imageSrc={imageSrc} 
                                    onOpen={handleOpenImage} 
                                    questionTopUsed={questionTopUsed}
                                    questionTopAlign={questionTopAlign}
                                    onChangeQuestionTopUsed={handleChangeQuestionTopUsed}
                                    onChangeQuestionTopAlign={handleChangeQuestionTopAlign}
                                    onDelete={handleDeleteImage}
                                />
                            </ScaleInputGrid>
                        </ScaleParentGrid>
                    </ScalePaper>
                    {
                        imageSrc && (
                            <ScalePaper>
                                <ScaleParentGrid>
                                    <ScaleTitleGrid xs={4} sm={2} md={2}>
                                        <ScaleTitle>
                                            {f({id: 'component.Survey.Create.Brand.Component.items.1'})}
                                        </ScaleTitle>
                                    </ScaleTitleGrid>
                                    <ScaleInputGrid xs={8} sm={10} md={10}>
                                        <Grid container spacing={1}>
                                            {
                                                animations.map(c => {
                                                    const { css, title } = c    
                                                    const choice = css === animationChoice
                                                    return <AnimationComponent key={css} css={css} title={title} choice={choice} onChange={handleChangeAnimationChoice}/>
                                                })
                                            }
                                        </Grid>
                                    </ScaleInputGrid>
                                </ScaleParentGrid>
                            </ScalePaper>
                        )
                    }
                </TabComponent>
            </div>
            <div style={{ display: show === 'image' ? 'block': 'none' }}>
                <ImageContainer 
                    height={xs ? 'calc(100vh - 34.8em)' : ''} 
                    layout="horizontal" 
                    onlyImage={true} 
                    isVideo={false} 
                    onClose={handleCloseImage} 
                    onAppendUrl={handleAppendImage} 
                    onChange={handleChangeImage}
                />
            </div>
        </CreateBox>
    )
}

export default withBreakPoints(memo(BrandComponent))