import { memo } from 'react'
import Chip from '@material-ui/core/Chip'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    branding_src: string;
    branding_size: string;
    onOpenBranding: () => void;
    onChange: (type: string, value: string) => void;
}

function BrandingImageComponent(props: Props) {
    const { branding_src, branding_size, onOpenBranding, onChange } = props

    return (
        <Grid container alignItems="center" spacing={2} style={{height: 54}}>
            <Grid item>
                <InsertPhotoIcon color="action" style={{cursor: 'pointer', display: 'block'}} onClick={onOpenBranding}/>
            </Grid>
            {
                branding_src && (
                    <>
                    <Grid item>
                        <Chip variant="default" size="small" avatar={<Avatar src={branding_src}/>} onDelete={() => onChange('branding_src', '')}/>
                    </Grid>
                    <Grid item>
                        <RadioGroup row name="branding_size"  value={branding_size} onChange={(e) => onChange('branding_size', e.target.value)}>
                            <Tooltip title="size: small">
                                <FormControlLabel
                                value="s"
                                control={<Radio color="primary" size="small"/>}
                                label="S"
                                labelPlacement="end"
                                />
                            </Tooltip>
                            <Tooltip title="size: medium">
                                <FormControlLabel
                                value="m"
                                control={<Radio color="primary" size="small" />}
                                label="M"
                                labelPlacement="end"
                                />
                            </Tooltip>
                            <Tooltip title="size: large">
                                <FormControlLabel
                                value="l"
                                control={<Radio color="primary" size="small" />}
                                label="L"
                                labelPlacement="end"
                                />
                            </Tooltip>
                        </RadioGroup>
                    </Grid>
                    </>
                )
            }
        </Grid>
    )
}

export default memo(BrandingImageComponent)