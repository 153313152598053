import { memo, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { CHANGE } from 'reducer/survey/create/logic/menuAlarm'
import { RESET_JUMP } from 'gql/jump'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from 'styled/Survey/Live/Dialog'
import { ListsContext } from 'container/Survey/Create/Logic/Jump/ListsContext'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'

interface Props {
    onClose: () => void;
}

function ButtonResetComponent(props: Props) {
    const { onClose } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const jumpState = useSelector((state: RootState) => state.surveyCreateLogicJump)

    const { rows } = jumpState

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const { onInit } = useContext(ListsContext)
    const { refetchSurveyQuestionsModuleAll } = useContext(Context)

    const [ open, setOpen ] = useState(false)

    const [ resetJump ] = useMutation(RESET_JUMP, {
        onCompleted: () => {
            // 임의로 초기화
            onInit()

            refetchSurveyQuestionsModuleAll()
             
            // 다이아로그 창 닫기
            handleClose()
            
            // collaspse 닫기
            onClose()

            dispatch({ type: CHANGE, kinds: ['jump', 'questionAll'] })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleOpen() {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    function handleSave() {
        resetJump({
            variables: {
                survey_no
            }
        })
    }

    return (
        <>
        <Button variant="outlined" size="small" onClick={handleOpen} disabled={rows.length === 0}>
            {f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonReset.button.reset'})}
        </Button>

        <Dialog open={open} fullScreen={false} onClose={handleClose}>
            <DialogTitle>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonReset.dialog.title'})}</DialogTitle>
            <DialogActions>
                <Button color="secondary" onClick={handleSave}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonReset.dialog.button.reset'})}</Button>
                <Button onClick={handleClose}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonReset.dialog.button.close'})}</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default memo(ButtonResetComponent)