import { useState, MouseEvent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SurveyDesignsProps } from 'gql/survey_designs'
import oc from 'open-color'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import CreateBox from 'styled/Survey/CreateBox'
import MyComponent from './My/Component'
import GalleryComponent from './Gallery/Component'

export type OnApplyProps = Omit<SurveyDesignsProps, 'title' | 'survey_designs_no'>

export interface Props {
    rows: any[];
    template: boolean;
    onOpen: () => void;
    onOpenPop: (e: MouseEvent<HTMLButtonElement>, b: number, c: boolean) => void;
    onApply: (a: OnApplyProps) => void;
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    alert: {
        marginBottom: 10,
        backgroundColor: 'white'
    }
})

const MyTabs = withStyles({
    root: {
        backgroundColor: oc.gray[3], 
        paddingBottom: 10
    }
})(Tabs)

function DesignComponent(props: Props) {
    const { rows, template, onOpen, onOpenPop, onApply } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ tab, setTab ] = useState(template ? 1 : 0)

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setTab(newValue)
    }

    useEffect(() => {
        setTab(template ? 1 : 0)
    }, [template])

    return (
        <>
        <MyTabs 
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            centered
            value={tab} 
            onChange={handleChange}
        >
            <Tab label={f({id: 'component.Survey.Create.Design.Component.tab.0'})} style={{fontWeight: 900}}/>
            <Tab label={f({id: 'component.Survey.Create.Design.Component.tab.1'})} style={{fontWeight: 900}}/>
        </MyTabs>
        <CreateBox className="design">
            <div className={classes.root}>
                <Alert severity="info" variant="outlined" className={classes.alert}>
                    <Typography variant="body2">{f({id: `component.Survey.Create.Design.Component.warning.${tab}`})}</Typography>
                </Alert>
                { tab === 0 ? <MyComponent rows={rows} onOpen={onOpen} onOpenPop={onOpenPop} onApply={onApply}/> : <GalleryComponent  onOpenPop={onOpenPop} onApply={onApply}/> }
            </div>
        </CreateBox>
        </>
    )
}

export default DesignComponent