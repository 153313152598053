import { memo, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import { MyListItemIcon } from 'styled/Survey/Share/Embed'

interface Props {
    fontSize: number;
    onChangeSize:(a:ChangeEvent<HTMLInputElement>) => void;
}

function PopupFontSizeComponent(props: Props) {
    const { fontSize, onChangeSize } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        <ListItem>
                <ListItemText>{f({id: 'component.Survey.Create.Share.PopupFontSize.title'})}</ListItemText>
                <MyListItemIcon>
                    <TextField style={{width: 50}} autoFocus value={fontSize} type="number" onChange={onChangeSize}/>
                </MyListItemIcon>
            </ListItem>
        </>
    )
}

export default memo(PopupFontSizeComponent)