import { useParams } from 'react-router-dom'
import { SurveyAnalysisSummaryProps, ViewMode, Print } from 'gql/survey_analysis_summary'
import { SurveyMboxProps } from 'gql/survey_mbox'
import { SurveyMboxDesignProps } from 'gql/survey_mbox_design'
import SummaryContext from 'context/summary'
import DataScrollComponent from './DataScroll'

interface Props {
    items: SurveyAnalysisSummaryProps[];
    mbox: SurveyMboxProps;
    design: SurveyMboxDesignProps;
    viewMode: ViewMode;
    print: Print;
}

const Component = (props: Props) => {
    const { items, mbox, design, viewMode, print } = props

    const params = useParams<{survey_no: string}>()
    const survey_no = params.survey_no ? Number(params.survey_no) : mbox.survey_no

    return (
        <SummaryContext.Provider value={{ design, mbox, viewMode, print }}>
            <DataScrollComponent survey_no={survey_no} items={items} viewMode={viewMode} print={print}/>
        </SummaryContext.Provider>
    )
}

export default Component