import { MouseEvent } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import { useIntl } from "react-intl"
import { useMutation } from '@apollo/client'
import { UPDATE_SURVEY_START_USED, UpdateSurveyStartUsedVariables, SurveyStartProps } from "gql/survey_start"
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Button, { ButtonProps } from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { defaultColor } from "./Items"

interface Props {
    used: SurveyStartProps['used'];
    refetch: () => void;
}

export const MyButton = withStyles({
    root: () => {
        return {
            color: defaultColor.color,
            backgroundColor: defaultColor.backgroundColor,
            '&:hover': {
                backgroundColor: defaultColor.backgroundColorHover
            }
        }
    }
})((props: ButtonProps) => <Button {...props} variant="contained" disableElevation size="small"/>)

export const Checkbox = withStyles(theme => ({
    root: (props: { disabled?: boolean }) => {
        const { disabled } = props

        const styles = disabled === true ? { color: theme.palette.action.disabled } : {}
        
        return {
            width: 15, 
            height: 15,
            ...styles
        }
    }
}))(props => <CheckBoxIcon {...props} color='primary'/>)

export const UnCheckbox = withStyles({
    root: {
        width: 15, 
        height: 15
    }
})(props => <CheckBoxOutlineBlankIcon {...props} color='action'/>)

function StartItemOpen(props: Props) {
    const { used, refetch } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const [ updateSurveyStartUsed ] = useMutation<UpdateSurveyStartUsedVariables>(UPDATE_SURVEY_START_USED, {
        onCompleted: () => {
            refetch()
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function ChangeEvent(e: MouseEvent<HTMLButtonElement>) {
        updateSurveyStartUsed({
            variables: {
                survey_no,
                used: !used
            }
        })       
    }


    return (
        <MyButton 
            startIcon={used ? <Checkbox/> : <UnCheckbox/>}
            onClick={ChangeEvent}
        >
            {f({id: 'component.Survey.Create.Menu.StartItemUsed.label'})}
        </MyButton>    
    )
}

export default StartItemOpen