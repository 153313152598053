import { memo, useState, useEffect, useCallback, MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { DELETE_JUMP_EXAMPLE_COUNT_WITH_TEXT } from 'gql/jump_example_count'
import { DELETE_JUMP_POINT_WITH_TEXT } from 'gql/jump_point'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Kind, OnCloseKind } from './QuizKindSelect'

interface Props {
    survey_no: number;
    kind: Kind;
    value: boolean;
    open: boolean;
    refetch?: () => void;
    onCloseKind: OnCloseKind;

}

function QuizKindSelectComponent(props: Props) {
    const { survey_no, kind, value, open, refetch, onCloseKind } = props

    const { formatMessage: f } = useIntl()

    const [ confirm, setConfirm ] = useState(false)

    const [ deleteExampleCount ] = useMutation(DELETE_JUMP_EXAMPLE_COUNT_WITH_TEXT, {
        onCompleted: () => {
   
            onCloseKind()
            setConfirm(false)
            if (refetch) refetch()
        }
    })

    const [ deletePoint ] = useMutation(DELETE_JUMP_POINT_WITH_TEXT, {
        onCompleted: () => {
            onCloseKind()
            setConfirm(false)
            if (refetch) refetch()
        }
    })

    const handleOpenConfirm = useCallback((e: MouseEvent) => {
        e.stopPropagation()
        setConfirm(true)
    }, [])

    const handleCloseConfirm = useCallback((e: MouseEvent) => {
        e.stopPropagation()
        setConfirm(false)
    }, [])

    function handleRemove(e: MouseEvent) {
        e.stopPropagation()

        if (kind === Kind.EampleCount) {
            deleteExampleCount({
                variables: {
                    survey_no
                }
            })

            return
        } 

        if (kind === Kind.Point) {
            deletePoint({
                variables: {
                    survey_no
                }
            })
        } 
    }

    useEffect(() => {
        if (!open) setConfirm(false)
    }, [open])

    if (!value) return null

    return (
        <div>
        {
            !confirm ? (
                <Button color="secondary" size="small" variant="outlined" onClick={handleOpenConfirm}>
                    {f({id: 'component.Survey.Create.Menu.QuizKindRemove.button.removeLogic'})}
                </Button>
            ) : (
                <ButtonGroup size="small">
                    <Button color="secondary" onClick={handleRemove}>
                        {f({id: 'component.Survey.Create.Menu.QuizKindRemove.button.remove'})}
                    </Button>
                    <Button onClick={handleCloseConfirm}>
                        {f({id: 'component.Survey.Create.Menu.QuizKindRemove.button.close'})}
                    </Button>
                </ButtonGroup>
            )
        }
        </div>
    )
}

export default memo(QuizKindSelectComponent)