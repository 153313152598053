import { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { List } from '@material-ui/core'
import SurveyModuleLists from 'styled/Survey/Module/Lists'



function getHeight(breakpoint) {
    const screenHeight = window.screen.height

    return breakpoint === 'sm' ? `calc(100vh - ${screenHeight * .2}px)` : `calc(100vh - ${screenHeight * .2}px)` 
}

const useStyles = makeStyles(theme => ({
    modules: {
        maxHeight: '70%', 
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: getHeight('sm')
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: getHeight('xs')
        },
        '&.scroll::-webkit-scrollbar': {
            width: 0
        },
        '&.scroll::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
            outline: 0
        }
    }
}))

function ModuleListsComponent(props) {
    const { ListSubheader, moduleLists, onCreateModules } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <List 
            dense 
            subheader={
                <ListSubheader>
                    {f({id: 'component.Survey.Create.Menu.Component.subheader.modules'})}
                </ListSubheader>
            }
            className={`${classes.modules} scroll`}
        >
            <SurveyModuleLists moduleLists={moduleLists} onChange={onCreateModules}/>
        </List>
    )
}

export default memo(ModuleListsComponent)