import { memo } from 'react'
import { Props as ParentProps } from './Body'
import { useStyles as useTextItemStyles } from './TextItem'
import FileValueComponent from './FileValue'

interface Props {
    title: string;
    survey_question_no: number;
    answer: ParentProps['answer'];
    files: ParentProps['files'];
}

function FileItemComponent(props: Props) {
    const { title, survey_question_no, answer, files } = props

    const classesTextItem = useTextItemStyles({})

    const fileObj = files[survey_question_no]

    const { src, filename, survey_analysis_users_no } = fileObj ? fileObj[0] : { src: '', filename: '', survey_analysis_users_no: '' }
    const fullSrc = src ? `${process.env.REACT_APP_LISTO_CF}${src}` : ''

    let comment = ''

    const value = answer[`Q${survey_question_no}`]

    if (value) {
        comment = value.comment[survey_question_no] || ''
    }

    if (!src) return null

    return <div className={classesTextItem.root}><FileValueComponent title={title} src={fullSrc} survey_analysis_users_no={survey_analysis_users_no} filename={filename} comment={comment} /></div>
}

export default memo(FileItemComponent)
