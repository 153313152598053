import { memo, useCallback } from 'react'
import oc from 'open-color'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { Step } from 'gql/survey_analysis_summary'
import TableRow from '@material-ui/core/TableRow'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useStyles as useChildStyles } from './Item'
import { getImgRows } from './Items'
import TableHeadCellComponent from './TableHeadCell'
import TableHeadIdComponent from './TableHeadId'
import TableHeadCellValueComponent from './TableHeadCellValue'
import TableHeadCheckboxComponent from './TableHeadCheckbox'
import TableHeadIdStandardComponent from './TableHeadIdStandard'
import TableHeadPrivacyComponent from './TableHeadPrivacy'

export interface Props {
    survey_no: number;
    questions: SurveyQuestionModuleProps[];
    allChecked: boolean;
    checked: number[];
    rowsLen: number;
    filterLen: number;
    step: Step;
    date: string;
    refetch: () => void;
    columns: any;
    orderKey: string;
    orderValue: boolean;
    standardColumnIndex: number;
    onSelectAllClick: () => void;
    onRequestSort: () => void;
    onChangeStandardColumnKey: (column: string) => void;
}

export const isID  = ['id']
export const isOrder  = ['einterval', 'accesstime', 'ip']

export const isMultiChoice = ['_02', '_04', '_13']
export const isRankChoice = ['_17', '_18']
export const isRankDChoice = ['_11']
export const isMatrixChoice = ['_03', '_04', '_06', '_08', '_15']
export const isTextChoice = ['_09', '_10', '_20']

// 개인정보 관련 문항인지 판단 (선택형, 서술, 주소, 파일업로드)
const isPrivacyType = ['_01', '_02', '_09', '_10', '_19', '_20']

export const getWidth = (column: string) => {
    return (isID.includes(column) || isOrder.includes(column)) ? 120 : 180
}

export const useStyles = makeStyles({
    tableCell: {
        borderTop: `1px solid ${oc.gray[3]}`,
        borderRight: `1px solid ${oc.gray[3]}`,
        background: `${oc.gray[1]} !important`,
        '&:last-child': {
            borderRight: 'none'
        }
    },
    tableBaseCellSize: {
        width: getWidth('id')
    },
    tableCellSize: {
        width: getWidth('Q')
    },
    tableCellPrivacy: {
        position: 'relative'
    },
    tableCheckboxCellSize: {

    }
})

// 다중 선택은 한 필드에서 value를 보여준다.
export const getColumns = (columns: any, step: Step) => {
    const isNewField: string[] = []

    return columns.reduce((acc: any, c: any, i: number) => {
        const { field, headerName, __typename, isComment } = c

        // 진행중일때는 완료시각이 없다.
        if (Number(step) !== Step.End && field === 'einterval') return acc

        // 매트릭스형은 문항에 대한 코멘트라 단독 필드가 필요하다 (매트릭스가 아닌것은 필드가 필요없어서 패스)
        if ((!isMatrixChoice.includes(__typename) && !isRankDChoice.includes(__typename)) && isComment) return acc

        const fieldArray = field.split('-')

        // 문항, 문항-보기, 문항-보기-코멘트, 문항-코멘트로 되어있는 형식을 [문항] 형식으로 바꾼다. 
        if (isMultiChoice.includes(__typename) || (__typename === '_21' && fieldArray.length > 1) || isRankChoice.includes(__typename) || isRankDChoice.includes(__typename)) {     
            
            if (!(isRankDChoice.includes(__typename) && isComment)) fieldArray.pop()

            const newField = fieldArray.join('-')

            const headerNameArray = headerName[1].split('|*5%|')

            headerNameArray.pop()

            const newHeaderName = headerNameArray.join('|*5%|')

            if (isNewField.includes(newField)) return acc

            const newHeaderNameArray = headerName.map((c: string, i: number) => {
                if (i === 1) return newHeaderName

                return c
            })

            acc.push({
                ...c,
                field: newField,
                headerName: newHeaderNameArray
            })
            isNewField.push(newField)

            return acc            
        }

        acc.push(c)
        return acc
    }, [])
}

const changeStandardColumn = (rows: any, index: number) => {
    if (index === 0) return rows

    const otherColumn = rows.filter((c: any, i: number) => i !== index)

    return [rows[index]].concat(otherColumn)
}

function TableHeadComponent(props: Props) {
    const { survey_no, questions, allChecked, checked, rowsLen, filterLen, step, date, columns, orderKey, orderValue, standardColumnIndex, onSelectAllClick, onRequestSort, onChangeStandardColumnKey, refetch } = props

    const classes = useStyles()
    const classesChild = useChildStyles()

    const getOrderDirection = useCallback((field: string) => {
        const direction = field === orderKey ? orderValue : false

        return direction ? 'desc' : 'asc'
    }, [orderKey, orderValue])

    const imgRows = getImgRows('header', questions)

    // 기준 컬럼 순서 변경
    const newColumns = changeStandardColumn(columns, standardColumnIndex)

    const checkedLen = checked.length

    return (
        <TableRow>
            <TableHeadCheckboxComponent 
                allChecked={allChecked} 
                checkedLen={checkedLen} 
                rowsLen={rowsLen}
                onSelectAllClick={onSelectAllClick} 
            />
            {newColumns.map((column: any, i: number) => {
                const { field, headerName, __typename, isComment } = column

                const label = typeof headerName === 'string' ? headerName : headerName[1]
    
                //const tooltip = label.replace(/\n/g, ' ')
                const labelArray = label.split('|*5%|')

                if (isComment && labelArray.length > 1) labelArray.pop()

                const tooltip = labelArray.map((c: string) => c.replace(/\n/g, ' ').replace(/</g, "&lt;").replace(/>/g, "&gt;")).join('\n')

                let className = classes.tableCell
                className = (isID.includes(field) || isOrder.includes(field)) ? `${className} ${classes.tableBaseCellSize}` : `${className} ${classes.tableCellSize}`
                className = i === 0 ? `${className} ${classesChild.id}` : isPrivacyType.includes(__typename) ? `isPrivacy ${className} ${classes.tableCellPrivacy}` : className

                return (
                    <TableHeadCellComponent key={field} className={className}>
                        {
                            /isPrivacy/.test(className) && (
                                <TableHeadPrivacyComponent 
                                    survey_no={survey_no}
                                    field={field}
                                    tooltip={tooltip}
                                    labelArray={labelArray}
                                    __typename={__typename} 
                                    filterLen={filterLen} 
                                    checkedLen={checkedLen}
                                    step={step}
                                    date={date}
                                    refetch={refetch}
                                />
                            )
                        }
                        {
                            (isID.includes(field) || isOrder.includes(field))  ? (
                                <TableHeadIdComponent 
                                    key={field}
                                    field={field}
                                    labelArray={labelArray}
                                    orderKey={orderKey}
                                    getOrderDirection={getOrderDirection}
                                    onRequestSort={onRequestSort}
                                />
                            ) : (
                                <TableHeadCellValueComponent 
                                    key={field} 
                                    field={field}
                                    imgRows={imgRows}
                                    tooltip={tooltip} 
                                    labelArray={labelArray} 
                                    __typename={__typename} 
                                    isComment={isComment} 
                                />
                            )
                        }
                        {
                            i === 0 && <TableHeadIdStandardComponent columns={columns} index={standardColumnIndex} onChange={onChangeStandardColumnKey} />
                        }
                    </TableHeadCellComponent>
                )
            })}
        </TableRow>
    )
}

export default memo(TableHeadComponent)