import { memo } from 'react'
import { useIntl } from 'react-intl'
import { getModuleIcon } from 'utils/survey'
import { getRGBA } from 'utils/analysis'
import Typography from '@material-ui/core/Typography'
import Tooltip from 'styled/Custom/Tooltip'
import { defaultDesign } from 'container/Survey/Create/NewAnalysis/Filter'

interface Props {
    number?: number;
    __typename: string;
}

function NumberIconComponent(props: Props) {
    const { number, __typename } = props

    const { formatMessage: f } = useIntl()

    const icons = getModuleIcon(f)
    const icon = icons.find(c => c.type === __typename)

    const Icon = icon ? icon.icon : null
    const tooltip = icon ? icon.name : null

    const iconColor = getRGBA({ color: defaultDesign.question, opacity: .8 })
    const color = getRGBA({ color: defaultDesign.question, opacity: 1 })

    const text = __typename === '_ending' ? f({id: 'component.Survey.Create.Analysis.Summary.DataEnding.title'}) : `Q${number}.`
    if (!Icon) return null

    return (
        <>
        <Tooltip title={tooltip} isMobileClick={true}>
            <Icon fontSize="small" style={{ color: iconColor, width: 19, height: 19, position: 'absolute', left: 0, marginTop: 3 }}/>
        </Tooltip> 
        <Typography style={{ color, fontWeight: 700, paddingLeft: 25 }}>{text}</Typography>
        </>
       
    )
}

export default memo(NumberIconComponent)