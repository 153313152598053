/**
 *  고객 도메인명 변경
 */
import { memo, useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { getShareLink } from 'utils/survey'
import { parseGraphqlError } from 'utils'
import { parseHtmlIntl } from 'ts-utils'
import { getPlanStyle } from 'ts-utils/survey'
import { Levels } from 'ts-policy'
import { CREATE_SURVEY_MBOX_ARGS, SurveyMboxArgsProps } from "gql/survey_mbox_args"
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import withSurveyFetchData from 'hoc/Survey/FetchData'
import useTokenInfo from 'hooks/useTokenInfo'
import CustomArgsCopyComponent from './CustomArgsCopy'
import CustomArgsRemoveComponent from './CustomArgsRemove'
import CustomArgsInputComponent from './CustomArgsInput'

interface Props {
    survey_no: number;
    listoDomain: string;
    propsFetchData: {
        mboxArgs: SurveyMboxArgsProps;
        refetchMboxArgs: () => {}
    }
}

export type OnChange = (value: string) => void;
export type OnDelete = () => void;

const MyBox = withStyles(theme => ({
    root: {
        marginTop: 12,
        border: '1px solid #c4c4c4',
        padding: '10px 10px 10px 15px',
        borderRadius: 5,
        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            gap: 1
        },
        '& > div:last-child': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 5,
            marginTop: 15,
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 5,
            }
        },
    }
}))(Box)

const DomainTypography = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))(Typography)

export const CDButton = withStyles(theme => ({
    disabled: {
        border: `1px solid ${oc.gray[4]} !important`
    }
}))(Button)

// 특수 문자로 시작하지마라
const getIsError = (val: string | number) => {
    if (!val) return false

    // 시작과끝이 특수문자면...에러다
    if (/^(-|_|\.|,|!)/gi.test(String(val))) return true
    if (/(-|_|\.|,|!)$/gi.test(String(val))) return true

    return false
}

// 세이브 버튼을 마구 마구 누르면 통신 못하게 막을거다
let doSave = false
let doTimer: ReturnType<typeof setTimeout>

function CustomArgsComponent(props: Props) {
    const { listoDomain, survey_no, propsFetchData } = props
    const { mboxArgs, refetchMboxArgs } = propsFetchData
    const { custom_args } = mboxArgs

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const token = useTokenInfo()

    // 스탠다드 | 프리미엄 플랜만 사용 가능
    const isDo = token.levels === Levels.Level10 || token.levels === Levels.Level100

    const [ create ] = useMutation(CREATE_SURVEY_MBOX_ARGS, {
        onCompleted: () => {
            refetchMboxArgs()
            clearTimeout(doTimer)
            doTimer = setTimeout(() => {
                doSave = false
            }, 2000)
        },
        onError: (e) => {
            const error = parseGraphqlError(e)
            if (error === 'overlap') dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Share.CustomArgs.overlap'}), variant: 'error' }) 
            else dispatch({ type: ERRORPAGESHOW })
            clearTimeout(doTimer)
            doTimer = setTimeout(() => {
                doSave = false
            }, 2000)
        }
    })

    const shareLink = getShareLink({domain: listoDomain, args: ''})

    const url = shareLink.survey.custom

    // 오리지날 값이랑 같다면 저장할필요없디
    const [ newArgs, setNewArgs ] = useState<string>(custom_args)

    const handleChange: OnChange = useCallback(value => {
        setNewArgs(value)
    }, [])

    const handleSave = () => {
        if (doSave) return
        doSave = true
        create({
            variables: {
                survey_no,
                custom_args: newArgs
            }
        })
    }

    const handleDelete = useCallback(() => {
        if (doSave) return
        doSave = true
        create({
            variables: {
                survey_no,
                custom_args: ''
            }
        })
        setNewArgs('')
    }, [survey_no, create])

    const isDisabledSave = newArgs === custom_args
    const isError = getIsError(newArgs)

    useEffect(() => {
        return () => {
            doSave = false
            clearTimeout(doTimer)
        }
    }, [])

    return (
        <>
        <div style={{marginTop: 20}}>
            <Typography style={{marginTop: 20}} component="span">{f({id: 'component.Survey.Create.Share.CustomArgs.title.0'})}</Typography>
            <Typography style={getPlanStyle()} component="span">
                {f({id: 'component.Survey.Create.Share.CustomArgs.title.1'})}
            </Typography>
        </div>
       
        <ul style={{marginLeft: 15, marginTop: 5, color: oc.gray[7], fontSize: 13}}>
            <li>{f({id: 'component.Survey.Create.Share.CustomArgs.lists.0'})}</li>
            <li>{f({id: 'component.Survey.Create.Share.CustomArgs.lists.1'})}</li>
            <li>{parseHtmlIntl(f({id: 'component.Survey.Create.Share.CustomArgs.lists.2'}))}</li>
            <li style={{fontWeight: 700}}>{f({id: 'component.Survey.Create.Share.CustomArgs.lists.3'})}</li>
        </ul>
        <MyBox>
            <div>
                <DomainTypography>{url}</DomainTypography>
                <CustomArgsInputComponent originArgs={custom_args} args={newArgs} isDo={isDo} error={isError} onChange={handleChange}/>
                <CustomArgsCopyComponent isDisabledSave={isDisabledSave}  isDo={isDo} isError={isError} url={url} custom_args={custom_args}/>
            </div>
            <div>
                <div>
                    <CDButton color="primary" variant='outlined' size="small"  disabled={isDisabledSave || isError} onClick={handleSave}>{f({id: 'component.Survey.Create.Share.CustomArgs.button.0'})}</CDButton>
                    <CustomArgsRemoveComponent disabled={!custom_args} onDelete={handleDelete}/>
                </div>
            </div>
        </MyBox>
        </>
    )   
}

const fetchArgs = {
    mboxArgs: true,
    _fetchPolicy: {
        mboxArgs: 'cache-first'
    }
}
export default withSurveyFetchData('edit')(fetchArgs)(false)(memo(CustomArgsComponent))


