import { useCallback, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { UPDATE_SURVEY_QUESTION_SUMMARY_QUESTION_OPEN, UPDATE_SURVEY_QUESTION_SUMMARY_COMMENT_OPEN } from 'gql/survey_question'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import DataItemComponent from './DataItem'
import DataEndingComponent from './DataEnding'

function DataFunctionComponent(props) {
    const { survey_no, common, length, i, onChangeEnd, onChangeOpen } = props
    const { question_type } = common

    const dispatch = useDispatch()
    
    const [ updateSurveyQuestionSummaryQuestionOpen ] = useMutation(UPDATE_SURVEY_QUESTION_SUMMARY_QUESTION_OPEN, {
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateSurveyQuestionSummaryCommentOpen ] = useMutation(UPDATE_SURVEY_QUESTION_SUMMARY_COMMENT_OPEN, {
        onError: (e) => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleUpdateSurveyQuestionSummaryQuestionOpen = useCallback((props) => {
        const { survey_question_no, summary_question_open } = props
        const variables = { survey_no, survey_question_no, summary_question_open }

        updateSurveyQuestionSummaryQuestionOpen({ variables })
    }, [survey_no, updateSurveyQuestionSummaryQuestionOpen])

    const handleUpdateSurveyQuestionSummaryCommentOpen = useCallback((props) => {
        const { survey_question_no, summary_comment_open } = props
        const variables = { survey_no, survey_question_no, summary_comment_open }

        updateSurveyQuestionSummaryCommentOpen({ variables })
        }, [survey_no, updateSurveyQuestionSummaryCommentOpen])

    const handleShowBackdrop = useCallback(async() => {
        await dispatch({type: BACKDROPSHOW})
        return true
    }, [dispatch])

    const handleHideBackdrop = useCallback(async() => {
        await dispatch({type: BACKDROPHIDE})
        return true
    }, [dispatch])

    useEffect(() => {
        if (i === (length - 1)) {
            onChangeEnd()
        }
    }, [i, length, onChangeEnd])

    return (
        <>
        {
            question_type === 'ending' ? (
                <DataEndingComponent 
                    onShowBackdrop={handleShowBackdrop}
                    onHideBackdrop={handleHideBackdrop}
                    onChangeOpen={onChangeOpen}
                    {...props}
                /> 
            ) : (
                <DataItemComponent
                    onUpdateSurveyQuestionSummaryQuestionOpen={handleUpdateSurveyQuestionSummaryQuestionOpen}
                    onUpdateSurveyQuestionSummaryCommentOpen={handleUpdateSurveyQuestionSummaryCommentOpen}
                    onShowBackdrop={handleShowBackdrop}
                    onHideBackdrop={handleHideBackdrop}
                    question_type={question_type}
                    onChangeOpen={onChangeOpen}
                    {...props}
                />
            )
        }
        </>
    )
}

export default DataFunctionComponent