import { memo } from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { useIntl } from 'react-intl'

function EditPopComponent(props) {
    const { survey_designs_no, template, onEdit, onCopy, onRemove } = props

    const { formatMessage: f } = useIntl()

    return (
        <List dense>
            {
                (survey_designs_no && !template) ? (
                    <>
                        <ListItem button onClick={() => onEdit(survey_designs_no, template)}>
                            <ListItemText primary={f({id: 'component.Survey.Create.Design.My.EditPop.edit'})}/>
                        </ListItem>
                        <ListItem button onClick={() => onCopy(survey_designs_no)}>
                            <ListItemText primary={f({id: 'component.Survey.Create.Design.My.EditPop.copy'})}/>
                        </ListItem>
                        <ListItem button onClick={() => onRemove(survey_designs_no)}>
                            <ListItemText primary={f({id: 'component.Survey.Create.Design.My.EditPop.remove'})}/>
                        </ListItem>
                    </>
                ) : (survey_designs_no && template) ? (
                    <ListItem button onClick={() => onEdit(survey_designs_no, template)}>
                        <ListItemText primary={f({id: 'component.Survey.Create.Design.My.EditPop.edit'})}/>
                    </ListItem>
                ) : null
            }
        </List>
    )
}

export default memo(EditPopComponent)