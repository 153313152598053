/**
 * 공유 레포트 편집으로 가기
 */
import { useState, useCallback, memo } from 'react'
import { useIntl } from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SurveyCreateAnalysisSummaryEditPopContainer from 'container/Survey/Create/NewAnalysis/Summary/EditPop'

interface Props {
    onClose: () => void;
}

function ReportIconComponent(props: Props) {
    const { onClose } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    const handleClick = () => {
        setOpen(true)
        onClose()
    }
    
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
        <MenuItem dense onClick={handleClick}>
            <ListItemIcon>
                <AssessmentIcon color="action"/>                
            </ListItemIcon>
            <ListItemText primary={f({id: "component.Survey.Create.Analysis.Summary.ReportIcon.primary"})}/>
        </MenuItem>
        <SurveyCreateAnalysisSummaryEditPopContainer open={open} onClose={handleClose}/>
        </>
    )
}

export default memo(ReportIconComponent)

