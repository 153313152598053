import Popover, { PopoverOrigin } from "@material-ui/core/Popover"
import { ColorPickerContainer } from 'container'


interface Props {
    picker: {
        anchor: HTMLButtonElement;
        name: string;
        color: string;
    }
    onChange: (name: string) => void;
    onClose: () => void
}

const anchorOrigin:PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center'
}

const transformOrigin:PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
}

function PopoverComponent(props: Props) {
    const { picker, onChange, onClose } = props

    const { anchor, name, color } = picker
 
    return (
  
            <Popover
                id='copy-popover'
                open={Boolean(anchor)}
                anchorEl={anchor}
                keepMounted
                onClose={onClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <ColorPickerContainer type="swatches" color={color} onChange={onChange(name)}/>
            </Popover>
  
    )
}

export default PopoverComponent