/**
 * 주소 복사
 */

import { memo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import clipboard from 'copy-to-clipboard'
import { SurveyMboxArgsProps } from "gql/survey_mbox_args"
import Button from "@material-ui/core/Button"

interface Props {
    isDisabledSave: boolean;
    isError: boolean;
    isDo: boolean;
    url: string;
    custom_args: SurveyMboxArgsProps['custom_args'];
}

let timer: ReturnType<typeof setTimeout>
function CustomArgsComponent(props: Props) {
    const { isDisabledSave, isError, isDo, url, custom_args } = props

    const { formatMessage: f } = useIntl()

    const [ copy, setCopy ] = useState(false)

    const handleCopy = () => {
        clipboard(`${url}${custom_args}`)
        setCopy(true)
    }

    useEffect(() => {
        //복사 완료후 링크 복사로 다시 돌린다.
        if (copy) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                setCopy(false)
            }, 1000)
        }
    }, [copy])

    const buttonName = f({id: `styled.Survey.CopyLink.button.${!copy ? 0 : 1}`})

    return <Button color="primary" variant="text" size="small" disabled={!isDo || !isDisabledSave || isError || !custom_args} onClick={handleCopy} style={{whiteSpace: 'pre'}}>{buttonName}</Button>
}

export default memo(CustomArgsComponent)