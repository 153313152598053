import { useState, useCallback, useEffect } from 'react'
import withFetchQuestionInPage from 'hoc/Survey/FetchQuestionInPage'
import withBreakPoints from 'hoc/BreakPoints'
import DeskTop from './DeskTop'
import Mobile from './Mobile'
import Item from './Item'


const Component = (props) => {
    const { 
        breakpoints,
        currentPage, 
        pages,
        position, 
        defaults,
        onChangeMovePage,
        onChangeMovePosition,
        onSaveMovePage,
        onClosePage,
        propsFetchQuestionInPage: withs 
    } = props

    const { md, xs } = breakpoints

    const [ method, setMethod ] = useState('move') // move, copy

    // 현제페이지를 제외하고 다른페이지가 있는지 체크
    const isExcludeCurrentPage = withs.pages.filter(c => c.survey_page_no !== currentPage).length > 0

    const handleChangeMethod = useCallback((val) => {
        setMethod(val)
    }, [])

    useEffect(() => {
        // 이동인데 이안에 문항 모두 복사 선택하면 자동으로 앞으로 옮겨준다.
        if (method === 'move' && position === 2) {
            onChangeMovePosition(0)
        }
    }, [position, method, onChangeMovePosition])
    // DB에서 가져온닥 일시적으로 페이지길이가 0일때가있다.  
    if (withs.pages.length === 0) return null

    const self  = pages.find(c => c.survey_page_no === currentPage)

    if (!self) return null

    return (
        <>
        {
            md ? (
                <Mobile xs={xs} method={method} isExcludeCurrentPage={isExcludeCurrentPage} onSave={onSaveMovePage} onClose={onClosePage}>
                    <Item 
                        method={method}
                        self={self}
                        position={position}
                        defaults={defaults}
                        onChangeMovePage={onChangeMovePage}
                        onChangeMovePosition={onChangeMovePosition}
                        onChangeMethod={handleChangeMethod}
                        withs={withs}
                        isExcludeCurrentPage={isExcludeCurrentPage}
                    />
                </Mobile>
            ) : (
                <DeskTop method={method} isExcludeCurrentPage={isExcludeCurrentPage} onSave={onSaveMovePage} onClose={onClosePage}>
                    <Item 
                        method={method}
                        self={self}
                        position={position}
                        defaults={defaults}
                        onChangeMovePage={onChangeMovePage}
                        onChangeMovePosition={onChangeMovePosition}
                        onChangeMethod={handleChangeMethod}
                        withs={withs}
                        isExcludeCurrentPage={isExcludeCurrentPage}
                    />
                </DeskTop>
            )
        }
        </>
    )
}

export default withFetchQuestionInPage()(withBreakPoints(Component))