import { Paper, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getBackgroundImageOpacity } from 'utils/survey'
import { scrollerObj as _scrollerObj } from 'utils/survey'
import SurveyCreateAnalysisSummaryEditDataBoxChildrenComponent from './EditDataBoxChildren'

const useStyles = makeStyles(theme => ({
    root: props => {
        const { background_color, background_cover, background_cover_opacity } = props

        return {
            height: 'calc(100vh - 110px)',
            overflow: 'auto',
            backgroundColor: `rgba(${background_color})`,
            backgroundSize: 'cover',
            [theme.breakpoints.down('sm')]: {
                height: 'calc(100vh - 200px)',
    
            },
            backgroundImage: `${getBackgroundImageOpacity({
                background_opacity: background_cover_opacity, 
                background_image: background_cover
            })}`,
            backgroundAttachment: 'scroll',
            paddingTop: 20,
            paddingBottom: 20
        }
    }
}))

export const scrollerObj = _scrollerObj()

const Component = (props) => {
    const { design, backgroundCoverOpacity, children } = props

    const {  background_color, background_cover } = design

    const classes = useStyles({ background_color, background_cover, background_cover_opacity: backgroundCoverOpacity })

    return (
        <Paper id={scrollerObj.id} elevation={7} className={classes.root}>
            <Container maxWidth="md">
                {
                    // dummy: design 값이 바뀔때만 재런더링을 해여하는데 design은 contextapi를 사용한다. 자식컴포넌트 react.memo 용으로 넣어놈..
                    <SurveyCreateAnalysisSummaryEditDataBoxChildrenComponent child={children} dummy={design}/>
                }
            </Container>
        </Paper>
    )
}

export default Component