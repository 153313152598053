/**
 * 응답데이터 전체 다운로드
 */
import { memo, useState, MouseEvent, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import Popover, { PopoverOrigin } from '@material-ui/core/Popover'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import GetAppIcon from '@material-ui/icons/GetApp'
import Tooltip from "styled/Custom/Tooltip"
import { IconBox } from "../Buttons"
import { Props as ParentProps } from "./Buttons"
import ChoiceDataDownload from './ChoiceDataDownload'
import AllDataDownload from './AllDataDownload'

export interface Props extends Omit<ParentProps, 'breakpoints' | 'refetchQuestions' | 'refetchEndings'> {
    xs: boolean;
}

function DataDownloadsComponent(props: Props) {
    const { survey_no, questions, conditionsPlay, step, date, xs } = props
    
    const { formatMessage: f } = useIntl()

    const searchState = useSelector((states: RootState) => states.analysisRowsSearch)
    const { search } = searchState

    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null)

    // 모달 open
    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    // 모달 close
    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const open = Boolean(anchorEl)
    const id = open ? `data-downloads-popover` : undefined

    const anchorOrigin: PopoverOrigin = xs ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'center' }
    const transformOrigin: PopoverOrigin = xs ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'center' }
    
    return (
        <>
        {
            xs ? (
                <BottomNavigationAction showLabel label={f({id: "component.Survey.Create.Analysis.Rows.DataDownloads.button.title"})} icon={<GetAppIcon />} onClick={handleOpen}/>
            ) : (
                <div style={{display: 'flex', gap: 10}}>
                    <Tooltip title={f({id: "component.Survey.Create.Analysis.Rows.DataDownloads.button.title"})} placement="top">
                        <IconBox onClick={handleOpen}>
                            <GetAppIcon fontSize="small"/>
                        </IconBox>
                    </Tooltip>
                </div>
            )
        }
       
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            keepMounted
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <ChoiceDataDownload open={open} questions={questions} onClose={handleClose} />
            {
                search === '' && (
                    <AllDataDownload 
                        survey_no={survey_no} 
                        questions={questions} 
                        conditionsPlay={conditionsPlay} 
                        step={step} 
                        date={date}
                        onClose={handleClose}
                    />
                )
            }
           
        </Popover>
        </>
    )
}

export default memo(DataDownloadsComponent)