import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { validation } from 'utils'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { SurveyEditCommentStyled } from 'styled/Input'
import useTextFiled, { Props as UseProps } from '../useChangeTextField'

interface Props {
    branding_src: string;
    branding_link: string;
    onChange: UseProps['onChange'];
}

const MyBox = withStyles({
    root: {
        marginTop: 5
    }
})(Box)


function BrandingSrcComponent(props: Props) {
    const { branding_src, branding_link, onChange } = props

    const [ value, setValue ] = useTextFiled({type: 'branding_link', value: branding_link, onChange})

    const { formatMessage: f } = useIntl()

    if (!branding_src) return null

    return (
        <MyBox>
            <SurveyEditCommentStyled
                value={value || ''} 
                inputProps={{
                    maxLength: getMaxlength('component.Survey.Create.Thanks.Edit.ButtonEnding.button_name'),
                    placeholder: 'https://...'
                }}
                name="branding_link"
                error={value ? !validation.url(value) : false}
                helper={f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.button_link_helper'})}
                onChange={setValue}
            />
        </MyBox>
    )
}

export default BrandingSrcComponent