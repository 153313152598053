import { memo, useEffect, useState, useContext, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { useIntl } from 'react-intl'
import ReactFlow, { OnLoadFunc, EdgeTypesType, Background, Controls, useZoomPanHelper, NodeTypesType, Elements } from 'react-flow-renderer'
import Box from '@material-ui/core/Box'
import ParseNodes from './ParseNodes'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { DiagramNodeIDContext } from './Context'
import getLayout from './getLayout'
import FloatingNode from './FloatingNode'
import FloatingEdge from './FloatingEdge'
import FloatingConnectionLine from './FloatingConnectionLine'
import MyFunctionsComponent from './MyFunctions'
import HelpComponent from './Help'
import ResetComponent from './Reset'
import MiniMapComponent from './MiniMap'

interface Props {
    instance: Elements | null;
    onChangeInstance: (a:any) => void;
    onNodeDragStop: () => void;
}

export type OnChangeElements = () => void

const edgeTypes: EdgeTypesType = {
    floating: FloatingEdge
}

const nodeTypes: NodeTypesType = {
    floating: FloatingNode
}

const UncontrolledDiagram = (props: Props) => {
    const { instance, onChangeInstance, onNodeDragStop } = props

    const { formatMessage: f } = useIntl()

    const { questionsKeyQuestionNo, mbox, endings, start } = useContext(Context)
    const { nodeID } = useContext(DiagramNodeIDContext)

    const { method, survey_no } = mbox

    const jump = useSelector((state: RootState) => state.surveyCreateLogicJump)
    const { rows } = jump

    // 파싱
    const parseElements = useCallback(() => {
        return getLayout(ParseNodes(f, rows, endings, questionsKeyQuestionNo, nodeID, method, start))
    }, [f, rows, endings, questionsKeyQuestionNo, nodeID, method, start])

    // element 파싱해서 state에 닫는다
    const handleChangeElements = useCallback(() => {
        setElements(parseElements())
    }, [parseElements])

    const [ elements, setElements ] = useState(parseElements())

    const { transform } = useZoomPanHelper()

    // 로드시 해당 인스턴스 저장 및 줌 초기값 설정
    const handleLoad:OnLoadFunc<any> = useCallback((reactFlowInstance) => {
        setTimeout(() => {
            // 저장된 값이 없다면 fitview 실행
            if (!instance) reactFlowInstance.fitView({ minZoom: .1, maxZoom: 1 }, 1)
        }, 100)

        onChangeInstance(reactFlowInstance)
    }, [instance, onChangeInstance])

    // 초기시 기존 플로우 저장된값 셋팅
    useEffect(() => {
        const restoreFlow = async () => {      
            if (instance) {
                const [x = 0, y = 0] = instance.position
                setElements(instance.elements || [])
                transform({ x, y, zoom: instance.zoom || 0 })
            }
        }
      
        restoreFlow()
    }, [instance, transform])

    useEffect(() => {
        handleChangeElements()
    }, [handleChangeElements])

    return (
        <Box style={{ height: '100%' }}>
            <MyFunctionsComponent/>
            <HelpComponent/>
            <ResetComponent survey_no={survey_no} onChangeElements={handleChangeElements}/>
            <ReactFlow 
                elements={elements} 
                edgeTypes={edgeTypes}
                nodeTypes={nodeTypes}
                minZoom={.1}
                connectionLineComponent={FloatingConnectionLine}
                onNodeDragStop={onNodeDragStop}
                onLoad={handleLoad}
            >
                <Background/>
                <Controls
                    onZoomIn={onNodeDragStop}
                    onZoomOut={onNodeDragStop}
                />
                <MiniMapComponent/>
            </ReactFlow>
 
        </Box>
    )
}

export default memo(UncontrolledDiagram)