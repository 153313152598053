import { memo, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { getEndingMssage } from 'ts-utils/survey'
import { SurveyEndingProps } from 'gql/survey_ending'
import { SHOW as URLSHOW } from 'reducer/survey/create/menu/ending/redirect'
import oc from 'open-color'
import { Draggable } from 'react-beautiful-dnd'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar, { AvatarProps } from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import EndingsItemKindComponent from './EndingsItemKind'
import EndingsItemMoreComponent from './EndingsItemMore'
import { defaultColor } from './Items'

interface Props {
    survey_no: number;
    len: number;
    row: SurveyEndingProps;
    i: number;
    onChangeContent: (a:string, b:object) => void;
}

export type OnChange = (newKind:SurveyEndingProps['kind']) => void;

export type Item = {
    content: string;
    redirect: string;
}

const useStyles = makeStyles({
    mybox: (props: { len: number }) => {
        const { len } = props

        const hoverObj = len > 1 ? { 
            '& .drag': {
                opacity: 1
            }
        } : {
            '& .drag': {
                opacity: 0
            }
        }

        return {
            transition: 'all .3s',
            '& .drag': {
                transition: 'all .3s',
                position: 'absolute',
                left: -1,
                opacity: 0,
                width: 18, 
                marginRight: 3,
                color: oc.gray[6]
            },
            '& .title': {
                display: 'flex'
            },
            '& .short-title': {
                display: 'none'
            },
            '&:hover': hoverObj
        }
        
    }
})

export const MyListItem = withStyles(theme => ({
    container: {   
        '& .more': {
            color: oc.gray[7],
            cursor: 'pointer',
            marginRight: 5,
        }
    }
}))(ListItem)

interface MyAvatarProps extends AvatarProps {
    style?: object;
    _is?: number;
}

export const MyAvatar = withStyles(theme => ({
    root: ({ style={}, _is=0 }: MyAvatarProps) => {
        return {
            width: 16, 
            height: 16, 
            fontSize: 11, 
            background: _is ? defaultColor.iconColorSelected : defaultColor.iconColor,
            borderRadius: '100%',
            ...style
        }
    }
}))(props => <Avatar variant="rounded" {...props}/>)

export const MyListItemSecondaryAction = withStyles({
    root: {   
        display: 'flex', 
        alignItems: 'center'
    }
})(ListItemSecondaryAction)

export function MyTooltip(props: {img_src: string, message: string, placement?:string, children: ReactNode}) {
    const { img_src, message, placement, children } = props

    const _title = img_src ? `<img src=${img_src} style="max-width: 250px; border-radius: 3px;" alt=""/><br />${message}` : message

    return (
        <Tooltip title={_title} placement={ placement || "right-end"}>
            {children}
        </Tooltip>
    )
}

function EndingsComponent(props: Props) {
    const { survey_no, len, row, i, onChangeContent } = props
    const { survey_ending_no, label, message, img_src, indexs, kind, redirect_url, redirect_label } = row

    const classes = useStyles({ len })

    const tooltipMsg = getEndingMssage(kind, message, redirect_url)

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const item: Item = {
        content: f({id: 'component.Survey.Create.Menu.EndingsItemKind.column.content'}),
        redirect: f({id: 'component.Survey.Create.Menu.EndingsItemKind.column.redirect'})
    }

    // menuItem click
    const handleChange = (newKind:SurveyEndingProps['kind']) => {
        switch (newKind) {
            case 'content':
                onChangeContent('thanks-end', { survey_ending_no })
                break
            case 'redirect':
                dispatch({ type: URLSHOW, survey_no, survey_ending_no, indexs, redirect_url, redirect_label })
                break
            default:
        }
    }

    const id = `endings-item-${survey_ending_no}`
    return (  
        <Draggable draggableId={id} index={i}>
        {(provided) => {
            return (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Box className={classes.mybox}>
                        <div  {...provided.dragHandleProps}>
                        <MyListItem button onClick={() => handleChange(kind)}>
                            <DragIndicatorIcon color='action' className='drag'/>
                            <ListItemText primary={item[kind]}/>
                            <ListItemAvatar style={{marginRight: 28}}>
                                <MyTooltip message={tooltipMsg} img_src={img_src} placement="top-start">
                                    <span>
                                        <MyAvatar _is={(tooltipMsg || img_src) ? 1 : 0}>{(indexs)}</MyAvatar>
                                    </span>
                                </MyTooltip>
                            </ListItemAvatar>
                            <MyListItemSecondaryAction>
                                <EndingsItemKindComponent
                                    item={item}
                                    kind={kind}
                                    onChange={handleChange}
                                />
                                <EndingsItemMoreComponent survey_ending_no={survey_ending_no} label={label}/>
                            </MyListItemSecondaryAction>
                        </MyListItem>
                        </div>
                    </Box>
                </div>
            )
        }}
        </Draggable>
    )
}

export default memo(EndingsComponent)