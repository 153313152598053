import styled from 'styled-components'
import { getShareLink } from 'utils/survey'

const IframeStyled = styled.iframe`
    position: absolute;
    visibility: visible;
    width: 100%;
    height: 100%;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s;
    background-color: white;
    // box-shadow: 1px 1px 2px #e9ecef, 0 0 25px #868e96, 0 0 5px #e9ecef;
    overflow-y: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
`

export default (props) => {
    const { args } = props
    
    const shareLink = getShareLink({args})
    const src = shareLink.survey.preview

    return (
        <>
        <IframeStyled src={src}/>
        </>
    )
}