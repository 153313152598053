import { memo } from 'react'
import { OnChangePoint } from './Item'
import { MyTextField } from './InputAllPoint'
import { Point } from './Component'
import { min, max, isError } from './ButtonSave'

interface Props {
    point: Point;
    onChange: OnChangePoint
}

function InputMemoComponent(props: Props) {
    const { point, onChange } = props

    const error = isError(min, max, point)

    return (
        <MyTextField error={error} value={point} styles={{ width: '100%' }} onChange={onChange}/>
    )
}

export default memo(InputMemoComponent)