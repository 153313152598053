import { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { getDownloadTitle } from 'utils/analysis'
import { parseHtmlIntl } from 'ts-utils'
import { componentType } from 'utils/analysis'
import oc from 'open-color'
import { useIntl } from 'react-intl'
import { CSVLink } from 'react-csv'
import { Workbook } from 'exceljs'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { DialogActions, DialogContent, DialogContentText, List, ListItem, ListItemText, ListItemSecondaryAction, Button, ButtonGroup, Typography, IconButton, CircularProgress, Select, MenuItem } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Help as HelpIcon } from '@material-ui/icons'
import Tooltip from 'styled/Custom/Tooltip'
import useSurveyTitle from 'hooks/useSurveyTitle'

export const useStyles = makeStyles({
    list: {
        border: `1px solid ${oc.gray[3]}`, 
        borderRadius: 5,
        marginTop: 20
    },
    button: {
        width: 60,
        whiteSpace: 'nowrap'
    },
    buttonBox: {
        position: 'relative'
    },
    circul: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
})

export const ButtonTypography = withStyles({
    root: {
        fontSize: 13
    }
})(props => <Typography {...props} variant="button"/>)

export const MyDialogContentText = withStyles({
    root: {
        fontSize: 15 
    }
})(DialogContentText)

export const MyTip = withStyles({
    root: {
        fontSize: 13
    }
})(props => <Typography {...props} color="secondary" variant="body2"/>)

export const MyListItemText = withStyles({
    root: {
        color: oc.gray[7]
    }
})(ListItemText)

function replaceNumber(val) {
    if (val === '' || val === null) return ''

    // 숫자이면, 숫자 문자이면 문자
    if (!Number.isNaN(Number(val))) {
        return Number(val)
    }

    return String(val)
}

const isScaleQuestion = ['_05', '_06', '_07', '_08', '_14', '_15']

// 파일업로드 하이퍼링크 작업
export const getHyperlink = (key, value) => {
    if (key.search(/COMMENT/) === -1) {
        if (typeof value === 'object') {
            if (value[2] === '_19') {
                if (value[0] && value[1]) {
                    return `${process.env.REACT_APP_ADMIN}/file/download?key=${value[0]}`
                }
            }
        }
    }

    return ''
}

// csv에서 = 들어가면 홑따옴표 붙인다
const ignoreCsvFormula = (str) => {
    const newStr = /^(=|-)/.test(str) ? `"${str}` : str

    // 0으로 시작하느 숫자일경우에 0이 나타나게 처리
    return newStr
}

// csv, excel 포멧에 맞게 변경하는 class
export class DownloadFormat {
    set reference(props) {
        const { columns, answers, answerType } = props

        this.columns = cloneDeep(columns)
        this.answers = cloneDeep(answers)
        this.answerType = answerType
    }

    // key값이 Q로 시작하면 질문문항, 그외는 응답자 정보값(ip/email, 시작시간, 완료타임)
    getValue(props) {
        const { key, value, isChangeBr=false, kind } = props
        // 질문내용이면 Q로 시작, 아니면 (ip, wdate, einterval)
        // 내용보가 타입이 숫자일경우 0, 아니면 1
        // isChangeBr => 엔터값 ' ' 으로 치환 필요할경우  true
        let val = ''
        if (key.search(/Q/g) === 0 || key === 'ending' || key === 'point') {
            const [ number, str, __typename ] = value
            switch(this.answerType) {
                case 0:
                    if (isScaleQuestion.includes(__typename)) {
                        val = replaceNumber(str)
                    } else {
                        val = String(str)
                    }
                    break
                case 1:
                    if (key.search(/comment/) > -1) {
                        if (kind === 'answers') val = String(str)
                        else val = number
                    }
                    else if (isScaleQuestion.includes(__typename)) {
                        val = replaceNumber(number)
                    }
                    else if (componentType[__typename] === 'text' || componentType[__typename] === 'file') {
                        val = String(str)
                    } 
                    else {
                        val = String(number)
                    }
                    break
                default:
                    if (key.search(/comment/) > -1) {
                        if (kind === 'answers') val = String(str)
                        else val = `${number.replace(' [COMMENT]', '')}. ${str}`
                    }
                    else if (__typename  === '_05' || __typename  === '_06') {
                        val = replaceNumber(str)
                    }
                    else if (__typename  === '_07' || __typename  === '_08') {
                        val = number ? `${number} - ${str}` : ''
                    }
                    else if (componentType[__typename] === 'text' || componentType[__typename] === 'file') {
                        val = String(str)
                    }
                    else if (key === 'ending') { // 답변 및 완료글, 포인트 컬럼
                        if (kind === 'answers') val = number ? `${number}. ${str}` : ''
                        else val = String(str)
                    }
                    else if (key === 'point') { // 답변 및 완료글, 포인트 컬럼
                        val = String(str)
                    }
                    else { // 모두 표기
                       val = number ? `${number}. ${str}` : ''
                    }
                    break
            }
        } else {
            val = value
        }

        if (kind === 'columns') val = val.replaceAll('|*5%|', '-')
        
        // typeof string 이며 값이 있을경우에만 치환
        return (isChangeBr && typeof val === 'string' && val) ? val.replace(/\n/g, ' ') : val
    }

    makeCSV() {
        const getData = (type, data) => {
            const [a, b] = data 

            const reg = /,/g

            if (type === 'column') {
                if (typeof b === 'string') data[1] = String(b || "").replace(reg, " ")
            } else {
                if (typeof a === 'string') data[0] = String(a || "").replace(reg, " ")
                if (typeof b === 'string') data[1] = String(b || "").replace(reg, " ")
            }

            return data
        }

        // 컬럼갑 나열
        const rowsColumns = this.columns.map(column => {
            const { field, headerName } = column

            const val = this.getValue({key: field, value: typeof headerName !== 'object' ? headerName : getData('column', headerName), isChangeBr: true, kind: 'columns'})

            return ignoreCsvFormula(val)
        })

        // 답변값은 sort 기준으로 재정령

        // 답변값 나열
        const rowsAnswers = this.answers.reduce((acc, answer, i) => {
            const row = Object.keys(answer).map((key, j) => {
                const value = answer[key]

                const text = this.getValue({key, value: typeof value !== 'object' ? value : getData('answer', value), isChangeBr: true, kind: 'answers'})

                const hyperlink = getHyperlink(key, value)

                if (hyperlink) return `=HYPERLINK(""${hyperlink}"",""${text}"")`

                return ignoreCsvFormula(text)
            })

            acc[i] = row

            return acc
        }, [])

        // 첫번째 ID 행은 삭제한다 (안보여줌)
        return [rowsColumns, ...rowsAnswers]
    }

    async makeEXCEL(filename) {
        return new Promise(async(resolve) => {
            const zip = new JSZip()

            const workbook = new Workbook()
            const sheet = workbook.addWorksheet('')
    
            const columns = this.columns.map(column => {
                const { field, headerName } = column
                // 질문내용이면 Q로 시작, 아니면 (ip, wdate, einterval)
                return this.getValue({key: field, value: headerName, isChangeBr: true, kind: 'columns'})
            })
    
            const answers = this.answers.map(columns => {
                return  Object.keys(columns).map((key, i) => {
                
                    const value = columns[key]

                    const text = this.getValue({key, value, isChangeBr: true, kind: 'answers'})

                    const hyperlink = getHyperlink(key, value)

                    if (hyperlink) return { text, hyperlink }

                    // 답변값 구하기
                    return text
                    
                })
            })
    
            const firstRows = sheet.addRow(columns)
            firstRows.font = { bold: true }
            sheet.addRows(answers)
    
            /*
            sheet.eachRow(function(row, rowNumber){
                row.eachCell( function(cell, colNumber){
                    if(cell.value && cell.value.hyperlink)
                        row.getCell(colNumber).font = {color: {argb: "004e47cc"}};
                });
            });
            */
      
            const mimeType = { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
    
            const buffer = await workbook.xlsx.writeBuffer(); 
            const blob = new Blob([buffer], mimeType); 
    
            const name = `${filename}.xlsx`
            zip.file(name, blob)
    
            const data = await zip.file(name).async('blob')
    
            FileSaver.saveAs(data, name)
    
            resolve(1)
        })
       
    }
}

const downloadFormat = new DownloadFormat()

const initialState = {
    fileType: 'csv', // csv, xlsx
    answerType: 0 // str, number, all (내용표기형식)
}

const Component = (props) => {
    const { data, onClose } = props
    const { columns, answers } = data

    const [ ctitle ] = useSurveyTitle()

    const title = getDownloadTitle({ title: ctitle, type: 'Rows' })

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ fileType, setFileType ] = useState(initialState.fileType) // answer count
    const [ answerType, setAnswerType ] = useState(initialState.answerType) // wdate comment
    const [ rowsCSV, setRowsCSV ] = useState([])

    const [ loading, setLoading ] = useState(false)

    const classes = useStyles()

    const handleChangeAnswerType = (e) => {
        setAnswerType(e.target.value)        
    }

    const handleDownoadExcel = async(filename) => {
        setLoading(true)

        setTimeout(async () => {
            downloadFormat.reference = { columns, answers, answerType }
            
            await downloadFormat.makeEXCEL(filename)

            setLoading(false)
        }, 300)
    }

    useEffect(() => {
        const Do = async() => {

            setLoading(true)

            setTimeout(async () => {
                downloadFormat.reference = { columns, answers, answerType }
                
                await setRowsCSV(downloadFormat.makeCSV())

                setLoading(false)
            }, 300)
        }

        if (fileType === 'csv') Do()

    }, [columns, answers, answerType, fileType, dispatch])

    // 응답값이 없으면 다운로드 안준다
    const disabled = answers.length === 0

    return (
        <>
            <DialogContent>
                <MyDialogContentText>
                    {parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.contentText'}))}
                </MyDialogContentText>
                <List className={classes.list}>
                    <ListItem>
                        <MyListItemText>{f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.list.fileTypeTitle'})}</MyListItemText>
                        <ListItemSecondaryAction>
                        <ButtonGroup size="small" disableElevation variant="contained" color="primary">
                            <Button className={classes.button} color={fileType === 'csv' ? 'primary' : 'default'} onClick={() => setFileType('csv')}><ButtonTypography>{f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.list.fileTypes.0'})}</ButtonTypography></Button>
                            <Button className={classes.button} color={fileType === 'xlsx' ? 'primary' : 'default'} onClick={() => setFileType('xlsx')}><ButtonTypography>{f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.list.fileTypes.1'})}</ButtonTypography></Button>
                        </ButtonGroup>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <MyListItemText>
                            {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.list.answerTypeTitle'})}
                            <IconButton size="small" style={{cursor: 'default'}}>
                                <Tooltip 
                                    title={f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.answerTypeTooltip'})} 
                                    isMobileClick={true}
                                >
                                    <HelpIcon fontSize="small"/>
                                </Tooltip>
                            </IconButton>
                        </MyListItemText>
                        <ListItemSecondaryAction>
                            <Select value={answerType} onChange={handleChangeAnswerType} style={{width: 110}}>
                                <MenuItem value={0}>{f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.list.answerTypes.0'})}</MenuItem>
                                <MenuItem value={1}>{f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.list.answerTypes.1'})}</MenuItem>
                                <MenuItem value={2}>{f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.list.answerTypes.2'})}</MenuItem>
                            </Select>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                    {
                        disabled ? (
                            <Tooltip title={disabled ? f({id: 'component.Survey.Create.Analysis.Rows.Personal.disabled'}) : ''} placement="top" isMobileClick={true}>
                                <span>
                                <Button color="primary" disabled={true}>
                                    {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.button.download'})}
                                </Button>
                                </span>
                            </Tooltip>
                        ) : fileType === 'csv' ? (
                            <CSVLink 
                                data={rowsCSV} 
                                filename={`${title}.csv`}
                                style={{textDecoration: 'none'}}
                                asyncOnClick={true}
                                onClick={(e, done) => {
                                    if (!loading) done(true)
                                    else done(false)
                                }}
                            >
                                <div className={classes.buttonBox}>
                                    <Button color="primary" disabled={loading}>
                                        {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.button.download'})}
                                    </Button>
                                    { loading && <CircularProgress className={classes.circul} size={18}/> }
                                </div>
                                    
                            </CSVLink>
                        ) : (
                            <div className={classes.buttonBox}>
                                {
                                   loading ? (
                                        <>
                                        <Button color="primary" disabled={true}>
                                            {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.button.download'})}
                                        </Button>
                                        <CircularProgress className={classes.circul} size={18}/>
                                        </>
                                    ) : (
                                        <Button color="primary" onClick={() => handleDownoadExcel(title)}>
                                            {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.button.download'})}
                                        </Button>
                                    )
                                }
                            </div>
                         
                        )
                    }
                <Button onClick={onClose} color="default">
                    {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.button.close'})}
                </Button>
            </DialogActions>
        </>
    )
}

export default Component