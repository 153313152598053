import { memo } from "react"
import fixedWidthString from 'fixed-width-string'
import { useIntl } from 'react-intl'
import { SurveyQuestionModuleProps, AnswerMatrix } from 'gql/survey_question_modules'
import { JumpNo } from 'gql/jump'
import { MySelect, MyTypography, OnChangeChildsProps } from './ItemRules'
import { MyMenuItem } from './SelectModule'

interface Props extends OnChangeChildsProps {
    typename: string;
    question: SurveyQuestionModuleProps;
    jump_rule_child_no: JumpNo;
    survey_module_answer_no: number;
}

function SelectMatrixAnswerComponent(props: Props) {
    const { typename, question, jump_rule_child_no, survey_module_answer_no, onChange } = props

    const { formatMessage: f } = useIntl()

    const { _answers } = question

    return (
        <MySelect
            error={!survey_module_answer_no} 
            displayEmpty 
            value={survey_module_answer_no || ''} 
            label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMatrixAnswer.label'})}
            style={{display: 'flex'}}
            onChange={(e) => onChange('survey_module_answer_no', jump_rule_child_no, Number(e.target.value) )}
        >
            {
                _answers.map((c: AnswerMatrix) => {
                    const { indexs, answer, survey_module_answer_no } = c
                    const label = typename === 'matrix' ? fixedWidthString(`${indexs}. ${answer}`, 30) : indexs

                    return (
                        <MyMenuItem key={survey_module_answer_no} value={survey_module_answer_no}>
                            <MyTypography>{label}</MyTypography>
                        </MyMenuItem>
                    )
                })
            }
        </MySelect>
    )
}

export default memo(SelectMatrixAnswerComponent)