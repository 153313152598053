import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, IconButton } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import ItemComponent from './Item'

const useStyles = makeStyles({
    new: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    }
})

function SimpleCard(props) {
    const { rows, onOpen, onOpenPop, onApply} = props
    const classes = useStyles()

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Paper variant="outlined" className={classes.new} onClick={onOpen}>
                    <IconButton>
                        <AddIcon fontSize="large"/>
                    </IconButton>
                </Paper>   
            </Grid>
            {
                rows.map(c => {
                    const { 
                        survey_designs_no, title, numbering, question, answer, answer_button, answer_sbutton, 
                        line_color, background_size, background_opacity, background_color, 
                        background_image, background_filter, font_family
                    } = c
                    
                    return (
                        <ItemComponent
                            key={survey_designs_no}
                            survey_designs_no={survey_designs_no}
                            title={title}
                            line_color={line_color}
                            background_size={background_size}
                            background_opacity={background_opacity}
                            background_color={background_color}
                            background_image={background_image}
                            background_filter={background_filter}
                            font_family={font_family}
                            numbering={numbering}
                            question={question}
                            answer={answer}
                            answer_button={answer_button}
                            answer_sbutton={answer_sbutton}
                            template={false}
                            onApply={onApply}
                            onOpen={onOpenPop}
                        />
                    )
                })
            }
        </Grid>
    )
}

export default SimpleCard