import { memo } from 'react'
import { 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Typography,
    ButtonGroup,
    Button,
    Grid,
    Paper
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { getDefaultQuestion } from 'utils/survey'
import { parseHtmlIntl } from 'ts-utils'
import styled from 'styled-components'
import oc from 'open-color'
import fixedWidthString from 'fixed-width-string'

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        background: 'white'
    },
    question: {
        marginTop: 18,
        marginBottom: 5
    },
    list: {
        width: '100%'
    },
    null: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'underline',
        marginTop: 40
    }
}))

const ListStyled = styled.div`
    width: 100%;
    overflow-x: hidden;
    height: 15em;
    overflow: auto;
    padding: 5px;
    background: white;
    border: 1px solid ${oc.gray[4]};
    border-radius: 5px;

`

const WrapperStyled = styled.div`
    padding: 1em;
`

const Component = (props) => {
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const {  
        position, 
        method,
        self,
        onChangeMovePage,
        onChangeMovePosition,
        onChangeMethod,
        withs
    } = props

    return (
        <WrapperStyled>
            <ButtonGroup size="small" variant="outlined" style={{background: 'white'}} disableElevation>
                <Button color={method === 'move' ? 'primary' : 'default'} onClick={() => onChangeMethod('move')}>
                    {f({id: 'component.Survey.Create.Pages.Move.tab.move'})}
                </Button>
                <Button color={method === 'copy' ? 'primary' : 'default'} onClick={() => onChangeMethod('copy')}>
                    {f({id: 'component.Survey.Create.Pages.Move.tab.copy'})}
                </Button>
            </ButtonGroup>
            <Paper variant="outlined" style={{marginTop: 25, padding: 10}}>
                {parseHtmlIntl(f({id: 'component.Survey.Create.Pages.Move.current'}, { indexs: self.indexs }))}
            </Paper>
            <Grid container spacing={1} style={{marginTop: 15}}>
                <Grid item xs={4} sm={4} md={4} >
                    <FormControl variant="outlined" className={classes.form}>
                        <InputLabel>{f({id: 'component.Survey.Create.Pages.Move.label.section'})}</InputLabel>
                        <Select 
                            label={f({id: 'component.Survey.Create.Pages.Move.label.section'})}
                            value={withs.val.survey_page_no} 
                            onChange={onChangeMovePage}
                        >
                        { 
                            withs.pages.map(c => <MenuItem key={c.survey_page_no} value={c.survey_page_no}>{c.indexs}</MenuItem>)
                        }
                        </Select>
                    </FormControl>    
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                    <FormControl variant="outlined" className={classes.form}>
                        <InputLabel>{f({id: `component.Survey.Create.Pages.Move.label.position.${method}`})}</InputLabel>
                        <Select 
                            label={f({id: `component.Survey.Create.Pages.Move.label.position.${method}`})}
                            value={position} 
                            onChange={(e) => onChangeMovePosition(e.target.value)}
                        >
                            <MenuItem value={0}>{f({id: 'component.Survey.Create.Pages.Move.position.0'})}</MenuItem>
                            <MenuItem value={1}>{f({id: 'component.Survey.Create.Pages.Move.position.1'})}</MenuItem>
                            <MenuItem value={2} style={{display: method === 'copy' ? 'block' : 'none'}}>{f({id: 'component.Survey.Create.Pages.Move.position.2'})}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Typography variant="subtitle2" className={classes.question}>{f({id: 'component.Survey.Create.Pages.Move.question'})}</Typography>
            <ListStyled>
                <List dense className={classes.list}>
                {
                    withs.questions.map(c => {
                        const { survey_question_no, pure_question, number, question_type } = c
                        const question = fixedWidthString(pure_question, 30)

                        return (
                            <ListItem key={survey_question_no} value={survey_question_no}>
                                    <ListItemText primary={getDefaultQuestion({f, number, question, question_type })}/>
                            </ListItem>
                        )   
                    })
                }
                </List>
            </ListStyled>
        </WrapperStyled>
    )
}

export default memo(Component)