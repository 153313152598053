/**
 * 응답수 컴포넌트
 * summary 데이터 가져오면 해당 컴포넌트에 리덕스로 response 값을 호출해준다.
 */
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { numberFormat } from 'utils'
import Typography from '@material-ui/core/Typography'

interface Props {
    classes: any;
}

function ResponseComponent(props: Props) {
    const { classes } = props

    const summaryRows = useSelector((states: RootState) => states.analysisSummaryRows)

    const { response } = summaryRows

    return (
        <Typography variant="caption" className={`${classes.typography} ${classes.count}`} display="block">
            {`${numberFormat(response)} responses`}
        </Typography>
    )
}

export default memo(ResponseComponent)