import { useEffect } from 'react'
import { Dialog as MuiDialog, DialogContent as MuiDialogContent } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Element } from 'react-scroll'
import randomstring from 'randomstring'
import { scroller } from 'react-scroll'
import oc from 'open-color'

export const MyDialog = withStyles({
    container: {
        backgroundColor: oc.gray[2]
    }
})(MuiDialog)

const DialogContent = withStyles({
    root: {
        padding: `0 !important`,
        overflow: 'hidden !important'
    }
})(MuiDialogContent)

const scrolls = {
    id: randomstring.generate(7),
    to: ''
}

const style = {
    maxHeight: '100vh', 
    overflow: 'auto'
}

const Mobile = (props) => {
    const { scrollTarget, page, onClose, children } = props

    useEffect(() => {
        setTimeout(() => {
            scroller.scrollTo(scrollTarget, {
                offset: -10,
                containerId: scrolls.id
            })
        }, 1)
    }, [scrollTarget, page])
    
    return (
        <MyDialog open={true} onClose={onClose}>
             <Element id={scrolls.id} style={style}>
                <DialogContent>
                    {children}
                </DialogContent>
             </Element>
        </MyDialog>
    )
}

export default Mobile