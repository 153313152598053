/**
 * 상단 버튼 모음
 */
import { memo } from 'react'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { MoblieNavigation } from '../Rows/Buttons'
import MobileTop from '../Rows/MobileTop'
import SortComponent from './Sort'
import DataDownloadsComponent from './DataDownloads'
import MoreComponent from './More'
import RefreshComponent from '../Refresh'

interface Props {
    survey_no: number;
    questions: SurveyQuestionModuleProps[];
    breakpoints: BreakPointsProps;
    refetchQuestions: () => void;
    refetchEndings: () => void;
}

function ButtonsComponent(props: Props) {
    const { survey_no, questions, refetchQuestions, refetchEndings, breakpoints } = props
    const { xs } = breakpoints
  
    return (
        <>
        {
            xs ? (
                <MoblieNavigation>
                    <SortComponent survey_no={survey_no} questions={questions} xs={true}/>
                    <DataDownloadsComponent xs={true}/>
                    <MobileTop/>
                </MoblieNavigation>
            ) : (
                <div>
                    <div>
                        <SortComponent survey_no={survey_no} questions={questions} xs={false}/>
                    </div>
                    <div>
                        <DataDownloadsComponent  xs={false}/>
                    </div>
                    <div>
                        <RefreshComponent refetchQuestions={refetchQuestions} refetchEndings={refetchEndings} />
                    </div>
                    <div>
                        <MoreComponent survey_no={survey_no}/>
                    </div>
                </div>
            )
        }
        </>
    )
}

export default withBreakPoints(memo(ButtonsComponent))