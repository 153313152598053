/**
 * _01 셀렉트 버튼
 */

import { memo } from 'react'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import { OnChangeText, OnChangeSign } from '../../List'
import { SelectBoxGrid, RemoveGrid } from '../../SelectBox'
import InputComponent from './Input'
import SignSelectBoxComponentComponent from './SignSelectBox'

export interface Props {
    typename: string;
    keyValue: string;
    sign: string;
    value: string;
    onChangeText: OnChangeText;
    onChangeSign: OnChangeSign;
}

const MyBox = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        '& > div:last-child': {
            flexGrow: 1,
            height: 41
        }
    }
}))(Box)

function Component(props: Props) {
    const { typename, keyValue, sign, value, onChangeText, onChangeSign } = props
    
    return (
        <>
        <SelectBoxGrid>
            <MyBox>
                <div>
                    <SignSelectBoxComponentComponent keyValue={keyValue} sign={sign} onChange={onChangeSign} />
                </div>
                <div>
                    <InputComponent typename={typename} keyValue={keyValue} value={value} onChange={onChangeText} />
                </div>
            </MyBox>
        </SelectBoxGrid>
        <RemoveGrid>

        </RemoveGrid>
        </>
    )

}

export default memo(Component)