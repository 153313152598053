import { memo, useState } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useStyles } from './QuestionButtons'

interface Props {
    question: any;
    img_src: any;
    number: any;
    modules: any;
    answers: any;
    __typename: any;
    question_type: any;
    order: any;
    sectionText: any;
    option: any;
    onSave: (a:any) => void;
}

function QuestionButtonPpptComponent(props: Props) {
    const { question, img_src, number, modules, answers, __typename, question_type, order, sectionText, option, onSave } = props
    
    const classes = useStyles()

    const [ loading, setLoading ] = useState(false)

    const handleSave = async() => {
        setLoading(true)

        await onSave({ question, img_src, number, modules, answers, __typename, question_type, order, sectionText, option })

        setLoading(false)
    }

    return (
        <Button 
            className={classes.button} 
            startIcon={
                loading ? <CircularProgress size={15} /> : <GetAppIcon fontSize="small" className={classes.icon} /> 
            } 
            size="small" 
            disabled={loading}
            variant="contained" 
            disableElevation 
            style={{ marginRight: 3 }}
            onClick={handleSave}
        >
            PPT
        </Button>
    )
}

export default memo(QuestionButtonPpptComponent)