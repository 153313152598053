import { memo } from 'react'
import TableCell from '@material-ui/core/TableCell'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'              


import { useStyles as useParentStyles } from './TableHead'
import { useStyles as useChildStyles } from './Item'

interface Props {
    allChecked: boolean;
    checkedLen: number;
    rowsLen: number;
    onSelectAllClick: (a: boolean) => void;
}

function TableHeadCheckboxComponent(props: Props) {
    const { allChecked, checkedLen, rowsLen, onSelectAllClick } = props

    const classesParent = useParentStyles()
    const classesChild = useChildStyles()

    if (checkedLen > 0 && checkedLen < rowsLen) {
        return (
            <TableCell padding="checkbox" onClick={() => onSelectAllClick(!allChecked)} className={`${classesChild.checkbox} ${classesChild.tableCell} ${classesParent.tableCheckboxCellSize} ${classesParent.tableCell}`}>
                <IndeterminateCheckBoxIcon color={allChecked ? 'secondary' : 'action'}  />
            </TableCell>
        )
    }

    return (
        <TableCell padding="checkbox" onClick={() => onSelectAllClick(!allChecked)} className={`${classesChild.checkbox} ${classesChild.tableCell} ${classesParent.tableCheckboxCellSize} ${classesParent.tableCell} `}>
            {
                allChecked ? (
                    <CheckBoxIcon color="secondary" />
                ) : (
                    <CheckBoxOutlineBlankIcon color="action" />
                )
            }
        </TableCell>
    )
}

export default memo(TableHeadCheckboxComponent)