import { useIntl } from 'react-intl'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import oc from 'open-color-js'
import { parseHtmlIntl } from 'ts-utils'

const BoxStyled = styled.div`
    background: black;
    color: white;
    width: 100%;
    max-height: 10em;
    border: 1px solid ${oc.gray1};
    border-radius: 5px;
    padding: 1em;
    overflow-y: auto;
    word-break: break-all;
`

export default ({selected, open, html, copyLink, on}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const { formatMessage: f } = useIntl()

    return (
        
        <Dialog open={open} onClose={on.close} fullScreen={fullScreen}>
            <DialogTitle>{parseHtmlIntl(f({id: 'component.Survey.Create.Share.CodeModal.title'}))}</DialogTitle>
            <DialogContent>
                <Typography paragraph color="textSecondary">
                    {parseHtmlIntl(f({id: `component.Survey.Create.Share.CodeModal.${selected}`}))}
                </Typography>
                <BoxStyled>
                    <Typography>
                    {html}
                    </Typography>
                </BoxStyled>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={on.copyCode} color="primary" disableRipple>
                <Typography variant="button">{ copyLink ? 'Copied!' : 'Copy Code' }</Typography>
                </Button>
                <Button onClick={on.close} color="default" disableRipple>
                    <Typography variant="button">Close</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
