/**
 * 외부사이트로 이동 Dialog
 */
import { memo, useState, useEffect, useCallback, ChangeEvent, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { RootState } from "reducer"
import { validation } from 'utils'
import { parseHtmlIntl } from 'ts-utils'
import { Levels } from 'ts-policy'
import { getMaxlength } from 'policy'
import { UPDATE_SURVEY_ENDING_REDIRECT, SurveyEndingProps } from 'gql/survey_ending'
import { HIDE } from 'reducer/survey/create/menu/ending/redirect'
import { SHOW as BACKDROPSHOW, HIDE as  BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useTokenInfo from 'hooks/useTokenInfo'
import Dialog from 'styled/Survey/Live/Dialog'
import EndingsItemRedirectInputComponent from './EndingsItemRedirectInput'

interface Props {
    refetch: () => void;
}

export type Name = 'redirectUrl' | 'redirectLabel';
export type OnChange = (e: ChangeEvent<HTMLInputElement>) => void;
export type OnEnter = (e: KeyboardEvent<HTMLInputElement>) => void;

interface GetInitState {
    redirect_url: SurveyEndingProps['redirect_url'];
    redirect_label: SurveyEndingProps['redirect_label'];
}

const getInitState = (props: GetInitState) => {
    const { redirect_url, redirect_label } = props

    return {
        redirectUrl: redirect_url || '',
        redirectLabel: redirect_label || ''
    }
}

function EndingsItemUrlComponent(props: Props) {
    const { refetch } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const token = useTokenInfo()

    const reducerState = useSelector((state: RootState) => state.surveyCreateMenuEndingRedirect)
    const { survey_no, survey_ending_no, indexs, redirect_url, redirect_label } = reducerState

    const [ state, setState ] = useState(getInitState({ redirect_url, redirect_label }))

    const [ update ] = useMutation(UPDATE_SURVEY_ENDING_REDIRECT, {
        onCompleted: () => {
            refetch()
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: HIDE })
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORORPAGESHOW })
            dispatch({ type: HIDE })
        }
    })

    const open = Boolean(survey_ending_no)
    const urlError = (state.redirectUrl && !validation.url(state.redirectUrl)) ? true : false

    const handleClose = () => {
        dispatch({ type: HIDE })
    }

    const handleChange: OnChange = useCallback(e => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }, [])

    const handleSave = useCallback(() => {
        if (urlError) return
        
        dispatch({ type: BACKDROPSHOW })
        update({ 
            variables: {
                survey_no,
                survey_ending_no,
                redirect_url: state.redirectUrl,
                redirect_label: state.redirectLabel,
            }
        })
    }, [survey_no, survey_ending_no, urlError, state.redirectUrl,  state.redirectLabel, dispatch, update])

    const handleEnter: OnEnter = useCallback(e => {
        if(e.code === 'Enter') {
            handleSave()
        }
    }, [handleSave])

    useEffect(() => {
        if (open) {
            setState(getInitState({ redirect_url, redirect_label }))
        }
       
    }, [open, redirect_url, redirect_label])

    return (
        <Dialog open={Boolean(survey_ending_no)} fullScreen={false} isMobileFullScreen={true} fullWidth={true} onClose={handleClose}>
            <DialogTitle>
                {
                    parseHtmlIntl(`${f({id: 'component.Survey.Create.Menu.Endings.subheader.label'})}${indexs} - 
                    ${f({id: 'component.Survey.Create.Menu.EndingsItemKind.column.redirect'})} 
                    ${f({id:'common.survey.plan.pay'})}`)
                }
            </DialogTitle>
            <DialogContent>
                <Typography component="div" gutterBottom>
                    {f({id: 'component.Survey.Create.Menu.EndingsItemRedirect.explain'})}
                </Typography>
                <EndingsItemRedirectInputComponent
                    name="redirectUrl"
                    placeholder="https://www.listovey.com" 
                    helper={f({id: 'component.Survey.Create.Menu.EndingsItemRedirect.url.helper'})}
                    error={urlError}
                    value={state.redirectUrl}
                    maxLength={getMaxlength('component.Survey.Create.Menu.EndingsItemRedirect.Input.url')}
                    onChange={handleChange}
                    onKeyUp={handleEnter}
                />
        
                <EndingsItemRedirectInputComponent 
                    name="redirectLabel" 
                    placeholder={f({id: 'component.Survey.Create.Menu.EndingsItemRedirect.label.placeholder'})}
                    helper={f({id: 'component.Survey.Create.Menu.EndingsItemRedirect.label.helper'})}
                    error={false}
                    value={state.redirectLabel}
                    maxLength={getMaxlength('component.Survey.Create.Menu.EndingsItemRedirect.Input.label')}
                    onChange={handleChange}
                    onKeyUp={handleEnter}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={urlError || token.levels === Levels.Level0} onClick={handleSave}>
                    {f({id: 'component.Survey.Create.Menu.EndingsItemRedirect.button.save'})}
                </Button>
                <Button onClick={handleClose}>
                    {f({id: 'component.Survey.Create.Menu.EndingsItemRedirect.button.close'})}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default memo(EndingsItemUrlComponent)