/**
 * 요약데이터 전체 다운로드
 */
import { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { ppts } from 'utils/analysis'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import GetAppIcon from '@material-ui/icons/GetApp'
import useSurveyTitle from 'hooks/useSurveyTitle'
import Tooltip from "styled/Custom/Tooltip"
import { IconBox } from "../Buttons"

interface Props {
    xs: boolean;
}

function DataDownloadsComponent(props: Props) {
    const { xs } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const { summaryRows, summaryOrder } = useSelector((states: RootState) => {
        return {
            summaryRows: states.analysisSummaryRows,
            summaryOrder: states.analysisSummaryOrder
        }
    })

    const { rows } = summaryRows
    const { order, pointType } = summaryOrder
    
    const [ title ] = useSurveyTitle()

    const handleClick = () => {
        const Ppts = new ppts()

        dispatch({type: BACKDROPSHOW, msg: f({id: 'component.Survey.Create.Analysis.Summary.DataDownloadDialog.downloadMsg'})})
        setTimeout(() => {
            Ppts.doAll({
                f, 
                title,
                items: rows,
                order,
                orderComment: 'wdate',
                pointType
            }).then((c) => {
                const { count } = c
                
                dispatch({type: BACKDROPHIDE})
                if (count > 1) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        message: f({id: 'component.Survey.Create.Analysis.Summary.DataDownloadDialog.tip'}),
                        variant: 'info',
                        duration: 10000
                    })
                }
            }).catch(e => {
                dispatch({type: BACKDROPHIDE})
            })
        }, 1000)
    }
    
    return (
        <>
        {
            xs ? (
                <BottomNavigationAction showLabel label={f({id: "component.Survey.Create.Analysis.Summary.DataDownloads.button.title"})} icon={<GetAppIcon />} onClick={handleClick}/>
            ) : (
                <Tooltip title={f({id: "component.Survey.Create.Analysis.Summary.DataDownloads.button.title"})} placement="top">
                    <IconBox onClick={handleClick}>
                        <GetAppIcon fontSize="small"/>
                    </IconBox>
                </Tooltip>
            )
        }
        </>
    )
}

export default memo(DataDownloadsComponent)