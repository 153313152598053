import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { StandardAnswer } from 'reducer/analysis/rows/personal'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface Props {
    answer: StandardAnswer;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex', 
        gap: 5, 
        fontSize: 12, 
        padding: '8px 12px 6px 12px',
        overflow: 'auto',
        width: '100%',
        background: oc.gray[0], 
        whiteSpace: 'nowrap',
        '& > div': {
            flex: 1,
            width: '100%',
            background: oc.gray[0], 
            borderRadius: 0, 
            padding: 0
        },
        '& > div + div': {
            marginLeft: 10
        },
        '& > div > div:first-child': {
            fontWeight: 700
        },
        '& > div > div:last-child': {
            color: oc.gray[8],
            overflowX: 'hidden'
        }
    }
}))

function InfoComponent(props: Props) {
    const { answer } = props
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    let accesstime = ''
    let einterval = ''
    let id = ''
    let ip = ''

    if (answer.accesstime) {
        if (answer.accesstime.choice.length > 0) accesstime = String(answer.accesstime.choice[0])
    }

    if (answer.einterval) {
        if (answer.einterval.choice.length > 0) einterval = String(answer.einterval.choice[0])
    }

    if (answer.id) {
        if (answer.id.choice.length > 0) id = String(answer.id.choice[0])
    }

    if (answer.ip) {
        if (answer.ip.choice.length > 0) {
            ip = String(answer.ip.choice[0])
        }
    }

    return (
       <div className={`${classes.root} info`}>
            <div>
                <div>{f({id: 'component.Survey.Create.Analysis.Rows.PersonalFolder.Info.id'})}</div>
                <div>{id}</div>
            </div>
            <div>
                <div>IP/EMAIL</div>
                <div>{ip}</div>
            </div>
            <div>
                <div>{f({id: 'component.Survey.Create.Analysis.Rows.PersonalFolder.Info.accesstime'})}</div>
                <div>{accesstime}</div>
            </div>
            {
                einterval && (
                    <div>
                        <div>{f({id: `component.Survey.Create.Analysis.Rows.PersonalFolder.Info.einterval.${einterval.length === 19 ? 'date' : 'time'}`})}</div>
                        <div>{einterval}</div>
                    </div>
                )
            }
       </div>
    )
}

export default memo(InfoComponent)
