import { useDispatch } from 'react-redux'
import Box, { BoxProps } from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { CHANGE } from 'reducer/survey/create/logic/jumpFocus'
import oc from 'open-color'

interface Props {
    id: string;
    display: string | undefined;
}

export const EditBox = withStyles({
	root: {
		cursor: 'pointer', 
		fontSize: 14, 
		color: oc.indigo[9], 
		backgroundColor: oc.gray[2], 
		padding: '6px 6px 5px 6px', 
		borderRadius: 3, 
		fontWeight: 700,
		display: 'table',
		transition: 'all .1s',
		'&:hover': {
			backgroundColor: oc.indigo[7], 
			color: 'white' 
		}
	}
})((props: BoxProps) => <Box {...props}/>)

// section을 기준으로 고유번호를 가져온다.
function getNo(id: string, section: string) {
    const index = id.indexOf(section)
    const start = index + section.length + 1

    return id.substring(start, id.length)            
}

function EditComponent(props: Props) {
    const { display='none', id } = props

    const dispatch = useDispatch()

    function handleClick() {

        const [_, _id ] = id.split('-')

        let no = ''
        let section = ''
        if (/default/.test(id)) {
           section = 'default'
           no = getNo(id, section)
        } else if (/rules/.test(id)) {
            section = 'rules'
            no = getNo(id, section)
        }

        if (!no || !section) return

        dispatch({ type: CHANGE, id: Number(_id), no, section })        
    }

    return <EditBox component="span" style={{ display }} onClick={handleClick}>EDIT</EditBox>
}

export default EditComponent