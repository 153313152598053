import { useContext } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { DiagramNodeIDContext } from './Context'
import Tooltip from 'styled/Custom/Tooltip'

const MyBox = withStyles({
    root: {
        marginTop: 12,
        '& > span + span': {
            marginLeft: 3
        }
    }
})(Box)

const MyItem = withStyles({
    root: {
        padding: '8px 10px',
        borderRadius: 3,
        fontSize: 12,
        border: `1px solid ${oc.gray[2]}`,
        background: oc.gray[0]
    }
})(Box)
function MySelectNodeExplainComponent() {
    const { nodeID } = useContext(DiagramNodeIDContext)
    const { id } = nodeID

    const { formatMessage: f } = useIntl()

    if (!id) return null

    return (
        <Tooltip title={f({id: 'component.Survey.Create.Logic.Jump.Diagram.MySelectNodeExplain.tooltip'})}>
            <MyBox>
                <MyItem component="span" style={{color: oc.orange[6]}}>{f({id: 'component.Survey.Create.Logic.Jump.Diagram.MySelectNodeExplain.button.hide'})}</MyItem>
                <MyItem component="span" style={{color: oc.blue[6]}}>{f({id: 'component.Survey.Create.Logic.Jump.Diagram.MySelectNodeExplain.button.show'})}</MyItem>
            </MyBox>
        </Tooltip>
    )
}

export default MySelectNodeExplainComponent