import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import HtmlParser from 'react-html-parser'
import { getMimePicture, getModuleIcon } from 'utils/survey'
import { handleDisabledRightClick } from 'ts-utils'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Video from 'styled/Survey/Live/Video'
import Youtube from 'styled/Survey/Live/Youtube'
import { Props as ParentProps } from './Component'

interface Props {
    typename: string;
    pure_question: ParentProps['questions'][0]['_question']['pure_question'];
    img_src: ParentProps['questions'][0]['_question']['img_src'];
}

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: 10,
        paddingBottom: 5,
        '& > div:first-child': {
            width: 40
        },
        '& > div:last-child': {
            flex: 1,
            paddingTop: 3
        }
    },
    title: {
        color: oc.gray[7],
        fontSize: 13
    },
    img: {
        maxWidth: '100%',
        borderRadius: 5,
        marginTop: 5
    },
    video: {
        marginTop: 10,
        marginBottom: 10,
        maxWidth: '100%'
    },
    iconBox: {
        border: `1px solid ${oc.gray[3]}`,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 28,
        height: 28,
        background: 'white'
    },
    icon: {
        width: 18
    }
}))

function SubjectComponent(props: Props) {
    const { typename, pure_question, img_src } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const icons = getModuleIcon(f)

    const icon = icons.find(c => c.type === typename)

    const Icon = icon ? icon.icon : null

    if (!Icon) return null

    const title = typeof pure_question === 'string' ? HtmlParser(pure_question.replaceAll('\r\n', '\n').replaceAll('\n', '<br />')) : pure_question

    const mime = getMimePicture(img_src)
    
    return (
        <Box className={classes.root}>
            <div>
                <span className={classes.iconBox}>
                    <Icon fontSize="small" color="action" className={classes.icon} />
                </span>
            </div>
            <div>
                <Typography variant="body2" className={classes.title}>{title}</Typography>
                {
                    (mime === 'image') ? <img src={img_src} alt="listovey" className={classes.img} onContextMenu={handleDisabledRightClick} /> : 
                    (mime === 'youtube') ? <div className={`video ${classes.video}`}><Youtube src={img_src} autoplay={0} mute={0} control={1} screen="stack" /></div> : null
                }
            </div>
        </Box>
    )
}

export default memo(SubjectComponent)
