import { useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer/index'
import { scroller, Element } from 'react-scroll'
import { scrollerObj } from './Component'

interface Props {
    survey_question_no: number;
    onOpen: () => void;
}

function ScrollerTargetComponent(props: Props) {
    const { survey_question_no, onOpen } = props
    const target = `target-${survey_question_no}`

    const error = useSelector((state: RootState) => state.surveyCreateLogicJumpPointError)

      // 빈값이 있다면 포커스 준다.
      useEffect(() => {
        if (error) {
            if (error.survey_question_no === survey_question_no) {
                scroller.scrollTo(target, {
                    ...scrollerObj.scrollTo,
                    containerId: scrollerObj.id
                })
                onOpen()
            }
        }
    }, [error, survey_question_no, target, onOpen])

    return <Element name={target}/>
}

export default memo(ScrollerTargetComponent)