import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'

const useStyles = makeStyles({
    root: {
        marginTop: 14
    }
})

export default (props) => {

    const { onClose } = props

    const classes = useStyles()

    return (
        <IconButton  edge="start" color="inherit" className={classes.root} onClick={onClose}>
            <CloseIcon/>
        </IconButton>
    )
}