import { useContext } from 'react'
import styled from 'styled-components'
import CreateBox from 'styled/Survey/CreateBox'
import PropsContext from 'context/props'

const WrapperStyled = styled.div`
    display: ${props => props.show === props.showType ? 'block' : 'none'};
`

const Component = props => {
    const {  scrolls, showType, children } = props
    const { show } = useContext(PropsContext)

    return (
        <WrapperStyled show={show} showType={showType}>
            <CreateBox  scrolls={scrolls}>
                {children}
            </CreateBox>
        </WrapperStyled>
    )
}

export default Component