import { makeStyles } from '@material-ui/core/styles'
import oc from 'open-color-js'
import { FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
    formControl: {
      minWidth: 120,
      color: oc.gray6
    },
    select: {
        color: oc.gray6,
        height: 47
    },
    button: {
        height: 47,
        color: oc.gray6
    }
}))

export default (props) => {
    const classes = useStyles()
    const { onToolbar, pages=[], page } = props
    const { formatMessage: f } = useIntl()

    return (
        <>
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Page</InputLabel>
            <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pages.find(({survey_page_no}) => survey_page_no === page) ? page : ''}
            labelWidth={40}
            className={classes.select}
            onChange={(e) => onToolbar.changePage(e)}
            >
            {
                pages.map(c => <MenuItem key={c.survey_page_no} value={c.survey_page_no}>{c.indexs}</MenuItem>)
            }
            </Select>
        </FormControl>
        <Button className={classes.button} variant="outlined" onClick={onToolbar.createPage}>
            {f({id: 'component.Survey.Create.Module.Toolbar.page'})}
        </Button>
        <Button className={classes.button} variant="outlined" onClick={onToolbar.movePage}>
            {f({id: 'component.Survey.Create.Module.Toolbar.pageMove'})}
        </Button>
        </>
    )
}
