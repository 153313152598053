import { memo } from "react"
import Box from "@material-ui/core/Box"
import makeStyles from '@material-ui/core/styles/makeStyles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    button: {
        borderRadius: 3,
        border: `1px solid ${theme.palette.grey[400]}`,
        width: 25,
        height: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all .3s',
        '&:hover': {
            background: theme.palette.grey[100]
        },
        cursor: 'pointer'
    },
    left: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    right: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 'none'
    },
}))

function MoveComponent() {
    const classes = useStyles()

    return null
    
    return (
        <Box className={classes.root}>
            <Box className={`${classes.button} ${classes.left}`}>
                <ArrowLeftIcon fontSize="large" color="action" />
            </Box>
            <Box className={`${classes.button} ${classes.right}`}>
                <ArrowRightIcon fontSize="large" color="action" />
            </Box>
        </Box>
    )
}

export default memo(MoveComponent)