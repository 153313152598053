import { List, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FolderIcon from '@material-ui/icons/Folder'
import { useIntl } from 'react-intl'
import CreateBox from 'styled/Survey/CreateBox'
import { Title, ListItem, ListItemIcon } from 'styled/Survey/CreateExplainStyle'
import YoutubeStyled from 'styled/Survey/Live/Youtube'

const MyList = withStyles({
    root: {
        marginBottom: 15
    }
})(props => <List disablePadding {...props}/>)

export default (props) => {
    const { method } = props

    const { formatMessage: f } = useIntl()

    return (
        <CreateBox className="explain">
            {
                method === 'multi' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Method.Preview.title.multi'})}</Title>
                    <MyList>
                        <ListItem>
                            <ListItemIcon><FolderIcon/></ListItemIcon>
                            <ListItemText 
                                primary={f({id: 'component.Survey.Create.Method.Preview.lists.multi.0'})}
                            />
                        </ListItem>
                    </MyList>
                    <YoutubeStyled src="https://www.youtube.com/watch?v=cIuQBfzwSFM" autoplay={1} mute={0} control={1} screen="wide"/>
                    </>
                ) : (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Method.Preview.title.one'})}</Title>                        
                    <MyList>
                        <ListItem>
                            <ListItemIcon><FolderIcon/></ListItemIcon>
                            <ListItemText 
                                primary={f({id: 'component.Survey.Create.Method.Preview.lists.one.0'})}
                            />
                        </ListItem>
                    </MyList>
                    <YoutubeStyled src="https://www.youtube.com/watch?v=ZKEZZr5B7DM" autoplay={1} mute={0} control={1} screen="wide"/>
                    </>
                )
            }
        </CreateBox>
    )
}