/**
 * 사용자가 요청하는 rows data httpd 통신을 한다.
 */
import { memo } from 'react'
import { Mode } from 'gql/survey_analysis_summary'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import withSurveyRows, { RowsProps } from 'hoc/Survey/Rows'
import DownloadMenuCompoent from './DownloadMenu'

export interface Props extends RowsProps {
    action: number;
    etc: {
        questions: SurveyQuestionModuleProps[];
        open: boolean;
        onClose: () => void;
    }

}

function DownloadMenuHttpComponent(props: Props) {
    const { action, etc, rowsData } = props
   
    return (
        <DownloadMenuCompoent action={action} etc={etc} rowsData={rowsData}/>
    )
}

export default withSurveyRows(Mode.Live)(true)(memo(DownloadMenuHttpComponent))