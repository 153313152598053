import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import ButtonGroup from "@material-ui/core/ButtonGroup"
import { OnChangeStartpoint } from 'container/Survey/Create/NewAnalysis/Rows/Container'
import { RemoveButton } from './Component'
import { Props as ParentProps, Open, ButtonBox } from './RemoveFileButton'
import RemoveUserDialogComponent from './RemoveUserDialog'

export interface Props extends Omit<ParentProps, 'files' | 'breakpoints'> {
    startpoint: number;
    isRemoveLastPageAllData: boolean;
    onChangeStartpoint: OnChangeStartpoint;
}

function RemoveButtonComponent(props: Props) {
    const { allCount, where, checked, search, startpoint, isRemoveLastPageAllData, onChangeStartpoint, onRefetch } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState<Open>(null)

    function handleOpen(type: Open) {
        setOpen(type)
    }

    const handleClose = useCallback(() => {
        setOpen(null)
    }, [])

    return (
        <>
        <ButtonBox>
            <div>{f({id: 'component.Survey.Create.Analysis.Rows.Component.toolbar.title'})}</div>
            <div>
               
                    <RemoveButton  disabled={checked.length === 0} onClick={() => handleOpen('part')}>
                        {f({id: 'component.Survey.Create.Analysis.Rows.Component.toolbar.button.0'})}
                    </RemoveButton>
                    {
                        (search === '' && allCount < 12000) && (
                            <RemoveButton disabled={allCount === 0} onClick={() => handleOpen('all')}>
                                {f({id: 'component.Survey.Create.Analysis.Rows.Component.toolbar.button.1'})}
                            </RemoveButton>
                        )
                    }
             
            </div>
        </ButtonBox>

        <RemoveUserDialogComponent 
            open={open} 
            where={where}
            checked={checked}
            search={search}
            startpoint={startpoint}
            isRemoveLastPageAllData={isRemoveLastPageAllData}
            onChangeStartpoint={onChangeStartpoint} 
            onRefetch={onRefetch}
            onClose={handleClose}
        />
        </>
    )
}

export default memo(RemoveButtonComponent)