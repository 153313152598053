/**
 * 더보기
 */
import { useState, useCallback, MouseEvent, memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp'
import Menu from '@material-ui/core/Menu'
import Tooltip from 'styled/Custom/Tooltip'
import { IconBox } from '../Buttons'
import ReportIconComponent from './ReportIcon'
import PrintIconComponent from './PrintIcon'

export interface Props {
    survey_no: number;
}

export const ItemBox = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${oc.gray[4]}`,
        borderRadius: 5,
        background: 'transparent',
        transition: 'all .3s',
        cursor: 'pointer',
        '&:hover': {
            background: oc.gray[2]
        }
    }
})(Box)

function MoreComponent(props: Props) {
    const { survey_no } = props
    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<null | SVGSVGElement>(null)

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleOpen = (event: MouseEvent<any>):void => {
        setAnchorEl(event.currentTarget)
    }

    const click = Boolean(anchorEl)

    return (
        <>
        <Tooltip title={f({id: "component.Survey.Create.Analysis.Summary.More.button.title"})} placement="top">
            <IconBox click={click ? 1 : 0} onClick={handleOpen}>
                <MoreVertSharpIcon/>
            </IconBox>
        </Tooltip>
        <Menu
            id="summary-more"
            anchorEl={anchorEl}
            keepMounted
            elevation={10}
            getContentAnchorEl={null}
            open={click}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div>
                <ReportIconComponent onClose={handleClose}/>
            </div>
            <div>
                <PrintIconComponent survey_no={survey_no} onClose={handleClose}/>
            </div>
        </Menu>
        </>
    )
}

export default memo(MoreComponent)