import { useContext, useState, useEffect } from 'react'
import { IconButton }from '@material-ui/core'
import { Save as SaveIcon, Close as CloseIcon, Replay as ReplayIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import Tabs from 'styled/Survey/Edit/Tabs'
import Tooltip from 'styled/Custom/Tooltip'
import { thanksScrolls } from 'container/Survey/Create/Thanks/Edit'
import CloseComponent from './Close'

function TabsComponent(props) {
    const { tabs } = props
    const { category, tabIndex, on, onClose } = useContext(PropsContext)

    const { formatMessage: f } = useIntl()

    const [ zeroTop, setZeroTop ] = useState(true)

    useEffect(() => {
        document.getElementById(thanksScrolls.id).addEventListener('scroll', function(e) {
            setZeroTop(e.target.scrollTop < 30)
        })
    }, [])

    return (
        <Tabs tabs={tabs} index={tabIndex} borderRadius={zeroTop}  onChange={on.changeTab}>
            <Tooltip title={f({id: 'component.Survey.Create.Thanks.Edit.Tabs.save'})} placement="top">
                <IconButton color="primary" onClick={on.save[category]} >
                    <SaveIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
            <Tooltip title={f({id: 'component.Survey.Create.Thanks.Edit.Tabs.init'})} placement="top">
                <IconButton onClick={on.changeInit}>
                    <ReplayIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
            <CloseComponent onClose={onClose}/>
        </Tabs>
    )
}

export default TabsComponent