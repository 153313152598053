import { memo, useState, useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { Method } from 'gql/survey_mbox'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import HelpIcon from '@material-ui/icons/Help'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContext from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import HelpMultiComponent from './HelpMulti'
import HelpOneComponent from './HelpOne'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'

export const HelpButton = withStyles({

})((props:{onClick: () => void}) => {
    const { onClick, ...other } = props
    const { formatMessage: f } = useIntl()

    return (
        <Button {...other} variant="contained" startIcon={<HelpIcon color="action"/>} size="small" disableElevation onClick={onClick}>
            {f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonHelp.button.help'})}
        </Button>
    )
})

function ButtonHelpComponent() {
    const { mbox } = useContext(Context)
    const { method } = mbox

    const { formatMessage: f } = useIntl()

    const refContent = useRef<HTMLDivElement | null>(null)

    const [ open, setOpen ] = useState(false)
    const [ _method, setMethod ] = useState(method)

    useEffect(() => {
        if (refContent.current) {
          refContent.current.scrollTop = 0
        }
    }, [_method, refContent])

    return (
        <>
        <HelpButton onClick={() => setOpen(true)}/>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogTitle>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonHelp.dialog.title'})}</DialogTitle>
            <ToggleButtonGroup 
                size="small" 
                style={{marginLeft: 20, marginBottom: 10}} 
                value={_method} 
                exclusive 
                onChange={(e, value) => {
                    if (!value) return
                    setMethod(value)
                }}
            >
                <ToggleButton value={Method.One}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonHelp.dialog.toggle.one'})}</ToggleButton>
                <ToggleButton value={Method.Multi}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonHelp.dialog.toggle.multi'})}</ToggleButton>
            </ToggleButtonGroup>
            <DialogContext ref={refContent} dividers>
                <DialogContentText>
                    {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonHelp.dialog.content'}))}
                </DialogContentText>
                {_method === Method.Multi ? <HelpMultiComponent/> : <HelpOneComponent/>}
            </DialogContext>
            <DialogActions>
                <Button  onClick={() => setOpen(false)}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonHelp.dialog.button.close'})}</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default memo(ButtonHelpComponent)