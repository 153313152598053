import { ChangeEvent, useCallback, useState } from 'react'

export interface Props {
    type: string;
    value: string;  
    onChange: (type: string, value: string) => void
}

let timer: ReturnType<typeof setTimeout>

function useTextFiled(props: Props) {
    const { type, value, onChange } = props

    const [ val, setVal ] = useState<string>(value)

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setVal(String(value))

        clearTimeout(timer)
        timer = setTimeout(() => {
            onChange(type, value)
        }, 300)
    }, [type, onChange])

    return [ val, handleChange ]
}

export default useTextFiled