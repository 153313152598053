import { memo } from 'react'
import { IconButton }from '@material-ui/core'
import { Save as SaveIcon, Close as CloseIcon, Replay as ReplayIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import Tabs from 'styled/Survey/Edit/Tabs'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    onSave: () => void;
    onInit: () => void;
    onClose: () => void;
}

function TabsComponent(props: Props) {
    const { onSave, onClose, onInit } = props

    const { formatMessage: f } = useIntl()

    const tabs = [f({id: 'container.Survey.Edit.01.tabs.0'})]

    return (
        <Tabs tabs={tabs} index={0} borderRadius={true}  onChange={() => {}}>
            <Tooltip title={f({id: 'component.Survey.Create.Thanks.Edit.Tabs.save'})} placement="top">
                <IconButton color="primary" onClick={onSave} >
                    <SaveIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
            <Tooltip title={f({id: 'component.Survey.Create.Thanks.Edit.Tabs.init'})} placement="top">
                <IconButton onClick={onInit}>
                    <ReplayIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
            <Tooltip title={f({id: 'component.Survey.Create.Thanks.Edit.Tabs.close'})} placement="top">
                <IconButton onClick={onClose} >
                    <CloseIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
        </Tabs>
    )
}

export default memo(TabsComponent)