import { memo } from 'react'
import { MyList, MyBox } from '../Lists/ButtonBox'
import ButtonSaveComponent from './ButtonSave'
import HelpComponent from './Help'
import { ContainerBox } from '../ExampleCount/ButtonBox'

function ButtonBoxComponent() {
    return (
        <ContainerBox>
            <MyList dense disablePadding>
                <MyBox>
                    <div>
                        <div>
                            <HelpComponent/>
                        </div>
                    </div>     
                    <div>
                    <ButtonSaveComponent/>
                    </div>
                </MyBox>
            </MyList>
        </ContainerBox>
    )
}

export default memo(ButtonBoxComponent)