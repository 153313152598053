/**
 * 문항 삭제
 */

import { memo } from 'react'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DeleteIcon from '@material-ui/icons/Delete'
import { Props as ParentProps } from './SelectBox'

interface Props {
    keyValue: ParentProps['keyValue'];
    filterLen: number;
    onDelete: ParentProps['onDelete'];
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '100%', 
        height: '100%',
        transition: 'all .3s',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    }
}))

function DeleteSelectBoxComponent(props: Props) {
    const { keyValue, filterLen, onDelete } = props

    const classes = useStyles()

    if (filterLen === 0) return null

    return (
        <Paper variant="outlined" className={classes.root} onClick={() => onDelete(keyValue || '')}>
            <DeleteIcon color="action" />
        </Paper>
    )
}

export default memo(DeleteSelectBoxComponent)