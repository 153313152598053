// 개인정보 데이터 안내 창
import { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { parseHtmlIntl } from 'ts-utils'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar';
import { DELETE_SURVEY_ANALYSIS_QUESTION } from 'gql/survey_analysis_users'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Tooltip from 'styled/Custom/Tooltip'
import { Props as ParentProps, OnClose, Open, AnchorEl } from './TableHeadPrivacy'
import { MyBox, IconComponent } from './TableHeadCellValue'
import { ConditionContent } from './RemoveUserDialog'

interface Props {
    survey_no: ParentProps['survey_no']; 
    anchorEl: AnchorEl;
    open: Open; 
    field: ParentProps['field'];
    labelArray: ParentProps['labelArray'];
    tooltip: ParentProps['tooltip'];
    __typename: ParentProps['__typename'];
    step: ParentProps['step'];
    date: ParentProps['date'];
    refetch: ParentProps['refetch'];
    onClose: OnClose;
}

const useStyles = makeStyles(theme => ({
    text: {
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 520
    },
    dialogContent: {
        maxWidth: 600,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%'
        }
    },
    checkboxWrapper: {
        display: 'flex',
        width: '100%',
        gap: 5,
        '& > div:first-child': {
            width: 25
        }
    }
}))

const getIsComment = (typename: string) => typename === '_01' || typename === '_02' || typename === '_19'

function TableHeadPrivacyDialogComponent(props: Props) {
    const { survey_no, open, anchorEl, field, labelArray, tooltip, __typename, step, date, refetch, onClose } = props
    
    const { formatMessage: f } = useIntl()

    const [ remove, { loading } ] = useMutation(DELETE_SURVEY_ANALYSIS_QUESTION, {
        onCompleted: () => {
            refetch();
            onClose();
        },
        onError: () => {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                message: f({ id: 'ccomponent.AlertSnackbar.errorMessage'}), 
                variant: 'error'
            })
        }
    })

    const classes = useStyles()

    const dispatch = useDispatch()

    const isComment = getIsComment(__typename)

    const [ agree, setAgree ] = useState(false)

    // 01,02 선택형에서 현재 사용된다.
    const [ agree2, setAgree2 ] = useState(false)

    const handleChangeAgree = () => {
        setAgree(prevState => !prevState)
    }

    const handleChangeAgree2 = () => {
        setAgree2(prevState => !prevState)
    }

    const handleClick = () => {
        remove({
            variables: {
                survey_no,
                survey_question_no: Number(field.replace('Q', '')), 
                typename: __typename, 
                date, 
                step: Number(step)
            }
        })
    }

    useEffect(() => {
        return () => {
            setAgree(false)
            setAgree2(false)
        }
    }, [])

    // 선택형이면 디폴트로 미선택
    useEffect(() => {
        setAgree2(!isComment)
    }, [isComment])

    const agreeBool = agree && agree2

    const id = open ? 'privacy-dialog' : undefined;

    return (
        <Popover 
        id={id} 
        open={open} 
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        onClose={loading ? () => {} : onClose}
    >
            <DialogTitle>{f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.title' })}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <MyBox>
                    <div>
                        <IconComponent f={f} typename={__typename} isComment={false} />
                    </div>
    
                    <div>
                        <Tooltip title={tooltip}>
                            <span>
                                {
                                    labelArray.map((c, i) => (
                                        <Typography key={`${i}-${c}`} className={classes.text} component="span">{c}</Typography>
                                    ))
                                }
                            </span>
                        </Tooltip>
                    </div>
                </MyBox>
                <div style={{ height: 15 }} />
                <ConditionContent date={date} step={step} search="" isSearchColumn={false} />
                <Box className={classes.checkboxWrapper} 
                    onClick={handleChangeAgree}
                    style={{ marginTop: 20 }}
                >
                    <div>
                        {
                            agree ? (
                                <CheckBoxIcon color="primary" style={{ display: 'block' }} />
                            ) : (
                                <CheckBoxOutlineBlankIcon color="action" style={{ display: 'block' }} />
                            )
                        }
                    </div>
                    <div>
                        <Typography variant='body2'>
                            {f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.explain.common' })}
                        </Typography>
                       
                    </div>
                </Box> 
                {
                    isComment && (
                        <Box className={classes.checkboxWrapper} 
                            onClick={handleChangeAgree2}
                            style={{ marginTop: 10 }}
                        >
                            <div>
                                {
                                    agree2 ? (
                                        <CheckBoxIcon color="primary" style={{ display: 'block' }} />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon color="action" style={{ display: 'block' }} />
                                    )
                                }
                            </div>
                            <div>
                                <Typography variant='body2'>
                                {
                                    __typename === '_19' ? (
                                        f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.explain.file' })
                                    ) : (
                                        f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.explain.choice' })
                                    )
                                }
                                </Typography>
                            
                            </div>
                        </Box> 
                    )
                }
                <Box className={classes.checkboxWrapper} 
                    style={{ marginTop: 10 }}
                >
                    <div>
                   
                    </div>
                    <div>
                        <Typography variant='body2' color="secondary">
                        {parseHtmlIntl(f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.caution' }))}
                        </Typography>
                        <Typography variant='body2' style={{ marginTop: 10 }}>
                        {f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.remove' })}
                        </Typography>
                    </div>
                </Box> 
            </DialogContent>
            <DialogActions>
                {
                    loading ? (
                        <CircularProgress color="inherit" size={15} />
                    ) : (
                        <Button color='secondary' disabled={!agreeBool} onClick={handleClick}>
                            {f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.button.remove' })}
                        </Button>
                    )
                }
                <Button onClick={onClose}>
                    {f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacyDialog.button.close' })}
                </Button>
            </DialogActions>
        </Popover>
    )    
}

export default memo(TableHeadPrivacyDialogComponent)
