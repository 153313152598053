/**
 * 문항 목록 선태하는 버튼
 */
import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import useOnClickOutside from 'hooks/useOnClickOutside'
import SelectBoxOptionsComponent from './SelectBoxOptions'
import { OnChange, OnDelete } from './List'
import SelectBoxOptionComponent from  './SelectBoxOption'
import DeleteSelectBoxComponent from  './DeleteSelectBox'
import { QuestionOption, QuestionOptionProps } from './Component'

export interface Props {
    questionOptions: QuestionOption;
    keyValue?: string;
    selectedQuestionOption?: QuestionOptionProps;
    selectedQuestionOptionKeys: string[]; 
    filterLen: number;
    onChange: OnChange;
    onDelete: OnDelete;
}

export const SelectBoxGrid = withStyles({
    root: {
        position: 'relative',
    }
})(props => <Grid {...props} item xs={11} sm={11} md={11} xl={11} lg={11}/>)

export const RemoveGrid = withStyles({
    root: {
        paddingLeft: 10
    }
})(props => <Grid {...props} item xs={1} sm={1} md={1} xl={1} lg={1}/>)

function SelectButtonComponent(props: Props) {
    const { questionOptions, keyValue, selectedQuestionOption, selectedQuestionOptionKeys, filterLen, onChange, onDelete } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    const handleClick = useCallback(() => {
        setOpen(prevState => !prevState)
    }, [])

    const handleClose = () => {
        setOpen(false)
    }

    // 문한 선택
    const handleChange: OnChange = useCallback((originKey, key, data) => {
        onChange(originKey, key, data)
        setOpen(false)
    }, [onChange])

    const ref = useOnClickOutside<HTMLDivElement>(handleClose)

    let primary = f({id: 'component.Survey.Create.Analysis.Filter.SelectBox.primary'})
    
    let secondary: string | undefined = ''
    let typename = ''
    
    if (keyValue && selectedQuestionOption) {
        primary = selectedQuestionOption.pure_question
        secondary = selectedQuestionOption.text
        const [ __typename ] = keyValue.split('-')

        if (__typename) typename = __typename
    }

    return (
        <div ref={ref}>
            <Grid container>
                <SelectBoxGrid>
                    <SelectBoxOptionComponent primary={primary} secondary={secondary} typename={typename} indexs={0} onChange={handleClick} selectBoxOpen={open} />
                    <SelectBoxOptionsComponent keyValue={keyValue} open={open} questionOptions={questionOptions} selectedQuestionOption={selectedQuestionOption} selectedQuestionOptionKeys={selectedQuestionOptionKeys} onChange={handleChange} />
                </SelectBoxGrid>
                <RemoveGrid>
                    <DeleteSelectBoxComponent filterLen={filterLen} keyValue={keyValue} onDelete={onDelete}/>
                </RemoveGrid>
            </Grid>

        </div>
    )
}

export default memo(SelectButtonComponent)