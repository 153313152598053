import { memo } from 'react'
import { useIntl } from 'react-intl'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TextField from '@material-ui/core/TextField'

interface Props {
    size: number;
    onChange:(a:number) => void;
}

function PopoverImageComponent(props: Props) {
    const { size, onChange } = props

    const { formatMessage: f } = useIntl()

    
    return (
        <>
        <ListItem>
            <ListItemText>{f({id: 'component.Survey.Create.Share.PopoverSize.title'})}</ListItemText>
            <ListItemSecondaryAction>
                <TextField type="number" autoFocus value={size} onChange={(e) => onChange(Number(e.target.value))} size="small" style={{width: 60, textAlign: 'right'}}/>
            </ListItemSecondaryAction>
        </ListItem>
        </>
    )
}

export default memo(PopoverImageComponent)