import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils/index'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { ExampleBox, Number, FolderIcon, SubTitle1, SubTitle2 } from './HelpMulti'

function HelpOneComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <>
        <List>
            <SubTitle1>{f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.subtitle.0'})}</SubTitle1>
            <ListItem>
                <ListItemAvatar>
                    <Number>1</Number>
                </ListItemAvatar>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.0'})} 
                    secondary={parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.0'}))}
                />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Number>2</Number>
                </ListItemAvatar>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.1'})} 
                    secondary={parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.1'}))}
                />
            </ListItem>
            <SubTitle2>{f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.subtitle.1'})}</SubTitle2>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.2'})} 
                    secondary={
                        <>
                        {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.2'}))}
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.label.2'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.third.2'}))}
                        </ExampleBox>
                        </>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.3'})} 
                    secondary={parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.3'}))}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.4'})} 
                    secondary={
                        <>
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.label4.0'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.4'}))}
                        </ExampleBox>
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.label4.1'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.third.4'}))}
                        </ExampleBox>
                        </>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.5'})}
                    secondary={
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.label.5'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.5'}))}
                        </ExampleBox>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.6'})}
                    secondary={
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.label.6'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.6'}))}
                        </ExampleBox>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.primary.7'})}
                />
            </ListItem>
          
        </List>
        </>
    )
}

export default HelpOneComponent