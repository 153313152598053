import { memo } from 'react'
import oc from 'open-color'
import { StandardAnswer, StandardFile } from 'reducer/analysis/rows/personal'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Props as ParentProps, HideQuestion } from './Component';
import Items from './Items'

export interface Props {
    answer: StandardAnswer;
    files: StandardFile;
    questions: ParentProps['questions'];
    endings: ParentProps['endings'];
    hideQuestion: HideQuestion;
}

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100% - 94px)', 
        background: oc.gray[2], 
        borderBottomLeftRadius: 5, 
        borderBottomRightRadius: 5, 
        overflow: 'auto',
        paddingBottom: 100
    }
}))

function BodyComponent(props: Props) {
    const { answer, files, questions, endings, hideQuestion } = props

    const classes = useStyles()

    return (
        <div className={`${classes.root} body`} >
            <Items answer={answer} files={files} questions={questions} endings={endings} hideQuestion={hideQuestion} />
        </div>
    )
}

export default memo(BodyComponent)
