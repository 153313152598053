import { useIntl } from 'react-intl'
import { Kind } from './QuizKindSelect'
import { PointKind, OnChangePointKind } from './Quiz'
import QuizPointTabComponent from './QuizPointTab'

export interface Props {
    kind: Kind | null;
    pointKind: PointKind;
    onChange: OnChangePointKind;
}

function QuizTitleComponent(props: Props) {
    const { kind, pointKind, onChange } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        {
            kind === Kind.EampleCount ? (
                <>
                {f({id: 'component.Survey.Create.Menu.Quiz.radio.0'})}
                </>
            ) : (
                <QuizPointTabComponent pointKind={pointKind} onChange={onChange}/>
            )
        }
        </>
    )
}

export default QuizTitleComponent