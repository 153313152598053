/**
 * 행  확장 축소
 */

 import { memo, useEffect } from 'react'
 import { useIntl } from 'react-intl'
 import { useSelector, useDispatch } from 'react-redux'
 import { RootState } from 'reducer'
 import { CHANGE } from 'reducer/analysis/rows/expandRow'
 import { INIT } from 'reducer/analysis/rows/expandRow'
 import IconButton from '@material-ui/core/IconButton'
 import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
 import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing'
 import Tooltip from 'styled/Custom/Tooltip'
 
 interface Props {
     survey_no: number
 }
 
 function ExpandRowComponent(props: Props) {
     const { survey_no } = props
 
     const { formatMessage: f } = useIntl()
     
     const dispatch = useDispatch()
 
     const state = useSelector((states: RootState) => states.analysisRowsExpandRow)
     const { expand } = state
 
     const cookieName = `rows-expand-${survey_no}`
 
     const handleChange = (val: boolean) => {
         localStorage.setItem(cookieName, val === true ? "1" : "") 
         dispatch({ type: CHANGE, expand: val })
     }
 
     useEffect(() => {
         dispatch({ type: CHANGE, expand: localStorage.getItem(cookieName) === "1" ? true : false })
 
         return () => {
             dispatch({ type: INIT })
         }
     }, [ cookieName, dispatch ])
     
 
     return (
        <>
        {
            expand ? (
                <Tooltip title={f({id: "component.Survey.Create.Analysis.Rows.ExpandRow.title.0"})}>
                    <IconButton size="small" onClick={() => handleChange(false)}>
                        <FormatLineSpacingIcon />
                </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title={f({id: "component.Survey.Create.Analysis.Rows.ExpandRow.title.1"})}>
                    <IconButton size="small" onClick={() => handleChange(true)}>
                        <VerticalAlignCenterIcon />
                    </IconButton>
                </Tooltip>
            )
        }
        </>
     )
 }
 
 export default memo(ExpandRowComponent)