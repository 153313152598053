import { useEffect, useCallback, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { SHOW, HIDE } from 'reducer/survey/create/menu'
import { withBreakPoints } from 'hoc'
import MyIconButton from 'styled/Custom/IconButton'
import { MyBox } from '../Module/Method'

const MyItemBox = withStyles({
    root: {
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%'
    }
})(Box)

const Mobile = (props) => {
    const { breakpoints, children } = props
    const { xs } = breakpoints
    
    const { formatMessage: f } = useIntl()

    const anchor = useSelector(states => states.surveyCreateMenu)

    const dispatch = useDispatch()
    
    const open = Boolean(anchor.show)

    const handleClick = useCallback(e => {
        const show = e.currentTarget
        dispatch({type: SHOW, show })
    }, [dispatch])

    const handleClose = useCallback(() => {
        dispatch({type: HIDE })
    }, [dispatch])

    useEffect(() => {
        return () => {
            handleClose()
        }
    }, [handleClose])
    return (
        <>
        <MyBox visibility="hidden">
            <MyIconButton onClick={handleClick} backgroundcolor='rgba(0, 0, 0, .1)' backgroundcolorhover='rgba(0, 0, 0, .2)'> 
                <AddIcon color="primary"/>
            </MyIconButton>
        </MyBox>
        <Dialog open={open} fullScreen={xs} fullWidth onClose={handleClose}>
            <DialogContent style={{padding: 0}}>
                <MyItemBox>{children}</MyItemBox>
            </DialogContent>
            {
                xs && (
                    <DialogActions>
                        <Button onClick={handleClose}>{f({id: 'component.Survey.Create.Menu.Mobile.Button.close'})}</Button>
                    </DialogActions>
                )
            }
        </Dialog>
        </> 
    )
}

export default withBreakPoints(memo(Mobile))


