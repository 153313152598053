import { memo, useState, useContext, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { NULL, CHANGE } from 'reducer/survey/create/logic/jumpNull'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { CHANGE as EQUALCHANGE } from 'reducer/survey/create/logic/jumpEqual' 
import { CHANGE as ALARMCHANGE } from 'reducer/survey/create/logic/menuAlarm'
import Button from '@material-ui/core/Button'
import { isEqual } from 'lodash-es'
import { CREATE_JUMP, JumpProps, JumpRuleProps, Kind, Type, ID } from 'gql/jump'
import { ModuleTypeName } from 'gql/survey_question_modules'
import { SurveyPageProps } from 'gql/survey_page'
import { getType } from './ItemSplit'
import BeforeUnload from 'styled/BeforeUnload'
import { OnChangeSelectedPage, SelectedPage } from './Component'
import { Context, SurveyQuestionModulePropsInKey } from 'container/Survey/Create/Logic/Jump/Context'
import { ListsContext } from 'container/Survey/Create/Logic/Jump/ListsContext'

// 미체크된 값을 체크하는 함수
function getCheckrows(rows: JumpProps[], pages: SurveyPageProps[], questionsKeyPageNo: SurveyQuestionModulePropsInKey) {
    function check(props: { id: ID, kind: Kind, typename?: ModuleTypeName }) {
        const { id, kind, typename } = props

        function checkValue(val: string | number) {
            return val === ''
        }

        function forCheck(rules: JumpRuleProps[]) {
            if (rules.length === 0) return null

            // 값이 선택안되거나 입력안된게 있다면 해당 kind 및 id를 리턴한다.
            const isNull = rules.find(rule => {
                const { survey_question_nos, childs } = rule

                // Action survey_question_nos가 없다면 선택해야한다.
                if (survey_question_nos.length === 0) return true

                return childs.find(child => {
                    const { type, value, survey_module_nos, survey_module_answer_no } = child

                    if (type === Type.Point) return checkValue(value)
                    else {
                        if (!typename) return false

                        switch(getType(typename)) {
                            case 'base':
                            case 'point':
                            case 'image':
                                return survey_module_nos.length === 0
                            case 'matrix':
                            case 'matrixPoint':
                            case 'rank':
                            case 'rankImage':
                                return survey_module_nos.length === 0 || !survey_module_answer_no
                            case 'text': 
                                return checkValue(value)
                            default:
                                return false

                        }
                    }
                })
            })

            return isNull ? { id, kind } : null
        }

        const pickRow = kind === Kind.Page ? (
            rows.find(row => id === row.id && Kind.Page === row.kind)
        ) : (
            rows.find(row => id === row.id && Kind.Question === row.kind)
        )

        // jump.survey_question_nos default 값은 안넣으면 그냥 다음으로 가면돼서 체크안한다.
        if (pickRow) {
            const { rules } = pickRow
            
            return forCheck(rules)
        }

        return null
    }

    // 미체크값이 있다면 id, kind, survey_page_no 값을 리턴해준다.
    function returnValue(data: { id: ID, kind: Kind}, survey_page_no: number) {
        return { ...data, survey_page_no }
    }

    for (let i=0; i<pages.length; i++) {
        const { survey_page_no } = pages[i]

        // 해당 페이지에 점프로직이 있는지 체크.
        const isNull = check({ id: survey_page_no, kind: Kind.Page })
        if (isNull) return returnValue(isNull, survey_page_no)

        // 해당 페이지내 문항 체크
        if (questionsKeyPageNo) {
            const questions = questionsKeyPageNo[survey_page_no]

            // 헤당 문항값이 있다면
            if (questions) {
                for (let j=0; j<questions.length; j++) {
                    const { _question, __typename } = questions[j]
                    const { survey_question_no } = _question

                     // 해당 문항에 점프로직이 있는지 체크.
                    const isNull = check({ id: survey_question_no, kind: Kind.Question, typename: __typename })
                    if (isNull) return returnValue(isNull, survey_page_no)

                }
            }
        }
    }

    return null
}

// 규칙도 없고, 디폴트로 가는 값도 없다면 빈배열 취급한다.
function exclusionRows(rows: JumpProps[]) {
    return rows.filter(row => {
        const { rules, survey_question_nos } = row

        return !(rules.length === 0 && survey_question_nos.length === 0) 
    })
}

interface Props {
    selectedPage: SelectedPage; 
    onChangeSelectedPage: OnChangeSelectedPage;
}

function ButtonSaveComponent(props: Props) {
    const { selectedPage, onChangeSelectedPage } = props

    const { formatMessage: f } = useIntl()

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const dispatch = useDispatch()

    const { pages, questionsKeyPageNo } = useContext(Context)
    const { jump, refetchJump } = useContext(ListsContext)

    const jumpState = useSelector((state: RootState) => state.surveyCreateLogicJump)
    const { rows } = jumpState

    // 마운트시 jump 데이터를 처음 가져오면, 초기값으로 담는다.
    const [ initRows, setInitRows ] = useState<JumpProps[]>(jump)

    const [ createJump ] = useMutation(CREATE_JUMP, {
        onCompleted: () => {
            dispatch({ type: EQUALCHANGE, is: true }) 

            setTimeout(() => {
                refetchJump()
                dispatch({ type: BACKDROPHIDE})
                dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonSave.success'}), variant: 'info' })
                dispatch({ type: ALARMCHANGE, kinds: ['jump'] })
            }, 50) 
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE})
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const newRows = exclusionRows(rows)

    const handleSave = useCallback(() => {
        const checkRows = getCheckrows(newRows, pages, questionsKeyPageNo)

        if (checkRows) {
            const { id, kind, survey_page_no } = checkRows

            // 선택된 페이지의 화면을 보고있는데, 다른페이지에서 미설정값이 있다면 해당페이지로 값을 변경해준다.
            if (selectedPage) {
                onChangeSelectedPage(survey_page_no)
            }

            dispatch({ type: CHANGE, id, kind })
            return
        } 

        dispatch({ type: NULL })

        dispatch({ type: BACKDROPSHOW })

        createJump({ variables: {
            survey_no,
            data: newRows
        } })

    }, [
        survey_no, 
        newRows, 
        pages, 
        questionsKeyPageNo, 
        selectedPage, 
        onChangeSelectedPage,
        createJump, 
        dispatch
    ])

    const disabled = isEqual(newRows, exclusionRows(initRows))

    // 동일한 값인지 체크해서 발행 (./Diagram/Provider에서 구독)
    useEffect(() => {
        dispatch({ type: EQUALCHANGE, is: disabled })
    }, [disabled, dispatch])

    // refetch 후에는 다시 init에 담는다
    useEffect(() => {
        setInitRows(jump)
    }, [jump])

    return (
        <>
        <Button 
            variant="contained" 
            color="primary" 
            disabled={disabled} 
            disableRipple
            onClick={handleSave}
        >
            {f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonSave.button.save'})}
        </Button>
        {!disabled && <BeforeUnload/>}
        </>
       
    )
}

export default memo(ButtonSaveComponent)