import { useMemo, useContext, useCallback } from 'react'
import oc from 'open-color-js'
import { Draggable } from 'react-beautiful-dnd'
import { Element } from 'react-scroll'
import styled, { css } from 'styled-components'
import { ButtonGroup, Button, Typography } from '@material-ui/core'
import { Bookmarks as BookmarksIcon, Done as DoneIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { SurveyModulesComponent } from 'component'
import { useIntl } from 'react-intl'
import EditSurveyContext from 'context/editSurvey'
import Question from 'styled/Survey/Question'
import MyButton from 'styled/Custom/Button'
import Module from 'styled/Survey/Module'
import ShapeInfoComponent from './ShapeInfo'
import FileAndPictureComponent from 'component/Survey/Modules/FileAndPicture'

const ButtonTypography = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 13
        }
    }
}))(props => <Typography {...props} variant="button"/>)

const WrapperStyled = styled.div`
    padding: 1em;
    transition: all .3s;
    border-radius: 3px;

    & > .toolbar {
        transition: all .3s;
        margin-bottom: 1.3em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & > .content {  
        position: relative;
    }

    & > .content > .dimmed {
        position: absolute;
        background: transparent;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
    &:hover {
        background: rgba(0, 0, 0, .1);
    }

    ${({isEdit}) => (
        isEdit && (
            css`
            & > .toolbar {
                display: none;
            }

            &:hover {
                background: rgba(0, 0, 0, 0);
            }
            & > .content > .dimmed {
                display: none;
            }
            `
        ) 
    )}   
`
const getItemStyle = (isEdit, draggableStyle, isDragging) => {

    let style = {
        userSelect: 'none',
        margin: '0 0 70px 0',
        boxSizing: 'border-box',
        background: 'transparent',
        borderRadius: '5px',
        height: '100%'
    }
    

    if (isDragging) {
        style = {
            ...style,
            margin: 0,
            border: `1px solid ${oc.indigo8}`
        }
    }

    if (!isEdit) {
        style = {
            ...style,
            ...draggableStyle
        }
    }

    return style
}


const Component = (props) => {
    const { target, scrollerObj, _question, _modules, _answers, _option, mbox, index, reply, onOpenCopy, onConfirmRemove, onOpenAppend } = props
    const { survey_question_no, img_src, img_position, img_name, confirm, module_type } = _question


    // viewPicture: 편집시 문항보기 리스트보기 변환 기존 재생하는거 막기위한상태값, 실제 라이브나 프리뷰에서는 무조건 true이다 (현재 미사용)
    const { editOpen, edit, onOpenEdit, customDesign } = useContext(EditSurveyContext)

    const { formatMessage: f } = useIntl()

    const buttonMessage = customDesign ? confirm || f({id: 'component.Survey.Live.One.button'}) : ''

    const onEditOpen = useCallback((survey_question_no) => {
        if (typeof onOpenEdit === 'function') {
            onOpenEdit(survey_question_no)
        }
    },[onOpenEdit])

    const Buttons = useCallback(() => {
       
        return (
            <ButtonGroup color='primary' size="small" style={{background: 'white', fontSize: 12}}>
                <Button 
                onClick={() => onEditOpen(survey_question_no)}>
                    <ButtonTypography>{f({id: 'component.Survey.Create.Module.Question.edit'})}</ButtonTypography>
                </Button>
                <Button onClick={(e) => onOpenCopy(e, survey_question_no, 'move')}>
                    <ButtonTypography>{f({id: 'component.Survey.Create.Module.Question.move'})}</ButtonTypography>
                </Button>
                <Button onClick={(e) => onOpenCopy(e, survey_question_no, 'copy')}>
                    <ButtonTypography>{f({id: 'component.Survey.Create.Module.Question.copy'})}</ButtonTypography>
                </Button>
                <Button onClick={(e) => onOpenAppend(e, survey_question_no)}>
                    <ButtonTypography>{f({id: 'component.Survey.Create.Module.Question.create'})}</ButtonTypography>
                </Button>
                <Button onClick={(e) => onConfirmRemove(e, survey_question_no, module_type)}>
                    <ButtonTypography>{f({id: 'component.Survey.Create.Module.Question.remove'})}</ButtonTypography>
                </Button>
            </ButtonGroup>
        )
        
    }, [survey_question_no, module_type, onOpenCopy, onConfirmRemove, onOpenAppend , f, onEditOpen])

    return useMemo(() => {
        return (
            <Draggable key={`${survey_question_no}`} draggableId={`draggableId-${survey_question_no}`} index={index}>
            {(provided, snapshot) => {

                  // 편집수정시에는 이동이 안되기때문에 커서포인트를 default로 바꾼다
                let itemStyle = getItemStyle(editOpen, provided.draggableProps.style, snapshot.isDragging)
                itemStyle = editOpen ? { ...itemStyle, cursor: 'default' } : itemStyle
    
                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={itemStyle}>
                        <Element name={target === survey_question_no ? scrollerObj.target : `target-${survey_question_no}`}>  
                            <WrapperStyled isEdit={editOpen}>
                                <div className='toolbar'>
                                    {!snapshot.isDragging && (
                                        <>
                                        <Buttons/>
                                        <ShapeInfoComponent method={mbox.method} img_src={img_src} img_position={img_position}/>
                                        </>
                                    )}
                                </div>
                                <div className='content'>
                                    {(edit && edit.survey_question_no) === survey_question_no && <BookmarksIcon style={{position: 'absolute', left: '-1em'}} color="secondary"/>}
                                    <SurveyModulesComponent {..._question} required={typeof _option === 'undefined' ? false : _option.required}  isOneButton={Boolean(buttonMessage)}>
                                        <div className="question">
                                            <Question question={_question.question} mark_required={mbox.mark_required} />
                                        </div>
                                        <div className="line"></div>
                                        <div className="module">
                                            <Module _question={_question} _modules={_modules} _answers={_answers} _option={_option} reply={reply}/>
                                        </div>
                                        <div className="image">
                                            <FileAndPictureComponent
                                                method={mbox.method}
                                                isEditList={true}
                                                img_src={img_src}
                                                img_name={img_name}
                                            />
                                        </div>
                                        <div className="oneButton">
                                            {buttonMessage &&  <MyButton size="medium" startIcon={<DoneIcon/>}>{buttonMessage}</MyButton>}
                                        </div>
                                    </SurveyModulesComponent>
                                    <div className='dimmed'></div>
                                </div>
                            </WrapperStyled>
                        </Element>
                    </div>
                )}
            }
            </Draggable>
        )
    }, [
        img_position,
        img_src,
        img_name,
        survey_question_no,
        target, 
        scrollerObj,
        _question,
        _modules,
        _answers,
        _option,
        mbox,
        index,
        reply,
        edit,
        editOpen,
        buttonMessage,
        Buttons
    ])
    
}

export default Component