import { memo } from "react"
import Box from "@material-ui/core/Box"
import MenuItem from "@material-ui/core/MenuItem"
import Avatar from "@material-ui/core/Avatar"
import { withStyles } from "@material-ui/core/styles"
import fixedWidthString from 'fixed-width-string'
import { useIntl } from 'react-intl'
import withSurveyPopoverImage, { OnOpen, OnClose } from 'hoc/Survey/PopoverImage'
import { SurveyQuestionModuleProps, ModuleBase, ModuleImg  } from 'gql/survey_question_modules'
import { JumpNo } from 'gql/jump'
import { MySelect, MyTypography, OnChangeChildsProps } from './ItemRules'

interface Props extends OnChangeChildsProps {
    popoverImageProps: {
        on: {
            open: OnOpen,
            close: OnClose
        }
    },
    question: SurveyQuestionModuleProps;
    typename: string;
    jump_rule_child_no: JumpNo;
    survey_module_nos: number[];
}

export const MenuItemImgBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: 10
        }
    }
})(Box)

export const MyMenuItem = withStyles({
    root: {
        maxWidth: 600
    }
})(MenuItem)


function SelectModuleComponent(props: Props) {
    const { question, typename, jump_rule_child_no, survey_module_nos, onChange, popoverImageProps } = props

    const { on } = popoverImageProps
    const onOpen = on.open

    const { formatMessage: f } = useIntl()

    const { _modules } = question

    const survey_module_no = survey_module_nos.length > 0 ? Number(survey_module_nos[0]) : 0

    return (
 
        <MySelect
            error={survey_module_no === 0}
            displayEmpty 
            value={survey_module_no || ''} 
            style={{flexGrow: 1, padding: 0}} 
            label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectModule.label'})}
            onChange={(e) => {
                onChange('survey_module_nos', jump_rule_child_no, [ Number(e.target.value) ])
            }}
        >
            {
                (typename === 'base' || typename === 'point') ? (
                    _modules.map((c: ModuleBase) => {
                        const { survey_module_no, indexs, answer } = c

                        const label = typename === 'base' ? fixedWidthString(`${indexs}. ${answer}`, 30) : indexs
    
                        return (
                            <MyMenuItem key={survey_module_no} value={survey_module_no}>
                                <MyTypography>{label}</MyTypography>
                            </MyMenuItem>
                        )
                    })
                ) : typename === 'image' ? (  
                    _modules.map((c: ModuleImg) => {
                        const { survey_module_no, indexs, answer, src } = c

                        const label = fixedWidthString(`${indexs}. ${answer}`, 30)

                        return (
                            <MyMenuItem key={survey_module_no} value={survey_module_no}>
                                <MenuItemImgBox>
                                    <Avatar 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onOpen(src)
                                        }}
                                        src={src}
                                    />
                                    <MyTypography>{label}</MyTypography>
                                </MenuItemImgBox>
                            </MyMenuItem>
                        )
                    })
                ) : null
            }
        </MySelect>
    )
}

export default withSurveyPopoverImage(memo(SelectModuleComponent))