/**
 * 선택된 아이템 목록 설정
 */

import { memo, useState, useCallback, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash-es'
import { CHANGE } from 'reducer/analysis/filter/data'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import { QuestionOption, QuestionOptionProps, Choice, getIsComment, notingQuestionOption } from './Component'
import SelectBoxComponent from './SelectBox'
import AddSelectBoxComponent from './AddSelectBox'
import DividerComponent from './Divider'
import Item01Component from './Item01/Component'
import Item01CommentComponent from './Item01/Comment/Component'


interface Props {
    questionOptions: QuestionOption;
    defaultQuestionOptions: QuestionOption;
}

export type SelectedQuestion = any;

export type OnChange = (originKey: string, key: string, data: QuestionOptionProps) => void;
export type OnDelete = (key: string) => void;
export type OnAdd = () => void;
export type OnChangeChoice = (key: string, id: Choice['id']) => void;
export type OnChangeText = (key: string, text: string) => void;
export type OnChangeSign = (key: string, sign: string) => void;

const ItemBox = withStyles({
    root: {
        marginTop: 5
    }
})(Box)

const choiceType = [ '_01', '_02', '_03', '_04', '_05', '_06', '_07', '_08', '_11', '_12', '_13', '_14', '_15', '_17', '_18', '_21', '_ending' ]
const textType = [ '_09', '_10', '_20' ]

function SelectedItemsComponent(props: Props) {
    const { questionOptions, defaultQuestionOptions } = props

    const [ selectedQuestionOptions, setSelectedQuestionOptions ] = useState<QuestionOption>(defaultQuestionOptions)

    const dispatch = useDispatch()

    // 문항 선택
    const handleChange: OnChange = useCallback((originKey, key, data) => {
        // 같은 문항을 선택했다면 리턴~
        if (originKey === key) return

        setSelectedQuestionOptions(prevState => {
            let copyState = cloneDeep(prevState)
            
            // 문항 변경시 기존 문항을 제거하면서 기존 문항 순서에 위치해야한다.
            if (originKey !== key) {
                copyState = Object.keys(copyState).reduce((acc: QuestionOption, c: string) => {
                    if (c === originKey) {
                        acc[key] = data
                        return acc
                    }

                    acc[c] = copyState[c]
                    return acc
                }, {}) 
            }

            return copyState
        })
    }, [])

    // 문항 삭재
    const handleDelete: OnDelete = useCallback((key) => {
        setSelectedQuestionOptions(prevState => {
            const copyState = cloneDeep(prevState)
            delete copyState[key]

            // 모든 셀렉트가 지워지면 최초 선택할 수 있는 값은 있어야 하니께..
            if (Object.keys(copyState).length === 0) return {  "": notingQuestionOption }

            return copyState
        })
    }, [])

    const handleAdd: OnAdd = () => {
        setSelectedQuestionOptions(prevState => {
            return {
                ...prevState,
                "": notingQuestionOption
            }
        })
    }

    // 보기 선택
    const handleChangeChoice: OnChangeChoice = useCallback((key, id) => {
        setSelectedQuestionOptions(prevState => {
            const selfValue = prevState[key].value as number[]
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    value: selfValue.includes(id) ? selfValue.filter((c: Choice['id']) => c !== id) : [ ...selfValue, id]
                }
            }
        })
    }, [])

    // 코멘트 주관식 답변 입력
    const handleChangeText: OnChangeText = useCallback((key, text) => {
        setSelectedQuestionOptions(prevState => {
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    value: String(text).trim()
                }
            }
        })
    }, [])

    // 등호 변경
    const handleChangeSign: OnChangeSign = useCallback((key, sign) => {
        setSelectedQuestionOptions(prevState => {
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    sign
                }
            }
        })
    }, [])

    // 저장 버튼으로 값을 보낸다.
    useEffect(() => {
        dispatch({ type: CHANGE, data: selectedQuestionOptions })
    }, [selectedQuestionOptions, dispatch])

    // 설정이 된 문항의 key
    const selectedQuestionOptionKeys = Object.keys(selectedQuestionOptions).map(c => c)

    // 필터로 추가된 문항이 있는지 판단( 미선택한 것은 아니다)
    const filterLen = selectedQuestionOptionKeys.filter(c => c).length

    return (
        <>
        {
            selectedQuestionOptionKeys.map((key, i) => {
                const [ typename ] = key.split('-')

                const is_comment = getIsComment(key)

                const data = selectedQuestionOptions[key]

                return (
                    <Fragment key={key}>
                        {
                            i > 0 && (
                                <DividerComponent />
                            )
                        }
                        {
                            !typename ? (
                                <SelectBoxComponent 
                                    key=""
                                    questionOptions={questionOptions} 
                                    selectedQuestionOptionKeys={selectedQuestionOptionKeys} 
                                    filterLen={filterLen}
                                    onChange={handleChange} 
                                    onDelete={handleDelete}
                                />
                            ) : (
                                <SelectBoxComponent
                                    keyValue={key}
                                    selectedQuestionOption={data}
                                    selectedQuestionOptionKeys={selectedQuestionOptionKeys} 
                                    questionOptions={questionOptions} 
                                    filterLen={filterLen}
                                    onChange={handleChange} 
                                    onDelete={handleDelete}
                                />
                            )
                        }
                       
                        {
                            (is_comment || textType.includes(typename)) ? (
                                <ItemBox>
                                    <Item01CommentComponent typename={typename} keyValue={key} sign={data.sign} value={data.value as string} onChangeText={handleChangeText} onChangeSign={handleChangeSign} />
                                </ItemBox>
                            ) : (choiceType.includes(typename)) ? (
                                <ItemBox>
                                {
                                    is_comment ? (
                                        <Item01CommentComponent typename={typename} keyValue={key} sign={data.sign} value={data.value as string} onChangeText={handleChangeText} onChangeSign={handleChangeSign} />
                                    ) : (
                                        <Item01Component keyValue={key} choices={data.choices || []} value={data.value as number[]} onChange={handleChangeChoice} />
                                    )
                                }
                                </ItemBox>
                            ) : null
                        }
                    </Fragment>
                )
            })
        }
        {
            (filterLen > 0 && filterLen < Object.keys(questionOptions).length) && (
                <AddSelectBoxComponent onAdd={handleAdd} />
            )
        }
        </>
    )
}

export default memo(SelectedItemsComponent)