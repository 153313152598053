import { Divider, List, ListItem, ListItemText, TextField, MenuItem, IconButton, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Help as HelpIcon } from '@material-ui/icons'
import { MyListItemIcon, MyCodeItem } from 'styled/Survey/Share/Embed'
import Tooltip from 'styled/Custom/Tooltip'
import { useIntl } from 'react-intl'

const useStyles = makeStyles({
    textField: {
        width: '50%'
    },
    selectField: {
        width: 45
    },
    title: {
        whiteSpace: 'pre'
    }
})
export default (props) => {
    const { standard, on } = props
    const { width, widthUnit, height, heightUnit, shadow } = standard

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <List>
            <ListItem>
                <ListItemText className={classes.title}>{f({id: 'component.Survey.Create.Share.Standard.lists.0'})}</ListItemText>
                <MyListItemIcon>
                    <TextField className={classes.textField} value={width} type="number" onChange={(e) => on.changeWidth(e.target.value)}/>
                </MyListItemIcon>
                <MyListItemIcon>
                    <TextField className={classes.selectField} select value={widthUnit} onChange={(e) => on.changeWidthUnit(e.target.value)}>
                        <MenuItem value="%">%</MenuItem>
                        <MenuItem value="px">px</MenuItem>
                        <MenuItem value="vh">vh</MenuItem>
                    </TextField>
                </MyListItemIcon>
               
            </ListItem>
            <Divider/>
            <ListItem>
                <ListItemText className={classes.title}>
                    {f({id: 'component.Survey.Create.Share.Standard.lists.1'})}            
                    <IconButton size="small">
                        <Tooltip title={f({id: 'component.Survey.Create.Share.Standard.tooltip.height'})} isMobileClick={true}>    
                            <HelpIcon fontSize="small" color="action"/>
                        </Tooltip>
                    </IconButton>
                </ListItemText>
                <MyListItemIcon>
                    <TextField className={classes.textField} value={height} type="number" onChange={(e) => on.changeHeight(e.target.value)}/>
                </MyListItemIcon>
                <MyListItemIcon>
                    <TextField className={classes.selectField} select value={heightUnit} onChange={(e) => on.changeHeightUnit(e.target.value)}>
                        <MenuItem value="%">%</MenuItem>
                        <MenuItem value="px">px</MenuItem>
                        <MenuItem value="vh">vh</MenuItem>
                    </TextField>
                </MyListItemIcon>
            </ListItem>
            <Divider/>
            <ListItem>
                <ListItemText className={classes.title}> {f({id: 'component.Survey.Create.Share.Standard.lists.3'})}</ListItemText>
                <MyListItemIcon>
                    <Switch checked={shadow} onChange={(e) => on.changeShadow(e.target.checked)}/>
                </MyListItemIcon>
            </ListItem>
            <Divider/>
            <MyCodeItem onClick={on.openCode}>
                {f({id: 'component.Survey.Create.Share.Standard.lists.2'})}
            </MyCodeItem>
        </List>
    )
}