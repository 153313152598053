import styled from 'styled-components'
import { getShareLink } from 'utils/survey'

const IframeStyled = styled.iframe`
    position: absolute;
    visibility: visible;
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: 100%;
    max-height: 100%;
    min-height: 100px;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s;
    background-color: white;
    box-shadow: ${props => props.shadow ? '1px 1px 2px #e9ecef, 0 0 25px #868e96, 0 0 5px #e9ecef' : 'none'};
    overflow-y: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
`

export default (props) => {
    const { width, widthUnit, height, heightUnit, shadow, args } = props

    const _width = `${width}${widthUnit}`
    const _height = heightUnit === '%' ? '100px' : `${height}${heightUnit}`

    const shareLink = getShareLink({args})
    const src = shareLink.survey.preview

    return (
        <>
        <IframeStyled width={_width} height={_height} shadow={shadow} src={src}/>
        </>
    )
}