import { memo } from 'react'
import Tab from 'styled/Survey/Edit/Tab'

interface Props {
    index: number;
    children: Element

}

// tabIndex 해당 컨첸츠 인덱스
// index 현재 선택된 인덱스
function TabComponent(props: Props) {
    const { index, children } = props
    
    return (
        <Tab tabIndex={0} index={index}>
            {children}
        </Tab>
    )
}

export default memo(TabComponent)