import { useIntl } from 'react-intl'
import oc from 'open-color'
import { 
    Grid, 
    List, 
    Card, 
    CardHeader, 
    ListItem, 
    ListItemText, 
    ListItemIcon, 
    Checkbox, 
    Divider
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CreateBox from 'styled/Survey/CreateBox'
import Tooltip from 'styled/Custom/Tooltip'
import SwitchButton from './SwitchButton'

const useListStyles = makeStyles((theme) => ({    
    root: {},
    cardHeader: {
        width: '100%',
        padding: theme.spacing(1, 2)
    },
    list: {
        width: '100%',
        height: 360,
        [theme.breakpoints.down('md')]: {
            height: 240,
        },
        [theme.breakpoints.down('sm')]: {
            height: 240,
        },
        [theme.breakpoints.down('xs')]: {
            height: 140,
        },
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto'
    },
    previewIcon: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    iconBox: {
        padding: 1, 
        borderRadius: 3, 
        border: `1px solid ${oc.gray[3]}`,
        transition: 'all .3s',
        '&:hover': {
            border: `1px solid ${oc.gray[5]}`,
            background: oc.gray[4]
        }
    }
}))

const CustomList = (props) => {
    const { formatMessage: f } = useIntl()
    const { survey_page_no: surveyPageNo, allChecked, target, items, on } = props

    const classes = useListStyles()

    return (
        <Card variant="outlined">
            <CardHeader 
            className={classes.cardHeader} 
            avatar={<Checkbox checked={allChecked} name={`c-${target}`} onClick={on.changeAll} disabled={items.length === 0}/>} title={ f({id: `component.Survey.Create.Logic.QuestionRandom.Component.list.subject.${target}`})} subheader={`${items.filter(c => c.checked).length}/${items.length} selected`}/>
            <Divider />
            <List className={classes.list}  dense component="div" role="list">
            {items.map(c => {
                const { survey_page_no, indexs, checking } = c
                const labelId = `transfer-list-all-item-${survey_page_no}-label`
                const labelName = `transfer-list-all-item-${survey_page_no}-name`

                // 문항리스트 보기로 선택된 페이지인지 판단
                const isQuestions = surveyPageNo === survey_page_no

                return (
                    <ListItem key={survey_page_no} role="listitem" button dense>
                        <ListItemIcon  onClick={() => on.change(survey_page_no)}>
                            <Checkbox name={labelName} checked={checking} disableRipple inputProps={{ 'aria-labelledby': labelId }}/>
                        </ListItemIcon>
                        
                        <ListItemText  onClick={() => on.change(survey_page_no)} id={labelId} primary={f({id: 'component.Survey.Create.Logic.QuestionRandom.Component.list.page'}, {page: indexs})} />
                        <Tooltip 
                            title={f({id: 'component.Survey.Create.Logic.QuestionRandom.Component.questions.on' })} 
                        >
                        <ListItemIcon className={classes.previewIcon} onClick={() => {
                            on.openQuestions(survey_page_no)
                        }}>
                            <div className={classes.iconBox}>
                                <VisibilityIcon color={isQuestions ? 'primary': 'inherit'} style={{ display: 'block' }} />
                            </div>
                        </ListItemIcon>
                        </Tooltip>

                        
                    </ListItem>
                )
            })}
            <ListItem />
            </List>
        </Card>
    )
}

export default props => {
    const { survey_page_no, question_random, allCheckedQuestionRandom, on } = props

    return (
        <CreateBox>
            <Grid container spacing={2} alignItems="center">
            
                <Grid item lg={5} md={12} sm={12} xs={12}>
                    <CustomList survey_page_no={survey_page_no} allChecked={allCheckedQuestionRandom[0]} target={0} items={question_random.filter(c => !c.checked)} on={on}/>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                    <SwitchButton onAdd={on.add} onRemove={on.remove}/>
                </Grid>
                <Grid item lg={5} md={12} sm={12} xs={12}>
                    <CustomList survey_page_no={survey_page_no} allChecked={allCheckedQuestionRandom[1]} target={1} items={question_random.filter(c => c.checked)} on={on}/>
                </Grid>
            </Grid>
        </CreateBox>
    )
}