import { memo } from 'react'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Props as ParentProps } from './Component'

interface Props {
    hide: ParentProps['hideQuestion'];
    onChange: ParentProps['onChangeHideQuestion']
}

function HideComponent(props: Props) {
    const { hide, onChange } = props

    const { formatMessage: f } = useIntl()
    
    return (
        <Button 
            variant="outlined" 
            size="small" 
            style={{ paddingTop: 0, paddingBottom: 0 }}  
            startIcon={hide ? <CheckBoxIcon color="action" /> : <CheckBoxOutlineBlankIcon color="action" />}
            onClick={() => onChange(!hide)}
        >
            {f({id: 'component.Survey.Create.Analysis.Rows.Personal.list.verifyUsed'})}
        </Button>
    )
}

export default memo(HideComponent)