import { memo } from 'react'
import Slider from '@material-ui/core/Slider'
interface Props {
    background_opacity: number;
    onChange: (e: any, newValue: number) => void
}

function BackgroundCustomComponent(props: Props) {
    const { background_opacity, onChange } = props

    return (
        <Slider value={(100 * background_opacity)} track="inverted" onChange={onChange}/>
    )
}

export default memo(BackgroundCustomComponent)















