// 문항 상단에 고정 컴포넌트
import { memo } from "react"
import { useIntl } from "react-intl"
import oc from 'open-color'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import HelpIcon from '@material-ui/icons/Help'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import { Props as ParentProps } from './Logo'

interface Props {
    questionTopUsed: ParentProps['questionTopUsed'];
    questionTopAlign: ParentProps['questionTopAlign'];
    onChangeUsed: ParentProps['onChangeQuestionTopUsed'];
    onChangeAlign: ParentProps['onChangeQuestionTopAlign'];
}

const useStyles = makeStyles(theme => ({
    helpBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 5
    },
    helpIcon: {
        [theme.breakpoints.down('xs')]: {
     
        }
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    align: {
        display: 'flex', 
        alignItems: 'center', 
        gap: 10,
        [theme.breakpoints.down('xs')]: {
            gap: 20,
        }
    },
    questionFixed: {
        border: `1px solid ${oc.gray[3]}`,
        borderRadius: 5,
        padding: 10
    },
    questionFixedCheckbox: {
       display: 'flex',
       alignItems: 'center',
       gap: 5
    },
    questionFixedTip: {
        color: theme.palette.action.active,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    icon: {
        display: 'block'
    },
    radio: {
        cursor: 'pointer'
    }
}))

function QuestionTopComponent(props: Props) {
    const { questionTopUsed, questionTopAlign, onChangeUsed, onChangeAlign } = props
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <Box className={classes.questionFixed}>
            <Grid container spacing={1}>
                <Grid item xs={8} sm={4} md={4}>
                    <Box className={classes.helpBox}>
                        <Typography variant="body2">{f({id: 'component.Survey.Create.Brand.Edit.QuestionTop.items.0'})}</Typography>
                        <Tooltip title={f({id: 'component.Survey.Create.Brand.Edit.QuestionTop.explain.0'})} isMobileClick={true}><HelpIcon color="action" fontSize="small" className={classes.helpIcon}/></Tooltip>
                    </Box>

                </Grid>
                <Grid item xs={4} sm={8} md={8}>
                    <Box className={classes.questionFixedCheckbox}>
                        {
                            questionTopUsed ? (
                                <CheckBoxIcon  color="primary" className={`${classes.icon} ${classes.radio}`} onClick={() => onChangeUsed(false)}/>
                            ) : (
                                <CheckBoxOutlineBlankIcon color="action"  className={`${classes.icon} ${classes.radio}`} onClick={() => onChangeUsed(true)}/>
                            ) 
                        }
                    </Box>
                </Grid>
                {
                    questionTopUsed && (
                        <>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography variant="body2">{f({id: 'component.Survey.Create.Brand.Edit.QuestionTop.items.1'})}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Box className={classes.align}>
                                <Box className={classes.checkbox} onClick={() => onChangeAlign('left')}>
                                    {
                                        questionTopAlign === 'left' ?  <RadioButtonCheckedIcon color="primary" className={classes.radio}/> :  <RadioButtonUncheckedIcon color="action" className={classes.radio}/>
                                    }
                                    <Typography variant="body2" className={classes.radio}>{f({id: 'component.Survey.Create.Brand.Edit.QuestionTop.align.0'})}</Typography>
                                </Box>
                                <Box className={classes.checkbox} onClick={() => onChangeAlign('center')}>
                                    {
                                        questionTopAlign === 'center' ?  <RadioButtonCheckedIcon color="primary" className={classes.radio}/> :  <RadioButtonUncheckedIcon color="action" className={classes.radio}/>
                                    }
                                    <Typography variant="body2" className={classes.radio}>{f({id: 'component.Survey.Create.Brand.Edit.QuestionTop.align.1'})}</Typography>
                                </Box>
                                <Box className={classes.checkbox}  onClick={() => onChangeAlign('right')}>
                                    {
                                        questionTopAlign === 'right' ?  <RadioButtonCheckedIcon color="primary" className={classes.radio}/> :  <RadioButtonUncheckedIcon color="action" className={classes.radio}/>
                                    }
                                    <Typography variant="body2" className={classes.radio}>{f({id: 'component.Survey.Create.Brand.Edit.QuestionTop.align.2'})}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        </>
                    )
                }
                
            </Grid>
        </Box>
    )
}

export default memo(QuestionTopComponent)