import styled from 'styled-components'
import CreateBox from 'styled/Survey/CreateBox'

const WrapperStyled = styled.div`
    display: ${props => props.show ? 'block' : 'none'};
`
// 좌측 메뉴 다음에 나오는 서브메뉴 or 편집화면에 들어가는 ㅋ
export default (props) => {
    const { show, children } = props

    return (
        <WrapperStyled show={show}>
            <CreateBox>
                {children}
            </CreateBox>
        </WrapperStyled>
    )
}