import { ReactChild } from 'react'
import { useIntl } from "react-intl"
import Popover, { PopoverOrigin } from "@material-ui/core/Popover"
import { withStyles } from '@material-ui/core/styles'
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Props as OtherCopyProps } from './OtherCopy'
import CopySaveComponent from './CopySave'

interface Props {
    anchorEl: OtherCopyProps['anchorEl'];
    type: OtherCopyProps['type'];
    onClose: OtherCopyProps['onClose'];
    onSave: OtherCopyProps['onSave'];
    children: ReactChild | ReactChild[];
}

const anchorOrigin:PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
}

const transformOrigin:PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center'
}

const MyPopover = withStyles(theme => ({
    paper: {
        width: '600px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))(Popover)


function PopoverComponent(props: Props) {
    const { formatMessage: f } = useIntl()

    const { anchorEl, type, onClose, onSave, children } = props

    return (
       
            <MyPopover
                id='copy-popover'
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                keepMounted
                onClose={onClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <DialogTitle id="scroll-dialog-title">{f({id: `component.Survey.Create.Module.Copy.title.${type}`})}</DialogTitle>
                <DialogContent style={{height: type === 'copy' ? 300 : 'auto'}}>
                    {children}
                </DialogContent>
                <DialogActions>
                    <CopySaveComponent type={type} onSave={onSave}/>
                    <Button onClick={onClose}>
                        <Typography variant="button">{f({id: 'component.Survey.Create.Module.Copy.button.close'})}</Typography>
                    </Button>
                </DialogActions>
            </MyPopover>
        
    )
}

export default PopoverComponent