import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { ID, Kind } from 'gql/jump'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import { OnCreateRule } from './ItemHeart'
import { isAddRule } from './ItemDefault'

interface Props {
    id: ID;
    kind: Kind;
    onCreate: OnCreateRule;
}

function ButtonCreateRuleComponent(props: Props) {
    const { id, kind, onCreate } = props

    const { formatMessage: f } = useIntl()

    const { questionsKeyQuestionNo } = useContext(Context)

    let is = true
    if (kind === Kind.Question) {
        if (questionsKeyQuestionNo) {
            const question = questionsKeyQuestionNo[id]
            const [{ _question }] = question
            const { question_type, module_type } = _question

            is = isAddRule(question_type, module_type)
        }      
    }

    return is ? (
        <ListItem style={{padding: 0, paddingTop: 5}}>
            <Button color="primary" variant="contained" size="small" disableElevation onClick={onCreate}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonCreateRule.button.add'})}</Button>
        </ListItem>
    ) : null
}

export default ButtonCreateRuleComponent