import { memo } from 'react'
import oc from 'open-color'
import HtmlParser from 'react-html-parser'
import { handleDisabledRightClick } from 'ts-utils'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface Props {
    src?: string;
    text: string;
    comment?: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        background: oc.indigo[1],
        borderRadius: 3,
        padding: '3px 5px',
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    text: {
        fontSize: 12,
        wordBreak: 'break-all'
    },
    img: {
        width: 28,
        height: 28,
        borderRadius: 3
    },
    comment: {
        marginTop: 5,
        paddingTop: 3,
        borderTop: `1px dashed ${oc.gray[5]}`
    }
}))

function ColumnValueComponent(props: Props) {
    const { src, text, comment } = props

    const classes = useStyles({ src })

    if (!src && text === '' && (comment === '' || comment === undefined) ) return null

    let minWidth = 30
    let justifyContent = 'center'

    if (src) {
        minWidth = 0
        justifyContent = 'flex-start'
    }

    return (
        <div style={{ display: 'table' }}>
            <div className={classes.root} style={{ minWidth }}>
                <div className={classes.box} style={{ justifyContent }}>
                    {src && <img src={src} alt="listovey" className={classes.img} onContextMenu={handleDisabledRightClick} />}
                    {text !== '' && <Typography variant="body2" className={classes.text}>{
                        typeof text === 'string' ? HtmlParser(text.replaceAll('\r\n', '\n').replaceAll('\n', '<br />')) : text
                    }</Typography>}
                </div>
                {
                    (comment !== '' && comment !== undefined) && <div className={classes.comment}><Typography variant="body2" className={classes.text}>{
                        typeof comment === 'string' ? HtmlParser(comment.replaceAll('\r\n', '\n').replaceAll('\n', '<br />')) : comment
                    }</Typography></div>
                }
            </div>

        </div>
    )
}

export default memo(ColumnValueComponent)
