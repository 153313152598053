// 문항과 문항사이에 구분선
import { memo } from 'react'
import oc from 'open-color'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative', 
        marginTop: 20, 
        marginBottom: 20
    },
    typography: {
        position: 'absolute', 
        top: -10, 
        left: '50%', 
        transform: 'translateX(-50%)', 
        color: theme.palette.grey[500], 
        fontWeight: 500,
        background: 'white', 
        paddingLeft: 20, 
        paddingRight: 20
    },
    divider: {
        borderBottom: `1px dashed ${theme.palette.grey[300]}`
    }
}))

function DivderComponent() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.divider} />
            <Typography className={classes.typography}>AND</Typography>
        </div>
    )
}

export default memo(DivderComponent)