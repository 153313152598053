import { memo} from 'react'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyAnalysisRowsPagingVariables } from 'gql/survey_analysis_rows'
import DownloadMenuHttpComponent from './DownloadMenuHttp'

export interface Props {
    survey_no: number;
    input: {
        conditionsPlay: SurveyAnalysisRowsPagingVariables['input']['conditionsPlay'];
        step: SurveyAnalysisRowsPagingVariables['input']['step'];
        date: SurveyAnalysisRowsPagingVariables['input']['date'];
        search: SurveyAnalysisRowsPagingVariables['input']['search'];
    };
    action: number;
    isRefresh: boolean;
    questions: SurveyQuestionModuleProps[];
    order: SurveyAnalysisRowsPagingVariables['input']['order'];
}

function DownloadSplitComponent(props: Props) {
    const { action, questions, ...other } = props

    return ( 
        <>
        <DownloadMenuHttpComponent 
            action={action}
            etc={{
                questions
            }}
            {...other}
            />
        </>
    )

}

export default memo(DownloadSplitComponent)