import { useCallback, Fragment, memo } from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select, { SelectProps } from '@material-ui/core/Select'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { v4 as uuidv4 } from 'uuid'
import { JumpRuleProps, JumpNo, ID, Kind, Type, Method, Conditiond } from 'gql/jump'
import { OnRemoveRule, OnChangeRule, getMethodSurveyQuestionNos, OnOpen, OnClose } from './ItemHeart'
import SelectTypeComponent from './SelectType'
import SelectSignQuestionComponent from './SelectSignQuestion'
import SelectSignPointComponent from './SelectSignPoint'
import ItemSplitComponent from './ItemSplit'
import { Refference } from './SelectActionQuestion'
import SelectMethodComponent from './SelectMethod'
import InputValueComponent from './InputValue'
import ButtonConditionComponent from './ButtonCondition'
import ButtonRemoveItemComponent from './ButtonRemoveItem'
import ButtonCreateItemComponent from './ButtonCreateItem'
import ButtonRemoveRuleComponent from './ButtonRemoveRule'
import ItemFocusComponent from './ItemFocus'
import SelectSplitActionComponent from './SelectSplitAction'

interface Props {
    id: ID;
    kind: Kind;
    row: JumpRuleProps;
    onRemoveRule: OnRemoveRule;
    onChangeRule: OnChangeRule;
}

export interface OnChangeChildsProps {
    onChange: (key: string, a: JumpNo, b: string | number | Array<number>) => void;
}

export interface OnRemoveProps {
    onRemove: (a: JumpNo) => void;
}

export interface OnCreateProps {
    onCreate: () => void;
}

export const MyPaper = withStyles({
    root: {
        position: 'relative',
        paddingTop: 30, 
        paddingBottom: 30,
        paddingRight: 20,
        paddingLeft: 20,
        '& + &': {
            marginTop: 10
        }
    }
})(Paper)

export const MyBox = withStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '& > div:first-of-type': {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'flex-start', 
            '& > * + *': {
                marginLeft: 10
            }
        },
        '& > div:last-of-type': {
            width: 'auto',
            marginLeft: 15
        },
        '& .conditionText': {
            width: 60,
            display: 'block',
            paddingTop: 5,
            color: oc.gray[9]
        }
    }
})(Box)

export const MyTypography = withStyles({
    root: {
        width: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden',
        fontSize: 14

    }
})((props: TypographyProps) => <Typography {...props}/>)

export const MySelect = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        height: 50,
        fontSize: 14
    },
    disabled: {
        color: oc.gray[8]
    }
})(({ style, error=false, label, ...other }: SelectProps) => (
    <FormControl style={style} variant="outlined">
        <InputLabel>{label}</InputLabel>
        <Select style={style} error={error} label={label} {...other}/>
    </FormControl>
))

/*
** jump_rule_child_no => uuid4()
*/
export function createChilds(props: { kind: Kind, jump_rule_no: JumpNo }) {
    const { kind, jump_rule_no } = props
    const jump_rule_child_no = uuidv4()

    return {
        jump_rule_child_no,
        jump_rule_no,
        type: kind === Kind.Page ? Type.Point : Type.Question,
        sign: 1,
        value: '',
        survey_module_nos: [],
        survey_module_answer_no: 0,
        conditiond: Conditiond.And
    }
}

function ItemRulesComponent(props: Props) {
    const { id, kind, row, onRemoveRule, onChangeRule } = props
   
    const { formatMessage: f } = useIntl()

    const { jump_rule_no, method, survey_question_nos, childs } = row // 타겟 설문문항

    const handleChangeMethod = useCallback((method: Method) => {
        const newRow = {
            ...row,
            method,
            survey_question_nos: getMethodSurveyQuestionNos()
        }
        onChangeRule(jump_rule_no, newRow)
    }, [jump_rule_no, row, onChangeRule])

    const handleChangeSurveyQuestionNos = useCallback((new_survey_question_nos: number[]) => {
        const newRow = {
            ...row,
            survey_question_nos: new_survey_question_nos
        }
        onChangeRule(jump_rule_no, newRow)
    }, [jump_rule_no, row, onChangeRule])

    const handleChaneChilds = useCallback((key, jump_rule_child_no, val) => {
        // type 변경시, value, survey_module_nos, survey_module_answer_no, sign은 1로 변경한다
        const defaultState = key === 'type' ? { val: '', survey_module_nos: [], survey_module_answer_no: 0, sign: 1 } : {}
        
        const newRow = {
            ...row,
            childs: row.childs.map(child => {
                // 변경되는 차이들만 변경
                if (jump_rule_child_no !== child.jump_rule_child_no) return child

                return { ...child, [key]: val, ...defaultState }
            })
        }
        onChangeRule(jump_rule_no, newRow)
    }, [jump_rule_no, row, onChangeRule])

    const handleRemove = useCallback((jump_rule_child_no) => {
        const newRow = {
            ...row,
            childs: row.childs.filter(child => jump_rule_child_no !== child.jump_rule_child_no)
        }
        onChangeRule(jump_rule_no, newRow)
    }, [jump_rule_no, row, onChangeRule])

    const handleCreate = useCallback(() => {
        const newChild = createChilds({ kind, jump_rule_no })
    
        const newRow = {
            ...row,
            childs: [
                ...row.childs,
                newChild
            ]
        }
        onChangeRule(jump_rule_no, newRow)

    }, [ kind, jump_rule_no, row, onChangeRule ])

    return (
        <MyPaper>
            <ItemFocusComponent section="rules" no={jump_rule_no}/>
            <ButtonRemoveRuleComponent jump_rule_no={jump_rule_no} onRemove={onRemoveRule}/>
            {
                childs.map((c, i) => (
                    <Fragment key={c.jump_rule_child_no}>
                        {
                            i > 0 && (
                                <ListItem style={{marginTop: 0, marginBottom: 0}}>
                                    <MyBox>
                                        <div style={{justifyContent: 'center'}}>
                                            <ButtonConditionComponent jump_rule_child_no={c.jump_rule_child_no} conditiond={c.conditiond} onChange={handleChaneChilds}/>
                                        </div>
                                        <div>
                                            
                                        </div>
                                    </MyBox>
                                </ListItem>
                            )
                        }
                        <ListItem>
                            <MyBox>
                                <div>
                                    <MyTypography className="conditionText">IF</MyTypography>
                                    {/**<SelectTypeComponent jump_rule_child_no={c.jump_rule_child_no} kind={kind} type={c.type} onChange={handleChaneChilds}/>**/}
                                    {
                                        c.type === Type.Question ? (
                                            <>
                                            <ItemSplitComponent 
                                                id={id} 
                                                jump_rule_child_no={c.jump_rule_child_no} 
                                                survey_module_nos={c.survey_module_nos} 
                                                survey_module_answer_no={c.survey_module_answer_no}
                                                value={c.value}
                                                onChange={handleChaneChilds}
                                            />
                                            <SelectSignQuestionComponent
                                                id={id} 
                                                jump_rule_child_no={c.jump_rule_child_no} 
                                                sign={c.sign} 
                                                onChange={handleChaneChilds}
                                            />
                                            </> 
                                        ) : (
                                            <>
                                            <InputValueComponent 
                                                number={true}
                                                jump_rule_child_no={c.jump_rule_child_no} 
                                                value={c.value} 
                                                placeholder={f({id: 'component.Survey.Create.Logic.Jump.Lists.ItemRules.inputValue.placeholder'})}
                                                onChange={handleChaneChilds}
                                            />
                                            <SelectSignPointComponent 
                                                jump_rule_child_no={c.jump_rule_child_no} 
                                                sign={c.sign} 
                                                onChange={handleChaneChilds}
                                            />
                                            </>
                                        )
                                    }
                                    
                                </div>
                                <div>
                                    <ButtonRemoveItemComponent length={childs.length} jump_rule_child_no={c.jump_rule_child_no} onRemove={handleRemove}/>
                                </div>
                            </MyBox>
                        </ListItem>
                    </Fragment>
                ))
            }
            <ListItem style={{paddingTop: 10}}>
                <MyBox >
                    <div>
                        <MyTypography variant="body2" className="conditionText">DO</MyTypography>
                        <SelectMethodComponent method={method} onChange={handleChangeMethod}/>
                        <SelectSplitActionComponent
                            refference={Refference.Rule}
                            id={id}
                            kind={kind} 
                            method={method}
                            survey_question_nos={survey_question_nos} 
                            onChange={handleChangeSurveyQuestionNos}
                        />
                    </div>
                    <div>
                        <ButtonCreateItemComponent onCreate={handleCreate}/>
                    </div>
                </MyBox>
            </ListItem>
        </MyPaper>
    )
}

export default memo(ItemRulesComponent)