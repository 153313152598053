/**
 * 검색어 입력
 */

import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import useOnClickOutside from 'hooks/useOnClickOutside'
import SelectBoxOptionComponent from '../../SelectBoxOption'
import { Props as ParentProps } from './Component'
import SignSelectBoxOptionsComponent from './SignSelectBoxOptions'

interface Props {
    keyValue: ParentProps['keyValue'];
    sign: ParentProps['sign'];
    onChange: ParentProps['onChangeSign'];
}


function SignSelectBoxComponent(props: Props) {
    const { keyValue, sign, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    const handleClick = useCallback(() => {
        setOpen(prevState => !prevState)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleChange: ParentProps['onChangeSign'] = useCallback((id, sign) => {
        onChange(id, sign)
        setOpen(false)
    }, [onChange])

    const ref = useOnClickOutside<HTMLDivElement>(handleClose)

    const name: { [key: string]: string } = { 
        equal: f({id: 'component.Survey.Create.Analysis.Filter.Item01.Comment.SignSelectBox.equal'}), 
        like: f({id: 'component.Survey.Create.Analysis.Filter.Item01.Comment.SignSelectBox.like'})
    }

    return (
        <div ref={ref} style={{ position: 'relative'}}>
            <SelectBoxOptionComponent secondary={name[sign] || name.equal} onChange={handleClick} indexs={0} selectBoxOpen={open} />
            <SignSelectBoxOptionsComponent open={open} keyValue={keyValue}  onChange={handleChange} />
        </div>
    )
}

export default memo(SignSelectBoxComponent)