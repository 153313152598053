/**
 * 썸네일 삭제
 */

import { memo, useState, MouseEvent } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useIntl } from "react-intl"
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import { DELETE_SURVEY_MBOX_THUMBNAIL } from "gql/survey_mbox_tumbnail"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { OnRemove } from './Thumbnail'

interface Props {
    survey_no: number;
    refetch: () => void;
    onRemove: OnRemove;
}

function ThumbnailRemoveComponent(props: Props) {
    const { survey_no, refetch, onRemove } = props
 
    const dispatch = useDispatch()

    const [ open, setOpen ] = useState(false)

    
    const { formatMessage: f } = useIntl()

    const [ remove ] = useMutation(DELETE_SURVEY_MBOX_THUMBNAIL, {
        onCompleted: () => {
            refetch()

        },
        onError: () => {
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    function handleOpen(event: MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        setOpen(true)
    }

    function handleClose(event: MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        setOpen(false)
    }

    const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
        remove({
            variables: {
                survey_no
            }
        })

        onRemove(event)
    }

    return (
        <>
        {
            open ? (
                <ButtonGroup size='small' variant='contained' disableElevation style={{position: 'absolute', right: 0, top: -10}}>
                    <Button color="secondary" size="small" onClick={handleRemove}>
                        {f({id: "component.Survey.Create.Share.ThumbnailRemove.button.remove"})}
                    </Button>
                    <Button  size="small" onClick={handleClose}>
                        {f({id: "component.Survey.Create.Share.ThumbnailRemove.button.close"})}
                    </Button>
                </ButtonGroup>
            ) : (
                <IconButton color='secondary' size='small' onClick={handleOpen} style={{position: 'absolute', right: -10, top: -10}}>
                    <RemoveCircleIcon fontSize='small'/>
                </IconButton>
            )
        }
        </>

    )
}

export default memo(ThumbnailRemoveComponent)