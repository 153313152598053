import { useContext, useEffect } from "react"
import { useParams } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"
import { useIntl } from 'react-intl'
import { RootState } from "reducer"
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as ERRORPAGESHOW } from 'reducer/backdrop'
import { CHANGE as CHANGESAVE, INIT as INITSAVE } from 'reducer/survey/create/logic/jumpPointSave'
import { isEqual, sortBy } from "lodash-es"
import { UPDATE_SURVEY_ENDING_POINTS, SurveyEndingProps } from "gql/survey_ending"
import { numberFormat } from "utils"
import Button from "@material-ui/core/Button"
import BeforeUnload from 'styled/BeforeUnload'
import { EndingContext, EndingEventContext } from "container/Survey/Create/Logic/Jump/EndingContext"
import { isError } from '../Point/ButtonSave'
import { Point } from '../Point/Component'

function getIsEqual(originDB: SurveyEndingProps[], newDB: SurveyEndingProps[]) {
    function parseNumber(data: SurveyEndingProps[]) {
        return data.map(c => ({
            ...c,
            start_point: Number(c.start_point),
            end_point: Number(c.end_point)
        }))
    }
    return isEqual(sortBy(parseNumber(originDB), 'indexs'), sortBy(parseNumber(newDB), 'indexs'))
}

// 사이 비교
export function isOverSize(start_point: Point, end_point: Point) {
    
    if (Number(start_point) > Number(end_point)) {
        console.log(start_point, end_point)
        return true
    }

    return false
}

export const limit_start_point = -30000
export const limit_end_point = 30000

function ButtonSaveComponent() {
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)
    
    const { endings } = useContext(EndingContext)
    const { refetchEndings } = useContext(EndingEventContext)

    const state = useSelector((state: RootState) => state.surveyCreateLogicJumpEnding)

    const [ updateSurveyEndingPoints ] = useMutation(UPDATE_SURVEY_ENDING_POINTS, {
        onCompleted: () => {
            refetchEndings()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleSave() {
        let isOver = false
        for (let i=0; i<state.rows.length; i++) {
            const row = state.rows[i]
            const { start_point, end_point } = row

            if (start_point < limit_start_point || start_point > limit_end_point) {
                isOver = true
                break
            }

            if (end_point < limit_start_point || end_point > limit_end_point) {
                isOver = true
                break
            }
        }

        if (isOver) {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                message: f(
                    {id: 'component.Survey.Create.Logic.Jump.Ending.ButtonSave.limit'}, 
                    {limit_start_point: numberFormat(limit_start_point), limit_end_point: numberFormat(limit_end_point)}
                ), 
                variant: 'warning' 
            })
            return
        }
        
        dispatch({ type: BACKDROPSHOW })
        updateSurveyEndingPoints({variables: {
            survey_no,
            data: state.rows.map(ending => {
                const{ survey_ending_no, start_point, end_point } = ending
                return { survey_ending_no, start_point, end_point }
            })
        }})
    }

    // 기존값과 새로운 값이 같으면 저장 버튼 비활성화
    let disabled = getIsEqual(endings, state.rows)

    // 기존값과 새로운값이 다르더라도, 인풋값이 없거나 시작점이 끝점보다 크다면 또 disabled 한다
    if (!disabled) {
        for (let i=0; i<state.rows.length; i++) {
            const row = state.rows[i]
            disabled = isError(limit_start_point, limit_end_point,  row.start_point)
            // 잘못된 값이 있다면 더이상 검증할 필요없다
            if (disabled) break

            disabled = isError(limit_start_point, limit_end_point,  row.end_point)
            // 잘못된 값이 있다면 더이상 검증할 필요없다
            if (disabled) break

            disabled = isOverSize(row.start_point,  row.end_point)
            // 잘못된 값이 있다면 더이상 검증할 필요없다
            if (disabled) break
        }
    }

    useEffect(() => {
        dispatch({ type: CHANGESAVE, disabled })
        return () => {
            dispatch({ type: INITSAVE })
        }
    }, [disabled, dispatch])

    return (
        <>
        <Button 
            variant="contained" 
            color="primary"
            disabled={disabled}
            onClick={handleSave}
        >
            {f({id: 'component.Survey.Create.Logic.Jump.Ending.ButtonSave.button.save'})}
        </Button>
         {!disabled && <BeforeUnload/>}
        </>

    )
}

export default ButtonSaveComponent