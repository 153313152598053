import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { UPDATE_SURVEY_ENDING_SORT } from 'gql/survey_ending'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Props as ParentProps, OnChangeSort } from './Endings'

interface Props {
    survey_no: number;
    rows: ParentProps['propsFetchData']['endings'];
    refetch: () => void;
    onChange: OnChangeSort;
    children:  JSX.Element[]
}

let timer: ReturnType<typeof setTimeout>;

function EndingsDropComponent(props: Props) {
    const { survey_no, rows, refetch, onChange, children } = props

    const dispatch = useDispatch()

    const [ update ] = useMutation(UPDATE_SURVEY_ENDING_SORT, {
        onCompleted: () => {
            setTimeout(() => {
                refetch()
            }, 100)
        },
        onError: () => {
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    const handleDrag = (result: any) => {
        if (rows.length <= 1) return

        if (!result.destination) return null
        
        const copy = rows.map(c => c)

        const sourceIndex = result.source.index //원래 인덱스
        const destinationIndex = result.destination.index


        // 옮겨져야할 값 을 배열에서 삭제하고 value값은 저장
        const [removedData] = copy.splice(sourceIndex, 1)

        // 옮겨져야할 값 추가
        copy.splice(destinationIndex, 0, removedData)

        onChange(copy)
        
        clearTimeout(timer)
        timer = setTimeout(() => {
            update({
                variables: {
                    survey_no,
                    indexs: copy.map(c => c.survey_ending_no)
                }
            })
        }, 100)
        
    }
    
    return (
        <DragDropContext onDragEnd={handleDrag} >
        <Droppable droppableId="Drop-endings">
        {(provided, snapshot) => (
            <div ref={provided.innerRef}  {...provided.droppableProps}> 
                {children}         
                {provided.placeholder}    
            </div>
        )}
        </Droppable>
        </DragDropContext>
    )
}

export default EndingsDropComponent