import { memo } from 'react'
import { useIntl } from 'react-intl'
import { animateScroll as scroll } from 'react-scroll'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

function MobileTopComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <BottomNavigationAction showLabel label={f({id: "component.Survey.Create.Analysis.Rows.MobileTop.button.title"})} icon={<ArrowUpwardIcon />} onClick={() => scroll.scrollToTop()}/>
    )
}

export default memo(MobileTopComponent)