import { FC, useMemo, CSSProperties } from 'react'
import { EdgeProps, useStoreState, getBezierPath, getEdgeCenter } from 'react-flow-renderer'
import { getEdgeParams } from './utils'
import oc from 'open-color'
import EditComponent from './Edit'

interface Props extends EdgeProps {
	data: {
		endingPoint: boolean;
	}
}

const foreignObjectSize = 60

function getColor(data: { endingPoint: boolean }) {
	const { endingPoint=false } = data
	return endingPoint ? { stroke: oc.green[8], fill: oc.cyan[0] } : { stroke: oc.indigo[8], fill: oc.yellow[8] }
}

const FloatingEdge: FC<Props> = ({ 
	id,
	animated,
	data,
	sourceX,
	sourceY,
	targetX,
	targetY,
	source, 
	target, 
	style 
}) => {
    const nodes = useStoreState((state) => state.nodes)
	const [edgeCenterX, edgeCenterY] = getEdgeCenter({ sourceX, sourceY, targetX, targetY })

    const sourceNode = useMemo(() => nodes.find((n) => n.id === source), [source, nodes]);
    const targetNode = useMemo(() => nodes.find((n) => n.id === target), [target, nodes]);

    if (!sourceNode || !targetNode) {
    	return null
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode)

    const d = getBezierPath({
		sourceX: sx,
		sourceY: sy,
		sourcePosition: sourcePos,
		targetPosition: targetPos,
		targetX: tx,
		targetY: ty
    })

	// display 속성은 버튼에다가 적용한다.
	const { display, ...rest } = style || {}

	const { stroke, fill } = getColor(data || {})

    return (
		<>
		<g className="react-flow__connection">
			<defs>
				<marker
					className="react-flow__arrowhead"
					id={id}
					markerWidth="45"
					markerHeight="45"
					viewBox="-10 -10 20 20"
					orient="auto"
					refX="0"
					refY="0"
				>
					<polyline
						stroke={stroke}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
						fill={fill}
						points="-5,-4 0,0 -5,4 -5,-4"
					/>
				</marker>
			</defs>
			<path id={id} className="react-flow__edge-path" d={d} markerEnd={!animated ? `url(#${id})` : ''} style={{...rest} as CSSProperties} />
		</g>
		<foreignObject
			width={foreignObjectSize}
			height={foreignObjectSize}
			x={edgeCenterX - foreignObjectSize / 3.3}
			y={edgeCenterY - foreignObjectSize / 1}
			className="edgebutton-foreignobject"
			requiredExtensions="http://www.w3.org/1999/xhtml"
		>
			<EditComponent display={display} id={id}/>
		</foreignObject>
	  	</>
    )
}

export default FloatingEdge