import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Select, MenuItem } from '@material-ui/core'
import { SelectStyled } from 'styled/Input'
import { useIntl } from 'react-intl'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'

export default function DeskTop(props) {
    const { method, selected, pageRandomDisabled, questionRandomDisabled, jumpDisabled, onChange } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const handleChange = useCallback(e => {
        const { value } = e.target

        if (value === 'page_random') {
            if (pageRandomDisabled === 'one') {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    message: f({id: 'component.Survey.Create.Logic.Menu.tooltip.page_random.1'}) 
                })
                return
            } else if (pageRandomDisabled) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    message: f({id: 'component.Survey.Create.Logic.Menu.tooltip.page_random.0' })
                })
                return
            }
        } else if (value === 'question_random') {
            if (questionRandomDisabled) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    message: f({id: 'component.Survey.Create.Logic.Menu.tooltip.question_random'}) 
                })
                return
            }
        } else if (value === 'page_jump') {
            if (jumpDisabled) {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    message: f({id: 'component.Survey.Create.Logic.Menu.tooltip.page_jump'}) 
                })
                return
            }
        }

        onChange(value)

    }, [f, dispatch, pageRandomDisabled, questionRandomDisabled, jumpDisabled, onChange])

    return (
        <Select input={<SelectStyled fontSize={13} style={{width: 130}} backgroundcolor="white" padding="8px 12px 8px 8px"/>} value={selected} onChange={(e) => handleChange(e)}>
            { 
                method === 'multi' && (
                    <MenuItem value="page_random" disabled={pageRandomDisabled}>
                        {f({id: 'component.Survey.Create.Logic.Menu.lists.page_random'})}
                    </MenuItem>
                ) 
            }
            <MenuItem value="question_random" disabled={questionRandomDisabled}>{f({id: 'component.Survey.Create.Logic.Menu.lists.question_random'})}</MenuItem>
            <MenuItem value="jump" disabled={jumpDisabled}>{f({id: 'component.Survey.Create.Logic.Menu.lists.page_jump'})}</MenuItem>
        </Select>
    )
}