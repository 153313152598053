import { memo } from 'react'
import { useIntl } from 'react-intl'
import Box, { BoxProps } from '@material-ui/core/Box'
import IconButton from 'styled/Custom/IconButton'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'
import { withStyles } from '@material-ui/core/styles'
import { JumpExampleCountTextProps } from 'gql/jump_example_count'
import { Props as BaseProps } from "./QuestionItemsBase"
import { OnChangeText, OnCreateText, OnRemoveText } from './QuestionItemSplit'
import InputTextComponent from './InputText'
import ItemComponent from "./Item"

interface Props extends Omit<BaseProps, 'question' | 'componentType'> {
    rowsText: JumpExampleCountTextProps[];
    onChangeText: OnChangeText;
    onCreateText: OnCreateText;
    onRemoveText: OnRemoveText;
}

interface MyIconButtonBoxProps extends BoxProps {
    length: number;
}

const MyItemBox = withStyles({
    root: {
        '& + &': {
            marginTop: 20
        }
    }
})(Box)

const MyIconButtonBox = withStyles({
    root: (props: MyIconButtonBoxProps) => {
        const { length } = props

        return {
            display: 'table',
            margin: '0 auto',
            marginTop: length === 0 ? 0 : 10
        }
    }
})(Box)

function QuestionItemsText(props: Props) {
    const { rows, rowsText, onChange, onChangeText, onCreateText, onRemoveText } = props

    const { formatMessage: f } = useIntl()

    const conditions = [ 
        f({id: 'component.Survey.Create.Logic.Jump.Point.QuestionItemsText.item.0'}),
        f({id: 'component.Survey.Create.Logic.Jump.Point.QuestionItemsText.item.1'}),
        f({id: 'component.Survey.Create.Logic.Jump.Point.QuestionItemsText.item.2'}),
        f({id: 'component.Survey.Create.Logic.Jump.Point.QuestionItemsText.item.3'})
    ]

    return (
        <>
        {
            rowsText.reduce((acc, rowText) => {
                const { jump_example_count_text_no, value } = rowText   
                
                const text = <InputTextComponent key={jump_example_count_text_no} jump_example_count_text_no={jump_example_count_text_no} value={value} onChange={onChangeText} onRemove={onRemoveText}/>

                const item = rows.filter(row => row.jump_example_count_text_no === jump_example_count_text_no).map((row, i) => {
                    const { jump_example_count_no } = row
                    const answer = conditions[i]        

                    return <ItemComponent key={`${jump_example_count_text_no}-${jump_example_count_no}`} answer={answer} row={row} onChange={onChange}/>
                })

                const box = <MyItemBox key={jump_example_count_text_no}>{[text, ...item]}</MyItemBox>
                
                return [ ...acc, box ]
            }, [])
        }
        <MyIconButtonBox length={rowsText.length}>
            <IconButton color="primary" onClick={onCreateText}><AddCircleOutlinedIcon/></IconButton>
        </MyIconButtonBox>
        </>
        
    )
}

export default memo(QuestionItemsText)