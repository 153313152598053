import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { Tabs, Tab, AppBar } from '@material-ui/core'
import styled from 'styled-components'

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    z-index: 3;
    height: 0;

    & > div {
        display: grid;
        width: 100%;
     
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "step";
        
        & > div.step {
            grid-area: step;
            display: none;
            background: white;
            border-bottom: 1px solid ${oc.gray[1]};
            position: fixed;
            width: 100%;
        }
    }

    @media only screen and (max-width: 600px) {
        height: 3.5em;

        & > div {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: 
                "step";
        }
        

        & > div > div.step {
            display: block;
        }
    }

`

function Component(props) {
    const { labels, activeStep, onChangeStep } = props

    const { formatMessage: f } = useIntl()

    const states = useSelector((states) => states.surveyCreateModuleIsEdit)

    const { is } = states

    const handleChange = (value:number) => {
        // 제작으로 이동이 아닐경우
        if (is && value !== 0) {
            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) onChangeStep(value)
        } else onChangeStep(value)
    }
    
    return (
        <WrapperStyled>
            <div>
                <div className='step'>
                    <AppBar position="static">
                        <Tabs value={activeStep} variant="fullWidth" indicatorColor="primary" style={{background: 'white', color: 'black'}}>
                            {
                                labels.map((label, i) => {
                                    const styles = activeStep === i ? {background: oc.indigo[9], color: 'white'} : {}
                                    return <Tab style={styles} key={label} label={label} onClick={() => handleChange(i)}/>
                                })
                            }
                        </Tabs>
                    </AppBar>
                </div>
            </div>
        </WrapperStyled>
    )
}

export default memo(Component)