import { useState, useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { getShareDomain } from 'ts-utils/survey'
import { getShareLink, getDevice } from 'utils/survey'
import { validEmailShare } from 'ts-utils/survey'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { UPDATE_SURVEY_ANALYSIS_USERS_RESET } from 'gql/survey_analysis_users'
import Dialog from 'styled/Survey/Live/Dialog'
import Tooltip from 'styled/Custom/Tooltip'
import { StyleTargetUserButton } from './Title'

interface Props {
    survey_no?: number;
    surveyArgs?: string;
    edit: boolean;
    survey_analysis_users_no: number;
    bar: string;
    font_family: string;
}

const device = getDevice()

function ResponseDialogComponent(props: Props) {
    const { survey_no, surveyArgs, edit, survey_analysis_users_no, bar, font_family } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const domain = getShareDomain(survey_no)
    const shareLink = getShareLink({ domain, args: surveyArgs })
    const webUrl = shareLink.survey.web
    const emailUrl = shareLink.survey.email
    

    const [ open, setOpen ] = useState(false)
    
    const [ updateSurveyAnalysisUsersReset ] = useMutation(UPDATE_SURVEY_ANALYSIS_USERS_RESET, {
        onCompleted: (data) => {
            handleClose()
            const id = data.updateSurveyAnalysisUsersReset
            const url = validEmailShare(id) ? `${emailUrl}?email=${id}` : webUrl
       
            window.open(url, "_blank")
        },
        onError: (e) => {
            // 설문 비공개 상태라면 다이 응답하기 몬한다.
            if (e.graphQLErrors[0].message === 'Not Open') {
                dispatch({ 
                    type: ALERTSNACKBARSHOW, 
                    message: f({id: 'component.Survey.Create.Analysis.Summary.ResponseDialog.openError'}), 
                    duration: 5000, 
                    variant: 'error' 
                })
            } else {
                dispatch({ type: ERRORPAGESHOW })
            }

            handleClose()
        }
    })

    const getStyleTargetUserButton = new StyleTargetUserButton({ bar, font_family })

    function handleOpen() {
        setOpen(true)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    function handleResponse() {
        if (!survey_no || !surveyArgs) return

        updateSurveyAnalysisUsersReset({
            variables: {
                survey_no,
                survey_analysis_users_no,
                device
            }
        })
    }

    return (
        <>
        <Button
            variant="contained"
            size="small" 
            style={getStyleTargetUserButton.defaultStyle}
            onClick={handleOpen}
        >
            {f({id: 'component.Survey.Create.Analysis.Summary.ResponseDialog.button'})}
        </Button>
        <Dialog open={open} fullScreen={false} onClose={handleClose}>
            <DialogTitle>{f({id: 'component.Survey.Create.Analysis.Summary.ResponseDialog.dialog.title'})}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.ResponseDialog.dialog.content'}))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Tooltip placement='top' isMobileClick={true} title={edit ? f({id: 'component.Survey.Create.Analysis.Summary.ResponseDialog.dialog.tooltip'}) : ''}>
                <span>
                    <Button 
                        onClick={handleResponse} 
                        color="primary"
                        disabled={edit}
                    >
                        {f({id: 'component.Survey.Create.Analysis.Summary.ResponseDialog.dialog.button.0'})}
                    </Button>
                </span>
                </Tooltip>
                <Button onClick={handleClose}>{f({id: 'component.Survey.Create.Analysis.Summary.ResponseDialog.dialog.button.1'})}</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default memo(ResponseDialogComponent)