import { memo, Fragment } from 'react'
import { ModuleMatrix, AnswerMatrix } from 'gql/survey_question_modules'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ColumnSubjectComponent from './ColumnSubject'
import ColumnValueComponent from './ColumnValue'
import { Props as ParentProps } from './Body'

interface Props {
    survey_question_no: number;
    answer: ParentProps['answer'];
    _modules?: ModuleMatrix[];
    _answers?: AnswerMatrix[];
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        paddingLeft: 10,
        paddingRight: 10,
        '& > div:first-child': {
            width: 40
        },
        '& > div:last-child': {
            flex: 1,
            background: 'white',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: 3,
            padding: '12px 0'
        }
    },
    itemBox: {
        paddingLeft: 12,
        paddingRight : 12
    },
    valueBox: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 5,
        paddingLeft: 0,
        paddingTop: 5
    },
    commentBox: {
        borderTop: `1px dashed ${theme.palette.grey[300]}`,
        padding: '10px 12px 0 12px',
        marginTop: 10
    }
}))

function ColumnItemsComponent(props: Props) {
    const { survey_question_no, answer, _modules, _answers } = props

    const classes = useStyles()

    if (!_modules) return null
    if (!_answers) return null

    const commentValue = answer[`Q${survey_question_no}`]

    const comment = commentValue ? commentValue.comment[survey_question_no] : ''

    return (
        <Box className={classes.root}>
            <div />
            <div>
                <div className={classes.itemBox}>
                {
                    _modules.map((c, i) => {
                        const { survey_module_no, column } = c

                        const key = `Q${survey_question_no}-${survey_module_no}`
                        const values = answer[key]

                        if (!values) return <ColumnSubjectComponent key={survey_module_no} column={column}/>

                        return (
                            <Fragment key={survey_module_no}>
                                {
                                    i > 0 && <div style={{ marginTop: 15 }} />
                                }
                                <ColumnSubjectComponent column={column}/>
                                <div className={classes.valueBox}>
                                    {
                                        values.choice.filter(c => c[0] !== '').map((c, j) => <ColumnValueComponent key={`${survey_module_no}-${j}`} text={c[1]} />)
                                    }
                                </div>                            
                            </Fragment>
                        )
                    })
                }
                </div>
                {
                    comment !== '' && <div className={classes.commentBox}><ColumnValueComponent text={comment} /></div>
                }
            </div>
        </Box>
       
    )
}

export default memo(ColumnItemsComponent)
