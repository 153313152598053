import { memo } from 'react'
import { Src } from "gql/survey_mbox_tumbnail"
import Grid from '@material-ui/core/Grid'
import withSurveyFetchData from "hoc/Survey/FetchData"
import ThumbnailComponent from './Thumbnail'
import ThumbnailButtonsComponent from './ThumbnailButtons'

export interface Props {
    survey_no: number;
    title: string;
    url: string;
    propsFetchData: {
        mboxThumbnailSrc: Src;
        refetchMboxThmubnailSrc: () => void;
    }
}

function SnsChildComponent(props: Props) {
    const { survey_no, url, title, propsFetchData } = props
    const { mboxThumbnailSrc, refetchMboxThmubnailSrc} = propsFetchData

    return (
        <>
        <Grid item xs={12} sm="auto" md="auto">
            <ThumbnailComponent survey_no={survey_no} thumbnail={mboxThumbnailSrc} refetch={refetchMboxThmubnailSrc}/>
        </Grid>
        <Grid item xs={12} sm="auto" md="auto">
            <ThumbnailButtonsComponent url={url} title={title} thumbnail={mboxThumbnailSrc}/>
        </Grid>
        </>
    )
}

export default withSurveyFetchData('edit')({mboxThumbnailSrc: true})(false)(memo(SnsChildComponent))