import { memo, ReactElement } from "react"
import Checkbox from "@material-ui/core/Checkbox"
import { MyTypography } from './ItemRules'
import { OnSelected } from './SelectActionQuestionItems'
import { MyMenuItem } from './SelectModule'

interface Props {
    selected: boolean;
    survey_question_no: number;
    isMulti: boolean;
    label: string | ReactElement<any> | ReactElement<any>[];
    onChange: OnSelected;
}

function SelectActionQuestionItemComponent(props: Props): any {
    const { selected, survey_question_no, isMulti, label, onChange } = props
    return (
        <MyMenuItem 
            key={survey_question_no} 
            value={survey_question_no}
            selected={selected} 
            onClick={() => onChange(selected, survey_question_no)}
        >
            {
                isMulti && <Checkbox checked={selected}/>
            }
            <MyTypography>{label}</MyTypography>
        </MyMenuItem>
    )
} 

export default memo(SelectActionQuestionItemComponent)