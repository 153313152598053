import { memo, useContext, useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { CHANGE } from 'reducer/survey/create/logic/menuAlarm'
import { UPDATE_SURVEY_QUESTION_VISIBLE } from 'gql/survey_question'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Context, SurveyQuestionModulePropsInKey } from 'container/Survey/Create/Logic/Jump/Context'

interface Props {
    survey_question_no: number;
}

const GridItem = withStyles({
    root: {
        paddingTop: 8,
        fontSize: 13
    }
})(props => <Grid item {...props}/>)

function getVisible(questions: SurveyQuestionModulePropsInKey, survey_question_no: Props['survey_question_no']) {
    if (!questions) return true

    const res = questions[survey_question_no]
    if (!res) return true

    const [{ _question }] = res
    const { visible } = _question

    return visible
}
function ItemVisibleComponent(props: Props) {
    const { survey_question_no } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const { questionsKeyQuestionNo } = useContext(Context)

    const [ visible, setVisible ] = useState(getVisible(questionsKeyQuestionNo, survey_question_no))

    const [ updateSurveyQuestionVisible ] = useMutation(UPDATE_SURVEY_QUESTION_VISIBLE, {
        onCompleted: () => {
            dispatch({ type: CHANGE, kinds: ['questionAll'] })
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type:ERRORPAGESHOW })
        }
    })

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const val = event.target.checked

        dispatch({ type: BACKDROPSHOW })

        setVisible(val)
        updateSurveyQuestionVisible({ 
            variables: {
                survey_no,
                survey_question_no,
                visible: val
            }
        })
    }, [dispatch, survey_no, survey_question_no, updateSurveyQuestionVisible])

    useEffect(() => {
        setVisible(getVisible(questionsKeyQuestionNo, survey_question_no))
    }, [questionsKeyQuestionNo, survey_question_no])

    return (
        <Typography component="div" style={{marginRight: 50}}>
            <Grid component="label" container alignItems="center" spacing={1}>
            <GridItem>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ItemVisible.hide'})}</GridItem>
            <Grid item>
                <Switch checked={visible} name="visible" size="small" color="primary" onChange={handleChange}/>
            </Grid>
            <GridItem>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ItemVisible.show'})}</GridItem>
            </Grid>
        </Typography>
    )
}

export default memo(ItemVisibleComponent)