/**
 * 이미지 형
 */
import { memo } from 'react'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    src: string | undefined;
    tooltip: string;
}

function TableHeadCellComponent(props: Props) {
    const { src, tooltip } = props
    
    if (!src) return null

    return (
        <div>
            <Tooltip title={tooltip}>
                <img src={src} alt="listovey" style={{ width: 26, height: 26, borderRadius: 3, display: 'block' }} />
            </Tooltip>
        </div>
    )
}

export default memo(TableHeadCellComponent)