/**
 * 공유 레포트(only live)에서 사용되는 정렬 버튼
*/
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { CHANGE, INIT } from 'reducer/analysis/summary/order'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import QuestionPaper from 'styled/Survey/Analysis/SummaryQuestionPaper'
import { QuestionBoxStyled } from 'styled/Survey/Analysis/Summary'
import { StyleTargetUserButton } from "./Title"

interface Props {
    survey_no: number;
    design: {
        bar: string;
        font_family: string;
    }
}

function OrderShareComponent(props: Props) {
    const { survey_no, design } = props
    const { font_family, bar } = design

    const { formatMessage: f } = useIntl()

    const [ mount, setMount ] = useState(false)

    const dispatch = useDispatch()
    const orderObject = useSelector((states: RootState) => states.analysisSummaryOrder)
    const { order } = orderObject

    const getStyleTargetUserButton = new StyleTargetUserButton({ font_family, bar })

    const handleChange = (value: string) => {
        dispatch({ type: CHANGE, survey_no, order: value, live: true })
    }

    useEffect(() => {
        dispatch({ type: INIT, survey_no, live: true })
        setMount(true)
    }, [survey_no, dispatch])

    if (!mount) return null

    return (
        <>
        <QuestionPaper>
            <QuestionBoxStyled>
                <ButtonGroup variant='contained' disableElevation size="small">
                    <Button 
                        style={getStyleTargetUserButton.target(order === 'answer')} 
                        onClick={() => handleChange('answer')}
                    >
                        {f({id: "component.Survey.Create.Analysis.Summary.OrderShare.button.answer"})}
                    </Button>
                    <Button 
                        style={getStyleTargetUserButton.target(order === 'count')} 
                        onClick={() => handleChange('count')}
                    >
                        {f({id: "component.Survey.Create.Analysis.Summary.OrderShare.button.count"})}
                    </Button>
                </ButtonGroup>
            </QuestionBoxStyled>
        </QuestionPaper>
        <div style={{height: 15}}></div>
        </>
    )
}

export default OrderShareComponent