import { memo, Fragment } from 'react'
import { QuestionType } from 'gql/survey_analysis_summary'
import { ModuleMatrix, AnswerMatrix, _12Module, _18Answer } from 'gql/survey_question_modules'
import { Props as ParentProps } from './Body'
import SubjectComponent from './Subject'
import ColumnItemsComponent from './ColumnItems'
import ChoiceWithCommentItemsComponent from './ChoiceWithCommentItems'
import RankWithCommentItemsComponent from './RankWithCommentItems'
import ChoiceItemsComponent from './ChoiceItems'
import PulldownItemsComponent from './PulldownItems'
import RankItemsComponent from './RankItems'
import TextItemComponent from './TextItem'
import FileItemComponent from './FileItem'
import EndingComponent from './Ending'
import PointComponent from './Point'
import { isMatrixChoice, isTextChoice } from '../TableHead'

interface Props {
    answer: ParentProps['answer'];
    files: ParentProps['files'];
    questions: ParentProps['questions'];
    endings: ParentProps['endings'];
    hideQuestion: ParentProps['hideQuestion'];
}

const isChoiceWithComment = ['_01', '_02', '_12', '_13']
const isChoice = ['_05', '_07', '_14']
const isPulldown = ['_21']
const isRank = ['_11']
const isRankWithComment = ['_17', '_18']
const isFileChoice = [ '_19' ]

function getIsAnswer<Modules>(props: { typename: string; answer: ParentProps['answer']; survey_question_no: number; _modules: Modules[], files: ParentProps['files'] }) {
    const { typename, answer, survey_question_no, _modules, files } = props

    if (isTextChoice.includes(typename)) {
        const is = answer[`Q${survey_question_no}`]

        if (!is) return false
        if (is.choice.length === 0) return false

        return (is.choice[0][1] === '' || is.choice[0][1] === undefined || is.choice[0][1] === null) ? false : true
    }

    if (isFileChoice.includes(typename)) {
        const fileObj = files[survey_question_no]

        if (!fileObj) return false

        const { src } = fileObj[0]

        return src ? true : false    
    }

    if (isMatrixChoice.includes(typename)) {
        let flag = false
        const len = _modules.length
        for (let i=0; i<len; i++) {
            const { survey_module_no } = _modules[i]
            const key = `Q${survey_question_no}-${survey_module_no}`
            const is = answer[key]

            if (!is) continue

            // 항목 중 값이 있다면 더이상 검증할 필요가 없다 
            if (is.choice.find(c => c[0] !== '')) {
                flag = true
                break
            }
        }

        return flag
    }

    if (isChoiceWithComment.includes(typename) || isPulldown.includes(typename) || isChoice.includes(typename) || isRank.includes(typename) || isRankWithComment.includes(typename)) {
        const is = answer[`Q${survey_question_no}`]
        if (!is) return false

        return is.choice.find(c => c[0] !== '') ? true : false
    }

    return false
}

function ItemsComponent(props: Props) {
    const { answer, files, questions, endings, hideQuestion } = props

    return (
        <>
        {
            questions.filter(c => c._question.question_type === QuestionType.Question).map(c => {
                const { _question, _modules, _answers, __typename } = c
                const { survey_question_no, pure_question, img_src } = _question

                const typename = __typename.replace('Module', '')

                if (hideQuestion) {
                    const isAnswer = isMatrixChoice.includes(typename) 
                                    ? getIsAnswer<ModuleMatrix>({ typename, answer, survey_question_no, _modules: _modules as ModuleMatrix[], files })
                                    : getIsAnswer<[]>({ typename, answer, survey_question_no, _modules: [], files })       
                                    
                    if (!isAnswer) return null
                }
                       
                return (
                    <Fragment key={survey_question_no}>
                        <SubjectComponent typename={typename} pure_question={pure_question} img_src={img_src} />
                        {
                            isChoice.includes(typename) ? <ChoiceItemsComponent survey_question_no={survey_question_no} answer={answer} /> : 
                            isRank.includes(typename) ? <RankItemsComponent survey_question_no={survey_question_no} answer={answer} /> : 
                            isMatrixChoice.includes(typename) ? <ColumnItemsComponent survey_question_no={survey_question_no} answer={answer} _modules={_modules as ModuleMatrix[]} _answers={_answers as AnswerMatrix[]} /> : 
                            isChoiceWithComment.includes(typename) ? <ChoiceWithCommentItemsComponent survey_question_no={survey_question_no} answer={answer} typename={typename} _modules={_modules as _12Module[]} /> : 
                            isRankWithComment.includes(typename) ? <RankWithCommentItemsComponent survey_question_no={survey_question_no} answer={answer} typename={typename} _answers={_answers as _18Answer[]} /> : 
                            isTextChoice.includes(typename) ? <TextItemComponent survey_question_no={survey_question_no} answer={answer} /> : 
                            isFileChoice.includes(typename) ? <FileItemComponent survey_question_no={survey_question_no} answer={answer} files={files} title={pure_question} /> : 
                            isPulldown.includes(typename) ? <PulldownItemsComponent survey_question_no={survey_question_no} answer={answer} /> : null
                            
                        }              
                        <div style={{ height: 10 }} />
                    </Fragment>
                )
            })
        }
        <EndingComponent answer={answer} endings={endings} hideQuestion={hideQuestion} />
        <PointComponent answer={answer} hideQuestion={hideQuestion} />
        </>
    )
}

export default memo(ItemsComponent)
