import { memo, ReactChild } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import Box from '@material-ui/core/Box'
import MessageIcon from '@material-ui/icons/Message'
import withStyles from '@material-ui/core/styles/withStyles'
import { DataTypes } from './nodeClass'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
	data: DataTypes;
	isConnectable: boolean;
}

const style = {
	background: '#555'
}

const MyBox = withStyles({
	root: ({ background }: {background: string }) => {
		return {
			display: 'flex', 
			alignItems: 'center',
			justifyContent: 'center',
			width: 280,
			paddingTop: 20,
			paddingBottom: 20,
			fontSize: 23,
			background,
			borderRadius: 5,
			color: 'white',
			border: 'none',
			boxShadow: '0 0.07rem 0.2rem 0 rgba(0, 0, 0, 0.1), 0 0.07rem 0.125rem 0 rgba(0, 0, 0, 0.06)',
			'& > div + div': {
				marginLeft: 20
			}
		}
	}
})(Box)


function MyTooltip(props: {img_src: string, message: string, children: ReactChild}) {
    const { img_src, message, children } = props

    const _title = img_src ? `<img src=${img_src} style="max-width: 200px; border-radius: 3px;" alt=""/><br />${message}` : message

    return (
        <Tooltip title={_title}>
            {children}
        </Tooltip>
    )
}

function FloatingNode({ data, isConnectable }: Props) {
	const { label, message, img_src, background='' } = data

	return (
		<>
		<Handle
			type="target"
			position={Position.Bottom}
			style={style}
			isConnectable={isConnectable}
		/>
		<MyBox background={background}>
			<div>{label}</div>
			<div>
				<MyTooltip message={message} img_src={img_src}>
					<MessageIcon style={{display: 'block', color: 'white', cursor: 'default'}}/>
				</MyTooltip>
			</div>
		</MyBox>
		<Handle
			type="source"
			position={Position.Bottom}
			style={style}
			isConnectable={isConnectable}
		/>
		</>
	)
}

export default memo(FloatingNode)