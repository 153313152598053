/**
 * 컬럼 변경
 */

import { memo } from 'react'
import { Props as ParentProps } from './TableHeadIdStandard'
import { isID, isOrder } from './TableHead'
import TableHeadIdStandardDialogItemComponent from './TableHeadIdStandardDialogItem'

interface Props {
    index: ParentProps['index'];
    columns: ParentProps['columns'];
    onChange: ParentProps['onChange'];
    onClose: () => void;
}

function TableHeadIdStandardDialogItemsComponent(props: Props) {
    const { index, columns, onChange, onClose } = props

    return (
       <>
        {
            columns.map((c: any, i: number) => {
                const { field, headerName, __typename, isComment } = c

                let typename = field
                let label = headerName

                if (!(isID.includes(field) || isOrder.includes(field))) {
                    typename = __typename
                    label = headerName[1]
                }
                
                const selected = i === index

                return (
                    <TableHeadIdStandardDialogItemComponent 
                        key={field} 
                        field={field}
                        i={i}
                        typename={typename} 
                        label={label} 
                        selected={selected}
                        isComment={isComment} 
                        onChange={onChange}
                        onClose={onClose}
                    />
                )
            })
        }
        </>
    )    
}

export default memo(TableHeadIdStandardDialogItemsComponent)