import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useIntl } from 'react-intl'

function MobileComponent(props) {
    const { open, onClose, children } = props

    const { formatMessage: f } = useIntl()

    return (
        <Dialog fullScreen open={open}>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{f({id: 'component.Survey.Create.Logic.HelpPopup.close'})}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default MobileComponent

