/**
 * 보기 리스트
 */

import { memo } from 'react'
import { useIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { Props as ParentProps } from './Component'
import { useStyles } from '../../SelectBoxOptions'
import SelectBoxOptionComponent from '../../SelectBoxOption'

interface Props {
    open: boolean;
    keyValue: ParentProps['keyValue'];
    onChange: ParentProps['onChangeSign'];
}

function SignSelectBoxOptionsComponent(props: Props) {
    const { open, keyValue, onChange } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    if (!open) return null

    return (
        <Paper className={classes.root} elevation={5}>
            <SelectBoxOptionComponent primary={f({id: 'component.Survey.Create.Analysis.Filter.Item01.Comment.SignSelectBox.equal'})} indexs={0} onChange={() => onChange(keyValue, 'equal')} />
            <SelectBoxOptionComponent primary={f({id: 'component.Survey.Create.Analysis.Filter.Item01.Comment.SignSelectBox.like'})} indexs={1} onChange={() => onChange(keyValue, 'like')} />
        </Paper>
    )
}

export default memo(SignSelectBoxOptionsComponent)