/**
 * 스텝 버튼 컴포넌트
 */
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import Stepper from '@material-ui/core/Stepper'
import StepButton from '@material-ui/core/StepButton'
import Step from '@material-ui/core/Step'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
    labels: any[];
    activeStep: number;
    onChangeStep: (val: number) => void;
}

const useStyles = makeStyles({
    root: {
        height: 0,
        backgroundColor: 'transparent',
    }
})

function StepperComponent(props: Props) {
    const { labels, activeStep, onChangeStep } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const states = useSelector((states: RootState) => states.surveyCreateModuleIsEdit)

    const { is } = states

    const handleChange = (value:number) => {
        // 제작으로 이동이 아닐경우
        if (is && value !== 0) {
            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) onChangeStep(value)
        } else onChangeStep(value)
    }

    return (
        <Stepper nonLinear activeStep={activeStep} className={classes.root}>
            {labels.map((label, i) => {
                return (
                    <Step key={i}>
                        <StepButton onClick={() => handleChange(i)}>
                            <Typography style={{whiteSpace: 'pre'}}>{label}</Typography>
                        </StepButton>
                    </Step>
                )
            })}
        </Stepper>
    )
}

export default memo(StepperComponent)