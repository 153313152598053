import { useState, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import PopupImage from 'styled/PopupImage'

interface Props {
    onChange:(a:string) => void;
}

function PopoverImageComponent(props: Props) {
    const { onChange } = props

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)
    const [ image, setImage ] = useState('')

    const dispatch = useDispatch()

    function handleOpen() {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    function handleSave() {
        const img = document.createElement('img');
        img.src = image

        img.onload = function () {
            onChange(image)
            handleClose()
        }

        img.onerror = function() {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Image.Container.alert.appendUrl.image'})
            })

            onChange('')
        }
    }

    function handleDelete() {
        setImage('')
        onChange('')
    }

    function handleChangeImage(props: {img_src: string}) {
        const { img_src } = props
        setImage(img_src)
    }

    return (
        <>
        <ListItem>
            <ListItemText>{f({id: 'component.Survey.Create.Share.PopoverImage.title'})}</ListItemText>
            <ListItemSecondaryAction>
                {!image ? (
                    <Typography color="primary" variant="body2" style={{cursor: 'pointer'}} onClick={handleOpen}>{f({id: 'component.Survey.Create.Share.PopoverImage.button.select'})}</Typography>
                ) : (
                    <Typography color="secondary" variant="body2" style={{cursor: 'pointer'}} onClick={handleDelete}>{f({id: 'component.Survey.Create.Share.PopoverImage.button.cancel'})}</Typography>
                )}
            </ListItemSecondaryAction>
        </ListItem>
        <PopupImage
            title={f({id: 'component.Survey.Create.Share.PopoverImage.Dialog.title'})}
            open={open}
            saveButtonText={f({id: 'component.Survey.Create.Share.PopoverImage.button.save'})}
            onSave={handleSave}
            onClose={handleClose}
            onAppendUrl={handleChangeImage}
            onChangeImage={handleChangeImage}
        />
        </>
    )
}

export default memo(PopoverImageComponent)