import { memo } from 'react'
import { useIntl } from 'react-intl'
import { SurveyStartProps } from 'gql/survey_start'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { withStyles } from '@material-ui/core/styles'
import MessageIcon from '@material-ui/icons/Message'
import { withSurveyFetchData } from 'hoc'
import { ListSubheader, ListSubheaderBox, defaultColor } from './Items'
import { MyListItemSecondaryAction, MyListItem, MyTooltip } from './EndingsItem'
import StartItemUsedComponent from './StartItemUsed'

interface Props {
    is_headerbar: boolean;
    onChangeCategory: (a:string) => void;
    propsFetchData: {
        start: SurveyStartProps;
        refetchStart: () => void;
    }
}

interface MySvgIconProps extends SvgIconProps {
    _is: number;
}

const MyMessageIcon = withStyles(theme => ({
    root: ({ _is=0 }: MySvgIconProps) => {
        return {
            display: 'block',
            color: _is ? defaultColor.iconColorSelected : defaultColor.iconColor,
            width: 17,
            height: 17
        }
    }
}))((props: MySvgIconProps) => (
    <MessageIcon {...props} fontSize="small"/>
))

function StartComponent(props: Props) {
    const { formatMessage: f } = useIntl()

    const { is_headerbar, onChangeCategory, propsFetchData } = props
    const { start, refetchStart } = propsFetchData
    const { message, img_src, used } = start

    return (
        <List 
            dense 
            subheader={
                <ListSubheader is_headerbar={is_headerbar}>
                    <ListSubheaderBox>
                        <div>{f({id: 'component.Survey.Create.Menu.Component.subheader.thanks.start'})}</div>
                        <div>
                            <StartItemUsedComponent used={used} refetch={refetchStart}/>
                        </div>
                    </ListSubheaderBox>
                 
                </ListSubheader>
                
            }
        >
            <MyListItem button disabled={!used} onClick={() => onChangeCategory('thanks-start')}>
                <ListItemText primary={f({id: 'component.Survey.Create.Menu.Component.thanks.start'})} />
                <MyTooltip img_src={img_src} message={message}>
                    <MyListItemSecondaryAction>
                        <MyMessageIcon _is={(message || img_src) ? 1 : 0}/>
                    </MyListItemSecondaryAction>
                </MyTooltip>
            </MyListItem>
        </List>
    )
}

export default withSurveyFetchData('edit')({ start: true })(false)(memo(StartComponent))