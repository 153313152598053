import { memo } from 'react'
import ordinal from 'ordinal'
import sortArray from 'sort-array'
import oc from 'open-color'
import { _18 } from 'gql/survey_analysis_summary'
import DataItemCommentHeaderComponent from './DataItemCommentHeader'
import DataItemCommentsComponent from './DataItemComments'

interface Props {
    survey_no: number;
    survey_question_no: number; 
    answers: _18['answers'];
}

function DataItemRankCommentsComponent(props: Props) {
    const { survey_no, survey_question_no, answers } = props
    
    let j = -1
    return (
        <>
        {
            answers.map((c, i) => {
                const { answer, src, ranks, survey_module_answer_no } = c

                sortArray(ranks, { 
                    by: 'indexs', 
                    order: 'asc'
                })

                if (!ranks.find(c => c.comments.length > 0)) return null

                j++

                return (
                    <div style={{ border: `1px solid ${oc.gray[3]}`, borderRadius: 5, padding: 10, marginTop: j === 0 ? 0 : 30 }} key={survey_module_answer_no}>
                        <DataItemCommentHeaderComponent index={0} answer={answer} src={src || undefined}/>
                        {
                            ranks.filter(c => c.commentLen > 0).map((d, j) => {
                                const { survey_module_no, comments, indexs, commentLen } = d

                                return (
                                    <div style={{ position: 'relative' }} key={survey_module_no}>
                                        <div style={{ 
                                                position: 'absolute', 
                                                left: 0, 
                                                top: 20, 
                                                background: 'rgba(0, 0, 0, .2)',
                                                color: 'white', 
                                                fontWeight: 900, 
                                                borderTopLeftRadius: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 30,
                                                height: 30,
                                                zIndex: 1
                                            }}
                                        >
                                            {ordinal(indexs)}
                                        </div>
                                        <DataItemCommentHeaderComponent index={j} commentLen={commentLen} isChild={true} />
                                        <DataItemCommentsComponent survey_no={survey_no} id={`${survey_question_no}-${survey_module_answer_no}-${survey_module_no}`} comments={comments} commentLen={commentLen} />     
                                        
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            })
        }
        </>
    )
}

export default memo(DataItemRankCommentsComponent)