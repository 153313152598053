import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import summaryContext from 'context/summary'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useStyles } from './QuestionButtons'

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > * + *': {
            marginLeft: 5
        }
    }
})(Box)

function CommentButtonsComponent(props) {
    const { onDownload } = props

    const { print } = useContext(summaryContext)

    const classes = useStyles()
    
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    async function handleDownload() {
        dispatch({ type: BACKDROPSHOW })
        setTimeout(async() => {
            await onDownload()
            dispatch({ type: BACKDROPHIDE })
        }, 100)
    }

    return (
        <MyBox>
         {
            !print && (
                <Button 
                    className={classes.button} 
                    startIcon={<GetAppIcon fontSize="small" className={classes.icon}/>} 
                    size="small" 
                    variant="contained" 
                    disableElevation 
                    style={{marginRight: 3}} 
                    onClick={handleDownload}
                >
                    CSV
                </Button>
            )
        }
        </MyBox>
    )
}

export default CommentButtonsComponent