import { useState, useCallback, useEffect, memo } from 'react'
import { useIntl, createIntl } from 'react-intl'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { getMaxlength } from 'policy'
import { validation } from 'utils'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { Box, Button } from '@material-ui/core'
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { UPDATE_SURVEY_MBOX_OPTION_ENDBUTTON } from 'gql/survey_mbox_option'
import { getLocale, getLangMessage } from 'component/Lang/Component'

interface Props {
    survey_no: number;
    lang: string;
    end_button: boolean;
    end_button_text: string;
    end_button_link: string;
}

interface MyInputProps extends StandardTextFieldProps {
    kind: string;
    max_length: number;
    f: any;
    fl: any;
}
 
const MyBox = withStyles({
    root: {
        '& > div:last-child': {
            textAlign: 'right'
        }
    }
})(Box)

const MyInput = withStyles({
    root: {

    }
})((props: MyInputProps) => {
    const { kind, value, max_length, f, fl, ...other } = props

    return (
        <TextField 
            value={value || ''}
            autoFocus={false}
            fullWidth 
            helperText={f({id: `component.Survey.Create.Publish.EndButton.helperText.${kind}`})}
            margin="dense" 
            placeholder={fl({id: `component.Survey.Create.Publish.EndButton.placeholder.${kind}`})}
            inputProps={{
                style: { fontSize: 15, minHeight: 20 },
                maxLength: max_length
            }}
            {...other}
        />
    )
})

function EndButtonComponent(props: Props) {
    const { survey_no, lang, end_button, end_button_text, end_button_link } = props

    const { formatMessage: f } = useIntl()
    const { formatMessage: fl } = createIntl({ locale: getLocale(lang), messages: getLangMessage(lang) })

    const dispatch = useDispatch()

    const [ originalText, setOriginalText ] = useState(end_button_text)
    const [ originalLink, setOriginalLink ] = useState(end_button_link)
    const [ newText, setNewText ] = useState(end_button_text)
    const [ newLink, setNewLink ] = useState(end_button_link)

    const [ updateSurveyMboxOptionEndButton ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_ENDBUTTON, {
        onCompleted: () => {
            dispatch({ type: BACKDROPHIDE })
            setOriginalText(newText)
            setOriginalLink(newLink)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleChangeText = useCallback((e) => {
        setNewText(e.target.value)
    }, [])

    const handleChangeLink = useCallback((e) => {
        setNewLink(e.target.value)
    }, [])

    const handleSave = useCallback(async () => {
        await dispatch({ type: BACKDROPSHOW })
        updateSurveyMboxOptionEndButton({
            variables: {
                survey_no,
                end_button: newText,
                end_button_link: newLink
            }
        })
    }, [survey_no, newText, newLink, dispatch, updateSurveyMboxOptionEndButton])

    // 미설정으로 변경시
    useEffect(() => {
        if (!end_button) {
            setOriginalText(end_button_text)
            setOriginalLink(end_button_link)
            setNewText(end_button_text)
            setNewLink(end_button_link)
        }
    }, [end_button, end_button_text, end_button_link])

    const validLink = newLink ? validation.url(newLink) : true
    let disabled = false
    disabled = (originalText === newText && originalLink === newLink) || !validLink

    return (
        <MyBox>
            <div>
                <MyInput kind="end_button" f={f} fl={fl} value={newText} max_length={getMaxlength('component.Survey.Create.Publish.EndButton.end_button')} onChange={handleChangeText}/>
                <MyInput kind="end_button_link" f={f} fl={fl}  value={newLink} max_length={getMaxlength('component.Survey.Create.Publish.EndButton.end_button_link')} error={!validLink} onChange={handleChangeLink}/>
            </div>
            <div>
                <Button 
                    variant="contained" 
                    disabled={disabled}
                    onClick={handleSave}
                    size="small"
                >
                    {f({id: 'component.Survey.Create.Publish.Message.save'})}
                </Button>
            </div>
        </MyBox>
        
    )
}

export default memo(EndButtonComponent)