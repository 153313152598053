import styled from 'styled-components'
import oc from 'open-color'
import { SurveyCreateShareStandardEmbedComponent, SurveyCreateSharePageEmbedComponent, SurveyCreateSharePopupEmbedComponent, SurveyCreateSharePopoverEmbedComponent } from 'component'

const WrapperStyled = styled.div`
    display: grid;
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: auto;
    grid-template-columns: 22em 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        "menu body";

    & > div.menu {
        grid-area: menu;
        width: 100%;
        border-right: 1px solid ${oc.gray[3]};
    }

    & > div.body {
        grid-area: body;
        position: relative;
        background: ${oc.gray[2]};
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: 
            "menu";

        & > div.menu {
            border-right: none;
        }

        & > div.body {
            display: none;
        }
    }

`

export default (props) => {
    const { args, selected, standard, popup, popover, children } = props

    return (
        <WrapperStyled>
            <div className="menu">{children}</div>
            <div className="body">
                {
                    selected === 'standard' ? <SurveyCreateShareStandardEmbedComponent args={args} {...standard}/> : 
                    selected === 'page' ? <SurveyCreateSharePageEmbedComponent args={args}/> : 
                    selected === 'popup' ? <SurveyCreateSharePopupEmbedComponent args={args} {...popup}/> : 
                    selected === 'popover' ? <SurveyCreateSharePopoverEmbedComponent args={args} {...popover}/> : '' 
                }
            </div>
        </WrapperStyled>
    )
}