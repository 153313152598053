/**
 * ipblocks ip 인풋
 **/ 
import { useState, ChangeEvent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { isEqual } from 'lodash'
import oc from 'open-color'
import { numberFormat } from 'utils'
import { validationIP } from 'ts-utils'
import { SHOW as BACKDROPSHOW, HIDE as  BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import { SurveyMboxOptionProps, UPDATE_SURVEY_MBOX_OPTION_IP_BLOCKS_LIST } from 'gql/survey_mbox_option'
import InputBase from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'

interface Props {
    open: boolean;
    survey_no: number;
    ip_blocks_list: SurveyMboxOptionProps['ip_blocks_list'];
    children: Element;
}

const Textarea = withStyles({
    root: {
        border: `1px solid ${oc.gray[3]}`,
        borderRadius: 3,
        padding: 5,
        color: oc.gray[7],
        fontSize: 14
    }
})(InputBase)

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 15,
        '& > div:first-child': {
            flexGrow: 1
        },
        marginBottom: 10
    }
})(Box)

export const maxCount = 1000

function IpBlocksInoutComponent(props: Props) {
    const { open, survey_no, ip_blocks_list, children } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ originalValue, setOriginalValue ] = useState(ip_blocks_list || '')
    const [ newValue, setNewValue ] = useState(ip_blocks_list || '')

    const disabled = isEqual(originalValue, newValue)

    const [ update ] = useMutation(UPDATE_SURVEY_MBOX_OPTION_IP_BLOCKS_LIST, {
        onCompleted: (data) => {
            setOriginalValue(data.updateSurveyMboxOptionIpBlocksList)
            setNewValue(data.updateSurveyMboxOptionIpBlocksList)
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setNewValue(value)
    }

    const handleSave = () => {
        if (disabled) return

        dispatch({ type: BACKDROPSHOW })

        const items = newValue.replace(/\r\n/g, '\n').split('\n')
        const len = items.length
        const newItems:string[] = []

        let j = 0;
        for (let i=0; i<len; i++) {
            // 1001개부터는 등록안한다.
            if (j >= maxCount) break

            const item = items[i].trim()

            // ip 형식 검사 및 중복 검사
            if (validationIP(item)) {
                if (!newItems.includes(item)) {
                    newItems.push(item)
                    j++;
                }
            }
        }

        // 엔터로 구분
        update({
            variables: {
                survey_no,
                ip_blocks_list: newItems.join('\n')
            }
        })
    }

    // open 하면 기존 origin값을 적용해준다
    useEffect(() => {
        if (open) {
            setNewValue(originalValue)
        }
    }, [open, originalValue])

    return (
        <>
        <MyBox>
            <div>
                <Textarea fullWidth multiline minRows={3} maxRows={3} value={newValue} onChange={handleChange}/>
                <Typography variant="caption" component="div" style={{color: oc.gray[6], paddingLeft: 5}}>
                    {f({id: 'component.Survey.Create.Publish.IpBlocksInput.helper'}, { count: numberFormat(maxCount) })}
                </Typography>
            </div>
            <Button variant="contained" size="small" disabled={disabled} style={{height: 63}} onClick={handleSave}>
                {f({id: 'component.Survey.Create.Publish.CutPeople.button.save'})}
            </Button>
        </MyBox>
        {children}
        </>
    )
}

export default IpBlocksInoutComponent