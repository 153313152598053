import { memo, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { RootState } from "reducer"
import { CHANGE } from 'reducer/analysis/summary/refresh'
import CachedIcon from '@material-ui/icons/Cached'
import Tooltip from 'styled/Custom/Tooltip'
import { IconBox } from "./Buttons"

interface Props {
    refetchQuestions: () => void;
    refetchEndings: () => void;
}

let timer: ReturnType<typeof setTimeout>

function RefreshComponent(props: Props) {
    const { refetchQuestions, refetchEndings } = props
    const dispatch = useDispatch()

    const [ disabled, setDisabled ] = useState(false)

    const { formatMessage: f } = useIntl()

    const { disabled: refresh } = useSelector((state: RootState) => state.analysisSummaryRefresh)

    const handleClick = () => {
        clearTimeout(timer)

        if (refetchQuestions) refetchQuestions()
        if (refetchEndings) refetchEndings()

        timer = setTimeout(() => {
            dispatch({ type: CHANGE })
        }, 100)
    }

    useEffect(() => {
        setDisabled(refresh)
    }, [refresh])

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <Tooltip title={f({id: 'component.Survey.Create.Analysis.Buttons.refresh'})} placement="top">
            <IconBox disabled={disabled} onClick={handleClick}>
                <CachedIcon fontSize="small" />
            </IconBox>
         </Tooltip>
    )
}

export default memo(RefreshComponent)