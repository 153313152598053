import { useContext, memo, useState, useCallback, MouseEvent } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import Box from "@material-ui/core/Box"
import MenuItem from "@material-ui/core/MenuItem"
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import HelpIcon from '@material-ui/icons/Help'
import { withStyles } from "@material-ui/core/styles"
import { useIntl } from 'react-intl'
import { Kind } from 'gql/jump'
import { getDefaultQuestion } from 'utils/survey'
import fixedWidthString from 'fixed-width-string'
import Tooltip from 'styled/Custom/Tooltip'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { NodeIDProps, OnChangeNodeIDProps, DiagramNodeIDContext, DiagramNodeIDEventContext } from './Context'
import { MyTypography } from '../Lists/ItemRules'
import { MyPaper, MyTitle, MyCollapse, MyCloseIcon } from '../Lists/SelectActionQuestion'

const SelectNodeHelpIconBox = withStyles({
    root: {
        position: 'absolute', 
        top: 5, 
        right: 0, 
        backgroundColor: 'white', 
        borderRadius: '100%'
    }
})(Box)

const SelectNodeHelpIcon = withStyles({
    root: {
        display: 'block'
    }
})(HelpIcon)

function GetTitle(props:NodeIDProps) {
    const { formatMessage: f } = useIntl()
    const { id, kind, number, question_type } = props
    if (!id ) return f({id: 'component.Survey.Create.Logic.Jump.Diagram.MySelectNode.all'})

    if (kind === Kind.Page) return `${f({id: 'common.survey.itemType.page'})} ${number}`
    else return `${f({id: `common.survey.itemType.question_type.${question_type}`})} ${number}`
}

function SelectActionQuestionComponent() {
    const { nodeID } = useContext(DiagramNodeIDContext)
    const { onChangeNodeID } = useContext(DiagramNodeIDEventContext)
    const { questionsKeyPageNo, pages } = useContext(Context)

    const { kind, id } = nodeID

    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)
    const [ collapse, setCollapse ] = useState<number[]>([])

    const handleOpenAnchorEl = useCallback((e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const handleCloseAnchorEl = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleChangeCollapse = useCallback((survey_page_no: number) => {
        setCollapse(prev => prev.includes(survey_page_no) ? [] : [ survey_page_no ])
    }, [])

    const handleChangeItem = useCallback((args: OnChangeNodeIDProps) => {
        onChangeNodeID(args)
        handleCloseAnchorEl()
    }, [onChangeNodeID, handleCloseAnchorEl])

    const handleChangeItemInit = useCallback((e) => {
        e.stopPropagation()
        onChangeNodeID(null)
    }, [onChangeNodeID])

    return (
        <MyPaper variant="outlined" error={0} style={{height: 32}}>
            <Tooltip title={f({id: 'component.Survey.Create.Logic.Jump.Diagram.MySelectNode.tooltip'})}>
                <SelectNodeHelpIconBox>
                    <SelectNodeHelpIcon fontSize="small" color="action"/>
                </SelectNodeHelpIconBox>
            </Tooltip>
            <List dense disablePadding>
                <ListItem button onClick={(handleOpenAnchorEl)} style={{padding: '0 2px 0 7px'}}>
                    <MyTitle selected={Boolean(nodeID)} primary={GetTitle(nodeID)}/>
                    {
                        // 선택한 값이 있을때만.
                        id ? <MyCloseIcon onClick={handleChangeItemInit} style={{marginLeft: 15}}/> : null
                    }
                    <ExpandMore/>
                </ListItem>
            </List>
            <Menu 
                anchorEl={anchorEl} 
                keepMounted 
                open={Boolean(anchorEl)}
                onClose={handleCloseAnchorEl}
            >
            {
                pages.reduce((acc, page) => {
                    const { indexs, survey_page_no } = page

                    const open = collapse.includes(survey_page_no)

                    const items = []
                    items.push(
                        <MenuItem 
                            key={survey_page_no} 
                            onClick={() => handleChangeCollapse(survey_page_no)} 
                        >
                            <ListItemText key={survey_page_no}>
                                <MyTypography>{`Section ${indexs}`}</MyTypography>
                            </ListItemText>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </MenuItem>    
                    )

                    const questionsInPage = questionsKeyPageNo ? questionsKeyPageNo[survey_page_no] : null
                    
                    const childItems = []
                    
                    // 설정 종류가 question이라면 동일 문항은 선택안되게 한다.
                    /*
                    if (pages.length > 1) {
                        if (!(kind === Kind.Page && id === survey_page_no)) {
                            childItems.push(
                                <MenuItem 
                                    key={survey_page_no} 
                                    value={survey_page_no}
                                    onClick={() => handleChangeItem({ kind: Kind.Page, id: survey_page_no, number: indexs })}
                                >
                                    <MyTypography>{`Section ${indexs}`}</MyTypography>
                                </MenuItem>
                            )
                        }
                    }
                    */

                    // page내 문항이 없을수 있으므로
                    if (!questionsInPage) return [...acc, ...items]

                    for (let i=0; i<questionsInPage.length; i++) {
                        const { _question } = questionsInPage[i]
                        const { survey_question_no, number, pure_question, question_type } = _question

                        // 설정 종류가 question이라면 동일 문항은 선택안되게 한다.
                        if (kind === Kind.Question && id === survey_question_no) continue

                        const label = getDefaultQuestion({f, number, question: fixedWidthString(pure_question, 50), question_type})
               
                        childItems.push(
                            <MenuItem 
                                key={survey_question_no} 
                                value={survey_question_no}
                                onClick={() => handleChangeItem({ kind: Kind.Question, id: survey_question_no, number, question_type })}
                            >
                              
                                <MyTypography>{label}</MyTypography>
                            </MenuItem>
                        )
                    }

                    items.push(
                        <MyCollapse key={`collapase-${survey_page_no}`} in={open} timeout="auto" unmountOnExit>
                            {childItems}
                        </MyCollapse>
                    )
                    
                    return [...acc, ...items]

                }, [])
            }
            </Menu>
        </MyPaper>
    )
}

export default memo(SelectActionQuestionComponent)