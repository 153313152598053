import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Props as ParentProps } from './Body'
import SubjectComponent from './Subject'
import ColumnValueComponent from './ColumnValue'
import { useStyles as useTextItemStyles } from './TextItem' 

interface Props {
    answer: ParentProps['answer'];
    hideQuestion: ParentProps['hideQuestion'];
}

function EndingComponent(props: Props) {
    const { answer, hideQuestion } = props
    
    const { formatMessage: f } = useIntl()
    
    const classesTextItem = useTextItemStyles({})

    if (!answer.point) return null

    const { choice } = answer.point

    // 답변 없으면 안보여준다
    if (hideQuestion) {
        if (choice.length === 0) return null
        if (choice[0][0] === '') return null
    }

    return (
        <>
        <SubjectComponent typename={"_point"} pure_question={f({id: 'component.Application.SlackPush.QuestionItems.point'})} img_src={""} />
        <div className={classesTextItem.root}>
            <ColumnValueComponent text={choice[0][0]} />
        </div>
        <div style={{ height: 10 }} />
        </>
    )

}

export default memo(EndingComponent)