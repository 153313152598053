import { useIntl } from 'react-intl'
import { Divider, List, ListItem, ListItemText, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MyColor, MyListItemIcon,  MyCodeItem } from 'styled/Survey/Share/Embed'
import PopupImageSizeComponent from './PopupImageSize'
import PopupFontSizeComponent from './PopupFontSize'
import PopoverImageComponent from './PopoverImage'

const useStyles = makeStyles({
    text: {
        whiteSpace: 'nowrap'
    },
    textField: {
        maxWidth: '90%',
        background: 'white'
    },
    fontSizeField: {
        maxWidth: 50
    }
})

export default (props) => {
    const { popup, on } = props
    const { text, color, fontSize, imageSize, imageSizeUnit } = popup

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <List>
            <ListItem>
                <ListItemText className={classes.text}>{f({id: 'component.Survey.Create.Share.Popup.lists.0'})}</ListItemText>
                <MyListItemIcon>
                    <TextField variant="outlined" value={text} margin="dense" onChange={on.changeText} className={classes.textField}/>
                </MyListItemIcon>
            </ListItem>
            <Divider/>
            <PopupFontSizeComponent fontSize={fontSize} onChangeSize={on.changeFontSize}/>
            <Divider/>
            <ListItem>
                <ListItemText>{f({id: 'component.Survey.Create.Share.Popup.lists.1'})}</ListItemText>
                <MyListItemIcon>
                    <MyColor color={color} onClick={(e) => on.openColorPicker(e)}/>
                </MyListItemIcon>
            </ListItem>
            <Divider/>
            <PopoverImageComponent onChange={on.changeImageSrc}/>
            <Divider/>
            <PopupImageSizeComponent imageSize={imageSize} imageSizeUnit={imageSizeUnit} onChangeSize={on.changeImageSize} onChangeUnit={on.changeImageSizeUnit}/>
            <Divider/>
            <MyCodeItem onClick={on.openCode}>
                {f({id: 'component.Survey.Create.Share.Popup.lists.2'})}
            </MyCodeItem>
        </List>
    )
}