import { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { toPng } from 'html-to-image'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Circul } from 'styled/Loading'
import useSurveyTitle from 'hooks/useSurveyTitle'
import { getFilename } from 'component/Survey/Create/NewAnalysis/Summary/DataItem'
import { Props as ParentProps } from './Component'

interface Props {
    divRef: ParentProps['divRef'];
    id: ParentProps['id'];
}

const saveImage = (props: { ref: Props['divRef']; name: string; }) => {
    const { ref, name } = props

    if (!ref) return
    if (!ref.current) return 

    return new Promise((resolve, reject) => {
        ref.current.style.setProperty('width', '720px')
        ref.current.style.setProperty('height', 'auto')
        ref.current.style.setProperty('padding-top', '10px')
        ref.current.style.setProperty('padding-right', '10px')
        ref.current.style.setProperty('padding-bottom', '10px')
        ref.current.style.setProperty('padding-left', '10px')

        // 내용 속 비디오 element
        const videoElement =  ref.current.querySelectorAll('div.video')

        if (videoElement) {
            const videoElementLen = videoElement.length

            for (let i=0; i<videoElementLen; i++) {
                videoElement[i].style = 'display: none'
            }
        }

        const infoElement =  ref.current.querySelector('div.info')
        const bodyElement =  ref.current.querySelector('div.body')

        infoElement.style = `border: 1px solid ${oc.gray[2]}; border-top-left-radius: 3px; ; border-top-right-radius: 3px;`
        bodyElement.style = 'height: 100%; padding-bottom: 0;'
        
        setTimeout(() => {
            toPng(ref.current, { cacheBust: false, backgroundColor: 'white', preferredFontFormat: 'Roboto' }).then((image) => {
                const link = window.document.createElement('a');
                link.style = 'display:none;'
                link.download =  `${name}.png`
                link.href = image;
                link.click()
                link.remove() 
                ref.current.style.setProperty('width', '100%')
                ref.current.style.setProperty('height', '100%')
                ref.current.style.setProperty('padding-top', '0')
                ref.current.style.setProperty('padding-right', '0')
                ref.current.style.setProperty('padding-bottom', '0')
                ref.current.style.setProperty('padding-left', '0')
                infoElement.style = `border: none; border-top-left-radius: 0; ; border-top-right-radius: 0;`
                bodyElement.style = 'calc(100% - 94px); padding-bottom: 100px;'
                
                if (videoElement) {
                    const videoElementLen = videoElement.length
        
                    for (let i=0; i<videoElementLen; i++) {
                        videoElement[i].style = 'display: block'
                    }
                }

                resolve(1)
            })
        }, 50)
    })
}

function PrintComponent(props: Props) {
    const { divRef, id } = props

    const { formatMessage: f } = useIntl()
    
    const [ loading, setLoading ] = useState(false)

    const [ title ] = useSurveyTitle()

    const handleClick = async() => {
        if (loading) return
        if (!divRef) return
        if (!divRef.current) return

        setLoading(true)
        setTimeout(async() => {
            const filename = `${getFilename({ title })}-${id}`
            await saveImage({ ref: divRef, name: filename })   

        setLoading(false)
        }, 300)

    }
   
    return (
        <Button 
            variant="outlined" 
            size="small" 
            style={{ paddingTop: 0, paddingBottom: 0 }} 
            startIcon={loading ? <Circul width="18px" height="18px" color={oc.indigo[9]} /> : <GetAppIcon color="action" fontSize="small" />} 
            onClick={handleClick}
        >
            {f({id: `component.Survey.Create.Analysis.Summary.QuestionButtons.button.image`})}
        </Button>
    )
}

export default memo(PrintComponent)