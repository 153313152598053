import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { parseHtmlIntl } from 'ts-utils'
import { useIntl } from 'react-intl'
import { DELETE_JUMP_POINT_WITH_TEXT } from 'gql/jump_point'
import { DELETE_JUMP_EXAMPLE_COUNT_WITH_TEXT } from 'gql/jump_example_count'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone'
import { withStyles } from '@material-ui/core/styles'
import Dialog from 'styled/Survey/Live/Dialog'
import { Kind, OnOpenKind, OnCloseWaring } from './QuizKindSelect'

interface Props {
    waring: Kind | null;
    onOpenKind: OnOpenKind
    onClose: OnCloseWaring
}

const MyTypography = withStyles(theme => ({
    root: {
        fontSize: 16
    }
}))(Typography)

const MyPaper = withStyles(theme => ({
    root: {
       padding: 20,
       marginBottom: 10
    }
}))(Paper)

const MyIconBox = withStyles(theme => ({
    root: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center'
    }
}))(Box)

const MyIcon = withStyles(theme => ({
    root: {
       width: 60,
       height: 60
    }
}))(WarningTwoToneIcon)


const MyButtonBox = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        }
    }
}))(Box)

function QuizWarningComponent(props: Props) {
    const { waring, onOpenKind, onClose } = props

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const { formatMessage: f } = useIntl()

    const [ deletePoint ] = useMutation(DELETE_JUMP_POINT_WITH_TEXT, {
        onCompleted: () => {
            onOpenKind(Kind.EampleCount)
        }
    })

    const [ deleteExampleCount ] = useMutation(DELETE_JUMP_EXAMPLE_COUNT_WITH_TEXT, {
        onCompleted: () => {
            onOpenKind(Kind.Point)
        }
    })

    let target1 = ''
    let target2 = ''
    if (waring === Kind.EampleCount) {
        target1 = f({id: 'component.Survey.Create.Menu.Quiz.radio.0'})
        target2 = f({id: 'component.Survey.Create.Menu.Quiz.radio.1'})
    } else if (waring === Kind.Point) {
        target1 = f({id: 'component.Survey.Create.Menu.Quiz.radio.1'})
        target2 = f({id: 'component.Survey.Create.Menu.Quiz.radio.0'})
    }

    function handleSave() {
        if (waring === Kind.EampleCount) {
            onClose()
            deletePoint({ variables: { survey_no } })
        } else if (waring === Kind.Point) {
            onClose()
            deleteExampleCount({ variables: { survey_no }})
        } 
    }

    return (
        <Dialog open={Boolean(waring)} fullScreen={false} isMobileFullScreen={true} fullWidth maxWidth="sm" onClose={onClose}>
            <DialogContent>
                <MyPaper variant="outlined">
                    <Grid container spacing={3} alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={2} md={2}>
                            <MyIconBox>
                                <MyIcon color="secondary"/>
                            </MyIconBox>
                        </Grid>
                        <Grid item xs={12} sm={10} md={10}>
                            <MyTypography component="div" style={{marginBottom: 20}}>
                                {parseHtmlIntl(f({id: 'component.Survey.Create.Menu.QuizWarning.message'}, {target1, target2}))}
                            </MyTypography>
                            <MyButtonBox>
                                <Button color="secondary" variant="contained" onClick={handleSave}>{f({id: 'component.Survey.Create.Menu.QuizWarning.button.remove'})}</Button>
                                <Button variant="contained" style={{marginLeft: 5}} onClick={onClose}>{f({id: 'component.Survey.Create.Menu.QuizWarning.button.prev'})}</Button>
                            </MyButtonBox>
                        </Grid>
                    </Grid>
                </MyPaper>
            </DialogContent>
        </Dialog>
    )
}

export default QuizWarningComponent