import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { getColorConcentration } from 'utils'
import { getShareLink } from 'utils/survey'
import { Close as CloseIcon } from '@material-ui/icons'

const ButtonStyled = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    background: ${props => `rgba(${props.color})`};
    color: ${props => props.textColor};
    padding: .7em;
    cursor: pointer;
    font-family: sans-serif;
    font-size: ${props => `${props.fontSize}px`};
`

const DimmStyled = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 300;
    visibility: hidden;

    ${props => {
        const { open } = props
        return open && css`
            visibility: visible;
        ` 
    }}
`

const PopupBoxStyled = styled.div`
    position: absolute;
    visibility: hidden;
    width: 90%;
    height: 0;
    padding: 10px;
    border: none;
    border-radius: 10px;
    transition: height .2s, visibility .2s;
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 301;

    &.open {
        visibility: visible;
        height: 90%;
    }
`

const PopupIframeStyled = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
`

const CloseButtonStyled = styled.div`
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 0;
    border-radius: 100%;
    background: rgba(0, 0, 0, .6);
    cursor: pointer;
    z-index: 302;

    ${props => {
        const { open } = props
        return open && css`
            top: -7px;
            right: -7px;
            width: 20px;
            height: 20px;
            display: flex;
        ` 
    }}
`

const Img = styled.img`
    max-width: ${props => `${props.size}${props.unit}`};
    max-height: 100%;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
`

const initialState = {
   open: false,
   src: ''
}

function PopupEmbedComponent(props) {
    const { args, text, color, fontSize, imageSrc, imageSize, imageSizeUnit } = props

    const [ open, setOpen ] = useState(initialState.open)
    const [ src, setSrc ] = useState(initialState.src)

    const textColor = getColorConcentration(color) ? 'white' : 'black'

    const changeOpen = () => {
        setOpen(prevState => !prevState)
    }

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                const shareLink = getShareLink({args})
                const src = shareLink.survey.preview

                setSrc(src)
            }, 300)
        } else {
            setTimeout(() => {
                setSrc(initialState.src)
            }, 600)
        }
    }, [open, args])


    return (
        <>
        {
            imageSrc ? (
                <Img alt="Survey" src={imageSrc} size={imageSize} unit={imageSizeUnit} onClick={changeOpen}/> 
            ) : (
                <ButtonStyled color={color} textColor={textColor} fontSize={fontSize} onClick={changeOpen} className="rotating">
                    {text}
                </ButtonStyled>
            ) 
        }
        <PopupBoxStyled className={open ? 'open' : ''}>
            <PopupIframeStyled src={src}/>
            <CloseButtonStyled open={open} onClick={changeOpen} >
                <CloseIcon style={{color: 'white', width: 13, height: 13 }}/>
            </CloseButtonStyled>
        </PopupBoxStyled>
        
        <DimmStyled open={open}/>
        </>
    )
}

export default PopupEmbedComponent