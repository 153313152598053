import { memo, ChangeEvent, useContext } from 'react'
import { useIntl } from 'react-intl'
import fixedWidthString from 'fixed-width-string'
import { getEndingMssage } from 'ts-utils/survey'
import { JumpExampleCountProps } from 'gql/jump_example_count'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Input from '@material-ui/core/Input'
import { OnChangeNos } from './Item'
import { EndingContext } from 'container/Survey/Create/Logic/Jump/ExampleCountContext'

interface Props {
    survey_ending_nos: JumpExampleCountProps['survey_ending_nos'];
    onChange: OnChangeNos;
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350
        }
    },
    keepMounted: true
}

function InputMemoComponent(props: Props) {
    const { survey_ending_nos, onChange } = props

    const { endings, endingIndexs } = useContext(EndingContext)

    const { formatMessage: f } = useIntl()

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as number[]);
    };

    return (
        <FormControl>
            <InputLabel id="jump-example-count-label">{f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.InputItems.label'})}</InputLabel>
            <Select
                style={{width: 100}}
                labelId="jump-example-count-label"
                id="jump-example-count"
                label={f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.InputItems.label'})}
                multiple
                value={survey_ending_nos}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected: number[]) => {
                    const rows = selected.filter(c => typeof endingIndexs[c] === 'number').map(c => {
                        return endingIndexs[c]
                    })

                    return rows.join(', ')
                }}
                MenuProps={MenuProps}
  
            >
            {endings.map((c, i) => (
                <MenuItem key={c.survey_ending_no} value={c.survey_ending_no}>
                    <Checkbox checked={survey_ending_nos.includes(c.survey_ending_no)} />
                    <ListItemText primary={`${i + 1}. ${fixedWidthString(getEndingMssage(c.kind, c.pure_message, c.redirect_url), 20)}`} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}

export default memo(InputMemoComponent)