import { memo, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import oc from 'open-color'
import { RootState } from 'reducer'
import { CHANGE } from 'reducer/analysis/filter/saveData'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { OnChangeStartPoint } from 'container/Survey/Create/NewAnalysis/Box'

interface Props {
    isFilter: boolean;
    localStorageName: string;
    onChangeStartPoint?: OnChangeStartPoint;
}

function DeleteAllButtonComponent(props: Props) {
    const { isFilter, localStorageName, onChangeStartPoint } = props

    const dispatch = useDispatch()
    const state = useSelector((states: RootState) => states.analysisFilterSaveData)
    const { data } = state

    if (!data) {
        return null
    }

    if (Object.keys(data).length === 0) {
        return null
    }

    if (!isFilter) {
        localStorage.removeItem(localStorageName)
        return null
    }
    
    const handleClick = (event: MouseEvent<SVGSVGElement>) => {
        event.stopPropagation()
        localStorage.removeItem(localStorageName)
        dispatch({ type: CHANGE, data: {} })
        if (onChangeStartPoint) onChangeStartPoint(0)
    }

    return (
        <DeleteForeverIcon fontSize="small" style={{ color: oc.pink[6] }} onClick={handleClick}/>
    )
}

export default memo(DeleteAllButtonComponent)

