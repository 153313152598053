/**
 * 컬럼 변경
 */

import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Tooltip from 'styled/Custom/Tooltip'
import { Props as ParentProps } from './TableHead'
import TableHeadIdStandardDialogComponent from './TableHeadIdStandardDialog'

export interface Props {
    index: number;
    columns: ParentProps['columns'];
    onChange: ParentProps['onChangeStandardColumnKey'];
}

export const useStyles = makeStyles({
    root: {
        position: 'absolute', 
        right: 0, 
        top: 0,
    },
    iconButton: {
        cursor: 'pointer', 
        borderLeft: 'rgba(0, 0, 0, .1) 1px solid',
        borderBottom: 'rgba(0, 0, 0, .1) 1px solid',
        borderBottomLeftRadius: 3,
        transition: 'all .3s',
        '&:hover': {
            background: 'rgba(0, 0, 0, .1)',
        }
    }
})

function TableHeadIdStandardComponent(props: Props) {
    const { index, columns, onChange } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const [ open, setOpen ] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
        <Tooltip title={f({id: "component.Survey.Create.Analysis.Rows.TableHeadIdStandard.title"})}>
            <div className={`${classes.root} ${classes.iconButton}`} onClick={handleOpen}>
                <KeyboardArrowDownIcon style={{ display: 'block', color: '#4050B5' }} />
            </div>
        </Tooltip>
        <TableHeadIdStandardDialogComponent open={open} columns={columns} index={index} onChange={onChange} onClose={handleClose} />
        </>
    )    
}

export default memo(TableHeadIdStandardComponent)