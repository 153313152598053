import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { getColorConcentration } from 'utils'
import { getShareLink } from 'utils/survey'
import { Icon } from 'styled/Survey/Share/Embed'
import { Close as CloseIcon } from '@material-ui/icons'

const WidgetStyled = styled.div`
    position: absolute;
    ${props => {
        const { position } = props
        return position === 'bottom-right' ? css`
            bottom: 20px;
            right: 20px;
        ` : position === 'bottom-left' ? css`
            bottom: 20px;
            left: 20px;
        ` : position === 'top-right' ? css`
            top: 20px;
            right: 20px;
        ` : position === 'top-left' && css`
            top: 20px;
            left: 20px;
        `
    }}
`

const ButtonStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    border-radius: 100%;
    background: ${props => `rgba(${props.color})`};
    cursor: pointer;
`

const PopoverIframeStyled = styled.iframe`
    position: absolute;
    visibility: hidden;
    width: 80%;
    max-width: 428px;
    height: 80%;
    max-height: 678px;
    border: none;
    border-radius: 10px;
    transition: all .3s;
    background-color: white;
    box-shadow: 1px 1px 2px #e9ecef, 0 0 25px #868e96, 0 0 5px #e9ecef;
    overflow-y: hidden;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    

    ${props => {
        const { open, position, size } = props

        const pos = `${(Number(size) / 1.5 + 20)}px`

        return (open && position === 'bottom-right') ? css`
            visibility: visible;
            bottom: ${pos};
            right: ${pos};
        ` : (open && position === 'bottom-left') ? css`
            visibility: visible;
            bottom: ${pos};
            left: ${pos};
        ` : (open && position === 'top-right') ? css`
            visibility: visible;
            top: ${pos};
            right: ${pos};
        ` : (open && position === 'top-left') ? css`
            visibility: visible;
            top: ${pos};
            left: ${pos};
        ` : (!open && position === 'bottom-right') ? css`
            visibility: hidden;
            width: 0;
            height: 0;
            bottom: 0;
            right: 0;
        ` : (!open && position === 'bottom-left') ? css`
            visibility: hidden;
            width: 0;
            height: 0;
            bottom: 0;
            left: 0;
        ` : (!open && position === 'top-right') ? css`
            visibility: hidden;
            width: 0;
            height: 0;
            top: 0;
            right: 0;
        ` : (!open && position === 'top-left') && css`
            visibility: hidden;
            width: 0;
            height: 0;
            top: 0;
            left: 0;
        `
    }}
`

const Img = styled.img`
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    border-radius: 100%;
    cursor: pointer;
`

const initialState = {
   open: false,
   link: ''
}

function iconStyles(props) {
    const { color, size } = props

    return {
        color,
        width: (size * .6), 
        height: (size * .6)
    }
} 

export default (props) => {
    const { args, shape, color, imageSrc, size, position } = props

    const [ open, setOpen ] = useState(initialState.open)
    const [ link, setLink ] = useState(initialState.link)
    const [ imgSrc, setImgSrc ] = useState(imageSrc)

    const iconColor = getColorConcentration(color) ? 'white' : 'black'

    const changeOpen = () => {
        setOpen(prevState => !prevState)
    }

    useEffect(() => {
        if (open) {
            const shareLink = getShareLink({args})
            const link = shareLink.survey.preview

            setTimeout(() => setLink(link), 300)
        } else {
            setLink(initialState.link)
        }
    }, [open, args])

    useEffect(() => {
        const img = document.createElement('img');
        img.src = imageSrc
        
        img.onload = function () {
            setImgSrc(imageSrc)
        }

        img.onerror = function() {
            setImgSrc('')
        }
    }, [imageSrc])

    return (
        <>
        <WidgetStyled position={position}>
            {
                imgSrc ? <Img alt="Survey" src={imgSrc} size={size} onClick={changeOpen}/> : (
                    <ButtonStyled color={color} size={size} onClick={changeOpen} className="rotating">
                    {
                        open ? <CloseIcon style={iconStyles({ color: iconColor, size })}/> : <Icon shape={shape} style={iconStyles({ color: iconColor, size })}/>
                    }
                    </ButtonStyled>
                )
            }
        </WidgetStyled>
        <PopoverIframeStyled position={position} size={size} open={open} src={link}/>
        </>
    )
}