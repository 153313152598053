import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useLazyQuery, useMutation } from '@apollo/client'
import { 
    GET_ROWS_FILE_DOWNLOAD_LOG, 
    CREATE_ROWS_FILE_DOWNLOAD_LOG,
    RowsFileDownloadLogProps, 
    RowsFileDownloadLogVariables, 
    CreateRowsFileDownloadLogVariables 
} from 'gql/rows_file_download_log'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { useIntl } from 'react-intl'
import { delay } from 'ts-utils'
import { getDownloadTitle } from 'utils/analysis'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import moment from 'moment'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import HelpIcon from '@material-ui/icons/Help'
import useSurveyTitle from 'hooks/useSurveyTitle'
import { MyListItemText, useStyles, ButtonTypography } from './Raw'
import Tooltip from 'styled/Custom/Tooltip'
import { Explain, getLimit, Filedownload as FiledownloadSummary } from '../Summary/FileUploadDialog'
import { getFilename } from '../Summary/DataItem'
import useFileUploadSize from 'hooks/useFileUploadSize'
import useTokenInfo from 'hooks/useTokenInfo'
import FileUploadSizeOverComponent from '../Summary/FileUploadSizeOver'
import { getFileQuestionTitle } from './Items'

interface Props {
    questions: SurveyQuestionModuleProps[];
    files: any;
    onClose: () => void;
}

class Filedownload extends FiledownloadSummary {
    setFileQuestionTitle(fileQuestionTitle: {[key: number]: string}) {
        this.fileQuestionTitle = fileQuestionTitle
    }
    async do(type:string, data: Props['files'], start:number, tag=0) {
        const zip = new JSZip()
        
        const len = data.length
    
        let zipname = ''
        if (tag === 0) zipname = getDownloadTitle({title: this.title, type: 'File' })
        else zipname = getDownloadTitle({ title: `${this.title}-${tag}`, type: 'File' })

        let total = 0

        for (let i=start; i<len; i++) {
            const { survey_analysis_users_no, survey_question_no, number, src, filename, filesize } = data[i]
            
            if (src) {
                this.dispatch({
                    type: BACKDROPSHOW,
                    msg: this.f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.update.message'}, {index: (i+1), len})
                })
    
                await delay(10)
    
                const blob = await this.doFetch(src)
        
                if (blob) {
                    let folder = zip
                    
                    const title = this.fileQuestionTitle[survey_question_no] 

                    const titleFilename = getFilename({ number: `${number}.`, title })

                    let name = `${titleFilename}-${survey_analysis_users_no}-${filename}`
            
                    if (type === 'folder') {
                        folder = zip.folder(String(survey_analysis_users_no))
                        name = `${titleFilename}-${filename}`
                    }
                    
                    // create a new file from the blob object
                    const file = new File([blob], name)
            
                    folder.file(name, file, { base64: true })
                }      
            }

            const nextUserNo = data[i+1] ? data[i+1].survey_analysis_users_no : 0
            
            total += (filesize || 0)
        
            // 무료 이면서, 파일다운로드
            if (!this.verifyLimitFreeFiledownload(total)) {
                const content = await zip.generateAsync({ type: 'blob' })
                FileSaver.saveAs(content, `${zipname}.zip`)
                
                this.dispatch({ type: BACKDROPHIDE })
                
                break        
            }
    
            // 500MB보다 클경우 파일 분리
            if ((total > this.limitTotal && survey_analysis_users_no !== nextUserNo) || i === (len - 1)) {
                const content = await zip.generateAsync({ type: 'blob' })
                FileSaver.saveAs(content, `${zipname}.zip`)
                
                if (i === (len - 1)) this.dispatch({ type: BACKDROPHIDE })
                else this.do(type, data, i+1, tag+1)

                break
            }
        }
    }



    // 현재 미사용
    async doUser(id:number, data: Props['files']) {
        return new Promise(async(resolve, reject) => {
            try {
                const zip = new JSZip()
                const len = data.length
    
                for (let i=0; i<len; i++) {
                    const { number, src, filename, } = data[i]

                    
    
                    if (!src) continue
    
                    await delay(100)
    
                    const blob = await this.doFetch(src)
            
                    if (!blob) continue
    
                    let folder = zip
                    let name = `${id}-${number}-${filename}`
    
                    // create a new file from the blob object
                    const file = new File([blob], name)
            
                    // 파일이 하나이면 압축안한다
                    if (len === 1) {
                    
                        FileSaver.saveAs(file, name)
                        resolve(1)
                        return
                    }
    
                    folder.file(name, file, { base64: true })
                }
    
                const content = await zip.generateAsync({ type: 'blob' })
                FileSaver.saveAs(content, `File-${id}.zip`)

                resolve(1)
            } catch (e) {
                reject(e)
            }
        })
    }
}

function FileDownload(props: Props) {
    const { questions, files, onClose } = props

    const fileQuestionTitle = getFileQuestionTitle(questions)

    const newFiles = files.filter((c: any) => c.src)

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const token = useTokenInfo()

    const [ title ] = useSurveyTitle()

    const [{ limit: limitFileUploadSize, used: usedFileUploadSize, isOver }] = useFileUploadSize()

    const [ type, setType ] = useState('folder')

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const [ count, setCount ] = useState(10)

    const [ getData ] = useLazyQuery<{rowsFileDownloadLog: RowsFileDownloadLogProps[]}, RowsFileDownloadLogVariables>(GET_ROWS_FILE_DOWNLOAD_LOG, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const now = moment().format('YYYY.MM.DD')
            const rows = data.rowsFileDownloadLog
            const len = rows.filter((row) => {
                const { wdate } = row
                const time = moment(Number(wdate)).format('YYYY.MM.DD')

                return now === time
            }).length

            setCount(len)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateData ] = useMutation<CreateRowsFileDownloadLogVariables>(CREATE_ROWS_FILE_DOWNLOAD_LOG, {
        onCompleted: async() => {
            setCount(prevCount => prevCount + 1)
         
            const filedownload = new Filedownload({dispatch, f, token, title})
            filedownload.setFileQuestionTitle(fileQuestionTitle)
            await filedownload.do(type, newFiles, 0, 0)

            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function updateCount() {
        updateData({
            variables: { survey_no }
        })
    }

    useEffect(() => {
        getData({ variables: { survey_no } })
    }, [survey_no, getData]) 

    const limit = getLimit(newFiles)

    return (
        <>
        <DialogContent style={{paddingTop: 0}}>
            {isOver ? (
                <div style={{marginTop: 10}}>
                    <FileUploadSizeOverComponent limit={limitFileUploadSize} used={usedFileUploadSize}/>
                </div>
            ) : (
                <>
                <div>
                <Explain type="rows"/>
                </div>
              
                <List className={classes.list}>
                    <ListItem>
                        <MyListItemText>
                            {f({id: 'component.Survey.Create.Analysis.Rows.FileDownload.kind'})}
                            <IconButton size="small" style={{cursor: 'default'}}>
                                <Tooltip 
                                    title={f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.filename.secondary.rows'})} 
                                    isMobileClick={true}
                                    placement="top"
                                >
                                    <HelpIcon fontSize="small"/>
                                </Tooltip>
                            </IconButton>
                        </MyListItemText>
                        <ListItemSecondaryAction>
                            <ButtonGroup size="small" disableElevation variant="contained" color="primary">
                                <Button className={classes.button} color={type === 'folder' ? 'primary' : 'default'} onClick={() => setType('folder')}>
                                    <ButtonTypography>{f({id: 'component.Survey.Create.Analysis.Rows.FileDownload.button.folder'})}</ButtonTypography>
                                </Button>
                                <Button className={classes.button} color={type === 'file' ? 'primary' : 'default'} onClick={() => setType('file')}>
                                    <ButtonTypography>{f({id: 'component.Survey.Create.Analysis.Rows.FileDownload.button.file'})}</ButtonTypography>
                                </Button>
                            </ButtonGroup>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                </>
            )}
            
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={updateCount} disabled={count >= limit || newFiles.length === 0 || isOver}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.button.download'}, {count, limit})}</Button>
            <Button onClick={onClose}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.button.close'})}</Button>
        </DialogActions>
        </>
    )
}

export default FileDownload