import { ChangeEvent, useState, useEffect, memo } from 'react'
import { useParams } from 'react-router'
import { useLazyQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { SurveyMboxProps } from 'gql/survey_mbox'
import { SurveyWorkspaceProps } from 'gql/survey_workspace'
import { GET_SURVEY_MBOX_IN_WORKSPACE } from 'gql/survey_mbox'
import fixedWidthString from 'fixed-width-string'
import oc from 'open-color'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'
import withSurveyFetchData from 'hoc/Survey/FetchData'
import { useStyles } from './CopyItem'
import { Props as OtherCopyProps } from './OtherCopy'
import CopyComponent from './Copy'

interface Props extends UsePrevStateProps {
    anchorEl: OtherCopyProps['anchorEl'];
    defaults: OtherCopyProps['defaults'];
    onChangePage: OtherCopyProps['onChangePage'];
    onChangeQuestion: OtherCopyProps['onChangeQuestion'];
    onChangePosition: OtherCopyProps['onChangePosition'];
    propsFetchData: {
        surveyWorkspacesInGroup: SurveyWorkspaceProps[];
    }
}

const MyBox = withStyles({
    root: {
        backgroundColor: oc.gray[1],
        borderRadius: 3,
        padding: 10,
        marginBottom: 20,
        width: '100%'
    }
})(Box)

const OtherCopyComponent = (props: Props) => {
	const { 
        anchorEl,
        defaults,
		onChangePage, 
		onChangeQuestion,
		onChangePosition,
        propsFetchData,
        usePrevState
	} = props

    const { surveyWorkspacesInGroup: surveyWorkspaces } = propsFetchData

    const open = Boolean(anchorEl)
    const prevOpen = usePrevState(open)

    const params = useParams<{survey_no:string}>()
    const survey_no = Number(params.survey_no)

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    // 해당 설문의 originDefault 
    const [ originDefaults, setOriginDefaults ] = useState(defaults)

    const [ newDefaults, setNewDefaults ] = useState(defaults)

    const [ other, setOther ] = useState(false)

    // 작업함 
    const [ workNo, setWorkNo ] = useState<number | ''>('')

    const [ otherSurveyNo, setOtherSurveyNo ] = useState<number | ''>('')

    // 설문지 리스트 호출
    const [ getMbox, { data } ] = useLazyQuery<{surveyMboxInWorkspace: SurveyMboxProps[]}>(GET_SURVEY_MBOX_IN_WORKSPACE, {
        fetchPolicy: 'network-only'
    })

    function handleChangeOther(e: ChangeEvent<HTMLInputElement>) {
        const { checked } = e.target
        // 기존 설문으로 볼때 다른 설문지로 선택했던 값 초기화 시킨다.
        if (!checked) {
            setOtherSurveyNo('')
            setWorkNo('')
            setNewDefaults(originDefaults)
        }
        setOther(checked)   
    }

    // 작업함 변경
    function handleChangeWorkNo(e: ChangeEvent<{ name?: string; value: number | '' }>) {
        const { value } = e.target
        setWorkNo(value)
        setOtherSurveyNo('')
        getMbox({
            variables: {
                survey_workspace_no: value
            }
        })
    }

    function handleChangeMboxNo(e: ChangeEvent<{ name?: string; value: number | '' }>) {
        const { value } = e.target
        setOtherSurveyNo(value)
    }

    // 창 열릴때만 오리지날 defaults 저장
    useEffect(() => {
        if (open && !prevOpen) {
            setOriginDefaults(defaults)
        }
    }, [open, prevOpen, defaults])


    // default 변경될때마다
    useEffect(() => {
        setNewDefaults(defaults)
    }, [defaults])


    // 다른 설문지로 복사 시, 작업함 설문 변경하면 초기화 (섹션 및 문항 다시 선택해야 한다)
    useEffect(() => {
        if (other) {
            setNewDefaults({
                survey_page_no: '',
                survey_question_no: '',
                position: 1
            })
        }
    }, [other, workNo, otherSurveyNo])

    useEffect(() => {
        if (!open) {
            setOther(false)
            setOtherSurveyNo('')
            setWorkNo('')
            setNewDefaults(defaults)
        }
    }, [open, defaults])

    const items = !data ? [] : data.surveyMboxInWorkspace.filter(c => c.survey_no !== survey_no)

	return (
		<>
        <MyBox>
            <FormControlLabel
                control={<Checkbox checked={other} onChange={handleChangeOther} name="checkedA" style={{padding: '0 8px 0 8px'}}/>}
                label={f({id: 'component.Survey.Create.Module.OtherFuncCopy.checkbox.label'})}
            />
            {
                <>
                {
                    other && (
                        <div style={{paddingTop: 10}}>
                        <FormControl className={classes.form}>
                            <InputLabel>{f({id: 'component.Survey.Create.Module.OtherFuncCopy.select.label.0'})}</InputLabel>
                            <Select value={workNo} onChange={handleChangeWorkNo}>
                                {
                                    surveyWorkspaces.map(c => (
                                        <MenuItem key={c.survey_workspace_no} value={c.survey_workspace_no}>
                                            {fixedWidthString(c.title, 40)}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl  className={classes.formMove}>
                            <InputLabel>{f({id: 'component.Survey.Create.Module.OtherFuncCopy.select.label.1'})}</InputLabel>
                            <Select value={otherSurveyNo} onChange={handleChangeMboxNo} disabled={items.length === 0}>
                                {
                                    items.map(c => (
                                        <MenuItem key={c.survey_no} value={c.survey_no}>
                                            {fixedWidthString(c.title, 40)}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        </div>
                    )
                }
                </>
            }
        </MyBox>
        <CopyComponent
            other={other}
            other_survey_no={otherSurveyNo}
            defaults={newDefaults}
            onChangePage={onChangePage}
            onChangeQuestion={onChangeQuestion}
            onChangePosition={onChangePosition}
        />
		</>
	)
}

export default withPrevState(withSurveyFetchData('edit')({surveyWorkspacesInGroup: true})(false)(memo(OtherCopyComponent)))

