import { memo, useContext } from 'react'
import oc from 'open-color'
import summaryContext from 'context/summary'
import { makeStyles } from '@material-ui/core/styles'
import { IconsBoxStyled } from 'styled/Survey/Analysis/Summary'

export const useStyles = makeStyles({
    button: {
        fontSize: 12,
        color: oc.gray[7]
    },
    icon: {
        width: 15,
        height: 15,
        color: oc.gray[7]
    }
})

function QuestionButtonsComponent(props) {
    const { children } = props
    
    const { print } = useContext(summaryContext)

    if (print) return null

    return (
        <IconsBoxStyled>
            {children}
        </IconsBoxStyled>
    )
}

export default memo(QuestionButtonsComponent)