import { memo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from "react-redux"
import { SHOW, HIDE } from 'reducer/survey/create/logic/jumpCollapse'
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { withStyles } from '@material-ui/core/styles'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import ButtonResetComponent from './ButtonReset'

interface Props {
    breakpoints: BreakPointsProps
}

const MyBox = withStyles({
    root: {
        display: 'inline',
        '& > * + *': {
            marginLeft: 10
        }
    }
})(Box)

function ButtonFunctionComponent(props: Props) {
    const { breakpoints } = props

    const dispatch = useDispatch()

    const { lg } = breakpoints

    const { formatMessage: f } = useIntl()

    function handleOpen() {
        dispatch({ type: SHOW })
    }

    const handleClose = useCallback(() => {
        dispatch({ type: HIDE })
    }, [dispatch])

    return (
        <MyBox>
            {
                !lg && (
                    <ButtonGroup size="small">
                        <Button onClick={handleOpen}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonFunction.button.open'})}</Button>
                        <Button onClick={handleClose}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonFunction.button.close'})}</Button>
                    </ButtonGroup>
                )
            }
            <ButtonResetComponent onClose={handleClose}/>
        </MyBox>
    )
}

export default withBreakPoints(memo(ButtonFunctionComponent))