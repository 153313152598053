import { useContext, useCallback } from 'react'
import PropsContext from 'context/props'
import { SurveyLiveQuestionComponent } from 'component'
import OneStyled, { QuestionStyled } from 'styled/Survey/Live/One'
import styled from 'styled-components'
import { getBackground, getReply, getComment } from 'utils/survey'
import EditSurveyContext from 'context/editSurvey'

const WrapperStyled = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-attachment: scroll !important;
`

const Component = (props) => {
    const { focus, question } = props

    const { mbox, replys, replyComments } = useContext(PropsContext)
    const { viewPicture } = useContext(EditSurveyContext)

    const { method } = mbox
    const { _question } = question
    const { img_src, img_position } = _question

    const { design } = useContext(PropsContext)

    const style = getBackground(design)

    const onPageNextOne = useCallback(() => {

    }, [])

    return (
        <WrapperStyled style={style}>
            <OneStyled no={focus.uuid} img_src={img_src} img_position={img_position} viewPicture={viewPicture}>
                <QuestionStyled img_src={img_src} img_position={img_position} method={method}>
                    <div>
                        <div>
                            <SurveyLiveQuestionComponent 
                                key={question._question.survey_question_no} 
                                reply={getReply({replys, question})}
                                replyComment={getComment({replyComments, question})}
                                mbox={mbox}
                                _question={question._question}
                                _modules={question._modules}
                                _answers={typeof question._answers === 'object' ? question._answers : []}
                                _option={question._option}
                                onPageNextOne={onPageNextOne}
                            />
                        </div>
                    </div>
                </QuestionStyled>
            </OneStyled>
        </WrapperStyled>
    )
}

export default Component