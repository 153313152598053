import { memo } from "react"
import Avatar from "@material-ui/core/Avatar"
import fixedWidthString from 'fixed-width-string'
import { useIntl } from 'react-intl'
import withSurveyPopoverImage, { OnOpen, OnClose } from 'hoc/Survey/PopoverImage'
import { SurveyQuestionModuleProps, AnswerRank, AnswerRankImg  } from 'gql/survey_question_modules'
import { JumpNo } from 'gql/jump'
import { MySelect, MyTypography, OnChangeChildsProps } from './ItemRules'
import { MenuItemImgBox } from './SelectModule'
import { MyMenuItem } from './SelectModule'

interface Props extends OnChangeChildsProps {
    popoverImageProps: {
        on: {
            open: OnOpen,
            close: OnClose
        }
    };
    question: SurveyQuestionModuleProps;
    typename: string;
    jump_rule_child_no: JumpNo;
    survey_module_answer_no: number;
}

function SelectRankAnswerComponent(props: Props) {
    const { question, typename, jump_rule_child_no, survey_module_answer_no, onChange, popoverImageProps } = props

    const { on } = popoverImageProps
    const onOpen = on.open

    const { formatMessage: f } = useIntl()

    const { _answers } = question

    return (
 
        <MySelect
            error={!survey_module_answer_no} 
            displayEmpty 
            value={survey_module_answer_no || ''} 
            style={{display: 'flex'}}
            label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectRankAnswer.label'})}
            onChange={(e) => {
                onChange('survey_module_answer_no', jump_rule_child_no, Number(e.target.value))
            }}
        >
            {
                typename === 'rank' ? (
                    _answers.map((c: AnswerRank) => {
                        const { survey_module_answer_no, indexs, answer } = c

                        const label = fixedWidthString(`${indexs}. ${answer}`, 30)
    
                        return (
                            <MyMenuItem key={survey_module_answer_no} value={survey_module_answer_no}>
                                <MyTypography>{label}</MyTypography>
                            </MyMenuItem>
                        )
                    })
                ) : typename === 'rankImage' ? (  
                    _answers.map((c: AnswerRankImg) => {
                        const { survey_module_answer_no, indexs, answer, src } = c

                        const label = fixedWidthString(`${indexs}. ${answer}`, 30)

                        return (
                            <MyMenuItem key={survey_module_answer_no} value={survey_module_answer_no}>
                                <MenuItemImgBox>
                                    <Avatar 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onOpen(src)
                                        }}
                                        src={src}
                                    />
                                    <MyTypography>{label}</MyTypography>
                                </MenuItemImgBox>
                            </MyMenuItem>
                        )
                    })
                ) : null
            }
        </MySelect>
    )
}

export default withSurveyPopoverImage(memo(SelectRankAnswerComponent))