import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { SurveyAnalysisRowsVariables } from 'gql/survey_analysis_rows'
import Box from "@material-ui/core/Box"
import { withStyles } from '@material-ui/core/styles'
import { RemoveButton } from './Component'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import RemoveFileDialogComponent from './RemoveFileDialog'

export type Where = {
    step: SurveyAnalysisRowsVariables['input']['step'];
    date: SurveyAnalysisRowsVariables['input']['date'];
}

export interface Props {
    allCount: number;
    where: Where;
    search: string;
    checked: number[];
    files: any[];
    onRefetch: (args?: object) => void;
    breakpoints: BreakPointsProps;
}

export type Open = 'all' | 'part' | null
export type OnClose = () => void;

export const ButtonBox = withStyles(theme => ({
    root: {
        display: 'flex', 
        alignItems: 'center', 
        gap: 5,
        whiteSpace: 'nowrap',
        fontWeight: 700,
        fontSize: 12,
        '& > div:first-child': {
            color: oc.gray[7]
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))(Box)

const BarBox = withStyles(theme => ({
    root: {
        height: '50px', 
        backgroundColor: oc.gray[2], 
        width: 1, 
        marginLeft: 15, marginRight: 15,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))(Box)

function FileRemoveButtonComponent(props: Props) {
    const { allCount, where, search, checked, files, onRefetch, breakpoints } = props
    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState<Open>(null)

    const isChecked = files.find(file => checked.includes(file.survey_analysis_users_no) && file.src)

    let style = {}
    if (isChecked) style = { border: `1px solid ${oc.cyan[9]}`, color: oc.cyan[9] } 


    function handleOpen(type: Open) {
        setOpen(type)
    }

    const handleClose = useCallback(() => {
        setOpen(null)
    }, [])

    // 파일이 아예 없을경우
    const allDisabled = allCount === 0

    if (allDisabled) return null

    if (xs) return null

    return (
        <>
        <ButtonBox>
            <div>{f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveButton.title'})}</div>
            <div>
             
                <RemoveButton disabled={!isChecked} onClick={() => handleOpen('part')}>
                    {f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveButton.button.0'})}
                </RemoveButton>
                {
                    search === '' && (
                        <RemoveButton onClick={() => handleOpen('all')}>
                            {f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveButton.button.1'})}
                        </RemoveButton>
                    )
                }
        
            </div>
        </ButtonBox>
        <BarBox />
        <RemoveFileDialogComponent 
            open={open} 
            where={where}
            search={search}
            checked={checked} 
            onRefetch={onRefetch} 
            onClose={handleClose}
        />
        </>
    )
}

export default memo(withBreakPoints(FileRemoveButtonComponent))