import { useContext, memo, useState, useCallback, useMemo, MouseEvent, createContext } from "react"
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import Paper, { PaperProps } from "@material-ui/core/Paper"
import List, { ListProps } from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText, { ListItemTextProps } from "@material-ui/core/ListItemText"
import Menu, { MenuProps } from "@material-ui/core/Menu"
import Fade from "@material-ui/core/Fade"
import ExpandMore from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from "@material-ui/core/styles"
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { Kind, ID, Method } from 'gql/jump'
import { getDefaultQuestion } from 'utils/survey'
import fixedWidthString from 'fixed-width-string'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import Collapse from '@material-ui/core/Collapse'
import SelectActionQuestionItemsComponent from "./SelectActionQuestionItems"

export enum Refference {
    Else,
    Rule
}

interface Props {
    refference: Refference;
    kind: Kind;
    id: ID;
    method: Method;
    surveyQuestionNos: number[];
    onChange: (a: number[]) => void;
}

interface MyListItemTextProps extends ListItemTextProps {
    selected: boolean;
}

interface MyPaperProps extends PaperProps {
    error: number;
}

interface MyListProps extends ListProps {
    style?: {}
}

export const MyMenu = withStyles({
    list: {
        maxHeight: 250,
        overflowY: 'scroll'
    }
})((props: MenuProps) => {
    return (
        <Menu
            {...props}
            variant="menu"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            TransitionComponent={Fade}
        />
    )
})

export const MyPaper = withStyles(theme => ({
    root: ({ error }: { error: number }) => {
        return {
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            height: 50,
            border: error === 1 ? `1px solid ${theme.palette.secondary.main} !important` : `1px solid ${theme.palette.action.disabled}`,
            '&:focus-within': {
                border: `2px solid ${theme.palette.primary.main}`
            }
        }
    },
    outlined: {
        padding: 0
    }
}))((props: MyPaperProps) => {
    const { children, ...other } = props
    return (
        <Paper {...other}>
            {children}
        </Paper>
    )
})

export const MyTitle = withStyles(theme => ({
    primary: ({ selected }: MyListItemTextProps) => {
        const styles = !selected ? { color: theme.palette.action.active, fontSize: 15 } : {} 

        return {
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'block',
            overflow: 'hidden',
            fontSize: 15,
            ...styles
        }
    }
}))(ListItemText)

export const MyList = withStyles({

})((props: MyListProps) => <List dense disablePadding {...props}/>)

export const MyCollapse = withStyles(theme => ({
    root: {
        paddingBottom: 10,
        minWidth: 250,
        maxWidth: 630
    },
    wrapper: {
        backgroundColor: oc.pink[0]
    }
}))(Collapse)

export const MyCloseIcon = withStyles({
    root: {
        width: 12,
        height: 12,
        marginRight: 5
    }
})(CloseIcon)

function GetTitle(self: SurveyQuestionModuleProps[] | null, surveyQuestionNos: number[]) {
    const len = surveyQuestionNos.length
    const { formatMessage: f } = useIntl()

    if (!self) {
        if (surveyQuestionNos[0] === 1) return f({id: 'component.Survey.Create.Menu.Component.subheader.thanks.start'})
        else return f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectActionQuestion.title'})
    }

    const [ { _question } ] = self
    const { number, pure_question, question_type } = _question

    let question = fixedWidthString(pure_question, 20)
    if (len > 1) question = f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMatrixModule.render'}, { render: question, len: (len - 1)})

    return getDefaultQuestion({f, number, question, question_type })
}

export interface ItemsContextProps {
    anchorEl: null | HTMLElement;
    surveyQuestionNos: number[];
    onChange: (a: number[]) => void;
} 

export const ItemsContext = createContext<ItemsContextProps>({
    anchorEl: null,
    surveyQuestionNos: [],
    onChange: () => {}
})

function SelectActionQuestionComponent(props: Props) {
    const { refference, kind, id, method, surveyQuestionNos, onChange } = props

    const {questionsKeyQuestionNo } = useContext(Context)

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)

    const handleOpenAnchorEl = useCallback((e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const handleCloseAnchorEl = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleSelectedNull = useCallback((e) => {
        e.stopPropagation()
        
        onChange([])
    }, [onChange])

    const self = questionsKeyQuestionNo ? questionsKeyQuestionNo[surveyQuestionNos[0]] : null

    // Refference.Rule 이면서 값이 없다면 error true이단. else는 값이 없어도 된다.
    const error = (refference === Refference.Rule && (!self && surveyQuestionNos[0] !== 1)) ? 1 : 0

    const selected = Boolean(self) || surveyQuestionNos[0] === 1

    return (
        <ItemsContext.Provider value={{anchorEl, surveyQuestionNos, onChange}}>
            <MyPaper variant="outlined" error={error}>
                <MyList style={{ flexGrow: 1 }}>
                    <ListItem button onClick={handleOpenAnchorEl}>
                        <MyTitle selected={selected} primary={GetTitle(self, surveyQuestionNos)}/>
                        {
                            // else일때만 X버튼 준다
                            (refference === Refference.Else && surveyQuestionNos.length > 0) && (
                                <MyCloseIcon onClick={handleSelectedNull}/>
                            )
                        }
                        <ExpandMore/>
                    </ListItem>
                </MyList>
                {
                    // surveyQuestionNos 바뀔때마다 MyMenu 재랜더링하면 위치가 바뀐다. 후... 그래서 useMemo 주고, surveyQuestionNos는 Context로 보낸다...
                    useMemo(() => (
                        <MyMenu 
                            anchorEl={anchorEl} 
                            open={Boolean(anchorEl)}
                            onClose={handleCloseAnchorEl}
                        >
                            <span>
                                <SelectActionQuestionItemsComponent
                                    kind={kind} id={id} method={method} onCloseAnchorEl={handleCloseAnchorEl}
                                />
                            </span>
                            
                        </MyMenu>
                    ), [kind, id, method, anchorEl, handleCloseAnchorEl])
                }
            </MyPaper>
        </ItemsContext.Provider>
    )
}

export default memo(SelectActionQuestionComponent)