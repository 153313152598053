import { memo, lazy, Suspense, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { Tabs, Tab, Divider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import Dialog from "styled/Survey/Live/Dialog"
import { RowsProps } from 'hoc/Survey/Rows'
import withPrevState, { UsePrevStateProps} from 'hoc/PrevState'

const RawComponent = lazy(() => import('./Raw'))
const PersonalComponent = lazy(() => import('./Personal'))
const FileDownloadComponent = lazy(() => import('./FileDownload'))

export interface Props extends UsePrevStateProps {
    action: number;
    etc: {
        questions: SurveyQuestionModuleProps[];
        open: boolean;
        onClose: () => void;
    }
    rowsData: RowsProps['rowsData'];
}

const MyTab = withStyles({
    root: {
        paddingTop: 10,
        fontSize: 16
    }
})(Tab)

function DownloadMenuComponent(props: Props) {
    const { action, etc, rowsData, usePrevState } = props
    const { questions } = etc

    const { complete, loading, rows } = rowsData

    const prevAction = usePrevState(action)

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ tab, setTab ] = useState(0)

    const [ open, setOpen ] = useState(false)

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setTab(newValue)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    useEffect(() => {
        // 로딩이 있을경우 안연다
        if (loading) {
            setOpen(false)
            return
        }

        if (!loading) dispatch({ type: BACKDROPHIDE })

        // 데이터 가져오기가 완료되었다면 창을 열어준다.
        if (action > 0 && complete) {
            setOpen(true)
        } else if (!complete && action > 0) {
            /*
            ** 다운로드 후 다른페이지 갓다가 다시오면 data, refetch등이 undefined 로 초기화 되면서 구분값이 없어진다
            ** 다른페이지 갓다가 다시 와서 다운로드 누르면 prevAction은 undefined인데 이때 complete가 false이다. 
            ** 임의로 창을 열어준다.
            */
            if (prevAction === undefined) {
                setOpen(true)
            }
        }

    }, [dispatch, loading, complete, action, prevAction])

    return (
        <Dialog fullScreen={false} fullWidth={true} isMobileFullScreen={true} open={open} onClose={handleClose}>
            <Tabs value={tab} indicatorColor="primary" textColor="primary" variant="scrollable" onChange={handleChange}>
                <MyTab label={f({id: 'component.Survey.Create.Analysis.Rows.Dialog.Tab.1'})} />
                <MyTab label={f({id: 'component.Survey.Create.Analysis.Rows.Dialog.Tab.0'})} />
                <MyTab label={f({id: 'component.Survey.Create.Analysis.Rows.Dialog.Tab.2'})} />
            </Tabs>
            <Divider style={{marginBottom: 15}}/>
                <Suspense fallback="">
                    {
                        tab === 1 ? <PersonalComponent questions={questions} data={rows} onClose={handleClose}/> : 
                        tab === 0 ? <RawComponent data={rows} onClose={handleClose}/> : <FileDownloadComponent questions={questions} files={rows.files} onClose={handleClose}/>

                    }
                </Suspense>
        </Dialog>

    )
}

export default withPrevState(memo(DownloadMenuComponent))