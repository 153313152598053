/**
 * 선택된 보기 option
 */

import { memo, MouseEvent } from 'react'
import oc from 'open-color'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { defaultImage } from 'styled/Survey/Img'
import { Props as ParentProps } from './Component'
 
interface Props {
    keyValue: string;
    primarys: ParentProps['choices'];
    selectBoxOpen?: boolean;
    onClick: () => void;
    onDelete: ParentProps['onChange'];
}
 
function Component(props: Props) {
    const { keyValue, primarys, selectBoxOpen, onClick, onDelete } = props

    const handleDelete = (event: MouseEvent<HTMLButtonElement>, id: ParentProps['choices'][0]['id']) => {
        event.stopPropagation()
        onDelete(keyValue, id)
    }

    return (
        <Paper variant="outlined">
            <MenuItem dense button onClick={onClick}>
                <ListItemText
                    primaryTypographyProps={{ 
                        style: { 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 3, 
                            flexWrap: 'wrap'
                        } 
                    }}
                >
                    {
                        primarys.map((primary) => {
                            return (
                                <div key={primary.id} style={{ 
                                    border: `1px solid ${oc.gray[4]}`, 
                                    color: oc.gray[8],
                                    background: 'white',
                                    padding: 5, 
                                    height: 28,
                                    borderRadius: 5,
                                    fontSize: 12,
                                    display: 'block',
                                    overflow: 'hidden',   
                                    textOverflow: 'ellipsis',  
                                    whiteSpace: 'nowrap',
                                    position: 'relative',
                                    paddingLeft: typeof primary.src !== 'undefined' ? 50 : 30
                                }}>
                                    <IconButton size="small" style={{ position: 'absolute', top: 0, left: 0 }} onClick={(event) => handleDelete(event, primary.id) }>
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                    {
                                        typeof primary.src !== 'undefined' && (
                                         
                                            <img 
                                                src={primary.src || defaultImage} 
                                                alt="listovey" 
                                                style={{ width: 20, height: 20, borderRadius: 3, marginRight: 5, position: 'absolute', left: 25, bottom: 3 }} 
                                            />
                                          
                                        )
                                    }
                                    {primary.text}
                                </div>
                            )
                        })
                    }
                </ListItemText>
                {selectBoxOpen === true ? <ExpandLess color="action" /> : selectBoxOpen === false ?  <ExpandMore color="action" /> : null}
            </MenuItem>
        </Paper>
    )
}
 
export default memo(Component)