import { useQuery } from '@apollo/client'
import { GET_SURVEY_DESIGNS_TEMPLATE_ALL, SurveyDesignsProps } from 'gql/survey_designs'
import Grid from '@material-ui/core/Grid'
import ItemComponent from '../My/Item'
import { Props as ParentProps } from '../Component' 

interface Props {
    onApply: ParentProps['onApply'];
    onOpenPop: ParentProps['onOpenPop'];
}

function GalleryComponent(props: Props) {
    const { onApply, onOpenPop } = props

    const { data } = useQuery<{surveyDesignsTemplateAll: SurveyDesignsProps[]}>(GET_SURVEY_DESIGNS_TEMPLATE_ALL)

    if (!data) return null

    return (
        <Grid container spacing={2}>
        {
            data.surveyDesignsTemplateAll.map(row => {
                return (
                    <ItemComponent
                        key={row.survey_designs_no}
                        survey_designs_no={row.survey_designs_no}
                        title={row.title}
                        line_color={row.line_color}
                        background_size={row.background_size}
                        background_opacity={row.background_opacity}
                        background_color={row.background_color}
                        background_image={row.background_image}
                        background_filter={row.background_filter}
                        font_family={row.font_family}
                        numbering={row.numbering}
                        question={row.question}
                        answer={row.answer}
                        answer_button={row.answer_button}
                        answer_sbutton={row.answer_sbutton}
                        template={true}
                        onApply={onApply}
                        onOpen={onOpenPop}
                    />
                )
            })
        }
        </Grid>
    )
}

export default GalleryComponent