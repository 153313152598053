import Tab from 'styled/Survey/Edit/Tab'

// tabIndex 해당 컨첸츠 인덱스
// index 현재 선택된 인덱스
export default ({children}) => {
    
    return (
        <Tab tabIndex={0} index={0}>
            {children}
        </Tab>
    )
}