import { useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import Dialog from 'styled/Survey/Live/Dialog'
import ContextContainer from 'container/Survey/Create/Logic/Jump/Context'
import PointContextContainer from 'container/Survey/Create/Logic/Jump/PointContext'
import PointContainer from 'container/Survey/Create/Logic/Jump/Point'
import EndingContextContainer from 'container/Survey/Create/Logic/Jump/EndingContext'
import EndingContainer from 'container/Survey/Create/Logic/Jump/Ending'
import ExampleCountContextContainer from 'container/Survey/Create/Logic/Jump/ExampleCountContext'
import ExampleCountContainer from 'container/Survey/Create/Logic/Jump/ExampleCount'
import { Kind } from './QuizKindSelect'
import QuizTitleComponent from './QuizTitle'

interface Props {
    kind: Kind | null;
    onClose: () => void;
}

// Point:포인트합산(Point, Ending)
export enum PointKind {
    Point,
    Ending
}

export type OnChangePointKind = (a: PointKind) => void


const MyContent = withStyles(theme => ({
    root: {
        height: 'calc(100vh - 300px)',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 50px)'
        }
    }
}))(Box)

const MyBox = withStyles({
    root: {
        height: '100%'
    }
})(Box)

function QuizComponent(props: Props) {
    const { kind, onClose } = props

    const open = Boolean(kind)

    const [ pointKind, setPointKind ] = useState(PointKind.Point)

    const handleChangePointKind: OnChangePointKind = useCallback((val) => {
        setPointKind(val)
    }, [])

    return (
        <Dialog open={open} fullScreen={false} isMobileFullScreen={true} fullWidth maxWidth="md" onClose={() => {}}>
            <DialogTitle>
                {
                    open ? (
                        <QuizTitleComponent kind={kind} pointKind={pointKind} onChange={handleChangePointKind}/>
                    ) : ''
                }
            </DialogTitle>
            <IconButton style={{position: 'absolute', top: 0, right: 0}} onClick={onClose}>
                <CloseIcon/>
            </IconButton>
            <MyContent>
            {
                (open) && (
                    <ContextContainer warning={false}>
                    {
                        kind === Kind.EampleCount ? (
                            <MyBox>
                                <ExampleCountContextContainer>
                                    <ExampleCountContainer/>
                                </ExampleCountContextContainer>
                            </MyBox>
                        ) : (
                            <MyBox>
                                <div style={{display: pointKind === PointKind.Point ? 'block' : 'none', height: '100%'}}>
                                    <PointContextContainer>
                                        <PointContainer/>
                                    </PointContextContainer>
                                
                                </div>
                                <div style={{display: pointKind === PointKind.Ending ? 'block' : 'none', height: '100%'}}>
                                    <EndingContextContainer>
                                        <EndingContainer/>
                                    </EndingContextContainer>
                                </div>
                            </MyBox>
                        )
                    }
                    </ContextContainer>
                )  
            }
            </MyContent>
        </Dialog>
    )
}

export default QuizComponent