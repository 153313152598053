import { DialogContent as MuiDialogContent, DialogActions, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { MyDialog } from '../Lists/Mobile'

const DialogContent = withStyles({
    root: {
        padding: `0 !important`
    }
})(MuiDialogContent)

const Mobile = (props) => {
    const { method, xs, isExcludeCurrentPage, onSave, onClose, children } = props
    
    const { formatMessage: f } = useIntl()

    return (
        <MyDialog fullScreen={xs} fullWidth open={true} onClose={onClose}>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {
                    isExcludeCurrentPage && (
                        <Button color="primary" onClick={() => onSave(method)}>
                          <Typography variant="button">{f({id: `component.Survey.Create.Pages.Move.save.${method}`})}</Typography>
                        </Button>
                    )
                }
                <Button onClick={onClose}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Pages.Move.close'})}</Typography>
                </Button>
            </DialogActions>
        </MyDialog>
    )
}

export default Mobile