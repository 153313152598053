import { memo } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import oc from 'open-color'
import { setSearchCookie } from 'ts-utils/summary'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { makeStyles } from '@material-ui/core/styles'
import DateButton from 'styled/Survey/Analysis/DateButton'
import { getDate } from 'styled/Survey/Analysis/Calendar'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    cookieName: string;
    disabled?: boolean;
    open: boolean;
    date: string;
    onInitial: () => void;
    onOpen: () => void;
    onClose: () => void;
    onDo: (val: string) => void;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 2
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        height: 33,
        border: `${oc.gray[4]} 1px solid`,
        borderRadius: 5,
        background: 'white',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            cursor: 'pointer',
            transition: 'all .3s',
        },
        '& > div:first-child': {
           borderTopLeftRadius: 5,
           borderBottonLeftRadius: 5,
        },
        '& > div:last-child': {
            borderTopRightRadius: 5,
            borderBottonRightRadius: 5,
         },
        '& .date-button': {
            width: '100%',
            height: '100%',
            transition: 'all .3s'
        }
    }
}))

function DateBox(props: Props) {
    const { cookieName, disabled=false, open, date, onInitial, onOpen, onClose, onDo } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const parseDate = getDate(date)
    const today = moment().format('YYYY-MM-DD')

    // disabled 일경우
    const color = disabled ? oc.gray[4] : 'black'

    const nextDisabled = parseDate.sdate >= today && parseDate.edate >= today
    const nextStyles = nextDisabled ? { color: oc.gray[4], cursor: 'default' } : { color }

    const handleChange = (direction: string) => {
        if (disabled) return
        if (direction === 'next' && nextDisabled) return

        if (!date) {
            onDo('today')
            if (cookieName) setSearchCookie(cookieName, 'today')
            return
        }

        const sdate = direction === 'prev' ? moment(parseDate.sdate).subtract(1, 'd').format('YYYY-MM-DD') : moment(parseDate.sdate).add(1, 'd').format('YYYY-MM-DD')
        const edate = direction === 'prev' ? moment(parseDate.edate).subtract(1, 'd').format('YYYY-MM-DD') : moment(parseDate.edate).add(1, 'd').format('YYYY-MM-DD')

        // 다음날짜에서 오늘하고 같거나 크다면 오늘로 간다
        const newSdate = sdate >= today ? today : sdate
        const newEdate = edate >= today ? today : edate

        //const newDate = date ===
        const newDate = `${newSdate} ~ ${newEdate}`
        if (cookieName) setSearchCookie(cookieName, newDate)
        onDo(newDate)
    }

   
    return (
        <div className={classes.root}>
            <div className={classes.icon}>
                <div>
                    <Tooltip title={f({id: 'component.Survey.Create.Analysis.DateBox.prev'})} placement="top">
                        <ArrowLeftIcon style={{ display: 'block', color }} onClick={() => handleChange('prev')} />
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title={open ? "" : f({id: 'component.Survey.Create.Analysis.DateBox.setting'})} placement="top">
                        <span className="date-button">
                            <DateButton 
                                cookieName={cookieName}
                                open={open} 
                                date={date} 
                                onInitial={onInitial} 
                                onOpen={onOpen} 
                                onClose={onClose} 
                                onDo={onDo}
                                size="small"
                                variant="contained"
                                style={{background: 'transparent', borderRadius: 0, borderRight: '#CED4DA 1px solid', borderLeft: '#CED4DA 1px solid', height: '100%' }}
                            />
                        </span>
                    </Tooltip>
                </div>
                   
                <div>
                    <Tooltip title={f({id: 'component.Survey.Create.Analysis.DateBox.next'})} placement="top">
                        <ArrowRightIcon style={{ display: 'block', ...nextStyles }} onClick={() => handleChange('next')} />
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default memo(DateBox)