import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TitleTypography } from 'styled/Survey/Analysis/Common'

const useStyles = makeStyles({
    container: {
        marginBottom: 5,
        minHeight: 36
    }
})

export default (props) => {
    const { title, children } = props

    const classes = useStyles()
    
    return (
        <Grid className={classes.container} container justifyContent="space-between" alignItems="flex-end">
            <Grid xs={2} md={4} item>
                <TitleTypography>{title}</TitleTypography>
            </Grid>
            <Grid xs={10} md={8} item>{children}</Grid>
        </Grid>
    )
}
