import { memo } from 'react'
import { useIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AnnouncementTwoToneIcon from '@material-ui/icons/AnnouncementTwoTone';

function MobileMessageComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <Paper variant="outlined" style={{ height: `calc(100vh - 22.4em)`, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 20 }}>
            <AnnouncementTwoToneIcon color="secondary" fontSize="large" />
            <Typography variant="body2">
                {f({id: 'component.Survey.Create.Analysis.Rows.MobileMessage.message'})}
            </Typography>
        </Paper>
    )
}

export default memo(MobileMessageComponent)