import { memo } from 'react'
import { useIntl } from 'react-intl'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableHeadCellTextComponent from './TableHeadCellText'
import { MyBox, IconComponent } from './TableHeadCellValue'

interface Props {
    field: string;
    labelArray: string[];
    orderKey: string;
    getOrderDirection: (a: string) => string;
    onRequestSort: (a: string) => void;
}

const useStyles = makeStyles(theme => ({
    root: {
        whiteSpace: 'pre',
    },
    active: {
        color: theme.palette.primary.main
    },
    icon: {
        color: theme.palette.primary.main
    },
    
    iconDirectionDesc: {
        color: theme.palette.primary.main
    },
    iconDirectionAsc: {
        color: theme.palette.primary.main
    }
}))

function TableHeadIdComponent(props: Props) {
    const { field, labelArray, orderKey, getOrderDirection, onRequestSort } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const direction = getOrderDirection(field)

    if (field === 'accesstime' || field === 'einterval') {
        return (
            <MyBox>
                <div>
                    <IconComponent typename={field}  f={f} />
                </div>
                <div>
                    <TableSortLabel 
                        classes={classes} 
                        active={orderKey === field} 
                        direction={direction} // 선택된 컬럼에서 다시 클릭시 order값을, 다른 컬럼클릭시 asc 로 초기화
                        onClick={() => onRequestSort(field)}
                    >
                        {
                            labelArray.map((c, i) => <TableHeadCellTextComponent key={`${i}-${c}`} field={field} label={c} />)
                        }
                    </TableSortLabel>
                </div>
            </MyBox>
        )

    }

    return (
        <MyBox>
            <div>
                <IconComponent typename={field}  f={f} />
            </div>
            <div>
                {
                    labelArray.map((c, i) => <TableHeadCellTextComponent key={`${i}-${c}`} field={field} label={c} />)
                }
            </div>
        </MyBox>
    )    
}

export default memo(TableHeadIdComponent)