import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    container: {
        paddingTop: 20
    }
})
const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    z-index: 1;
    height: 100%;
`

const BoxStyled = styled.div`
    height: 100%;
`

const getListStyle = () => ({
    width: '100%'
})


const Component = (props) => {
    const { refComments, onModule, design, mbox, onDnDEnd, children } = props
    
    const classes = useStyles()

    return (
        <WrapperStyled>
            <BoxStyled>
                <DragDropContext onDragEnd={onDnDEnd} >
                    <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}  {...provided.droppableProps} style={getListStyle()}> 
                            <Container className={classes.container}>
                            <AccessMethodContext.Provider value={{mode: 'edit'}}>
                                <PropsContext.Provider value={{ refComments, onModule, design, mbox}}>
                                    {children}
                                </PropsContext.Provider>
                            </AccessMethodContext.Provider>
                            </Container>
                            {provided.placeholder}    
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
            </BoxStyled>

        </WrapperStyled>
    )
}

export default Component