/**
 * 컬럼 변경
 */

import { memo } from 'react'
import { useIntl } from 'react-intl'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from 'styled/Survey/Live/Dialog'
import { Props as ParentProps } from './TableHeadIdStandard'
import TableHeadIdStandardDialogItemsComponent from './TableHeadIdStandardDialogItems'

interface Props {
    open: boolean;
    index: ParentProps['index'];
    columns: ParentProps['columns'];
    onChange: ParentProps['onChange'];
    onClose: () => void;
}

function TableHeadIdStandardDialogComponent(props: Props) {
    const { open, index, columns, onChange, onClose } = props

    const { formatMessage: f } = useIntl()

    const a: string[] = []
    const b: string[] = []

    for (let i=0; i<columns.length; i++) {
        a.push(columns[i].field)

        if (!b.includes(columns[i].field)) b.push(columns[i].field)
    }

    return (
        <Dialog open={open} fullScreen={false} onClose={onClose}>
            <DialogTitle>{f({id: "component.Survey.Create.Analysis.Rows.TableHeadIdStandard.title"})}</DialogTitle>
            <DialogContent>
                <TableHeadIdStandardDialogItemsComponent index={index} columns={columns} onChange={onChange} onClose={onClose} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{f({id: "component.Survey.Create.Analysis.Rows.TableHeadIdStandardDialog.button.close"})}</Button>
            </DialogActions>
        </Dialog>
    )    
}

export default memo(TableHeadIdStandardDialogComponent)