import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import HelpIcon from '@material-ui/icons/Help'
import { TitleTypography } from 'styled/Survey/Analysis/Common'
import Tooltip from 'styled/Custom/Tooltip'
import { TitleGrid, TitleStyled, useStyles } from './Component'
import RefererDomainComponent from './RefererDomain'
import RefererConturyCityComponent from './RefererConturyCity'

interface Props {
    chartRefererDomain: object;
    chartRefererCity: object;
    chartRefererContury: object;
    refererDomainCount: object;
    refererConturyCount: object;
    refererCityCount: object;
    cookieName: string;
    ids: string[];
}

function RefererGraphComponent(props: Props) {
    const { chartRefererDomain, chartRefererCity, chartRefererContury, refererDomainCount, refererConturyCount, refererCityCount, cookieName, ids } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    return (
        <>
        <TitleGrid style={{marginTop: 60}}> 
            <Grid item xs={12} sm={12} md={12}>
                <TitleStyled>
                    <TitleTypography>{f({id: 'component.Survey.Create.Analysis.Insight.RefererGraph.title.0'})}</TitleTypography>
                    <Tooltip variant="button" placement="top" isMobileClick={true} title={f({id: 'component.Survey.Create.Analysis.Insight.RefererGraph.tooltip.0'})}>
                        <HelpIcon color="disabled" fontSize="small" className="help"/>
                    </Tooltip>        
                </TitleStyled>
            </Grid>
        </TitleGrid>
        <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Paper className={classes.chart} style={{position: 'relative'}} variant="outlined">
                    <RefererDomainComponent chartRefererDomain={chartRefererDomain} refererDomainCount={refererDomainCount} id={ids[4]}/>
                </Paper>
            </Grid>
        </Grid>
        {
            /**
             * 
             * <TitleGrid style={{marginTop: 60}}> 
            <Grid item xs={12} sm={8} md={8}>
                <TitleStyled>
                    <TitleTypography>{f({id: 'component.Survey.Create.Analysis.Insight.RefererGraph.title.1'})}</TitleTypography>
                    <Tooltip variant="button" placement="top" isMobileClick={true} title={f({id: 'component.Survey.Create.Analysis.Insight.RefererGraph.tooltip.1'})}>
                        <HelpIcon color="disabled" fontSize="small" className="help"/>
                    </Tooltip>        
                </TitleStyled>
            </Grid>
            <Grid item xs={false} sm={4} md={4}>
                <Typography style={{fontSize: 12}} color="secondary">{f({id: 'component.Survey.Create.Analysis.Insight.RefererGraph.tip'})}</Typography>
            </Grid>
        </TitleGrid>
        <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Paper className={classes.chart} variant="outlined" style={{position: 'relative'}}>
                    <RefererConturyCityComponent 
                        chartRefererContury={chartRefererContury} 
                        chartRefererCity={chartRefererCity}
                        refererConturyCount={refererConturyCount}
                        refererCityCount={refererCityCount}
                        cookieName={cookieName}
                        id={ids[5]}
                    />
                </Paper>
            </Grid>
        </Grid>
             */
        }
        </>
    )
}

export default RefererGraphComponent