import { memo, Fragment, useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { Divider as MuiDivider } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { isEqual } from 'lodash-es'
import { scroller, animateScroll as scroll } from 'react-scroll'
import withBreakPoints from 'hoc/BreakPoints'
import { scrollerObj } from './Data'
import DataFuncComponent from './DataFunc'
import PageBoxComponent from 'styled/Survey/Analysis/PageBox'

const Divider = withStyles({
    root: {
        marginTop: 25
    }
})(props => <MuiDivider {...props}/>)

function DataScrollComponent(props) {
    const { items, viewMode, survey_no, print, breakpoints } = props

    const { sm } = breakpoints

    const dispatch = useDispatch()

    const [ index, setIndex ] = useState(0)

    const [ itemsState, setItemsState ] = useState(items)

    const handleChange = useCallback((i: number) => {
        if (index === i) return 

        dispatch({ type: BACKDROPSHOW })

        setTimeout(() => {
            setIndex(i)
        }, 200)

    }, [index, dispatch])

    const handleChangeOpen = useCallback((survey_question_no, key, open) => {
        setItemsState(prevState => {
            return prevState.map((c) => {
                if (c.common.survey_question_no !== survey_question_no) return c

                return {
                    ...c,
                    common: {
                        ...c.common,
                        [key]: open
                    }
                }

            })
        })
    }, []) 

    const handleChangeEnd = useCallback(() => {
        dispatch({ type: BACKDROPHIDE })

        scroller.scrollTo('top', {
            ...scrollerObj.scrollTo,
            containerId: scrollerObj.id
        })
        
        scroll.scrollTo(0)
    }, [dispatch])

    const datas = itemsState.slice(index * 20, index * 20 + 20)
    const length = datas.length

    let itemLength = itemsState.length

    const isEnding = itemsState.find(item => item.common.question_type === 'ending')
    if (isEnding) {
        // 완료글이 하나이면 안보이므로 카운트해서 뺀다
        if (isEnding.modules.length < 2) itemLength = itemLength - 1
    }

    useEffect(() => {
        setItemsState(items)
    }, [items])

    // report 이면서, sm이면 left:0

    const pageStyles = (viewMode === 'report' && sm) ? { left: 0 } : { }

    return (
        <>
        {
            useMemo(() => (
                datas.map((c, i) => {
                    const { common } = c
                    const key = common.kind === 'question' ? `${common.kind}-${common.survey_question_no}` : `${common.kind}-${common.survey_ending_no}`
                    return (
                        <Fragment key={key}>
                        {
                            i > 0 && <Divider/>
                        }
                            <DataFuncComponent 
                                viewMode={viewMode}
                                {...c}
                                i={i}  
                                length={length}
                                survey_no={survey_no}
                                print={print ? 1 : 0}
                                onChangeEnd={handleChangeEnd}
                                onChangeOpen={handleChangeOpen}
                            />
                        </Fragment>
                    )
                })
            ), [datas, viewMode, survey_no, print, length, handleChangeEnd, handleChangeOpen])
        }
        { itemLength > 20 && (
            <div style={{ position: viewMode === 'report' ? 'fixed' : 'sticky', bottom: -10, zIndex: 2, ...pageStyles }}>
                <PageBoxComponent index={index} length={itemLength} onChange={handleChange}/>
            </div>
        )}
        </>
        
    )
}

export default memo(withBreakPoints(DataScrollComponent), (prev, next) => {
    return  isEqual(prev.items, next.items) && 
            isEqual(prev.viewMode, next.viewMode) && 
            isEqual(prev.survey_no, next.survey_no) 
})