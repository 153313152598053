import { scrollerObj as ScrollerObj } from 'utils/survey'
import { Element } from 'react-scroll'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import ButtonBoxComponent from './ButtonBox'
import ItemsComponent from './Items'

export type Point = number | '-' | ''

export const ComponentBox = withStyles({
    root: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        height: '100%'
    }
})(Box)

export const MyBox = withStyles({
    root: {
        padding: 20
    }
})(Box)

export const scrollerObj = ScrollerObj()

export const style = {
    ...scrollerObj.style,
    height: '100%',
    position: 'relative',
    backgroundAttachment: 'scroll'
}

function Component() {
    return (
        <ComponentBox>      
           <Element id={scrollerObj.id} style={style}>
                <MyBox>
                    <ItemsComponent/>
                </MyBox>
            </Element>
            <ButtonBoxComponent/>
        </ComponentBox>
    )
}

export default Component