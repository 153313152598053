/**
 * 보기마다 코멘트있는 문항 (01, 02, 12, 13)
 */
import { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { _12, GET_SURVEY_ANALYSIS_SUMMARY_COMMENT_QUESTION_MODULE } from 'gql/survey_analysis_summary'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppIcon from '@material-ui/icons/GetApp'
import { getKey } from 'hoc/Survey/Summary'
import { useStyles } from './QuestionButtons'
import { parseText } from './QuestionButtonText'
import { getFilename } from './DataItem'

interface Props {
    survey_no: number; 
    survey_question_no: number; 
    filename: string;
    modules: _12['modules'];
}

const makeFile = (props: { survey_no: number, survey_question_no: number, modules: _12['modules'], values: any; filename: string; f: any }) => {
    const { survey_no, survey_question_no, modules, values, filename, f } = props

    return new Promise((resolve) => {
        const zip = new JSZip()
        const key = getKey(survey_no)

        const label = f({id: 'utils.analysis.ppts.writeBarComments.label'})
        
        const folder = zip.folder(filename)

        const len = modules.length

        for (let i=0; i<len; i++) {
            const { survey_module_no, i: index, answer, src } = modules[i]
            const id = `${key}-${survey_no}-${survey_question_no}-${survey_module_no}`
            const value = values[id]

            if (!value) continue

            let newAnswer = answer
            
            // 이미지형인데 내용이 없을경우
            if (typeof src !== 'undefined' && !answer) newAnswer = 'IMAGE' 

            const answerFilename = getFilename({ number: `A${(index+1)}.`, title: newAnswer })

            const comments = value.map(c => parseText({idd: c.idd, wdate: c.wdate, comment: c.comment}))
            comments.unshift(label)

            folder.file( `${answerFilename}.csv`, comments.join("\n"))
        }

        zip.generateAsync({type: 'blob'}).then(function(content) {
            FileSaver.saveAs(content, `${filename}.zip`)
            resolve(1)
        })
    })
}

function QuestionButtonModuleTextComponent(props: Props) {
    const { survey_no, survey_question_no, modules, filename } = props
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ loading, setLoading ] = useState(false)

    const [ getData ] = useLazyQuery(GET_SURVEY_ANALYSIS_SUMMARY_COMMENT_QUESTION_MODULE, {
        onCompleted: async(data) => {

            await makeFile({ survey_no, survey_question_no, modules, values: data.surveyAnalysisSummaryCommentQuestionModule, filename, f })

            setLoading(false)

        },
        fetchPolicy: 'network-only'
    })

    const handleSave = async() => {
        if (loading) return

        setLoading(true)
        getData({
            variables: {
                survey_no,
                survey_question_no,
                key: getKey(survey_no)
            }
        })
    }

 
    const disabled = modules.filter(c => c.comments.length > 0).length === 0

    return (
        <Button 
            className={classes.button} 
            startIcon={
                loading ? <CircularProgress size={15} /> : <GetAppIcon fontSize="small" className={classes.icon} /> 
            } 
            disabled={loading || disabled}
            size="small" 
            variant="contained" 
            disableElevation 
            style={{ marginRight: 3 }}
            onClick={handleSave}
        >
            CSV
        </Button>
    )
}

export default memo(QuestionButtonModuleTextComponent)