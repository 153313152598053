/**
 * 보기 정렬
 */
import { useEffect, useState, MouseEvent, memo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { CHANGE, INIT } from 'reducer/analysis/summary/order'
import { RootState } from 'reducer'
import { parseHtmlIntl } from 'ts-utils'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Menu from '@material-ui/core/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import SortIcon from '@material-ui/icons/Sort'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import { IconBox } from '../Buttons'

interface Props {
    survey_no: number;
    questions: SurveyQuestionModuleProps[];
    xs: boolean;
}

const MyListItem = withStyles({
    root: {
        width: '100%'
    }
})(ListItem)

function SortComponent(props: Props) {
    const { survey_no, xs } = props

    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<null | SVGSVGElement>(null)

    const { order } = useSelector((state: RootState) => state.analysisSummaryOrder)

    const dispatch = useDispatch()

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOpen = (event: MouseEvent<any>):void => {
        setAnchorEl(event.currentTarget)
    }

    function handleChange<T>(props: { kind: 'order' | 'pointType', value: T }):void {
        const { kind, value } = props

        dispatch({ type: CHANGE, survey_no, [kind]: value})
    }

    // 초기 값을 가져온다.
    useEffect(() => {
        dispatch({ type: INIT, survey_no })
    }, [survey_no, dispatch])
    
    const click = Boolean(anchorEl)
    return (
        <>
        {
            xs ? (
                <BottomNavigationAction showLabel label={f({id: "component.Survey.Create.Analysis.Summary.Sort.button.title"})} icon={<SortIcon />} onClick={handleOpen}/>
            ) : (
                <Tooltip title={f({id: "component.Survey.Create.Analysis.Summary.Sort.button.title"})} placement="top">
                    <IconBox click={click ? 1 : 0} onClick={handleOpen}>
                        <SortIcon fontSize="small"/>
                    </IconBox>
                </Tooltip>
            )
        }

        <Menu
            id="summary-sort"
            anchorEl={anchorEl}
            keepMounted
            elevation={10}
            getContentAnchorEl={null}
            open={click}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
        >
            <MyListItem>
                <ListItemText 
                    style={{marginRight: 30}}
                    primary={f({id: "component.Survey.Create.Analysis.Summary.Sort.order.primary"})}
                    secondary={parseHtmlIntl(f({id: "component.Survey.Create.Analysis.Summary.Sort.order.secondary"}))}
                />
                <ListItemSecondaryAction>
                    <ButtonGroup size='small' variant='contained' disableElevation>
                        <Button color={order === 'answer' ? 'primary' : 'default'} onClick={() => handleChange({ kind: 'order', value: 'answer'})}>
                           {f({id: "component.Survey.Create.Analysis.Summary.Sort.order.button.answer"})}
                        </Button>
                        <Button color={order === 'count' ? 'primary' : 'default'} onClick={() => handleChange({ kind: 'order', value: 'count'})}>
                            {f({id: "component.Survey.Create.Analysis.Summary.Sort.order.button.count"})}
                        </Button>
                    </ButtonGroup>
                </ListItemSecondaryAction>
            </MyListItem>
        </Menu>
        </>
    )
}

export default memo(SortComponent)