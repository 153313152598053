import { useIntl, createIntl } from 'react-intl'
import { TextField } from '@material-ui/core'
import { getMaxlength } from 'policy'
import { getLocale, getLangMessage } from 'component/Lang/Component'

function MessageComponent(props) {
    const { lang, type, newValue, onChange } = props
    

    const { formatMessage: f } = useIntl()
    const { formatMessage: fl } = createIntl({ locale: getLocale(lang), messages: getLangMessage(lang) })

    return (
        <TextField 
            autoFocus={false}
            fullWidth 
            helperText={f({id: `component.Survey.Create.Publish.MessageInput.helperText`})}
            margin="dense"
            multiline 
            maxRows={3} 
            placeholder={fl({id: `container.Survey.Live.Ip.${type}`})}
            value={newValue}
            onChange={onChange}
            inputProps={{
                style: { fontSize: 15, minHeight: 20 },
                maxLength: getMaxlength('component.Survey.Create.Publish.MessageInput')
            }}
        />
    )
}

export default MessageComponent