import { ListItem, Divider, Switch, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import CreateBox from 'styled/Survey/CreateBox'

const useStyles = makeStyles({
    formControlLabel: {
    
    }
})

export default (props) => {
    const { page_random, except_last_page_random, on } = props
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <CreateBox>
            <ListItem>
                <FormControlLabel 
                    value="start" 
                    control={<Switch color="primary" checked={page_random}/>} 
                    onChange={on.change} 
                    label={f({id: 'component.Survey.Create.Logic.PageRandom.Component.switch.0'})} 
                    labelPlacement="end"
                />
            </ListItem>
            <Divider/>
            <ListItem>
                <FormControlLabel 
                    value="start" 
                    control={<Switch color="primary"  
                    checked={except_last_page_random} 
                    disabled={!page_random}/>} 
                    onChange={on.changeExceptLastPageRandom} 
                    label={f({id: 'component.Survey.Create.Logic.PageRandom.Component.switch.1'})} 
                    labelPlacement="end" 
                    className={classes.formControlLabel}
                />
            </ListItem>
            <Divider/>
        </CreateBox>
    )
}