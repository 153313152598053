import { memo } from 'react'
import ColumnValueComponent from './ColumnValue'
import { Props as ParentProps } from './Body'
import { useStyles as useTextItemStyles } from './TextItem'
import { useStyles as useChoiceItemsStyles } from './ChoiceItems'

interface Props {
    survey_question_no: number;
    answer: ParentProps['answer'];
}

function ChoiceItemsComponent(props: Props) {
    const { survey_question_no, answer } = props

    const classesChoiceItems = useChoiceItemsStyles()
    const classesTextItem = useTextItemStyles({ isRank: true })

    const value = answer[`Q${survey_question_no}`]

    if (!value) return null

    const { choice } = value

    let commentValue = ''
    const isComment = answer[`Q${survey_question_no}-comment`]

    if (isComment) {
        commentValue = isComment.comment[`${survey_question_no}-comment`] || ''
    }
    
    return (
        <>
        <div className={classesTextItem.root}>
            {
                choice.filter(c => c[0] !== '').map((c, i) => {
                    const [ _, text ] = c
                  
                    return <ColumnValueComponent key={`${survey_question_no}-${i}`} text={text} />
                })
            }
        </div>
        {
            commentValue && <div className={classesChoiceItems.commentBox}><div className={classesChoiceItems.comment}><ColumnValueComponent text={commentValue} /></div></div>
        }
        </>
    )
}

export default memo(ChoiceItemsComponent)
