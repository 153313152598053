import { memo, useState } from "react"
import { useIntl } from "react-intl"
import { getExt } from 'utils'
import { getBackgroundFilter } from 'ts-utils/survey'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FilterIcon from '@material-ui/icons/Filter'
import BeforeUnload from 'styled/BeforeUnload'
import { getFilterSignedUrl, uploadFile } from 'component/Survey/Modules/19/S3'
import { filterKinds } from './Kind'
import { getFiltername, getKind, Kind, OnChangeIsFiles } from './Component'

interface Props {
    background_image: string;
    onChange: OnChangeIsFiles;
}

// png 이미지로 만들기
const convertImgToPng = async(img: HTMLElement, filename: string, filterProperty: { filter: string }) => {
    const canvas = document.createElement('canvas')

    canvas.width = img.clientWidth
    canvas.height = img.clientHeight

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')

    if (!ctx) return ''

    ctx.filter = filterProperty.filter
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    //ctx.fillStyle = filterProperty.backgroundColor  
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

    let ext = getExt(filename)

    ext = ext.toLowerCase()

    if (ext === 'jpg') ext = 'jpeg'
    
    const dataUrl = canvas.toDataURL(`image/${ext}`)

    canvas.remove()
    
    const res = await fetch(dataUrl)
    const blob = await res.blob()

    if (!blob) return ''

    const file = new File([blob], filename, {type: blob.type})

    return file
}

const makeImageFilter = async(background_image: string, kind: Kind) => {    
    const len = filterKinds.length

    const img = document.getElementById('smaple-background-image')
    
    if (!img) return []

    const files: File[] = []

    for (let i=0; i<len; i++) {
        const filter = filterKinds[i]
        
        const filterProperty = getBackgroundFilter({ background_filter: filter })
        
        const file = await convertImgToPng(img, getFiltername(background_image, filter, kind), filterProperty)

        if (file) files.push(file)
    }

    return files
}

function FilterComponent(props: Props) {
    const { background_image, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ loading, setLoading ] = useState(false)

    const handleChange = async() => {
        // 필터링 이미지 만들기
        const kind = getKind(background_image)

        if (!kind) return

        setLoading(true)

        const files = await makeImageFilter(background_image, kind)
  
        const filenames = files.map(c => c.name)

        const signedurls = await getFilterSignedUrl({ filenames, kind })

        const len = signedurls.length

        for (let i=0; i<len; i++) {
            const signedurl = signedurls[i]
            const file = files[i]
            await uploadFile(signedurl, file)
        }

        onChange(true)

        setLoading(false)
    }

    return (
        <>
        <Button 
            startIcon={loading ? <CircularProgress size={20} /> : <FilterIcon color="action" fontSize="small" />} 
            variant="contained" 
            disableElevation 
            disabled={loading}
            onClick={handleChange}
        >
            {f({id: 'component.Survey.Create.Design.Custom.Filter.MakeFilterImage.button.make'})}
        </Button>
        {
            loading && <BeforeUnload />
        }
        <img crossOrigin="anonymous" id="smaple-background-image" alt="" src={background_image} style={{position: 'absolute', zIndex: 0, left: -10000, top: -10000}} />
        </>
    )
}

export default memo(FilterComponent)