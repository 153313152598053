import { memo } from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import withStyles from '@material-ui/core/styles/withStyles'
import { SelectedPage, OnChangeSelectedPage } from './Component'
import SelectPageComponent from './SelectPage'
import ButtonFunctionComponent from './ButtonFunction'
import ButtonHelpComponent from './ButtonHelp'
import ButtonSaveComponent from './ButtonSave'

interface Props {
    selectedPage: SelectedPage;
    onChangeSelectedPage: OnChangeSelectedPage;
}

export const MyList = withStyles({
    root: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
        backgroundColor: 'rgba(255, 255, 255, .8)',
        padding: 7
    }
})(List)

interface MyBoxProps extends BoxProps {
    style?: any;
}

export const MyBox = withStyles({
    root: ({ style={} }: MyBoxProps) => {
        return {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '2px 5px',
            justifyContent: 'space-between',
            '& > * + *': {
                marginLeft: 5
            },
            '& > div:first-child': {
                display: 'flex',
                '& > * + *': {
                    marginLeft: 20
                }
            },
            ...style
        }   
    }
})(Box)

function ButtonBoxComponent(props: Props) {
    const { selectedPage, onChangeSelectedPage } = props

    return (
        <MyList dense disablePadding>
            <MyBox>
                <div>
                    <ButtonHelpComponent/>
                    <SelectPageComponent selectedPage={selectedPage} onChange={onChangeSelectedPage}/>
                    <ButtonFunctionComponent/>
                </div>     
                <div>
                    <ButtonSaveComponent selectedPage={selectedPage} onChangeSelectedPage={onChangeSelectedPage}/>
                </div>
            </MyBox>
        </MyList>
    )
}

export default memo(ButtonBoxComponent)