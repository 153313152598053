import { useEffect } from 'react'
import oc from 'open-color-js'
import Wrapper, { scrollerObj } from 'styled/Survey/Left'
import randomstring from 'randomstring'
import { scroller } from 'react-scroll'

// 스크롤러 설정값

const scrolls = {
    id: randomstring.generate(7),
    to: ''
}

export default (props) => {
    const { scrollTarget, page, children } = props

    useEffect(() => {
        setTimeout(() => {
            scroller.scrollTo(scrollTarget, {
                ...scrollerObj.scrollTo,
                offset: -10,
                containerId: scrolls.id
            })
        }, 1)
    }, [scrollTarget, page])

    return (
        <Wrapper scrolls={scrolls} background={oc.gray0} style={{width:100}}>
            {children}
        </Wrapper>
        
    )
}
