import { useState, useCallback, useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CHANGE } from 'reducer/survey/create/logic/menuSelected'
import { withBreakPoints, withSurveyFetchData } from 'hoc'
import DeskTop from './DeskTop'
import Mobile from './Mobile'

function Menu(props) {
    const { selected: oriSelected, breakpoints, propsFetchData } = props
    const { mbox, mboxOption, questionAll, jump, refetchMboxOption, refetchQuestionAll, refetchJump } = propsFetchData

    const dispatch = useDispatch()

    const state = useSelector(states => states.surveyCreateLogicMenuAlarm)

    const { method } = mbox
    const { question_random, page_random } = mboxOption
    
    const { md } = breakpoints

    const [ selected, setSelected ] = useState(oriSelected)

    const handleSelected = useCallback((val) => {
        setSelected(val)
    }, [])

    // 클래식은 페이지 또는 문항이 랜덤을 적용중일경우 점프 사용 못한다
    let jumpDisabled = false
    if (method === 'one') {
        jumpDisabled = question_random.length > 0
    } else {
        jumpDisabled = page_random || question_random.length > 0
    }

    // 점프로직 및 문항에 숨기기가 있을경우 페이지.질문 랜덤 사용몬한다.
    const isQuestionHide = Boolean(questionAll.find(c => c.visible === false))
    
    // 페이지 랜덤이나 문항 랜덤은 점프로직이나 문항 숨기기가 있을걍우 사용못한다.
    let pageRandomDisabled = false
    let questionRandomDisabled = false
    if (jump.length > 0 || isQuestionHide) {
        pageRandomDisabled = true
        questionRandomDisabled = true
    }

    let mySelected = selected
    if (!mySelected) {
        if (mbox.method === 'one') {
            mySelected = 'question_random'
            if (questionRandomDisabled) mySelected = 'jump'
        } else {
            mySelected = 'page_random'
            if (jumpDisabled) mySelected = 'page_random'
            else if (pageRandomDisabled || questionRandomDisabled) mySelected = 'jump'
        }
    }

    useEffect(() => {
        if (state.page_random) {
            refetchMboxOption()
        }
    }, [state.page_random, refetchMboxOption])

    useEffect(() => {
        if (state.question_random) {
            refetchMboxOption()
        }
    }, [state.question_random, refetchMboxOption])

    useEffect(() => {
        if (state.jump) {
            refetchJump()
        }
    }, [state.jump, refetchJump])

    useEffect(() => {
        if (state.questionAll) {
            refetchQuestionAll()
        }
    }, [state.questionAll, refetchQuestionAll])

    useEffect(() => {
        dispatch({ type: CHANGE, selected: mySelected })
    }, [mySelected, dispatch])

    return (
        <>
        {
            md ? (
                <Mobile 
                    method={method}
                    selected={mySelected} 
                    jumpDisabled={jumpDisabled} 
                    pageRandomDisabled={pageRandomDisabled}
                    questionRandomDisabled={questionRandomDisabled}
                    onChange={handleSelected} 
                />
            ) : (
                <DeskTop 
                    method={method}
                    selected={mySelected} 
                    jumpDisabled={jumpDisabled} 
                    pageRandomDisabled={pageRandomDisabled}
                    questionRandomDisabled={questionRandomDisabled}
                    onChange={handleSelected} 
                />
            )
        }
        </>
    )
}

export default withSurveyFetchData('edit')({
    mbox: true,
    mboxOption: true,
    questionAll: true,
    jump: true
})(false)(withBreakPoints(memo(Menu)))