import { ID, Kind, JumpProps, Method } from 'gql/jump'
import oc from 'open-color'

// 고유아이디 Props
interface GetIDProps {
    id: ID;
    kind: Kind;
    mark?: string;
}

// 페이지 또는 rules 링크 오브젝트 Props
interface GetLinkObjProps {
    method: Method;
    source: string;
    endingPoint: boolean;
    survey_question_nos: number[];
}

// data property
export type DataTypes = {
    label: string;
    message: string; 
    img_src: string;
    background?: string;
}

export type NextItemProps = Pick<JumpProps, 'id' | 'kind'>

// 문항 링크 오브젝트 Props
interface GetLinkDefaultObjProps extends GetLinkObjProps {
    nextItem: NextItemProps | undefined;
}

export function getKindColor(kind: Kind, source?: string) {
    // question-1 은 start 이다.
    return kind === Kind.Page ? oc.green[9] : kind === Kind.Question ? source === 'question-1' ? oc.violet[9] : oc.blue[9] : oc.gray[9]
}

// 노드 만들기 클래스
class Node {
    
    private id: ID;
    private kind: Kind;
    private mark?: string;

    set refference(props: GetIDProps) {
        const { id, kind, mark } = props
        this.id = id
        this.kind = kind
        this.mark = mark
    }

    // 고유 아이디값 구하기
    getID(props: GetIDProps) {
        const { id, kind, mark } = props

        let idd = `${kind}-${id}`
        if (mark) idd = `${idd}-${mark}`

        return idd
    }

    // Method 이동이면 Kind Question 완료글이면 End로 해준다
    getMethodKind(method: Method) {
        return method === Method.Move ? Kind.Question : Kind.End
    }

    // 페이지 또는 rules 링크 오브젝트 형식
    getLinkObj(props: GetLinkObjProps) {
        const { method, source, endingPoint, survey_question_nos } = props

        // method 이동이고 도착지점이 있을때만 연결해준다.
        if (!((method === Method.Move || method === Method.End) && survey_question_nos.length > 0)) return null

        // 방법이 완료일경우 Kind를 End로 한다 (완료글 Node의 kind가 End이기 때문이다.)
        const kind = this.getMethodKind(method)

        return this.makeLinkObj({
            source,
            endingPoint,
            editButton: true,
            target: this.getID({ id: survey_question_nos[0], kind })
        })
    }

    // default(else) 링크 오브젝트 형식
    getLinkDefaultObj(props: GetLinkDefaultObjProps) {
        const { method, source, endingPoint, survey_question_nos, nextItem } = props

        // 디폴트값
        // 방법이 이동이나엔드이며 목표점이 있다면 디폴트값은 패스 아니면 디폴트
        if (!((method === Method.Move || method === Method.End) && survey_question_nos.length > 0)) {
            if (!nextItem) return null

            const { id, kind } = nextItem

    
            return this.makeLinkObj({
                source,
                editButton: false,
                target: this.getID({ id, kind })
            })
        }

        return this.makeLinkObj({
            source,
            endingPoint,
            editButton: true,
            target: this.getID({ id: survey_question_nos[0], kind: this.getMethodKind(method) })
        })
    }

    // 랑크 오브젝트 형식
    // soure: 원본노드, target: 타깃노드, modify: editButton 
    makeLinkObj(props: { source: string; target: string; endingPoint?: boolean, editButton: boolean; animated?: boolean }) {
        const { source, target, endingPoint=false, editButton, animated=false } = props

        return {
            id: this.getID({ id: this.id, kind: this.kind, mark: this.mark }),
            source,
            target,
            type: 'floating',
            data: {
                endingPoint
            },
            animated,
            style: { display: editButton ? 'table' : 'none' }
        }
    }

    // 노드 오브젝트형식
    // source가 id
    // visible 숨김, 보이기 둘다 있을경우 mix, 보이기일경우 shows 숨김일경우 hides 아무것도 아닐경우 빈값
    makeNodeObj(props: {source: string, data: DataTypes, visible: string | null}) {
        const { source, data, visible } = props

        // node
        const kind = /^question/.test(source) ? Kind.Question : /^page/.test(source) ? Kind.Page : Kind.End
        const background = visible === 'mix' ? `linear-gradient(to left, ${oc.orange[4]} 50%, ${oc.blue[4]} 50%)` :
                           visible === 'shows' ? oc.blue[4] : 
                           visible === 'hides' ? oc.orange[4] : getKindColor(kind, source)

        return {
            id: source,
            data: { ...data, background },
            position: { x: 0, y: 0 },
            type: 'floating'
        }
    }
    
}

export default Node