import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import { _19, ViewMode, GET_SURVEY_ANALYSIS_SUMMARY_FILE_QUESTION } from 'gql/survey_analysis_summary'
import { getKey } from 'hoc/Survey/Summary'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useStyles } from './QuestionButtons'
import FileUploadDialogComponent from './FileUploadDialog'

interface Props {
    viewMode: ViewMode;
    survey_no: number;
    survey_question_no: number;
    moduleLen: _19['moduleLen'];
    filename: string;
}

function QuestionButtonsComponent(props: Props) {
    const { viewMode, survey_no, survey_question_no, moduleLen, filename } = props
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    const [ modules, setModules ] = useState<_19['modules']>([])

    const [ getFile, { loading } ] = useLazyQuery(GET_SURVEY_ANALYSIS_SUMMARY_FILE_QUESTION, {
        onCompleted: async(data) => {
           
            setModules(data.surveyAnalysisSummaryFileQuestion)
            setOpen(true)
        },
        fetchPolicy: 'network-only'
    })

    const handleOpen = () => {
        getFile({
            variables: {
                survey_no,
                survey_question_no,
                key: getKey(survey_no)
            }
        })
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
        <Button 
            className={classes.button} 
            startIcon={
                loading ? <CircularProgress size={15} /> : <GetAppIcon fontSize="small" className={classes.icon} /> 
            }
            size="small" 
            variant="contained" 
            disableElevation 
            disabled={moduleLen === 0}
            onClick={handleOpen}
        >
            {f({id: `component.Survey.Create.Analysis.Summary.QuestionButtons.button.file`})}
        </Button>
        { viewMode === ViewMode.View && <FileUploadDialogComponent survey_no={survey_no} survey_question_no={survey_question_no} modules={modules} filename={filename} open={open} onClose={handleClose} /> }
        </>
    )
}

export default memo(QuestionButtonsComponent)