import { useContext, useState, useCallback, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE as CHANGE_TEXT } from 'reducer/survey/create/logic/jumpExampleCountText'
import { CHANGE } from 'reducer/survey/create/logic/jumpExampleCount'
import { RootState } from 'reducer'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/styles'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { getDefaultQuestion, getModuleIcon } from 'utils/survey'
import { JumpNo } from 'gql/jump'
import { JumpExampleCountProps, JumpExampleCountTextProps } from 'gql/jump_example_count'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import Tooltip from 'styled/Custom/Tooltip'
import { ExampleCountContext, parseJumpExampleCountTextRow, parseJumpExampleCountRow } from 'container/Survey/Create/Logic/Jump/ExampleCountContext'
import { MyTypography } from '../Lists/ItemHeart'
import QuestionItemsBase from './QuestionItemsBase'
import QuestionItemsImg from './QuestionItemsImg'
import QuestionItemsMatrix from './QuestionItemsMatrix'
import QuestionItemsText from './QuestionItemsText'
import QuestionItemsRank from './QuestionItemsRank'
import QuestionItemsRankImg from './QuestionItemsRankImg'
import ScrollerTargetComponent from '../Point/ScrollerTarget'

interface Props {
    question: SurveyQuestionModuleProps;
}
interface MyButtonProps extends ButtonProps {
    collapse: number;
}

export type OnChange = (a: JumpExampleCountProps) => void
export type OnChangeText = (a: Omit<JumpExampleCountTextProps, 'survey_question_no'>) => void
export type OnCreateText = () => void
export type OnRemoveText = (a: JumpNo ) => void

export  const MyPaper = withStyles({
    root: {
        width: '100%',
        padding: 10,
        paddingTop: 5,
        paddingBottom: 5,
        '& + &': {
            marginTop: 20
        }
    }
})(Paper)

export const MyBox = withStyles({
    root: (props: { display: number }) => {
        const { display } = props

        return {
            display: Boolean(display) ? 'block' : 'none',
            background: oc.gray[4],
            padding: 20,
            marginBottom: 5
        }
    }
})(Box)

export const MyDivder = withStyles({
    root: (props: { display: number }) => {
        const { display } = props

        return {
            display: Boolean(display) ? 'block' : 'none'
        }
    }
})(Divider)

export const MyButton = withStyles({
    root: {
        marginLeft: 10
    }
})((props: MyButtonProps) => {
    const { collapse, ...other } = props
    const { formatMessage: f } = useIntl()

    return (
        <Button {...other}>
            {f({id: `component.Survey.Create.Logic.Jump.ExampleCount.QuestionItemSplit.button.collapse.${collapse ? 'close' : 'open'}`})}
        </Button>
    )
})

export const MyListItem = withStyles({
    root: {
        paddingRight: 0
    }
})(ListItem)

export function getComponentType(type: string) {
    switch (type) {
        case '_01':
        case '_02':
        case '_07':
        case '_14':
        case '_21':
            return 'base'
        case '_03':
        case '_04':
        case '_08':
        case '_15':
            return 'matrix'
        case '_05':
            return 'point'
        case '_06':
            return 'pointMatrix'
        case '_09':
        case '_10':
        case '_20':
            return 'text'
        case '_11':
        case '_17':
            return 'rank'
        case '_12':
        case '_13':
            return 'img'
        case '_18':
            return 'rankImg'
        default:
            return ''
    }
}

// 완료글 선택을 했는지 체크
function isValue(rows:JumpExampleCountProps[]) {
    const len = rows.length
    for (let i=0; i<len; i++) {
        const row = rows[i]
        const { survey_ending_nos } = row
        if (survey_ending_nos.length > 0) return true
    }

    return false
}

function ItemSplitComponent(props: Props) {
    const { question } = props
    const { _question } = question
    const { survey_question_no, number, pure_question, question_type, module_type } = _question

    const dispatch = useDispatch()

    const { jumpExampleCountRowsKeyQuestionNo, jumpExampleCountTextRowsKeyQuestionNo } = useContext(ExampleCountContext)

    // 전체 삭제시, 전체 초기화
    const initState = useSelector((state: RootState) => state.surveyCreateLogicJumpExampleCountInit )

    // 해당문항의 값 
    const [ rows, setRows ] = useState(jumpExampleCountRowsKeyQuestionNo[survey_question_no] || [])

    // 주관식 문항에서 사용된다.
    const [ rowsText, setRowsText ] = useState(jumpExampleCountTextRowsKeyQuestionNo[survey_question_no] || [])

    const [ collapse, setCollapse ] = useState(isValue(rows))

    const { formatMessage: f } = useIntl()

    const componentType = getComponentType(module_type)

    const label = getDefaultQuestion({f, number, question: pure_question, question_type})

    const modules = getModuleIcon(f)
    
    // state 값 변경.
    const handleChange:OnChange = useCallback((row) => {
        setRows(prev => {
            return prev.map(c => {
                return c.jump_example_count_no !== row.jump_example_count_no ? c : row
            })
        })
    }, [])

    const handleChangeText:OnChangeText = useCallback((args) => {
        const { jump_example_count_text_no, value } = args

        setRowsText(prev => {
            return prev.map(c => {
                return c.jump_example_count_text_no !== jump_example_count_text_no ? c : {
                    ...c,
                    value
                }
            })
        })
    }, [])

    // 현재는 주관식에만 사용된다.
    const handleCreateText:OnCreateText = useCallback(() => {
        
        const jumpPointTextRow = parseJumpExampleCountTextRow({ survey_question_no })
        const { jump_example_count_text_no } = jumpPointTextRow

        const jumpPointRow0 = parseJumpExampleCountRow({ survey_question_no, jump_example_count_text_no, survey_ending_nos: [], module_type })
        const jumpPointRow1 = parseJumpExampleCountRow({ survey_question_no, jump_example_count_text_no, survey_ending_nos: [], module_type })
        const jumpPointRow2 = parseJumpExampleCountRow({ survey_question_no, jump_example_count_text_no, survey_ending_nos: [], module_type })
        const jumpPointRow3 = parseJumpExampleCountRow({ survey_question_no, jump_example_count_text_no, survey_ending_nos: [], module_type })

        setRowsText(prev => ([
            ...prev,
            jumpPointTextRow
        ]))

        setRows(prev => ([
            ...prev,
            jumpPointRow0,
            jumpPointRow1,
            jumpPointRow2,
            jumpPointRow3
        ]))
    }, [survey_question_no, module_type])

    // 현재는 주관식에만 사용된다.
    const handleRemoveText:OnRemoveText = useCallback((jump_example_count_text_no) => {
        setRowsText(prev => prev.filter(row => row.jump_example_count_text_no !== jump_example_count_text_no))
        setRows(prev => prev.filter(row => row.jump_example_count_text_no !== jump_example_count_text_no))
    }, [])

     // 에러가 있을경우 펼침
    const handleOpenCollapse = useCallback(() => {
        setCollapse(true)
    }, [])
    
    // 주관식 내용 변경시 발행
    useEffect(() => {
        dispatch({ type: CHANGE_TEXT, O_CHANGE: { survey_question_no, rows: rowsText } })
    }, [rowsText, survey_question_no, dispatch])

    // 포인트 목록 변경시 발행
    useEffect(() => {
        dispatch({ type: CHANGE, O_CHANGE: { survey_question_no, rows } })
    }, [rows, survey_question_no, dispatch])

    // 전체 삭제 시 초기화
    useEffect(() => {
        if (initState.val) {
            setRows(prevState => prevState.map(row => ({...row, survey_ending_nos: []})))
            setRowsText([])
            setCollapse(false)
        }
    }, [initState.val])

    const self = modules.find((c: any) => c.type === module_type)

    if (!self) return null
    
    const Icon = self.icon

    return (
        <MyPaper variant="outlined">
            <MyListItem>
                <ListItemIcon>
                    <Tooltip title={self.name}>
                        <Icon fontSize="small"/>
                    </Tooltip>
                </ListItemIcon>
                <ListItemText>
                    <MyTypography>{label}</MyTypography>
                </ListItemText>
                <ListItemIcon>
                    <MyButton 
                        collapse={collapse ? 1 : 0}
                        variant="contained" 
                        disableElevation size="small" 
                        onClick={() => setCollapse(prevState => !prevState)}
                    />
                </ListItemIcon>
            </MyListItem>
            <ScrollerTargetComponent survey_question_no={survey_question_no} onOpen={handleOpenCollapse}/>
            <MyDivder display={collapse ? 1 : 0}/>
            <MyBox display={collapse ? 1 : 0}>
                {
                    (componentType === 'base' || componentType === 'point') ? (
                        <QuestionItemsBase componentType={componentType} question={question} rows={rows} onChange={handleChange}/> 
                    ) : 
                    (componentType === 'img') ? (
                        <QuestionItemsImg question={question} rows={rows} onChange={handleChange}/>
                    ) : 
                    (componentType === 'matrix' || componentType === 'pointMatrix') ? (
                        <QuestionItemsMatrix componentType={componentType} question={question} rows={rows} onChange={handleChange}/>
                    ) : 
                    (componentType === 'text') ? (
                        <QuestionItemsText 
                            rowsText={rowsText} 
                            rows={rows} 
                            onChange={handleChange} 
                            onChangeText={handleChangeText} 
                            onCreateText={handleCreateText}
                            onRemoveText={handleRemoveText}
                        />
                    ) : 
                    (componentType === 'rank') ? (
                        <QuestionItemsRank question={question} rows={rows} onChange={handleChange}/> 
                    ) : 
                    (componentType === 'rankImg') ? (
                        <QuestionItemsRankImg question={question} rows={rows} onChange={handleChange}/>
                    ) : null
                }
            </MyBox>
        </MyPaper>
    )
}

export default memo(ItemSplitComponent)