import { memo } from 'react'
import { useIntl } from 'react-intl'
import { SurveyMboxCoverProps } from 'gql/survey_mbox_cover'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { withStyles } from '@material-ui/core/styles'
import MessageIcon from '@material-ui/icons/Message'
import { withSurveyFetchData } from 'hoc'
import useTokenInfo from 'hooks/useTokenInfo'
import Tooltip from 'styled/Custom/Tooltip'
import { ListSubheader, ListSubheaderBox, defaultColor } from './Items'
import { MyListItemSecondaryAction, MyListItem } from './EndingsItem'
import BrandUsedComponent from './BrandUsed'
import { Levels } from 'ts-policy'

interface Props {
    is_headerbar: boolean;
    onChangeCategory: (a:string) => void;
    propsFetchData: {
        mboxCover: SurveyMboxCoverProps;
        refetchMboxCover: () => void;
    }
}

interface MySvgIconProps extends SvgIconProps {
    _is: number;
}

const MyMessageIcon = withStyles(theme => ({
    root: ({ _is=0 }: MySvgIconProps) => {
        return {
            display: 'block',
            color: _is ? defaultColor.iconColorSelected : defaultColor.iconColor,
            width: 17,
            height: 17
        }
    }
}))((props: MySvgIconProps) => (
    <MessageIcon {...props} fontSize="small"/>
))

function BrandComponent(props: Props) {
    const { formatMessage: f } = useIntl()

    const { is_headerbar, onChangeCategory, propsFetchData } = props
    const { mboxCover, refetchMboxCover } = propsFetchData
    const { src, animation, used } = mboxCover

    const { levels } = useTokenInfo()

    // 무료사용자는 on, off 못하지만 편집창을 열을수 있어야해서 무조건 true로 처리한다.
    const newUsed = levels === Levels.Level0 ? true : used

    let imgTag = ''
    if (src && used) imgTag = `
        <div style="width: 150px; height: 150px; display: flex; align-items: center; justify-content: center; overflow: hidden;">
            <img src=${src} class="animate__animated animate__infinite animate__slow ${animation}" style="max-height: 50px; max-width: 80%; border-radius: 3px; alt=""/>
        </div>
        `

    return (
        <List 
            dense 
            subheader={
                <ListSubheader is_headerbar={is_headerbar}>
                    <ListSubheaderBox>
                        <div>{f({id: 'component.Survey.Create.Menu.Brand.label'})}</div>
                        <div>
                            <BrandUsedComponent levels={levels} used={used} refetch={refetchMboxCover}/>
                        </div>
                    </ListSubheaderBox>
                 
                </ListSubheader>
            }
        >
            <MyListItem button disabled={!newUsed} onClick={() => onChangeCategory('brand')}>
                <ListItemText primary={f({id: 'component.Survey.Create.Menu.Brand.label'})}/>
                <Tooltip title={imgTag}>
                    <MyListItemSecondaryAction>
                        <MyMessageIcon _is={(used && src) ? 1 : 0}/>
                    </MyListItemSecondaryAction>
                </Tooltip>
            </MyListItem>
        </List>
    )
}

const fetchDataArgs = {
    mboxCover: true
}

export default withSurveyFetchData('edit')(fetchDataArgs)(false)(memo(BrandComponent))