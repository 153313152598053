/**
 * 썸네일 설명
 */

import { memo} from "react"
import { useIntl } from "react-intl"
import oc from "open-color"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
    onClose: () => void;
}

const useStyles = makeStyles(theme => ({
    img: {
        display: 'block',
        borderRadius: 5,
        maxWidth: 300,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%'
        }
    }
}))

function ThumbnailExplainComponent(props: Props) {
    const { onClose } = props

    const classes = useStyles() 

    const { formatMessage: f } = useIntl()

    return(
        <>
        <DialogContent>
            <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <img 
                    src={`${process.env.REACT_APP_IMG}/survey-web/component/Survey/Create/Share/thumbnail.png`} 
                    alt="thumbnail" 
                    className={classes.img}
                />
                <Typography variant="caption" color="textSecondary">
                    {f({id: "component.Survey.Create.Share.ThumbnailExplainPopup.kakaotalk"})}
                </Typography>
            </Box>
            <DialogContentText style={{marginTop: 10, color: oc.gray[7], textDecoration: 'underline'}}>
                {f({id: "component.Survey.Create.Share.ThumbnailExplainPopup.explain"})}
            </DialogContentText>
        </DialogContent>

        <DialogActions>
            <Button onClick={onClose}>
                {f({id: "component.Survey.Create.Share.ThumbnailExplainPopup.button.close"})}
            </Button>
        </DialogActions>
        </>
    )
}

export default memo(ThumbnailExplainComponent)