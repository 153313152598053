import { memo, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'

interface Props {
    children: ReactNode
}

function RemoveButtonBoxComponent(props: Props) {
    const { children } = props

    const state = useSelector((states: RootState) => states.analysisRowsPersonal)
    const { answer } = state

    if (answer) return null
    
    return (
        <>
        {children}
        </>
    )
}

export default memo(RemoveButtonBoxComponent)