import  { useContext } from 'react'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import PropsContext from 'context/props'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import OptionStyled from 'styled/Survey/Edit/Option'
import BrandingLinkComponent from './Ending/BrandingLink'
import BrandingOptionComponent from './Ending/BrandingOption'

function OptionComponent(props) {
    const { rows, onOpenBranding, onChange } = useContext(PropsContext)
    const { sns, listovey_logo, branding, branding_src, branding_size, branding_link } = rows

    const { formatMessage: f } = useIntl()

    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox name="sns" color="primary" checked={sns} onChange={(e) => onChange('sns', e.target.checked)}/>
                    } label={f({id:'component.Survey.Create.Thanks.Edit.OptionEnding.sns'})}/>
                </div>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox name="branding" color="primary" disabled={listovey_logo} checked={branding} onChange={(e) => onChange('branding', e.target.checked)}/>
                    } label={parseHtmlIntl(`${f({id:'component.Survey.Create.Thanks.Edit.OptionEnding.branding'})} ${f({id:'common.survey.plan.pay'})}`)}/>
                </div>
            </div>
            <div className={`sub last ${(branding && !listovey_logo) ? 'open branding' : ''}`}>
                <div style={{width: '100%'}}>
                    <BrandingOptionComponent branding_src={branding_src} branding_size={branding_size} onOpenBranding={onOpenBranding} onChange={onChange}/>
                    <BrandingLinkComponent  branding_src={branding_src} branding_link={branding_link} onChange={onChange}/>
                </div>

            </div>
        </OptionStyled>
    )
}

export default OptionComponent