import { memo, MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import CropIcon from '@material-ui/icons/Crop'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import CropSquareIcon from '@material-ui/icons/CropSquare'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    background_size: string;
    onChange: (e: MouseEvent<HTMLElement>, newValue: string | null) => void
}

function BackgroundCustomComponent(props: Props) {
    const { background_size, onChange } = props

    const { formatMessage: f } = useIntl()

    return (
        <ToggleButtonGroup exclusive size="small" value={background_size} style={{background: 'white'}} onChange={onChange}>
            <Tooltip title={f({id: 'component.Survey.Create.Design.Custom.Option.layout_type.2'})}>
                <ToggleButton value='cover'>
                    <CropIcon color={background_size === 'cover' ? 'primary' : 'action'}/>
                </ToggleButton>
            </Tooltip>
            <Tooltip title={f({id: 'component.Survey.Create.Design.Custom.Option.layout_type.3'})}>
                <ToggleButton value='100% 100%'>
                    <SettingsOverscanIcon color={background_size === '100% 100%' ? 'primary' : 'action'}/>
                </ToggleButton>
            </Tooltip>
            <Tooltip title={f({id: 'component.Survey.Create.Design.Custom.Option.layout_type.1'})}>
                <ToggleButton value='contain'>
                    <CropSquareIcon color={background_size === 'contain' ? 'primary' : 'action'}/>
                </ToggleButton>
            </Tooltip>
            <Tooltip title={f({id: 'component.Survey.Create.Design.Custom.Option.layout_type.0'})}>
                <ToggleButton value='auto'>
                    <ViewModuleIcon color={background_size === 'auto' ? 'primary' : 'action'}/>
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    )
}

export default memo(BackgroundCustomComponent)