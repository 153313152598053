import { useState, useEffect } from 'react'
import { IconButton }from '@material-ui/core'
import { Save as SaveIcon, Close as CloseIcon, Replay as ReplayIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import Tabs from 'styled/Survey/Edit/Tabs'
import Tooltip from 'styled/Custom/Tooltip'

let timer = null

function TabsComponent(props) {
    const { on } = props
    const { formatMessage: f } = useIntl()

    const [ disabled, setDisabled ] = useState(false)

    const handleSave = () => {
        on.save()
        setDisabled(true)
    }

    useEffect(() => {
        if (disabled) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                setDisabled(false)
            }, 2000)
        }
    }, [disabled])

    return (
        <Tabs position="static" borderRadius={1}>
            {
                disabled ? (
                    <IconButton disabled={true} color="primary" onClick={handleSave}>
                        <SaveIcon fontSize='small'/>
                    </IconButton>
                ) : (
                    <Tooltip title={disabled ? "" : f({id: 'component.Survey.Edit.Tabs.save'})} placement="top">
                        <span>
                            <IconButton disabled={disabled} color="primary" onClick={handleSave}>
                                <SaveIcon fontSize='small'/>
                            </IconButton>
                        </span>
                    </Tooltip>
                )
            }
            <Tooltip title={f({id: 'component.Survey.Edit.Tabs.init'})} placement="top">
                <IconButton onClick={on.init}>
                    <ReplayIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
            <Tooltip title={f({id: 'component.Survey.Edit.Tabs.close'})} placement="top">
                <IconButton onClick={on.close}>
                    <CloseIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
        </Tabs>
    )
}

export default TabsComponent