import { memo, useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { DELETE_SUVEY_ENDING } from 'gql/survey_ending'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { OnClose } from './EndingsMenu'

interface Props {
    survey_no: number;
    survey_ending_no: number;
    onClose: OnClose;
    refetch: () => void;
}

function EndingRemoveComponent(props: Props) {
    const { survey_no, survey_ending_no, refetch, onClose } = props
    
    const dispatch = useDispatch()
    const { formatMessage: f } = useIntl()

    const [ confirm, setConfirm ] = useState(false)

    const [ deleteSurveyEnding ] = useMutation(DELETE_SUVEY_ENDING, {
        onCompleted: () => {
            setTimeout(() => {
                dispatch({ type: BACKDROPHIDE })
                refetch()
                onClose()
            }, 100)
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleConfirmOpen = () => {
        setConfirm(true)
    }

    const handleConfirmClose = () => {
        setConfirm(false)
    }

    const handleRemove = () => {
        dispatch({ type: BACKDROPSHOW })
        deleteSurveyEnding({
            variables: {
                survey_no,
                survey_ending_no
            }
        })
    }

    useEffect(() => {
        setConfirm(false)
    }, [survey_ending_no])

    return (
        <>
        {
            confirm ? (
                <MenuItem>
                   <ButtonGroup size='small' fullWidth disableElevation>
                        <Button color='secondary' onClick={handleRemove}>
                            {f({id: 'component.Survey.Create.Menu.EndingsRemove.remove'})}
                        </Button>
                        <Button onClick={handleConfirmClose}>
                            {f({id: 'component.Survey.Create.Menu.EndingsRemove.close'})}
                        </Button>
                    </ButtonGroup>
                </MenuItem>
            ) : (
                <MenuItem onClick={handleConfirmOpen}>
                    <Typography variant="body2" color="secondary">
                        {f({id: 'component.Survey.Create.Menu.EndingsMenu.remove'})}
                    </Typography>
                </MenuItem>
            )
        }
        </>
    )
}

export default memo(EndingRemoveComponent)