import { useContext } from 'react'
import PropsContext from 'context/props'
import Tab from 'styled/Survey/Edit/Tab'

// tabIndex 해당 컨첸츠 인덱스
// index 현재 선택된 인덱스
export default (props) => {
    const { index, children } = props
    const { tabIndex } = useContext(PropsContext)
    
    return (
        <Tab tabIndex={tabIndex} index={index}>
            {children}
        </Tab>
    )
}