/**
 * 백그라운드 이미지 필터 효과 설정
 */
import { memo, useState, MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    background_filter: string;
    breakpoints: BreakPointsProps;
    onChange: (val: string) => void;
}

export const filterKinds = [ 'brightness', 'blur', 'saturate' ]

function BackgroundFilterComponent(props: Props) {
    const { background_filter, breakpoints, onChange } = props

    const key = background_filter

    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChange = (val: string) => {
        onChange(val)
        handleClose()
    }
    
    return (
        <>
        {
            xs ? (
            <>
            {
                /** 모바일에서는 화면이 안나와서 셀렉트로 처리 */
            }
            <Button size="small" variant="contained" disableElevation endIcon={<ArrowDropDownIcon fontSize="small" />} onClick={handleOpen}>
                {f({id: `component.Survey.Create.Design.Custom.BackgroundFilter.items.${!key ? 'none' : key}`})}
            </Button>
            <Menu
                id="background-filter"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem selected={!key} onClick={() => handleChange('')}>{f({id: 'component.Survey.Create.Design.Custom.BackgroundFilter.items.none'})}</MenuItem>
                {
                    filterKinds.map(c => <MenuItem key={c} selected={key === c} onClick={() => handleChange(c)}>{f({id: `component.Survey.Create.Design.Custom.BackgroundFilter.items.${c}`})}</MenuItem>)
                }
            </Menu>
            </>
            ) : (
                <ButtonGroup variant="contained" disableElevation size="small">
                    <Button color={!key ? "primary" : "default"} onClick={() => onChange('')}>{f({id: 'component.Survey.Create.Design.Custom.BackgroundFilter.items.none'})}</Button>
                    {
                        filterKinds.map(c => <Button key={c} color={key === c ? "primary" : "default"} onClick={() => onChange(c)}>{f({id: `component.Survey.Create.Design.Custom.BackgroundFilter.items.${c}`})}</Button>)
                    }
                </ButtonGroup>
            )
        }
        </>
    )
}

export default withBreakPoints(memo(BackgroundFilterComponent))