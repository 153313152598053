import styled, { css } from 'styled-components'
import { useTheme } from '@material-ui/styles'
import oc from 'open-color-js'

const WrapperStyeld = styled.div`
    display: grid;

    width: 100%;

    & > div.Pillar:nth-child(1) {
        grid-area: content-1;
    }

    & > div.Pillar:nth-child(2) {
        grid-area: content-2;
    }

    & > div.Pillar:nth-child(3) {
        grid-area: content-3;
    }

    & .CreateBox {
        width: 100%;
        height: calc(100vh - 64px);
        background-color: ${oc.gray2};
        overflow-y: auto;
        padding: 15px;
        position: relative;
    }

    & .CreateBox.dense {
        padding: 0;
    }

    & .CreateBox.jumpList {
        background-color: ${oc.gray2};
        padding: 0;
        padding-bottom: 50px;
    }

    & .CreateBox.jumpPoint {
        background-color: ${oc.gray3};
        padding: 0;
        padding-bottom: 50px;
    }

    // 설명문
    & .CreateBox.explain {
        background-color: white;
        padding: 25px;
    }

    // Design category
    & .CreateBox.design {
        height: calc(100vh - 122px);
    }

    @media only screen and (max-width: 1280px) {
        & .CreateBox {
            height: calc(100vh - 124px);
        }

        & .CreateBox.design {
            height: calc(100vh - 181px);
        }
    }

    @media only screen and (max-width: 600px) {
        & .CreateBox {
            padding: 10px;
            height: calc(100vh - 12.3em);
        }

        & .CreateBox.design {
            height: calc(100vh - 230px);
        }
    }

    
    ${props => {
        const { category, layout } = props

        return category === 'module' ? (
            layout === 'lists' ? css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 21em 1fr;
            ` :
            layout === 'edit' ? css `
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 1fr 1.38fr;
            ` :
            layout === 'pageLists' ? css `
                grid-template-areas: "content-1 content-2 content-3";
                grid-template-columns: 21em 13em 1fr;
            ` : 
            layout === 'pageMove' && css `
                grid-template-areas: "content-1 content-2 content-3";
                grid-template-columns: 21em 32em 1fr;
            ` 
        ) 
        : 
        (category === 'thanks-start' || category === 'thanks-end' || category === 'brand') ? css`
            grid-template-areas: "content-1 content-2";
            grid-template-columns: minmax(48em, 50em) 1fr;
        `
        : 
        category === 'design' ? (
            css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 40em 1fr;
            `
        )
        :
        category === 'logic' ? (
            layout === 'page_random' ? css`
                grid-template-areas: "content-1 content-2 content-3";
                grid-template-columns: 18em 30em 1fr;
                & .Left {
                    background-color: ${oc.gray3};
                }
            ` :
            layout === 'question_random' ? css`
                grid-template-areas: "content-1 content-2 content-3";
                grid-template-columns: 18em 52em 1fr;
                & .Left {
                    background-color: ${oc.gray3};
                }
            ` : css`
                transition: all .3s;
                grid-template-areas: "content-1 content-2 content-3";
                grid-template-columns: 13em minmax(600px, 800px) 1fr;
                & .Left {
                    background-color: ${oc.gray3};
                }
                & > div.Pillar:nth-child(2), & > div.Pillar:nth-child(3) {
                    border-left: 1px solid ${oc.gray4};
                }
            `
        )
        :
        category === 'method' ? css`
            grid-template-areas: "content-1 content-2";
            grid-template-columns: 40em 1fr;
        `
        :
        category === 'setting' ? css`
            grid-template-areas: "content-1 content-2";
            grid-template-columns: 35em 1fr;
        `
        :
        category === 'publish' && css`
            grid-template-areas: "content-1 content-2";
            grid-template-columns: 45em 1fr;
            & > div.Pillar:nth-child(1) {
                border-right: 1px solid ${oc.gray3};
            }
    
        `
    }}

    @media only screen and (max-width: 1280px) {
        ${props => {
            const { category, layout } = props

            return category === 'module' ? (
                layout === 'lists' ? css`
                    grid-template-areas: "content-1 content-2";
                    grid-template-columns: 21em 1fr;
                ` :
                layout === 'edit' ? css `
                    grid-template-areas: "content-1";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(2) {
                        display: none;
                    }

                    // 에디트창 border-right 삭제
                    & .Left {
                        border-right: none;
                    }
                    
                    // 우측하단 viewmode Dial 안보이게 처리
                    .MethodDial {
                        display: none;
                    }
                ` :
                layout === 'pageLists' ? css `
                    grid-template-areas: "content-1 content-3";
                    grid-template-columns: 21em 1fr;

                    & > div.Pillar:nth-child(2) {
                        display: none;
                    }
                ` : 
                layout === 'pageMove' && css `
                    grid-template-areas: "content-1 content-3";
                    grid-template-columns: 21em 1fr;

                    & > div.Pillar:nth-child(2) {
                        display: none;
                    }
                ` 
            ) 
            : 
            (category === 'thanks-start' || category === 'thanks-end' || category === 'brand') ? css`
                grid-template-areas: "content-1";
                grid-template-columns: 1fr;

                & > div.Pillar:nth-child(2) {
                    display: none;
                }
            `
            : 
            category === 'design' ? (
                css`
                    grid-template-areas: "content-1 content-2";
                    grid-template-columns: 1fr 1fr;

                `
            )
            :
            category === 'logic' ? (
                layout === 'page_random' ? css`
                    grid-template-areas: "content-2 content-3";
                    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                    & > div.Pillar:nth-child(1) {
                        display: none;
                    }
                ` :
                layout === 'question_random' ? css`
                    grid-template-areas: "content-2 content-3";
                    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                    & > div.Pillar:nth-child(1) {
                        display: none;
                    }
                ` : css`
                    grid-template-areas: "content-2 content-3";
                    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                    & > div.Pillar:nth-child(1) {
                        display: none;
                    }
                `
            )
            :
            category === 'method' ? css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 1fr 2fr;
            `
            :
            category === 'setting' ? css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 1fr 2fr;
            `
            :
            category === 'publish' && css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 1fr 1.2fr;

        
            `
        }}
      
    }
    
    @media only screen and (max-width: 960px) {
        ${props => {
            const { category, layout } = props

            return category === 'module' ? (
                layout === 'lists' ? css`
                    grid-template-areas: "content-2";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(1) {
                        display: none;
                    }
                ` :
                layout === 'edit' ? css `
                    grid-template-areas: "content-1";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(2) {
                        display: none;
                    }

                    // 에디트창 border-right 삭제
                    & .Left {
                        border-right: none;
                    }
                    
                    // 우측하단 viewmode Dial 안보이게 처리
                    .MethodDial {
                        display: none;
                    }
                ` :
                layout === 'pageLists' ? css `
                    grid-template-areas: "content-3";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(1), & > div.Pillar:nth-child(2) {
                        display: none;
                    }
                ` : 
                layout === 'pageMove' && css `
                    grid-template-areas: "content-3";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(1), & > div.Pillar:nth-child(2) {
                        display: none;
                    }
                ` 
            ) 
            : 
            (category === 'thanks-start' || category === 'thanks-end' || category === 'brand') ? css`
                grid-template-areas: "content-1";
                grid-template-columns: 1fr;
                & > div.Pillar:nth-child(2) {
                    display: none;
                }
            `
            : 
            category === 'design' ? (
                css`
                    grid-template-areas: "content-1";
                    grid-template-columns: 1fr;

                    & > div.Pillar:nth-child(2) {
                        display: none;
                    }
                `
            )
            :
            category === 'logic' ? (
                layout === 'page_random' ? css`
                    grid-template-areas: "content-2 content-3";
                    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                    & > div.Pillar:nth-child(1) {
                        display: none;
                    }
                ` :
                layout === 'question_random' ? css`
                    grid-template-areas: "content-2 content-3";
                    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                    & > div.Pillar:nth-child(1) {
                        display: none;
                    }
                ` : css`
                grid-template-areas: "content-2 content-3";
                    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                    & > div.Pillar:nth-child(1) {
                        display: none;
                    }
                `
            )
            :
            category === 'method' ? css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 1fr 2fr;
            `
            :
            category === 'setting' ? css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 1fr 2fr;
            `
            :
            category === 'publish' && css`
                grid-template-areas: "content-1 content-2";
                grid-template-columns: 1.2fr 1fr;
            `
        }} 
    }


    @media only screen and (max-width: 600px) {
        ${props => {
            const { category, layout } = props

            return category === 'logic' ? (
                layout === 'page_random' ? css`
                    grid-template-areas: "content-2";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(1), & > div.Pillar:nth-child(3) {
                        display: none;
                    }
                ` :
                layout === 'question_random' ? css`
                    grid-template-areas: "content-2";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(1), & > div.Pillar:nth-child(3) {
                        display: none;
                    }
                ` : css`
                    grid-template-areas: "content-2";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(1), & > div.Pillar:nth-child(3) {
                        display: none;
                    }
                `
            ) : category === 'method' ? css`
                    grid-template-areas: "content-1";
                    grid-template-columns: 1fr;
                    & > div.Pillar:nth-child(2) {
                        display: none;
                    }
            ` : category === 'setting' ? css`
                grid-template-areas: "content-1";
                grid-template-columns: 1fr;
                & > div.Pillar:nth-child(2) {
                    display: none;
                }
            ` : category === 'publish' ? css`
                grid-template-areas: "content-1";
                grid-template-columns: 1fr;
                & > div.Pillar:nth-child(2) {
                    display: none;
                }
            ` : ''
        }} 
    }

`
const Component = (props) => {
    const { category='', layout='', children } = props

    const theme = useTheme()

    return (
        <WrapperStyeld category={category} layout={layout} theme={theme}>
            {children}
        </WrapperStyeld>
    )
}

export default Component