import { useContext, useCallback, useState, memo, MouseEvent } from "react"
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { SurveyStartProps } from "gql/survey_start"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ExpandMore from '@material-ui/icons/ExpandMore'
import { parseHtmlIntl } from "ts-utils"
import { ID } from 'gql/jump'
import { SurveyPageProps } from "gql/survey_page"
import { MyTypography } from './ItemHeart'
import { MyMenuItem } from './SelectModule'
import { MyMenu, MyPaper, MyList, MyTitle, MyCloseIcon, Refference } from './SelectActionQuestion'
import { Context, SurveyQuestionModulePropsInKey } from 'container/Survey/Create/Logic/Jump/Context'

interface Props {
    id: ID;
    pages: SurveyPageProps[];
    questionsKeyQuestionNo: SurveyQuestionModulePropsInKey;
    questionsKeyPageNo: SurveyQuestionModulePropsInKey;
    refference: Refference;
    surveyQuestionNos: number[];
    onChange: (a: number[]) => void;
}

type OnSelected = (a: ID) => void

function StartItem(props: { used: SurveyStartProps['used'], onSelected: OnSelected}) {
    const { used, onSelected } = props
    const { formatMessage: f } = useIntl()

    if (used) {
        return (
            <MyMenuItem 
                style={{fontWeight: 'bold'}}
                value={1} 
                onClick={() => onSelected(1)}
            >
                {f({id: 'component.Survey.Create.Menu.Component.subheader.thanks.start'})}
            </MyMenuItem>
        )
    }

    return null
}

function SelectActionPageComponent(props: Props) {
    const { id, pages, questionsKeyQuestionNo, questionsKeyPageNo, refference, surveyQuestionNos, onChange } = props
    const { start } = useContext(Context)
    const { used } = start

    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)

    const handleOpenAnchorEl = useCallback((e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const handleCloseAnchorEl = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleSelectedNull = useCallback((e) => {
        e.stopPropagation()
        
        onChange([])
    }, [onChange])

    const handleSelected:OnSelected = useCallback((id) => {
        
        onChange([ id ])
        handleCloseAnchorEl()
    }, [ onChange, handleCloseAnchorEl])

    const currentValue = questionsKeyQuestionNo ? questionsKeyQuestionNo[id] : null

    const currentPage = currentValue ? currentValue[0]._question.survey_page_no : 0

    const self = pages.find(c => c.survey_page_no === surveyQuestionNos[0]) || null

    // Refference.Rule 이면서 값이 없다면 error true이단. else는 값이 없어도 된다.
    let error = (refference === Refference.Rule && (!self && surveyQuestionNos[0] !== 1)) ? 1 : 0

    const pageLists = pages.filter(c => c.survey_page_no !== currentPage)

    if (!questionsKeyPageNo) return null

    // 시작글도 없고 섹션도 없다면 모...
    if (!used && pageLists.length === 0) {
        return (
            <MyPaper variant="outlined" error={error}>
                <List dense>
                    <ListItem>
                        <MyTitle selected={false} primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectActionPage.none'})}/>
                    </ListItem>
                </List>
            </MyPaper>
        )
    }

    const selected = Boolean(self) || surveyQuestionNos[0] === 1

    return (
        <MyPaper variant="outlined" error={error}>
            <MyList style={{ flexGrow: 1 }}>
                <ListItem button onClick={handleOpenAnchorEl}>
                    <MyTitle 
                        selected={selected} 
                        primary={
                            self ? `Section ${self.indexs}` : 
                            surveyQuestionNos[0] === 1 ? f({id: 'component.Survey.Create.Menu.Component.subheader.thanks.start'}) :
                            f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectActionPage.primary'})
                        }
                    />
                    {
                        // else일때만 X버튼 준다
                        (refference === Refference.Else && surveyQuestionNos.length > 0) && (
                            <MyCloseIcon onClick={handleSelectedNull}/>
                        )
                    }
                    <ExpandMore/>
                </ListItem>
            </MyList>
            <MyMenu 
                anchorEl={anchorEl} 
                open={Boolean(anchorEl)}
                onClose={handleCloseAnchorEl}
            >
                {
                    StartItem({used, onSelected: handleSelected})
                }
                {
                    pageLists.map(page => {
                        const { survey_page_no, indexs } = page

                        const caution = !questionsKeyPageNo[survey_page_no] ? `<span style="color:${oc.red[9]}; font-size:13px;">${f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectActionPage.caution'})}</span>` : ''

                        return (
                            <MyMenuItem key={survey_page_no} value={survey_page_no} onClick={() => handleSelected(survey_page_no)}>
                                <MyTypography>{parseHtmlIntl(`Section ${indexs} ${caution}`)}</MyTypography>
                            </MyMenuItem>
                        )
                    })
                }
            </MyMenu>
        </MyPaper>

    )
}

export default memo(SelectActionPageComponent)