import { useState, ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'
import { replaceNotNumber } from 'utils'
import { getEndingMssage } from 'ts-utils/survey'
import { SurveyEndingProps } from 'gql/survey_ending'
import { CHANGE } from 'reducer/survey/create/logic/jumpEnding'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import { MyTypography } from '../Point/Item'
import { isOverSize, limit_start_point, limit_end_point } from './ButtonSave'
import { isError } from '../Point/ButtonSave'

interface Props {
    row: SurveyEndingProps
}

const MyPaper = withStyles({
    root: {
        padding: 10,
        '& + &': {
            marginTop: 20
        }
    }
})(Paper)

const MyTextBox = withStyles({
    root: {
       display: 'flex',
       alignItems: 'center'
    }
})(Box)

let timer: ReturnType<typeof setTimeout>

function ItemComponent(props: Props) {
    const { row } = props
    const { survey_ending_no, start_point, end_point, indexs,kind, pure_message, redirect_url } = row

    const msg = getEndingMssage(kind, pure_message, redirect_url)

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ value, setValue ] = useState({ start_point, end_point })

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value: newNalue } = e.target
        const number = replaceNotNumber(newNalue, true)

        setValue(prev => ({
            ...prev,
            [name]: number
        }))
    }

    useEffect(() => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            dispatch({ type: CHANGE, O_CHANGE: {
                survey_ending_no,
                start_point: value.start_point,
                end_point: value.end_point
            }})
        }, 150)
    }, [survey_ending_no, value.start_point, value.end_point, dispatch])


    // 빈값 -, 최대값 넘으면 error
    let start_error = isError(limit_start_point, limit_end_point, value.start_point)
    let end_error = isError(limit_start_point, limit_end_point, value.end_point)
    
    // 시작번호가 끝번호보다 크다면 에러
    if (isOverSize(value.start_point, value.end_point)) {
        start_error = true
        end_error = true
    }

    return (
        <MyPaper variant="outlined">
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={8} md={9}>
                    <MyTypography>{`${indexs}. ${msg}`}</MyTypography>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MyTextBox>
                        <TextField 
                            label={f({id: 'component.Survey.Create.Logic.Jump.Ending.Item.label'})}
                            type={isMobile ? 'number' : 'text'}
                            size="small" 
                            name="start_point" 
                            value={value.start_point} 
                            variant="filled" 
                            style={{width: '100%'}}
                            error={start_error}
                            onChange={handleChange}
                        /> 
                        <div style={{margin: '0 8px'}}>to</div>
                        <TextField 
                            label={f({id: 'component.Survey.Create.Logic.Jump.Ending.Item.label'})}
                            type={isMobile ? 'number' : 'text'}
                            size="small" 
                            name="end_point" 
                            value={value.end_point} 
                            variant="filled" 
                            style={{width: '100%'}}
                            error={end_error}
                            onChange={handleChange}
                        />
                    </MyTextBox>
                </Grid>
            </Grid>
        </MyPaper>
    )
}

export default ItemComponent