import { useState, useCallback, memo, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import MessageInputComponent from './MessageInput'
import { Box, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { UPDATE_SURVEY_MBOX_MESSAGE } from 'gql/survey_mbox_message'

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > div:first-child': {
            flexGrow: 1
        },
        '& > div:last-child': {
            flexGrow: 0
        },
        '& > div + div': {
            marginLeft: 15
        }
    }
})(Box)

function MessageComponent(props) {
    const { survey_no, lang, type, value, initial } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ originalValue, setOriginalValue ] = useState(value)
    const [ newValue, setNewValue ] = useState(value)

    const [ updateSurveyMboxMessage ] = useMutation(UPDATE_SURVEY_MBOX_MESSAGE, {
        onCompleted: () => {
            dispatch({ type: BACKDROPHIDE })
            setOriginalValue(newValue)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })


    const handleChange = useCallback((e) => {
        setNewValue(e.target.value)
    }, [])


    const handleSave = useCallback(async () => {
        await dispatch({ type: BACKDROPSHOW })
        updateSurveyMboxMessage({
            variables: {
                survey_no,
                type,
                value: newValue
            }
        })
    }, [survey_no, type, newValue, dispatch, updateSurveyMboxMessage])

    useEffect(() => {
        // 비활성화 할때는 초기값으로 변경해준다
        if (initial) setNewValue(originalValue)
    }, [initial, originalValue])
    return (
        <MyBox>
            <div>
                <MessageInputComponent lang={lang} type={type} newValue={newValue} onChange={handleChange}/>
            </div>
            <div>
                <Button 
                    variant="contained" 
                    disabled={originalValue === newValue}
                    onClick={handleSave}
                    size="small"
                >
                    {f({id: 'component.Survey.Create.Publish.Message.save'})}
                </Button>
            </div>
        </MyBox>
        
    )
}

export default memo(MessageComponent)