import { useState, useCallback } from 'react'
import { VerticalSplit as VerticalSplitIcon, ChromeReaderMode as ChromeReaderModeIcon } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import oc from 'open-color-js'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
    icon: {
        color: oc.indigo7,
        [theme.breakpoints.down('xs')]: {
            width: 20,
            height: 20
        }
    }
}))

function ShapeInfoComponent(props) {
    const { method, img_position, img_src } = props

    const classes = useStyles()

    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    if (method !== 'one' || img_position === 'stack' || !img_src) return null

    return (
        <>
        <IconButton onClick={handleOpen} size="small">
            {
                img_position === 'float'  ? <VerticalSplitIcon className={classes.icon}/> : <ChromeReaderModeIcon className={classes.icon}/>
            }
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="body2">
                    {f({id: `component.Survey.Create.Module.ShapeInfo.title.${img_position}`})}
                </Typography>
            </DialogTitle>
            <DialogContent style={{background: oc.gray2, paddingTop: 20, paddingBottom: 20}}>
                <img 
                    src={`${process.env.REACT_APP_IMG}/survey-web/component/Survey/Create/Module/ShapeInfo/example-${img_position}.png`} 
                    style={{maxWidth: '100%', maxHeight: 250}}
                    alt="preview"
                />
            </DialogContent>
        </Dialog> 
        </>
    )
}

export default ShapeInfoComponent 