import { useIntl } from 'react-intl'
import { Button, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AppsIcon from '@material-ui/icons/Apps'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    tab: string;
    breakpoints: BreakPointsProps;
    onChange: (val: string) => void;
}

const MyButton = withStyles({
    root: {
        '@media (max-width: 350px)': {
            display: 'none'
        }
    }
})(Button)

function AppButtonComponent(props: Props) {
    const { tab, breakpoints, onChange } = props

    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const disabled = tab === 'integration'

    return (
        <>
        {
            xs ? (
                <IconButton size="small" disabled={disabled} onClick={() => onChange('integration')}>
                    <AppsIcon fontSize='small' color='primary'/>
                </IconButton>
            ) : (
                <MyButton 
                    variant="contained"
                    disabled={disabled}
                    size="small" 
                    startIcon={<AppsIcon color='primary'/>}
                    onClick={() => onChange('integration')}
                >
                    {f({id: 'component.Survey.Create.Analysis.AppButton.button.name'})}
                </MyButton>
            )
        }
        </>
        
    )
}

export default withBreakPoints(AppButtonComponent)