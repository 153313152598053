import { List } from '@material-ui/core'
import { MyCodeItem } from 'styled/Survey/Share/Embed'
import { useIntl } from 'react-intl'

export default (props) => {
    const { on } = props

    const { formatMessage: f } = useIntl()

    return (
        <List>
            <MyCodeItem onClick={on.openCode}>
                {f({id: 'component.Survey.Create.Share.Popover.lists.3'})}
            </MyCodeItem>
        </List>
    )
}