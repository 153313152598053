import styled from 'styled-components'
import oc from 'open-color-js'

const WrapperStyeld = styled.div`
    
    display: grid;

    & > div.OuterPillar:nth-child(1) {
        grid-area: left;
    }

    & > div.OuterPillar:nth-child(2) {
        grid-area: body;
    }

    grid-template-areas: "left body";
    grid-template-columns: 4em 1fr;
    grid-template-rows: 1fr;
    width: 100%;
  

    @media only screen and (max-width: 1280px) {
        grid-template-areas: "left""body";
        grid-template-columns: 1fr;
        grid-template-rows: 4.2em 1fr;

        & > div.OuterPillar:nth-child(1) {
            background-color: ${oc.gray0};
            border-bottom: 1px solid ${oc.gray3};
            padding: 0 10px;

            & .Left {
                border-right: none;
            }
        }
        height: calc(100vh - 64px);
        overflow-y: hidden;
    }

    @media only screen and (max-width: 600px) {
        height: calc(100vh - 114px);
    }
`

export default (props) => {
    const { children } = props

    return (
        <WrapperStyeld>
            {children}
        </WrapperStyeld>
    )
}