import { memo } from 'react'
import { SurveyQuestionModuleProps, ModuleBase } from 'gql/survey_question_modules'
import { JumpExampleCountProps } from 'gql/jump_example_count'
import { OnChange } from './QuestionItemSplit'
import ItemComponent from "./Item"

export interface Props {
    componentType: string;
    question: SurveyQuestionModuleProps;
    rows: JumpExampleCountProps[];
    onChange: OnChange;
}

function QuestionItemsBase(props: Props) {
    const { componentType, question, rows, onChange } = props
    const { _modules } = question

    return (
        <>
        {
            _modules.map((_module: ModuleBase) => {
                const { survey_module_no, answer, indexs } = _module
                const title = componentType === 'point' ? indexs : `${indexs}. ${answer}`

                // 기존값 체크
                const row = rows.find(row => row.survey_module_no === survey_module_no)

                if (!row) return null

                return (
                    <ItemComponent key={survey_module_no} answer={title} row={row} onChange={onChange}/>
                )
            })
        }
        </>
        
    )
}

export default memo(QuestionItemsBase)