import { memo, useState, useEffect, ChangeEvent } from "react"
import { useIntl } from "react-intl"
import oc from 'open-color'
import { getMaxlength } from 'policy'
import { SurveyMboxArgsProps } from "gql/survey_mbox_args"
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from "@material-ui/core/styles"
import { OnChange } from './CustomArgs'

interface Props {
    originArgs: SurveyMboxArgsProps['custom_args'];
    args: SurveyMboxArgsProps['custom_args'];
    error: boolean;
    isDo: boolean;
    onChange: OnChange;
}

const Input = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${oc.gray[7]}`,
        borderRadius: 0,
        paddingLeft: 1,
        paddingRight: 1,
        fontSize: 14,
    },
    error: {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    input: {

        paddingBottom: 2
    } 
}))(InputBase)



let timer: ReturnType<typeof setTimeout> 

function CustomArgsInputComponent(props: Props) {
    const { originArgs, args, error, isDo, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ newArgs, setNewArgs ] = useState(args)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target

        const newValue = String(value).replaceAll(/[`~@#$%^&*|+=?;:'"<>\(\)\{\}\[\]\\\/ ]/gim, '').trim()
        setNewArgs(newValue)

        clearTimeout(timer)

        setTimeout(() => {
            onChange(newValue)
        }, 300)
    }

    // 좌측 마우스 클릭시 공백 지운다
    const handleClick = () => {
        setNewArgs(newArgs ? newArgs.trim() : '')
    }

    // 우측 마우스 클릭시에는 이모지 입력이고 한간읠 띄어야 마지막 문자를 안지운다.
    const handleRightClick = () => {
        setNewArgs(newArgs ? `${newArgs} ` : '')
    }

    // 삭제일때는 input value 와 상관없이 빈갑이 오는데, 다시 셋팅을 해줘야 빈값이 인식된다.
    useEffect(() => {
        if (!originArgs) setNewArgs('')
    }, [originArgs])

    // disabled 처리
    const styles = !isDo ? { borderBottom: `1px solid ${oc.gray[4]}` } : { }

    return (
        <Input 
            placeholder={f({id: 'component.Survey.Create.Share.CustomArgsInput.placeholder'})}
            fullWidth 
            margin="dense" 
            value={newArgs}
            error={error}
            disabled={!isDo}
            style={styles}
            inputProps={{ maxLength: getMaxlength('component.Survey.Create.Share.CustomArgs.args') }}
            onChange={handleChange}
            onClick={handleClick}
            onContextMenu={handleRightClick}
        />
    )
}

export default memo(CustomArgsInputComponent)