import { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { numberFormat } from 'utils'
import { HelpButton } from '../Lists/ButtonHelp'
import DialogHelpComponent from '../Point/DialogHelp'
import { limit_start_point, limit_end_point } from './ButtonSave'

function HelpComponent() {
    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const title = f({id: 'component.Survey.Create.Logic.Jump.Ending.Help.title'})

    const items = [

        { primary: f({id: 'component.Survey.Create.Logic.Jump.Ending.Help.item.primary.0'}), secondary: ''},
        { primary: f({id: 'component.Survey.Create.Logic.Jump.Ending.Help.item.primary.1'}), secondary: ''},
        { primary: f({id: 'component.Survey.Create.Logic.Jump.Ending.Help.item.primary.2'}), secondary: f({id: 'component.Survey.Create.Logic.Jump.Ending.Help.item.secondary.2'})},
        { primary: f({id: 'component.Survey.Create.Logic.Jump.Ending.Help.item.primary.3'}, { min: numberFormat(limit_start_point), max: numberFormat(limit_end_point)}), secondary: ''},
        { primary: f({id: 'component.Survey.Create.Logic.Jump.Ending.Help.item.primary.4'}), secondary: ''}
    ]

    return (
        <>
        <HelpButton onClick={handleOpen}/>
        <DialogHelpComponent title={title} items={items} open={open} onClose={handleClose}/>
        </>
    )
}

export default HelpComponent