import { useState, memo } from 'react'
import { useIntl } from 'react-intl'
import { CSVLink } from 'react-csv'
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone'
import Tooltip from 'styled/Custom/Tooltip'
import { MyIconButton } from './CompleteIntervalLists'

interface Props {
    data: object;
    type: 'city' | 'country' | 'domain'
}

function RefererCityDownloadComponent(props: Props) {
    const { data, type } = props

    const [ rows, setRows ] = useState<any[]>([])

    const { formatMessage: f } = useIntl()

    function download() {
        const entries = [
            [
                f({id: `component.Survey.Create.Analysis.Insight.RefererCityDownload.column.type.${type}`}), 
                f({id: `component.Survey.Create.Analysis.Insight.RefererCityDownload.column.connect`})
            ], 
            ...Object.entries(data)
        ]
        setRows(entries)
    }

    return (

        <CSVLink 
            data={rows} 
            filename={`${type.charAt(0).toUpperCase()}${type.slice(1)}ConnectCount.csv`} 
            style={{textDecoration: 'none'}}
            asyncOnClick={true}
            onClick={download}
        >
             <MyIconButton disabled={Object.keys(data).length === 0} color="primary">
                <Tooltip 
                    title={f({id: 'component.Survey.Create.Analysis.Insight.RefererCityDownload.tooltip'})} 
                    placement="top-end" 
                    isMobileClick={true}
                    delayCloseTime={1500}
                >
                    <GetAppTwoToneIcon/>
                </Tooltip>
            </MyIconButton>
                
        </CSVLink> 
    )
}

export default memo(RefererCityDownloadComponent)