import { memo, MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW } from 'reducer/survey/create/menu/ending/menu'
import Box from '@material-ui/core/Box'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { withStyles } from '@material-ui/core/styles'
import { defaultColor } from './Items'

interface Props {
    survey_ending_no: number;
    label: string;
}

export type OnClose = () => void

const MyBox = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: defaultColor.backgroundColor,
        width: 25,
        height: 25,
        borderRadius: 3,
        marginLeft: 5,
        transition: 'all .3s',
        cursor: 'pointer',
        '&:hover': {
            background: defaultColor.backgroundColorHover
        }
    }
}))(Box)

function EndingsMoreComponent(props: Props) {
    const { survey_ending_no, label } = props

    const dispatch = useDispatch()

    function Open(e: MouseEvent<HTMLDivElement>) {
        dispatch({ type: SHOW, survey_ending_no, label, anchorEl: e.currentTarget, isRemove: true })
    }

    return (
        <MyBox onClick={Open}>
            <MoreVertIcon color='action' fontSize='small' style={{ display: 'block' }}/>
        </MyBox>
    )
   
}

export default memo(EndingsMoreComponent)