// 완료글 복사
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { CREATE_SURVEY_ENDING_COPY, CREATE_SURVEY_ENDING_COPY_APPEND  } from 'gql/survey_ending'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { OnClose } from './EndingsMenu'

interface Props {
    survey_no: number;
    survey_ending_no: number;
    survey_ending_nos: number[];
    onClose: OnClose;
    refetch: () => void;
}

function EndingsCopyComponent(props: Props) {
    const { survey_no, survey_ending_no, survey_ending_nos, onClose, refetch } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ create ] = useMutation(CREATE_SURVEY_ENDING_COPY, {
        onCompleted: () => {
            setTimeout(() => {
                dispatch({ type: BACKDROPHIDE })
                refetch()
                onClose()
            }, 100)
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ append ] = useMutation(CREATE_SURVEY_ENDING_COPY_APPEND, {
        onCompleted: () => {
            setTimeout(() => {
                dispatch({ type: BACKDROPHIDE })
                refetch()
                onClose()
            }, 100)
        },
        onError: (e) => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const handleCreate = () => {
        dispatch({ type: BACKDROPSHOW })
        create({
            variables: {
                survey_no,
                survey_ending_no
            }
        })
    }

    const handleAppend = () => {
        dispatch({ type: BACKDROPSHOW })

        const indexs = survey_ending_nos.reduce((acc: number[], c: number) => {
            // 해당 완료글 바로 뒤에 들어가는 부분은 0으로 처리
            acc.push(Number(c))
            if (c === survey_ending_no) acc.push(0)
        
            return acc
        }, [])

        append({
            variables: {
                survey_no,
                survey_ending_no,
                indexs
            }
        })
    }

    return (
        <>
        <MenuItem onClick={handleAppend}>
            <Typography variant="body2">
                {f({id: 'component.Survey.Create.Menu.EndingsCopy.item.0'})}
            </Typography>
        </MenuItem>
        <MenuItem onClick={handleCreate}>
            <Typography variant="body2">
                {f({id: 'component.Survey.Create.Menu.EndingsCopy.item.1'})}
            </Typography>
        </MenuItem>
        </>
    )
}

export default EndingsCopyComponent