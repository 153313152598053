import { memo, useState, useEffect, MouseEvent, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import { GET_JUMP_POINT_AND_EXAMPLE_COUNT_EXIST } from 'gql/jump_point'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import QuizComponent from './Quiz'
import QuizWarningComponent from './QuizWarning'
import QuizKindRemoveComponent from './QuizKindRemove'
import { defaultColor } from './Items'

export enum Kind {
    EampleCount = "exampleCount",
    Point = "point"
}

export type OnOpenKind = (a: Kind) => void;
export type OnCloseKind = () => void;

export type OnCloseWaring = () => void;

export const QuizButton = withStyles(theme => ({
    root: {
        color: defaultColor.color,
        background: defaultColor.backgroundColor,
        '&:hover': {
            background: defaultColor.backgroundColorHover,
        }
    }
}))(Button)

function QuizKindSelectComponent() {
    const { formatMessage: f } = useIntl()

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const [getData, { data, refetch, loading }] = useLazyQuery(GET_JUMP_POINT_AND_EXAMPLE_COUNT_EXIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const { point, exampleCount } = data ? data.jumpPointAndExampleCountExist : { point: false, exampleCount: false }

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)

    const [ kind, setKind ] = useState<null | Kind>(null)

    const [ warning, setWarning ] = useState<null | Kind>(null)

    function handlePopoverOpen(event: MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget)
    }
    
    const handlePopoverClose:OnCloseKind = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleOpenKind:OnOpenKind = useCallback(selected => {
        setKind(selected)
    }, [])

    const handleOpenKindAndWaring:OnOpenKind = useCallback(selected => {
        handlePopoverClose()
        if ((selected === Kind.EampleCount && point) || (selected === Kind.Point && exampleCount)) {
            setWarning(selected)
            return
        }
        setKind(selected)
    }, [point, exampleCount, handlePopoverClose])

    const handleCloseKind = useCallback(() => {
        setKind(null)
        if (refetch) refetch()
    }, [refetch])

    const handleCloseWaring:OnCloseWaring = useCallback(() => {
        setWarning(null)
        if (refetch) refetch()
    }, [refetch])

    const popoverOpen = Boolean(anchorEl)

    useEffect(() => {
        getData({ variables: { survey_no } })
    }, [survey_no, getData])

    return (
        <>
        <QuizButton variant="contained" disableElevation size="small" style={{marginLeft: 3}} onClick={handlePopoverOpen}>
            {
                exampleCount ? f({id: 'component.Survey.Create.Menu.Quiz.radio.0'}) : 
                point ? f({id: 'component.Survey.Create.Menu.Quiz.radio.1'}) : 
                f({id: 'component.Survey.Create.Menu.Endings.subheader.button.edit'})
            }
        </QuizButton>
        <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={popoverOpen}
            onClose={handlePopoverClose}
        >
            <MenuItem button disabled={loading} selected={exampleCount} onClick={() => handleOpenKindAndWaring(Kind.EampleCount)}>
                <ListItemText primary={f({id: 'component.Survey.Create.Menu.Quiz.radio.0'})} />
                <ListItemText style={{paddingLeft: 10}}>
                    <QuizKindRemoveComponent 
                        survey_no={survey_no} 
                        kind={Kind.EampleCount} 
                        value={exampleCount} 
                        open={Boolean(anchorEl)} 
                        refetch={refetch}
                        onCloseKind={handlePopoverClose}
                    />
                </ListItemText>
            </MenuItem>
            <Divider light/>
            <MenuItem button disabled={loading} selected={point} onClick={() => handleOpenKindAndWaring(Kind.Point)}>
                <ListItemText primary={f({id: 'component.Survey.Create.Menu.Quiz.radio.1'})}/>
                <ListItemText style={{paddingLeft: 10}}>
                    <QuizKindRemoveComponent 
                        survey_no={survey_no} 
                        kind={Kind.Point} 
                        value={point} 
                        open={Boolean(anchorEl)} 
                        refetch={refetch}
                        onCloseKind={handlePopoverClose}
                    />
                </ListItemText>
            </MenuItem>
        </Menu>
        <QuizComponent kind={kind} onClose={handleCloseKind}/>
        <QuizWarningComponent waring={warning} onOpenKind={handleOpenKind} onClose={handleCloseWaring} />
        </>
    )
}

export default memo(QuizKindSelectComponent)