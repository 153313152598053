/**
 * 셀렉트 박스 아이템
 */

import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { getModuleIcon } from 'utils/survey'
import { parseHtmlIntl } from 'ts-utils'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { defaultImage } from 'styled/Survey/Img'

interface Props {
    primary?: string | number;
    secondary?: string;
    typename?: string;
    selectBoxOpen?: boolean;
    indexs: number;
    is_comment?: boolean;
    src?: string;
    error?: boolean;
    onChange: any;
}

const commentStyle: any = {
    fontSize: 7,
    fontWeight: 900,
    position: 'absolute', 
    left: -5, 
    bottom: -9,
    color: oc.gray[7],
    borderRadius: 5,
    padding: 0
}

export const errorBorderStyle: any = {
    border: `1px solid ${oc.red[3]}`
}

function Component(props: Props) {
    const { primary, secondary, typename, selectBoxOpen, indexs, is_comment, src, error, onChange } = props

    const { formatMessage: f } = useIntl()

    const icons = getModuleIcon(f)

    const icon = icons.find(c => c.type === typename)

    const Icon = icon ? icon.icon : null

    const borderStyle = error ? errorBorderStyle : {}

    return (
        <Paper variant="outlined" style={{ marginTop: indexs === 0 ? 0 : 5, position: 'relative', ...borderStyle  }}>
            
            <MenuItem dense button onClick={onChange}>
                <ListItemIcon style={{ minWidth: Icon ? 55 : 3, position: 'relative' }}>
                    { is_comment ? <Typography variant="caption" style={commentStyle}>COMMENT</Typography> : null }
                    { Icon ? <Icon fontSize="small" /> : null }
                </ListItemIcon>
                {
                    typeof src !== 'undefined' && (
                        <ListItemIcon style={{ minWidth: 33 }}>
                            <img src={src || defaultImage} alt="listovey" style={{ width: 28, height: 28, borderRadius: 3 }} />
                        </ListItemIcon>
                    )
                }
                <ListItemText 
                    primary={primary === undefined ? undefined : (primary === 0 ? 0 : primary || parseHtmlIntl('&nbsp;'))}
                    secondary={secondary || undefined}
                    primaryTypographyProps={{ 
                        style: { 
                            display: 'block',
                            overflow: 'hidden',   
                            textOverflow: 'ellipsis',  
                            whiteSpace: 'nowrap'
                        } 
                    }} 
                    secondaryTypographyProps={{ 
                        style: { 
                            display: 'block',
                            overflow: 'hidden',   
                            textOverflow: 'ellipsis',  
                            whiteSpace: 'nowrap'
                        } 
                    }} 
                />
                {
                    selectBoxOpen === true ? <ExpandLess color="action" /> : selectBoxOpen === false ? <ExpandMore name="expand" color="action" /> : null
                }
            </MenuItem>
        </Paper>
    )
}

export default memo(Component)