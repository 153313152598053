import { memo, useCallback, ChangeEvent } from 'react'
import { JumpPointProps } from 'gql/jump_point'
import { replaceNotNumber } from 'utils'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles'
import withSurveyPopoverImage, { OnOpen, OnClose } from 'hoc/Survey/PopoverImage'
import { OnChange } from './QuestionItemSplit';
import InputItemComponent from './InputItem'

interface Props {
    popoverImageProps: {
        on: {
            open: OnOpen,
            close: OnClose
        }
    },
    answer: string;
    row: JumpPointProps;
    onChange: OnChange;
    src?: string;
}

export type OnChangePoint = (e: ChangeEvent<HTMLInputElement>) => void

export const MyPaper = withStyles({
    root: {
        padding: '2px 15px 8px 15px',
        '& + &': {
            marginTop: 7
        }
    }
})(Paper)

export const MyAvatar = withStyles({
    root: {
        width: 30,
        height: 30
    }
})(Avatar)

export const MyBox = withStyles({
    root: (props: { lastwidth?: number}) => {
        const { lastwidth=60 } = props

        return {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            '& > div + div': {
                marginLeft: 15
            },
            '& > div:first-child': {
                width: `calc(100% - ${lastwidth + 5}px)`,
                display: 'flex',
                alignItems: 'center',
                '& > * + *': {
                    marginLeft: 10
                }
            },
            '& > div:last-child': {
                width: lastwidth
            }
        }
    }
})(Box)

export const MyTypography = withStyles({
    root: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden',
        fontSize: 14
    }
})(Typography)

function Item(props: Props) {
    const { answer, row, src, onChange, popoverImageProps } = props

    const { on } = popoverImageProps
    const onOpen = on.open

    const isSrc = src !== undefined

    const handleChangePoint: OnChangePoint = useCallback(e => {
        const { value } = e.target

        const point = replaceNotNumber(value, true)

        onChange({
            ...row,
            point
        })
    }, [row, onChange])

    return (
        <MyPaper variant="outlined">
            <MyBox>
                <div style={{paddingTop: isSrc ? 5 : 0}}>
                    {
                        isSrc && (
                            <MyAvatar 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onOpen(src)
                                }}
                                src={src}
                            />
                        )
                    }    
                    <MyTypography>{answer}</MyTypography>
                </div> 
                <div>
                    <InputItemComponent point={row.point} onChange={handleChangePoint}/>
                </div>
            </MyBox>
        </MyPaper>
    )
}

export default withSurveyPopoverImage(memo(Item))