import DeskTop from './DeskTop'
import Mobile from './Mobile'
import { withBreakPoints } from 'hoc'

function PreviewComponent(props) {    
    const { breakpoints, explainType, onClose } = props

    const { xs } = breakpoints
    
    return (
        <>
        {
            xs ? (
                <Mobile open={Boolean(explainType)} onClose={onClose}>
                    <DeskTop explainType={explainType}/>
                </Mobile>
            ) : (
                <DeskTop explainType={explainType}/>
            )
        }
        </>
    )
}

export default withBreakPoints(PreviewComponent)