import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { parseHtmlIntl, delay, fileSize } from 'ts-utils'
import { Levels } from 'ts-policy'
import moment from 'moment'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import oc from 'open-color'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { 
    GET_SUMMARY_FILE_DOWNLOAD_LOG, 
    CREATE_SUMMARY_FILE_DOWNLOAD_LOG,
    SummaryFileDownloadLogProps, 
    SummaryFileDownloadLogVariables, 
    CreateSummaryFileDownloadLogVariables 
} from 'gql/summary_file_download_log'
import { _19 } from 'gql/survey_analysis_summary'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FolderIcon from '@material-ui/icons/Folder'
import { withStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Grid, { GridProps } from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { Props as ParentProps  } from './DataItemFileUpload'
import Dialog from 'styled/Survey/Live/Dialog'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import useFileUploadSize from 'hooks/useFileUploadSize'
import useTokenInfo, { Token } from 'hooks/useTokenInfo'
import { MyTip } from '../Rows/Raw'
import FileUploadSizeOverComponent from './FileUploadSizeOver'

interface Props extends ParentProps {
    survey_no: number;
    survey_question_no: number; 
    modules: _19['modules']; 
    filename: string; 
    open: boolean; 
    onClose: () => void;
    breakpoints: BreakPointsProps;
}

const MyListItemIcon = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))(ListItemIcon)

const MyListItemText = withStyles(theme => ({
    primary: {
        fontSize: 16
    }
}))(ListItemText)

const MyGridItem = withStyles(theme => ({
    root: {
        color: theme.palette.action.active,
        fontSize: 14  
    }
}))((props: GridProps) => <Grid item {...props}/>)

export interface FiledownloadProps {
    dispatch: any;
    f: any;
    token: Token;
    title?: string;
}

const limitFreeFiledownload = 1048576 * 15

export class Filedownload {
    dispatch: any
    f: any
    token: Token
    filedownloadDate: string
    now: string
    limitFreeFiledownload: number
    limitTotal: number
    title: string
    fileQuestionTitle: { [key: number]: string }

    constructor(props: FiledownloadProps) {
        const { dispatch, f, token, title } = props
        this.dispatch = dispatch
        this.f = f
        this.token = token
        this.title = title || ''

        this.filedownloadDate = token.filedownload_date || '1998-11-17'
        this.now = moment().format('YYYY-MM-DD')
        this.limitFreeFiledownload = limitFreeFiledownload // 무료 플랜에서는 최대 15MB 다운로드 할 수 있다.

        this.limitTotal = 524288000
    }

    // 무료이면서, 파일다운로드 제한 날짜보다 현재가 크다면 15mb로 제한
    verifyLimitFreeFiledownload(total: number) {
        // 무료 이면서, 파일다운로드
        if (this.token.levels === Levels.Level0) {
            if (this.filedownloadDate < this.now) {
                if (total > this.limitFreeFiledownload) {
                    return false
                }
            } 
        }

        return true
    }

    async doFetch(src: string) {
        const blob = await fetch(`${process.env.REACT_APP_LISTO_CF}${src}`).then(response => {
            if (response.status !== 200) return null
            return response.blob()
        })

        return blob
    }

    async do(filename: string, data: Props['modules'], start:number, tag=0) {
        const zip = new JSZip()
    
        const len = data.length
    
        let total = 0
        const zipname = tag === 0 ? `${filename}.zip` : `${filename}-${tag}.zip`
        for (let i=start; i<len; i++) {
            const { survey_analysis_users_no, src, filename, filesize } = data[i]

            if (src) {
                this.dispatch({
                    type: BACKDROPSHOW,
                    msg: this.f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.update.message'}, {index: (i+1), len})
                })
                
                await delay(10)
    
                const name = `${survey_analysis_users_no}-${filename}`
    
                const blob = await this.doFetch(src)
    
                if (blob) {
                    // create a new file from the blob object
                    const file = new File([blob], name)
                        
                    zip.file(name, file, { base64: true })
                }
            }
          
    
            total += (filesize || 0)

            // 무료 이면서, 파일다운로드
            if (!this.verifyLimitFreeFiledownload(total)) {
                const content = await zip.generateAsync({ type: 'blob' })
                FileSaver.saveAs(content, zipname)

                this.dispatch({ type: BACKDROPHIDE })

                break        
            }
    
            // 500MB보다 클경우 파일 분리
            if (total > this.limitTotal || i === (len - 1)) {
                const content = await zip.generateAsync({ type: 'blob' })
                FileSaver.saveAs(content, zipname)
                
                if (i === (len - 1)) this.dispatch({ type: BACKDROPHIDE })
                else this.do(filename, data, i+1, tag+1)

                break
            }
        }
    }
}

export function getLimit(modules: Props['modules']) {
    const size = modules.reduce((acc, c) => acc + c.filesize, 0)
    
    if (size >= 1048576 * 1000) return 10
    else if (size >= 1048576 * 500) return 20
    else return 30
}

export function Explain(props: { type: string }) {
    const { type } = props
    const { formatMessage: f } = useIntl()

    const token = useTokenInfo()

    const filedownloadDate = token.filedownload_date || '1998-11-11'
    const now = moment().format('YYYY-MM-DD')

    return (
        <>
        <List disablePadding dense>
            <ListItem>
                <MyListItemIcon>
                    <FolderIcon/>
                </MyListItemIcon>
                <MyListItemText 
                    primary={f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.filename.primary'})}
                    secondary={parseHtmlIntl(f({id: `component.Survey.Create.Analysis.Summary.FileUploadDialog.filename.secondary.${type}`}))}
                />
            </ListItem>
            <ListItem>
                <MyListItemIcon>
                    <FolderIcon/>
                </MyListItemIcon>
                <ListItemText>
                    <Typography>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.limit.primary'})}</Typography>
                    <Grid container style={{marginTop: 5}}>
                        <MyGridItem xs={6} sm={3} md={3}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.limit.secondary.0'})}</MyGridItem>
                        <MyGridItem xs={6} sm={9} md={9}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.limit.third.0'})}</MyGridItem>
                    </Grid>

                    <Grid container>
                        <MyGridItem xs={6} sm={3} md={3}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.limit.secondary.1'})}</MyGridItem>
                        <MyGridItem xs={6} sm={9} md={9}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.limit.third.1'})}</MyGridItem>
                    </Grid>

                    <Grid container>
                        <MyGridItem xs={6} sm={3} md={3}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.limit.secondary.2'})}</MyGridItem>
                        <MyGridItem xs={6} sm={9} md={9}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.limit.third.2'})}</MyGridItem>
                    </Grid>
                    </ListItemText>
            </ListItem>
            <ListItem>
                <MyListItemIcon>
                    <FolderIcon/>
                </MyListItemIcon>
                <ListItemText
                    primary={f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.time.primary'})}
                    secondary={f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.time.secondary.0'})}
                />
            </ListItem>
            {
                token.levels === Levels.Level0 && (
                    <ListItem>
                        <MyListItemIcon>
                            <FolderIcon style={{color: oc.blue[9]}}/>
                        </MyListItemIcon>
                        <ListItemText>
                            <Typography style={{color: oc.blue[9]}}>
                                {now > filedownloadDate ? (
                                    parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.filedownloadDate.0'}, {size: fileSize(limitFreeFiledownload)}))
                                ) : (
                                    parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.filedownloadDate.1'}, {date: filedownloadDate, size: fileSize(limitFreeFiledownload)})) 
                                )}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                )
            }
        </List>
        <div style={{padding: '40px 0 20px 0'}}>
            <MyTip>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.DataDownloadDialog.tip'}))}</MyTip>
        </div>

        </>
    )
}

function FileUploadDialogComponent(props: Props) {
    const { survey_no, survey_question_no, filename, modules, open, onClose, breakpoints } = props
    const { xs } = breakpoints

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [{ limit: limitFileUploadSize, used: usedFileUploadSize, isOver }] = useFileUploadSize()

    const token = useTokenInfo()

    const [ count, setCount ] = useState(10)

    const [ getData ] = useLazyQuery<{summaryFileDownloadLog: SummaryFileDownloadLogProps[]}, SummaryFileDownloadLogVariables>(GET_SUMMARY_FILE_DOWNLOAD_LOG, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const now = moment().format('YYYY.MM.DD')
            const rows = data.summaryFileDownloadLog
            const len = rows.filter((row) => {
                const { wdate } = row
                const time = moment(Number(wdate)).format('YYYY.MM.DD')

                return now === time
            }).length
            setCount(len)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    const [ updateData ] = useMutation<CreateSummaryFileDownloadLogVariables>(CREATE_SUMMARY_FILE_DOWNLOAD_LOG, {
        onCompleted: async() => {
            setCount(prevCount => prevCount + 1)
            const filedownload = new Filedownload({dispatch, f, token})
            filedownload.do(filename, modules, 0, 0)
          
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function updateCount() {
        updateData({
            variables: { survey_no, survey_question_no }
        })
    }

    useEffect(() => {
        if (open) getData({ variables: { survey_no, survey_question_no } })
    }, [open, survey_no, survey_question_no, getData]) 

    const limit = getLimit(modules)

    return (
        <Dialog open={open} fullScreen={xs} fullWidth={true} onClose={onClose}>
            <DialogTitle>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.title'})}</DialogTitle>
            <DialogContent>
                {isOver ? <FileUploadSizeOverComponent limit={limitFileUploadSize} used={usedFileUploadSize} onClose={onClose}/> : <Explain type="summary"/>}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={updateCount} disabled={count >= limit || modules.length === 0 || isOver}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.button.download'}, {count, limit})}</Button>
                <Button onClick={onClose}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.button.close'})}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withBreakPoints(FileUploadDialogComponent)