import { Element } from 'react-scroll'
import ButtonBoxComponent from './ButtonBox'
import ItemsComponent from './Items'
import { ComponentBox, MyBox, style, scrollerObj } from '../Point/Component'

function Component() {
    return (
        <ComponentBox>
            <Element id={scrollerObj.id} style={style}>
                <MyBox>
                    <ItemsComponent/>
                </MyBox>
            </Element>
            <ButtonBoxComponent/>
        </ComponentBox>
    )
}

export default Component