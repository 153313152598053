import { useContext } from 'react'
import { useIntl } from 'react-intl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { OnChangeSelectedPage, SelectedPage } from './Component'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'

interface Props {
    selectedPage: SelectedPage;
    onChange: OnChangeSelectedPage;
}

const MySelect = withStyles({
    root: {
        minWidth: 80,
        paddingLeft: 10
    }
})(Select)

function SelectPageComponent(props: Props) {
    const { selectedPage, onChange } = props

    const { formatMessage: f } = useIntl()

    const { pages } = useContext(Context)

    if (pages.length === 1) return null

    return (
        <MySelect displayEmpty value={selectedPage} onChange={(e) => onChange(e.target.value as SelectedPage)}>
            <MenuItem value="">{f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectPage.primary'})}</MenuItem>
            {
                pages.map(page => <MenuItem key={page.survey_page_no} value={page.survey_page_no}>{`Section ${page.indexs}`}</MenuItem>)
            }
        </MySelect>
    )
}

export default SelectPageComponent