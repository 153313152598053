import { useContext } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { useIntl } from 'react-intl'
import OptionStyled from 'styled/Survey/Edit/Option'
import PropsContext from 'context/props'

export default () => {
    const { rows, on } = useContext(PropsContext)
    const { used } = rows
    const { formatMessage: f } = useIntl()

    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={used} name="used" color="primary" onChange={on.changeUsed}/>
                    } label={f({id: 'component.Survey.Create.Thanks.Edit.OptionStart.used'})}/>
                </div>
                <div></div>
            </div>
            <div className='line'></div>
        </OptionStyled>
    )
}