import { useCallback } from 'react'
import ItemsComponent from './Items'
import DeskTopComponent from './DeskTop'
import MobileComponent from './Mobile'
import { withBreakPoints } from 'hoc' 

const MenuComponent = (props) => {

    // isHeaderBar: isHeaderBar 온거라먄 true
    const { 
        method, 
        isHeaderBar, 
        pageMenu, 
        pages, 
        page, 
        onCreateModules,
        onCreatePage,
        onOpenPageLists, 
        onOpenPageMove,
        onChangeCategory, 
        refetchQuestions,
        refetchPages,
        breakpoints 
    } = props

    const { md, sm } = breakpoints

    const selectedPageLists = pageMenu === 'lists'
    const selectedPageMove = pageMenu === 'move'

    const totalPage = pages.length

    // 현제 페이지 번호
    const { indexs: pageIndex } = pages.find(c => c.survey_page_no === page) || { indexs: 1 }

    const firstPageNo = pages[0].survey_page_no

    const MyItems = useCallback(() => {
        return (
            <ItemsComponent
                method={method}
                isHeaderBar={isHeaderBar}
                selectedPageLists={selectedPageLists}
                selectedPageMove={selectedPageMove}
                totalPage={totalPage}
                pageIndex={pageIndex}
                survey_page_no={page}
                firstPageNo={firstPageNo}
                onCreateModules={onCreateModules}
                onCreatePage={onCreatePage}
                onOpenPageLists={onOpenPageLists} 
                onOpenPageMove={onOpenPageMove} 
                onChangeCategory={onChangeCategory}
                refetchQuestions={refetchQuestions}
                refetchPages={refetchPages}
            />
        )
            
    }, [
        method, 
        isHeaderBar, 
        selectedPageLists, 
        selectedPageMove, 
        pageIndex,
        page,
        totalPage,
        firstPageNo,
        onCreateModules,
        onCreatePage,
        onOpenPageLists, 
        onOpenPageMove,
        onChangeCategory,
        refetchQuestions,
        refetchPages,
    ])


    return (
        <>
        {
            isHeaderBar && sm ? (
                <MobileComponent>
                    <MyItems/>   
                </MobileComponent>
            ) : !isHeaderBar && (
                <DeskTopComponent md={md}>
                    <MyItems/>
                </DeskTopComponent>
            )
        }
        </>
    )
}

export default withBreakPoints(MenuComponent)


