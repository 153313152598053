import { memo } from 'react'
import ResponseComponent from 'styled/Survey/Analysis/SummaryCommentResponseCount'

interface Props {
    index: number;
    answer?: string;
    src?: string;
    commentLen?: number;
    isChild?: boolean;
}

function DataItemCommentHeaderComponent(props: Props) {
    const { index, answer, src, commentLen, isChild } = props

    const marginTop = isChild ? 10 : 30

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: index > 0 ? marginTop : 0  }}>
            {
                src && <img style={{ width: 30, height: 30, borderRadius: 3, marginRight: 5 }} src={src} alt="listovey" />
            }
            <ResponseComponent answer={answer} len={commentLen} />
        </div>
    )
}

export default memo(DataItemCommentHeaderComponent)