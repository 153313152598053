import { memo, useEffect, useState, useCallback } from "react"
import { useLazyQuery } from "@apollo/client"
import { IS_FILES } from "gql/s3"
import { getFilename, getPathname } from "ts-utils"
import { SurveyDesignsProps } from 'gql/survey_designs'
import KindComponent, { filterKinds } from './Kind'
import MakeFilterImageComponent from './MakeFilterImage'

interface Props {
    background_image: SurveyDesignsProps['background_image'];
    background_filter: SurveyDesignsProps['background_filter'];
    onChange: (val: SurveyDesignsProps['background_filter']) => void;
}

export type Kind = 'unsplash' | 'listovey'

export type OnChangeIsFiles = (flag: boolean) => void

// 이미지 주소를 판단하여 언스플래시 인지 listovey인지 
export const getKind = (background_image: string): Kind | '' => {
    if (!background_image) return ''

    // 언스플래쉬 이미지 인지 판단
    if (/^https:\/\/images.unsplash.com/.test(background_image)) return 'unsplash'

    // 리스토베이 이미지 인지 판단
    const match = background_image.match(new RegExp(`^${process.env.REACT_APP_CF}`))
    if (match) {
        if (match.length > 0) return 'listovey'
    }

    return ''
}

// 필터링된 파일명
export const getFiltername = (background_image: string, filter: string, kind: 'unsplash' | 'listovey') => {
    return kind === 'unsplash' ? `${filter}-${getFilename(background_image)}.png` : `${filter}-${getFilename(background_image)}`
}

// 저장된 파일 경로
const makePath = (background_image: string) => {
    // 언스플래시
    if (getKind(background_image) === 'unsplash') return filterKinds.map(c => `${process.env.REACT_APP_CF_UNSPLASH}/${getFiltername(background_image, c, 'unsplash')}`)
    
    const pathname = getPathname(background_image)
    return filterKinds.map(c => `${pathname}/${getFiltername(background_image, c, 'listovey')}`)
}

function FilterComponent(props: Props) {
    const { background_image, background_filter, onChange } = props

    const [ isFiles, setIsFiles ] = useState<boolean | null>(null)

    // 파일이 있는지 체크
    const [ getIsFiles ] = useLazyQuery(IS_FILES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            let flag = false
            const len = data.isFiles.length

            for (let i=0; i<len; i++) {
                const row = data.isFiles[i]

                // 한개라도 없다면 이미지를 다시 업로드 해야한다.
                if (!row) {
                    flag = false
                    break
                }

                flag = true
            }

            setIsFiles(flag)
        },
        onError: (e) => {
            console.log(e)
        }
    })

    const handleChangeIsFiles: OnChangeIsFiles = useCallback((flag) => {
        setIsFiles(flag)
    }, [])

    useEffect(() => {
        // 필터링된 이미지가 있는지 판단
        const existFile = async() => {

            const srcs = makePath(background_image).map(src => src.replace(`${process.env.REACT_APP_CF}/`, ''))

            getIsFiles({ variables: { srcs } })
        }

        existFile()
    }, [background_image, getIsFiles])

    if (isFiles === null) return <div style={{ paddingTop: 6, paddingBottom: 6, fontSize: 16 }}>Loading...</div>

    // 필터링 파일이 없거나 적을경우(발생하기 어려운 상황)
    if (isFiles === false) return <MakeFilterImageComponent background_image={background_image} onChange={handleChangeIsFiles}/>

    // 필터링 파일이 있을경우
    return <KindComponent background_filter={background_filter} onChange={onChange}/>
}

export default memo(FilterComponent)