import { useState, useCallback } from 'react'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { 
    Typography,
    FormControl, 
    FormLabel,
    FormControlLabel, 
    RadioGroup,
    Radio,
    Checkbox, 
    Button, 
    AppBar, 
    Tabs, 
    Tab, 
    Box, 
    List, 
    ListItem, 
    ListItemText, 
    ListItemIcon
} from '@material-ui/core'
import { Folder as FolderIcon, Warning as WarningIcon } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { getShareLink } from 'utils/survey'
import htmlParser from 'react-html-parser'
import clipboard from 'copy-to-clipboard'
import styled from 'styled-components'
import { postMessage } from 'utils'

const useStyles = makeStyles(theme => ({
    input: {
        maxWidth: 600,
        backgroundColor: 'white'
    },
    box: {
        marginTop: 50
    },
    list: {
        marginBottom: 10
    },
    getresponseTypeBox: {
        display: 'none',
        paddingLeft: 20,
        marginTop: 5,
        marginBottom: 12,
        '&.getresponse': {
            display: 'block'
        }
    },
    getresponseTypeFormLabel: {
        fontSize: 15
    },
    getresponseTypeLabel: {
        transition: 'all .3s',
        color: theme.palette.grey[700],
        '&.html, &.template': {
            color: theme.palette.primary.main
        }
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        '& > * + *': {
            marginLeft: 5
        }
    }
}))

const MyListItemIcon = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))(ListItemIcon)

const MyListItemText = withStyles({
    root: {
        wordBreak: 'break-all'
    }
})(ListItemText)


const Ustyled = styled.u`
    cursor: pointer;
`

const urls = {
    stibee: 'https://stibee.com',
    mailchimp: 'https://mailchimp.com',
    getresponse: 'https://getresponse.com'
}

export default (props) => {
    const { on, listoDomain, args='', } = props

    const classes = useStyles()

    const [ type, setType ] = useState('stibee')
    const [ getresponseType, setGetresponseType ] = useState('html') // html, getResponse
    const [ button, setButton ] = useState(false)

    const { formatMessage: f, locale } = useIntl()

    const shareLink = getShareLink({domain: listoDomain, args})
    const url = shareLink.survey.email

    const handleClipBoard = useCallback((url) => {
        clipboard(url)

        postMessage({ 
            dispatch: { type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Share.Email.clipboard'}), duration: 1000 } 
        })

    }, [f])

    return (
        <> 
        <AppBar position="static" color="default" elevation={0} variant="outlined">
        <Tabs value={type} indicatorColor="primary" textColor="primary"  variant="scrollable" scrollButtons="on" onChange={(e, newValue) => setType(newValue)}>
            <Tab value="stibee" label={<Typography noWrap={false} variant="body2">{f({id: 'component.Survey.Create.Share.Email.tabs.0'})}</Typography>} />
            <Tab value="getresponse" label={<Typography noWrap={false} variant="body2">{f({id: 'component.Survey.Create.Share.Email.tabs.2'})}</Typography>}/>
            <Tab value="mailchimp" label={<Typography noWrap={false} variant="body2">{f({id: 'component.Survey.Create.Share.Email.tabs.1'})}</Typography>}/>
            <Tab value="etc" label={<Typography noWrap={false} variant="body2">{f({id: 'component.Survey.Create.Share.Email.tabs.3'})}</Typography>}/>
        </Tabs>
        </AppBar>

       
        <Box className={classes.box}>
            <Typography variant="h5" gutterBottom>{f({id: `component.Survey.Create.Share.Email.${type}.title`})}</Typography>
            <List className={classes.list}>
                {
                    type === 'stibee' ? (
                        <>
                        
                        
                        
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText 
                                primary={
                                    f(
                                        {id: 'component.Survey.Create.Share.Email.stibee.lists.0'}, 
                                        {url: <Ustyled onClick={() => handleClipBoard(urls.stibee)}>{urls.stibee}</Ustyled>}
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText primary={f({id: 'component.Survey.Create.Share.Email.stibee.lists.1'})}/>
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><WarningIcon/></MyListItemIcon>
                            <MyListItemText secondary={htmlParser(f({id: 'component.Survey.Create.Share.Email.stibee.lists.2'}))}/>
                        </ListItem>
                        </>
                    ) : type === 'mailchimp' ? (
                        <>
                        <ListItem>
                            <MyListItemIcon><FolderIcon/></MyListItemIcon>
                            <MyListItemText
                                primary={
                                    f(
                                        {id: 'component.Survey.Create.Share.Email.mailchimp.lists.0'}, 
                                        {url: <Ustyled onClick={() => handleClipBoard(urls.mailchimp)}>{urls.mailchimp}</Ustyled>}
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText primary={f({id: 'component.Survey.Create.Share.Email.mailchimp.lists.1'})}/>
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><WarningIcon/></MyListItemIcon>
                            <MyListItemText secondary={htmlParser(f({id: 'component.Survey.Create.Share.Email.stibee.lists.2'}))}/>
                        </ListItem>
                        </>
                    ) : type === 'getresponse' ? (
                        <>
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText
                                primary={
                                    f(
                                        {id: 'component.Survey.Create.Share.Email.getresponse.lists.0'}, 
                                        {url: <Ustyled onClick={() => handleClipBoard(urls.getresponse)}>{urls.getresponse}</Ustyled>}
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText primary={f({id: 'component.Survey.Create.Share.Email.getresponse.lists.1'})}/>
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText primary={f({id: 'component.Survey.Create.Share.Email.getresponse.lists.2'})}/>
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><WarningIcon/></MyListItemIcon>
                            <MyListItemText secondary={htmlParser(f({id: 'component.Survey.Create.Share.Email.stibee.lists.2'}))}/>
                        </ListItem>
                        </>
                    ) : (
                        <>
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText 
                                primary={f({id: 'component.Survey.Create.Share.Email.etc.lists.0'})}
                                secondary={f({id: 'component.Survey.Create.Share.Email.etc.sublists.0'}, {url})}
                            />
                        </ListItem>
                        <ListItem>
                            <MyListItemIcon><FolderIcon /></MyListItemIcon>
                            <MyListItemText primary={f({id: 'component.Survey.Create.Share.Email.etc.lists.1'})} />
                        </ListItem>
                        <>
                        {
                            locale === 'ko-KR' ? (
                                <ListItem>
                                    <MyListItemIcon><FolderIcon /></MyListItemIcon>
                                    <MyListItemText 
                                          primary={f({id: 'component.Survey.Create.Share.Email.etc.lists.2'})}
                                          secondary={f({id: 'component.Survey.Create.Share.Email.etc.sublists.2'}, {url})}
                                    />
                                </ListItem>
                            ) : null
                        }
                        </>
                        </>
                    )
                }
            </List>
            <Box className={`${classes.getresponseTypeBox} ${type}`}>
                <FormControl component="fieldset">
                    <FormLabel component="legend" disabled className={classes.getresponseTypeFormLabel}>{f({id: 'component.Survey.Create.Share.Email.getresponse.formLabel'})}</FormLabel>
                    <RadioGroup 
                        row 
                        value={getresponseType} 
                        onChange={(e) => setGetresponseType(e.target.value)}
                    >
                        <FormControlLabel
                            value="html"
                            control={<Radio color="primary" />}
                            label={f({id: 'component.Survey.Create.Share.Email.getresponse.label.html'})}
                            labelPlacement="end"
                            className={`${classes.getresponseTypeLabel} ${getresponseType === 'html' ? 'html' : ''}`}
                        />
                        <FormControlLabel
                            value="template"
                            control={<Radio color="primary" />}
                            label={f({id: 'component.Survey.Create.Share.Email.getresponse.label.template'})}
                            labelPlacement="end"
                            className={`${classes.getresponseTypeLabel} ${getresponseType === 'template' ? 'template' : ''}`}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            
            <Box className={classes.buttonBox}>
                <Button variant="contained" size="medium" color="default"  onClick={() => on.openCode(type, getresponseType, button)}> 
                    <Typography>{f({id: 'component.Survey.Create.Share.Email.code'})}</Typography>
                </Button>
                <FormControlLabel
                    control={<Checkbox size="small" name="checkedA" onChange={() => setButton(prevState => !prevState)}/>}
                    label={<Typography variant="caption">{f({id: 'component.Survey.Create.Share.Email.button'})}</Typography>}
                />
            </Box>
        </Box>
        </>


    )
}