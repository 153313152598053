import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Select, MenuItem, Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { 
    Create as CreateIcon, 
    Palette as PaletteIcon,
    CallSplit as CallSplitIcon, 
    Settings as SettingsIcon,
    FormatListNumbered as FormatListNumberedIcon,
    SwapHoriz as SwapHorizIcon
} from '@material-ui/icons'
import { withSurveyModuleLists } from 'hoc'
import { useIntl } from 'react-intl'
import Wrapper, { useStyles } from 'styled/Survey/Left'
import { SelectStyled } from 'styled/Input'
import oc from 'open-color'
import { withBreakPoints } from 'hoc'

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > div + div': {
            marginLeft: 10
        },
        '& > div:last-child': {
            display: 'flex',
            alignItems: 'center'
        },
        '& > div:last-child > div + div': {
            marginLeft: 10
        },
        '& > div:last-child > div': {
            display: 'flex',
            alignItems: 'center'
        },
        '& > div:last-child > div > * + *': {
            marginLeft: 5
        }
    }
})(Box)

const MyTitle = withStyles({
    root: (props) => {
        const { selected } = props

        return {
            fontSize: 10,
            textAlign: 'center',
            color: selected ? oc.indigo[9] : oc.gray[9]
        }

    }
})(Box)

const ItemBox = withStyles({
    root: (props) => {
        const { selected } = props

        return {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .3s',
            cursor: 'pointer',
            background: selected ? 'rgba(0, 0, 0, .1)': 'transparent',
            paddingTop: 5,
            paddingBottom: 5,
            '&:hover': {
                background: selected ? 'rgba(0, 0, 0, .1)': 'rgba(151, 151, 151, .1)'
            }
        }
    }
})(Box)

const Left = (props) => {

    const { title, category, onChange, breakpoints, children } = props

    const { md } = breakpoints

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const states = useSelector(states => states.surveyCreateModuleIsEdit)
    const { is: isEdit } = states

    const handleChange = (value) => {
        // 편집중인데 다른 곳으로 이탈할경우 경고창 보여준다.
        if (isEdit && value !== 'module') {

            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) {
                onChange(value)
            }
        } 
        // 시작/완료글에서 문항 아이콘클릭시 무반응으로 처리
        else if (value === 'module' && (category === 'thanks-start' || category === 'thanks-end')) {

        }
        else onChange(value)
    }

    // module이랑 thanks랑 합쳐져서 이상하게 꼬엿다 후...

    const customCategory = category === 'module' || category === 'thanks-start' || category === 'thanks-end' || category === 'brand' ? 'module' : category

    const background = md ? 'transparent' : oc.gray[4]

    return (
        <Wrapper background={background} borderRight="none" className='Pillar'>
            {
                md ? (
                    <MyBox>
                        <div>
                            <Select input={<SelectStyled fontSize={13} style={{width: 130}} backgroundcolor="white" padding="8px 12px 8px 8px"/>} value={customCategory} onChange={(e) => handleChange(e.target.value)}>
                                <MenuItem value="module">{f({id: 'component.Survey.Create.Left.tooltip.0'})}</MenuItem>
                                <MenuItem value="design">{f({id: 'component.Survey.Create.Left.tooltip.2'})}</MenuItem>
                                <MenuItem value="logic">{f({id: 'component.Survey.Create.Left.tooltip.3'})}</MenuItem>
                                <MenuItem value="method">{f({id: 'component.Survey.Create.Left.tooltip.4'})}</MenuItem>
                                <MenuItem value="setting">{f({id: 'component.Survey.Create.Left.tooltip.5'})}</MenuItem>
                                <MenuItem value="publish">{f({id: 'component.Survey.Create.Left.tooltip.6'})}</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <div>{children}</div>
                            <div><Typography color="primary">{title}</Typography></div>
                        </div>
                        
                    </MyBox>
                ) : (
                    <div style={{paddingTop: 8}}>
                        <ItemBox onClick={() => handleChange('module')} selected={customCategory === 'module'}>
                            <CreateIcon className={customCategory === 'module' ? classes.iconSelected : classes.icon}/>
                            <MyTitle selected={customCategory === 'module'}>{f({id: 'component.Survey.Create.Left.tooltip.0'})}</MyTitle>
                        </ItemBox>
                        <ItemBox onClick={() => handleChange('design')} selected={customCategory === 'design'}>    
                            <PaletteIcon className={customCategory === 'design' ? classes.iconSelected : classes.icon}/>
                            <MyTitle selected={customCategory === 'design'}>{f({id: 'component.Survey.Create.Left.tooltip.2'})}</MyTitle>
                        </ItemBox>
                        <ItemBox onClick={() => handleChange('logic')} selected={customCategory === 'logic'}>
                            <CallSplitIcon className={customCategory === 'logic' ? classes.iconSelected : classes.icon}/>
                            <MyTitle selected={customCategory === 'logic'}>{f({id: 'component.Survey.Create.Left.tooltip.3'})}</MyTitle>
                        </ItemBox>
                        <ItemBox onClick={() => handleChange('method')} selected={customCategory === 'method'}>
                            <SwapHorizIcon className={customCategory === 'method' ? classes.iconSelected : classes.icon}/>
                            <MyTitle selected={customCategory === 'method'}>{f({id: 'component.Survey.Create.Left.tooltip.4'})}</MyTitle>
                        </ItemBox>
                        <ItemBox onClick={() => handleChange('setting')} selected={customCategory === 'setting'}>
                            <FormatListNumberedIcon className={customCategory === 'setting' ? classes.iconSelected : classes.icon}/>
                            <MyTitle selected={customCategory === 'setting'}>{f({id: 'component.Survey.Create.Left.tooltip.5'})}</MyTitle>
                        </ItemBox>
                        <ItemBox onClick={() => handleChange('publish')} selected={customCategory === 'publish'}>
                            <SettingsIcon className={customCategory === 'publish' ? classes.iconSelected : classes.icon}/>
                            <MyTitle selected={customCategory === 'publish'}>{f({id: 'component.Survey.Create.Left.tooltip.6'})}</MyTitle>
                        </ItemBox>
                    </div>
                )
            }
            
        </Wrapper>
    )
}

export default withBreakPoints(withSurveyModuleLists('side')(memo(Left)))

