import { memo } from 'react'
import oc from 'open-color'
import { getMimePicture } from 'utils/survey'
import { downloadFile } from 'utils'
import { handleDisabledRightClick } from 'ts-utils'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import GetAppIcon from '@material-ui/icons/GetApp'
import withSurveyPopoverImageNew, { OnPopoverImageOpen } from 'hoc/Survey/PopoverImageNew'
import { getFilename } from 'component/Survey/Create/NewAnalysis/Summary/DataItem'

interface Props {
    title: string;
    filename: string;
    src: string;
    comment: string;
    survey_analysis_users_no: number;
    onPopoverImageOpen: OnPopoverImageOpen;
}

const useStyles = makeStyles(theme => ({
    root: {
        background: oc.indigo[1],
        borderRadius: 3,
        padding: '3px 5px 3px 5px',
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
   
    },
    text: {
        fontSize: 12,
        wordBreak: 'break-all'
    },
    img: {
        maxWidth: 150,
        maxHeight: 150,
        borderRadius: 3,
        cursor: 'pointer'
    },
    comment: {
        marginTop: 5,
        paddingTop: 3,
        borderTop: `1px dashed ${oc.gray[5]}`
    }
}))

function FileValueComponent(props: Props) {
    const { title, src, survey_analysis_users_no, filename, comment, onPopoverImageOpen } = props

    const classes = useStyles()

    const mime = getMimePicture(src)

    const handleClick = async() => { 
        const newTitle = title ? `${getFilename({ title })}-${survey_analysis_users_no}-${filename}` : `${survey_analysis_users_no}-${filename}`

        const blob = await fetch(src).then(response => {
            if (response.status !== 200) return null
            return response.blob()
        })
    
        downloadFile({ filename: newTitle, data: blob })
    }

    return (
        <div style={{ display: 'table' }}>
            <div className={classes.root}>
                <div className={classes.box}>
                    {
                        mime === 'image' ? <img src={src} alt="listovey"  loading="lazy" className={classes.img} onContextMenu={handleDisabledRightClick} onClick={() => onPopoverImageOpen(src)} />
                                         : <Typography variant="body2" className={classes.text}>{filename}</Typography>
                    }
                    <IconButton size="small" onClick={handleClick}>
                        <GetAppIcon />
                    </IconButton>

                </div>
                {
                    (comment !== '' && comment !== undefined) && <div className={classes.comment}><Typography variant="body2" className={classes.text}>{comment}</Typography></div>
                }
            </div>

        </div>
    )
}

export default withSurveyPopoverImageNew(memo(FileValueComponent))