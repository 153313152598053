/**
 * Redirect 인풋박스 컴포넌트
 */
import { memo } from 'react'
import TextField from '@material-ui/core/TextField'
import { Name, OnChange, OnEnter } from './EndingsItemRedirect'

interface Props {
    name: Name;
    placeholder: string;
    helper: string;
    error: boolean;
    value: string;
    maxLength: number;
    onChange: OnChange;
    onKeyUp: OnEnter;
}

function EndingsItemRedirectInput(props: Props) {
    const { name, placeholder, helper, error, value, maxLength, onChange, onKeyUp } = props
    return (
        <TextField 
            name={name}
            style={{marginTop: 20}}
            fullWidth 
            placeholder={placeholder}
            helperText={helper}
            error={error}
            value={value}
            inputProps={{ maxLength }}
            onChange={onChange}
            onKeyUp={onKeyUp}
        />
    )
}

export default memo(EndingsItemRedirectInput)