/**
 * 로우데이터 가져오기 액션에서 리프레시 체크하여 내보내기
 */
import { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { RefreshProps } from 'reducer/analysis/summary/refresh'
let timer: ReturnType<typeof setTimeout> 

function useRefreshDownload(): [{action: number, isRefresh: boolean, refreshDisabled: RefreshProps['disabled']}, () => void] {
    // 다운로드 실행 난수
    const [ action, setAction ] = useState(0)

    // 리프레시로 실행 여부 (우측 상단에 새로고침 아이콘 누를시 발동)
    const [ isRefresh, setIsRefresh ] = useState(false)
    

    function handleAction() {
        clearTimeout(timer)
        
        setTimeout(() => {
            // 다운로드 후에는 새로고침하기전 까지는 캐시값 가져온다.
            setIsRefresh(false)
        }, 100)
    
        setAction(Math.random())
    }

    const refresh = useSelector((states: RootState) => states.analysisSummaryRefresh)

    // 새로고침 버튼 누르면 데이터 다운로드 시, refetch로 가져온다
    useEffect(() => {
        if (refresh.refresh > 0) {
            setIsRefresh(true)
            setAction(0)
        }
    }, [refresh.refresh])
    
    useEffect(() => {
        return () => {
            setAction(0)
        }
    }, [])

    return [ { action, isRefresh, refreshDisabled: refresh.disabled }, handleAction ]
}

export default useRefreshDownload