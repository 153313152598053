import { Typography, List, ListItemText } from '@material-ui/core'
import { Folder as FolderIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import CreateBox from 'styled/Survey/CreateBox'
import { Title, ListItem, ListItemIcon } from 'styled/Survey/CreateExplainStyle'

export default () => {    
    const { formatMessage: f } = useIntl()

    return (
        <CreateBox className="explain">
            <Title variant="h5" gutterBottom>{f({id: 'component.Survey.Create.Logic.PageRandom.Explain.title'})}</Title>
             <List > 
            	<ListItem>
                	<ListItemIcon>
                    	<FolderIcon/>
                  	</ListItemIcon>
                  	<ListItemText
                    	primary={f({id: 'component.Survey.Create.Logic.PageRandom.Explain.lists.0'})}
                  	/>
                </ListItem>

                <ListItem>
                	<ListItemIcon>
                    	<FolderIcon/>
                  	</ListItemIcon>
                  	<ListItemText
                    	primary={f({id: 'component.Survey.Create.Logic.PageRandom.Explain.lists.1'})}
                  	/>
                </ListItem>

                <ListItem>
                	<ListItemIcon>
                    	<FolderIcon color="secondary"/>
                  	</ListItemIcon>
                  	<ListItemText
                    	primary={<Typography color="secondary">{f({id: 'component.Survey.Create.Logic.PageRandom.Explain.lists.2'})}</Typography>}
                  	/>
                </ListItem>

                <ListItem>
                	<ListItemIcon>
                    	<FolderIcon color="secondary"/>
                  	</ListItemIcon>
                  	<ListItemText
                    	primary={<Typography color="secondary">{f({id: 'component.Survey.Create.Logic.PageRandom.Explain.lists.3'})}</Typography>}
                  	/>
                </ListItem>
            </List>
        </CreateBox>
    )
}