import { useState, memo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import oc from 'open-color-js'
import { numberFormat } from 'utils'
import moment from 'moment'
import { 
    Container, 
    Typography, 
    Grid, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
    Dialog,
    Box
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Help as HelpIcon, MoreHorizOutlined as MoreHorizOutlinedIcon } from '@material-ui/icons'
import { StyledTableSortLabel } from 'styled/TableHeader'
import Tooltip from 'styled/Custom/Tooltip'
import ModuleLabel from 'styled/Survey/ModuleLabel'
import { getDate } from 'styled/Survey/Analysis/Calendar'
import { TitleTypography } from 'styled/Survey/Analysis/Common'
import Chart from 'styled/Chart'
import { withBreakPoints } from 'hoc'
import SurveyCreateAnalysisTabComponent from 'component/Survey/Create/NewAnalysis/Tab'
import SurveyCreateAnalysisInsightDelayInputComponent from 'component/Survey/Create/NewAnalysis/Insight/DelayInput'
import CompleteIntervalLists from './CompleteIntervalLists'
import DelayTimeLists from './DelayTimeLists'
import DelayTimeListsOne from './DelayTimeListsOne'
import CompleteComponent from './Complete'
import RefererGraphComponent from './RefererGraph'
import ButtonsComponent from './Buttons'
import Buttons2Component from './Buttons2'
import TopComponent from './Top'

export const useStyles = makeStyles(theme => ({
    table: {
       width: '100%'
    },
    insight: {
        padding: 12,
        position: 'relative',
        height: 82
    },
    chart: {
        padding: 10, 
        height: 300
    },
    pdfButton: {
        position: 'fixed',
        bottom: 20,
        right: 10
    },
    columnBox: {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    },
    columnMoreIcon: {
        display: 'block', 
        width: 25, 
        height: 22
    }
}))

const MyContainer1 = withStyles({
    root: {
        marginTop: 25,
        paddingBottom: 25
    }
})(props => <Container maxWidth="xl" disableGutters {...props}/>)

const MyContainer2 = withStyles({
    root: {
        paddingTop: 25,
        paddingBottom: 25
    }
})(props => <Container maxWidth="xl" disableGutters {...props}/>)

const MyContainer3 = withStyles(theme => ({
    root: {
        paddingTop: 25,
        paddingBottom: 30,
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 90
        },
    }
}))(props => <Container maxWidth="xl" disableGutters {...props}/>)

export const TitleGrid = withStyles({
    root: {
        marginBottom: 10,
        '& > *:nth-child(2)': {
            textAlign: 'right'
        }
    }
})(props => <Grid container  direction="row" justifyContent="space-between" alignItems="flex-end" {...props}/>)

export const MyInsightValue = withStyles({
    root: {
        color: '#001d50',
        textAlign: 'right',
        height: '60%'
    }
})(props => <Typography {...props} variant="h4" display="block"/>)

export const MyInsightColumn = withStyles({
    root: {
        color: oc.gray6,
        height: '40%'
    }
})(props => <Typography {...props} variant="subtitle2" display="block"/>)

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: oc.gray6,
      color: theme.palette.common.white,
      padding: '10px 0'
    },
    body: {
        padding: '3px 0',
        wordBreak: 'break-all'
    },
}))(props => <TableCell {...props}/>)

export const TitleStyled = styled.div`
    display: table;
    position: relative;

    .help {
        position: absolute;
        top: 0;
        right: -21px;
    }
`

const Img = styled.img`
    border-radius: 5px;
    display: block;
    max-width: 100%;
    max-height: 100%;
`

const DelayDateBoxStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    
    & > * + * {
        margin-left: 10px;
    }
`
export const GraphBox = withStyles({
    root: (props) => {
        const { width=270, height=270 } = props

        return {
            position: 'absolute',
            top: -10000,
            left: -10000,
            width,
            height
        }
    }
})(Box)

// 막대그래프 너무 넓으면 보기흉하니까.. 빈값 추가해준다
function addSpaceData({data, labels, breakpoints}) {
    let guideLen = 10
    const len = data.length

    if (breakpoints.md) guideLen = 10
    if (breakpoints.sm) guideLen = 7
    if (breakpoints.xs) guideLen = 3

    if (len >= guideLen) return { customData: data, customLabels: labels }

    const customData = [...data]
    const customLabels = [...labels]

    // 빈값을 채운다.
    const endLen = guideLen - len
    for (let i=0; i<endLen; i++) {
        customData.push('')
        customLabels.push('')
    }
    
    return { customData, customLabels }
}

// refert data 그래프에 다 표현 못하니까..
function getLimitRefererObjectValue(obj, breakpoints) {
    const keys = Object.keys(obj)
    const values = Object.values(obj)

    let limit = 30
    if (breakpoints.sm) limit = 20
    if (breakpoints.xs) limit = 10

    if (keys.length <= limit) return { keys, values}


    const newKeys = []
    const newValues = []

    for (let i=0; i<limit; i++) {
        newKeys.push(keys[i])
        newValues.push(values[i])
    }

    return {
        keys: newKeys,
        values: newValues
    }
}


const getChartData = {
    device: (data, f) => {
        return {
            data: [data.browser, data.tablet, data.mobile, data.other],
            
            labels: [
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.device.0'}), 
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.device.1'}), 
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.device.2'}), 
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.device.3'})
            ],
            title: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.device'}),
            legendPosition: 'bottom'
        }
    },
    groupPeriod: (data, f, date) => {
        let label0 = f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupPeriod.0'})
        let label1 = f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupPeriod.1'})
        let label2 = f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupPeriod.2'})
        let label3 = f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupPeriod.3'})
        let label4 = f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupPeriod.4'})
        let label5 = f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupPeriod.5'})
        let label6 = f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupPeriod.6'})

        const { edate } = getDate(date)

        // 설문 기간 검색이 있고, 검색 마지막 날짜가 오늘 날짜가 아니라면 날짜값을 표시해준다.
        if (edate && edate !== moment().format('YYYY-MM-DD')) {
            label0 = moment(edate).subtract(6, 'days').format('MM.DD')
            label1 = moment(edate).subtract(5, 'days').format('MM.DD')
            label2 = moment(edate).subtract(4, 'days').format('MM.DD')
            label3 = moment(edate).subtract(3, 'days').format('MM.DD')
            label4 = moment(edate).subtract(2, 'days').format('MM.DD')
            label5 = moment(edate).subtract(1, 'days').format('MM.DD')  
            label6 = parseDate(edate).substring(5, 10)
        }

        return {
            data: [data._6, data._5, data._4, data._3, data._2, data._1, data._0],
            labels: [
                label0,
                label1,
                label2,
                label3,
                label4,
                label5,
                label6
            ],
            title: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.groupPeriod'}),
            legendPosition: false
        }
    },
    groupWeekDay: (data, f) => {
        return {
            data: [data._0, data._1, data._2, data._3, data._4, data._5, data._6],
            labels: [
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupWeekDay.0'}),
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupWeekDay.1'}),
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupWeekDay.2'}),
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupWeekDay.3'}),
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupWeekDay.4'}),
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupWeekDay.5'}),
                f({id: 'component.Survey.Create.Analysis.Insight.Component.labels.groupWeekDay.6'})
            ],
            title: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.groupWeekDay'}),
            legendPosition: false
        }
    },
    groupTime: (data, f) => {
        return {
            data: Object.values(data),
            labels: [
                '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
                '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
            ],
            title: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.groupTime'}),
            legendPosition: false
        }
    },
    refererDomain: (domains, breakpoints, f) => {
        const { keys, values } = getLimitRefererObjectValue(domains, breakpoints) 

        const { customData, customLabels } = addSpaceData({data: values, labels: keys, breakpoints}) 

        return { data: customData, labels: customLabels, title: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.refererDomain'}), legendPosition: false }
    },
    refererContury: (conturys, breakpoints, f) => {
        const { keys, values } = getLimitRefererObjectValue(conturys, breakpoints) 

        const { customData, customLabels } = addSpaceData({data: values, labels: keys, breakpoints}) 

        return { data: customData, labels: customLabels, title: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.refererContury'}), legendPosition: false }
    },
    refererCity: (citys, breakpoints, f) => {
        const { keys, values } = getLimitRefererObjectValue(citys, breakpoints) 

        const { customData, customLabels } = addSpaceData({data: values, labels: keys, breakpoints}) 

        return { data: customData, labels: customLabels, title: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.refererCity'}), legendPosition: false }
    }
}

const initialState = {
    img: {
        open: false,
        src: ''
    }
}

function parseDate(date) {
    if (!date) return date
    return date.replace(/-/g, '.')
}

const Component = (props) => {

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ img, setImg ] = useState(initialState.img)

    const {
        survey_no, 
        loading,
        ids, 
        mbox,
        insightData, 
        deviceType, 
        pageViewData, 
        pageViewOrder,
        date, 
        dateCookieName,
        completeCookieName,
        refererCityCookieName,
        openCalendar, 
        on, 
        refetchMbox,
        refetchInsight,
        refetchPageView,
        breakpoints 
    } = props

    const { method, delay_one, delay_multi } = mbox

    const { 
        connectCount,
        startCount,
        completeCount,
        completeAvg,
        completeAvgTime,
        deviceConnectCount, 
        groupDayConnectCount,
        groupWeekDayConnectCount,
        groupTimeConnectCount,
        refererDomainCount,
        refererConturyCount,
        refererCityCount
    } = insightData

    const chartDevice = getChartData.device(deviceConnectCount, f)
    const chartGroupPeriod = getChartData.groupPeriod(groupDayConnectCount, f, date)
    const chartGroupWeekDay = getChartData.groupWeekDay(groupWeekDayConnectCount, f)
    const chartGroupTime = getChartData.groupTime(groupTimeConnectCount, f)
    const chartRefererDomain = getChartData.refererDomain(refererDomainCount, breakpoints, f)
    const chartRefererContury = getChartData.refererContury(refererConturyCount, breakpoints, f)
    const chartRefererCity = getChartData.refererCity(refererCityCount, breakpoints, f)
    
    const { order, orderBy } = pageViewOrder
    const _orderBy = orderBy ? 'desc' : 'asc'

    // 페이지/문항 내용 글자길이 자르기
    const delay = method === 'one' ? delay_one : delay_multi
    const { xs } = breakpoints

    // 이미지 미리보기
    const handleImage = {
        open: (src) => {
            setImg({ open: true, src })
        },
        close: () => setImg(prevState => ({...prevState, open: false }))
    }
    
    return (
        <>
        <SurveyCreateAnalysisTabComponent/>        
       
        <MyContainer1>
            <Container>
                <ButtonsComponent
                    dateCookieName={dateCookieName}
                    openCalendar={openCalendar} 
                    date={date} 
                    loading={loading}
                    deviceType={deviceType} 
                    on={on}
                >
                    <Buttons2Component
                        ids={ids} 
                        insightData={insightData} 
                        pageViewData={pageViewData}
                        deviceType={deviceType}
                        date={date}
                        mbox={mbox} 
                        loading={loading}
                        completeCookieName={completeCookieName}
                        refetchMbox={refetchMbox}
                        refetchInsight={refetchInsight}
                        refetchPageView={refetchPageView}
                        refererCityCookieName={refererCityCookieName}
                    />
                </ButtonsComponent>
                <Divider style={{marginTop: 10, marginBottom: 30}}/>
                <TitleGrid>
                    <Grid item md={6}>
                        <TitleStyled>
                            <TitleTypography>{f({id: 'component.Survey.Create.Analysis.Insight.Component.title.insight'})}</TitleTypography>
                            <Tooltip variant="button" placement="bottom" isMobileClick={true} title={f({id: 'component.Survey.Create.Analysis.Insight.Component.tooltip.insight'})}>
                                <HelpIcon color="disabled" fontSize="small" className="help"/>
                            </Tooltip>
                        </TitleStyled>
                    </Grid>
                    <Grid item md={6}>
                        
                    </Grid>
                </TitleGrid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Paper className={classes.insight} variant="outlined">
                            <MyInsightColumn>{f({id: 'component.Survey.Create.Analysis.Insight.Component.title.connectCount'})}</MyInsightColumn>
                            <MyInsightValue>{numberFormat(connectCount)}</MyInsightValue>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Paper className={classes.insight} variant="outlined">
                            <MyInsightColumn>{f({id: 'component.Survey.Create.Analysis.Insight.Component.title.startCount'})}</MyInsightColumn>
                            <MyInsightValue>{numberFormat(startCount)}</MyInsightValue>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Paper className={classes.insight} variant="outlined">
                            <CompleteComponent completeAvg={completeAvg} completeCount={completeCount} cookieName={completeCookieName}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Paper className={classes.insight} variant="outlined">
                            <CompleteIntervalLists survey_no={survey_no} date={date} deviceType={deviceType} completeCount={completeCount}/>
                            <MyInsightColumn>
                                <div className={classes.columnBox}>
                                    <div>{f({id: 'component.Survey.Create.Analysis.Insight.Component.title.completeTime'})}</div>
                                    
                                    <Tooltip variant="button" placement="top" title={f({id: 'component.Survey.Create.Analysis.Insight.Component.tip.completeTime'})} isMobileClick={true}>
                                        <div>
                                            <MoreHorizOutlinedIcon className={classes.columnMoreIcon}/>
                                        </div>
                                    </Tooltip>
                                    
                                </div>
                            </MyInsightColumn>
                            <MyInsightValue>{completeAvgTime}</MyInsightValue>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </MyContainer1>
        <MyContainer2>
            <Container>
                <TitleGrid> 
                    <Grid item>
                        <TitleStyled>
                            <TitleTypography>{f({id: 'component.Survey.Create.Analysis.Insight.Component.title.chart'})}</TitleTypography>
                            <Tooltip variant="button" placement="top" isMobileClick={true} title={f({id: 'component.Survey.Create.Analysis.Insight.Component.tooltip.chart'})}>
                                <HelpIcon color="disabled" fontSize="small" className="help"/>
                            </Tooltip>        
                        </TitleStyled>
                    </Grid>
                </TitleGrid>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Paper className={classes.chart} variant="outlined">
                            <Chart chartType="doughnut" {...chartDevice}/>
                        </Paper>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Paper className={classes.chart} variant="outlined">
                            <Chart chartType="bar" {...chartGroupPeriod}/>
                        </Paper>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Paper className={classes.chart} variant="outlined">
                            <Chart chartType="bar" {...chartGroupWeekDay}/>
                        </Paper>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Paper className={classes.chart} variant="outlined">
                            <Chart chartType="line" {...chartGroupTime}/>
                        </Paper>
                    </Grid>
                </Grid>
                <RefererGraphComponent 
                    chartRefererDomain={chartRefererDomain}
                    chartRefererCity={chartRefererCity}
                    chartRefererContury={chartRefererContury}
                    refererDomainCount={refererDomainCount}
                    refererConturyCount={refererConturyCount}
                    refererCityCount={refererCityCount}
                    cookieName={refererCityCookieName}
                    ids={ids}
                />
            </Container>
        </MyContainer2>
        <MyContainer3>
            <Container>
                <TitleGrid> 
                    <Grid item md={4}>
                        <TitleStyled>
                            <TitleTypography>{f({id: `component.Survey.Create.Analysis.Insight.Component.title.${method}`})}</TitleTypography>
                            <Tooltip variant="button" placement="top" isMobileClick={true} title={f({id: `component.Survey.Create.Analysis.Insight.Component.tooltip.pageViews.${method}`}, {delay})}>
                                <HelpIcon color="disabled" fontSize="small" className="help"/>
                            </Tooltip>        
                        </TitleStyled>
                    </Grid>
                    <Grid item md={8}>
                        <DelayDateBoxStyled>
                            <SurveyCreateAnalysisInsightDelayInputComponent 
                                survey_no={survey_no}
                                method={method}
                                delay_multi={delay_multi}
                                delay_one={delay_one}
                                refetchMbox={refetchMbox}
                                refetchPageView={refetchPageView}
                            />
                        </DelayDateBoxStyled>
                    </Grid>
                </TitleGrid>
                <TableContainer component={Paper}  className={classes.table}  variant="outlined">
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell component="th" scope="row"  style={{paddingLeft: 10}}>
                                <StyledTableSortLabel active={order === 'indexs'} direction={_orderBy} onClick={() => on.changeOrder('indexs')}>
                                    <Typography variant="subtitle2">{f({id: `component.Survey.Create.Analysis.Insight.Component.page.${method === 'multi' ? 'columnPage' : 'columnQuestion'}`})}</Typography>
                                </StyledTableSortLabel>
                            </StyledTableCell>
                            {
                                !xs && (
                                    <StyledTableCell align="right" style={{whiteSpace: 'pre'}} sortDirection={"asc"}>
                                        <StyledTableSortLabel active={order === 'views'} direction={_orderBy} onClick={() => on.changeOrder('views')}>
                                            <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Analysis.Insight.Component.page.column.0'})}</Typography>
                                        </StyledTableSortLabel>
                                    </StyledTableCell>
                                )
                            }
                            <StyledTableCell align="right" style={{whiteSpace: 'pre'}} sortDirection={"desc"}>
                                <StyledTableSortLabel active={order === 'delays'} direction={_orderBy} onClick={() => on.changeOrder('delays')}>
                                    <Typography variant="subtitle2">{f({id: 'component.Survey.Create.Analysis.Insight.Component.page.column.1'})}</Typography>
                                </StyledTableSortLabel>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {pageViewData.map((row, i) => (
                        <TableRow key={i}>
                            <StyledTableCell component="td" scope="row" style={{paddingLeft: 10}}>
                                { method === 'one' ? (
                                    <ModuleLabel 
                                        question={row.pure_question} 
                                        number={row.number} 
                                        questionType={row.question_type}
                                        moduleType={row.module_type} 
                                        variant="body2"
                                    />
                                ) : (
                                    <div style={{padding: 10}}>{row.pure_question}</div>
                                )}
                            </StyledTableCell>
                            {
                                !xs && (
                                    <StyledTableCell align="right" style={{width: 70}}>
                                        <Typography variant="body2">{row.viewString}</Typography>
                                    </StyledTableCell>
                                )
                            }
                            <StyledTableCell align="right" style={{width: 126}}>
                                {
                                    method === 'multi' ? (
                                        <DelayTimeLists 
                                            survey_no={survey_no} 
                                            survey_page_no={row.survey_page_no} 
                                            page={row.page} 
                                            delay_time={delay_multi}
                                            date={date}
                                            deviceType={deviceType}
                                            count={row.delayCount}
                                            text={row.delayString}
                                        />
                                    ) : (
                                        <DelayTimeListsOne 
                                            survey_no={survey_no} 
                                            survey_question_no={row.survey_question_no} 
                                            number={row.number} 
                                            delay_time={delay_one}
                                            date={date}
                                            deviceType={deviceType}
                                            count={row.delayCount}
                                            text={row.delayString}
                                            moduleType={row.moduleType}
                                        />
                                    )
                                }
                                
                            </StyledTableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Container>
        </MyContainer3>
        <Dialog open={img.open} onClose={handleImage.close}>
            <Img src={img.src}/>
        </Dialog>
        
        <GraphBox>
            <Chart id={ids[0]} chartType="doughnut" {...chartDevice}/>
        </GraphBox>
        <GraphBox>
            <Chart id={ids[1]} chartType="bar" {...chartGroupPeriod}/>
        </GraphBox>
        <GraphBox>
            <Chart id={ids[2]} chartType="bar" {...chartGroupWeekDay}/>
        </GraphBox>
        <GraphBox>
            <Chart id={ids[3]} chartType="line" {...chartGroupTime}/>
        </GraphBox>

        <TopComponent/>
        </>
    )
}

export default memo(withBreakPoints(Component))