import { Grid, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { 
    ArrowDropUp as ArrowDropUpIcon,
    ArrowDropDown as ArrowDropDownIcon,
    ArrowLeft as ArrowLeftIcon,
    ArrowRight as ArrowRightIcon
} from '@material-ui/icons'
import { withBreakPoints } from "hoc"


const MyButton = withStyles(theme => ({
    root: {
        background: 'white'
    }
}))(props => <Button variant="outlined" size="small"{...props}/>)


const Component = (props) => {
    const { breakpoints, mobile='md', onAdd, onRemove } = props

    const { md, sm, xs } = breakpoints

    // 모바일 화면 사이즈일때
    let isMobile = false
    if (mobile === 'md' && md) isMobile = true
    else if (mobile === 'sm' && sm) isMobile = true
    else if (mobile === 'xs' && xs) isMobile = true

    return (
        <Grid container direction={isMobile ? 'row' : 'column'} alignItems="center" justifyContent="center" spacing={2}>
            <Grid item>
                <MyButton aria-label="move selected right" onClick={onAdd}>
                    {isMobile ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                </MyButton>
            </Grid>
            
            <Grid item>
                <MyButton aria-label="move selected left" onClick={onRemove}>
                    {isMobile ? <ArrowDropUpIcon/> : <ArrowLeftIcon/>}
                </MyButton>
            </Grid>
        </Grid>
    )
}

export default withBreakPoints(Component)