/*
** 개인별 응답 화면
**/

import { memo, useEffect, useCallback, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { INIT as INITPERSONAL  } from 'reducer/analysis/rows/personal'
import { INIT as INITPERSONALIDS  } from 'reducer/analysis/rows/personalIds'
import { SurveyEndingProps } from 'gql/survey_ending'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import HeadComponent from './Head/Component'
import BodyComponent from './Body'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import InfoComponent from './Info'


export interface Props {
    questions: SurveyQuestionModuleProps[];
    endings: SurveyEndingProps[];
    breakpoints: BreakPointsProps
}

export type DivRef = null | HTMLDivElement;
export type HideQuestion = boolean;

export type OnChangeHideQuestion = (bool: HideQuestion) => void;

const useStyles = makeStyles(theme => ({
    root: {
        width: '50%', 
        maxWidth: 740,
        height: `calc(100vh - 16em)`, 
        paddingLeft: 5,
    },
    paper: {
        width: '100%', 
        height: `100%`, 
        position: 'sticky', 
        top: 0
    }
}))

function PersonalComponent(props: Props) {
    const { questions, endings, breakpoints } = props

    const { xs } = breakpoints
    
    const classes = useStyles()

    const dispatch = useDispatch()

    const divRef = useRef<DivRef>(null)

    const [ hideQuestion, setHideQuestion ] = useState(false)

    const state = useSelector((states: RootState) => states.analysisRowsPersonal)
    const stateIds = useSelector((states: RootState) => states.analysisRowsPersonalIds)

    const { answer, files } = state
    const { ids } = stateIds

    const id = answer ? Number(answer.id.choice[0]) : 0

    const handleClose = useCallback(() => {
        dispatch({ type: INITPERSONAL })
    }, [dispatch])

    const handleChangeHideQuestion: OnChangeHideQuestion = useCallback((bool) => {
        setHideQuestion(bool)
    }, []) 

    // 리스트에 개별 응답 번호가 없다면 강제로 닫는다.
    useEffect(() => {
        if (!ids.includes(id)) {
            dispatch({ type: INITPERSONAL })
        }
    }, [id, ids, dispatch])

    // 모바일에서는 안보여준다
    useEffect(() => {
        if (xs) {
            dispatch({ type: INITPERSONAL })
        }
    }, [xs, dispatch])

    useEffect(() => {
        return () => {
            dispatch({ type: INITPERSONAL })
            dispatch({ type: INITPERSONALIDS })
        }
    }, [dispatch])

    if (!answer) return null

    return (
        <div className={classes.root} >
            <Paper variant="outlined" className={classes.paper}>
                <HeadComponent id={id} hideQuestion={hideQuestion} divRef={divRef} onClose={handleClose} onChangeHideQuestion={handleChangeHideQuestion} />
                <div ref={divRef} style={{ height: '100%' }}>
                    <InfoComponent answer={answer} />
                    <BodyComponent answer={answer} files={files} questions={questions} endings={endings} hideQuestion={hideQuestion} />
                </div>
            </Paper>
        </div>
    )
}

export default memo(withBreakPoints(PersonalComponent))

