import { useIntl } from 'react-intl'
import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons'
import { MyColor, MyPosition, MyListItemIcon, MyIconButton, MyCodeItem, Icon } from 'styled/Survey/Share/Embed'
import Tooltip from 'styled/Custom/Tooltip'
import PopoverImageComponent from './PopoverImage'
import PopoverSizeComponent from './PopoverSize'

export default (props) => {
    const { popover, on } = props
    const { shape, color, position, size } = popover

    const { formatMessage: f } = useIntl()

    return (
        <>
        <List>
            <ListItem>
                <ListItemText>{f({id: 'component.Survey.Create.Share.Popover.lists.0'})}</ListItemText>
                <MyListItemIcon>
                    <MyIconButton onClick={() => on.changeShape('add')}>
                        <Icon shape="add" color={shape === 'add' ? "primary" : "disabled"}/>
                    </MyIconButton>
                    <MyIconButton onClick={() => on.changeShape('text')}>
                        <Icon shape="text" color={shape === "text" ? "primary" : "disabled"}/>
                    </MyIconButton>
                    <MyIconButton onClick={() => on.changeShape('widget')}>
                        <Icon shape="widget" color={shape === "widget" ? "primary" : "disabled"}/>
                    </MyIconButton>
                    <MyIconButton onClick={() => on.changeShape('rate')}>
                        <Icon shape="rate" color={shape === "rate" ? "primary" : "disabled"}/>
                    </MyIconButton>
                </MyListItemIcon>
            </ListItem>
            <Divider/>
            <ListItem>
                <ListItemText>{f({id: 'component.Survey.Create.Share.Popover.lists.1'})}</ListItemText>
                <MyListItemIcon>
                    <MyColor onClick={(e) => on.openColorPicker(e)} color={color}/>
                </MyListItemIcon>
            </ListItem>
            <Divider/>
            <PopoverImageComponent onChange={on.changeImageSrc}/>
            <Divider/>
            <PopoverSizeComponent size={size} onChange={on.changeSize}/>
            <Divider/>
            <ListItem>
                <ListItemText>{f({id: 'component.Survey.Create.Share.Popover.lists.2'})}</ListItemText>
                <MyListItemIcon>
                    <Tooltip title={f({id: 'component.Survey.Create.Share.Popover.position.0'})} placement="top">
                        <span>
                        <MyPosition onClick={() => on.changePosition('bottom-right')} selected={position === 'bottom-right'}>
                            <FiberManualRecordIcon color="disabled" style={{position: 'absolute', right: 0, bottom: 0}} fontSize="small"/>
                        </MyPosition>
                        </span>
                    </Tooltip>
                    <Tooltip title={f({id: 'component.Survey.Create.Share.Popover.position.1'})} placement="top">
                        <span>
                        <MyPosition onClick={() => on.changePosition('bottom-left')} selected={position === 'bottom-left'}>
                            <FiberManualRecordIcon color="disabled" style={{position: 'absolute', left: 0, bottom: 0}}  fontSize="small"/>
                        </MyPosition>
                        </span>
                    </Tooltip>
                    <Tooltip title={f({id: 'component.Survey.Create.Share.Popover.position.2'})} placement="top">
                        <span>
                        <MyPosition onClick={() => on.changePosition('top-right')} selected={position === 'top-right'}>
                            <FiberManualRecordIcon color="disabled" style={{position: 'absolute', right: 0, top: 0}} fontSize="small"/>
                        </MyPosition>
                        </span>
                    </Tooltip>
                    <Tooltip title={f({id: 'component.Survey.Create.Share.Popover.position.3'})} placement="top">
                        <span>
                        <MyPosition onClick={() => on.changePosition('top-left')} selected={position === 'top-left'}>
                            <FiberManualRecordIcon color="disabled" style={{position: 'absolute', left: 0, top: 0}} fontSize="small"/>
                        </MyPosition>
                        </span>
                    </Tooltip>
                </MyListItemIcon>
            </ListItem>
            <Divider/>
            <MyCodeItem onClick={on.openCode}>
                {f({id: 'component.Survey.Create.Share.Popover.lists.3'})}
            </MyCodeItem>
        </List>
        </>
    )
}