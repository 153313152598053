import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { JumpNo } from 'gql/jump'
import Tooltip from '@material-ui/core/Tooltip'
import EditAttributesIcon from '@material-ui/icons/EditAttributesRounded'

interface Props {
    section: 'default' | 'rules';
    no: JumpNo;
}

function ItemFocusComponent(props: Props) {
    const { section, no } = props

    const jumpFocus = useSelector((state: RootState) => state.surveyCreateLogicJumpFocus)

    const boolean = `${section}-${no}` === `${jumpFocus.section}-${jumpFocus.no}`

    return (
        <>
        {
            boolean ? (
                <Tooltip title="EDIT">
                    <EditAttributesIcon color="secondary" fontSize="large" style={{position: 'absolute', top: 2, left: 30}}/>
                </Tooltip>
            ) : null
        }
        </>
    )
}

export default ItemFocusComponent