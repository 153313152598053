import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import oc from 'open-color'
import { 
    ListItem, 
    Switch, 
    MenuItem, 
    List,
    ListItemIcon, 
    ListItemText, 
    ListItemSecondaryAction,
    IconButton,
    Typography,
    FormControlLabel,
    Checkbox,
    Tabs,
    Tab,
    Paper,
    Select,
    Radio,
    RadioGroup
} from '@material-ui/core'
import { 
    LooksOne as LooksOneIconm, 
    FiberManualRecord as FiberManualRecordIcon, 
    SignalCellular3Bar as SignalCellular3BarIcon,
    HelpOutlineOutlined as HelpOutlineOutlinedIcon
} from '@material-ui/icons'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import CreateBox from 'styled/Survey/CreateBox'
import { ListItemSub } from 'styled/Survey/Setting/ListItem'
import Tooltip from 'styled/Custom/Tooltip'
import DateFnsUtils  from '@date-io/date-fns'
import { ko } from 'date-fns/locale'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import useTokenInfo from 'hooks/useTokenInfo'
import { SelectStyled } from 'styled/Input'
import MuiMessageComponent from './Message'
import EndButtonComponent from './EndButton'
import CutPeopleComponent from './CutPeople'
import IpBlocksInputComponent from './IpBlocksInput'

const useStyles = makeStyles({
    toolbar: {
        background: 'white'
    },
    support: {
        justifyContent: 'flex-end'
    },
    list: {
        marginBottom: 50
    }
})

const MyTabs = withStyles(theme => ({
    root: {
        position: 'sticky',
        top: 0,
        zIndex: 1
    }
}))(props => {
    return (
        <Tabs
            indicatorColor="primary" 
            textColor="primary" 
            variant="fullWidth" 
            {...props}
        />
    )
})

const EndDateStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    & > * + * {
        margin-left: 1em;
    }
`

function Component(props) {
    const { formatMessage: f } = useIntl()
    
    const classes = useStyles()

    const { 
        survey_no, 
        lang, 
        progress, 
        prev_button, 
        just,
        allow_ips, 
        end_date_used, 
        end_date, 
        open, 
        restart,
        cut_people, 
        cut_people_type, 
        end_button,
        end_button_text,
        end_button_link,
        alarm,
        ip_blocks,
        ip_blocks_list,
        first_question,
        mboxMessage, 
        on, 
        onViewExplain,
        refetch
    } = props

    const [ tab, setTab ] = useState(0)

    const [ help, setHelp ] = useState('')

    const { lang: _lang } = useSelector(state => state.lang)

    const token = useTokenInfo()

    let newAlarm = alarm

    // 각 등급에 맞는 시간 설정값이 아니라면 강제로 처리
    if (token.levels === 'level10' && alarm === 5) newAlarm = 30
    else if (token.levels === 'level1000' && (alarm === 30 || alarm === 5)) newAlarm = 60
    else if (token.levels === 'level0' && alarm !== 0) newAlarm = 0

    const handleHelp = useCallback((value) => {
        const sHelp = value === help ? '' : value
        setHelp(sHelp)
        onViewExplain(sHelp)
    }, [help, onViewExplain])

    const HelpAction = useCallback(({ value }) => (
        <ListItemIcon style={{marginRight: 25}}>
            <IconButton size="small" color={help === value ? 'primary' : 'default'} onClick={() => handleHelp(value)}><HelpOutlineOutlinedIcon fontSize="small"/></IconButton>
        </ListItemIcon>
    ), [help, handleHelp])


    const MessageComponent = useCallback(({ type, value, initial }) => (
        <MuiMessageComponent survey_no={survey_no} lang={lang} type={type} value={value} initial={initial}/>
    ), [survey_no, lang])

    const handleChangeTab = (e, newValue) => {
        setTab(newValue)
        refetch()
    }

    return (
        <CreateBox>
            <Paper >
            <MyTabs value={tab} onChange={handleChangeTab}>
                <Tab label={f({id: 'component.Survey.Create.Publish.Component.subheader.1'})}/>
                <Tab label={f({id: 'component.Survey.Create.Publish.Component.subheader.2'})}/>
            </MyTabs>
            </Paper>
            <List className={classes.list}>
            {
                tab === 1 ? (
                    <>
                    <ListItem>
                        <ListItemText>
                            Language
                            <Typography variant="caption">{f({id: 'component.Survey.Create.Publish.Component.caption.0'})}</Typography>
                        </ListItemText>
                        <HelpAction value="lang"/>
                        <ListItemSecondaryAction>
                            <Select value={lang} style={{width: 80}} input={<SelectStyled fontSize={12} padding="6px 12px 6px 8px" backgroundcolor="white"/>} onChange={on.changeLang}>
                                <MenuItem value="ko">Korean</MenuItem>
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="jp">Japanese</MenuItem>
                                <MenuItem value="vi">Vietnamese</MenuItem>
                                <MenuItem value="ru">Russian</MenuItem>
                            </Select>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.1'})}</ListItemText>
                        <HelpAction value="progress"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={progress !== 'nothing'} onChange={(e) => on.changeProgress(e.target.checked ? 'text' : 'nothing')}/>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItemSub open={progress !== 'nothing'}>
                        <ToggleButtonGroup className={classes.toolbar} exclusive value={progress} onChange={(e) => on.changeProgress(e.currentTarget.value)}>
                            <Tooltip title={f({id: 'component.Survey.Create.Publish.Component.progress.0'})}>
                                <ToggleButton edge="start" value='text'>
                                    <LooksOneIconm color={progress === 'text' ? 'primary' : 'disabled'}/>    
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title={f({id: 'component.Survey.Create.Publish.Component.progress.1'})}>
                                <ToggleButton edge="start" value='progress'>
                                    <SignalCellular3BarIcon color={progress === 'progress' ? 'primary' : 'disabled'}/>
                                </ToggleButton>
                            </Tooltip>

                            <Tooltip title={f({id: 'component.Survey.Create.Publish.Component.progress.2'})}>
                                <ToggleButton edge="start" value='dots'>
                                    <FiberManualRecordIcon color={progress === 'dots' ? 'primary' : 'disabled'}/>
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                    </ListItemSub>
            
                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.2'})}</ListItemText>
                        <HelpAction value="prev_button"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={prev_button} onChange={on.changePrevButton}/>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary={f({id: 'component.Survey.Create.Publish.Component.lists.3'})}
                            secondary={f({id: 'component.Survey.Create.Publish.Component.listsSecondary.3'})}
                        />
                        <HelpAction value="just"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={just} onChange={on.changeJust}/>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary={f({id: 'component.Survey.Create.Publish.Component.lists.9'})}
                            secondary={f({id: 'component.Survey.Create.Publish.Component.listsSecondary.9'})}
                        />
                        <HelpAction value="alarm"/>
                        <ListItemSecondaryAction>
                            <Select 
                                value={newAlarm} 
                                style={{width: 80}} 
                                input={<SelectStyled fontSize={12} padding="6px 12px 6px 8px" backgroundcolor="white"/>} 
                                onChange={on.changeAlarm}
                                renderValue={(val) => {
                                    if (val === 0) return f({id: 'component.Survey.Create.Publish.Component.alarm.items.0'})
                                    if (val === 1) return f({id: 'component.Survey.Create.Publish.Component.alarm.items.1'}, {val})
                                    if (val === 5) return f({id: 'component.Survey.Create.Publish.Component.alarm.items.1'}, {val})
                                    if (val === 30) return f({id: 'component.Survey.Create.Publish.Component.alarm.items.1'}, {val})
                                    if (val === 60) return f({id: 'component.Survey.Create.Publish.Component.alarm.items.2'}, {val: val / 60})
                                    return f({id: 'component.Survey.Create.Publish.Component.alarm.items.0'})
                                }}
                            >
                                <MenuItem value={0}>{f({id: 'component.Survey.Create.Publish.Component.alarm.items.0'})}</MenuItem>
                                <MenuItem disabled={token.levels !== 'level100'} value={5}>{f({id: 'component.Survey.Create.Publish.Component.alarm.items.1'}, {val: 5})} <span style={{ color: oc.indigo[6], fontSize: 12, paddingLeft: 5 }}>{f({id: 'component.Survey.Create.Publish.Component.alarm.levels.0'})}</span></MenuItem>
                                <MenuItem disabled={token.levels !== 'level100' && token.levels !== 'level10'} value={30}>{f({id: 'component.Survey.Create.Publish.Component.alarm.items.1'}, {val: 30})}  <span style={{ color: oc.indigo[6], fontSize: 12, paddingLeft: 5 }}>{f({id: 'component.Survey.Create.Publish.Component.alarm.levels.1'})}</span></MenuItem>
                                <MenuItem disabled={token.levels !== 'level100' && token.levels !== 'level10' && token.levels !== 'level1000'} value={60}>{f({id: 'component.Survey.Create.Publish.Component.alarm.items.2'}, {val: 1})} <span style={{ color: oc.indigo[6], fontSize: 12, paddingLeft: 5 }}>{f({id: 'component.Survey.Create.Publish.Component.alarm.levels.2'})}</span></MenuItem>
                            </Select>
                        </ListItemSecondaryAction>
                    </ListItem>
                    </>
                ) : (
                    <>
                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.4'})}</ListItemText>
                        <HelpAction value="open"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary"  checked={open} onChange={on.changeOpen}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItemSub open={!open}>
                        <MessageComponent type="open" value={mboxMessage.open} initial={open}/>
                    </ListItemSub>
                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.6'})}</ListItemText>
                        <HelpAction value="end_date_used"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary"  checked={end_date_used} onChange={on.changeEndDateUsed}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                
                    <ListItemSub open={Boolean(end_date_used)}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={_lang === 'ko' ? ko : ''}>
                            <EndDateStyled>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy.MM.dd"
                                    margin="normal"
                                    label={f({id: 'component.Survey.Create.Publish.Component.end_date'})}
                                    value={end_date}
                 
                                    maxDateMessage=""
                                    onChange={on.changeEndDate}
                                    onClose={on.saveEndDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}

                                    disablePast={true}
                                    minDateMessage=""
                                />
                                <KeyboardTimePicker
                                    margin="normal"
                                    variant="inline"
                                    label={f({id: 'component.Survey.Create.Publish.Component.end_time'})}
                                    value={end_date}
                                    onChange={on.changeEndDate}
                                    onClose={on.saveEndDate}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change time'
                                    }}
                                
                                />
                            </EndDateStyled>
                        </MuiPickersUtilsProvider>
                        <MessageComponent type="end_date" value={mboxMessage.end_date} initial={!Boolean(end_date_used)}/>
                    </ListItemSub>


                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.11'})}</ListItemText>
                        <HelpAction value="first_question"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={Boolean(first_question)} onChange={(e) => on.changeFirstQuestion(e.target.checked ? 1 : 0)}/>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItemSub open={Boolean(first_question)}>
                        <RadioGroup name="position" value={first_question} onChange={(e) => on.changeFirstQuestion(Number(e.target.value))}>
                            <FormControlLabel
                                value={1}
                                control={<Radio color="primary" />}
                                label={f({id: 'component.Survey.Create.Publish.Component.first_question.0'})}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value={2}
                                control={<Radio color="primary" />}
                                label={f({id: 'component.Survey.Create.Publish.Component.first_question.1'})}
                                labelPlacement="end"
                            />
                             <FormControlLabel
                                value={3}
                                control={<Radio color="primary" />}
                                label={f({id: 'component.Survey.Create.Publish.Component.first_question.2'})}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </ListItemSub>


                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.5'})}</ListItemText>
                        <HelpAction value="allow_ips"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={allow_ips} onChange={on.changeAllowIps}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItemSub open={allow_ips}>
                        <FormControlLabel 
                            control={
                                <Checkbox checked={restart} onChange={on.changeRestart}/>
                            } 
                            label={f({id: 'component.Survey.Create.Publish.Component.allow_ips.restart'})} 
                        />
                    </ListItemSub>
                    <ListItemSub open={Boolean(!allow_ips)}>
                        <MessageComponent type="allow_ips" value={mboxMessage.allow_ips} initial={allow_ips}/>
                    </ListItemSub>
                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.7'})}</ListItemText>
                            <HelpAction value="cut_people"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={Boolean(cut_people)} onChange={on.changeCutPeople}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItemSub open={Boolean(cut_people)}>
                        <CutPeopleComponent survey_no={survey_no} cut_people={cut_people} cut_people_type={cut_people_type}>
                            <MessageComponent type="cut_people" value={mboxMessage.cut_people} initial={!Boolean(cut_people)}/>
                        </CutPeopleComponent>
                    </ListItemSub>

                    <ListItem>
                        <ListItemText>{f({id: 'component.Survey.Create.Publish.Component.lists.10'})}</ListItemText>
                            <HelpAction value="ip_blocks"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={Boolean(ip_blocks)} onChange={on.changeIpBlocks}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItemSub open={Boolean(ip_blocks)}>
                        <IpBlocksInputComponent open={Boolean(ip_blocks)} survey_no={survey_no} ip_blocks_list={ip_blocks_list}>
                            <MessageComponent type="ip_blocks" value={mboxMessage.ip_blocks} initial={!Boolean(ip_blocks_list)}/>
                        </IpBlocksInputComponent>
                    </ListItemSub>

                    <ListItem>
                        <ListItemText
                            primary={f({id: 'component.Survey.Create.Publish.Component.lists.8'})}
                            secondary={f({id: 'component.Survey.Create.Publish.Component.listsSecondary.8'})}
                        />
                        <HelpAction value="end_button"/>
                        <ListItemSecondaryAction>
                            <Switch color="primary" checked={end_button} onChange={on.changeEndButton}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItemSub open={end_button}>
                        <EndButtonComponent survey_no={survey_no} lang={lang} end_button={end_button} end_button_text={end_button_text} end_button_link={end_button_link}/>
                    </ListItemSub>
                    </>
                )
            }
            </List>
        </CreateBox>        
    )
}

export default Component