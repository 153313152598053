import { memo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { JumpNo, ID } from 'gql/jump'
import { componentType } from 'utils/analysis'
import MenuItem from "@material-ui/core/MenuItem"
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { OnChangeChildsProps, MySelect } from './ItemRules'

interface Props extends OnChangeChildsProps {
    id: ID;
    jump_rule_child_no: JumpNo;
    sign: number;
}

function getMenuItems(rows: {value: number, text: string}[]) {
    return rows.map(c => (
        <MenuItem key={c.value} value={c.value}>
           {c.text}
        </MenuItem>
    ))
}

function SelectSignQuestionComponent(props: Props) {
    const { id, jump_rule_child_no, sign, onChange } = props

    const { formatMessage: f } = useIntl()

    const { questionsKeyQuestionNo } = useContext(Context)

    // 선택형
    const menuItemModuleArray = [
        { value: 1, text: f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.module.item.0'}) },
        { value: 2, text: f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.module.item.1'}) }
    ]

    // 서술형
    const menuItemTextArray = [
        { value: 1, text: f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.text.item.0'}) },
        { value: 2, text: f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.text.item.1'}) },
        { value: 3, text: f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.text.item.2'}) },
        { value: 4, text: f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.text.item.3'}) },
        { value: 5, text: f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.text.item.4'}) }
    ]

    const question = questionsKeyQuestionNo && questionsKeyQuestionNo[id]

    if (!question) return null

    const [ Q ] = question
    const { __typename } = Q
    const typename = __typename.replace('Module', '')

    const rows = componentType[typename] === 'text' ? menuItemTextArray : menuItemModuleArray

    return (
        <MySelect 
            value={sign} 
            style={{width: 80, marginBottom: 1}} 
            label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignQuestion.label'})} 
            onChange={(e) => {
                onChange('sign', jump_rule_child_no, Number(e.target.value))
            }}
        >
            {
                getMenuItems(rows)
            }
        </MySelect>
    )
}

export default memo(SelectSignQuestionComponent)