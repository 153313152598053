import { ChangeEvent, useState, memo } from 'react'
import { useIntl } from 'react-intl'
import { getSearchCookie, setSearchCookie } from 'ts-utils/summary'
import oc from 'open-color'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { numberFormat } from 'utils'
import { MyInsightColumn, MyInsightValue } from './Component'

interface Props {
    completeCount: number;
    completeAvg: number;
    cookieName: string;
}

const MyBox = withStyles({
    root: {
        position: 'absolute',
        top: 5
    }
})(Box)

const MySelect = withStyles({
    root: {
        fontSize: 14,
        color: oc.gray[8]
    }
})(Select)

export function setCookie(cookieName: string) {
    const value = Number(getSearchCookie(cookieName)) || 0
    setSearchCookie(cookieName, value)

    return value
}

function CompleteComponent(props: Props) {
    const { cookieName, completeCount, completeAvg } = props
    
    const [ kind, setKind ] = useState(setCookie(cookieName))

    const { formatMessage: f } = useIntl()

    function handleChange(e: ChangeEvent<{ value: unknown }>) {
        const value = Number(e.target.value)
        setKind(Number(e.target.value))
        setSearchCookie(cookieName, value)
    }

    return (
        <>
        <MyInsightColumn>
            <MyBox>
                <MySelect value={kind} onChange={handleChange}>
                    <MenuItem value={0}>{f({id: 'component.Survey.Create.Analysis.Insight.Component.title.completeCount'})}</MenuItem>
                    <MenuItem value={1}>{f({id: 'component.Survey.Create.Analysis.Insight.Component.title.completeAvg'})}</MenuItem>
                </MySelect>
            </MyBox>
        </MyInsightColumn>
        <MyInsightValue>
            {
                kind === 0 ? numberFormat(completeCount) : `${completeAvg}%`
            }
        </MyInsightValue>
        </>
    )
}

export default memo(CompleteComponent)