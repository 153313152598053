import { memo } from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { MyList } from '../Lists/ButtonBox'
import HelpComponent from './Help'
import InputAllPointComponent from './InputAllPoint'
import ButtonSaveComponent from './ButtonSave'
import { ContainerBox } from '../ExampleCount/ButtonBox'
import InputInitComponent from './InputInit'

const MyBox = withStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '2px 5px',
        justifyContent: 'space-between',
        '& > * + *': {
            marginLeft: 5
        },
        '& > div:first-child': {
            display: 'flex',
            '& > * + *': {
                marginLeft: 20
            }
        },
        '& > div:last-child': {
            display: 'flex',
            '& > * + *': {
                marginLeft: 20
            }
        },
        [theme.breakpoints.down('xs')]: {
            '& > div:nth-of-type(1) > div:nth-of-type(2)': {
                display: 'none'
            }
        }
    }
}))(Box)

function ButtonBoxComponent() {
    return (
        <ContainerBox>
            <MyList dense disablePadding>
                <MyBox style={{alignItems: 'flex-end'}}>
                    <div>
                        <div>
                            <HelpComponent/>
                        </div>
                        <div><InputAllPointComponent/></div>
                    </div>     
                    <div>
                        <InputInitComponent/>
                        <ButtonSaveComponent/>
                    </div>
                </MyBox>
            </MyList>
        </ContainerBox>
    )
}

export default memo(ButtonBoxComponent)