import { memo, useState, useEffect } from "react"
import { useIntl } from "react-intl"
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import CachedIcon from '@material-ui/icons/Cached'
import Tooltip from 'styled/Custom/Tooltip'
import { IconBox } from "../Buttons"

interface Props {
    xs: boolean;
    loading: boolean;
    refetchMbox: () => void;
    refetchInsight: () => void;
    refetchPageView: () => void;
}

let timer: ReturnType<typeof setTimeout>

function RefreshComponent(props: Props) {
    const { xs, loading, refetchMbox, refetchInsight, refetchPageView } = props

    const [ disabled, setDisabled ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleClick = () => {
        clearTimeout(timer)

        setDisabled(true)

        if (refetchMbox) refetchMbox()
        if (refetchInsight) refetchInsight()
        if (refetchPageView) refetchPageView()
    }

    useEffect(() => {
        if (!loading) setDisabled(false) 
    }, [loading])

    return (
        <>
        {
            xs ? (
                <BottomNavigationAction showLabel label={f({id: "component.Survey.Create.Analysis.Buttons.refresh"})} icon={<CachedIcon />} onClick={handleClick}/>
            ) : (
                <Tooltip title={f({id: 'component.Survey.Create.Analysis.Buttons.refresh'})} placement="top">
                    <IconBox disabled={disabled} onClick={handleClick}>
                        <CachedIcon fontSize="small" />
                    </IconBox>
                </Tooltip>
            )
        }
        </>
    )
}

export default memo(RefreshComponent)