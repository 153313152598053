import { memo, useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'
import { _19, GET_SURVEY_ANALYSIS_SUMMARY_FILES, ViewMode } from 'gql/survey_analysis_summary'
import { GET_LEVELS_IN_SURVEY_NO } from 'gql/survey_mbox'
import CommentsStyled from 'styled/Survey/Analysis/SummaryComment'
import SummaryFileItem from 'styled/Survey/Analysis/SummaryFileItem'
import { getKey } from 'hoc/Survey/Summary'

interface Props {
    survey_no: number;
    id: string; 
    filename: string;
    modules: _19['modules'];
    moduleLen: number;
    viewMode: ViewMode;
}

function DataItemFileUploadListComponent(props: Props) {
    const { survey_no, id, modules, filename, moduleLen, viewMode } = props

    const [ rows, setRows ] = useState<_19['modules']>(modules);

    const [ getData ] = useLazyQuery(GET_SURVEY_ANALYSIS_SUMMARY_FILES, {
        onCompleted: (data) => {
            setRows(prevState => ([
                ...prevState,
                ...data.surveyAnalysisSummaryFiles
            ]))
        }
    })

    const [ getLevels, { data: levelData } ] = useLazyQuery(GET_LEVELS_IN_SURVEY_NO, {
        onCompleted: (data) => {
            console.log(111, data)
        }
    })

    const len = rows.length

    const handleFetch = () => {
        getData({
            variables: {
                survey_no, 
                key: getKey(survey_no), 
                id: String(id), 
                offset: len
            }
        })
    }

    useEffect(() => {
        setRows(modules)
    }, [modules])

    useEffect(() => {
        if (viewMode === ViewMode.Report) {
            getLevels({ 
                variables: { survey_no }
            })
        }
    }, [survey_no, viewMode, getLevels])


    // 공개레포트에서는 levels가 반드시 필요하다
    if (viewMode === ViewMode.Report && !levelData) return null



    return ( 
        <> 
        <CommentsStyled className="_09" id={`scrollableDiv-${id}`}>
            <InfiniteScroll
                dataLength={len}
                next={handleFetch}
                hasMore={moduleLen > len}
                loader={<div>...Loading</div>}
                scrollableTarget={`scrollableDiv-${id}`}
            >
            {
                rows.map((d, j) => {
                    return (
                        <SummaryFileItem 
                            key={d.survey_analysis_users_no} 
                            name={filename} 
                            survey_analysis_users_no={d.survey_analysis_users_no} 
                            filename={d.filename} 
                            src={d.src} 
                            wdate={d.wdate} 
                            viewMode={viewMode}
                            levels={!levelData ? undefined : levelData.levelsInSurveyNo}
                        />
                    )
                })
            }
            </InfiniteScroll>
        </CommentsStyled>
        </>
    )
}

export default memo(DataItemFileUploadListComponent)