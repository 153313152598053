import { memo, useEffect, useRef } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { MyIconButton } from '../Summary/Top'

interface Props {
    onClick: () => void;
    breakpoints: BreakPointsProps;
}

function TopComponent(props: Props) {
    const { breakpoints } = props
    const { xs } = breakpoints

    const refTop = useRef<null | HTMLButtonElement>(null)

    const handleClick = () => {
        scroll.scrollTo(0)
    }

    useEffect(() => {
        function show() {
            if (!refTop) return
            if (!refTop.current) return

            if (xs) {
                refTop.current.style.visibility = 'hidden'
                return
            }

            // pc와 모바일일때 스크롤 객체가 다르다
            const top = (document.documentElement.scrollTop || document.body.scrollTop)

            if (top > 1000) refTop.current.style.visibility = 'visible'
            else refTop.current.style.visibility = 'hidden'            
        }

        // 모바일olny은 따른 컴포넌트에서 보여준다.
      
        window.addEventListener('scroll', show)
        
    }, [xs, refTop])

    return (
        <MyIconButton onClick={handleClick} ref={refTop}>
            <ArrowUpwardIcon/>
        </MyIconButton>
    )
}

export default withBreakPoints(memo(TopComponent))