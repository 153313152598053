import { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getWidth } from './TableHead'

interface Props {
    field: string;
    label: string;
}

const useStyles = makeStyles(theme => ({
    text: (props: { width: number }) => {
        const { width } = props

        return {
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width,
            fontSize: 12
        }
    }
}))

function TableHeadCellTextComponent(props: Props) {
    const { field, label } = props

    const classes = useStyles({ width: getWidth(field) })
    
    return (
        <Typography className={classes.text} component="span" variant="body2">{label}</Typography>
    )
}

export default memo(TableHeadCellTextComponent)