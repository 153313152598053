import { memo } from 'react'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { SurveyEditCommentStyled } from 'styled/Input'
import useTextFiled, { Props as UseProps } from '../useChangeTextField'

interface Props {
    button_name: string;
    onChange: UseProps['onChange'];
}


function ButtonNameComponent(props: Props) {
    const { button_name, onChange } = props

    const [ value, setValue ] = useTextFiled({type: 'button_name', value: button_name, onChange})

    const { formatMessage: f } = useIntl()

    return (
        <SurveyEditCommentStyled
            defaultValue={value} 
            inputProps={{
                maxLength: getMaxlength('component.Survey.Create.Thanks.Edit.ButtonEnding.button_name'),
                placeholder: f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.placeholder'})
            }}
            name="button_name"
            helper={f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.button_name_helper'})}
            onChange={setValue}
        />
    )
}

export default memo(ButtonNameComponent)