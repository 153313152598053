import { memo } from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { JumpNo, Conditiond } from 'gql/jump'
import { OnChangeChildsProps } from './ItemRules'

interface Props extends OnChangeChildsProps {
    jump_rule_child_no: JumpNo;
    conditiond: Conditiond;
}

function ButtonSignComponent(props: Props) {
    const { jump_rule_child_no, conditiond, onChange } = props

    return (
        <ButtonGroup size="small" variant="contained" disableElevation>
            <Button color={conditiond === Conditiond.And ? 'primary' : 'default'} onClick={() => onChange('conditiond', jump_rule_child_no, Conditiond.And)}>AND</Button>
            <Button color={conditiond === Conditiond.Or ? 'primary' : 'default'} onClick={() => onChange('conditiond', jump_rule_child_no, Conditiond.Or)}>OR</Button>
        </ButtonGroup>
    )
}

export default memo(ButtonSignComponent)