import { memo, useState, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CHANGE } from 'reducer/analysis/filter/saveData'
import { 
    SurveyQuestionModuleProps,  
    _01Module, 
    _03Module,
    _03Answer, 
    _03Option, 
    _05Module, 
    _05Option, 
    _06Module, 
    _06Answer, 
    _06Option, 
    _07Module, 
    _07Option, 
    _08Module, 
    _08Answer, 
    _08Option, 
    _09Option, 
    _11Answer, 
    _11Module, 
    _11Option, 
    _12Module, 
    _14Module, 
    _14Option, 
    _15Module, 
    _15Answer, 
    _15Option,
    _17Module, 
    _17Answer, 
    _18Module, 
    _18Answer, 
    _21Module,
    _21Option
} from 'gql/survey_question_modules'
import { SurveyEndingProps } from 'gql/survey_ending'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'
import HelpIcon from '@material-ui/icons/Help'
import { createKey } from 'hoc/Survey/RowsPaging'
import Dialog from 'styled/Survey/Live/Dialog'
import ListComponent from './List'
import SaveComponent from './Save'
import DeleteAllButtonComponent from './DeleteAllButton'
import { OnChangeStartPoint } from 'container/Survey/Create/NewAnalysis/Box'

interface Props {
    survey_no: number;
    questions: SurveyQuestionModuleProps[];
    endings: SurveyEndingProps[];
    onChangeStartPoint?: OnChangeStartPoint;
}

export type Choice = {
    id: number; 
    text: string;
    src?: string;
}

export interface QuestionOptionProps {
    pure_question: string;
    text?: string;
    choices?: Choice[];
    sign: string;
    value: (string | number)[] | string; 
}

export type QuestionOption = {[key: string]: QuestionOptionProps}

export type OnSave = (data: QuestionOption) => void;

// 필터 셀렉트 question options 만들기
const makeQuestionOptions = (f: any, questions: SurveyQuestionModuleProps[], endings: SurveyEndingProps[], storageValue: string | null ): QuestionOption => {

    const values = storageValue ? JSON.parse(storageValue) : {}
    
    const len = questions.length
    const newQuestions: any = {}

    for (let i=0; i<len; i++) {
        const { _question, __typename, _modules, _answers, _option } = questions[i]
        const { survey_question_no, pure_question } = _question
        
        const typename = __typename.replace('Module', '')
        const key = `${typename}-${survey_question_no}`

        switch(typename) {
            case '_01': 
            case '_02': {
                // 보기 선택 
                // 기존 셋팅된 값이 있는지
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _modules.map((c: _01Module) => ({ id: c.survey_module_no, text: c.answer }))
                const choiceNos = choices.map(c => c.id)

                newQuestions[key] = {
                    pure_question,
                    choices,
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                // 보기별 코멘트
                const commentChoices = _modules.filter((c: _01Module) => c.is_comment)
                const commentLen = commentChoices.length

                for (let j=0; j<commentLen; j++) {
                    const { survey_module_no, answer } = commentChoices[j] as _01Module
                    const commentKey = `${key}-${survey_module_no}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        text: answer,
                        sign,
                        value
                    }
                }

                break
            }
            case '_03':
            case '_04': {
                const rowLen = _modules.length
                const choices = _answers.map((c: _03Answer) => ({ id: c.survey_module_answer_no, text: c.answer }))
                const choiceNos = choices.map(c => c.id)

                // 행마다 선택 가능하게 처리
                for (let j=0; j<rowLen; j++) {
                    const { survey_module_no, column } = _modules[j] as _03Module
                    const rowKey = `${key}-${survey_module_no}`

                    const { sign, value } = values[rowKey] || { sign: 'include', value: [] }

                    newQuestions[rowKey] = {
                        pure_question,
                        text: column,
                        choices,
                        sign,
                        value: value.filter((c: number) => choiceNos.includes(c))
                    }
                }

                const { is_comment } = _option as _03Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }
            case '_05': {
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _modules.map((c: _05Module) => ({ id: c.survey_module_no, text: c.indexs }))
                const choiceNos = choices.map(c => c.id)

                newQuestions[key] = {
                    pure_question,
                    choices: _modules.map((c: _05Module) => ({ id: c.survey_module_no, text: c.indexs })),
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                const { is_comment } = _option as _05Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }
            case '_06': {
                const rowLen = _modules.length
                const choices = _answers.map((c: _06Answer) => ({ id: c.survey_module_answer_no, text: c.indexs }))
                const choiceNos = choices.map(c => c.id)

                // 행마다 선택 가능하게 처리
                for (let j=0; j<rowLen; j++) {
                    const { survey_module_no, column } = _modules[j] as _06Module
                    const rowKey = `${key}-${survey_module_no}`

                    const { sign, value } = values[rowKey] || { sign: 'include', value: [] }

                    newQuestions[rowKey] = {
                        pure_question,
                        text: column,
                        choices,
                        sign,
                        value: value.filter((c: number) => choiceNos.includes(c))
                    }
                }

                const { is_comment } = _option as _06Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }
            case '_07': {
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _modules.map((c: _07Module) => ({ id: c.survey_module_no, text: `${c.indexs}. ${c.answer}` }))
                const choiceNos = choices.map(c => c.id)

                newQuestions[key] = {
                    pure_question,
                    choices: _modules.map((c: _07Module) => ({ id: c.survey_module_no, text: `${c.indexs}. ${c.answer}` })),
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                const { is_comment } = _option as _07Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }
            case '_08': {
                const rowLen = _modules.length
                const choices = _answers.map((c: _08Answer) => ({ id: c.survey_module_answer_no, text: `${c.indexs}. ${c.answer}`}))
                const choiceNos = choices.map(c => c.id)

                // 행마다 선택 가능하게 처리
                for (let j=0; j<rowLen; j++) {
                    const { survey_module_no, column } = _modules[j] as _08Module
                    const rowKey = `${key}-${survey_module_no}`

                    const { sign, value } = values[rowKey] || { sign: 'include', value: [] }

                    newQuestions[rowKey] = {
                        pure_question,
                        text: column,
                        choices,
                        sign,
                        value: value.filter((c: number) => choiceNos.includes(c))
                    }
                }

                const { is_comment } = _option as _08Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }
            case '_09': {
                const { type } = _option as _09Option

                const { sign, value } = values[key] || { sign: 'equal', value: '' }

                newQuestions[key] = {
                    pure_question,
                    type,
                    sign,
                    value
                }

                break
            }
            case '_10': {
                const { sign, value } = values[key] || { sign: 'equal', value: '' }

                newQuestions[key] = {
                    pure_question,
                    sign,
                    value
                }
                
                break
            }
            case '_11': {
                // 1순위 부터 순서대로 저장
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _answers.map((c: _11Answer) => ({ id: c.survey_module_answer_no, text: c.indexs }))
                const choiceNos = choices.map(c => c.id)
                
                newQuestions[key] = {
                    pure_question,
                    choices,
                    ranks: _modules.map((c: _11Module) => ({ id: c.survey_module_no, indexs: c.indexs })),
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                const { is_comment } = _option as _11Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`
                    
                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }
            case '_12': 
            case '_13': {
                // 보기 선택 
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _modules.map((c: _12Module) => ({ id: c.survey_module_no, text: c.answer, src: c.src }))
                const choiceNos = choices.map(c => c.id)

                newQuestions[key] = {
                    pure_question,
                    choices,
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                // 보기별 코멘트
                const commentChoices = _modules.filter((c: _12Module) => c.is_comment)
                const commentLen = commentChoices.length

                for (let j=0; j<commentLen; j++) {
                    const { survey_module_no, answer } = commentChoices[j] as _12Module
                    const commentKey = `${key}-${survey_module_no}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        answer,
                        sign,
                        value
                    }
                }

                break
            }
            case '_14': {
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _modules.map((c: _14Module) => ({ id: c.survey_module_no, text: c.indexs }))
                const choiceNos = choices.map(c => c.id)

                newQuestions[key] = {
                    pure_question,
                    choices,
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                const { is_comment } = _option as _14Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }
            case '_15': {
                const rowLen = _modules.length
                const choices = _answers.map((c: _15Answer) => ({ id: c.survey_module_answer_no, text: c.indexs }))
                const choiceNos = choices.map(c => c.id)

                // 행마다 선택 가능하게 처리
                for (let j=0; j<rowLen; j++) {
                    const { survey_module_no, column } = _modules[j] as _15Module
                    const rowKey = `${key}-${survey_module_no}`

                    const { sign, value } = values[rowKey] || { sign: 'include', value: [] }

                    newQuestions[rowKey] = {
                        pure_question,
                        text: column,
                        choices,
                        sign,
                        value: value.filter((c: number) => choiceNos.includes(c))
                    }
                }

                const { is_comment } = _option as _15Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }

            case '_17': {
                // 1순위 부터 순서대로
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _answers.map((c: _17Answer) => ({ id: c.survey_module_answer_no, text: c.answer }))
                const choiceNos = choices.map(c => c.id)

                newQuestions[key] = {
                    pure_question,
                    choices,
                    ranks: _modules.map((c: _17Module) => ({ id: c.survey_module_no, indexs: c.indexs })),
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                // 보기별 코멘트
                const commentChoices = _answers.filter((c: _17Answer) => c.is_comment)
                const commentLen = commentChoices.length

                for (let j=0; j<commentLen; j++) {
                    const { survey_module_answer_no, answer } = commentChoices[j] as _17Answer
                    const commentKey = `${key}-${survey_module_answer_no}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        text: answer,
                        sign,
                        value
                    }
                }

                break
            }

            case '_18': {
                // 1순위 부터 순서대로
                const choices = _answers.map((c: _18Answer) => ({ id: c.survey_module_answer_no, text: c.answer, src: c.src }))
                const choiceNos = choices.map(c => c.id)

                const { sign, value } = values[key] || { sign: 'include', value: [] }
                newQuestions[key] = {
                    pure_question,
                    choices,
                    ranks: _modules.map((c: _18Module) => ({ id: c.survey_module_no, indexs: c.indexs })),
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                // 보기별 코멘트
                const commentChoices = _answers.filter((c: _18Answer) => c.is_comment)
                const commentLen = commentChoices.length

                for (let j=0; j<commentLen; j++) {
                    const { survey_module_answer_no, answer, src } = commentChoices[j] as _18Answer
                    const commentKey = `${key}-${survey_module_answer_no}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        text: answer,
                        src,
                        sign,
                        value
                    }
                }

                break
            }

            case '_20': {
                const { sign, value } = values[key] || { sign: 'equal', value: '' }

                newQuestions[key] = {
                    pure_question,
                    sign,
                    value
                }

                break
            }

            case '_21': {
                // 보기 선택 
                const { sign, value } = values[key] || { sign: 'include', value: [] }
                const choices = _modules.map((c: _21Module) => ({ id: c.survey_module_no, text: c.answer }))
                const choiceNos = choices.map(c => c.id)

                newQuestions[key] = {
                    pure_question,
                    choices: _modules.map((c: _21Module) => ({ id: c.survey_module_no, text: c.answer })),
                    sign,
                    value: value.filter((c: number) => choiceNos.includes(c))
                }

                const { is_comment } = _option as _21Option

                if (is_comment) {
                    const commentKey = `${key}-is_comment`

                    const { sign, value } = values[commentKey] || { sign: 'equal', value: '' }

                    newQuestions[commentKey] = {
                        pure_question,
                        sign,
                        value
                    }
                }

                break
            }

            default: {
                break
            }
        }
    }

    const endingLen = endings.length

    // 완료글이 1개 일때는 필요 없음
    if (endingLen <= 1) return newQuestions

    const { sign: endingSign, value: endingValue } = values['_ending'] || { sign: 'include', value: [] }

    newQuestions['_ending'] = {
        
        pure_question: f({id: "component.Survey.Create.Menu.Component.subheader.thanks.end"}),
        choices: endings.map((c) => ({ id: c.survey_ending_no, text: c.pure_message })),
        sign: endingSign,
        value: endingValue
    }

    return newQuestions
}

// 빈값
export const notingQuestionOption = { pure_question: "", sign: "", value: "" }

// 값이 있는지 체크
const getDefaultQuestionOptions = (questionOptions: QuestionOption) => {
    // 저장된 값이 있으면..
    const defaultQuestionOptions: QuestionOption = {}
    const keys = Object.keys(questionOptions)

    const len = keys.length
    for (let i=0; i<len; i++) {
        const key = keys[i]

        const self = questionOptions[key]

        if (typeof self.value === 'string') {
            if (self.value) defaultQuestionOptions[key] = self
        } else {
            if (self.value.length > 0) defaultQuestionOptions[key] = self
        }
    }

    // 저장된 값이 없다면 default
    if (Object.keys(defaultQuestionOptions).length === 0) {
        defaultQuestionOptions[""] = notingQuestionOption
    }

    return defaultQuestionOptions
}


// 코멘트 문항인지 판단
export const getIsComment = (key: string) => {
    return /is_comment/.test(key)
}

// localstroage값이 유효한지 체크 (저장 후 문항 및 보기 삭제)
const getRealData = (questionOptions: QuestionOption, storageName: string) => {
    const item = localStorage.getItem(storageName)
 
    if (!item) return {}

    const newData: { [key: string]: { sign: string, value: string | (string | number)[] }} = {}
    const obj = JSON.parse(String(localStorage.getItem(storageName)))
    const keys = Object.keys(obj)
    const len = keys.length
    for (let i=0; i<len; i++) {
        const key = keys[i]

        // localstroage 값
        const self = obj[key]

        // 실제값
        const real = questionOptions[key]
        
        if (!self) continue
        
        // 문항이 삭제되었다면..
        if (!real) continue

        // 주관식, 코멘트
        if (typeof self.value === 'string') {
            newData[key] = self
            continue
        }

        // 보기 선택 값이 있다면
        if (real.value.length > 0) {
            newData[key] = self
        }
    }

    return newData
}

const useStyles = makeStyles(theme => ({
    button: {
        background: 'white',
        border: '1px solid #CED4DA',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))

function FilterComponent(props: Props) {
    const { survey_no, questions, endings, onChangeStartPoint } = props

    const { formatMessage: f } = useIntl()
    
    const dispatch = useDispatch()

    const classes = useStyles()

    const [ open, setOpen ] = useState(false)

    // 필터
    const localStorageName = `filter-2403-${survey_no}`

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSave: OnSave = useCallback((data) => {
        const newData: { [key: string]: { sign: string, value: string | (string | number)[] }} = {}
        const keys = Object.keys(data)
        const len = keys.length

        for (let i=0; i<len; i++) {
            const key = keys[i]

            if (!key) continue

            const { sign, value } = data[key]

            newData[key] = { sign, value }
        }

        // 빈값인지 체크 
        
        // 응답 데이터 가져오는 키값을 변경하여 필터조건에 맞게 새로은 data를 json파일로 DB 저장 / 이걸 하지 않으면 기존 저장된 DB에서 가져온다..
        createKey(survey_no) 

        localStorage.setItem(localStorageName, JSON.stringify(newData))
        dispatch({ type: CHANGE, data: newData })
        if (onChangeStartPoint) onChangeStartPoint(0)
        setOpen(false)
    }, [survey_no, localStorageName, dispatch, onChangeStartPoint])

    const questionOptions = makeQuestionOptions(f, questions, endings, localStorage.getItem(localStorageName))

    // 디폴트 selectQuestionOptions
    const defaultQuestionOptions = getDefaultQuestionOptions(questionOptions)

    const realData = getRealData(questionOptions, localStorageName)

    // 세이브된 데이터 dispatch 
    useEffect(() => {
        dispatch({ type: CHANGE, data: realData })
    }, [ realData, dispatch ])

    return (
        <>
        <Button 
            variant="outlined" 
            color="default" 
            className={classes.button}
            style={{ background: 'white' }}
            size="small" 
            startIcon={<FilterListRoundedIcon fontSize="small" />} 
            endIcon={<DeleteAllButtonComponent isFilter={Object.keys(realData).length > 0} localStorageName={localStorageName} onChangeStartPoint={onChangeStartPoint} />}
            onClick={handleOpen}
        >
            {f({id: 'component.Survey.Create.Analysis.Filter.Component.button.filter'})}
        </Button>
        <Dialog open={open} fullWidth={true} fullScreen={true} maxWidth="md" onClose={() => {}}>
            <Container>
                <DialogTitle style={{ paddingLeft: 0, display: 'flex', alignItems: 'center' }}>
                    {f({id: 'component.Survey.Create.Analysis.Filter.Component.title'})}
                    <IconButton size="small" style={{ marginLeft: 3, marginBottom: 3 }} onClick={() => window.open('https://www.listovey.com/support/help/5b452e84-16c1-4ba1-886a-517c8564b3df', '_blank')}><HelpIcon fontSize="small" /></IconButton>
                </DialogTitle>
            </Container>
            <DialogContent>
                <Container style={{ minHeight: '70vh', paddingBottom: 300 }}>
                {
                    open && <ListComponent questionOptions={questionOptions} defaultQuestionOptions={defaultQuestionOptions} />
                }
                </Container>
            </DialogContent>
          
                <Container>
                <DialogActions>
                    <SaveComponent defaultQuestionOptions={defaultQuestionOptions} onSave={handleSave} />
                    <Button size="large" onClick={handleClose}>
                        {f({id: 'component.Survey.Create.Analysis.Filter.Component.button.close'})}
                    </Button>
                    </DialogActions>
                </Container>
           
        </Dialog>
        </>
    )
}

export default memo(FilterComponent)
