/**
 * ppt 다운로드
 */
import { useIntl } from 'react-intl'
import oc from 'open-color'
import pptxgen from 'pptxgenjs'
import { getPptLayout, numberFormat } from 'utils'
import { getDownloadTitle } from 'utils/analysis'
import { getSearchCookie } from 'ts-utils/summary'
import fixedWidthString from 'fixed-width-string'
import moment from 'moment'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import GetAppIcon from '@material-ui/icons/GetApp'
import useSurveyTitle from 'hooks/useSurveyTitle'
import Tooltip from "styled/Custom/Tooltip"
import { getDateName } from 'styled/Survey/Analysis/DateButton'
import { IconBox } from "../Buttons"
import { Props as ParentProps } from './Buttons2'

interface Props {
    ids: ParentProps['ids'];
    insightData: ParentProps['insightData'];
    pageViewData: ParentProps['pageViewData'];
    deviceType: ParentProps['deviceType'];
    date: ParentProps['date'];
    mbox: ParentProps['mbox'];
    completeCookieName: ParentProps['completeCookieName'];
    refererCityCookieName: ParentProps['refererCityCookieName'];
    xs: boolean;
}

type MakeProps = {
    text: string;
    x: number;
    y: number;
}

const getPPT = (props: any) => {
    const { f, title: ctitle, insightProperty, chartData, pageData } = props
    const { connectCount, startCount, completeAvg, completeCount, completeCookieName, completeTime, deviceType } = insightProperty

    const { ids } = chartData
    const { method, date, pageViewData, delay } = pageData
    
    let slide: any = {}

    let options = {} 
    let options1 = {} 
    let options2 = {} 
    let options3 = {} 
    let options4 = {} 
    let rows = []

    
    const pres = new pptxgen()

    /* 인트로 */
    const { layout, defineSlideMaster } = getPptLayout()

    let masterName = 'intro'
    pres.layout = layout
    pres.defineSlideMaster({
        ...defineSlideMaster,
        title: masterName
    })

    slide = pres.addSlide({ masterName })

    options = { x: 1, y: 1, color: '000000', bold: true, fontSize: 23 }
    slide.addText('Insight', options)
    options = { x: 1, y: 1.5, color: '000000', bold: true, fontSize: 22, align: 'left', valign: 'top' }
    slide.addText(ctitle, options)

    const today = moment().format('YYYY.MM.DD')
    options = { x: 1.2, y: 6, color: '000000', fontSize: 14 }
    slide.addText(today, options)


    /* 통계 */
    masterName = 'insight'
    pres.defineSlideMaster({
        ...defineSlideMaster,
        title: masterName,
        slideNumber: { x: '90%', y: "96%", fontSize: 10 }
    })

    slide = pres.addSlide({ masterName })

    function makeTitle(props: MakeProps) {
        const { text, x, y } = props
        const options = { x, y, color: '363636', align: 'left', fontSize: 12 }
        slide.addText(text, options)
    }

    function makeTitleRight(props: MakeProps) {
        const { text, x, y } = props
        const options = { x, y, color: '363636', align: 'left', fontSize: 8, underline: true }
        slide.addText(text, options)
    }

    // 날찌 및 디바이스 검색 내용
    const dateValue = getDateName(f, date, true)

    rows = [
        [
            dateValue,
            f({id: `component.Survey.Create.Analysis.Insight.Component.select.deviceType.${deviceType}`})
        ]
    ]

    options = { x:1, y: 1, w: 4, valign: 'm', rowH: 0.25, fontSize: 9, border: {type: 'solid', color: '000000', pt: .11}}
    slide.addTable(rows, options)

    makeTitle({ text: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.insight'}), x: 1, y: 1.7 })


    // completeKind에 따라 완료율, 완료수를 보여줘야해서..
    let completeName = f({id: 'component.Survey.Create.Analysis.Insight.Component.title.completeAvg'})
    let completeValue = completeAvg

    if (Number(getSearchCookie(completeCookieName)) === 1) {
        completeName = f({id: 'component.Survey.Create.Analysis.Insight.Component.title.completeCount'})
        completeValue = completeCount
    }

    options1 = { align: 'left', fontSize: 10 } 
    options2 = { align: 'right', fontSize: 15 } 
    rows = [
        [
            {text: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.connectCount'}), options: options1},
            {text: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.startCount'}), options: options1},
            {text: completeName, options: options1},
            {text: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.completeTime'}), options: options1}
        ],
        [
            {text: connectCount, options: options2},
            {text: startCount, options: options2},
            {text: completeValue, options: options2},
            {text: completeTime, options: options2}
        ]
    ]

    options = { x:1, y: 1.83, w: 11.5, valign: 'm', fill: "f8f9fa", rowH: 0.4, border: {type: 'solid', color: 'ffffff', pt: 1}}
    slide.addTable(rows, options)

    makeTitle({ text: f({id: 'component.Survey.Create.Analysis.Insight.Component.title.chart'}), x: 1, y: 3.1 })


    const chartDevice = document.getElementById(ids[0]).toDataURL('image/jpg')
    const chartGroupPeriod = document.getElementById(ids[1]).toDataURL('image/jpg')
    const chartGroupWeekDay = document.getElementById(ids[2]).toDataURL('image/jpg')
    const chartGroupTime = document.getElementById(ids[3]).toDataURL('image/jpg')  
    const chartRefererDomain = document.getElementById(ids[4]).toDataURL('image/jpg')  
    // const chartRefererCity = document.getElementById(ids[5]).toDataURL('image/jpg')  

    //3.2 3.7

    slide.addImage({ data: chartDevice, x: 0.8, y: 3.4, w: 3, h: 3 })
    slide.addImage({ data: chartGroupPeriod, x: 3.6, y: 3.4, w: 2.8, h: 3 })
    slide.addImage({ data: chartGroupWeekDay, x: 6.6, y: 3.4, w: 2.8, h: 3 })
    slide.addImage({ data: chartGroupTime, x: 9.6, y: 3.4, w: 3, h: 3 })
    
    // 유입경로 
    slide = pres.addSlide({ masterName })

    makeTitle({ text: f({id: 'component.Survey.Create.Analysis.Insight.RefererGraph.title.0'}), x: 1, y: 1 })
    slide.addImage({ data: chartRefererDomain, x: 0.8, y: 1.1, w: 11.6, h: 3.8 })

    // makeTitle({ text: f({id: 'component.Survey.Create.Analysis.Insight.RefererGraph.title.1'}), x: 1, y: 4.1 })
    // slide.addImage({ data: chartRefererCity, x: 0.8, y: 4.2, w: 11.6, h: 2.5 })

    // 이탈자 지표
    slide = pres.addSlide({ masterName })

    makeTitle({ text: f({id: `component.Survey.Create.Analysis.Insight.Component.title.${method}`}), x: 1, y: 1 })
    makeTitleRight({ text: f({id: 'component.Survey.Create.Analysis.Insight.Dial.delay'}, {delay}), x: 11.6, y: 1 })
    
    options1 = { align: 'left', fontSize: 8, fill: {color: oc.gray[6] }, color: 'ffffff' } 
    options2 = { align: 'right', fontSize: 8, fill: {color: oc.gray[6] }, color: 'ffffff' }
    options3 = { align: 'left', fontSize: 9} 
    options4 = { align: 'right', fontSize: 9}  

    rows = [
        [
            {text: f({id: `component.Survey.Create.Analysis.Insight.Component.page.${method === 'multi' ? 'columnPage' : 'columnQuestion'}`}), options: options1},
            {text: f({id: 'component.Survey.Create.Analysis.Insight.Component.page.column.0'}), options: options2},
            {text: f({id: 'component.Survey.Create.Analysis.Insight.Component.page.column.1'}), options: options2},
        ]
    ]

    const pageViewsRows = pageViewData.map(c => {
        const _question = fixedWidthString(c.pure_question.replace(/\r\n/g, '').replace(/\n/g, ''), 60)
        return [
            {
                text: c.number ? `${c.number}. ${_question}` : _question, 
                options: options3
            },
            {
                text: c.viewString,
                options: options4
            },
            {
                text: c.delayString,
                options: options4
            }
        ]
    })


    options = { 
        x:1, y: 1.2, w: 11.5, valign: 'm', fill: "fafafa", rowH: 0.3, colW: [8.4, 1.4, 1.7], 
        autoPage: true, autoPageRepeatHeader: true, newSlideStartY: 0.5, autoPageLineWeight: 1,
        border: {type: 'solid', color: 'ffffff', pt: 1}
    }
    slide.addTable([...rows, ...pageViewsRows], options)


    const title = getDownloadTitle({title: ctitle, type: 'Insight' })

    pres.writeFile(`${title}.pptx`)
}


function DataDownloadsComponent(props: Props) {
    const { 
        ids, 
        insightData, 
        pageViewData, 
        deviceType, 
        date, 
        mbox, 
        completeCookieName, 
        refererCityCookieName, 
        xs 
    } = props

    const { method, delay_multi, delay_one } = mbox

    const { 
        connectCount,
        startCount,
        completeAvg,
        completeCount,
        completeAvgTime
    } = insightData

    const { formatMessage: f } = useIntl()

    const [ title ] = useSurveyTitle()
    
    const insightProperty = {
        connectCount: String(numberFormat(connectCount)),
        startCount: String(numberFormat(startCount)),
        completeAvg: `${completeAvg}%`,
        completeCount: String(numberFormat(completeCount)), 
        completeCookieName,
        refererCityCookieName,
        completeTime: numberFormat(completeAvgTime),
        deviceType
    }

    const chartData = {
        ids
    }

    const pageData = {
        method,
        date,
        pageViewData,
        delay: method === 'multi' ? delay_multi : delay_one
    }

    const propsPPT = { title, f, insightProperty, chartData, pageData }

    const handleClick = () => {
        getPPT(propsPPT)
    }

    return (
        <>
        {
            xs ? (
                <BottomNavigationAction showLabel label={f({id: "component.Survey.Create.Analysis.Insight.DataDownloads.button.title"})} icon={<GetAppIcon />} onClick={handleClick}/>
            ) : (
                <Tooltip title={f({id: "component.Survey.Create.Analysis.Insight.DataDownloads.button.title"})} placement="top">
                    <IconBox onClick={handleClick}>
                        <GetAppIcon fontSize="small"/>
                    </IconBox>
                </Tooltip>
            )
        }
        </>
    )

}

export default DataDownloadsComponent