import { useContext } from 'react'
import PropsContext from 'context/props'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import Question from 'styled/Survey/Edit/Question'

export default (props) => {
    const { placeholder } = props

    const { rows, refMessage, on, onEdit } = useContext(PropsContext)
    const { message, img_src, align } = rows

    const { formatMessage: f } = useIntl()

    const onChange = onEdit.changeRef
    const onChangeAlign = on.changeAlign

    const onAttach = {
        remove: on.removeImage,
        open: on.openImage
    }
    return (
        <Question 
            ref={refMessage} 
            question={message}
            name="message" 
            rows={8} 
            img_src={img_src}
            align={align}
            placeholder={placeholder || f({id: `component.Survey.Create.Thanks.Edit.Question.message`})}
            maxlength={getMaxlength('component.Survey.Create.Thanks.Edit.Question.message')}
            onChange={onChange}
            onChangeAlign={onChangeAlign}
            onAttach={onAttach}
        />
    )
} 