/**
 * 다운로드 버튼 등이 위치 한다.
 */
import { memo } from "react"
import { SurveyMboxProps } from 'gql/survey_mbox'
import withBreakPoints, { BreakPointsProps } from "hoc/BreakPoints"
import { BarBox } from "../Buttons"
import { MoblieNavigation } from '../Rows/Buttons'
import MobileTopComponent from '../Rows/MobileTop'
import DataDownloads from "./DataDownloads"
import RefreshComponent from "./Refresh"

export interface Props {
    ids: any[];
    insightData: object;
    pageViewData: any[];
    deviceType: string;
    date: string;
    mbox: SurveyMboxProps;
    loading: boolean;
    completeCookieName: string;
    refererCityCookieName: string;
    refetchMbox: () => void;
    refetchInsight: () => void;
    refetchPageView: () => void;
    breakpoints: BreakPointsProps;
}

function Buttons2Component(props: Props) {
    const { 
        ids, 
        insightData, 
        pageViewData, 
        deviceType, 
        date, 
        mbox, 
        loading,
        completeCookieName, 
        refererCityCookieName, 
        refetchMbox,
        refetchInsight,
        refetchPageView,
        breakpoints 
    } = props
    const { xs } = breakpoints

    return (
        <>
        {
            xs ? (
                <MoblieNavigation>
                    <DataDownloads
                        ids={ids}
                        insightData={insightData}
                        pageViewData={pageViewData}
                        deviceType={deviceType}
                        date={date}
                        mbox={mbox}
                        completeCookieName={completeCookieName}
                        refererCityCookieName={refererCityCookieName}
                        xs={true}
                    />
                    <MobileTopComponent/>
                </MoblieNavigation>
            ) : (
                <>
                <BarBox/>
                <div>
                    <div>
                    <DataDownloads
                        ids={ids}
                        insightData={insightData}
                        pageViewData={pageViewData}
                        deviceType={deviceType}
                        date={date}
                        mbox={mbox}
                        completeCookieName={completeCookieName}
                        refererCityCookieName={refererCityCookieName}
                        xs={false}
                    />
                  
                    </div>
                    <div>
                        <RefreshComponent xs={false} loading={loading} refetchMbox={refetchMbox} refetchInsight={refetchInsight} refetchPageView={refetchPageView} />
                    </div>
                </div>
                </>
            )
        }
        </>    
    )
}

export default withBreakPoints(memo(Buttons2Component))