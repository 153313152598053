// 응답값이 있는 사용자가 편집페이지 들어왔을때 주의사항 안내한다
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { Dialog, DialogContent, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton, Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { WarningTwoTone as WarningTwoToneIcon, Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import { GET_SURVEY_ANALYSIS_USERS_COUNT } from 'gql/survey_analysis_users'
import htmlParser from 'react-html-parser'
import { parseHtmlIntl } from 'ts-utils'
import { withBreakPoints } from 'hoc'

const useStyles = makeStyles(theme => ({
    content: {
        textAlign: 'center',
        paddingTop: 40,
        paddingBottom: 40
    },
    icon: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    close: {
        position: 'absolute',
        right: 10,
        top: 10,
        zIndex: 1000
    },
    button: {
        marginTop: 10
    }
}))

const PrimaryTypography = withStyles({

})(props => <Typography variant="body2" {...props}/>)

function UsersConfirmModalComponent(props) {
    const { breakpoints, survey_no } = props

    const { xs } = breakpoints

    const classes = useStyles()

    const dispatch = useDispatch()

    const history = useHistory()

    const [ open, setOpen ] = useState(false)

    const { formatMessage: f } = useIntl()

    useQuery(GET_SURVEY_ANALYSIS_USERS_COUNT, {
        variables: { survey_no },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: data => {
           if (data.surveyAnalysisUserCount > 0) setOpen(true)
        },
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function onClose() {
        setOpen(false)
    }
    
    function onMove() {
        history.push(`/survey/form/analysis/${survey_no}#tab=rows`)
    }

    return (
        <Dialog open={open} onClose={() => {}} scroll={'paper'} fullWidth maxWidth="sm" fullScreen={xs}>
            <IconButton className={classes.close} onClick={onClose}>
               <CloseIcon/>
           </IconButton>
            <DialogContent className={classes.content}>
            
                <WarningTwoToneIcon color="secondary" className={classes.icon}/>
                <Typography variant="h6" gutterBottom>{f({id: 'component.Survey.Create.UsersConfirmModal.title'})}</Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon color="secondary"/>
                        </ListItemIcon>
                        <ListItemText primary={<PrimaryTypography variant="body2">{f({id: 'component.Survey.Create.UsersConfirmModal.content.0'})}</PrimaryTypography>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon color="secondary"/>
                        </ListItemIcon>
                        <ListItemText primary={
                            <PrimaryTypography >{f({id: 'component.Survey.Create.UsersConfirmModal.content.1'})}</PrimaryTypography>
                        }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon color="secondary"/>
                        </ListItemIcon>
                        <ListItemText primary={
                            <PrimaryTypography >{f({id: 'component.Survey.Create.UsersConfirmModal.content.2'})}</PrimaryTypography>
                        }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon color="secondary"/>
                        </ListItemIcon>
                        <ListItemText primary={<PrimaryTypography>{htmlParser(f({id: 'component.Survey.Create.UsersConfirmModal.content.3'}))}</PrimaryTypography>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                        <ListItemText primary={<PrimaryTypography>{f({id: 'component.Survey.Create.UsersConfirmModal.content.4'})}</PrimaryTypography>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText 
                            primary={<PrimaryTypography>{parseHtmlIntl(f({id: 'component.Survey.Create.UsersConfirmModal.content.5'}))}</PrimaryTypography>}
                        />
                    </ListItem>
                </List>
                <Button className={classes.button} variant="contained" color="primary" disableElevation onClick={onMove}>
                    <Typography variant="button">
                        {f({id: 'component.Survey.Create.UsersConfirmModal.button.0'})}
                    </Typography>
                </Button>
            </DialogContent>
             
          
        </Dialog>
    )
}

export default withBreakPoints(UsersConfirmModalComponent)