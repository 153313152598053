import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_SURVEY_ANALYSIS_DELAY_TIME_LISTS } from 'gql/survey_analysis_views'
import { downloadFile } from 'utils'
import { secondsToTime } from 'utils/survey'
import { parseGraphqlDate, parseGraphqlDeviceType } from 'ts-utils/summary'
import moment from 'moment'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import { getDate } from 'styled/Survey/Analysis/Calendar'

const useStyles = makeStyles({
    root: props => {
        const { count } = props

        return {
            cursor: count > 0 ? 'pointer' : 'text'
        }
    }
})

export default function CompleteIntervalLists(props) {
    const { survey_no, survey_page_no, delay_time, date, deviceType, page, count, text } = props
    
    const { formatMessage: f } = useIntl()

    const classes = useStyles({ count })

    const dispatch = useDispatch()

    const [ getLists, { loading } ] = useLazyQuery(GET_SURVEY_ANALYSIS_DELAY_TIME_LISTS, {
        onCompleted: res => {

            const label = f({id: 'component.Survey.Create.Analysis.Insight.DelayTimeLists.label.multi'})
            const rows = res.surveyAnalysisDelayTimeLists.map(c => {
                const{ ip, email, device, wdate, rdate, delay_time } = c
                const wDate = moment(Number(wdate)).format('YYYY.MM.DD HH:mm:ss')
                const rDate = rdate ? moment(Number(rdate)).format('YYYY.MM.DD HH:mm:ss') : ''
                const time = rdate ? secondsToTime(delay_time) : ''
                const str = `${email || ip},${f({id: `component.Survey.Create.Analysis.Insight.DelayTimeLists.device.${device}`})},${wDate},${rDate},${time}`
                return str 
            })

            const data = [label,...rows].join(`\n`)
            downloadFile({ filename: `DelayTime-S${page}.csv`, data })   
        },
        onError: e => {
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    const handleExport = useCallback(async() => {

        const { sdate, edate } = getDate(date)
        const parseDate = parseGraphqlDate(sdate, edate)

        getLists({
            variables: {
                survey_no,
                survey_page_no, 
                sdate: parseDate.sdate,
                edate: parseDate.edate,
                deviceType: parseGraphqlDeviceType(deviceType),
                delay_time 
            }
        })        
    }, [getLists, survey_no, survey_page_no, delay_time, date, deviceType])

    useEffect(() => {
        if (loading) dispatch({ type: BACKDROPSHOW })
        else dispatch({ type: BACKDROPHIDE })
    }, [dispatch, loading])

    return (
        <>
        {
            count > 0 ? (
                <Tooltip 
                    title={f({id: 'component.Survey.Create.Analysis.Insight.DelayTimeLists.tooltip'})}
                    isMobileClick={true}
                    placement="top-end" 
                    delayCloseTime={1500}
                >
                    <div onClick={handleExport} className={classes.root}>
                        <Typography variant="body2" color="primary">{text}</Typography>
                    </div>
                </Tooltip>
            ) : (
                <Typography variant="body2">{text}</Typography>
            )
        }
        </>
        
    )
}