/**
 * 공유 레포트 편집으로 가기
 */
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { componentType } from 'utils/analysis'
import { SurveyAnalysisSummaryProps } from 'gql/survey_analysis_summary';
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PrintIcon from '@material-ui/icons/Print'
import { Props as ParentProps } from './More'

interface Props extends ParentProps {
    onClose: () => void;
}

export const getCookieName = (survey_no: number) => {
    return `summary-print-${survey_no}`
}

function PrintIconComponent(props: Props) {
    const { survey_no, onClose } = props

    const { formatMessage: f } = useIntl()

    const summaryRows = useSelector((states: RootState) => states.analysisSummaryRows)

    const { rows, response } = summaryRows

    const printRows = rows.filter((row: SurveyAnalysisSummaryProps) => {
        const typename = row.__typename.replace('Summary', '')
        const type = componentType[typename] 

        return type !== 'text' && type !== 'file'
    })

    const handleClick = () => {

        const cookieName = getCookieName(survey_no)

        sessionStorage.setItem(cookieName, JSON.stringify({rows: printRows, response }))
      
        window.open(`/print/summary/${survey_no}`)
        onClose()
    }

    return (
        <MenuItem dense onClick={handleClick}>
            <ListItemIcon>
                <PrintIcon color="action"/>                
            </ListItemIcon>
            <ListItemText primary={f({id: "component.Survey.Create.Analysis.Summary.PrintIcon.primary"})}/>
        </MenuItem>
    )
}

export default memo(PrintIconComponent)

