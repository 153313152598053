import { memo, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import randomstring from 'randomstring'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { CHANGE } from 'reducer/survey/create/logic/jumpExampleCountInit'
import { DELETE_JUMP_EXAMPLE_COUNT_WITH_TEXT } from 'gql/jump_example_count'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import withStyles from '@material-ui/core/styles/withStyles'
import { ExampleCountEventContext } from 'container/Survey/Create/Logic/Jump/ExampleCountContext'

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'flex-end',
        '& > * + *': {
            marginLeft: 10
        }
    }
})(Box)

function InputAllPoint() {

    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { formatMessage: f } = useIntl()

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const { refetchJumpExampleCountWithRelation} = useContext(ExampleCountEventContext)

    const [ deleteData ] = useMutation(DELETE_JUMP_EXAMPLE_COUNT_WITH_TEXT, {
        onCompleted: () => {
            refetchJumpExampleCountWithRelation()
            dispatch({ type: CHANGE, val: randomstring.generate(7) })
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    function handleSave() {
        dispatch({ type: BACKDROPSHOW })
        deleteData({ 
            variables: {
                survey_no
            }
        })
        handleClose()
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined;

    return (
        <MyBox>
            <Button aria-describedby={id} color="secondary" disableElevation onClick={handleOpen}>
                {f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.InputInit.button.reset'})}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <DialogContent>
                    {f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.InputInit.content'})}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleSave}>
                        {f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.InputInit.button.remove'})}
                    </Button>
                    <Button onClick={handleClose}>
                        {f({id: 'component.Survey.Create.Logic.Jump.ExampleCount.InputInit.button.close'})}
                    </Button>
                </DialogActions>
            </Popover>
        </MyBox>
    )
} 

export default memo(InputAllPoint)