import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { getSearchCookie, setSearchCookie } from 'ts-utils/summary'
import { Avatar, ButtonGroup, Button, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { handleDisabledRightClick } from 'ts-utils'
import styled from 'styled-components'
import { getRGBA } from 'utils/analysis'
import { getColorConcentration } from 'utils'
import oc from 'open-color'
import { INITIAL, SEARCH } from 'reducer/analysis/summary/targetUser'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import useSurveyTokenInfo from 'hooks/useSurveyTokenInfo'
import ResponseDialogComponent from './ResponseDialog'
import ResponseComponent from './Response'

export const useStyles = makeStyles(theme => ({
    typography: props => {
        const { color, fontFamily } = props
        return {
            color,
            fontFamily,
            wordBreak: 'break-all'
        }
    },
    count: {
        paddingLeft: 3
    },
    avatar: props => {
        const { background_logo } = props
    
        return {
            display: background_logo ? 'block' : 'none',
            width: theme.spacing(18),
            height: theme.spacing(18),
            [theme.breakpoints.down('xs')]: {
                width: theme.spacing(12),
                height: theme.spacing(12)
            },
            marginBottom: 20
        }
    },
    buttonBox: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 5,
        gap: 30,
        [theme.breakpoints.down('xs')]: {
            gap: 10
        }
    }    
}))

const TitleBoxStyled = styled.div`
    padding: 1.2em 1.2em 3em 1.2em;
`

export function StyleTargetUserButton(props) {
    const { bar, font_family } = props

    const [ r, g, b ] = bar.split(',')
    const concentUnselect = getColorConcentration(`${r}, ${g}, ${b}, .1`)
    const concentSelect = getColorConcentration(`${r}, ${g}, ${b}, 1`)
    const defaultSelect = getColorConcentration(`${r}, ${g}, ${b}, .8`)


    // 바 배경색
    const unselectedBarBgcolor = getRGBA({color: bar, opacity: .1})
    // 바 색
    const selectedBarBgcolor = getRGBA({color: bar, opacity: 1})
    // 바 색
    const defaultBarBgcolor = getRGBA({color: bar, opacity: .8})

    this.unselectedStyle= {
        backgroundColor: unselectedBarBgcolor, 
        color: concentUnselect ? 'white' : oc.gray[8],
        fontFamily: font_family
    }

    this.selectedStyle = {
        backgroundColor: selectedBarBgcolor, 
        color: concentSelect ? 'white' : oc.gray[8], 
        fontFamily: font_family,
        cursor: 'default'
    }

    this.defaultStyle = {
        backgroundColor: defaultBarBgcolor, 
        color: defaultSelect ? 'white' : oc.gray[8], 
        fontFamily: font_family
    }
}

StyleTargetUserButton.prototype.all = function(targetUser) {
    // 전체결과
    return !targetUser ? this.selectedStyle : this.unselectedStyle
}

StyleTargetUserButton.prototype.target = function(targetUser) {
    // 나의결과
    return !targetUser ? this.unselectedStyle : this.selectedStyle
}

StyleTargetUserButton.prototype.default = function() {
    // 기본
    return this.defaultStyle
}

// 쿠키명
export const getCookieName = (survey_no) => {
    return `summary-result-${survey_no}`
}

// 나의결과 전체결과중 default가 무엇인지 판단
const getDefaultTargetUser = (survey_no, view_type) => {
    // 전체결과 나의결과 보여주는거면 맨처음 default는 전체결과지만, 나의결과 선택 후 새로고침하면 나의결과가 유지되야한다.
    if (view_type === 'all') {
        const name = getCookieName(survey_no)
        const value = getSearchCookie(name)

        // 1이면 나의결과가 디폴트다 유지해야한다.
        return Number(value) === 0
    } 
    
    // view_type이 나의결과 이면 true 아니면 false
    return view_type === 'self'
}

export default (props) => {
    const { survey_no, design, title, surveyArgs, edit=false } = props
    
    const { view_type, font_family, question, background_logo, bar, response } = design

    const color = getRGBA({color: question, opacity: 1})

    const classes = useStyles({ fontFamily: font_family, color, background_logo })

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const location = useLocation()
    const { search } = location

    const params = queryString.parse(search)

    const [ targetUser, setTargetUser ] = useState(getDefaultTargetUser(survey_no, view_type))

    const surveyToken = useSurveyTokenInfo(survey_no, params.m === 'email' ? 'email' : 'ip')

    // 토큰값이 있는데 survey_analysis_users_no 가 없다면, 삭제된 응답값? 정도로 보면되니. 값이 없다 판단하여 임의로 0번 검색
    const survey_analysis_users_no = surveyToken ? surveyToken.survey_analysis_users_no : 1

    const getStyleTargetUserButton = new StyleTargetUserButton({ bar, font_family })

    function handleTargetUser(val) {
        if (edit) {
            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Analysis.Summary.Title.viewType.tooltip'}), variant: 'info' })
            return
        }

        if (val === targetUser) return

        setTargetUser(val)

        const name = getCookieName(survey_no)


        // 나의결과/전체결과는 새로고침이후에도 유지되야해서 로컬스토리지로 저장한다.
        if (val) {
            dispatch({ type: SEARCH, survey_analysis_users_no })
            setSearchCookie(name, 0)
        }
        else {
            dispatch({ type: INITIAL })
            setSearchCookie(name, 1)
        }
    }
    
    // 결과보기 타입 모두보기 타입이면서 편집이나 응답값이 잇다면 보여주고 아니면 버튼 자체를 안보여준다.
    const isViewTypeButton = view_type === 'all'

    // 수정페이지에서는 응답버튼활성화, 실제페이지에서는 응답버튼활성화와 응답한 쿠키값이 있을때 보여진다.
    const isResponseButton = (edit && response) || (response && survey_analysis_users_no > 1)

    return (
        <TitleBoxStyled>
            <Avatar src={background_logo} className={classes.avatar} variant="circular" onContextMenu={handleDisabledRightClick}/>
            <Typography variant="h5" className={classes.typography} display="block">{title}</Typography>            
            <ResponseComponent classes={classes}/>
            <Box className={classes.buttonBox}>
                {
                    isViewTypeButton ? (
                        <ButtonGroup size="small" variant="contained">
                            <Button 
                                style={getStyleTargetUserButton.target(targetUser)}
                                onClick={() => handleTargetUser(true)}
                            >
                                {f({id: 'component.Survey.Create.Analysis.Summary.Title.viewType.button.self'})}
                            </Button>
                            <Button 
                                style={getStyleTargetUserButton.all(targetUser)}
                                onClick={() => handleTargetUser(false)}
                            >
                                {f({id: 'component.Survey.Create.Analysis.Summary.Title.viewType.button.all'})}
                            </Button>
                        </ButtonGroup>
                    ) : null
                }
                {
                    isResponseButton ? (
                        <ResponseDialogComponent 
                            survey_no={survey_no} 
                            surveyArgs={surveyArgs} 
                            edit={edit}
                            survey_analysis_users_no={survey_analysis_users_no} 
                            font_family={font_family} 
                            bar={bar}
                        />
                    ) : null
                }
            </Box>
        </TitleBoxStyled>
    )
}
