import { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { getShareLink } from 'utils/survey'
import { FormControl, FormControlLabel, FormHelperText, Switch, Typography, Box, Grid, Divider } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CopyLink from 'styled/Survey/CopyLink'
import useSurveyOpen from 'hooks/useSurveyOpen'
import useSurveyTitle from 'hooks/useSurveyTitle'
import SnsChildComponent from './SnsChild'
import CustomArgsComponent from './CustomArgs'

const useStyles = makeStyles(theme => ({
    copyLink: {
        background: 'white',
        width: '100%'
    },
    formLabel: {
        paddingBottom: 8, 
        color: oc.gray[8]
    },
    linkBox: {
        maxWidth: 700,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    }
}))

const ConetentsBox = withStyles({
    root: (props) => {
        const { style } = props

        const Style = style || {}
        return {
            marginLeft: 17,
            ...Style
        }

    }
})(Box)

const MyDivder = withStyles({
    root: {
        marginTop: 20, 
        marginBottom: 20
    }
})(Divider)


function SnsComponent (props) {
    const { listoDomain, args=''} = props

    const classes = useStyles()

    const params = useParams()
    const survey_no = Number(params.survey_no)

    const [ title ] = useSurveyTitle()
    const [ open, setOpen ] = useSurveyOpen()

    const { formatMessage: f } = useIntl()

    const shareLink = getShareLink({domain: listoDomain, args})
    const webUrl = shareLink.survey.web

    return (  
      
        <>
        <Typography component="div" gutterBottom>
            {f({id: "component.Survey.Create.Share.Sns.title.0"})}
        </Typography>
        <ConetentsBox className={classes.linkBox}>
            <CopyLink 
                url={webUrl} 
                label=""
                className={classes.copyLink}
            />
            <CustomArgsComponent survey_no={survey_no} listoDomain={listoDomain}/>
        </ConetentsBox>
        <MyDivder/>
        <Typography component="div" gutterBottom>
            {f({id: "component.Survey.Create.Share.Sns.title.1"})}
        </Typography>
        <ConetentsBox>
            <Grid container spacing={3} alignItems='center'>
                <SnsChildComponent survey_no={survey_no} url={webUrl} title={title}/>
            </Grid>
        </ConetentsBox>
        <MyDivder/>
        <Typography component="div" gutterBottom>
            {f({id: "component.Survey.Create.Share.Sns.title.2"})}
        </Typography>
        <ConetentsBox>
            <FormControl component="fieldset">
                <FormControlLabel
                    control={<Switch color="primary" checked={open} onChange={setOpen} name="open" />}
                    label={f({id: 'component.Survey.Create.Share.Sns.open'})}
                />
                <FormHelperText>{f({id: 'component.Survey.Create.Share.Sns.formHelperText'})}</FormHelperText>
            </FormControl>
        </ConetentsBox> 
        </>
    )
}

export default memo(SnsComponent)