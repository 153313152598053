import CreateBox from 'styled/Survey/CreateBox'

const Component = props => {
    const { scrolls, children } = props

    return (
        <CreateBox scrolls={scrolls} >
            {children}
        </CreateBox>
    )
}

export default Component