import { useContext, useCallback, useState, memo, MouseEvent } from "react"
import { useIntl } from 'react-intl'
import fixedWidthString from 'fixed-width-string'
import oc from 'open-color'
import { ID } from 'gql/jump'
import { getEndingMssage } from 'ts-utils/survey'
import ListItem from "@material-ui/core/ListItem"
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { QuizContext } from 'container/Survey/Create/Logic/Jump/ListsContext'
import { MyTypography } from './ItemHeart'
import { MyMenuItem } from './SelectModule'
import { MyMenu, MyPaper, MyList, MyTitle, MyCloseIcon, Refference } from './SelectActionQuestion'

interface Props {
    refference: Refference;
    surveyQuestionNos: number[];
    onChange: (a: number[]) => void;
}

function SelectActionEndingComponent(props: Props) {
    const { refference, surveyQuestionNos, onChange } = props

    const { endings } = useContext(Context)

    const { exampleCount, point } = useContext(QuizContext)

    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)

    let splitText = ''

    // 보기 선탠 수 방식의 값이 있다면
    if (exampleCount) {
        splitText = f({id: 'component.Survey.Create.Menu.Quiz.radio.0'})
    } else if (point) {
        splitText = f({id: 'component.Survey.Create.Menu.Quiz.radio.1'})
    }
    
    const handleOpenAnchorEl = useCallback((e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const handleCloseAnchorEl = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleSelectedNull = useCallback((e) => {
        e.stopPropagation()
        
        onChange([])
    }, [onChange])

    const handleSelected = useCallback((id: ID) => {
        
        onChange([ id ])
        handleCloseAnchorEl()
    }, [ onChange, handleCloseAnchorEl])

    const self = endings.find(c => c.survey_ending_no === surveyQuestionNos[0]) || null

    // Refference.Rule 이면서 값이 없다면 error true이단. else는 값이 없어도 된다.
    let error = (refference === Refference.Rule && !self) ? 1 : 0

    // -1 완료글 포인트 분기의 값이라 pages에 없어도 error가 아니다.
    if (surveyQuestionNos[0] === -1) error = 0

    const primary = surveyQuestionNos[0] === -1 ? splitText : 
                                                            self ? fixedWidthString(`${(self.indexs)}. ${getEndingMssage(self.kind, self.pure_message, self.redirect_url )}`, 20) 
                                                                 : f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectActionEnding.primary'})
                                                                 
    const boolean = Boolean(self || surveyQuestionNos[0] === -1)

    return (
        <MyPaper variant="outlined" error={error}>
            <MyList style={{ flexGrow: 1 }}>
                <ListItem button onClick={handleOpenAnchorEl}>
                    <MyTitle selected={boolean} primary={primary}/>
                    {
                        // else일때만 X버튼 준다
                        (refference === Refference.Else && surveyQuestionNos.length > 0) && (
                            <MyCloseIcon onClick={handleSelectedNull}/>
                        )
                    }
                    <ExpandMore/>
                </ListItem>
            </MyList>
            <MyMenu 
                anchorEl={anchorEl} 
                open={Boolean(anchorEl)}
                onClose={handleCloseAnchorEl}
            >
                {
                    (endings.length > 1 && (exampleCount || point)) && (
                        <MyMenuItem style={{background: oc.gray[0]}} value={-1} onClick={() => handleSelected(-1)}>
                            <MyTypography style={{fontWeight: 'bold'}}>{splitText}</MyTypography>
                        </MyMenuItem>
                    )
                }
                {
                    endings.map(ending => {
                        const { survey_ending_no, indexs, kind, pure_message, redirect_url } = ending
                        const msg = getEndingMssage(kind, pure_message, redirect_url )
                   
                        return (
                            <MyMenuItem key={survey_ending_no} value={survey_ending_no} onClick={() => handleSelected(survey_ending_no)}>
                                <MyTypography>{`${indexs}. ${msg}`}</MyTypography>
                            </MyMenuItem>
                        )
                    })
                }
            </MyMenu>
        </MyPaper>

    )
}

export default memo(SelectActionEndingComponent)