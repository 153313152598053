import { memo, useState, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CHANGE } from 'reducer/survey/create/logic/jumpPointAllPoint'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { isMobile } from 'react-device-detect'
import { replaceNotNumber } from 'utils'
import Box from '@material-ui/core/Box'
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { min, max, isError } from './ButtonSave'

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'flex-end',
        '& > * + *': {
            marginLeft: 10
        }
    }
})(Box)

export const MyTextField = withStyles({
    root: (props:{styles: object}) => {
        const { styles } = props
        return {
            ...styles
        }
    }
})((props: TextFieldProps) => {
    const { value, placeholder, onChange, ...other } = props

    return (
        <TextField 
            {...other}
            type={isMobile ? 'number' : 'text'}
            value={value} 
            placeholder={placeholder} 
            onChange={onChange}
        />
    )
})

function InputAllPoint() {

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ value, setValue ] = useState<number | ''>('')

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const number = replaceNotNumber(e.target.value, true)
        setValue(number)
    }

    function handleClick() {
        const point = Number(value)
        if (point < min) {
            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Logic.Jump.Point.InputAllPoint.valid'}, {min, max}), variant: 'warning'})
            return
        }
        if (point > max) {
            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Logic.Jump.Point.InputAllPoint.valid'}, {min, max}), variant: 'warning'})
            return
        }
        dispatch({ type: CHANGE, point: Number(value) })
    }

    const disabled = isError(min, max, value)

    return (
        <MyBox>
            <MyTextField 
                value={value} 
                placeholder={f({id: 'component.Survey.Create.Logic.Jump.Point.InputAllPoint.placeholder'})} 
                styles={{ width: 140 }} 
                onChange={handleChange}
            />
            <Button variant="contained" size="small" disabled={disabled} onClick={handleClick}>{f({id: 'component.Survey.Create.Logic.Jump.Point.InputAllPoint.button.apply'})}</Button>
        </MyBox>
    )
} 

export default memo(InputAllPoint)