import { memo, useContext } from 'react'
import { useIntl } from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import { Method } from 'gql/jump'
import { Method as MboxMethod } from 'gql/survey_mbox'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { MySelect } from './ItemRules'

interface Props {
    method: Method;
    onChange: (a: Method) => void;
}

function SelectMethodComponent(props: Props) {
    const { method, onChange } = props

    const { pages, mbox, start } = useContext(Context)
    const { method: mboxMethod } = mbox
    const { used: startUsed } = start

    const { formatMessage: f } = useIntl()

    let isMove = true

    // 클래식 모드이면서 섹션이 한개라면 이동을 안보여준다. (기존선택 저장된값이 이동이 아니라면..안보여준고. 이동을 선택한상태에서 섹션을 삭제한상태라면 어쩔수없이 보여준다.)
    if (mboxMethod === MboxMethod.Multi && method !== Method.Move && pages.length <= 1) isMove = false
    if (startUsed) isMove = true

    return (
        <MySelect style={{width: 80}} label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMethod.label'})} value={method} onChange={(e) => onChange(e.target.value as Method)}>
            <MenuItem value={Method.Hide}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMethod.item.hide'})}</MenuItem>
            <MenuItem value={Method.Show}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMethod.item.show'})}</MenuItem>
            {
                isMove && <MenuItem value={Method.Move}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMethod.item.move'})}</MenuItem>
            }
            <MenuItem value={Method.End}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMethod.item.end'})}</MenuItem>
        </MySelect>
    )
}

export default memo(SelectMethodComponent)