import { memo, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { MyListItemIcon } from 'styled/Survey/Share/Embed'

interface Props {
    imageSize: number;
    imageSizeUnit: 'px' | '%';
    onChangeSize:(a:ChangeEvent<HTMLInputElement>) => void;
    onChangeUnit:(a:ChangeEvent<HTMLInputElement>) => void;
}

function PopupImageSizeComponent(props: Props) {
    const { imageSize, imageSizeUnit, onChangeSize, onChangeUnit } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        <ListItem>
                <ListItemText>{f({id: 'component.Survey.Create.Share.PopupImageSize.title'})}</ListItemText>
                <MyListItemIcon>
                    <TextField style={{width: 50}} value={imageSize} type="number" onChange={onChangeSize}/>
                </MyListItemIcon>
                <MyListItemIcon>
                    <TextField select value={imageSizeUnit} onChange={onChangeUnit}>
                        <MenuItem value="px">px</MenuItem>
                        <MenuItem value="%">%</MenuItem>
                    </TextField>
                </MyListItemIcon>
            </ListItem>
        </>
    )
}

export default memo(PopupImageSizeComponent)