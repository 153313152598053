/**
 * 버튼 모음
 */
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectStyled } from 'styled/Input'
import DateBox from 'component/Survey/Create/NewAnalysis/DateBox'
import { WrapperBox } from '../Buttons'


interface Props {
    dateCookieName: string;
    loading: boolean;
    date: string;
    deviceType: string;
    openCalendar: boolean;
    on: any;
    children: ReactNode
}

function ButtonsComponent(props: Props) {
    const { dateCookieName, loading, date, deviceType, openCalendar, on, children } = props
    const { formatMessage: f } = useIntl()

    return (
        <WrapperBox justifyContent="flex-start">
            <div>
                <DateBox
                    cookieName={dateCookieName}
                    disabled={loading}
                    open={openCalendar} 
                    date={date} 
                    onInitial={on.changeInitial} 
                    onOpen={on.openCalendar} 
                    onClose={on.closeCalendar} 
                    onDo={on.save}
                />
                <Select 
                    value={deviceType} 
                    style={{ 
                        width: 150,
                        background: 'white'
                    }} 
                    input={<SelectStyled fontSize={13} padding="6px 12px 6px 8px"/>} onChange={on.changeDeviceType}
                >
                    <MenuItem value="all">
                        {f({id: 'component.Survey.Create.Analysis.Insight.Component.select.deviceType.all'})}
                    </MenuItem>
                    <MenuItem value="browser">
                        {f({id: 'component.Survey.Create.Analysis.Insight.Component.select.deviceType.browser'})}
                    </MenuItem>
                    <MenuItem value="tablet">
                        {f({id: 'component.Survey.Create.Analysis.Insight.Component.select.deviceType.tablet'})}
                    </MenuItem>
                    <MenuItem value="mobile">
                        {f({id: 'component.Survey.Create.Analysis.Insight.Component.select.deviceType.mobile'})}
                    </MenuItem>
                    <MenuItem value="other">
                        {f({id: 'component.Survey.Create.Analysis.Insight.Component.select.deviceType.other'})}
                    </MenuItem>
                </Select>
            </div>
            {children}
        </WrapperBox>
       
    )
}

export default ButtonsComponent