import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils/index'
import oc from 'open-color'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Folder from '@material-ui/icons/Folder'

export const ExampleBox = withStyles({
    root: {
        position: 'relative',
        display: 'block',
        border: `1px solid ${oc.gray[3]}`,
        borderRadius: 3,
        padding: '18px 10px 10px 10px',
        marginTop: 15,
        lineHeight: '185%',
        color: oc.gray[7],
        '& > span': {
            position: 'absolute',
            top: -12,
            left: 7,
            display: 'block',
            backgroundColor: 'white',
            padding: '0 3px',
            fontSize: 13,
            color: oc.gray[6]
        }
    }
})((props: { label: string, children: React.ReactChild[] }) => {
    const { label, children, ...other } = props
    return (
        <Box {...other} component="span">
            <span>{label}</span>
            {children}
        </Box>
    )

})

export const Number = withStyles({
    root: {
        width: 25, 
        height: 25, 
        fontSize: 15, 
        fontWeight: 900
    }
})(Avatar)

export const FolderIcon = withStyles({

})(props => <Folder {...props}/>)

export const SubTitle1 = withStyles({

})(props => <Typography {...props} variant="h6" gutterBottom/>)

export const SubTitle2 = withStyles({
    root: {
        marginTop: 30
    }
})(props => <Typography {...props} variant="h6" gutterBottom/>)

function HelpMultiComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <>
        <List>
            <SubTitle1>{f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.subtitle.0'})}</SubTitle1>
            <ListItem>
                <ListItemAvatar>
                    <Number>1</Number>
                </ListItemAvatar>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.0'})} 
                    secondary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.secondary.0'})}/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Number>2</Number>
                </ListItemAvatar>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.1'})}
                    secondary={parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.secondary.1'}))}/>
            </ListItem>
            <SubTitle2>{f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.subtitle.1'})}</SubTitle2>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.2'})}
                    secondary={
                        <>
                        {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.secondary.2'}))}
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.label.2'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpOne.third.2'}))}
                        </ExampleBox>
                        </>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.3'})}
                    secondary={parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.secondary.3'}))}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.4'})}
                    secondary={
                        <>
                        {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.secondary.4'}))}
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.label.4'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.third.4'}))}
                        </ExampleBox>
                        </>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.5'})}
                    secondary={
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.label.5'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.secondary.5'}))}
                        </ExampleBox>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.6'})}
                    secondary={
                        <ExampleBox label={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.label.6'})}>
                            {parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.secondary.6'}))}
                        </ExampleBox>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.7'})}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.primary.8'})}
                    secondary={parseHtmlIntl(f({id: 'component.Survey.Create.Logic.Jump.Lists.HelpMulti.secondary.8'}))}
                />
            </ListItem>
        </List>
        </>
    )
}

export default HelpMultiComponent