import { memo, useCallback } from 'react'
import { JumpExampleCountProps } from 'gql/jump_example_count'
import withSurveyPopoverImage, { OnOpen, OnClose } from 'hoc/Survey/PopoverImage'
import { OnChange } from './QuestionItemSplit'
import InputItemsComponent from './InputItems'
import { MyPaper, MyAvatar, MyBox, MyTypography } from '../Point/Item'

interface Props {
    popoverImageProps: {
        on: {
            open: OnOpen,
            close: OnClose
        }
    },
    answer: string;
    row: JumpExampleCountProps;
    onChange: OnChange;
    src?: string;
}

export type OnChangeNos = (val: JumpExampleCountProps['survey_ending_nos']) => void

function Item(props: Props) {
    const { answer, row, src, onChange, popoverImageProps } = props

    const { on } = popoverImageProps
    const onOpen = on.open

    const isSrc = src !== undefined

    const handleChangeNos: OnChangeNos = useCallback(survey_ending_nos => {
        survey_ending_nos.sort()
        onChange({
            ...row,
            survey_ending_nos
        })
    }, [row, onChange])

    return (
        <MyPaper variant="outlined">
            <MyBox lastwidth={105}>
                <div style={{paddingTop: isSrc ? 5 : 0}}>
                    {
                        isSrc && (
                            <MyAvatar 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onOpen(src)
                                }}
                                src={src}
                            />
                        )
                    }    
                    <MyTypography>{answer}</MyTypography>
                </div> 
                <div>
                    <InputItemsComponent survey_ending_nos={row.survey_ending_nos} onChange={handleChangeNos}/>
                </div>
            </MyBox>
        </MyPaper>
    )
}

export default withSurveyPopoverImage(memo(Item))