import { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import { Kind } from 'gql/jump'
import { Method } from 'gql/survey_mbox'
import { SelectedPage } from './Component'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { ListsContext } from 'container/Survey/Create/Logic/Jump/ListsContext'
import ItemPageComponent from './ItemPage'
import ItemQuestionComponent from './ItemQuestion'

interface Props {
    selectedPage: SelectedPage;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    }
}))

function ItemsComponent(props: Props) {
    const { selectedPage } = props

    const { pages, questions, mbox } = useContext(Context)
    const { method } = mbox
    const { jump } = useContext(ListsContext)
    
    const classes = useStyles()
    
    return (
        <List dense disablePadding className={classes.root}>
        { 
            pages.reduce((acc, page) => {
                const { survey_page_no } = page

                // selectedPage 값이 없으면 모든페이지 보여주고, 값이 있으면 해당페이지내 문항만 보여준다
                const show = !selectedPage ? true : selectedPage === survey_page_no

                // jump, jumpElse 해당값 가져오기
                const jumpRow = jump.find(c => c.id === survey_page_no && c.kind === Kind.Page)

                // 점프row 값은 반드시 있다. 방어코드 차원에서 
                const _acc = (jumpRow && pages.length > 1 && method === Method.Multi) 
                             ? [...acc, <ItemPageComponent key={survey_page_no} show={show} jumpRow={jumpRow} {...page}/>] 
                             : acc
                
                const questionItems = questions.filter(row => {
                    const { _question } = row
                    const { survey_page_no: key } = _question
        
                    return survey_page_no === key
        
                }).reduce((accQ, question) => {
                    const { _question } = question
                    const { survey_question_no } = _question

                    // 점프row 값은 반드시 있다. 방어코드 차원에서 
                    const jumpRow = jump.find(c => c.id === survey_question_no && c.kind === Kind.Question)

                    return jumpRow ? [...accQ, <ItemQuestionComponent key={survey_question_no} show={show} jumpRow={jumpRow} {...question}/>] : accQ
                    
                }, [])
        
                return [..._acc, ...questionItems]
            }, [])
        }
        </List>
    )
}

export default ItemsComponent