/**
 * 썸네일 등록
 */

import { memo, useState, useCallback, MouseEvent } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useIntl } from "react-intl"
import oc from "open-color"
import { parseHtmlIntl } from 'ts-utils'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import { CREATE_SURVEY_MBOX_THUMBNAIL } from "gql/survey_mbox_tumbnail"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/core/styles"
import PopupImage from 'styled/PopupImage'
import ThumbnailRemoveComponent from "./ThumbnailRemove"
import { Props as ParentProps } from './SnsChild'

interface Props {
    survey_no: ParentProps['survey_no'];
    thumbnail: ParentProps['propsFetchData']['mboxThumbnailSrc'];
    refetch: ParentProps['propsFetchData']['refetchMboxThmubnailSrc'];
}

export type OnRemove = (event: MouseEvent<HTMLButtonElement>) => void;

const MyPaper = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 150, 
        height: 132,
        marginBottom: 3,
        color: oc.gray[7],
        fontSize: 12,
        cursor: 'pointer',
        transition: 'all .3s',
        position: 'relative',
        '&:hover': {
            background: oc.gray[3]
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))(Paper)

function ThumbnailComponent(props: Props) {
    const { survey_no, thumbnail, refetch } = props

    const dispatch = useDispatch()

    const [ open, setOpen ] = useState(false)
    const [ image, setImage ] = useState(thumbnail)
    
    const { formatMessage: f } = useIntl()

    const [ create ] = useMutation(CREATE_SURVEY_MBOX_THUMBNAIL, {
        onCompleted: () => {
            setOpen(false)
            refetch()
        },
        onError: () => {
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setImage(thumbnail)
        setOpen(false)
    
    }, [thumbnail])


    const handleSave = useCallback(() => {
        const img = document.createElement('img');
        img.src = image || ""

        img.onload = function () {
            create({
                variables: {
                    survey_no,
                    src: image
                }
            })
        }

        img.onerror = function() {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Image.Container.alert.appendUrl.image'})
            })
        }
    }, [survey_no, image, create, dispatch, f])

    const handleRemove: OnRemove = useCallback((event) => {
        event.stopPropagation()
        setImage('')
    }, [])

    const handleChange = useCallback((props: {img_src: string}) => {
        const { img_src } = props
        setImage(img_src)
    }, [])

    return (
        <>
        <MyPaper onClick={handleOpen}>
            {
                image ? (
                    <>
                    <ThumbnailRemoveComponent survey_no={survey_no} onRemove={handleRemove} refetch={refetch}/>
                    <img 
                        src={image} 
                        style={{maxWidth: '100%', height: '100%'}} alt="thumbnail"
                    />
                    </>
                ) : (
                    <>
                    {parseHtmlIntl(f({id: 'component.Survey.Create.Share.Thumbnail.size'}))}
                    </>
                )
            }
        </MyPaper>
        <PopupImage
            title={f({id: 'component.Survey.Create.Share.Thumbnail.Dialog.title'})}
            open={open}
            saveButtonText={f({id: 'component.Survey.Create.Share.Thumbnail.Dialog.button.save'})}
            onSave={handleSave}
            onClose={handleClose}
            onAppendUrl={handleChange}
            onChangeImage={handleChange}
        />
        </>
    )
}

export default memo(ThumbnailComponent)