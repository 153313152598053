import { memo } from 'react'
import { AnswerMatrix } from 'gql/survey_question_modules'
import { Props } from './QuestionItemsBase'
import ItemComponent from "./Item"

function QuestionItemsMatrix(props: Props) {
    const { componentType, question, rows, onChange } = props
    const { _answers } = question

    return (
        <>
        {
            _answers.map((_answer: AnswerMatrix) => {
                const { survey_module_answer_no, answer, indexs } = _answer
                const title = componentType === 'pointMatrix' ? indexs : `${indexs}. ${answer}`

                const row = rows.find(row => row.survey_module_answer_no === survey_module_answer_no)

                if (!row) return null

                return <ItemComponent key={survey_module_answer_no} answer={title} row={row} onChange={onChange}/>
            })
        }
        </>
    )
}

export default memo(QuestionItemsMatrix)