import { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles as useSubjectStyles } from './Subject'

interface Props {
    column: string;
}

function ColumnSubjectComponent(props: Props) {
    const { column } = props

    const classesSubject = useSubjectStyles()

    return <Typography variant="body2" className={classesSubject.title}>{column}</Typography>
}

export default memo(ColumnSubjectComponent)
