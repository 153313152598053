import { memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyAnalysisRowsProps } from 'gql/survey_analysis_rows'
import { RootState } from 'reducer'
import { isMobile } from 'react-device-detect'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DoneIcon from '@material-ui/icons/Done'
import DownloadMenuCompoent from './DownloadMenu'

interface Props {
    open: boolean;
    questions: SurveyQuestionModuleProps[];
    onClose: () => void;
}

interface InitRows {
    complete: boolean;
    loading: boolean;
    rows: {
        columns: SurveyAnalysisRowsProps['columns'];
        answers: SurveyAnalysisRowsProps['answers'];
        files: SurveyAnalysisRowsProps['files'];
    };
}

const initRowData: InitRows = {
    complete: false,
    loading: false,
    rows: {
        columns: [],
        answers: [],
        files: []
    }
}
function ChoiceDataDownload(props: Props) {
    const { open, questions, onClose } = props

    const { formatMessage: f } = useIntl()
    
    const [ action, setAction ] = useState(0)
    const [ rowData, setRowData ] = useState(initRowData)

    const states = useSelector((state: RootState) => ({
        choiceState: state.analysisRowsChoice,
        dataState: state.analysisRowsData
    }))

    const { choiceState, dataState } = states
    const { ids } = choiceState
    const { data } = dataState

    const handleClick = () => {
        setAction(Math.random())
        setRowData(prevState => ({
            ...prevState,
            complete: true,
            rows: {
                columns: data.columns,
                answers: data.answers.filter(c => ids.includes(c.id)),
                files: data.files.filter(c => ids.includes(c.survey_analysis_users_no) && c.src)
            }
        }))
        onClose()
    }

    useEffect(() => {
        if (open) {
            setRowData(initRowData)
            setAction(0)
        }
    }, [open])

    return (
        <>
        <MenuItem button dense={!isMobile} disabled={ ids.length === 0 || data.columns.length === 0 || data.answers.length === 0 } onClick={handleClick}>
            <ListItemIcon>
                <DoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Create.Analysis.Rows.ChoiceDataDownload.primary'})}  />
        </MenuItem>
        <DownloadMenuCompoent action={action} etc={{ questions }} rowsData={rowData}/>
        </>
    )
}

export default memo(ChoiceDataDownload)

