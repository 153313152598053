import { memo } from 'react'
import { Typography, List, ListItemText } from '@material-ui/core'
import { Folder as FolderIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import { withSurveyFetchData } from 'hoc'
import CreateBox from 'styled/Survey/CreateBox'
import { Title, ListItem,  ListItemIcon } from 'styled/Survey/CreateExplainStyle'

function ExplainComponent(props) {
	const { propsFetchData } = props    
	const { mbox } = propsFetchData
	const { method } = mbox

    const { formatMessage: f } = useIntl()

    return (
        <CreateBox className="explain">
            <Title>{f({id: 'component.Survey.Create.Logic.QuestionRandom.Explain.title'})}</Title>
            <List> 
            	<ListItem>
                	<ListItemIcon>
                    	<FolderIcon/>
                  	</ListItemIcon>
                  	<ListItemText
                    	primary={f({id: 'component.Survey.Create.Logic.QuestionRandom.Explain.lists.0'})}
                  	/>
                </ListItem>

                <ListItem>
                	<ListItemIcon>
                    	<FolderIcon />
                  	</ListItemIcon>
                  	<ListItemText
                    	primary={f({id: 'component.Survey.Create.Logic.QuestionRandom.Explain.lists.1'})}
                  	/>
                </ListItem>
				{
					method === 'multi' && (
						<ListItem>
							<ListItemIcon>
								<FolderIcon/>
							</ListItemIcon>
							<ListItemText
								primary={<Typography>{f({id: 'component.Survey.Create.Logic.QuestionRandom.Explain.lists.2'})}</Typography>}
							/>
						</ListItem>
					)
				}
                <ListItem>
                	<ListItemIcon>
                    	<FolderIcon color="secondary"/>
                  	</ListItemIcon>
                  	<ListItemText
                    	primary={<Typography color="secondary">{f({id: 'component.Survey.Create.Logic.QuestionRandom.Explain.lists.3'})}</Typography>}
                  	/>
                </ListItem>
            </List>
        </CreateBox>
    )
}
export default withSurveyFetchData('edit')({
    mbox: true,
    _fetchPolicy: {
		mbox: 'cache-first'
	}
})(false)(memo(ExplainComponent))