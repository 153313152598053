import { useEffect, useRef } from 'react'
import { Container, IconButton } from '@material-ui/core'
import { ArrowUpward as ArrowUpwardIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { createGlobalStyle } from 'styled-components'
import { getRGBA } from 'utils/analysis'
import { getBackgroundImageOpacity } from 'utils/survey'
import { animateScroll as scroll } from 'react-scroll'

const useStyles = makeStyles(theme => ({
    top: props => {
        const { color } = props

        return {
            color,
            position: 'fixed',
            right: 29,
            bottom: 75,
            visibility: 'hidden',
            [theme.breakpoints.down('sm')]: {
                right: 10,
                bottom: 55
            }
        }
    }
}))

const GlobalStyles = createGlobalStyle`
    html, body {
        font-size: 16px;
        background-color: ${({ background_color}) => background_color};
        background-image: ${({background_cover, background_cover_opacity}) => {
            return getBackgroundImageOpacity({
                background_opacity: background_cover_opacity, 
                background_image: background_cover
            })
        }};
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        padding-top: .5em;
        padding-bottom: 2em;
    }
`

const Component = (props) => {
    const { design, children } = props

    const { background_color, question, background_cover, background_cover_opacity } = design

    const color = getRGBA({color: question, opacity: 1})
    const _background_color = getRGBA({color: background_color, opacity: 1})

    const refTop = useRef()

    const classes = useStyles({ color })

    useEffect(() => {
        function show() {
            const top = document.documentElement.scrollTop || document.body.scrollTop

            if (top > 3000) refTop.current.style.visibility = 'visible'
            else refTop.current.style.visibility = 'hidden'
        }
        
        window.addEventListener('scroll', show)
    }, [refTop])

    return (
        <>
        <GlobalStyles background_color={_background_color} background_cover={background_cover} background_cover_opacity={background_cover_opacity}/>
        <Container maxWidth="md">
            {children}
        </Container>
        <IconButton onClick={scroll.scrollToTop}>
            <ArrowUpwardIcon className={classes.top} ref={refTop}/>
        </IconButton>
        </>
    )
}

export default Component