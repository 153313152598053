import { memo } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import fixedWidthString from 'fixed-width-string'
import { useIntl } from 'react-intl'
import { SurveyQuestionModuleProps, ModuleMatrix } from 'gql/survey_question_modules'
import { JumpNo } from 'gql/jump'
import { MySelect, MyTypography, OnChangeChildsProps } from './ItemRules'
import SelectMatrixAnswerComponent from './SelectMatrixAnswer'
import { MyMenuItem } from './SelectModule'

interface Props extends OnChangeChildsProps {
    typename: string;
    question: SurveyQuestionModuleProps;
    jump_rule_child_no: JumpNo;
    survey_module_nos: number[];
    survey_module_answer_no: number;
}

export const MyBox = withStyles({
    root: {
        flexGrow: 1,
        '& > * + *': {
            marginTop: 12
        }
    }
})(Box)

function SelectMatrixModuleComponent(props: Props) {
    const { jump_rule_child_no, question, survey_module_nos, onChange } = props

    const { formatMessage: f } = useIntl()

    const { _modules } = question

    return (
        <MyBox>
            <MySelect
                error={survey_module_nos.length === 0} 
                displayEmpty
                multiple 
                value={survey_module_nos} 
                style={{display: 'flex'}}
                label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMatrixModule.label'})}
                onChange={(e) => {
                    onChange('survey_module_nos', jump_rule_child_no, e.target.value as number[])
                }}
                renderValue={(selected: number[]) => {
                    let render = ''

                    const len = selected.length

                    if (len > 0) {
                        const _self = _modules.find((c: ModuleMatrix) => c.survey_module_no === selected[0]) as ModuleMatrix | undefined

                        if (_self) {
                            const { indexs, column } = _self
                            render = fixedWidthString(`${indexs}. ${column}`, 30)
                            
                            if (len > 1) render = f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectMatrixModule.render'}, { render, len: (len - 1)}) 
                        }
                    }
                    
                    return render
                }}
            >
                {
                    _modules.map((c: ModuleMatrix) => {
                        const { indexs, column, survey_module_no } = c
                        const label = fixedWidthString(`${indexs}. ${column}`, 30)

                        return (
                            <MyMenuItem key={survey_module_no} value={survey_module_no}>
                                <Checkbox checked={survey_module_nos.indexOf(survey_module_no) > -1} />
                                <MyTypography>{label}</MyTypography>
                            </MyMenuItem>
                        )
                    })
                }
            </MySelect>
            <SelectMatrixAnswerComponent {...props}/>
        </MyBox>
    )
}

export default memo(SelectMatrixModuleComponent)