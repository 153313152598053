import { useState, createContext, useCallback, memo } from 'react'
import Box from '@material-ui/core/Box'
import { ID, Kind } from 'gql/jump'
import { QuestionType } from 'gql/survey_question_modules'
import Provider from './Provider'
import MyFunctionsComponent from './MyFunctions'

export interface NodeIDProps {
    kind: Kind;
    id: ID;
    number: number;
    question_type: QuestionType; // 페이지에서는 사용안된다
}

export interface OnChangeNodeIDProps extends Omit<NodeIDProps, 'question_type'>{
    question_type?: QuestionType;
}

// 그냥 디폴트로 넣어준다. id가 0이면 모두 보여준거다. 나머지 값은 의미없다.
const nodeIDInit = {
    kind: Kind.Question,
    id: 0,
    number: 0,
    question_type: QuestionType.Question
}

export const DiagramNodeIDContext = createContext({
    nodeID: nodeIDInit
})

export const DiagramNodeIDEventContext = createContext({
    onChangeNodeID: (props:OnChangeNodeIDProps | null) => {}
})

const ContextComponent = () => {
    // 타겟 노드 선택.
    const [ nodeID, setNodeID ] = useState<NodeIDProps>(nodeIDInit)

    const handleChangeNodeID = useCallback((props:OnChangeNodeIDProps | null) => {
        if (!props ) {
            setNodeID(nodeIDInit)
            return 
        }
        const { kind, id, number, question_type } = props

        setNodeID({ kind, id, number, question_type: question_type || QuestionType.Question})
    }, [])

    return (
        <DiagramNodeIDContext.Provider value={{ nodeID }}>
            <DiagramNodeIDEventContext.Provider value={{onChangeNodeID: handleChangeNodeID }}>
                <Box style={{ height: '100%' }}>
                    <MyFunctionsComponent/>
                    <Provider/> 
                </Box>
            </DiagramNodeIDEventContext.Provider>
        </DiagramNodeIDContext.Provider>
    )
}

export default memo(ContextComponent)