import { memo, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { isEqual, sortBy } from 'lodash-es'
import Button from '@material-ui/core/Button'
import { CHANGE } from 'reducer/survey/create/logic/jumpPointError'
import { CREATE_JUMP_EXAMPLE_COUNT, JumpExampleCountTextProps, JumpExampleCountProps } from 'gql/jump_example_count'
import BeforeUnload from 'styled/BeforeUnload'
import { InitContext, ExampleCountEventContext } from 'container/Survey/Create/Logic/Jump/ExampleCountContext'

function getIsEqualExampleCount(db: JumpExampleCountProps[], state: JumpExampleCountProps[]) {
    return isEqual(sortBy(db, 'jump_example_count_no'), sortBy(state, 'jump_example_count_no'))
}

function getIsEqualExampleCountText(db: JumpExampleCountTextProps[], state: JumpExampleCountTextProps[]) {
    return isEqual(sortBy(db, 'jump_example_count_text_no'), sortBy(state, 'jump_example_count_text_no'))
}

function ButtonSaveComponent() {
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const { jumpExampleCount, jumpExampleCountText } = useContext(InitContext)
    const { refetchJumpExampleCountWithRelation} = useContext(ExampleCountEventContext)

    const { stateExampleCount, stateExampleCountText }  = useSelector((state:RootState) => ({
        stateExampleCount: state.surveyCreateLogicJumpExampleCount,
        stateExampleCountText: state.surveyCreateLogicJumpExampleCountText
    }))

    const [ createJumpPoint ] = useMutation(CREATE_JUMP_EXAMPLE_COUNT, {
        onCompleted: () => {
            refetchJumpExampleCountWithRelation()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleSave() {
        const isTextError = stateExampleCountText.rows.find(c => c.value === '')

        if (isTextError) {
            const { survey_question_no } = isTextError
            dispatch({ type: CHANGE, survey_question_no })
            return
        }
        
        dispatch({ type: BACKDROPSHOW })
        createJumpPoint({ 
            variables: {
                survey_no,
                jumpExampleCount: stateExampleCount.rows, 
                jumpExampleCountText: stateExampleCountText.rows, 
            }
        })
    }

    const disabled = getIsEqualExampleCount(jumpExampleCount, stateExampleCount.rows) && getIsEqualExampleCountText(jumpExampleCountText, stateExampleCountText.rows)

    return (
        <>
        <Button 
            variant="contained" 
            color="primary" 
            disabled={disabled}
            onClick={handleSave}
        >
           {f({id: 'component.Survey.Create.Logic.Jump.Ending.ButtonSave.button.save'})}
        </Button>
        {!disabled && <BeforeUnload/>}
        </>
    )
}

export default memo(ButtonSaveComponent)