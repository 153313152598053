import { useContext, useState, useCallback, useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducer'
import { CHANGE as CHANGE_TEXT } from 'reducer/survey/create/logic/jumpPointText'
import { CHANGE } from 'reducer/survey/create/logic/jumpPoint'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { useIntl } from 'react-intl'
import { getDefaultQuestion, getModuleIcon } from 'utils/survey'
import { JumpNo } from 'gql/jump'
import { JumpPointProps, JumpPointTextProps } from 'gql/jump_point'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import Tooltip from 'styled/Custom/Tooltip'
import { PointContext, parseJumpPointTextRow, parseJumpPointRow } from 'container/Survey/Create/Logic/Jump/PointContext'
import { MyTypography } from '../Lists/ItemHeart'
import QuestionItemsBase from './QuestionItemsBase'
import QuestionItemsImg from './QuestionItemsImg'
import QuestionItemsMatrix from './QuestionItemsMatrix'
import QuestionItemsText from './QuestionItemsText'
import QuestionItemsRank from './QuestionItemsRank'
import QuestionItemsRankImg from './QuestionItemsRankImg'
import { MyButton, MyDivder, MyBox, MyPaper, MyListItem, getComponentType } from '../ExampleCount/QuestionItemSplit'
import ScrollerTargetComponent from './ScrollerTarget'

interface Props {
    question: SurveyQuestionModuleProps;
}

export type OnChange = (a: JumpPointProps) => void
export type OnChangeText = (a: Omit<JumpPointTextProps, 'survey_question_no'>) => void
export type OnCreateText = () => void
export type OnRemoveText = (a: JumpNo ) => void

// 완료글 선택을 했는지 체크
function isValue(rows:JumpPointProps[]) {
    const len = rows.length
    for (let i=0; i<len; i++) {
        const row = rows[i]
        const { point } = row
        if (point > 0) return true
    }

    return false
}

function ItemSplitComponent(props: Props) {
    const { question } = props
    const { _question } = question
    const { survey_question_no, number, pure_question, question_type, module_type } = _question

    const dispatch = useDispatch()

    const allPoint = useSelector((state: RootState) => state.surveyCreateLogicJumpPointAllPoint)

     // 전체 삭제시, 전체 초기화
     const initState = useSelector((state: RootState) => state.surveyCreateLogicJumpPointInit )

    const { jumpPointRowsKeyQuestionNo, jumpPointTextRowsKeyQuestionNo } = useContext(PointContext)

    // 해당문항의 값 
    const [ rows, setRows ] = useState(jumpPointRowsKeyQuestionNo[survey_question_no] || [])
    // 주관식 문항에서 사용된다.
    const [ rowsText, setRowsText ] = useState(jumpPointTextRowsKeyQuestionNo[survey_question_no] || [])

    const [ collapse, setCollapse ] = useState(isValue(rows))

    const { formatMessage: f } = useIntl()

    const componentType = getComponentType(module_type)

    const label = getDefaultQuestion({f, number, question: pure_question, question_type})

    const modules = getModuleIcon(f)
    
    // state 값 변경.
    const handleChange:OnChange = useCallback((row) => {
        setRows(prev => {
            return prev.map(c => {
                return c.jump_point_no !== row.jump_point_no ? c : row
            })
        })
    }, [])

    const handleChangeText:OnChangeText = useCallback((args) => {
        const { jump_point_text_no, value } = args

        setRowsText(prev => {
            return prev.map(c => {
                return c.jump_point_text_no !== jump_point_text_no ? c : {
                    ...c,
                    value
                }
            })
        })
    }, [])

    // 현재는 주관식에만 사용된다.
    const handleCreateText:OnCreateText = useCallback(() => {
        
        const jumpPointTextRow = parseJumpPointTextRow({ survey_question_no })
        const { jump_point_text_no } = jumpPointTextRow

        const jumpPointRow0 = parseJumpPointRow({ survey_question_no, jump_point_text_no, point: 1, module_type })
        const jumpPointRow1 = parseJumpPointRow({ survey_question_no, jump_point_text_no, module_type })
        const jumpPointRow2 = parseJumpPointRow({ survey_question_no, jump_point_text_no, module_type })
        const jumpPointRow3 = parseJumpPointRow({ survey_question_no, jump_point_text_no, module_type })

        setRowsText(prev => ([
            ...prev,
            jumpPointTextRow
        ]))

        setRows(prev => ([
            ...prev,
            jumpPointRow0,
            jumpPointRow1,
            jumpPointRow2,
            jumpPointRow3
        ]))
    }, [survey_question_no, module_type])

    // 현재는 주관식에만 사용된다.
    const handleRemoveText:OnRemoveText = useCallback((jump_point_text_no) => {
        setRowsText(prev => prev.filter(row => row.jump_point_text_no !== jump_point_text_no))
        setRows(prev => prev.filter(row => row.jump_point_text_no !== jump_point_text_no))
    }, [])

    // 에러가 있을경우 펼침
    const handleOpenCollapse = useCallback(() => {
        setCollapse(true)
    }, [])

    // 전체 포인트 변경
    useEffect(() => {
        if (allPoint.point !== '' && !Number.isNaN(Number(allPoint.point))) {
            setRows(prev => {
                return prev.map(c => ({
                    ...c,
                    point: Number(allPoint.point)
                }))
            })
        }
    }, [allPoint])
    
    // 주관식 내용 변경시 발행
    useEffect(() => {
        dispatch({ type: CHANGE_TEXT, O_CHANGE: { survey_question_no, rows: rowsText } })
    }, [rowsText, survey_question_no, dispatch])

    // 포인트 목록 변경시 발행
    useEffect(() => {
        dispatch({ type: CHANGE, O_CHANGE: { survey_question_no, rows } })
    }, [rows, survey_question_no, dispatch])

    // 전체 삭제 시 초기화
    useEffect(() => {
        if (initState.val) {
            setRows(prevState => prevState.map(row => ({...row, point: 0})))
            setRowsText([])
            setCollapse(false)
        }
    }, [initState.val])

    const self = modules.find((c: any) => c.type === module_type)

    if (!self) return null
    
    const Icon = self.icon

    return (
        <MyPaper variant="outlined">
            <MyListItem>
                <ListItemIcon>
                    <Tooltip title={self.name}>
                        <Icon fontSize="small"/>
                    </Tooltip>
                </ListItemIcon>
                <ListItemText>
                    <MyTypography>{label}</MyTypography>
                </ListItemText>
                <ListItemIcon>
                    <MyButton 
                        collapse={collapse ? 1 : 0}
                        variant="contained" 
                        disableElevation size="small" 
                        onClick={() => setCollapse(prevState => !prevState)}
                    />
                </ListItemIcon>
            </MyListItem>
            <ScrollerTargetComponent survey_question_no={survey_question_no} onOpen={handleOpenCollapse}/>
            <MyDivder display={collapse ? 1 : 0}/>
            <MyBox display={collapse ? 1 : 0}>
                {
                    (componentType === 'base' || componentType === 'point') ? (
                        <QuestionItemsBase componentType={componentType} question={question} rows={rows} onChange={handleChange}/> 
                    ) : 
                    (componentType === 'img') ? (
                        <QuestionItemsImg question={question} rows={rows} onChange={handleChange}/>
                    ) : 
                    (componentType === 'matrix' || componentType === 'pointMatrix') ? (
                        <QuestionItemsMatrix componentType={componentType} question={question} rows={rows} onChange={handleChange}/>
                    ) : 
                    (componentType === 'text') ? (
                        <QuestionItemsText 
                            rowsText={rowsText} 
                            rows={rows} 
                            onChange={handleChange} 
                            onChangeText={handleChangeText} 
                            onCreateText={handleCreateText}
                            onRemoveText={handleRemoveText}
                        />
                    ) : 
                    (componentType === 'rank') ? (
                        <QuestionItemsRank question={question} rows={rows} onChange={handleChange}/> 
                    ) : 
                    (componentType === 'rankImg') ? (
                        <QuestionItemsRankImg question={question} rows={rows} onChange={handleChange}/>
                    ) : null
                }
            </MyBox>
        </MyPaper>
    )
}

export default memo(ItemSplitComponent)