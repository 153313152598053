
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { DELETE_JUMP_FLOW } from 'gql/jump_flow'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import CachedIcon from '@material-ui/icons/Cached'
import oc from 'open-color'
import { OnChangeElements } from './Component';
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    survey_no: number;
    onChangeElements: OnChangeElements;
}

const MyBox = withStyles({
    root: {
        position: 'absolute', 
        bottom: 120,
        left: 8,
        padding: 3,
        background: 'white',
        border: `2px solid ${oc.gray[1]}`,
        cursor: 'pointer',
        zIndex: 5
    }
})(Box)

function ResetComponent(props: Props) {
    const { survey_no, onChangeElements } = props

    const { formatMessage: f } = useIntl()

    const [ deleteJumpFlow ] = useMutation(DELETE_JUMP_FLOW, {
        onCompleted: () => {
            onChangeElements()
        }
    })

    return (
        <Tooltip title={f({id: 'component.Survey.Create.Logic.Jump.Diagram.Reset.tooltip'})} placement="top">
            <MyBox onClick={() => {
                deleteJumpFlow({ 
                    variables: {
                        survey_no
                    }
                })
            }}>
                <CachedIcon fontSize="small" style={{display: 'block'}}/>
            </MyBox>
        </Tooltip>
    )
}

export default ResetComponent