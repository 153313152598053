import { List, ListItem, Switch, Divider, ListItemIcon, ListItemText } from '@material-ui/core'
import { useIntl } from 'react-intl'
import CreateBox from 'styled/Survey/CreateBox'

export default (props) => {
    const { formatMessage: f } = useIntl()
    
    const { mark_number, mark_required, on } = props

    return (
        <CreateBox className="dense">
            <List>
                <ListItem>
                    <ListItemText>{f({id: 'component.Survey.Create.Setting.Component.lists.0'})}</ListItemText>
                    <ListItemIcon><Switch color="primary" checked={mark_number} onChange={on.changeNumber}/></ListItemIcon>
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemText>{f({id: 'component.Survey.Create.Setting.Component.lists.1'})}</ListItemText>
                    <ListItemIcon><Switch color="primary" checked={mark_required} onChange={on.changeRequired}/></ListItemIcon>
                </ListItem>
                <Divider/>
            </List>
        </CreateBox>        
    )
}