import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import { withStyles } from '@material-ui/core/styles'
import { openHelpPage } from 'component/Survey/Workspace/Left/Help/ItemHelp'

const MyBox = withStyles({
    root: {
        display: 'table',
        margin: '0 auto',
        marginTop: -100
    }
})(Box)

const Title = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))(Typography)

function HelpComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <MyBox>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <Title variant='h6' style={{marginBottom: 35}}>
                    {f({id: 'component.Survey.Create.Module.Help.title'})}
                </Title>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
                    <EmojiObjectsIcon fontSize="small" style={{color: oc.orange[3]}}/>
                    <Typography variant="body2">
                        {f({id: 'component.Survey.Create.Module.Help.tip'})}
                    </Typography>
                </div>

                <Button variant="contained" color="inherit" size="large" onClick={openHelpPage} style={{marginLeft: 5}}>
                    {f({id: 'component.Survey.Create.Module.Help.button'})}
                </Button>
            </div>
        </MyBox>
    )
}

export default memo(HelpComponent)