import { memo } from 'react'
import { useIntl } from 'react-intl'
import { getModuleIcon } from 'utils/survey'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from 'styled/Custom/Tooltip'
import { ImgRowsProps, isImageQuestion } from './Items'
import TableHeadCellPhotoComponent from './TableHeadCellPhoto'
import TableHeadCellTextComponent from './TableHeadCellText'

interface Props {
    tooltip: string;
    field: string;
    labelArray: string[];
    imgRows: ImgRowsProps;
    __typename: string;
    isComment: boolean;
}

export const MyBox = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& > div:last-child': {
            flexGrow: 1
        }
    }
}))(Box)

export const IconComponent = (props: { f: any, typename: string, isComment?: boolean }) => {
    const { f, typename, isComment } = props

    const icons = getModuleIcon(f)

    const icon = icons.find(c => c.type === typename)

    const Icon = icon ? icon.icon : null

    if (!Icon) return null

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Paper variant="outlined" style={{ display: 'table', padding: 5 }}>
                <Icon color="action" style={{ width: 16, height: 16, display: 'block' }} />
            </Paper>            
            {
                isComment &&  <Typography style={{ fontSize: 7, fontWeight: 900, position: 'absolute', bottom: -10 }}>COMMENT</Typography>
            }
        </div>
    )
}

function TableHeadCellComponent(props: Props) {
    const { tooltip, field, labelArray, imgRows, __typename, isComment } = props

    const { formatMessage: f } = useIntl()

    let newTooltip = tooltip
    let src: string | undefined = undefined
    if (isImageQuestion.includes(__typename)) {
        let newField = field.replace('Q', '')
        if (isComment) newField = newField.replace('-comment', '')

        src = imgRows[newField]

        if (src) newTooltip = `<img src="${src}" alt="listovey" style="max-width: 200px; border-radius: 3px;  display: block; margin-top: 3px;" />${newTooltip}`
    }

    return (
        <MyBox>
            <div style={{ position: 'relative' }}>
                <IconComponent f={f} typename={__typename} isComment={isComment} />
            </div>
            <TableHeadCellPhotoComponent src={src} tooltip={newTooltip} />
            <div>
                <Tooltip title={newTooltip}>
                    <span>
                        {
                            labelArray.map((c, i) => (
                                <TableHeadCellTextComponent key={`${i}-${c}`} field={field} label={c} />
                            ))
                        }
                    </span>
                </Tooltip>
            </div>
        </MyBox>
    )
}

export default memo(TableHeadCellComponent)