import { memo, useState, useEffect  } from 'react'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { getDefaultQuestion, getModuleIcon } from 'utils/survey'
import { useIntl } from 'react-intl'
import { JumpProps } from 'gql/jump'
import { WrapperStyled } from './ItemPage'
import ItemHeartComponent from './ItemHeart'

interface Props extends SurveyQuestionModuleProps {
    show: boolean;
    jumpRow: JumpProps;
}

function ItemPageComponent(props: Props) {
    const { show, _question, jumpRow } = props
    const { survey_question_no, pure_question, number, module_type, question_type } = _question

    const { formatMessage: f } = useIntl()

    const label = getDefaultQuestion({f, number, question: pure_question, question_type})

    const modules = getModuleIcon(f)

    /**
     * 초기값을 state에 담아놓는다
    */
    const [ stateJumpRow, setStateJumpRow ] = useState(jumpRow)

    /**
     * 저장후 jumpRow이 바뀌면 다시 변경해준다.
     * 페이지 보기 값 변경시에도 변경없게 하는목적이 더크다. (state로 관리안하면 페이지값 변경시 jumpRow 값이 바뀐다(DB에 저장안된 값들..))
     */
     useEffect(() => {
        setStateJumpRow(jumpRow)
    }, [jumpRow, survey_question_no ])
    
    const self = modules.find((c: any) => c.type === module_type)

    if (!self) return null

    return (
        <WrapperStyled show={show}>
            <ItemHeartComponent 
                icon={self.icon} 
                label={label[0]} 
                tooltip={self.name} 
                jumpRow={stateJumpRow} 
            />
        </WrapperStyled>
    )
}

export default memo(ItemPageComponent)