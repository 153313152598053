import { memo } from 'react'
import { useIntl } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FolderIcon from '@material-ui/icons/Folder'
import { withStyles } from '@material-ui/core/styles'
import { parseHtmlIntl } from 'ts-utils'

type Item = {
    primary: string;
    secondary: string;
}

interface Props {
    title: string;
    items: Item[];
    open: boolean;
    onClose: () => void;
}

const MyListItemIcon = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))(ListItemIcon)

function DialogHelpComponent(props: Props) {
    const { title, items, open, onClose } = props

    const { formatMessage: f } = useIntl()

    return (
        <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <List>
                {
                    items.map((item, i) => (
                        <ListItem key={i}>
                            <MyListItemIcon>
                                <FolderIcon/>
                            </MyListItemIcon>
                            <ListItemText primary={parseHtmlIntl(item.primary)} secondary={parseHtmlIntl(item.secondary)}/>
                        </ListItem>
                    ))
                }
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>{f({id: 'component.Survey.Create.Logic.Jump.Point.DialogHelp.button.close'})}</Button>
        </DialogActions>
    </Dialog>
    )
}

export default memo(DialogHelpComponent)