import { useState, ReactElement, ElementType, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { CHANGE } from 'reducer/survey/create/logic/jump'
import { v4 as uuidv4 } from 'uuid'
import { Kind, JumpNo, JumpProps, Method, JumpRuleProps } from 'gql/jump'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import oc from 'open-color'
import { isEqual } from 'lodash-es'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'
import { scroller, Element } from 'react-scroll'
import Tooltip from 'styled/Custom/Tooltip'
import { getKindColor } from '../Diagram/nodeClass'
import { scrollerObj } from './Component'
import ItemRulesComponent, { createChilds } from './ItemRules'
import ItemDefaultComponent from './ItemDefault'
import ItemVisibleComponent from './ItemVisible'
import ButtonCreateRuleComponent from './ButtonCreateRule'

interface Props extends UsePrevStateProps {
    icon: ElementType;
    label: string | ReactElement;
    tooltip: string;
    jumpRow: JumpProps;
}

// 규칙추가
export type OnCreateRule = () => void
// 규칙제거
export type OnRemoveRule = (jump_rule_no: JumpNo) => void
// 규칙변경
export type OnChangeRule = (jump_rule_no: JumpNo, row:JumpRuleProps) => void

// 문항 방법 (이동, 보이기 숨기기)
export type OnChangeElseMethod = (a: Method) => void;

// 문항 방법 (이동, 보이기 숨기기)
export type OnChangeElseSurveyQuestionNos = (a: number[]) => void;

function creatJumpRule(jump_no: JumpNo, kind: Kind) {
    const jump_rule_no = uuidv4()
    const childs = createChilds({ kind, jump_rule_no })

    return {
        jump_rule_no,
        jump_no,             // primary key,
        method: Method.Move,
        survey_question_nos: [],  // 타겟 설문 번호
        childs: [ childs ]
    }
}

// 방법 변경시 선택값 초기화
export function getMethodSurveyQuestionNos() {
    return []
}

const useStyles = makeStyles((theme) => ({
    collapse: ({ open }: { open: boolean }) => {
        return {
            backgroundColor: oc.gray[0],
            padding: open ? 15 : 0,
            transition: 'all .3s'
        }
    },
    list: {
        width: '100%',
        backgroundColor: oc.gray[4],
        borderRadius: 5,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
}))

export const MyTypography = withStyles({
    root: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden',
        fontSize: 15
    }
})(Typography)

function ItemHeartComponent(props: Props) {
    const { icon, label, tooltip, jumpRow, usePrevState } = props

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const [ stateRow, setStateRow ] = useState<JumpProps>(jumpRow)

    const prevStateRow = usePrevState(stateRow)

    const { jump_no, kind, id, method, rules, survey_question_nos } = stateRow

    const target = `${kind}-${id}`

    const rulesLength = rules.length
    const surveyQuestionNosLength = survey_question_nos.length

    const { jumpNull, jumpCollapse, jumpFocus } = useSelector((state: RootState) => ({
        jumpNull: state.surveyCreateLogicJumpNull,
        jumpCollapse: state.surveyCreateLogicJumpCollapse,
        jumpFocus: state.surveyCreateLogicJumpFocus
    }))

    const classes = useStyles({ open })

    const handleClick = () => {
      setOpen(!open)
    }
    
    // item컴포넌트에 rule item에대한 값이 변경된걸 반영해야하기 때문에 리듀서 jump에서 최신값을 가져와 추가해준다.
    const handleCreateRule = useCallback(() => {
        const newRule = creatJumpRule(jump_no, kind)

        setStateRow(prevRow => {
            const { rules } = prevRow

            const newRules = {
                ...prevRow,
                rules: [
                    ...rules,
                    newRule
                ]
            }

            return newRules
        })
    }, [ jump_no, kind ])

    const handleRemoveRule = useCallback((jump_rule_no: JumpNo) => {
        setStateRow(prevRow => {
            const { rules } = prevRow

            const newRules = {
                ...prevRow,
                rules: rules.filter(c => c.jump_rule_no !== jump_rule_no)
            }

            return newRules
        })
    }, [])

    // rule 변경 (item에서 rule 값 및 childs 변경시 사용)
    const handleChangeRule = useCallback((jump_rule_no: JumpNo, row: JumpRuleProps) => {
        setStateRow(prevRow => ({
            ...prevRow,
            rules: prevRow.rules.map(rule => jump_rule_no === rule.jump_rule_no ? row : rule)
        }))
    }, [])


    // 디폴트 문항 방법 (이동, 보이기, 숨기기)
    const handleChangeElseMethod = useCallback((method: Method) => {
        setStateRow(prevRow => ({
            ...prevRow,
            method,
            survey_question_nos: getMethodSurveyQuestionNos()
        }))
    }, [])


    // 디폴트 문항 가기 변경
    const handleChangeElseSurveyQuestionNos = useCallback((survey_question_nos: number[]) => {
        setStateRow(prevRow => ({
            ...prevRow,
            survey_question_nos
        }))
    }, [])

    // 저장버튼 클릭시 미설정된 값이 있다면 collapse를 펼치고 포커스를 준다.
    const isNull = ((jumpNull.id && jumpNull.kind) && (id === jumpNull.id && kind === jumpNull.kind)) 
  
    useEffect(() => {
        if (isNull) {
            setOpen(true)
            scroller.scrollTo(target, {
                ...scrollerObj.scrollTo,
                containerId: scrollerObj.id
            })
        }
    }, [jumpNull, isNull, target])


    useEffect(() => {
        if (Number(jumpFocus.id) === Number(id)) {
            setOpen(true)
            scroller.scrollTo(target, {
                ...scrollerObj.scrollTo,
                containerId: scrollerObj.id
            })
        }
    }, [jumpFocus, id, target])

    useEffect(() => {
        if (jumpCollapse.open === true) {
            if (rulesLength > 0 || surveyQuestionNosLength > 0) {
                setOpen(true)
            }
        }
    }, [ rulesLength, surveyQuestionNosLength, jumpCollapse ])


    // 전체 닫기 누르면 무조건 다 닫히게한다. jumpCollapse 를 참조하는 이유는 새로운 object로 참조하기 위해서
    useEffect(() => {
      
        if (jumpCollapse.open === false) {
            setOpen(false)
            return 
        } 
    }, [ jumpCollapse ])


    // 전체 해제하면 해당 아이템값을 초기화 시켜줘야한다.
    useEffect(() => {
        setStateRow(jumpRow)
    }, [jumpRow])

    // rows 추가 삭제시 리듀서에 보낸다.
    useEffect(() => {
        // 초기 로딩시에는 리듀서로 안보내고 변경값이 있을때 추가한다. (최초는 container에서 저장하기때문)
        if (prevStateRow && !isEqual(stateRow, prevStateRow)) {
            dispatch({
                type: CHANGE,
                O_CHANGE: {
                    jump_no,
                    row: stateRow
                }
            })
        }
    }, [ jump_no, stateRow, prevStateRow, dispatch ])

    const Icon = icon

    return (
        <>
        <Element name={target}/>
        <ListItem button onClick={handleClick}>
            <ListItemIcon>
                <Tooltip title={tooltip}>
                    <Icon fontSize="small" style={{color: ((rulesLength > 0 || surveyQuestionNosLength > 0) ? getKindColor(kind) : oc.gray[6])}}/>
                </Tooltip>
            </ListItemIcon>
            <ListItemText style={{paddingRight: 220}}>
                <MyTypography color={isNull ? "secondary" : "initial"} style={{fontSize: 13}}>{label}</MyTypography>
            </ListItemText>
            {
                kind === Kind.Question && (
                    <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>
                        <ItemVisibleComponent survey_question_no={id}/>
                    </ListItemSecondaryAction>
                )
            }
            <ListItemSecondaryAction onClick={handleClick} style={{cursor: 'pointer'}}>{open ? <ExpandLess /> : <ExpandMore />}</ListItemSecondaryAction>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapse}>
            <List component="div" dense className={classes.list}>
            {
                rules.map((c) => {
                    return (
                        <ItemRulesComponent 
                            key={c.jump_rule_no} 
                            id={id} 
                            kind={kind} 
                            row={c} 
                            onRemoveRule={handleRemoveRule} 
                            onChangeRule={handleChangeRule}
                        />
                    )
                })
            }
            <ButtonCreateRuleComponent id={id} kind={kind} onCreate={handleCreateRule}/>
            <ItemDefaultComponent
                kind={kind}
                id={id}
                method={method}
                jump_no={jump_no}
                survey_question_nos={survey_question_nos}
                rulesLength={rulesLength}
                onChangeElseMethod={handleChangeElseMethod}
                onChangeElseSurveyQuestionNos={handleChangeElseSurveyQuestionNos}
            />
            </List>
        </Collapse>
        </>
    )
}

export default withPrevState(memo(ItemHeartComponent))