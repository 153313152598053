import { memo, ReactNode } from 'react'
import TableCell from '@material-ui/core/TableCell'

export interface Props {
    className: string;
    children: ReactNode;
}

function TableHeadCellComponent(props: Props) {
    const { className, children } = props

    return (
        <TableCell scope="row" className={className}>
            {children}
        </TableCell>
    )
}

export default memo(TableHeadCellComponent)