import { memo } from 'react'
import Chart from 'styled/Chart'
import { GraphBox } from './Component'
import RefererDownloadComponent from './RefererDownload'

interface Props {
    chartRefererDomain: object;
    refererDomainCount: object;
    id: string;
}

function RefererConturyCityComponent(props: Props) {
    const { chartRefererDomain, refererDomainCount, id } = props

    return (
        <>
       <Chart chartType="bar" {...chartRefererDomain}/>
       <RefererDownloadComponent data={refererDomainCount} type="domain"/> 
        <GraphBox width={880} height={240}>
            <Chart id={id} chartType="bar" {...chartRefererDomain}/>
        </GraphBox>
        </>
        
    )
}

export default memo(RefererConturyCityComponent)