import { memo } from 'react'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'
import { getBackgroundPath, getBackgroundImageOpacity } from 'utils/survey'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Tooltip from 'styled/Custom/Tooltip'
import { Props as ParentProps, OnApplyProps } from '../Component'

interface Props extends OnApplyProps {
    survey_designs_no: number;
    title: string;
    template: boolean;
    onApply: ParentProps['onApply'];
    onOpen: ParentProps['onOpenPop'];
}

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 'calc(100% - 39px)'
    
    },
    button: {
        marginTop: 4,
        width: 30,
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button2: {
        width: 20,
        height: 10
    },
    more: {
        marginRight: 5
    }
})

const MyCard = withStyles(theme => ({
    root: (props: { background_color: string, background_image: string, background_filter: string, background_opacity: number }) => {
        const { background_color, background_image, background_filter, background_opacity } = props

        let style: any = { backgroundColor: `rgba(${background_color})` }

        if (background_image) {
            const backgroundImage = getBackgroundPath({ background_image, background_filter })
            const background = getBackgroundImageOpacity({ background_opacity, background_image: backgroundImage })
            style = { ...style, backgroundImage: background }
        }

        return {
            ...style,
            position: 'relative',
            backgroundSize: '100% 100%',
            height: 146,
            '& .button': {
                opacity: isMobile ? 1 : 0,
                transition: 'all .5s'
            },
            '&:hover': {
                '& .button': {
                    opacity: 1
                },  
            },
            [theme.breakpoints.down('sm')]: {
                height: 186
            },
            [theme.breakpoints.down('xs')]: {
                height: 126
            }
        }
    }
}))(Card)

const BottomBox = withStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'white',
        padding: '5px 0 3px 6px'
    }
})(Box)

const MyButton = withStyles({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 10,
        padding: '0 !important',
        height: 22,
        fontSize: 11,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
    }
})(Button)

function ItemComponent(props: Props) {
    const { 
        survey_designs_no, 
        title, 
        line_color, 
        background_size, 
        background_opacity, 
        background_color, 
        background_image, 
        background_filter,
        numbering,
        question, 
        font_family, 
        answer, 
        answer_button, 
        answer_sbutton,
        template,
        onApply, 
        onOpen 
    } = props

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <Grid item xs={4}>
            <MyCard 
                background_color={background_color}
                background_image={background_image}
                background_filter={background_filter}
                background_opacity={background_opacity}
            >
                <CardContent className={classes.content}>
                    <Typography variant="body2" style={{ fontFamily: font_family, color: `rgba(${numbering})`, fontSize: 14 }}>{f({id: 'component.Survey.Create.Design.My.Component.numbering'})}</Typography>
                    <Typography variant="body2" style={{ fontFamily: font_family, color: `rgba(${question})`, fontSize: 14 }}>{f({id: 'component.Survey.Create.Design.My.Component.question'})}</Typography>
                    <Typography variant="body2" style={{ fontFamily: font_family, color: `rgba(${answer})`, fontSize: 14, marginBottom: 2 }}>{f({id: 'component.Survey.Create.Design.My.Component.answer'})}</Typography>
                    <Paper className={classes.button} style={{background: `rgba(${answer_button})`}}>
                        <Paper className={classes.button2}  style={{background: `rgba(${answer_sbutton})`}}></Paper>
                    </Paper>
                </CardContent>
                <Divider/>
                <BottomBox>
                    <Tooltip title={title}>
                        <Typography variant="subtitle2" noWrap>
                            {title}
                        </Typography>
                    </Tooltip>
                    {
                        survey_designs_no && (
                            <IconButton size="small" className={classes.more} onClick={(e) => onOpen(e, survey_designs_no, template)}>
                                <MoreHorizIcon color="primary"/>
                            </IconButton>
                        )
                    }
                </BottomBox>
                <MyButton 
                    className="button"
                    variant="contained"
                    size="small"
                    onClick={() => onApply({
                        line_color,
                        background_size,
                        background_opacity,
                        background_color,
                        background_image, 
                        numbering,
                        question,
                        font_family,
                        answer, 
                        answer_button, 
                        answer_sbutton,
                        background_filter
                    })}
                >
                    {f({id: 'component.Survey.Create.Design.My.Item.button.apply'})}
                </MyButton>
            </MyCard>
        </Grid>
    )
}

export default memo(ItemComponent)