/**
 * _01 셀렉트 버튼
 */

import { memo } from 'react'
import { QuestionOptionProps } from '../Component'
import { OnChangeChoice } from '../List'
import ChoiceSelecBoxComponent from './ChoiceSelectBox'

export interface Props {
    keyValue: string;
    choices: Exclude<QuestionOptionProps['choices'], undefined>;
    value: number[];
    onChange: OnChangeChoice;
}

function Component(props: Props) {
    const { keyValue, choices, value, onChange } = props
    
    if (!choices) return null

    return <ChoiceSelecBoxComponent keyValue={keyValue} choices={choices} value={value} onChange={onChange} />

}

export default memo(Component)