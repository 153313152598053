import { memo } from 'react'
import { ModuleImg } from 'gql/survey_question_modules'
import ColumnValueComponent from './ColumnValue'
import { Props as ParentProps } from './Body'
import { useStyles as useTextItemStyles } from './TextItem'

interface Props {
    survey_question_no: number;
    answer: ParentProps['answer'];
    typename: string;
    _modules: ModuleImg[];
}

function ChoiceWithCommentComponent(props: Props) {
    const { survey_question_no, answer, _modules, typename } = props

    const classes = useTextItemStyles({})

    const value = answer[`Q${survey_question_no}`]
    if (!value) return null

    const { choice, comment } = value
    let imgRows:{ [a: number]: string } = {}

    if (typename === '_12' || typename === '_13') {
        imgRows = _modules.reduce((acc: { [a: number]: string }, c) => {
            const { indexs, src } = c
            acc[indexs] = src 

            return acc
        }, {})
    }

    return (
        <div className={classes.root}>
            {
                choice.filter(c => c[0] !== '').map((c, i) => {
                    const [ index, text ] = c
                    const commentValue = comment[index]

                    return <ColumnValueComponent key={`${survey_question_no}-${i}`}  src={imgRows[index]} text={text} comment={commentValue} />
                })
            }
        </div>
    )
}

export default memo(ChoiceWithCommentComponent)
