import { memo, useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { parseHtmlIntl, fileSize } from 'ts-utils'
import { numberFormat } from 'utils'
import {
    DELETE_SURVEY_ANALYSIS_USER_FILES_INDIVIDUAL,
    DELETE_SURVEY_ANALYSIS_USER_FILES_ALL
} from 'gql/survey_analysis_users'

import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, Grid } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import makeStyles from '@material-ui/core/styles/makeStyles'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'
import { Open, OnClose, Props as ParentProps } from './RemoveFileButton'
import { FileUploadContext } from './Component'
import { ConditionContent } from './RemoveUserDialog'

interface Props extends UsePrevStateProps, Omit<ParentProps, 'files' | 'breakpoints'> {
    open: Open;
    onClose: OnClose;
}

// 파일 삭제
function getFileText(type:Open, f:any, checked: ParentProps['checked']) {
    if (type === 'all') {
        return {
            title: f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.title'}),
            subtitle: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.all.subtitle'})),
            subtitle2: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.all.subtitle2'})),
            content: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.content'}))
        }
    }

    if (type === 'part') {
        return {
            title: f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.title'}),
            subtitle: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.part.subtitle'}, {count: numberFormat(checked.length)})),
            subtitle2: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.part.subtitle2'})),
            content: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.content'}))
        }
    }

    return { title: '', subtitle: '', subtitle2: '', content: '' }
}

const useStyles = makeStyles(theme => ({
    checkboxWrapper: {
        display: 'flex',
        width: '100%',
        gap: 5,
        '& > div:first-child': {
            width: 25
        }
    }
}))

function RemoveFileDialogComponent(props: Props) {
    const { open, where, checked, search, onRefetch, onClose, usePrevState } = props

    const { limit, used, refetch: refetchFileUploadSize } = useContext(FileUploadContext)

    const classes = useStyles()

    const dispatch = useDispatch()

    const prevOpen = usePrevState(open)

    const { formatMessage: f } = useIntl()

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const [ agree, setAgree ] = useState<boolean>(false)

    function success() {
        refetchFileUploadSize()
        onRefetch()
        onClose()
        dispatch({ type: BACKDROPHIDE })
    }

    function fail() {
        onClose()
        dispatch({ type: BACKDROPHIDE })
        dispatch({ type: ERRORPAGESHOW })
    }

    const [ deleteFilesIndividual ] = useMutation(DELETE_SURVEY_ANALYSIS_USER_FILES_INDIVIDUAL, {
        onCompleted: () => {
            success()
        },
        onError: (e) => {
            fail()
        }
    })

    const [ deleteFilesAll ] = useMutation(DELETE_SURVEY_ANALYSIS_USER_FILES_ALL, {
        onCompleted: () => {
            success()
        },
        onError: () => {
            fail()
        }
    })

    function handleDelete() {
        if (open === 'all') {
            dispatch({ type: BACKDROPSHOW })
    
            let newWhere = {...where}
            if (typeof newWhere.step !== undefined) newWhere.step = Number(newWhere.step)
            
            deleteFilesAll({ variables: { survey_no, input: newWhere }})
            return
        }

        if (open === 'part') {
            dispatch({ type: BACKDROPSHOW })
            deleteFilesIndividual({ variables: { survey_no, ids: checked }})
            return
        }
    }

    const { title, subtitle, subtitle2, content } = getFileText((open || prevOpen), f, checked)

    const over = used > limit ? used - limit : 0

    useEffect(() => {
        if (!open) setAgree(false)
    }, [open])


    useEffect(() => {
        return () => {
            setAgree(false)
        } 
    }, [])

    return (
        <Dialog open={Boolean(open)} fullWidth={true} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>
                    {subtitle}
                </Typography>
                <div style={{ height: 10 }} />
                <ConditionContent {...where} search={search} />
                <div style={{ height: 20 }} />
                <Typography>
                    {subtitle2}
                </Typography>
                <div style={{ height: 10 }} />
                <div style={{padding: 10, border: `1px solid ${oc.gray[4]}`, borderRadius: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={4} md={4} style={{fontWeight: 900}}>
                            {f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.storage.column.0'})}
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            {fileSize(limit)}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={4} md={4} style={{fontWeight: 900}}>
                            {f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.storage.column.1'})}
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            {fileSize(used, 2)}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={4} md={4} style={{fontWeight: 900}}>
                            {f({id: 'component.Survey.Create.Analysis.Rows.FileRemoveDialog.storage.column.2'})}
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                            {fileSize(over, 2)}
                        </Grid>
                    </Grid>
                </div>
                <div style={{ height: 20 }} />
                <Box className={classes.checkboxWrapper} onClick={() => setAgree(prev => !prev)}>
                    <div>
                        {
                            agree ? (
                                <CheckBoxIcon color="primary" style={{ display: 'block' }} />
                            ) : (
                                <CheckBoxOutlineBlankIcon color="action" style={{ display: 'block' }} />
                            )
                        }
                    </div>
                    <div>
                        <Typography variant='body2' style={{ lineHeight: '155%' }}>
                            {content}
                        </Typography>
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={!agree} color="secondary" onClick={handleDelete}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.button.1'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default memo(withPrevState(RemoveFileDialogComponent))