import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/Help'
import { openHelpPage } from '../Workspace/Left/Help/ItemHelp'

function ChannelComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <Button 
            startIcon={<HelpIcon color="primary"/>}
            variant="contained" 
            disableElevation 
            style={{marginLeft: 9}}
            onClick={openHelpPage}
            size='small'
        >
            <Typography variant="button">
            {f({id: 'component.Survey.Create.Channel.button'})}
            </Typography>
        </Button>
    )
}

export default ChannelComponent