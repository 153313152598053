import { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { animateScroll as scroll } from 'react-scroll'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GetApp as GetAppIcon, ArrowUpward as ArrowUpwardIcon } from '@material-ui/icons'
import IconButton from 'styled/Custom/IconButton'
import Tooltip from 'styled/Custom/Tooltip'
import DownloadSplitComponent from './DownloadSplit' 
import withBreakPoints from 'hoc/BreakPoints'

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        bottom: 20,
        right: 20
    },
    navigation: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%'
    }
})

const Component = (props) => {
    const { survey_no, order, questions, input, breakpoints } = props
    const { xs } = breakpoints

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    function openDataDownload() {
        setOpen(true)
    }

    const  handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
        {
            xs ? (
                <BottomNavigation value="" onChange={() => {}} showLabels className={classes.navigation}>
                    <BottomNavigationAction label={f({id: 'component.Survey.Create.Analysis.Rows.Dial.tooltip.0'})} icon={<GetAppIcon />} onClick={openDataDownload}/>
                    <BottomNavigationAction label={f({id: 'component.Survey.Create.Analysis.Rows.Dial.tooltip.1'})} icon={<ArrowUpwardIcon />} onClick={() => scroll.scrollToTop()}/>
                </BottomNavigation>
            ) : (    
                <IconButton className={classes.root} onClick={openDataDownload} color="primary" backgroundcolor='rgba(0, 0, 0, .2)' backgroundcolorhover='rgba(0, 0, 0, .3)'>
                    <Tooltip title={f({id: 'component.Survey.Create.Analysis.Rows.Dial.tooltip.2'})}>
                        <GetAppIcon/>
                    </Tooltip>
                </IconButton>
            )
        }
        <DownloadSplitComponent 
            survey_no={survey_no} 
            order={order} 
            questions={questions} 
            input={input} 
            open={open} 
            onClose={handleClose}
        />
        </>
    )
}

export default withBreakPoints(Component)