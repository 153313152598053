import { memo, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { isEqual, sortBy } from 'lodash-es'
import Button from '@material-ui/core/Button'
import { CHANGE } from 'reducer/survey/create/logic/jumpPointError'
import { CHANGE as CHANGESAVE, INIT as INITSAVE } from 'reducer/survey/create/logic/jumpPointSave'
import { CREATE_JUMP_POINT, JumpPointTextProps, JumpPointProps } from 'gql/jump_point'
import BeforeUnload from 'styled/BeforeUnload'
import { InitContext, PointEventContext } from 'container/Survey/Create/Logic/Jump/PointContext'
import { Point } from './Component'

export const min = -99
export const max = 99

function getIsEqualPoint(db: JumpPointProps[], state: JumpPointProps[]) {
    function parseNumber(data: JumpPointProps[]) {
        return data.map(c => ({
            ...c,
            point: c.point as Point === '' ? '' : Number(c.point)
        }))
    }
    return isEqual(sortBy(parseNumber(db), 'jump_point_no'), sortBy(parseNumber(state), 'jump_point_no'))
}

function getIsEqualPointText(db: JumpPointTextProps[], state: JumpPointTextProps[]) {
    return isEqual(sortBy(db, 'jump_point_text_no'), sortBy(state, 'jump_point_text_no'))
}

export function isError(limitMin: number, limitMax: number, point: Point) {
    if (point === '' || point === '-') return true
    if (point < limitMin) return true
    if (point > limitMax) return true

    return false
} 

function ButtonSaveComponent() {
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const { jumpPoint, jumpPointText } = useContext(InitContext)
    const { refetchJumpPointWithRelation} = useContext(PointEventContext)

    const { statePoint, statePointsText }  = useSelector((state:RootState) => ({
        statePoint: state.surveyCreateLogicJumpPoint,
        statePointsText: state.surveyCreateLogicJumpPointText
    }))
    
    const [ createJumpPoint ] = useMutation(CREATE_JUMP_POINT, {
        onCompleted: () => {
            refetchJumpPointWithRelation()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleSave() {
        // 포인트 숫자 입력을 안햇을경우 포커스 줄거다
        const isPointError = statePoint.rows.find(c => isError(min, max, c.point))

        if (isPointError) {
            const { survey_question_no } = isPointError
            dispatch({ type: CHANGE, survey_question_no })
            return
        }
        
        // 주관식 내용 입력을 안햇을경우 포커스 줄거다
        const isTextError = statePointsText.rows.find(c => c.value === '')

        if (isTextError) {
            const { survey_question_no } = isTextError
            dispatch({ type: CHANGE, survey_question_no })
            return
        }
        
        dispatch({ type: BACKDROPSHOW })
        createJumpPoint({ 
            variables: {
                survey_no,
                jumpPoint: statePoint.rows.map(row => {
                    const point = row.point > max ? max : row.point < min ? min : row.point
                    return {
                        ...row,
                        point
                    }
                }), 
                jumpPointText: statePointsText.rows, 
            }
        })
    }

    const disabled = getIsEqualPoint(jumpPoint, statePoint.rows) && getIsEqualPointText(jumpPointText, statePointsText.rows)

    useEffect(() => {
        dispatch({ type: CHANGESAVE, disabled })
        return () => {
            dispatch({ type: INITSAVE })
        }
    }, [disabled, dispatch])

    return (
        <>
        <Button 
            variant="contained" 
            color="primary" 
            disabled={disabled}
            onClick={handleSave}
        >
           {f({id: 'component.Survey.Create.Logic.Jump.Ending.ButtonSave.button.save'})}
        </Button>
        {!disabled && <BeforeUnload/>}
        </>
    )
}

export default memo(ButtonSaveComponent)