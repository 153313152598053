import { useIntl } from 'react-intl'
import { getShareDomain } from 'ts-utils/survey' 
import { getShareLink } from 'utils/survey'
import styled from 'styled-components'
import CopyLink from 'styled/Survey/CopyLink'

const WrapperStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    & > div {
        width: 100%;
        max-width: 600px;
    }
`

export default (props) => {
    const { survey_no, args } = props

    const { formatMessage: f } = useIntl()

    const domain = getShareDomain(survey_no)
    
    const shareLink = getShareLink({domain, analysisArgs : args})
    const url = shareLink.analysis

    return (
        <WrapperStyled>
            <div>
                <CopyLink url={url} label={f({id: 'component.Survey.Create.Analysis.Summary.EditOpen.label'})} size="small" fullWidth  style={{background: 'white'}}/>
            </div>
        </WrapperStyled>
    )
}