import { useState } from 'react'
import { Typography } from '@material-ui/core'
import { SpeedDial, SpeedDialAction } from '@material-ui/lab'
import { ChromeReaderMode as ChromeReaderModeIcon, ViewList as ViewListIcon, ViewStream as ViewStreamIcon, GpsFixed as GpsFixedIcon } from '@material-ui/icons'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

const WrapperStyled = styled.div`
    position: fixed;
    right: ${({ viewType }) => viewType === 'one' ? '2.2em' : '1em'};
    bottom: 1em;
    z-index: 1;
`

const getActions = (f) => {
    return [
        { icon: <ViewStreamIcon />, name: f({id: 'component.Survey.Create.Module.MethodView.lists.0'}), key: 'one' },
        { icon: <ViewListIcon />, name: f({id: 'component.Survey.Create.Module.MethodView.lists.1'}), key: 'multi' }
    ]
}

export default function SpeedDials(props) {

    const { hidden, viewType, onChangeMethodView, onChangeFocusEdit } = props

    const { formatMessage: f } = useIntl()

    const [open, setOpen] = useState(false)
  
    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return (
        <WrapperStyled viewType={viewType} className="MethodDial">
            <SpeedDial
                ariaLabel="method dial"
                hidden={hidden}
                icon={<ChromeReaderModeIcon/>}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="up"
            >
                {getActions(f).map((action) => (
                    <SpeedDialAction
                        disabled={viewType === action.key} 
                        key={action.key} 
                        icon={action.icon} 
                        tooltipTitle={<Typography variant="caption">{action.name}</Typography>} 
                        onClick={async() => {
                            await handleClose()
                            onChangeMethodView(action.key)
                        }}
                    />
                ))}
                {
                    viewType === 'multi' && (
                        <SpeedDialAction 
                            icon={<GpsFixedIcon/>} 
                            tooltipTitle={<Typography variant="caption">{f({id: 'component.Survey.Create.Module.MethodView.focus'})}</Typography>} 
                            onClick={async() => {
                                await handleClose()
                                onChangeFocusEdit()
                            }}
                        />
                    )
                }
            </SpeedDial>
        </WrapperStyled>
    )
}