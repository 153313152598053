import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import oc from 'open-color'
import { SurveyPageProps } from 'gql/survey_page'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { isAddRule } from '../Lists/ItemDefault'
import QuestionItemSplitComponent from './QuestionItemSplit'

interface Props {
    page: SurveyPageProps;
    len: number;
    i: number;
    questions: SurveyQuestionModuleProps[] | null;
    defaultOpen: boolean;
}

const MyCollapse = withStyles({
    root: {
        background: oc.gray[0]
    },
    hidden: {
        width: '100%'
    }
})(Collapse)

const MyBox = withStyles({
    root: {
        width: '100%',
        padding: 20
    }
})(Box)

function PageItemComponent(props: Props) {
    const { page, questions, defaultOpen } = props
    const { indexs } = page

    const [ open, setOpen ] = useState(defaultOpen)

    const isError = useSelector((state: RootState) => state.surveyCreateLogicJumpPointError)

    function handleClick() {
        setOpen(prev => !prev)
    }

    // 해당 페이지내에서 서술현 Text 빈값이 있다면 열어준다.
    useEffect(() => {
        if (isError && questions) {
            if (questions.find(question => question._question.survey_question_no === isError.survey_question_no)) {
                setOpen(true)
            }
        }
    }, [isError, questions])

    return (
        <>
        <ListItem style={{minHeight: 38}} button onClick={handleClick}>
            <ListItemText style={{paddingRight: 15}}>
                {`Section ${indexs}`}
            </ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <MyCollapse in={open} timeout="auto">
            <MyBox>
            {
                questions && questions.filter(question => isAddRule(question._question.question_type, question._question.module_type)).map(question => {
                    return (
                        <QuestionItemSplitComponent key={question._question.survey_question_no} question={question}/>
                    )
                })
            }
            </MyBox>
        </MyCollapse>
        </>
    )
}

export default PageItemComponent