import dagre from 'dagre'
import { isNode, Elements, Position } from 'react-flow-renderer'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

let nodeWidth = 1200
const nodeHeight = 96

const getLayout = (elements: Elements, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    elements.forEach((el) => {
        if (isNode(el)) {
            dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        } else {
            dagreGraph.setEdge(el.source, el.target)
        }
    })

    dagre.layout(dagreGraph)

    return elements.map((el) => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id)
            el.targetPosition = isHorizontal ? Position.Left : Position.Top
            el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom

            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. Moreover we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            el.position = {
                x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - nodeHeight / 2
            }
        }

        return el
    });
};


export default getLayout