import { useEffect, memo } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { ViewList as ViewListIcon, ViewStream as ViewStreamIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { Method } from 'gql/survey_mbox'
import { withPrevState } from 'hoc'
import useChangeMethod from 'hooks/useChangeMethod'
import MyIconButton from 'styled/Custom/IconButton'

export const MyBox = withStyles(theme => ({
    root: ({ visibility }) => {
        return {
            position: 'fixed', 
            right: '1.4em',
            bottom: '1.4em',
            zIndex: 3,
            visibility,
            [theme.breakpoints.down('sm')]: {
                visibility: visibility === 'visible' ? 'hidden' : 'visible'
            }
        }
    }
}))(Box)

function MethodComponent (props) {

    const { method, refetchMbox, usePrevState } = props

    const params = useParams()

    const [ complete, onChange ] = useChangeMethod(Number(params.survey_no))

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const prevMethod = usePrevState(method)

    useEffect(() => {
        if (prevMethod && method !== prevMethod) {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'info',
                message: f({id: `component.Survey.Create.Module.Method.lists.${method}`})  
            })
        }
    }, [f, method, prevMethod, dispatch])

    useEffect(() => {
        if (complete) {
            refetchMbox()
            dispatch({ type: BACKDROPHIDE })
        }
    }, [complete, refetchMbox, dispatch])

    return null

    /*
    return (
        <MyBox visibility="visible">
            <MyIconButton 
                onClick={() => {
                    dispatch({ type: BACKDROPSHOW })
                    onChange(method === Method.One ? Method.Multi : Method.One)
                }} 
                backgroundcolor='rgba(0, 0, 0, .1)' 
                backgroundcolorhover='rgba(0, 0, 0, .2)'
            > 
                {method === 'multi' ? <ViewListIcon color="primary"/> : <ViewStreamIcon color="primary"/>}
            </MyIconButton>
        </MyBox>
    )
    */
}

export default withPrevState(memo(MethodComponent))