// 개인정보 데이터 인지 설정
import { memo, useMemo, useCallback, useState, useEffect, MouseEvent } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLazyQuery } from "@apollo/client"
import { useIntl } from "react-intl"
import { SURVEY_ANALYSIS_QUESTION_COUNT } from 'gql/survey_analysis_users'
import { RootState } from "reducer"
import { SHOW as ALERTSNACKBARSHOW, HIDE as ALERTSNACKBARHIDE } from 'reducer/alertSnackbar'
import oc from 'open-color'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteForeverIcon from '@material-ui/icons/Delete'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Props as ParentProps } from './TableHead'
import { useStyles as useTableHeadIdStandardStyles } from "./TableHeadIdStandard"
import Tooltip from 'styled/Custom/Tooltip'
import TableHeadPrivacyDialog from "./TableHeadPrivacyDialog"

export interface Props {
    survey_no:  ParentProps['survey_no'];
    field: string;
    filterLen: ParentProps['filterLen'];
    checkedLen: number;
    tooltip: string;
    labelArray: string[];
    __typename: string;
    step: ParentProps['step'];
    date: ParentProps['date'];
    refetch: ParentProps['refetch'];
}

export type OnClose = () => void
export type Open = boolean
export type AnchorEl = HTMLButtonElement | null

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        right: -2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 28,
        height: 25,
        gap: 2
    },
    icon: (props: { open: boolean }) => {
        const { open } = props

        return {
            width: 17,
            height: 17,
            marginBottom: 1,
            display: 'block',
            color: open ? theme.palette.primary.main : oc.gray[6],
            cursor: 'pointer'
        }
    },
    text: {
        fontSize: 11
    }
}))

// 임시 앵커
let tmpAnchorEl: AnchorEl | null  = null;

// 삭제 버튼 막누르는거 방지하기위해 
let timer: ReturnType<typeof setTimeout>

function TableHeadPrivacyComponent(props: Props) {
    const { survey_no, field,  labelArray, tooltip, __typename, filterLen, checkedLen, step, date, refetch } = props

    const dispatch = useDispatch()

    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null)

    const [ isClick, setIsClick ] = useState<boolean>(false)

    const tableHeadIdStandardClasses = useTableHeadIdStandardStyles()

    const state = useSelector((states: RootState) => states.analysisRowsSearch)
    const { search } = state

    const isSearch = Boolean(search)

    const open = Boolean(anchorEl);

    const classes = useStyles({ open })

    const { formatMessage: f } = useIntl()

    const [ getCount, { loading } ] = useLazyQuery(SURVEY_ANALYSIS_QUESTION_COUNT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            dispatch({ type: ALERTSNACKBARHIDE })

            if (data) {
                const count = data.surveyAnalysisQuestionCount
    
                if (count === 0) {
                    dispatch({ 
                        type: ALERTSNACKBARSHOW, 
                        message: f(
                            { id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacy.noData' },
                            { 
                                date: date ? date.replace('~', ' ~ ') : f({id: 'styled.Survey.Analysis.DateButton.popover.0'}),
                                step: f({id: `component.Survey.Create.Analysis.Buttons.step.item.${step}`})
                            }
                        ), 
                        variant: 'info',
                        duration: 6000
                    })
    
                    return false
                }
    
                setAnchorEl(tmpAnchorEl)
                return false
            }
    
        },
        onError: () => {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                message: f({ id: 'ccomponent.AlertSnackbar.errorMessage'}), 
                variant: 'error'
            })
        }
    })

    const handleClick = useCallback(async(event: MouseEvent<HTMLButtonElement>) => {
        if (loading) return
        if (isClick) return

        setIsClick(true)

        if (filterLen > 0 || checkedLen > 0 || isSearch) {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacy.noPermission' }),
                duration: 10000
            }) 
            return
        }

        // getCount onCompleted 함수에서 event 접근을 위해 임시로 담는다.
        tmpAnchorEl = event.currentTarget

        getCount({
            variables: {
                survey_no,
                survey_question_no: Number(field.replace('Q', '')), 
                typename: __typename, 
                date, 
                step: Number(step)
            }
        })
    }, [
        f,
        dispatch, 
        survey_no, 
        isClick,
        field, 
        __typename, 
        date, 
        step, 
        filterLen, 
        checkedLen, 
        isSearch, 
        loading,
        getCount
    ])

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    useEffect(() => {
        // 1초동안 다시 클릭 안되게 방지
        if (isClick) {
            clearTimeout(timer)
            timer = setTimeout(() => {
              setIsClick(false)
            }, 1000)
        }
    }, [isClick])

    useEffect(() => {
        return () => {
            clearTimeout(timer)
            dispatch({ type: ALERTSNACKBARHIDE })
        }
    }, [dispatch])
    
    return (
        <>
        {
            useMemo(() => {
                return (
                    <Box className={`${classes.root} ${tableHeadIdStandardClasses.iconButton}`} onClick={handleClick}>
                        <Tooltip title={open ? "" : f({ id: 'component.Survey.Create.Analysis.Rows.TableHeadPrivacy.tooltip'})}>
                            {
                                loading ? <CircularProgress color='primary' size={16} /> : <DeleteForeverIcon className={classes.icon} />
                            }
                        </Tooltip>
                    </Box>
                )
            }, [
                f,
                classes.root, 
                classes.icon, 
                tableHeadIdStandardClasses.iconButton, 
                open, 
                loading, 
                handleClick
            ])
        }
        <TableHeadPrivacyDialog 
            survey_no={survey_no}
            anchorEl={anchorEl}
            open={open}
            field={field} 
            labelArray={labelArray}
            tooltip={tooltip}
            __typename={__typename} 
            step={step} 
            date={date} 
            refetch={refetch}
            onClose={handleClose}
        />
        </>
    )    
}

export default memo(TableHeadPrivacyComponent)
