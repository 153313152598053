import { useState, useEffect, ChangeEvent, memo, useCallback } from 'react'
import InputBase from "@material-ui/core/InputBase"
import { withStyles } from '@material-ui/core/styles'
import { getMaxlength } from 'policy'
import { replaceNotNumber } from 'utils'
import { JumpNo } from 'gql/jump'
import { OnChangeChildsProps } from './ItemRules'

interface Props extends OnChangeChildsProps {
    jump_rule_child_no: JumpNo;
    value: string | number;
    placeholder: string;
    number: boolean; // 타입
}

const MyInput = withStyles(theme => ({
    root: {
        minWidth: '0 !important'
    },
    input: ({ error }: { error: boolean }) => {
        return {
            minWidth: '0 !important',
            height: 35,
            borderRadius: 3,
            paddingRight: '10px',
            paddingLeft: '10px',
            transition: 'all .1s',
            border: error ? `1px solid ${theme.palette.secondary.main}` : `1px solid ${theme.palette.action.disabled}`,
            '&:focus': {
                border: `2px solid ${theme.palette.primary.main}`,
                height: 33
            }
        }
    }
}))(InputBase)

let time: ReturnType<typeof setTimeout> 

function InputValueComponent(props: Props) {
    const { jump_rule_child_no, value, placeholder, number=false, onChange } = props

    const [ _value, setValue ] = useState(value)

    const hanldeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        const val = number ? replaceNotNumber(value) : value

        setValue(val)

        clearTimeout(time)

        time = setTimeout(() => {
            onChange('value', jump_rule_child_no, val)
        }, 300)
    }, [number, jump_rule_child_no, onChange])

    // 텍스트입력값이 있는데 타입 포인트로 바뀔경우 숫자가 아니면 값 제거한다.
    useEffect(() => {
        if (number) {
            setValue(prev => {
                const val = Number(prev)
                return Number.isNaN(val) ? '' : prev
            })
        }
    }, [ number ])

    useEffect(() => {
        setValue(value)
    }, [value])

    return (
        <MyInput 
            style={{flexGrow: 1}} 
            value={_value} 
            onChange={hanldeChange} 
            placeholder={placeholder}
            error={_value === ""}
            inputProps={{
                maxLength: getMaxlength('component.Survey.Create.Logic.Jump.Lists.InputValue')
            }} 
        />
    )
}

export default memo(InputValueComponent)