import { MiniMap } from 'react-flow-renderer'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    breakpoints: BreakPointsProps
}

function MiniMapComponent(props: Props) {
    const { breakpoints } = props  
    const { lg } = breakpoints

    return null

    return (
        <MiniMap
            nodeColor={(n) => {
                const { data } = n
                const { background } = data
                
                return background
            }}
            nodeBorderRadius={2}
        />
    )
}

export default withBreakPoints(MiniMapComponent)