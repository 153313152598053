import { memo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { isMobile } from 'react-device-detect'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import useRefreshDownload from './useRefreshDownload'
import { Props as ParentProps } from './DataDownloads'
import DownloadSplitComponent from './DownloadSplit'

interface Props extends Omit<ParentProps, 'xs'> {
    onClose: () => void;
}

function AllDataDownload(props: Props) {
    const { survey_no, questions, conditionsPlay, step, date, onClose } = props

    const { formatMessage: f } = useIntl()

    const [{ action, isRefresh }, onAction ] = useRefreshDownload()

    const order = useSelector((states: RootState) => states.analysisRowsOrder)

    const input = {
        conditionsPlay, 
        step, 
        search: '', 
        date
    }

      // 전체 다운로드 
    const handleClick = () => {
        onAction()
        onClose()
    }

    return (
        <>
        <MenuItem button dense={!isMobile} onClick={handleClick}>
            <ListItemIcon>
                <DoneAllIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={f({id: 'component.Survey.Create.Analysis.Rows.AllDataDownload.primary'})} />
        </MenuItem>
        <DownloadSplitComponent 
            survey_no={survey_no} 
            order={order} 
            questions={questions} 
            input={input} 
            action={action}
            isRefresh={isRefresh}
        />
        </>
    )
}

export default memo(AllDataDownload)

