import { memo, useEffect, useRef } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { withStyles } from '@material-ui/core/styles'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    idd: string;
    onClick: () => void;
    breakpoints: BreakPointsProps;
}

export const MyIconButton = withStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: 5,
        right: 5,
        zIndex: 1,
        visibility: 'hidden',
        [theme.breakpoints.down('sm')]: {
            bottom: 0,
            right: 0
        },
        [theme.breakpoints.down('xs')]: {
            visibility: 'hidden'
        }
    }

}))(IconButton)

function TopComponent(props: Props) {
    const { idd, onClick, breakpoints } = props
    const { xs, md } = breakpoints

    const refTop = useRef<null | HTMLButtonElement>(null)

    useEffect(() => {
        const id = document.getElementById(idd)
        function show() {
            if (!id || !refTop) return
            if (!refTop.current) return

            if (xs) {
                refTop.current.style.visibility = 'hidden'
                return
            }

            // pc와 모바일일때 스크롤 객체가 다르다
            const top = md ? (document.documentElement.scrollTop || document.body.scrollTop) : id.scrollTop

            if (top > 1000) refTop.current.style.visibility = 'visible'
            else refTop.current.style.visibility = 'hidden'            
        }

        // 모바일olny은 따른 컴포넌트에서 보여준다.
        if (id) {
            // pc와 모바일일때 스크롤 객체가 다르다
            md ? window.addEventListener('scroll', show) : id.addEventListener('scroll', show)
        }
    }, [idd, xs, md, refTop])

    return (
        <MyIconButton onClick={onClick} ref={refTop}>
            <ArrowUpwardIcon  fontSize={xs ? 'small' : 'medium'}  />
        </MyIconButton>
    )
}

export default withBreakPoints(memo(TopComponent))