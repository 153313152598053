/**
 * 현재 페이지 내에서 검색
 */
import { memo, useState, ChangeEvent, KeyboardEvent, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Paper, InputBase, Button } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SearchIcon from '@material-ui/icons/Search'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'

interface Props extends UsePrevStateProps {
    value: string;
    onChange: (val: string) => void;
}

const useStyles = makeStyles(theme => ({
    paper: {
        height: 30,
        padding: '2px 8px',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        border: `1px solid ${theme.palette.grey[300]}`,
        width: 280,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    input: {
        flex: 1,
        fontSize: 13
    },
    iconButton: {
        padding: 0,
        paddingRight: 2
    }
}))

function SearchComponent(props: Props) {
    const { value, onChange, usePrevState } = props 
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const ref = useRef<null | HTMLInputElement>(null)

    const [ newValue, setNewValue ] = useState(value)

    let prevValue: any = usePrevState(newValue)
    prevValue = prevValue ? String(prevValue) : ''
        
    let  disabled = newValue === prevValue

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewValue(event.target.value)
    }

    const handleClick = () => {
        onChange(newValue)
    }
    const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (disabled) return

        if(event.code === 'Enter') onChange(newValue)
    }

    return (
        <Paper variant="outlined" className={classes.paper}>
            <InputBase
                inputRef={ref}
                className={classes.input}
                placeholder={f({id: 'component.Survey.Create.Analysis.Buttons.placeholder'})}
                inputProps={{ 'aria-label': f({id: 'component.Survey.Create.Analysis.Buttons.placeholder'}) }}
                value={newValue}
                onChange={handleChange}
                onKeyUp={handleEnter}
            />
            <Button size="small" onClick={handleClick} color="primary" disabled={disabled}>
                {f({id: 'component.Survey.Create.Analysis.Buttons.confirm'})}
            </Button>
            <SearchIcon color="action" fontSize='small'/>
        </Paper>
    )
}

export default withPrevState(memo(SearchComponent))