import QRCode from 'qrcode.react'
import { Button, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { getShareLink } from 'utils/survey'

const WrapperStyled = styled.div`
    width: 100%;

    & > div:last-child {
        margin-top: 1.2em;
    }
`

export default (props) => {
    const { listoDomain, args } = props

    const { formatMessage: f } = useIntl()

    const onDownload = () => {
        const canvas = document.getElementById('qrcode')
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
        let downloadLink = document.createElement("a")
        downloadLink.href = pngUrl
        downloadLink.download = "QRcode.png"
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    const shareLink = getShareLink({domain: listoDomain, args})
    const url = shareLink.survey.web
    
    return (
        <WrapperStyled>
            <div>
                <QRCode id='qrcode' value={url} size={240} level={"H"} includeMargin={false}/>
            </div>
            <div>
               <Button onClick={onDownload} variant="contained">
                   <Typography>{f({id: 'component.Survey.Create.Share.Qr.download'})}</Typography>
                </Button>
            </div>   
        </WrapperStyled>
    )
}