import { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { Typography, TextField, InputAdornment, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { QueryBuilder as QueryBuilderIcon } from '@material-ui/icons'
import { UPDATE_SURVEY_MBOX_DELAY } from 'gql/survey_mbox'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { useIntl } from 'react-intl'

function getDelay(props) {
    const { method, delay_multi, delay_one } = props

    return method === 'multi' ? delay_multi : delay_one
}

function getDisabled(props) {
    const { originalDelay, delay } = props

    return originalDelay === delay || !(delay >= 1 && delay <= 99)
}

const Component = (props) => {
    const { survey_no, delay_multi, delay_one, method, refetchMbox, refetchPageView } = props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const originalDelay = getDelay({ method, delay_multi, delay_one })

    const [ open, setOpen ] = useState(false)
    const [ delay, setDelay ] = useState(originalDelay)

    const [ updateSuveyMboxDelay ] = useMutation(UPDATE_SURVEY_MBOX_DELAY, {
        onCompleted: () => {
            refetchPageView()
            refetchMbox()
        },
        onError: (e) => {
            dispatch({type: ERRORPAGESHOW})
        }
    })

    // 기존값이랑 같거나 1~99가 아닐때
    const disabled = getDisabled({ originalDelay, delay })

    const handleDialogOpen = useCallback((_open) => {
        setOpen(_open)
    }, [])

    const handleChangeDelay = useCallback((e) => {
        const { value } = e.target

        setDelay(value)
    }, [])

    const handleSaveDelay = useCallback(() => {
        if (disabled) return

        updateSuveyMboxDelay({ variables: {
            survey_no,
            delay: Number(delay)
        } })

        handleDialogOpen(false)

    }, [survey_no, delay, disabled, updateSuveyMboxDelay, handleDialogOpen])

    useEffect(() => {
        setDelay( getDelay({ method, delay_multi, delay_one }) )
    }, [delay_multi, delay_one, method])

    return (
        <>
        {
            useMemo(() => {
                return (
                    <Button variant="outlined" disableElevation size="small" startIcon={<QueryBuilderIcon/>} onClick={() => handleDialogOpen(true)}>
                        <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Insight.DelayInput.button'}, {delay: originalDelay})}</Typography>
                    </Button>
                )
            }, [f, originalDelay, handleDialogOpen])
        }
        {
            useMemo(() => {
                return (
                    <Dialog open={open} onClose={() => handleDialogOpen(false)} fullWidth maxWidth="xs">
                        <DialogTitle id="form-dialog-title">{f({id: 'component.Survey.Create.Analysis.Insight.DelayInput.dialog.title'})}</DialogTitle>
                        <DialogContent>
                            <TextField
                                value={delay}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                           <QueryBuilderIcon color="action"/>
                                        </InputAdornment>
                                    ),
                                    min: 1, max: 99
                                }}
                                autoFocus
                                helperText={f({id: 'component.Survey.Create.Analysis.Insight.DelayInput.dialog.helperText'})}
                                onChange={handleChangeDelay}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSaveDelay} color="primary" disabled={disabled}>
                                <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Insight.DelayInput.dialog.button.0'})}</Typography>
                            </Button>
                            <Button onClick={() => handleDialogOpen(false)} color="primary">
                                <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Insight.DelayInput.dialog.button.1'})}</Typography>
                            </Button>
                        </DialogActions>
                    </Dialog> 
                )
            }, [
                f,
                open,
                delay,
                disabled,
                handleDialogOpen, 
                handleChangeDelay, 
                handleSaveDelay
            ])
        }

        
        </>
    )

}

export default Component