import { 
    InsertLink as InsertLinkIcon, 
    CropFree as CropFreeIcon,
    MailOutline as MailOutlineIcon,
    ViewDay as ViewDayIcon,
    CropDin as CropDinIcon,
    VideoLabel as VideoLabelIcon,
    Widgets as WidgetsIcont
} from '@material-ui/icons'
import { useIntl } from 'react-intl'
import Drawer from 'styled/Drawer'

function getSubHeaders(f) {
    return [
        f({id: 'component.Survey.Create.Share.Frame.header.0'}),
        f({id: 'component.Survey.Create.Share.Frame.header.1'})
    ]
}

function getItems(f) {
    return [
        [
            {
                key: 'sns',
                primary: f({id: 'component.Survey.Create.Share.Frame.title.sns'}),
                icon: InsertLinkIcon,
                isPadding: true
            },
            {
                key: 'qrcode',
                primary: f({id: 'component.Survey.Create.Share.Frame.title.qrcode'}),
                icon: CropFreeIcon,
                isPadding: true
            },
            {
                key: 'email',
                primary: f({id: 'component.Survey.Create.Share.Frame.title.email'}),
                icon: MailOutlineIcon,
                isPadding: true
            }
        ],
        [
            {
                key: 'standard',
                primary: f({id: 'component.Survey.Create.Share.Frame.title.standard'}),
                icon: ViewDayIcon,
                isPadding: false
            },
            {
                key: 'page',
                primary: f({id: 'component.Survey.Create.Share.Frame.title.page'}),
                icon: CropDinIcon,
                isPadding: false
            },
            {
                key: 'popup',
                primary: f({id: 'component.Survey.Create.Share.Frame.title.popup'}),
                icon: VideoLabelIcon,
                isPadding: false
            },
            {
                key: 'popover',
                primary: f({id: 'component.Survey.Create.Share.Frame.title.popover'}),
                icon: WidgetsIcont,
                isPadding: false
            }
        ]
    ]
}

const Component = props => {
    const { selected, on, children } = props

    const { formatMessage: f } = useIntl()

    const subheaders = getSubHeaders(f)

    const items = getItems(f)

    return <Drawer subheaders={subheaders} items={items} selected={selected} onChange={on.change}>{children}</Drawer>
}

export default Component