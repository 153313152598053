import { withBreakPoints } from 'hoc'
import DeskTopComponent from './DeskTop'
import MobileComponent from './Mobile'
import ItemsComponent from './Items'
import randomstring from 'randomstring'// 스크롤러 설정값

const scrollTarget = randomstring.generate(7)

const Component = props => {
    const {  breakpoints, pages, page, onOpenPageLists, onChange } = props

    const { xs, sm, md, lg, xl } = breakpoints

    if (!xs && !sm && !md && !lg && !xl) return null

    return (
        <>
        {
            md ? (
                <MobileComponent scrollTarget={scrollTarget} page={page} onClose={onOpenPageLists}>
                    <ItemsComponent scrollTarget={scrollTarget} pages={pages} page={page} onChange={onChange}/>
                </MobileComponent>
            ) : (
                <DeskTopComponent scrollTarget={scrollTarget} page={page}>
                    <ItemsComponent scrollTarget={scrollTarget} pages={pages} page={page} onChange={onChange}/>
                </DeskTopComponent>
            )
        }
        </>
    )
}

export default withBreakPoints(Component)