import { useContext, memo } from "react"
import { useIntl } from 'react-intl'
import { ModuleTypeName } from 'gql/survey_question_modules'
import { JumpNo, ID } from 'gql/jump'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { OnChangeChildsProps } from './ItemRules'
import SelectModuleComponent from './SelectModule'
import SelectMatrixModuleComponent from './SelectMatrixModule'
import SelectRankModuleComponent from './SelectRankModule'
import InputValueComponent from './InputValue'

interface Props extends OnChangeChildsProps {
    jump_rule_child_no: JumpNo;
    id: ID;
    survey_module_nos: number[];
    survey_module_answer_no: number;
    value: string | number;
}

export function getType(typename: ModuleTypeName) {
    switch (typename) {
        case 'Module_01':
        case 'Module_02':    
        case 'Module_07':
        case 'Module_14':
        case 'Module_21':
            return 'base'
        
        case 'Module_05':
            return 'point'

        case 'Module_12':
        case 'Module_13':
            return 'image'

        case 'Module_03':
        case 'Module_04':
        case 'Module_08':
        case 'Module_15':
            return 'matrix'
        case 'Module_06':
            return 'matrixPoint'

        case 'Module_09':
        case 'Module_10':
        case 'Module_20':
            return 'text'

        case 'Module_11':
        case 'Module_17':
            return 'rank'

        case 'Module_18':
            return 'rankImage'

        default:
            return 'matrix'
    }
}

function ItemSplitComponent(props: Props) {
    const { id, ...other } = props

    const { questionsKeyQuestionNo } = useContext(Context)

    const { formatMessage: f } = useIntl()

    const question = questionsKeyQuestionNo && questionsKeyQuestionNo[id]

    if (!question) return null

    const [ Q ] = question
    const { __typename } = Q

    const typename = getType(__typename)

    return (
        <>
        {
            (typename === 'base' || typename === 'point' || typename === 'image') ? (
                <SelectModuleComponent typename={typename} question={Q} {...other}/>
            ) : (typename === 'matrix' || typename === 'matrixPoint') ? (
                <SelectMatrixModuleComponent typename={typename} question={Q} {...other}/>
            ) : (typename === 'rank' || typename === 'rankImage') ? (
                <SelectRankModuleComponent typename={typename} question={Q} {...other}/>
            ) : (typename === 'text') && (
                <InputValueComponent placeholder={f({id: 'component.Survey.Create.Logic.Jump.Lists.ItemSplit.inputValue.placeholder'})} number={false} {...other}/>
            )
        }
        </>
    )
}

export default memo(ItemSplitComponent)