/**
 * Quiz Point Tab (STEP1, STEP2 선택)
 */
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar';
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/core/styles'
import { PointKind } from './Quiz'
import { Props as ParentProps } from './QuizTitle'

const MyButton = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 12
        }
    }
}))(Button)

function QuizPointTabComponent(props: Pick<ParentProps, 'pointKind' | 'onChange'>) {
    const { pointKind, onChange } = props

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const states = useSelector((states: RootState) => states.surveyCreateLogicJumpPointSave)

    const { disabled } = states

    const handleChange = (kind: PointKind) => {
        // 탭이동시에
        if (pointKind !== kind) {
            // 저장할 값이 있다면 탭이동 몬하게한다..
            if (!disabled) {
                dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Create.Menu.QuizPointTab.handleChange.message'})})
                return
            }
        }

        onChange(kind)
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            <div style={{marginRight: 10}}>{f({id: 'component.Survey.Create.Menu.Quiz.radio.1'})}</div>
            <ButtonGroup color="primary" variant="contained" size="small" disableElevation>
                <MyButton 
                    color={pointKind === PointKind.Point ? 'primary' : 'default'}
                    style={{cursor: pointKind === PointKind.Point ? 'default' : 'pointer'}}
                    onClick={() => handleChange(PointKind.Point)}
                >
                    {f({id: 'component.Survey.Create.Logic.Jump.Point.ButtonBox.SelectButton.name.0'})}
                </MyButton>
                <MyButton
                    color={pointKind === PointKind.Ending ? 'primary' : 'default'}
                    style={{cursor: pointKind === PointKind.Ending ? 'default' : 'pointer'}}
                    onClick={() => handleChange(PointKind.Ending)}
                >
                    {f({id: 'component.Survey.Create.Logic.Jump.Point.ButtonBox.SelectButton.name.1'})}
                </MyButton>
            </ButtonGroup>
        </div>
    ) 
}

export default QuizPointTabComponent