import oc from 'open-color-js'
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NoteSharp as NoteSharpIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import { Element } from 'react-scroll'

const useStyles = makeStyles(theme => ({
    icon: {
        color: oc.gray5
    },
    iconSelected: {
        color: oc.gray8
    }
}))
         
export default (props) => {
    const {  scrollTarget, pages, page, onChange } = props
    const classes = useStyles()
    const { formatMessage: f } = useIntl()

    return (
        <List dense>
        {
            pages.map(c => {
                const selected = c.survey_page_no === page
                return (
                    <Element key={c.survey_page_no} name={selected ? scrollTarget : `left-page-${c.survey_page_no}`}>
                        <ListItem button selected={selected} onClick={() => onChange(c.survey_page_no)}>
                            <ListItemIcon className={selected ? classes.iconSelected : classes.icon}><NoteSharpIcon/></ListItemIcon>
                            <ListItemText  primary={f({id: 'component.Survey.Create.Pages.Lists.page'}, {page: c.indexs})} />
                        </ListItem>
                    </Element>  
                    
                )
            })
        }
        </List>
    )
}
