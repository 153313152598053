/**
 * 필터 추가
 */

import { memo } from 'react'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AddIcon from '@material-ui/icons/Add'
import { OnAdd } from './List'

interface Props {
    onAdd: OnAdd;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        padding: 10,
        cursor: 'pointer',
        transition: 'all .3s',
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    }
}))
function AddSelectBoxComponent(props: Props) {
    const { onAdd }  = props

    const classes = useStyles()

    return (
        <div style={{ display: 'table', marginTop: 20 }}>
            <Paper variant="outlined" className={classes.root} onClick={onAdd}>
                <AddIcon color="action" />
            </Paper>
        </div>

    )
} 

export default memo(AddSelectBoxComponent)