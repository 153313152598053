import { List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core'
import { SwapCalls as SwapCallsIcon, SwapVert as SwapVertIcon, CallSplit as CallSplitIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import Wrapper, { useStyles } from 'styled/Survey/Left'
import Tooltip from 'styled/Custom/Tooltip'

export default (props) => {
    const { method, selected, pageRandomDisabled, questionRandomDisabled, jumpDisabled, onChange } = props
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    return (
        <Wrapper>
            <List dense>
                {
                    method === 'multi' && (
                        <>
                        <Tooltip placement="top-end" title={
                            pageRandomDisabled === 'one' ? f({id: 'component.Survey.Create.Logic.Menu.tooltip.page_random.1'})  : 
                            pageRandomDisabled ? f({id: 'component.Survey.Create.Logic.Menu.tooltip.page_random.0'}) : ''
                        }>
                            <div>
                            <ListItem button onClick={() => onChange('page_random')} selected={selected === 'page_random'} disabled={pageRandomDisabled}>
                                <ListItemIcon><SwapCallsIcon className={selected === 'page_random' ? classes.iconSelected : classes.icon}/></ListItemIcon>
                                <ListItemText primary={f({id: 'component.Survey.Create.Logic.Menu.lists.page_random'})} />
                            </ListItem>
                            </div>
                        </Tooltip>
                        <Divider/>
                        </>
                    )
                }
                <Tooltip placement="top-end"  title={questionRandomDisabled ? f({id: 'component.Survey.Create.Logic.Menu.tooltip.question_random'}) : ''}>
                    <div>
                    <ListItem button onClick={() => onChange('question_random')} selected={selected === 'question_random'} disabled={questionRandomDisabled}>
                        <ListItemIcon><SwapVertIcon className={selected === 'question_random' ? classes.iconSelected : classes.icon}/></ListItemIcon>
                        <ListItemText primary={f({id: 'component.Survey.Create.Logic.Menu.lists.question_random'})} />
                    </ListItem>
                    </div>
                </Tooltip>
                <Divider/>
                <Tooltip placement="top-end"  title={jumpDisabled ? f({id: `component.Survey.Create.Logic.Menu.tooltip.page_jump.${method}`}) : ''}>
                    <div>
                    <ListItem button onClick={() => onChange('jump')} selected={selected === 'jump'} disabled={jumpDisabled}>
                        <ListItemIcon><CallSplitIcon className={selected === 'page_jump' ? classes.iconSelected : classes.icon}/></ListItemIcon>
                        <ListItemText primary={f({id: 'component.Survey.Create.Logic.Menu.lists.page_jump'})} />
                    </ListItem>
                    </div>
                </Tooltip>
            </List>
        </Wrapper>
    )
}