import { Button, Typography } from '@material-ui/core'
import oc from 'open-color-js'
import Wrapper from 'styled/Survey/Left'
import { useIntl } from 'react-intl'
import styled from 'styled-components'


const ItemBoxStyled = styled.div`
    padding-top: ${props => !props.isExcludeCurrentPage ? '15px': 0};
`
const ButtonBoxStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
   
`

const Component = (props) => {
    const { method, isExcludeCurrentPage, onSave, onClose, children } = props

    const { formatMessage: f } = useIntl()

    return (
        <Wrapper background={oc.gray0}>
            <ItemBoxStyled isExcludeCurrentPage={isExcludeCurrentPage}>
                {children}
            </ItemBoxStyled>
            <ButtonBoxStyled>
                <Button color="primary" variant="contained" disableElevation size="small" onClick={() => onSave(method)}>
                    <Typography variant="button">{f({id: `component.Survey.Create.Pages.Move.save.${method}`})}</Typography>
                </Button>
                <Button variant="contained" disableElevation size="small" onClick={onClose}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Pages.Move.close'})}</Typography>
                </Button>
            </ButtonBoxStyled>
        </Wrapper>
    )
}

export default Component