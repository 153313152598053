import { useContext, useState, useCallback, memo, useEffect } from "react"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import fixedWidthString from 'fixed-width-string'
import { getDefaultQuestion } from 'utils/survey'
import { useIntl } from 'react-intl'
import { Kind, ID, Method } from 'gql/jump'
import { SurveyStartProps } from "gql/survey_start"
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import { MyTypography } from './ItemRules'
import { MyMenuItem } from './SelectModule'
import { MyCollapse, ItemsContext } from './SelectActionQuestion'
import SelectActionQuestionItemComponent from './SelectActionQuestionItem'

interface Props {
    kind: Kind;
    id: ID;
    method: Method;
    onCloseAnchorEl: () => void;
}

export type OnSelected = (selected: boolean, id: ID) => void;

function StartItem(props: { method: Method, used: SurveyStartProps['used'], surveyQuestionNos: number[], onSelected: OnSelected }) {
    const { method, used, surveyQuestionNos, onSelected } = props
    const { formatMessage: f } = useIntl()

    if (method === Method.Move && used) {
        const selected = surveyQuestionNos.includes(1)
        return (
            <MyMenuItem 
                style={{fontWeight: 'bold'}}
                value={1} 
                selected={surveyQuestionNos.includes(1)} 
                
                onClick={() => onSelected(selected, 1)}
            >
                {f({id: 'component.Survey.Create.Menu.Component.subheader.thanks.start'})}
            </MyMenuItem>
        )
    }

    return null
}

function SelectActionQuestionItemsComponent(props: Props) {
    const { kind, id, method, onCloseAnchorEl } = props

    const { start, questionsKeyPageNo, questionsKeyQuestionNo, pages } = useContext(Context)
    const { anchorEl, surveyQuestionNos, onChange } = useContext(ItemsContext)

    const { used } = start

    const [ collapse, setCollapse ] = useState<number[]>([])

    const { formatMessage: f } = useIntl()

    // method가 보이기 숨기기 이면 멀티선택, 이동이면 단일선택
    const isMulti = method === Method.Show || method === Method.Hide

    const handleChangeCollapse = useCallback((survey_page_no: number) => {
        if (isMulti) {
            setCollapse(prev => {
                if (prev.includes(survey_page_no)) {
                    return prev.filter(c => c !== survey_page_no)
                }else {
                    return [
                        ...prev,
                        survey_page_no
                    ]
                }
            })
        } else {
            setCollapse(prev => prev.includes(survey_page_no) ? [] : [ survey_page_no ])
        }
    }, [isMulti])

    const handleSelected:OnSelected = useCallback((selected, id) => {
        
        if (isMulti) {
            // 선택된 값을 클릭한거면 빼고, 미선택된 값을 클릭한거라면 넣는다.
            onChange(selected ? (
                surveyQuestionNos.filter(c => id !== c) 
            ) : (
                [ ...surveyQuestionNos, id ]
            ))
        } else {
            onChange([ id ])
            onCloseAnchorEl()
        }
    }, [isMulti, surveyQuestionNos, onChange, onCloseAnchorEl])

    // 숨김,보이기일경우 뒷문항은 취급안한다.
    let myPageIndex = 0
    let myQuestionIndex = 0
    
    if (kind === Kind.Question && (method === Method.Show || method === Method.Hide)) {
        const myQuestion = questionsKeyQuestionNo ? questionsKeyQuestionNo[id] : null
        if (myQuestion) {
            const [{ _question }] = myQuestion
            const { survey_page_no, indexs } = _question
            myQuestionIndex = indexs

            const { indexs: pageIndex } = pages.find(c => c.survey_page_no === survey_page_no) || { indexs: 0 } 
            myPageIndex = pageIndex
        }
    }

    const A =  pages.reduce((acc, page) => {
        const { indexs, survey_page_no } = page

        // 설정 종류가 page라면 해당 페이지내 문항으로는 점프 못한다.
        if (kind === Kind.Page && id === survey_page_no) return acc

        // 숨김/보이기 일경우 이전 섹션은 안보여준다 
        if (indexs < myPageIndex) return acc

        const open = collapse.includes(survey_page_no)

        const items = []
        items.push(
            <MenuItem 
                key={survey_page_no} 
                onClick={() => handleChangeCollapse(survey_page_no)} 
            >
                <ListItemText key={survey_page_no}>
                    <MyTypography>{`Section ${indexs}`}</MyTypography>
                </ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>    
        )

        const questionsInPage = questionsKeyPageNo ? questionsKeyPageNo[survey_page_no] : null

        // page내 문항이 없을수 있으므로
        if (!questionsInPage) return [...acc, ...items]

        const childItems = []

        const pageIndex = indexs

        for (let i=0; i<questionsInPage.length; i++) {
            const { _question } = questionsInPage[i]
            const { survey_question_no, number, pure_question, question_type, indexs } = _question

            // 설정 종류가 question이라면 동일 문항은 선택안되게 한다.
            if (kind === Kind.Question && id === survey_question_no) continue


            if (pageIndex === myPageIndex && indexs < myQuestionIndex) continue


            const label = getDefaultQuestion({f, number, question: fixedWidthString(pure_question, 50), question_type})

            const selected = surveyQuestionNos.includes(survey_question_no)

            childItems.push(
                <SelectActionQuestionItemComponent
                    key={survey_question_no}
                    selected={selected}
                    survey_question_no={survey_question_no}
                    isMulti={isMulti}
                    label={label}
                    onChange={handleSelected}
                />
            )
        }

        items.push(
            <MyCollapse key={`collapase-${survey_page_no}`} in={open} timeout="auto" unmountOnExit>
                {childItems}
            </MyCollapse>
        )
        
        return [...acc, ...items]

    }, [])

    useEffect(() => {
        // 셀렉트를 열때 기존값이 있으면 해당페이지를 열어준다
        if (anchorEl && questionsKeyQuestionNo && surveyQuestionNos) {
            const selectedPageNos = []

            for (let i=0; i<surveyQuestionNos.length; i++) {
                const question = questionsKeyQuestionNo[surveyQuestionNos[i]]

                if (!question) continue

                const [{ _question }] = question
                const { survey_page_no } = _question

                selectedPageNos.push(survey_page_no)
            }

            // 중복값 제거헤서 저장
            setCollapse(Array.from(new Set(selectedPageNos)))
        }
    }, [anchorEl, questionsKeyQuestionNo, surveyQuestionNos])

    return (
        <span>
            {
                StartItem({method, used, surveyQuestionNos, onSelected: handleSelected})
            }
            {A}
        </span>
    )
} 

export default memo(SelectActionQuestionItemsComponent)