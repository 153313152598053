/**
 * 셀렉트 버튼에 들어가는 아이템스
 */

import { memo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import { getIsComment } from './Component'
import { Props as ParentProps } from './SelectBox'
import SelectBoxOptionComponent from  './SelectBoxOption'

interface Props extends Omit<ParentProps, 'onDelete' | 'filterLen'> {
    open: boolean;
}

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        maxHeight: 300,
        width: '100%',
        overflowY: 'auto',
        zIndex: 100,
        padding: 5
    }
}))

function SelectItemsComponent(props: Props) {
    const { keyValue, open, questionOptions, selectedQuestionOption, selectedQuestionOptionKeys, onChange } = props

    const classes = useStyles()

    if (!open) return null


    // 이미 선택된 값은 뺀다 (자기 자신은 제외)
    const otherSelectedKeys = selectedQuestionOptionKeys.filter(c => c !== keyValue)
    return (
        <Paper className={classes.root} elevation={5}>
            {
                Object.keys(questionOptions).filter(c => !otherSelectedKeys.includes(c)).map((key, i) => {
                    const keySplit = key.split('-')
                    const typename = keySplit[0]
                    const data = questionOptions[key]

                    const is_comment = getIsComment(key)

                    return (
                        <SelectBoxOptionComponent key={key} primary={data.pure_question} secondary={data.text} typename={typename} indexs={i} is_comment={is_comment} onChange={() => onChange(keyValue || "", key, data)} />
                    )
                })
            }
        </Paper>
    )

}

export default memo(SelectItemsComponent)