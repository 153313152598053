import { useIntl } from 'react-intl'
import HelpIcon from '@material-ui/icons/Help'
import Tooltip from "styled/Custom/Tooltip"

function HelpComponent() {
    const { formatMessage: f } = useIntl()

    return (
        <Tooltip title={f({id: 'component.Survey.Create.Logic.Jump.Diagram.Help.title'})} placement="top">
            <HelpIcon color="action" style={{
                position: 'absolute',
                bottom: 160,
                left: 11,
                zIndex: 1000
            }}/>
        </Tooltip>
    )
}

export default HelpComponent