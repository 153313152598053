import { useIntl } from 'react-intl'
import HtmlParser from 'react-html-parser'
import { Button, Typography, Box } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const MyBox = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 30,
        margin: '0 auto',
        marginTop: -100
    }
})(Box)

const MyText = withStyles(theme => ({
    root: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: 13
        }
    }
}))(Typography)

function RemovePage({onRemovePage}) {

    const { formatMessage: f } = useIntl()

    return (
        <MyBox>
            <MyText color="secondary">
                {HtmlParser(f({id: 'component.Survey.Create.Module.RemovePage.tip'}))}
            </MyText>
            <Button
                variant="contained"
                color="inherit"
                size="large"
                startIcon={<DeleteIcon />}
                onClick={onRemovePage}
            >
                <Typography variant="button">{f({id: 'component.Survey.Create.Module.RemovePage.Button'})}</Typography>
            </Button>
        </MyBox>
    )
}

export default RemovePage