import oc from 'open-color'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyPageProps } from 'gql/survey_page'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import withFetchQuestionInPage from 'hoc/Survey/FetchQuestionInPage'
import CopyItemComponent from './CopyItem'
import { Props as OtherCopyProps } from './OtherCopy'
import useCopySave from './useCopySave'

export interface Props {
	other: boolean;
	other_survey_no: number | '';
	onChangePage: OtherCopyProps['onChangePage'];
    onChangeQuestion: OtherCopyProps['onChangeQuestion'];
    onChangePosition: OtherCopyProps['onChangePosition'];
    propsFetchQuestionInPage: {
        questions: SurveyQuestionModuleProps['_question'][];
        pages: SurveyPageProps[];
        questionAll: SurveyQuestionModuleProps['_question'][];
        val: {
            survey_page_no: number;
            survey_question_no: number;
            position: 0 | 1;
        }
    }
}

const MyBox = withStyles({
	root: (props: { view: number }) => {
		const { view } = props
		return {
			display: view === 1 ? 'block' : 'none',
			backgroundColor: oc.gray[1],
			padding: 10,
			borderRadius: 3
		}
	}
})(Box)

const CopyComponent = (props: Props) => {
	const { 
		other,
		other_survey_no,
		onChangePage, 
		onChangeQuestion,
		onChangePosition,
		propsFetchQuestionInPage: withs
	} = props

	const questionAll = withs.questionAll
    const survey_page_no = withs.val.survey_page_no 
    const survey_question_no = withs.val.survey_question_no

	useCopySave({
        questions: questionAll,
        survey_page_no,
        survey_question_no,
        other_survey_no
    })

	let view = 1
	if (other && !other_survey_no) view = 0
	return (
		<MyBox view={view}>
			<CopyItemComponent
				pages={withs.pages}
				questions={withs.questions}
				survey_page_no={withs.val.survey_page_no}
				survey_question_no={withs.val.survey_question_no}
				position={withs.val.position}
				onChangePage={onChangePage}
				onChangeQuestion={onChangeQuestion}
				onChangePosition={onChangePosition}
			/>
		</MyBox>
	)
}

export default withFetchQuestionInPage()(CopyComponent)