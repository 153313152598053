import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from "open-color"
import { parseHtmlIntl } from 'ts-utils'
import { Step } from 'gql/survey_analysis_summary'
import { Select, MenuItem, Box, Divider } from '@material-ui/core'
import { withStyles, styled } from '@material-ui/core/styles'
import { SelectStyled } from 'styled/Input'
import DateBox from './DateBox'
import FilterComponent from './Filter/Component'

export const IconBox = styled(Box)(({ disabled, click }) => {
        const styles = disabled ? { 
            cursor: 'default', 
            border: `1px solid ${oc.gray[2]}`, color: oc.gray[3] 
        } : { 
            cursor: 'pointer', 
            border: `1px solid ${oc.gray[4]}` 
        }

        return {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            background: click ? oc.gray[4] : 'white', 
            borderRadius: 5,
            width: 33,
            height: 33,
            ...styles       
        }
    
})

export const WrapperBox = withStyles({
    root: (props) => {
        const { justifyContent } = props 
        return {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            marginBottom: 8,
            justifyContent: justifyContent || 'space-between',
            '& > div': {
                display: 'flex'
            },
            '& > div:first-child': {
                gap: 5
            },
            '& > div:last-child': {
                gap: 3
            }
        }
    }
})(Box)

// 구분자 bar
export const BarBox = () => (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 33}}>
        <div style={{width: 1, height: 26, background: oc.gray[3], marginRight: 8, marginLeft: 8}}/>
    </div>
)

function ButtonsComponent(props) {
    const { survey_no, questions, endings, open, step, date, dateCookieName, on, children, onChangeStartPoint } = props

    const { formatMessage: f } = useIntl()

    return (
        <>
        <WrapperBox>
            <div>
                <DateBox 
                    cookieName={dateCookieName} 
                    open={open} 
                    date={date} 
                    onInitial={on.changeInitial} 
                    onOpen={on.openCalendar} 
                    onClose={on.closeCalendar} 
                    onDo={on.changeDate}
                />
                <Select 
                    value={step === Step.All ? Step.End : step} 
                    input={<SelectStyled fontSize={13} padding="6px 12px 6px 8px" backgroundcolor="white"/>} 
                    onChange={on.changeStep}
                    renderValue={(value) => {
                        return parseHtmlIntl(`<span style='font-size: 13px; font-weight: 700'>${f({id: 'component.Survey.Create.Analysis.Buttons.step.name'})}:</span> [${f({id: `component.Survey.Create.Analysis.Buttons.step.item.${value}`})}]`)
                    }}
                >
                    <MenuItem value={Step.End}>{f({id: 'component.Survey.Create.Analysis.Buttons.step.item.1'})}</MenuItem>
                    <MenuItem value={Step.Ing}>{f({id: 'component.Survey.Create.Analysis.Buttons.step.item.2'})}</MenuItem>
                </Select>
                <FilterComponent survey_no={survey_no} questions={questions} endings={endings} onChangeStartPoint={onChangeStartPoint} />
            </div>
            {children}
        </WrapperBox>
        <Divider style={{marginBottom: 10}} />
        </>
    )
}

export default memo(ButtonsComponent)