import { memo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useStyles } from './QuestionButtons'

interface Props {
    onSave: () => void;
}

let timer: ReturnType<typeof setTimeout>

function QuestionButtonsComponent(props: Props) {
    const { onSave } = props
    
    const classes = useStyles()

    const [ loading, setLoading ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleSave = async() => {
        if (loading) return 
        
        setLoading(true)

        timer = setTimeout(async () => {
            const res = await onSave()
            
            setLoading(false)
        }, 1000)
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <Button 
            className={classes.button} 
            startIcon={
                loading ? <CircularProgress size={15}  /> : <GetAppIcon fontSize="small" className={classes.icon} /> 
            } 
            disabled={loading}
            size="small" 
            variant="contained" 
            disableElevation  
            onClick={handleSave}
        >
            {f({id: `component.Survey.Create.Analysis.Summary.QuestionButtons.button.image`})}
        </Button>
    )
}

export default memo(QuestionButtonsComponent)