import { memo } from 'react'
import Box from '@material-ui/core/Box'
import Divder from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MoveComponent from './Move'
import CloseComponent from './Close'
import HideComponent from './Hide'
import PrintComponent from './Print'
import { HideQuestion, OnChangeHideQuestion, DivRef } from '../Component'

export interface Props {
    hideQuestion: HideQuestion;
    divRef: DivRef;
    id: number;
    onClose: () => void;
    onChangeHideQuestion: OnChangeHideQuestion;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'cneter',
        width: '100%',
        justifyContent: 'space-between',
        '& > div.sub': {
            display: 'flex',
            alignItems: 'cneter',
            justifyContent: 'center',
            gap: 5
        },
        padding: 10,
    }
}))

function HeadComponent(props: Props) {
    const { hideQuestion, divRef, id, onClose, onChangeHideQuestion } = props

    const classes = useStyles()

    return (
        <>
        <Box className={`${classes.root} head`}>
            <div className="sub">
                <MoveComponent />
            </div>
            <div className="sub">
                <HideComponent hide={hideQuestion} onChange={onChangeHideQuestion} />
                <PrintComponent divRef={divRef} id={id} />
                <CloseComponent onClose={onClose} />
            </div>
        </Box>
        <Divder light className="head-line" />
        </>
    )
}

export default memo(HeadComponent)