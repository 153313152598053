import { Container } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SurveyCreateAnalysisTabComponent from 'component/Survey/Create/NewAnalysis/Tab'

const MyContainer1 = withStyles(theme => ({
    root: {
        marginTop: 25,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 80
        }
    }
}))(props => <Container maxWidth="xl" disableGutters {...props}/>)

const Component = (props) => {
    const { children } = props
    
    return (
        <>
        <SurveyCreateAnalysisTabComponent/>
        <MyContainer1>
            <Container maxWidth="xl">
                {children}
            </Container>
        </MyContainer1>
        </>
    )
}

export default Component