// import IsChangeIcon from 'styled/Survey/Edit/IsChange'

import { useContext } from 'react'
import styled from 'styled-components'
import PropsContext from 'context/props'
import { getBackground } from 'utils/survey'

const WrapperStyled = styled.div`
    position: relative;
    height: calc(100vh - 64px);
    background-attachment: scroll !important;
    
    & > .dimmed {
        position: absolute;
        background: rgba(0, 0, 0, .6);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    & > .status {
        position: fixed;
        padding: 1em;
   
        margin-left: .5em;
        z-index: 2;
    }

    & > div.body {
        max-width: 100%;
        height: 100%;
        overflow-y: auto;
    }
`

export default (props) => {
    const { used, children } = props
    const { design, mbox } = useContext(PropsContext)
    const { method } = mbox
    const style = getBackground(design)

    return (
        <WrapperStyled method={method} style={style}>
            <div className="status">
                {/*<IsChangeIcon isChange={isChange}/>*/}
            </div>
            {used === false && <div className="dimmed"></div>}
            <div className='body'>{children}</div>
        </WrapperStyled>
    )
}