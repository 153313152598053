import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { parseHtmlIntl, getKeyOfArray } from 'ts-utils'
import { getLabel } from 'ts-utils/modules'
import { getExcelParsing } from 'utils/survey'
import { getDownloadTitle } from 'utils/analysis'
import ordinal from 'ordinal'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { QuestionType, _02Module, _03Module, _04Module, _04Answer } from 'gql/survey_question_modules'
import { Workbook, Worksheet } from 'exceljs'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Tooltip from 'styled/Custom/Tooltip'
import { RowsProps } from 'hoc/Survey/Rows'
import { Props as ParentProps } from './DownloadMenu'
import { MyDialogContentText, MyListItemText, useStyles, MyTip, getHyperlink } from './Raw'
import useSurveyTitle from 'hooks/useSurveyTitle'

const equalTypeBaseOne = ['_01', '_12']
const equalTypeBaseMulti = ['_02', '_13']
const equalTypeMatrixOne = ['_03', '_08', '_15']
const equalTypeMatrixMulti = ['_04']
const equalTypePoint = ['_05']
const equalTypePointMatrix = ['_06']
const equalTypeRecommand = ['_07', '_14']
const equalTypeRankDrag = ['_11']
const equalTypeRankClick = ['_17', '_18']
const equalTypeText = ['_09', '_10', '_20']
const equalTypeFileUpload = ['_19']
const equalTypeDropdown = ['_21']

interface Props {
    questions: ParentProps['etc']['questions'];
    data: RowsProps['rowsData']['rows'];
    onClose: () => void;
}

interface DoProps {
    questions: ParentProps['etc']['questions'];
    data: RowsProps['rowsData']['rows'];
    f: any;
    verifyUsed: boolean;
    title?: string;
}

interface VerfiyResponseBaseOne {
    row: any;
    survey_question_no: number;
}

interface VerfiyResponseBaseMulti {
    row: any;
    survey_question_no: number;
    modules: _02Module[];
}

interface VerfiyResponseMatrixOne {
    row: any;
    survey_question_no: number;
    modules: _03Module[];
}

interface VerfiyResponseMatrixMulti {
    row: any;
    survey_question_no: number;
    modules: _04Module[];
    answers: _04Answer[];
}

interface VerfiyResponseText {
    row: any;
    survey_question_no: number;
}

interface WriteResponseBaseOneProps {
    worksheet: Worksheet; 
    row: any;
    key: number;
    survey_question_no: number; 
    line: number;
}

interface WriteResponseBaseMultiProps {
    worksheet: Worksheet; 
    row: any;
    survey_question_no: number; 
    survey_module_no: number;
    line: number;
}

interface WriteResponseMatrixOneProps {
    worksheet: Worksheet; 
    row: any;
    key: number;
    survey_question_no: number; 
    survey_module_no: number;
    line: number;
}

interface WriteResponseMatrixMultiProps {
    worksheet: Worksheet; 
    row: any;
    survey_question_no: number; 
    survey_module_no: number;
    survey_module_answer_no: number;
    line: number;
}

interface WriteResponseRankProps{
    worksheet: Worksheet;
    rank: number; 
    line: number;
}

interface WriteExampleCommentProps {
    worksheet: Worksheet; 
    row: any;
    survey_question_no: number; 
    survey_module_no: number;
    line: number;
}

interface WriteRankExampleCommentProps {
    worksheet: Worksheet; 
    row: any;
    survey_question_no: number;
    survey_module_no: number;
    survey_module_answer_no: number;
    line: number;
}

interface WriteCommentProps {
    worksheet: Worksheet; 
    row: any;
    survey_question_no: number; 
    line: number;
}

class Verify {
    used: boolean
    constructor(used: boolean) {
        this.used = used
    }

    responseBaseOne(props: VerfiyResponseBaseOne) {
        const { row, survey_question_no } = props

        if (!this.used) return true

        const is = row[`Q${survey_question_no}`]
        if (is) {
            const [ value ] = is
            return Boolean(value)
        }
    
        return false
    }
    
    responseBaseMulti(props: VerfiyResponseBaseMulti) {
        const { row, survey_question_no, modules } = props

        if (!this.used) return true
    
        for (let i=0;i<modules.length; i++) {
            const { survey_module_no } = modules[i]
            const is = row[`Q${survey_question_no}-${survey_module_no}`]
            if (is) {
                const [ value ] = is
                return Boolean(value)
            }
        }
    
        return false
    }
    
    responseMatrixOne(props: VerfiyResponseMatrixOne) {
        const { row, survey_question_no, modules } = props

        if (!this.used) return true
    
        for (let i=0;i<modules.length; i++) {
            const { survey_module_no } = modules[i]
            const is = row[`Q${survey_question_no}-${survey_module_no}`]
            if (is) {
                const [ value ] = is
                return Boolean(value)
            }
        }
    
        return false
    }
    
    responseMatrixMulti(props: VerfiyResponseMatrixMulti) {
        const {row, survey_question_no, modules, answers } = props

        if (!this.used) return true
    
        for (let i=0; i<modules.length; i++) {
            const { survey_module_no } = modules[i]
            for (let j=0; j<answers.length; j++) {
                const { survey_module_answer_no } = answers[i]
                const is = row[`Q${survey_question_no}-${survey_module_no}-${survey_module_answer_no}`]
                if (is) {
                    const [ value ] = is
                    return Boolean(value)
                }
            }
        }
    
        return false
    }

    responseText(props: VerfiyResponseText) {
        const { row, survey_question_no } = props

        if (!this.used) return true

        const is = row[`Q${survey_question_no}`]
        if (is) {
            const [ value ] = is
            return Boolean(value)
        }
    
        return false
    }
    
    responseEnding(number: number) {
        if (!this.used) return true

        return Boolean(number)
    }
}

function checkResponse(worksheet: Worksheet, key: string, checked: boolean) {
    worksheet.getCell(key).value = checked ? '●' : ''
    worksheet.getCell(key).font = { family: 1, size: 7, bold: true }
    worksheet.getCell(key).alignment = { horizontal: 'center', vertical: 'middle' }
}

function checkComment(worksheet: Worksheet, key: string) {
    worksheet.getCell(key).value = 'COMMENT'
    worksheet.getCell(key).font = { family: 1, size: 6, bold: false }
    worksheet.getCell(key).alignment = { horizontal: 'center', vertical: 'middle' }
}

function commonAnswer(worksheet: Worksheet, startKey: string, endKey: string, line: number, value: string) {
    worksheet.mergeCells(`${startKey}${line}:${endKey}${line}`)
    worksheet.getCell(`${startKey}${line}`).value = value
    worksheet.getCell(`${startKey}${line}`).font = { family: 1, size: 11, bold: false }
    worksheet.getCell(`${startKey}${line}`).alignment = { wrapText: true }
}

// 단일 선택형 답변 검증
function writeResponseBaseOne(props: WriteResponseBaseOneProps) {
    const { worksheet, row, key, survey_question_no, line } = props

    const is = row[`Q${survey_question_no}`]
    if (is) {
        const [ value ] = is
        checkResponse(worksheet, `B${line}`, value === key)
    }
}

// 멀티 선택형 답변 검증
function writeResponseBaseMulti(props: WriteResponseBaseMultiProps) {
    const { worksheet, row, survey_question_no, survey_module_no, line } = props

    const is = row[`Q${survey_question_no}-${survey_module_no}`]
    if (is) {
        const [ value ] = is
        checkResponse(worksheet, `B${line}`, Boolean(value))
    }
}

// 매트릭스 단일 선택형 검증
function writeResponseMatrixOne(props: WriteResponseMatrixOneProps) {
    const { worksheet, row, key, survey_question_no, survey_module_no, line } = props

    const is = row[`Q${survey_question_no}-${survey_module_no}`]
    if (is) {
        const [ value ] = is
        checkResponse(worksheet, `B${line}`, value === key)
    }
}

// 매트릭스 멀티 선택형 검증
function writeResponseMatrixMulti(props: WriteResponseMatrixMultiProps) {
    const { worksheet, row, survey_question_no, survey_module_no, survey_module_answer_no, line } = props

    const is = row[`Q${survey_question_no}-${survey_module_no}-${survey_module_answer_no}`]
    if (is) {
        const [ value ] = is
        checkResponse(worksheet, `B${line}`, Boolean(value))
    }
}

// 순위 체크
function wrriteResponseRank(props: WriteResponseRankProps) {
    const { worksheet, rank, line } = props
    worksheet.getCell(`B${line}`).value = ordinal(rank)
    worksheet.getCell(`B${line}`).font = { family: 1, size: 9, bold: true }
    worksheet.getCell(`B${line}`).alignment = { horizontal: 'center', vertical: 'middle' }
}

// 보기별 코멘트
function writeExampleComment(props: WriteExampleCommentProps) {
    const { worksheet, row, survey_question_no, survey_module_no, line } = props
    let newLine = line + 1

    const is = row[`Q${survey_question_no}-${survey_module_no}-comment`]
    if (is) {
        const [ _, value ] = is
        if (value) {
            commonAnswer(worksheet, 'D', 'I', newLine, value)

            checkComment(worksheet, `C${newLine}`)

            return true
        }
    }

    return false
}

// 순위형 코멘트
function writRankExampleComment(props: WriteRankExampleCommentProps) {
    const { worksheet, row, survey_question_no, survey_module_no, survey_module_answer_no, line } = props
    let newLine = line + 1

    const is = row[`Q${survey_question_no}-${survey_module_no}-${survey_module_answer_no}-comment`]

    if (is) {
        const [ _, value ] = is
        if (value) {
            commonAnswer(worksheet, 'D', 'I', newLine, value)
            checkComment(worksheet, `C${newLine}`)

            return true
        }
    }

    return false
}

// 코멘트
function writeComment(props: WriteCommentProps) {
    const { worksheet, row, survey_question_no, line } = props
    let newLine = line + 1

    const is = row[`Q${survey_question_no}-comment`]
    if (is) {
        const [ _, value ] = is
        commonAnswer(worksheet, 'C', 'I', newLine, value)
        checkComment(worksheet, `B${newLine}`)

        return true
    }

    return false
}

function writeText(props: WriteCommentProps) {
    const { worksheet, row, survey_question_no, line } = props
    let newLine = line + 1

    const is = row[`Q${survey_question_no}`]
    if (is) {
        const [ _, value ] = is
        commonAnswer(worksheet, 'B', 'I', newLine, value)

        return true
    }

    return false
}

function writeFileText(props: WriteCommentProps) {
    const { worksheet, row, survey_question_no, line } = props
    let newLine = line + 1

    const key = `Q${survey_question_no}`
    const is = row[key]
    if (is) {
        const [ _, text ] = is
        const hyperlink = getHyperlink(key, is)
        const value = hyperlink ? {  text, hyperlink } : text
        commonAnswer(worksheet, 'B', 'I', newLine, value)

        return true
    }

    return false
}

function writeIntro(worksheet:Worksheet, key: any, value: string, line: number) {
    worksheet.mergeCells(`A${line}:C${line}`)
    worksheet.getCell(`A${line}`).value = key
    worksheet.getCell(`A${line}`).font = { family: 3, size: 10, bold: false }
    worksheet.getCell(`A${line}`).fill = { 	type: 'pattern', pattern: 'solid', fgColor: { argb: 'e9ecef' }  }
    worksheet.getCell(`A${line}`).border = {
        bottom: { style: 'thin', color: { argb: 'ffffff' } },
        right: { style: 'thin', color: { argb: 'ffffff' } }
    }
    worksheet.getCell(`A${line}`).alignment = { horizontal: 'center', vertical: 'middle' }

    worksheet.mergeCells(`D${line}:G${line}`)
    worksheet.getCell(`D${line}`).value = value
    worksheet.getCell(`D${line}`).font = { family: 1, size: 10, bold: false }
    worksheet.getCell(`D${line}`).alignment = { indent: 1 }
}

function writeQuestion(worksheet:Worksheet, number: number | '', value:string, line: number) {
    worksheet.mergeCells(`B${line}:I${line}`)
    worksheet.getCell(`B${line}`).value = value
    worksheet.getCell(`B${line}`).font = { family: 1, size: 11, bold: true }
    worksheet.getCell(`B${line}`).alignment = { wrapText: true, shrinkToFit: true }

    if (number) {
        worksheet.getCell(`A${line}`).value = number
        worksheet.getCell(`A${line}`).font = { family: 2, size: 9, bold: true }
        worksheet.getCell(`A${line}`).alignment = { wrapText: true, shrinkToFit: true, horizontal: 'center', vertical: 'middle' }
    }
}

function writeAnswer(worksheet:Worksheet, value:string, line: number) {
    commonAnswer(worksheet, 'C', 'I', line, value)
}

function writeEnding(worksheet:Worksheet, number: number, text: string, line: number) {
    commonAnswer(worksheet, 'C', 'I', line, text)

    worksheet.getCell(`B${line}`).value = number
    worksheet.getCell(`B${line}`).font = { family: 3, size: 11, bold: false }
    worksheet.getCell(`B${line}`).alignment = { horizontal: 'center', vertical: 'middle' }
}

function writePoint(worksheet:Worksheet, number: number, line: number) {
    worksheet.getCell(`B${line}`).value = number
    worksheet.getCell(`B${line}`).font = { family: 3, size: 11, bold: false }
    worksheet.getCell(`B${line}`).alignment = { horizontal: 'center', vertical: 'middle' }
}


function writeColumn(worksheet:Worksheet, value:string, line: number) {
    commonAnswer(worksheet, 'B', 'I', line, value)
}

function writeColumnAnswer(worksheet:Worksheet, value:string, line: number) {
    commonAnswer(worksheet, 'C', 'I', line, value)
}

function writeSpace(worksheet:Worksheet, line: number) {
    commonAnswer(worksheet, 'A', 'I', line, '')
}

export async function Do(props: DoProps) {
    return new Promise(async function(resolve, reject) {
        const { f, questions, data, verifyUsed, title: ctitle } = props

        const title = getDownloadTitle({title: ctitle, type: 'Personals' })

        const verify = new Verify(verifyUsed)

        const { columns, answers: rows }: { answers: any[] } = data
        const rowsLen = rows.length
        const questionLen = questions.length 
    
        const zip = new JSZip()

        // id, ip/email, wdate ~ ending 필드만 가져오기
        const notQuestion = columns.filter(column => !/^Q/.test(column.field))
        const fieldNameObj = getKeyOfArray('field', notQuestion)
    
        function getFieldName(id: string) {
            const [ value ] = fieldNameObj[id]

            return typeof value.headerName === 'object' ? value.headerName[0] : value.headerName
        }
    
        function make(props: { workbook: Workbook, start: number, end: number }) {
            const { workbook, start, end } = props

            for (let i=start; i<=end; i++) {
                if (i > (rowsLen - 1)) break
    
                const row = rows[i]
                const { id, ip, accesstime, einterval }: {id: number, ip: string, accesstime: string, einterval: string } = row
                const worksheet = workbook.addWorksheet(String(id), { pageSetup: { paperSize: 9, orientation: 'portrait' }})
                const dobCol = worksheet.getColumn(1)
                dobCol.width = 3
        
                let line=0
                
                writeIntro(worksheet, getFieldName('id'), String(id), ++line)
                writeIntro(worksheet, getFieldName('ip'), ip, ++line)
                writeIntro(worksheet, getFieldName('accesstime'), accesstime, ++line)
                writeIntro(worksheet, getFieldName('einterval'), einterval, ++line)
        
                writeSpace(worksheet, ++line) 
        
                for (let j=0; j<questionLen; j++) {
                    const question = questions[j]
                    const { _question } = question
                    const { survey_question_no, question_type, question: origin_question, number, module_type } = _question

                    const pure_question = String(getExcelParsing(origin_question))
                    
                    if (question_type === QuestionType.Explain) continue
                    if (equalTypeFileUpload.includes(module_type)) {
                        if (!verify.responseText({row, survey_question_no})) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        if (writeFileText({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                        if (writeComment({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else if (equalTypeText.includes(module_type)) {
                        if (!verify.responseText({row, survey_question_no})) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        if (writeText({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else if (equalTypeBaseOne.includes(module_type)) {
                        if (!verify.responseBaseOne({row, survey_question_no})) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        const { _modules } = question
                        const moduleLen = _modules.length
        
                        for (let n=0; n<moduleLen; n++) {
                            const { answer, indexs, survey_module_no }:any = _modules[n]
        
                            writeAnswer(worksheet, `${n+1}. ${answer}`, ++line)
        
                            writeResponseBaseOne({worksheet, row, key: indexs, survey_question_no, line})
        
                            if (writeExampleComment({worksheet, row, survey_question_no, survey_module_no, line})) {
                                ++line
                            }
                        }
                    } else if (equalTypeBaseMulti.includes(module_type)) {
                        const { _modules } = question
                        
                        if (!verify.responseBaseMulti({row, survey_question_no, modules: _modules })) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        const moduleLen = _modules.length
        
                        for (let n=0; n<moduleLen; n++) {
                            const { answer, survey_module_no }:any = _modules[n]
        
                            writeAnswer(worksheet, `${n+1}. ${answer}`, ++line)
        
                            writeResponseBaseMulti({worksheet, row, survey_question_no, survey_module_no, line})
        
                            if (writeExampleComment({worksheet, row, survey_question_no, survey_module_no, line})) {
                                ++line
                            }
                        }
                    } else if (equalTypeMatrixOne.includes(module_type)) {
                        const { _modules, _answers } = question

                        if (!verify.responseMatrixOne({row, survey_question_no, modules: _modules })) continue

                        writeQuestion(worksheet, number, pure_question, ++line)
                        
                        const moduleLen = _modules.length
                        const answerLen = _answers.length
        
                        for (let n=0; n<moduleLen; n++) {
                            const { column, survey_module_no }:any = _modules[n]
        
                            writeColumn(worksheet, `${n+1}. ${column}`, ++line)
        
                            for (let m=0; m<answerLen; m++) {
                                const { answer, indexs }:any = _answers[m]
        
                                writeColumnAnswer(worksheet, `${indexs}. ${answer}`, ++line)
        
                                writeResponseMatrixOne({ worksheet, row, key: indexs, survey_question_no, survey_module_no, line })
                            }
                        }
        
                        if (writeComment({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else if (equalTypeMatrixMulti.includes(module_type)) {
                        const { _modules, _answers } = question

                        if (!verify.responseMatrixMulti({row, survey_question_no, modules: _modules, answers: _answers })) continue

                        writeQuestion(worksheet, number, pure_question, ++line)
                        
                        const moduleLen = _modules.length
                        const answerLen = _answers.length
        
                        for (let n=0; n<moduleLen; n++) {
                            const { column, survey_module_no }:any = _modules[n]
        
                            writeColumn(worksheet, `${n+1}. ${column}`, ++line)
        
                            for (let m=0; m<answerLen; m++) {
                                const { answer, survey_module_answer_no }:any = _answers[m]
        
                                writeColumnAnswer(worksheet, `${m+1}. ${answer}`, ++line)
        
                                writeResponseMatrixMulti({ worksheet, row, survey_question_no, survey_module_no, survey_module_answer_no, line })
                            }
                        }
        
                        if (writeComment({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else if (equalTypePoint.includes(module_type)) {
                        if (!verify.responseBaseOne({row, survey_question_no})) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        const { _modules, _option } = question
                        const moduleLen = _modules.length

                        const { left_label, right_label, reverse }:any = _option
                        const leftLabel = left_label || ''
                        const rightLabel = right_label || ''

                        for (let n=0; n<moduleLen; n++) {
                            const { indexs }:any = _modules[n]

                            writeAnswer(worksheet, `${indexs} ${getLabel({ indexs, len: moduleLen, reverse, left_label: leftLabel, right_label: rightLabel })}`, ++line)
        
                            writeResponseBaseOne({worksheet, row, key: indexs, survey_question_no, line})
                        }
        
                        if (writeComment({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else if (equalTypeRecommand.includes(module_type)) {
                        if (!verify.responseBaseOne({row, survey_question_no})) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        const { _modules } = question
                        const moduleLen = _modules.length
        
                        for (let n=0; n<moduleLen; n++) {
                            const { indexs, answer }:any = _modules[n]
                        
                            writeAnswer(worksheet, `${indexs}. ${answer}`, ++line)
        
                            writeResponseBaseOne({worksheet, row, key: indexs, survey_question_no, line})
                        }
        
                        if (writeComment({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else if (equalTypePointMatrix.includes(module_type)) {
                        const { _modules, _answers, _option } = question

                        if (!verify.responseMatrixOne({row, survey_question_no, modules: _modules })) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        const moduleLen = _modules.length
                        const answerLen = _answers.length
                        
                        const { left_label, right_label, reverse }:any = _option
                        const leftLabel = left_label || ''
                        const rightLabel = right_label || ''
        
                        for (let n=0; n<moduleLen; n++) {
                            const { column, survey_module_no }:any = _modules[n]
        
                            writeColumn(worksheet, `${n+1}. ${column}`, ++line)
        
                            for (let m=0; m<answerLen; m++) {
                                const { indexs }:any = _answers[m]
                                
                                writeAnswer(worksheet, `${indexs} ${getLabel({ indexs, len: answerLen, reverse, left_label: leftLabel, right_label: rightLabel })}`, ++line)
        
                                writeResponseMatrixOne({ worksheet, row, key: indexs, survey_question_no, survey_module_no, line } )
                            }
                        }
        
                        if (writeComment({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else if (equalTypeRankDrag.includes(module_type) || equalTypeRankClick.includes(module_type)) {
                        const { _modules, _answers } = question

                        if (!verify.responseMatrixOne({row, survey_question_no, modules: _modules })) continue

                        writeQuestion(worksheet, number, pure_question, ++line)

                        const moduleLen = _modules.length
                        
                        // 답변이 있는지 체크
                        let isAnswer = false

                        for (let n=0; n<moduleLen; n++) {
                            const { survey_module_no, indexs }:any = _modules[n]
                    
                            const is = row[`Q${survey_question_no}-${survey_module_no}`]
                            if (is) {
                                const [ value ] = is
                                const _answer = _answers[(value - 1)]
                                if (_answer) {
                                    const { survey_module_answer_no, indexs: number, answer }: any = _answer
                                    writeAnswer(worksheet, `${number}. ${answer}`, ++line)
                                    wrriteResponseRank({ worksheet, rank: indexs, line })
        
                                    // 17, 18은 보기에 코멘트 값이 있일수 있다.
                                    if (equalTypeRankClick.includes(module_type)) {
                                        if (writRankExampleComment({worksheet, row, survey_question_no, survey_module_no, survey_module_answer_no, line})) {
                                            ++line
                                        }
                                    }

                                    isAnswer = true
                                }
                            } else break // 1순위 부터 검색하는 거기때문에 값이 없다면, 그 다음것들도 무조건 없다
                        }

                        // 답변이 없다면 그냥 보기 순서대로 보여주자
                        if (!isAnswer) {
                            const answerLen = _answers.length
                            for (let n=0; n<answerLen; n++) {
                                const { indexs: number, answer }: any = _answers[n]
                                writeAnswer(worksheet, `${number}. ${answer}`, ++line)
                            }
                        }
                        
                        // 11번 문항에 코멘트가 들어간다
                        if (equalTypeRankDrag.includes(module_type)) {
                            if (writeComment({ worksheet, row, survey_question_no, line })) {
                                ++line
                            }
                        }
                    }  else if (equalTypeDropdown.includes(module_type)) {
                        const { _modules, _option } = question
                        const { kind } = _option

                        const moduleLen = _modules.length

                        if (!kind) {
                            if (!verify.responseBaseOne({row, survey_question_no})) continue
                            
                            writeQuestion(worksheet, number, pure_question, ++line)

                            for (let n=0; n<moduleLen; n++) {
                                const { answer, indexs }:any = _modules[n]
                        
                                writeAnswer(worksheet, `${n+1}. ${answer}`, ++line)
                        
                                writeResponseBaseOne({worksheet, row, key: indexs, survey_question_no, line})
                            }
                        } else {
                            if (!verify.responseBaseMulti({row, survey_question_no, modules: _modules })) continue

                            writeQuestion(worksheet, number, pure_question, ++line)

                            for (let n=0; n<moduleLen; n++) {
                                const { answer, survey_module_no }:any = _modules[n]
                        
                                writeAnswer(worksheet, `${n+1}. ${answer}`, ++line)
                        
                                writeResponseBaseMulti({worksheet, row, survey_question_no, survey_module_no, line})
                                
                            }
                        }
                    
                        if (writeComment({ worksheet, row, survey_question_no, line })) {
                            ++line
                        }
                    } else continue
        
                    writeSpace(worksheet, ++line)
                }
    
                if (row['ending']) {
                    const [ number, text ] = row['ending']
                    if (verify.responseEnding(number)) {
                        writeQuestion(worksheet, '', getFieldName('ending'), ++line)
                        writeEnding(worksheet, number, text, ++line) 
                    }
                }

                if (row['point']) {
                    const [ number ] = row['point']
                    if (verify.responseEnding(number)) {
                        writeQuestion(worksheet, '', f({id: 'component.Survey.Create.Analysis.Rows.Personal.point'}), ++line)
                        writePoint(worksheet, number, ++line) 
                    }
                }
            }
            
            return workbook
        }
    
        const no = Math.ceil(rowsLen / 50)
        const splits = []
        for (let i=0; i<no; i++) {
            const j = i+1  
            
            splits.push({
                start: i * 50,
                end: j * 50 - 1
            })
        }
        
        for (let i=0; i<splits.length; i++) {
            const workbook = new Workbook()
            const { start, end } = splits[i]
            const newWorbook = make({ workbook, start, end })
    
            const mimeType = { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
            const buffer = await newWorbook.xlsx.writeBuffer(); 
            const blob = new Blob([buffer], mimeType); 
        
            zip.file(`${title}-${(i+1)}.xlsx`, blob)
        }
        
        if (splits.length > 1) {
            zip.generateAsync({type: 'blob'}).then(function(content) {
                FileSaver.saveAs(content, `${title}.zip`)
                resolve(1)
            })
        } else {
            zip.file(`${title}-1.xlsx`).async('blob').then(function (data) {
                FileSaver.saveAs(data, `${title}.xlsx`)
                resolve(1)
            })
        }
    })
    
}

function Personal(props: Props) {
    const { questions, data, onClose } = props
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const [ title ] = useSurveyTitle()

    const [ verifyUsed, setVerifyUsed ] = useState(false)

    async function download() {
        dispatch({ type: BACKDROPSHOW, msg: f({id: 'component.Survey.Create.Analysis.Summary.DataDownloadDialog.downloadMsg'}) })
        setTimeout(async() => {
            await Do({f, title, questions, verifyUsed, data})
            dispatch({ type: BACKDROPHIDE })
        }, 100)
    }

    const disabled = data.answers.length === 0
    return (
        <>
        <DialogContent>
            <MyDialogContentText>
                {parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.Personal.contentText'}))}
            </MyDialogContentText>
            <MyTip>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.DataDownloadDialog.tip'}))}</MyTip>
            <List className={classes.list}>
                <ListItem>
                    <MyListItemText>
                        {f({id: 'component.Survey.Create.Analysis.Rows.Personal.list.verifyUsed'})}
                    </MyListItemText>
                    <ListItemSecondaryAction>
                        <Switch color="primary" checked={verifyUsed} onChange={(e) => setVerifyUsed(e.target.checked)}/>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </DialogContent>
        <DialogActions>
            <Tooltip title={disabled ? f({id: 'component.Survey.Create.Analysis.Rows.Personal.disabled'}) : ''} placement="top" isMobileClick={true}>
                <span>
                    <Button color="primary" onClick={download} disabled={disabled}>
                        {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.button.download'})}
                    </Button>
                </span>
            </Tooltip>
            <Button onClick={onClose} color="default">
                {f({id: 'component.Survey.Create.Analysis.Rows.DataDownloadDialog.button.close'})}
            </Button>
        </DialogActions>
        </>
    )
}

export default Personal