import { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import oc from 'open-color'
import { getMimePicture } from 'utils/survey'
import { parseHtmlIntl } from 'ts-utils'
import { SHOW as ALERTSNACKBARSHOW  } from 'reducer/alertSnackbar'
import styled from 'styled-components'
import { 
    Paper, Typography, List, ListItem, ListItemText, ListItemAvatar, Select, MenuItem,
    ListItemSecondaryAction, ListSubheader, Divider, Switch, IconButton
} from '@material-ui/core'
import { 
    Add as AddIcon, 
    DeleteForever as DeleteForeverIcon, 
    Replay as ReplayIcon,
    SaveTwoTone as SaveTwoToneIcon
} from '@material-ui/icons'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from 'styled/Custom/Tooltip'
import PopupImage from 'styled/PopupImage'
import FontFamilys from 'styled/Survey/FontFamilys'
import ColorPickerComponent from './ColorPicker'
import BackgroundOpacity from 'component/Survey/Create/Design/Custom/BackgroundOpacity'

const useStyles = makeStyles(theme => ({
    fontFamilysBox: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    subtitle: {
        paddingLeft: 15,
        marginTop: 3,
        listStylePosition: 'outside', 
        listStyleType: 'disc',
        wordBreak: 'break-all', 
        fontSize: 13,
        color: theme.palette.grey[600],
        '& > li + li': {
            marginTop: 2
        }
    },
    secondaryItem: {
        paddingLeft: 28,
        paddingBottom: 8,
        listStylePosition: 'outside', 
        listStyleType: 'disc',
        wordBreak: 'break-all', 
        fontSize: 13,
        color: theme.palette.grey[600],
        '& > li + li': {
            marginTop: 5
        }
    }
}))

const PaperStyled = withStyles((theme) => ({
    root: {
        width: 25,
        height: 25,
        cursor: 'pointer'
    }
}))(Paper)

const MyListSubheader = withStyles((theme) => ({
    root: {
       background: oc.gray[0]
    }
}))(ListSubheader)

const WrapperStyled = styled.div`
    height: calc(100vh - 100px);
    overflow-y: auto;
  
    @media only screen and (max-width: 960px) {
        height: initial;
    }
`

const ButtonBoxStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const initPicker = {
    anchor: null,
    name: '',
    color: ''
}

const Component = (props) => {
    const { design, originDesign, backgroundCoverOpacity, on } = props
    const { font_family, question, background_color, bar, background_cover, background_logo } = design

    const classes = useStyles()

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ openImage, setOpenImage ] = useState({open: false, type: ''})

    const [ image, setImage ] = useState('')

    const [ picker, setPicker] = useState(initPicker)

    const onOpenImage = useCallback((type) => setOpenImage({open: true, type}), [])

    const onCloseImage = useCallback(() => setOpenImage(prevState => ({...prevState, open: false})), [])

    const onChangeImage = useCallback(props => {
        const { img_src } = props
        setImage(img_src)
    }, [])
    
    const onAppendUrl = useCallback(props => {
        const { img_src } = props
        
        setImage(img_src)
        return 
        
    }, [])

    function handleOpenPicker(event) {
        const key = event.currentTarget.getAttribute('name')
        const color = design[key]

        setPicker({
            anchor: event.currentTarget,
            name: key,
            color
        })
    }

    const handleClosePicker = useCallback(() => {
        setPicker(initPicker)
    }, [])

    const onSave = () => {
        const mime = getMimePicture(image)

        if (mime !== 'image') {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Image.Container.alert.appendUrl.nothing'})
            })

            return
        }
            
        const img = document.createElement('img');
        img.src = image

        img.onload = function () {
            on.changeImage({ type: openImage.type, src: image })
            onCloseImage()
        }

        img.onerror = function() {
            dispatch({ 
                type: ALERTSNACKBARSHOW, 
                variant: 'error', 
                message: f({id: 'container.Image.Container.alert.appendUrl.image'})
            })
        }

    }


    // 폰트/색상값 equal check
    const isEqualColor = `${font_family}${question}${background_color}${bar}` === 
                         `${originDesign.font_family}${originDesign.question}${originDesign.background_color}${originDesign.bar}`

    return (
        <>
        <WrapperStyled>
        <Typography variant="h5">{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.title'})}</Typography>
        <ul className={classes.subtitle}>
            <li> {parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.subtitle.0'}))}</li>
            <li> {parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.subtitle.1'}))}</li>
        </ul>

        <List>
            <MyListSubheader>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.subheader.0'})}</MyListSubheader>
            <ListItem divider>
                <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.open'})}</ListItemText>
                <ListItemSecondaryAction><Switch color="primary" edge="end" checked={design.open} onClick={on.saveOpen}/></ListItemSecondaryAction>
            </ListItem>
           
            <ListItem>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.viewType'})}
                />
                <ListItemAvatar>
                    <Select onChange={on.saveViewType} value={design.view_type}>
                        <MenuItem value="all">{f({id: `component.Survey.Create.Analysis.Summary.EditDesign.listItem.viewTypeItem.all`})}</MenuItem>
                        <MenuItem value="total">{f({id: `component.Survey.Create.Analysis.Summary.EditDesign.listItem.viewTypeItem.total`})}</MenuItem>
                        <MenuItem value="self">{f({id: `component.Survey.Create.Analysis.Summary.EditDesign.listItem.viewTypeItem.self`})}</MenuItem>
                    </Select>
                </ListItemAvatar>
            </ListItem>
            <ul className={classes.secondaryItem}>
                <li>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.viewTypeItemSub.0'}))}</li>
                <li>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.viewTypeItemSub.1'}))}</li>
            </ul>
            <Divider/>

            <ListItem>
                <ListItemText 
                    primary={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.response'})} 
                />
                <ListItemAvatar><Switch color="primary" edge="end" checked={design.response} onClick={on.saveResponse}/></ListItemAvatar>
            </ListItem>
            <ul className={classes.secondaryItem}>
                <li>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.responseSub.0'}))}</li>
                <li>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.responseSub.1'}))}</li>
            </ul>
            <Divider/>
        </List>
      
        <List style={{marginTop: 20}}>
            <MyListSubheader>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.subheader.1'})}</MyListSubheader>
            <ListItem >
                <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.font'})}</ListItemText>
                <ListItemText>
                    <div className={classes.fontFamilysBox}>
                        <FontFamilys value={font_family} backgroundcolor="white" onChange={on.changeFontFamily}/>
                    </div>
                </ListItemText>
            </ListItem>
            <Divider light/>
            <ListItem >
                <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.text'})}</ListItemText>
                <ListItemSecondaryAction><PaperStyled name="question" style={{background: `rgba(${question})`}} onClick={handleOpenPicker}></PaperStyled></ListItemSecondaryAction>
            </ListItem>
            <Divider light/>
            <ListItem >
                <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.background'})}</ListItemText>
                <ListItemSecondaryAction>  <PaperStyled name="background_color" style={{background: `rgba(${background_color})`}} onClick={handleOpenPicker}></PaperStyled></ListItemSecondaryAction>
            </ListItem>
            <Divider light/>
            <ListItem >
                <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.bar'})}</ListItemText>
                <ListItemSecondaryAction>  <PaperStyled name="bar" style={{background: `rgba(${bar})`}} onClick={handleOpenPicker}></PaperStyled></ListItemSecondaryAction>
            </ListItem>
            <Divider/>
            {
                !isEqualColor && (
                    <ButtonBoxStyled>
                        <Tooltip title={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.tooltip.save'})}>
                            <IconButton onClick={on.saveColorPicker} color="primary" >
                                <SaveTwoToneIcon fontSize="large"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.tooltip.rollback'})}>
                            <IconButton onClick={on.revertColorPicker}>
                                <ReplayIcon/>
                            </IconButton>
                        </Tooltip>
                    </ButtonBoxStyled>
                )
            }
        </List>
        <List style={{marginTop: 20}}>
            <MyListSubheader>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.subheader.2'})}</MyListSubheader>
            <ListItem >
                <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.cover'})}</ListItemText>
                
                <ListItemSecondaryAction> 
                    {
                        !background_cover ? (
                            <Tooltip title={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.tooltip.add'})}>
                                <IconButton size="small" onClick={() => onOpenImage('background_cover')}><AddIcon/></IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.tooltip.remove'})}>
                                <IconButton size="small" onClick={() => on.changeImage({ type: 'background_cover', src: '' })} color="secondary" >
                                    <DeleteForeverIcon/>
                                </IconButton>
                            </Tooltip>
                        )                                        
                    }
                </ListItemSecondaryAction>
            </ListItem>
            {background_cover && (
                <ListItem dense>
                    <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.opacity'})}</ListItemText>
                    <ListItemText style={{paddingRight: 10}}>   
                        <BackgroundOpacity background_opacity={backgroundCoverOpacity} onChange={on.changeCoverOpacity}/>
                    </ListItemText>
                </ListItem>
            )}
            <Divider light/>
            <ListItem >
                <ListItemText>{f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.logo'})}</ListItemText>
                
                <ListItemSecondaryAction>
                    {
                        !background_logo ? (
                            <Tooltip title={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.tooltip.add'})}>
                                <IconButton size="small" onClick={() => onOpenImage('background_logo')}><AddIcon/></IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.tooltip.remove'})}>
                                <IconButton size="small" onClick={() => on.changeImage({ type: 'background_logo', src: '' })} color="secondary" >
                                    <DeleteForeverIcon/>
                                </IconButton>
                            </Tooltip>
                        )               
                    }
                </ListItemSecondaryAction>
            </ListItem>
            <Divider/>
        </List>

        <PopupImage 
            title={f({id: 'component.Survey.Create.Analysis.Summary.EditDesign.listItem.dialogTitle'})}
            open={openImage.open}
            onSave={onSave}
            onClose={onCloseImage}
            onAppendUrl={onAppendUrl}
            onChangeImage={onChangeImage}
        />
        </WrapperStyled>
        <ColorPickerComponent 
            picker={picker}
            onChange={on.changeColorPicker}
            onClose={handleClosePicker}
        />
        </>
    )
}

export default Component