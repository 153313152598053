import { memo } from 'react'
import styled from 'styled-components'
import { isEqual } from 'lodash-es'

const WrapperStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding-top: 80px;

    @media only screen and (max-width: 960px) {
        padding-top: 0;
    }
    
    & > div {
        width: 100%;
        max-width: 720px;
    }
    
`

const Component = ({children}) => {
    return (
        <WrapperStyled>
            <div>
                {children}
            </div>
        </WrapperStyled>
    )
    
}

export default memo(Component, (prev, next) => {
    // 질문, 디자인, 답변값이 변할때만 다시 랜더링한다.. (다시 랜더링하면 contextAPI 다시 호출한다)
    // viewTYpe이 multi일때만 재랜더링하면된다

    return  (
        prev.pageIndex === next.pageIndex &&
        prev.editOpen === next.editOpen && 
        isEqual(prev.mbox, next.mbox) && 
        isEqual(prev.rowsJump, next.rowsJump) && 
        isEqual(prev.questions, next.questions) && 
        isEqual(prev.design, next.design) && 
        isEqual(prev.replys, next.replys) && 
         isEqual(prev.target, next.target)
    ) || next.viewType !== 'multi'
})
