import { Popover, List } from '@material-ui/core'
import { withSurveyModuleLists } from 'hoc'
import SurveyModuleLists from 'styled/Survey/Module/Lists'

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
}

const transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
}

const Append = (props) => {

    const { moduleLists, append, on } = props

    return (
        <Popover
            id='copy-popover'
            open={Boolean(append.anchorEl)}
            anchorEl={append.anchorEl}
            onClose={on.close}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <List style={{minWidth: 210}}>
                <SurveyModuleLists moduleLists={moduleLists} onChange={on.append}/>
            </List>
        </Popover>
    )
}

export default withSurveyModuleLists('body')(Append)