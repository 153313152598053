import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { handleDisabledRightClick, parseHtmlIntl } from 'ts-utils'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import { Props as ParentProps, OnOpenImage, OnDeleteImage, OnChangeQuestionTopUsed, OnChangeQuestionTopAlign } from '../Component'
import QuestionTopComponent from './QuestionTop'
import { Typography } from '@material-ui/core'

export interface Props {
    imageSrc: ParentProps['src'];
    questionTopUsed: ParentProps['question_top_used'];
    questionTopAlign: ParentProps['question_top_align'];
    onOpen: OnOpenImage;
    onDelete: OnDeleteImage;
    onChangeQuestionTopUsed: OnChangeQuestionTopUsed;
    onChangeQuestionTopAlign: OnChangeQuestionTopAlign;
}

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        gap: 5
    },
    align: {
        display: 'flex', 
        alignItems: 'center', 
        gap: 10
    },
    questionFixed: {
        border: `1px solid ${oc.gray[3]}`,
        borderRadius: 5,
        padding: 10
    },
    sizeTip: {
        color: theme.palette.action.active,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    icon: {
        display: 'block'
    },
    tip: {
        marginLeft: 12,
        marginTop: 10,
        color: theme.palette.action.active
    }
}))

function LogoComponent(props: Props) {
    const { imageSrc, questionTopUsed, questionTopAlign, onOpen, onDelete, onChangeQuestionTopUsed, onChangeQuestionTopAlign } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    return (
        <>
            {
                imageSrc ? (
                    <>
                    <Box className={classes.box}>
                    <Chip variant="default" size="medium" avatar={<Avatar src={imageSrc} onContextMenu={handleDisabledRightClick}/>} onDelete={onDelete}/>
                    </Box>
                    <ul className={classes.tip}>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.0' }))}</li>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.1' }))}</li>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.2' }))}</li>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.3' }))}</li>
                    </ul>
                    <Divider light style={{ marginTop: 10, marginBottom: 10}}/>
                    <QuestionTopComponent questionTopUsed={questionTopUsed} questionTopAlign={questionTopAlign} onChangeUsed={onChangeQuestionTopUsed} onChangeAlign={onChangeQuestionTopAlign}/>
                    </>
                ): (
                    <>
                    <Box className={classes.box}>
                        <Button color="primary" size="small" variant="contained" startIcon={<InsertPhotoIcon/>} onClick={onOpen}>{f({id: 'component.Survey.Create.Brand.Edit.Logo.name'})}</Button>
                    </Box>
                    <ul className={classes.tip}>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.0' }))}</li>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.1' }))}</li>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.2' }))}</li>
                        <li>{parseHtmlIntl(f({ id: 'component.Survey.Create.Brand.Edit.Logo.tip.3' }))}</li>
                    </ul>
                    </>
                )
            }
        </>
    )
}

export default memo(LogoComponent)

