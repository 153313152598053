import { memo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ColumnValueComponent from './ColumnValue'
import { Props as ParentProps } from './Body'

interface Props {
    survey_question_no: number;
    answer: ParentProps['answer'];
}

export const useStyles = makeStyles(theme => ({
    root: (props: { isRank?: boolean }) => {
        const { isRank } = props

        if (isRank) {
            return {
                display: 'table',
                width: '100%',
                paddingLeft: 50,
                paddingRight: 10,
                '& > * + *': {
                    marginTop: 5
                }
            }
        }

        return {
            display: 'flex',
            width: '100%',
            paddingLeft: 50,
            paddingRight: 10,
            gap: 5,
            flexWrap: 'wrap'
        }

    }
}))

function TextItemComponent(props: Props) {
    const { survey_question_no, answer } = props

    const classes = useStyles({})
    
    let comment = ''

    const value = answer[`Q${survey_question_no}`]
    if (value) {
        if (value.choice.length > 0) {
            if (value.choice[0][1]) comment = value.choice[0][1]
        }
    }

    if (comment === '' || comment === undefined || comment === null) return null

    return <div className={classes.root}><ColumnValueComponent text={comment} /></div>
}

export default memo(TextItemComponent)
