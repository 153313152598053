/**
 * 상단 버튼 모음
 */
import { memo } from 'react'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { Step } from 'gql/survey_analysis_summary'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import { withStyles } from '@material-ui/core/styles'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import DataDownloadsComponent from './DataDownloads'
import MobileTopComponent from './MobileTop'
import RefreshComponent from '../Refresh'
import { QuestionOption } from "component/Survey/Create/NewAnalysis/Filter/Component"

export interface Props {
    survey_no: number;
    questions: SurveyQuestionModuleProps[];
    conditionsPlay: QuestionOption | null;
    step: Step;
    date: string;
    refetchQuestions: () => void;
    refetchEndings: () => void;
    breakpoints: BreakPointsProps;
}

export const MoblieNavigation = withStyles( ({
    root: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        zIndex: 1    
    }
}))(props => <BottomNavigation {...props} value="" onChange={() => {}}/>)

function ButtonsComponent(props: Props) {
    const { survey_no, questions, step, date, conditionsPlay, refetchQuestions, refetchEndings, breakpoints } = props
    const { xs } = breakpoints
    
    return (
        <>
        {
            xs ? (
                <MoblieNavigation>
                    <DataDownloadsComponent 
                        survey_no={survey_no} 
                        questions={questions} 
                        conditionsPlay={conditionsPlay} 
                        step={step} 
                        date={date}
                        xs={true}
                    />
                    <MobileTopComponent/>
                </MoblieNavigation>
            ) : (
                <>
                <div>
                    <div>
                        <DataDownloadsComponent 
                            survey_no={survey_no} 
                            questions={questions} 
                            conditionsPlay={conditionsPlay} 
                            step={step} 
                            date={date}
                            xs={false}
                        />
                    </div>
                    <div>
                        <RefreshComponent refetchQuestions={refetchQuestions} refetchEndings={refetchEndings} />
                    </div>
                </div>
                </>
            )
        }
        </>
    )
}

export default memo(withBreakPoints(ButtonsComponent))