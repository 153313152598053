/*
** choices 셀렉트 박스
*/

import { memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl' 
import useOnClickOutside from 'hooks/useOnClickOutside'
import { Props as ParentProps } from './Component'
import { SelectBoxGrid, RemoveGrid } from '../SelectBox'
import SelectBoxOptionComponent from '../SelectBoxOption'
import ChoiceSelectedBoxOptionComponent from './ChoiceSelectedBoxOption'
import ChoiceSelectBoxOptionsComponent from './ChoiceSelectBoxOptions'

const isRank = [ '_11', '_17', '_18' ]
function ChoiceSelecBoxComponent(props: ParentProps) {
    const { keyValue, choices, value, onChange } = props

    const { formatMessage: f } = useIntl() 

    const [ open, setOpen ] = useState(false)

    const handleChange: ParentProps['onChange'] = useCallback((key, value) => {
        onChange(key, value)
    }, [onChange])

    const handleClick = useCallback(() => {
        setOpen(prevState => !prevState)
    }, [])

    const handleClose = () => {
        setOpen(false)
    }

    const [ typename ] = keyValue.split('-')

    const secondary = isRank.includes(typename) ? f({id: 'component.Survey.Create.Analysis.Filter.Item01.ChoiceSelectBox.placeholder.rank'}) : f({id: 'component.Survey.Create.Analysis.Filter.Item01.ChoiceSelectBox.placeholder.base'})

    const ref = useOnClickOutside<HTMLDivElement>(handleClose)

    const primarys = value.reduce((acc: ParentProps['choices'], c: number) => {
        const choice = choices.find(key => key.id === c)
        if (choice) acc.push(choice)

        return acc
    }, [])

    return (
        <div ref={ref}>
            <SelectBoxGrid>
            {
                primarys.length === 0 ? (
                    <>
                    <SelectBoxOptionComponent secondary={secondary} error={true} onChange={handleClick} indexs={0} selectBoxOpen={open} />
                    <ChoiceSelectBoxOptionsComponent keyValue={keyValue} choices={choices} value={value} open={open} onChange={handleChange} />
                    </>
                ) : (
                    <>
                    <ChoiceSelectedBoxOptionComponent keyValue={keyValue} primarys={primarys} selectBoxOpen={open} onClick={handleClick} onDelete={handleChange} />
                    <ChoiceSelectBoxOptionsComponent keyValue={keyValue} choices={choices} value={value} open={open} onChange={handleChange} />
                    </>
                )
            }
            </SelectBoxGrid>
            <RemoveGrid>
  
            </RemoveGrid>
        </div>
    )
}

export default memo(ChoiceSelecBoxComponent)
