import { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage.js'
import { GET_SURVEY_WORKSPACE_GROUP_NO_IN_SURVEY_NO } from 'gql/survey_workspace_group'
import CircularProgress from '@material-ui/core/CircularProgress'
import PopoverComponent from './PopoverCopy'
import OtherFuncCopyComponent from './OtherFuncCopy'

export interface Props {
	survey_no: number;
    anchorEl: Element | null;
    type: 'copy' | 'move';
	defaults: {
		survey_page_no: number | '';
		survey_question_no: number | '';
		position: 0 | 1;
	};
    onChangePage: (a: ChangeEvent<{ name?: string; value: unknown }>) => void;
    onChangeQuestion: (a: ChangeEvent<{ name?: string; value: unknown }>) => void;
    onChangePosition: (a: ChangeEvent<{ name?: string; value: unknown }>) => void;
    onClose: () => void;
    onSave: (other_survey_no: number | '') => void;
}

const OtherCopyComponent = (props: Props) => {
	const { 
		survey_no,
		anchorEl, 
		type, 
        defaults,
		onChangePage, 
		onChangeQuestion,
		onChangePosition,
		onClose,
		onSave
	} = props

	const dispatch = useDispatch()

	const [ getData, { data }] = useLazyQuery(GET_SURVEY_WORKSPACE_GROUP_NO_IN_SURVEY_NO, {
		onError: () => {
			dispatch({ type: ERRORORPAGESHOW })
		}
	})

	useEffect(() => {
		if (anchorEl) {
			getData({
				variables: { survey_no }
			})
		}
	}, [survey_no, anchorEl, getData])

	return (
		<>
	    <PopoverComponent anchorEl={anchorEl} type={type} onClose={onClose} onSave={onSave}>
			{
				!data ? <CircularProgress/> : (
					<OtherFuncCopyComponent
						survey_workspace_group_no={data.surveyWorkspaceGroupNoInSurveyNo}
						anchorEl={anchorEl}
						defaults={defaults}
						onChangePage={onChangePage}
						onChangeQuestion={onChangeQuestion}
						onChangePosition={onChangePosition}
					/>
				)
			}          
        </PopoverComponent>
		</>
	)
}

export default OtherCopyComponent

