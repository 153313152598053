import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import PageRandomExplainComponent from './PageRandom/Explain'
import QuestionRandomExplainComponent from './QuestionRandom/Explain'
import { useIntl } from 'react-intl'

function HelpComponent(props) {
    const { layout, open, onClose } = props
    
    const { formatMessage: f } = useIntl()

    return (
        <Dialog fullScreen open={open}>
            <DialogContent>
                {
                    layout === 'page_random' ? <PageRandomExplainComponent/> : 
                    layout === 'question_random' ? <QuestionRandomExplainComponent/> : ''
                }                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{f({id: 'component.Survey.Create.Logic.HelpPopup.close'})}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default HelpComponent

