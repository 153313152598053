import { useIntl } from 'react-intl'
import moment from 'moment'
import { fileSize } from 'ts-utils'
import { parseHtmlIntl } from 'ts-utils'
import { PermitList } from 'gql/user_permit'
import Grid, { GridProps } from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { Props as ParentProps  } from './DataItemFileUpload'
import withUserFetchData from 'hoc/User/FetchData'
import useChannelIO from 'hooks/useChannel'
import useTokenInfo from 'hooks/useTokenInfo'

interface Props {
    limit: number;
    used: number;
    onClose: ParentProps['onClose'];
    propsFetchData: {
        permitList: PermitList;
    }
}

const FileUploadGridItem = withStyles(theme => ({
    root: {
        color: theme.palette.common.black,
        fontSize: 15
    }
}))((props: GridProps) => <Grid item {...props}/>)

function FileUploadSizeOverComponent(props: Props) {
    const { limit, used, onClose, propsFetchData } = props
    const { permitList } = propsFetchData

    const { formatMessage: f } = useIntl()

    const token = useTokenInfo()

    const channelIO = useChannelIO({ user_no: token.user_no, email: token.email })

    async function handleChannel() {
        onClose()
        const ChannelIO = await channelIO()
        ChannelIO('showMessenger')
    }

    let limit_fileupload_size_index = 0

    if (token.year_date) {
        if (token.year_date >= moment().format('YYYY-MM-DD')) limit_fileupload_size_index = 1
    }

    return (
        <>
        <Grid container>
            <FileUploadGridItem xs={3} sm={3} md={3}>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.FileUploadSizeOver.limit.primary'}))}</FileUploadGridItem>
            <FileUploadGridItem xs={9} sm={9} md={9}>: {fileSize(limit)}</FileUploadGridItem>
        </Grid>
        <Grid container style={{marginTop: 5}}>
            <FileUploadGridItem xs={3} sm={3} md={3}>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.FileUploadSizeOver.used.primary'}))}</FileUploadGridItem>
            <FileUploadGridItem xs={9} sm={9} md={9}>: {fileSize(used, 2)}</FileUploadGridItem>
        </Grid>
        <Grid container style={{marginTop: 5, marginBottom: 10}}>
            <FileUploadGridItem xs={3} sm={3} md={3}>{parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.FileUploadSizeOver.over.primary'}))}</FileUploadGridItem>
            <FileUploadGridItem xs={9} sm={9} md={9}>: {fileSize((used - limit), 2)}</FileUploadGridItem>
        </Grid>
        <Typography variant="body2" style={{marginBottom: 10}}>
            {parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.FileUploadSizeOver.caution'}))}
        </Typography>
        {
            // 사용량이 프리미엄 용량도 넘는다면....
            used > Number(permitList.level100.limit_fileupload_size[limit_fileupload_size_index]) ? (
                <Button size="small" variant="contained" disableElevation style={{marginTop: 10}} onClick={handleChannel}>{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.FileUploadSizeOver.channel'})}</Button>
            ) : (
                <Link style={{marginTop: 10, cursor: 'pointer'}} href="/payment/choice">{f({id: 'component.Survey.Create.Analysis.Summary.FileUploadDialog.FileUploadSizeOver.upgrade'})}</Link>
            )
        }
     
        </>
    )
}

export default withUserFetchData({
    permitList: true
})(false)(FileUploadSizeOverComponent)