import { memo } from 'react'
import { useIntl } from 'react-intl'
import HtmlParser from 'react-html-parser'
import MenuItem from "@material-ui/core/MenuItem"
import { JumpNo } from 'gql/jump'
import { OnChangeChildsProps, MySelect } from './ItemRules'

interface Props extends OnChangeChildsProps {
    jump_rule_child_no: JumpNo;
    sign: number;
}

const menuItemArray = [
    { value: 1, text: '&nbsp;&nbsp;&nbsp;=' },
    { value: 2, text: '&nbsp;&nbsp;&nbsp;!=' },
    { value: 3, text: '&nbsp;&nbsp;&nbsp;>' },
    { value: 4, text: '&nbsp;&nbsp;&nbsp;>=' },
    { value: 5, text: '&nbsp;&nbsp;&nbsp;<' },
    { value: 6, text: '&nbsp;&nbsp;&nbsp;&lt;=' }
]

function getMenuItems(rows: {value: number, text: string}[]) {
    return rows.map(c => (
        <MenuItem key={c.value} value={c.value}>
            <span style={{fontFamily: 'yg-jalnan'}}>{HtmlParser(c.text)}</span>
        </MenuItem>
    ))
}

function SelectSignPointComponent(props: Props) {
    const { jump_rule_child_no, sign, onChange } = props

    const { formatMessage: f } = useIntl()

    return (
        <MySelect value={sign} style={{width: 80, marginBottom: 1}} label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectSignPoint.label'})} onChange={(e) => {
            onChange('sign', jump_rule_child_no, Number(e.target.value))
        }}>
            {
                getMenuItems(menuItemArray)
            }
        </MySelect>
    )
}

export default memo(SelectSignPointComponent)