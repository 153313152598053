import { memo, useContext } from 'react'
import { Method, JumpProps } from 'gql/jump'
import { Method as MboxMethod } from 'gql/survey_mbox'
import { Context } from 'container/Survey/Create/Logic/Jump/Context'
import SelectActionQuestionComponent, { Refference } from './SelectActionQuestion'
import SelectActionPageComponent from './SelectActionPage'
import SelectActionEndingComponent from './SelectActionEnding'

interface Props {
    refference: Refference;
    method: JumpProps['method'];
    id: JumpProps['id'];
    kind: JumpProps['kind'];
    survey_question_nos: JumpProps['survey_question_nos'];
    onChange: (new_survey_question_nos: number[]) => void;
}

function SelectSplitActionComponent(props: Props) {
    const { refference, method, id, kind, survey_question_nos, onChange } = props

    const { pages, mbox, questionsKeyQuestionNo, questionsKeyPageNo } = useContext(Context)

    return (
        <>
        {
            method === Method.End ? (
                <SelectActionEndingComponent
                    refference={refference}
                    surveyQuestionNos={survey_question_nos} 
                    onChange={onChange}
                />
            ) : mbox.method === MboxMethod.Multi && method === Method.Move ? (
                <SelectActionPageComponent 
                    refference={refference}
                    id={id}
                    pages={pages}
                    questionsKeyQuestionNo={questionsKeyQuestionNo}
                    questionsKeyPageNo={questionsKeyPageNo}
                    surveyQuestionNos={survey_question_nos} 
                    onChange={onChange}
                />
            ) : (
                <SelectActionQuestionComponent 
                    refference={refference}
                    id={id}
                    kind={kind} 
                    method={method}
                    surveyQuestionNos={survey_question_nos} 
                    onChange={onChange}
                />
            )
        }
        </>
    )
}

export default memo(SelectSplitActionComponent)