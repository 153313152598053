/**
 * 응답데이터 while
 */

import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { getKeyOfArray } from 'ts-utils'  
import { SurveyAnalysisRowsProps } from 'gql/survey_analysis_rows'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { Step } from 'gql/survey_analysis_summary'
import ItemComponent from './Item'

interface Props {
    questions: SurveyQuestionModuleProps[];
    answer: SurveyAnalysisRowsProps['answers'][0];
    files: SurveyAnalysisRowsProps['files'];
    checked: number[];
    standardColumnIndex: number;
    step: Step;
    onSelectClick: (a: number) => void;
}

export type OnChangeLaunchNo = (val: number) => void;

export type ImgRowsProps = {[a: string]: string};

// 이미지 문항
export const isImageQuestion = [ '_12', '_13', '_18']

// 보기별 코멘트가 있는 문항
export const isChoiceComment = [ '_01', '_02', '_12', '_13' ]

// 보기별 코멘트 있는 순위 문항
export const isRankComment = [ '_17', '_18' ]

/*
** type: header(테이블 타이틀 부분), answer(답변) 
** 이미지 값 구하기
*/
export const getImgRows = (type: string, questions: SurveyQuestionModuleProps[]) => {
    return questions.reduce((acc, c) => {
        const { _question, __typename } = c
        const { survey_question_no } = _question
    
        const typename = __typename.replace('Module', '')
        if (!isImageQuestion.includes(typename)) return acc
    
      
        if (typename === '_18') {
            const len = c._modules.length
            const childLen = c._answers.length
            for (let i=0; i<len; i++) {
                const { survey_module_no, indexs }: any = c._modules[i]
                const key = type === 'header' ? survey_module_no : indexs
                for (let j=0; j<childLen; j++) {
                    const { src, survey_module_answer_no, indexs }: any = c._answers[j]
                    const answerKey = type === 'header' ? survey_module_answer_no : indexs
                    acc[`${survey_question_no}-${key}-${answerKey}`] = src
                }
            }
            
            return acc
        }

        const len = c._modules.length
        for (let i=0; i<len; i++) {
            const { src, survey_module_no, indexs }: any = c._modules[i]
            const key = type === 'header' ? survey_module_no : indexs
            acc[`${survey_question_no}-${key}`] = src
        }
    
        return acc
    }, {})
}

// 파일 다운로드시 제목까지 첨부하기 위해 
export const getFileQuestionTitle = (questions: SurveyQuestionModuleProps[]) => {
    return questions.reduce((acc: { [key: number]: string }, c: SurveyQuestionModuleProps) => {
        const { __typename, _question } = c
        const { survey_question_no, pure_question } = _question
        if (__typename !== 'Module_19') return acc
        
        acc[survey_question_no] = pure_question
        return acc
    }, {})
} 


/**
 * 보기별 코멘트 indexs 구하기
 */
const getChoiceCommentIndex = (questions: SurveyQuestionModuleProps[]) => {

    return questions.reduce((acc, c)  => {
        const { _question } = c
        const { survey_question_no, module_type } = _question
    
    
        if (isChoiceComment.includes(module_type)) {
            const { _modules } = c
            _modules.map(d => {
                const { survey_module_no, indexs } = d
                acc[`${survey_question_no}-${survey_module_no}`] = indexs
            })

            return acc
        }

        if (isRankComment.includes(module_type)) {
            const { _modules, _answers } = c

            // 순위 index
            _modules.map(d => {
                const { survey_module_no, indexs } = d
                acc[`${survey_question_no}-M${survey_module_no}`] = indexs
            })

            // 보기 index
            _answers.map(d => {
                const { survey_module_answer_no, indexs } = d
                acc[`${survey_question_no}-A${survey_module_answer_no}`] = indexs
            })

            return acc
        }
        return acc
    }, {})
}

function ItemsComponent(props: Props) {
    const { questions, answer, files, checked, standardColumnIndex, step, onSelectClick } = props

    const state = useSelector((states: RootState) => states.analysisRowsExpandRow)
    const { expand } = state

    const personalState = useSelector((states: RootState) => states.analysisRowsPersonal)
    const personalId = personalState.answer ? personalState.answer.id.choice[0] : 0

    const userFiles = getKeyOfArray('survey_analysis_users_no', files)

    const imgRows = getImgRows('answer', questions)

    const choiceCommentIndex = getChoiceCommentIndex(questions)
    
    const file = userFiles[answer.id] || []

    const fileQuestionTitle = getFileQuestionTitle(questions)

    return (
        <ItemComponent 
            key={answer.id} 
            checked={checked.includes(answer.id)} 
            answer={answer} 
            files={file}
            standardColumnIndex={standardColumnIndex}
            imgRows={imgRows}
            expand={expand}
            choiceCommentIndex={choiceCommentIndex}
            step={step}
            fileQuestionTitle={fileQuestionTitle}
            selectedLaunch={personalId === Number(answer.id)}
            onSelectClick={onSelectClick}
        />
    )
}

export default memo(ItemsComponent)