/**
 * 검색어 입력
 */

import { memo, useState, useEffect, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import { Props as ParentProps } from './Component'
import { errorBorderStyle } from '../../SelectBoxOption'

interface Props {
    typename: string;
    keyValue: ParentProps['keyValue'];
    value: ParentProps['value'];
    onChange: ParentProps['onChangeText'];
}

let timer: ReturnType<typeof setTimeout>

function InputComponent(props: Props) {
    const { typename, keyValue, value, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ newValue, setNewValue ] = useState(value)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setNewValue(value)

        clearTimeout(timer)
        timer = setTimeout(() => {
            onChange(keyValue, value)            
        }, 300)
    }

    useEffect(() => {
        return () => clearTimeout(timer)
    }, [])

    let borderStyle: any = {}
    if (!newValue) borderStyle = errorBorderStyle

    return (
        <Paper variant="outlined" style={{ height: 'inherit', ...borderStyle }}>
            <InputBase 
                error={!newValue}
                value={newValue}
                fullWidth 
                placeholder={typename === '_20' ? f({id: 'component.Survey.Create.Analysis.Filter.Item01.Comment.Input.placeholder.address'}) : f({id: 'component.Survey.Create.Analysis.Filter.Item01.Comment.Input.placeholder.base'})}
                style={{ paddingLeft: 10, paddingRight: 10, height: 'inherit' }} 
                onChange={handleChange} 
            />
        </Paper>
    )
}

export default memo(InputComponent)