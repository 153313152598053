import { memo } from 'react'
import { useIntl } from 'react-intl'
import { validation } from 'utils'
import { getMaxlength } from 'policy'
import { SurveyEditCommentStyled } from 'styled/Input'
import useTextFiled, { Props as UseProps } from '../useChangeTextField'

interface Props {
    button_name: string;
    button_link: string;
    onChange: UseProps['onChange'];
}

function ButtonNameComponent(props: Props) {
    const { button_name, button_link, onChange } = props

    const [ value, setValue ] = useTextFiled({type: 'button_link', value: button_link, onChange})

    const { formatMessage: f } = useIntl()

    return (
        <SurveyEditCommentStyled
            disabled={!button_name}
            error={value ? !validation.url(value) : false}
            value={value}
            inputProps={{
                maxLength: getMaxlength('component.Survey.Create.Thanks.Edit.ButtonEnding.button_link'),
                placeholder: f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.button_link'})
            }}
            name="button_link"
            helper={f({id: 'component.Survey.Create.Thanks.Edit.ButtonEnding.button_link_helper'})}
            onChange={setValue}
        />
    )
}

export default memo(ButtonNameComponent)