import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CHANGE } from 'reducer/survey/create/module/copy'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'

interface Props {
    questions: SurveyQuestionModuleProps['_question'][];
    survey_page_no: number | '';
    survey_question_no: number | '';
    other_survey_no: number | '';
}

function useCopySave(props: Props) {
    const { questions, survey_page_no, survey_question_no, other_survey_no } = props
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: CHANGE,
            questions, 
            survey_page_no, 
            survey_question_no, 
            other_survey_no
        })
    }, [questions, survey_page_no, survey_question_no, other_survey_no, dispatch])

    return null
}

export default useCopySave