
/**
 * 내가 만든 기능들 집합소
 */
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import MySelectNodeComponent from "./MySelectNode"
import MySelectNodeExplainComponent from "./MySelectNodeExplain"

const MyBox = withStyles({
    root: {
        position: 'absolute',
        padding: 10,
        zIndex: 5
    }
})(Box)

function MyFunctionsComponent() {
    return (
        <MyBox>
            <MySelectNodeComponent/>
            <MySelectNodeExplainComponent/>
        </MyBox>        
    )
}

export default MyFunctionsComponent