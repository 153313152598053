import { memo } from 'react'
import { useIntl } from 'react-intl'
import { postMessage } from 'utils'
import { SHOW as IFRAMEDIALOGSHOW } from 'reducer/iframeDialog'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import SnsIcon from 'styled/SnsIcon/Component'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { Props as ParentProps } from './SnsChild'

interface Props {
    url: ParentProps['url'];
    title: ParentProps['title'];
    thumbnail: ParentProps['propsFetchData']['mboxThumbnailSrc'];
    breakpoints: BreakPointsProps;
}

const MyButton = withStyles({
    root: {
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
})((props: ButtonProps) => <Button {...props}/>)

function ThumbnailButtonsComponent(props: Props) {
    const { url, title, thumbnail, breakpoints } = props

    const { formatMessage: f } = useIntl()

    const { xs } = breakpoints

    const handleCachePopupOpen = () => {
        postMessage({
            dispatch: { type: IFRAMEDIALOGSHOW, name: 'SurveyCreateShareSnsCachePopup' } 
        })
    }

    const handleThumbnailPopupOpen = () => {
        postMessage({
            dispatch: { type: IFRAMEDIALOGSHOW, name: 'SurveyCreateShareThumbnailExplainPopup' } 
        })
    }

    let iconSize = 50
    let buttonSize: ButtonProps['size'] = 'large'
    let fullWidth = false

    if (xs) {
        iconSize = 33
        buttonSize = 'medium'
        fullWidth = true
    }

    return (
        <>
        <Box style={{display: 'flex', gap: 10, justifyContent: 'center'}}>
            <SnsIcon url={url} title={title} thumbnail={thumbnail} size={iconSize}/> 
        </Box>
        <div style={{marginTop: 15}}>
            <MyButton 
                onClick={handleThumbnailPopupOpen}
                size={buttonSize}
                fullWidth={fullWidth}
            >
                {f({id: 'component.Survey.Create.Share.Sns.thumbnailButton'})}
            </MyButton>
            <MyButton 
                onClick={handleCachePopupOpen}
                size={buttonSize}
                fullWidth={fullWidth}
            >
                {f({id: 'component.Survey.Create.Share.Sns.cacheButton'})}
            </MyButton>
        </div>
       
        </>
    )
}

export default withBreakPoints(memo(ThumbnailButtonsComponent))