import Wrapper from 'styled/Survey/Left'
import oc from 'open-color'

const DeskTop = (props) => {
    const { md, children } = props
    const height = md ? 'calc(100vh - 122px)' : undefined

    return (
        <Wrapper background={oc.gray[2]} height={height} paddingTop='1.36em' paddingBottom='3.36em'>
            {children}
            <div style={{ height: 80 }}></div>
        </Wrapper>
    )
}

export default DeskTop


