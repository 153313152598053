/**
 * 컬럼 변경
 */

import { memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import { getModuleIcon } from 'utils/survey'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Props as ParentProps } from './TableHeadIdStandard'


interface Props {
    typename: string;
    label: string;
    field: string;
    i: number;
    isComment?: boolean;
    selected: boolean;
    onChange: ParentProps['onChange'];
    onClose: () => void;
}

const useStyles = makeStyles({
    text: {
        display: 'block',
        overflow: 'hidden',   
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%'
    },
    itemIcon: {
        minWidth: 40
    }
})

const IconComponent = (props: { f: any, typename: string, isComment?: boolean }) => {
    const { f, typename, isComment } = props

    const icons = getModuleIcon(f)

    const icon = icons.find(c => c.type === typename)

    const Icon = icon ? icon.icon : null

    if (!Icon) return null

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Paper variant="outlined" style={{ display: 'table', padding: 5, position: 'relative' }}>
                <Icon color="action" style={{ width: 16, height: 16, display: 'block' }} />
                {
                    isComment &&  <Typography color="textSecondary" style={{ fontSize: 7, fontWeight: 900, position: 'absolute', bottom: -10, left: -4 }}>COMMENT</Typography>
                }
            </Paper>    
        </div>
    )
}

// COMMENT 문구 있는 문항
const isCommentStr = [ '_03', '_06', '_08', '_15' ]
function TableHeadIdSelectDialogItemComponent(props: Props) {
    const { typename, label, field, i, isComment, selected, onChange, onClose } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const handleChange = () => {
        if (selected) {
            onClose()
            return
        }

        onChange(field)
        onClose()
    }

    const labelArray = label.split('|*5%|')

    if (isCommentStr.includes(typename) && isComment) labelArray.pop()

    return (
        <ListItem autoFocus={selected} selected={selected} button style={{ border: `1px solid ${oc.gray[2]}`, borderRadius: 3, marginBottom: 5 }} onClick={handleChange}>
            <ListItemIcon className={classes.itemIcon}>
                <IconComponent typename={typename} f={f} isComment={isComment} />
            </ListItemIcon>
            <ListItemText>
                {
                    labelArray.map((c, i) => <Typography key={i} className={classes.text} variant="body2" color="textSecondary">{c}</Typography>)
                }
            </ListItemText>    
        </ListItem>
    )    
}

export default memo(TableHeadIdSelectDialogItemComponent)