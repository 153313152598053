import { memo } from 'react'
import { AnswerRankImg } from 'gql/survey_question_modules'
import { Props as BaseProps } from './QuestionItemsBase'
import useSelectRank from './useSelectRank'
import ItemComponent from "./Item"

type Props = Omit<BaseProps, 'componentType'>

function QuestionItemsMatrix(props: Props) {
    const { question, rows, onChange } = props
    const { _modules, _answers } = question

    const [ target_survey_module_no, SelectComponent ] = useSelectRank({ _modules })

    return (
        <>
        <SelectComponent/>
        {
            _answers.map((_answer: AnswerRankImg) => {
                const { survey_module_answer_no, answer, indexs, src } = _answer
                const title = `${indexs}. ${answer}`

                const row = rows.find(row => row.survey_module_no === target_survey_module_no && row.survey_module_answer_no === survey_module_answer_no)

                if (!row) return null

                return <ItemComponent key={`${target_survey_module_no}-${survey_module_answer_no}`} answer={title} src={src} row={row} onChange={onChange}/>
            })
        }
        </>
    )
}

export default memo(QuestionItemsMatrix)