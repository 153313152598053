/*
** 커스텀 링크 삭제
*/

import { memo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { CDButton, OnDelete } from './CustomArgs'

interface Props {
    disabled: boolean;
    onDelete: OnDelete;
}

function CustomArgsRemoveComponent(props: Props) {
    const { disabled, onDelete } = props
    const [ action, setAction ] = useState(false)

    const { formatMessage: f } = useIntl()

    const handleDelete = () => {
        onDelete()
        setAction(false)
    }

    const handleOpen = () => {
        setAction(true)
    }

    const handleClose = () => {
        setAction(false)
    }

    useEffect(() => {
        return () => {
            setAction(false)
        }
    }, [])

    return (
        <>
        {
            action ? (
                <ButtonGroup size='small' variant='contained' disableElevation>
                    <Button color='secondary' onClick={handleDelete}>{f({id: 'component.Survey.Create.Share.CustomArgsRemove.button.0'})}</Button> 
                    <Button onClick={handleClose}>{f({id: 'component.Survey.Create.Share.CustomArgsRemove.button.1'})}</Button>
                </ButtonGroup>
            ) : (
                <CDButton color="secondary"  variant='outlined' size="small"  disabled={disabled} onClick={handleOpen}>{f({id: 'component.Survey.Create.Share.CustomArgsRemove.button.0'})}</CDButton>
            )
        }
   
        </>
    )
}

export default memo(CustomArgsRemoveComponent)