import { useContext } from 'react'
import { useIntl } from 'react-intl'
import propsContext from 'context/props'
import { Typography, Paper, Tabs, Tab, Container } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AppButtonComponent from './AppButton'

const MyContainer1 = withStyles({
    root: {
        background: 'white',
        maxWidth: '100%'
    }
})(props => <Container {...props}/>)

const MyContainer2 = withStyles({
    root: {
        paddingTop: '.3em'
    }
})(props => <Container {...props}/>)

const MyPaper = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'transparent'
    }
})(props => <Paper {...props} elevation={0}/>)

const TabTypography = withStyles({
    root: {
        fontWeight: 'bold'
    }
})(props => <Typography variant="subtitle2" {...props}/>)

export default () => {
    const { tab, onChange } = useContext(propsContext)
    const { formatMessage: f } = useIntl()

    return (
        <MyContainer1>
            <MyContainer2>
                <MyPaper elevation={0}>
                    <Tabs value={tab === 'integration' ? false : tab} indicatorColor="primary" textColor="primary" onChange={(e, tab) => onChange(tab)}>
                        <Tab value="insight" label={<TabTypography>{f({id: 'component.Survey.Create.Analysis.Tab.lists.0'})}</TabTypography>} />
                        <Tab value="summary" label={<TabTypography>{f({id: 'component.Survey.Create.Analysis.Tab.lists.1'})}</TabTypography>} />
                        <Tab value="rows" label={<TabTypography>{f({id: 'component.Survey.Create.Analysis.Tab.lists.2'})}</TabTypography>} />
                    </Tabs>
                    <AppButtonComponent tab={tab} onChange={onChange}/>
                </MyPaper>
            </MyContainer2>
        </MyContainer1>
    ) 
}