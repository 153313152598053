/**
 * 주관식 문항 다운로드
 */
import { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import moment from 'moment'
import { _05, GET_SURVEY_ANALYSIS_SUMMARY_COMMENT_QUESTION } from 'gql/survey_analysis_summary'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppIcon from '@material-ui/icons/GetApp'
import { getKey } from 'hoc/Survey/Summary'
import { useStyles } from './QuestionButtons'

interface Props {
    survey_no: number; 
    survey_question_no: number; 
    filename: string;
    commentLen: number;
}

// 0으로 시작하고 숫자이면서 2글자 이상이면
export const getIsZeroStartValue = (value: string) => {

    if (!Number.isNaN(Number(value))) {
        return String(value).indexOf('0') === 0 ? `="${value}"` : value
    }

    return value
}

export const parseText = (props: { idd: number, wdate: string, comment: string }) => {
    const { idd, wdate, comment } = props

    const newComment = getIsZeroStartValue(comment)

    return `${idd},${moment(wdate).format('YYYY.MM.DD HH:mm:ss')},${newComment.replace(/,/g, '').replace(/\r\n/g, '').replace(/\n/g, '')}`
}

const makeFile = (props: { comments: _05['comments']; filename: string; f: any }) => {
    const { comments, filename, f } = props

    return new Promise((resolve) => {
        const zip = new JSZip()

        if (comments.length === 0) {
            resolve(1)
            return
        }

        const label = f({id: 'utils.analysis.ppts.writeBarComments.label'})

        const _comments = comments.map(c => parseText({idd: c.idd, wdate: c.wdate, comment: c.comment}))
        _comments.unshift(label)

        zip.file(`${filename}.csv`, _comments.join("\n"))

        zip.file(`${filename}.csv`).async('blob').then(function (data) {

            FileSaver.saveAs(data, `${filename}.csv`)
        })

        resolve(1)
    })
}

function QuestionButtonTextComponent(props: Props) {
    const { survey_no, survey_question_no, filename, commentLen } = props
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const [ loading, setLoading ] = useState(false)

    const [ getData ] = useLazyQuery(GET_SURVEY_ANALYSIS_SUMMARY_COMMENT_QUESTION, {
        onCompleted: async(data) => {

            await makeFile({ comments: data.surveyAnalysisSummaryCommentQuestion, filename, f })

            setLoading(false)

        },
        fetchPolicy: 'network-only'
    })

    const handleSave = async() => {
        if (loading) return

        setLoading(true)
        getData({
            variables: {
                survey_no,
                survey_question_no,
                key: getKey(survey_no)
            }
        })
    }

    return (
        <Button 
            className={classes.button} 
            startIcon={
                loading ? <CircularProgress size={15} /> : <GetAppIcon fontSize="small" className={classes.icon} /> 
            } 
            disabled={loading || commentLen === 0}
            size="small" 
            variant="contained" 
            disableElevation 
            style={{ marginRight: 3 }}
            onClick={handleSave}
        >
            CSV
        </Button>
    )
}

export default memo(QuestionButtonTextComponent)