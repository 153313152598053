import { memo, useState, createContext, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { TableVirtuoso } from "react-virtuoso"
import oc from 'open-color'
import { CHANGE as CHANGEORDER } from 'reducer/analysis/rows/order'
import { CHANGE as CHANGEPERSONALIDS } from 'reducer/analysis/rows/personalIds'
import { CHANGE as CHANGESEARCH, INIT as INITSEARCH } from 'reducer/analysis/rows/search'
import { CHANGE as CHANGECHOICE, INIT as INITCHOICE } from 'reducer/analysis/rows/choice'
import { CHANGE as CHANGEDATA, INIT as INITDATA } from 'reducer/analysis/rows/data'
import { TablePagination, Paper, Toolbar, Button, IconButton } from '@material-ui/core'
import { makeStyles, withStyles, createStyles, useTheme } from '@material-ui/core/styles'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import useFileUploadSize from 'hooks/useFileUploadSize'
import useTokenInfo from 'hooks/useTokenInfo'
import withBreakPoints from 'hoc/BreakPoints'
import RemoveFileButtonComponent from './RemoveFileButton'
import RemoveUserButtonComponent from './RemoveUserButton'
import ItemsComponent from './Items'
import TableHeadComponent, { getColumns } from './TableHead'
import ExpandRowComponent from './ExpandRow'
import SearchComponent from './Search'
import MobileMessageComponent from './MobileMessage'
import RemoveButtonBoxComponent from './RemoveButtonBox'

export const FileUploadContext = createContext({
    limit: 0,
    used: 0,
    isOver: true,
    refetch: () => {}
})

export const TokenContext = createContext({
    token: {}
})

const useStylesPage = makeStyles((theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        }
    })
)

const useStyles = makeStyles(theme => ({
    tablePagination: {
        display: 'flex',
        justifyContent: 'flex-start'
    }
}))

export const RemoveButton = withStyles({
    root: {
        whiteSpace: 'nowrap',
        color: oc.gray[7],
        fontSize: 12
    }
})(props => <Button size="small" {...props} />)


// 키 인덱스
const getKeyIndex = (rows: any, key: string, step) => {
    // 진행 상태가 완료가 아닌데 완료시각 기준일 경우 0으로 리턴
    if (Number(step) !== 1 && key === 'einterval') return 0

    const len = rows.length 
    for(let i=0; i<len; i++) {
        if (rows[i].field === key) {
            return i
        }
    }

    return 0
}

function EnhancedToolbar(props) {
    const { survey_no, userCount, fileCount, where, checked, files, filterLen, search, startpoint, isRemoveLastPageAllData, onChangeSearch, onRefetch, onChangeStartpoint } = props

    return (
        <Toolbar style={{ paddingLeft: 13, paddingRight: 13 }}>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 15 }}>
                    <SearchComponent value={search} onChange={onChangeSearch}/>   
                    <ExpandRowComponent survey_no={survey_no} />                 
                </div>
                <div style={{ display: 'flex' }}>
                    {
                        filterLen === 0 && (
                            <RemoveButtonBoxComponent>
                                <RemoveFileButtonComponent 
                                    allCount={fileCount}
                                    where={where}
                                    checked={checked} 
                                    files={files} 
                                    search={search}
                                    onRefetch={onRefetch}
                                />
                                <RemoveUserButtonComponent
                                    allCount={userCount}
                                    where={where}
                                    checked={checked} 
                                    search={search}
                                    startpoint={startpoint}
                                    isRemoveLastPageAllData={isRemoveLastPageAllData}
                                    onRefetch={onRefetch}
                                    onChangeStartpoint={onChangeStartpoint}
                                />
                            </RemoveButtonBoxComponent>
                        )
                    }
                   
                </div>
            </div>
           
        </Toolbar>
    )
}


// 시작시각, 완료시각만 사용한다 (사용자번호, 이메일 정렬 뺌)
export const getOrderKey = (key: string, step: number) => {
    if (Number(step) === 1) {
        if (!(key === 'accesstime' || key === 'einterval')) return 'einterval'

        return key
    }

    // 진행중은 무조건 시작시각
    if (Number(step) === 2) return 'accesstime'
    

    return 'einterval'
}

// 검색 값이 있을경우 필터링 한다 
const getSearchAnswers = (search: String, answers) => {
    if (search === '') return answers

    return answers.filter(answer => {
        const keys = Object.keys(answer)
        const len = keys.length
        let flag = false 

        for (let i=0; i<len; i++) {
            const key = keys[i]
            const value = answer[key]
            const realValue = typeof value !== 'object' ? value : value[1]

            if (String(realValue).toLowerCase().indexOf(String(search).toLowerCase()) !== -1) {
                flag = true
                break
            }
        }

        return flag
    })
}

const initialState = {
    checked: [],
    allChecked: false
}


function TablePaginationActions(props) {
    const classes = useStylesPage();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    }
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    }
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    }
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }
  
    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}
  
function Component(props) {
    const { survey_no, questions, data, userCount, fileCount, order, startpoint, offset, where, loading, conditionsPlay, step, onChangeStartpoint, onChangeOffset, refetch, breakpoints } = props
    const { columns, answers, files } = data

    const { date } = where

    const newOrder = { key: getOrderKey(order.key, step), value: order.value  }
    
    const dispatch = useDispatch()  

    const { xs } = breakpoints

    // 필터 값이 있으면  체크박스 및 삭제 버튼 가린다.
    const filterLen  = conditionsPlay ? Object.keys(conditionsPlay).length : 0

    const [{ limit: limitFileUploadSize, used: usedFileUploadSize, isOver: isOverFileUploadSize, refetch: refetchFileUploadSize }] = useFileUploadSize()

    const token = useTokenInfo()

    const standardColumnCookieName = `rows-standardColumn-${survey_no}`
    // const searchCookieName = `rows-search-${survey_no}`

    const [ checked, setChecked ] = useState(initialState.checked) //체크박스 선택값 (survey_analysis_users_no)
    const [ allChecked, setAllChecked ] = useState(initialState.allChecked)
   
    // 첫번째 컬럼
    const [ standardColumnKey, setStandardColumnKey ] = useState(localStorage.getItem(standardColumnCookieName) || 'id')

    // 검색
    // const [ search, setSearch ] = useState(sessionStorage.getItem(searchCookieName) || '')
    const [ search, setSearch ] = useState('')

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const baseColumns = getColumns(columns, step)

    const standardColumnIndex = getKeyIndex(getColumns(columns, step), standardColumnKey, step)

    const checkedLen = checked.length 
    const answerLen = answers.length 

    // 마지막 페이지에서 전체 삭제하고 리패치하면 데이터가 없어서 화면단에서 페이징 오류가 발생한다. 임의로 전페이지로 옮긴다.
    let isRemoveLastPageAllData = false
    if (startpoint > 0 && checkedLen > 0 && checkedLen === answerLen) {
        if (startpoint * offset + offset >= userCount) {     
            isRemoveLastPageAllData = true
        }
    }

    // 검색어에 맞는필터
    const newAnswers = getSearchAnswers(search, answers)
    const ids = newAnswers.map(c => c.id)

    // 페이징 변경시 체크박스 초기화
    function handlePageChange(e, newPage) {
        onChangeStartpoint(newPage)
        setChecked(initialState.checked)
        setAllChecked(initialState.allChecked)
    }
    
    function handleRowsPerPageChange(e) {
        onChangeStartpoint(0)
        onChangeOffset(parseInt(e.target.value, 10))
    }

    // 정렬값 변경시 체크값 초기회
    function handleRequestSort(field) {
        const value = newOrder.key === field ? !newOrder.value : false
        
        onChangeStartpoint(0)

        dispatch({ type: CHANGEORDER, survey_no, key: field, value })

        setChecked(initialState.checked)
        setAllChecked(initialState.allChecked)
    }

    // 체크박스 체크
    const handleSelectClick = useCallback((value) => {
        const id = Number(value)

        // true 값이 오면 넣어주고 false 이면 빼준다
        setChecked(prevState => {
            if (prevState.includes(id)) {
                // 값이 있다면 빼준다
                return [...prevState.filter(_id => _id !== id)]
            }

            // 없다면 넣어준다
            return [...prevState, id]
        })
    }, [])

    // 체크박스 전체 체크
    const handleSelectAllClick =  useCallback((checked) => {
        if (checked) {
            setChecked([...newAnswers.map(answer => answer.id)])
            setAllChecked(true)
        } else {
            setChecked([])
            setAllChecked(false)
        }
    }, [newAnswers])

    const handleRefetch = useCallback((args) => {
        setChecked([])
        setAllChecked(false)

        // 해당페이지에서 모든 값 삭제시 1페이지로 넘긴다.
        //if (startpoint > 0 && checkedLen > 0 && checkedLen === answerLen) onChangeStartpoint(0)

        if (args) {
            refetch(args)
            return
        }

        refetch()

    }, [refetch])

    // 기준이 되는 컬럼 변경
    const handleChangeStandardColumnKey = useCallback((id) => {
        setStandardColumnKey(id)
        localStorage.setItem(standardColumnCookieName, id)
    }, [standardColumnCookieName])

    // 검색값 변경
    const handleChangeSearch = (value) => {
        //sessionStorage.setItem(searchCookieName, value)
        dispatch({ type: CHANGESEARCH, search: value })
        setSearch(value)
    }

    // 컬럼값 테이블에 맞게 커스텀
    useEffect(() => {
        setStandardColumnKey(localStorage.getItem(standardColumnCookieName) || 'id')
    }, [standardColumnCookieName])
    
    // 현재 데이터 
    useEffect(() => {
        dispatch({ type: CHANGEDATA, data })
    }, [data, dispatch])

    // 현재 체크된 id 
    useEffect(() => {
        dispatch({ type: CHANGECHOICE, ids: checked })
    }, [checked, dispatch])

    // 사용자 번호 개인별 응답 페이지로 보낸다
    useEffect(() => {
        if (!loading) {
            dispatch({ type: CHANGEPERSONALIDS, ids })
        }
    }, [ids, loading, dispatch])

    // 언마운트 시, 체크박스 데이터 초기화, 검색어 초기화
    useEffect(() => {
        return () => {
            dispatch({ type: INITCHOICE })
            dispatch({ type: INITDATA })
            dispatch({ type: INITSEARCH })
        }
    }, [dispatch])


    if (xs) {
        return <MobileMessageComponent />
    }

    if (loading) return null
    
    return (
        <TokenContext.Provider value={{token}}>
            <FileUploadContext.Provider value={{
                limit: limitFileUploadSize, 
                used: usedFileUploadSize, 
                isOver: isOverFileUploadSize, 
                refetch: refetchFileUploadSize
            }}>            
                <Paper variant="outlined">
                    <EnhancedToolbar 
                        survey_no={survey_no}
                        userCount={userCount}
                        fileCount={fileCount}
                        checked={checked} 
                        startpoint={startpoint}
                        isRemoveLastPageAllData={isRemoveLastPageAllData}
                        where={where} 
                        files={files} 
                        filterLen={filterLen}
                        search={search}
                        onRefetch={handleRefetch} 
                        onChangeStartpoint={onChangeStartpoint}
                        onChangeSearch={handleChangeSearch}
                    />
                    <TableVirtuoso
                        style={{ width: '100%', height: `calc(100vh - 24.4em)` }}
                        data={newAnswers}
                        components={{ Table: ({ style, ...props }) => (
                            <table {...props} style={{ ...style, width: '100%' }} /> 
                        )}}
                        fixedHeaderContent={() => (
                            <TableHeadComponent
                                survey_no={survey_no}
                                questions={questions}
                                allChecked={allChecked}
                                checked={checked} 
                                rowsLen={answers.length}
                                columns={baseColumns}
                                orderKey={newOrder.key} 
                                orderValue={newOrder.value} 
                                filterLen={filterLen}
                                date={date}
                                step={step}
                                refetch={refetch}
                                standardColumnIndex={standardColumnIndex}
                                onSelectAllClick={handleSelectAllClick} 
                                onRequestSort={handleRequestSort}
                                onChangeStandardColumnKey={handleChangeStandardColumnKey}
                            />
                        )}
                        itemContent={(index, answer) => (
                            <ItemsComponent 
                                index={index}
                                questions={questions}
                                answer={answer}
                                files={files}
                                checked={checked}
                                standardColumnIndex={standardColumnIndex}
                                step={step}
                                onSelectClick={handleSelectClick}
                            />
                        )}
                    />
                    <TablePagination
                        className={classes.tablePagination}
                        rowsPerPageOptions={[50, 100, 200, 300, 400]}
                        count={userCount}
                        component="div"
                        page={answers.length === 0 ? 0 : startpoint}
                        rowsPerPage={offset}
                        labelRowsPerPage={f({id: 'component.Survey.Create.Analysis.Rows.Component.labelRowsPerPage'})}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
            </FileUploadContext.Provider>
        </TokenContext.Provider>
    )
}

export default withBreakPoints(memo(Component))