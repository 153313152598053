import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/react-hooks'
import { downloadFile } from 'utils'
import { secondsToTime } from 'utils/survey'
import { parseGraphqlDate, parseGraphqlDeviceType } from 'ts-utils/summary'
import moment from 'moment'
import { GET_SURVEY_ANALYSIS_COMPLETE_INTERVAL_LISTS } from 'gql/survey_analysis_views'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { GetAppTwoTone as GetAppTwoToneIcon } from '@material-ui/icons'
import Tooltip from 'styled/Custom/Tooltip'
import { getDate } from 'styled/Survey/Analysis/Calendar'

export const MyIconButton = withStyles({
    root: {
        position: 'absolute',
        top: 5,
        right: 5
    }
})(props => <IconButton size="small" {...props}/>)

const filename = 'CompleteTime.csv'

export default function CompleteIntervalLists(props) {
    const { survey_no, date, deviceType, completeCount } = props
    
    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ getLists, { loading } ] = useLazyQuery(GET_SURVEY_ANALYSIS_COMPLETE_INTERVAL_LISTS, {
        onCompleted: res => {

            const label = f({id: 'component.Survey.Create.Analysis.Insight.CompleteIntervalLists.label'})
            const rows = res.surveyAnalysisCompleteIntervalLists.map(c => {
                const{ ip, email, device, einterval, wdate } = c
                const time = secondsToTime(einterval)
                const str = `${email || ip},${f({id: `component.Survey.Create.Analysis.Insight.CompleteIntervalLists.device.${device}`})},${moment(Number(wdate)).format('YYYY.MM.DD HH:mm:ss')},${time}`
                return str 
            })

            const data = [label,...rows].join(`\n`)
            downloadFile({ filename, data })   
        },
        onError: e => {
            dispatch({ type: ERRORPAGESHOW })
        },
        fetchPolicy: 'network-only'
    })

    const handleExport = useCallback(async() => {

        const { sdate, edate } = getDate(date)
        const parseDate = parseGraphqlDate(sdate, edate)

        getLists({
            variables: {
                survey_no,
                sdate: parseDate.sdate,
                edate: parseDate.edate,
                deviceType: parseGraphqlDeviceType(deviceType)
            }
        })        
    }, [getLists, survey_no, date, deviceType])

    useEffect(() => {
        if (loading) dispatch({ type: BACKDROPSHOW })
        else dispatch({ type: BACKDROPHIDE })
    }, [dispatch, loading])

    return null

    return (
        <>
        <MyIconButton disabled={completeCount === 0} color="primary" onClick={handleExport}>
            <Tooltip 
                title={f({id: 'component.Survey.Create.Analysis.Insight.CompleteIntervalLists.tooltip'})} 
                placement="top-end" 
                isMobileClick={true}
                delayCloseTime={1500}
            >
                <GetAppTwoToneIcon/>
            </Tooltip>
        </MyIconButton>
        </>
    )
}