import { memo } from 'react'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles as useMoveStyles } from './Move'
import { Props as ParentProps } from './Component'

interface Props {
    onClose: ParentProps['onClose'];
}

function CloseComponent(props: Props) {
    const { onClose } = props

    const classesMove = useMoveStyles()

    return (
        <Box className={classesMove.button} onClick={onClose}>
            <CloseIcon color="action" />
        </Box>
    )    
}

export default memo(CloseComponent)