import { useCallback, useEffect, useState } from 'react'
import { List, ListItem, FormControlLabel, Divider, Radio, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'
import { useIntl } from 'react-intl'
import { Method } from 'gql/survey_mbox'
import { withBreakPoints } from 'hoc'
import CreateBox from 'styled/Survey/CreateBox'
import PreviewPopupComponent from './PreviewPopup'

const Component = (props) => {
    const { breakpoints, method, onChange } = props

    const { xs } = breakpoints

    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false) // one, multi, false

    const handleOpen = useCallback((_open) => {
        setOpen(_open)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])


    useEffect(() => {
        if (!xs) handleClose()
    }, [xs, handleClose])

    return (
        <>
        <CreateBox className="dense">
            <List dense>
             <ListItem>
                <ListItemText>
                    <FormControlLabel 
                        label={f({id: 'component.Survey.Create.Method.Component.lists.one'})} 
                        labelPlacement="end" control={<Radio color="primary" checked={method === Method.One} onChange={() => onChange(Method.One)}/>}
                    />
                </ListItemText>
                {
                    xs && (
                        <ListItemSecondaryAction>
                            <IconButton size="small" onClick={() => handleOpen(Method.One)}>
                                <HelpTwoToneIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    )
                }
                
            </ListItem>
            <Divider/>
            <ListItem>
                <ListItemText>
                    <FormControlLabel 
                        label={f({id: 'component.Survey.Create.Method.Component.lists.multi'})} 
                        labelPlacement="end" control={<Radio color="primary" checked={method === Method.Multi} onChange={() => onChange(Method.Multi)}/>}
                    />
                </ListItemText>
                {
                    xs && (
                        <ListItemSecondaryAction>
                            <IconButton size="small" onClick={() => handleOpen(Method.Multi)}>
                                <HelpTwoToneIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    )
                }
            </ListItem>
            <Divider/>
            </List>
        </CreateBox>
        <PreviewPopupComponent method={open} open={Boolean(open)} onClose={handleClose}/>
        </>
    )
}

export default withBreakPoints(Component)