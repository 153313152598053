import { memo, useCallback, useState, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import SummaryContext from 'context/summary'
import { UPDATE_SURVEY_MBOX_SUMMARY_ENDING_OPEN } from 'gql/survey_mbox'
import { _Ending } from 'gql/survey_analysis_summary'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { ppts } from 'utils/analysis'
import SummaryEnding from 'styled/Survey/Analysis/SummaryEnding'
import QuestionPaper from 'styled/Survey/Analysis/SummaryQuestionPaper'
import { QuestionNumberStyled, QuestionBoxStyled, QuestionBoxForMakeImageStyled } from 'styled/Survey/Analysis/Summary'
import { Bar01Component, saveGraphImage } from './DataItem'
import QuestionButtonsComponent from './QuestionButtons'
import NumberIconComponent from './NumberIcon'
import VisibleLangComponent from './VisibleLang'

interface Props extends _Ending {
    survey_no: number;
    viewMode: string;
    onShowBackdrop: () => void;
    onHideBackdrop: () => void;
}

function DataEndingItemComponent(props: Props) {
    const { survey_no, viewMode, common, modules, onShowBackdrop, onHideBackdrop } = props
    const { question_type, module_type: __typename } = common

    const name = `ending-open-${survey_no}`

    const divRef = useRef<null | HTMLDivElement>(null)

    const { mbox } = useContext<any>(SummaryContext)
    const { summary_ending_open } = mbox

    const { formatMessage: f } = useIntl()

    const dispatch = useDispatch()

    const [ order, setOrder ] = useState('count')

    const [ summaryEndingOpen, setSummaryEndingOpen ] = useState<boolean>(sessionStorage.getItem(name) === null ? summary_ending_open : sessionStorage.getItem(name) === '1')

    const [ updateSummaryOpen ] = useMutation(UPDATE_SURVEY_MBOX_SUMMARY_ENDING_OPEN, {
        onError: () => {
            dispatch({ type: ERRORPAGESHOW })
        }
    })
    
    const _modules = modules.map((c, i) => {
        const { survey_ending_no, pure_message, img_src, count } = c
        return { 
            survey_module_no: survey_ending_no,
            answer: pure_message || `${f({id: 'component.Survey.Create.Analysis.Summary.DataEnding.title'})}-${(i+1)}`,
            img_src,
            count
        }
    })

    const _ppts = new ppts({ f })
    
    _ppts.init({ question: f({id: 'component.Survey.Create.Analysis.Summary.DataEnding.title'}), modules, questionType: question_type, __typename, order, section: 11 })

    const handleChange = useCallback((val) => {
        setOrder(val)
    }, [])

    const handleDownload = () => {
        _ppts.doOne()
    }

    const handleSaveImage = async() => {
        if (!divRef) return
        if (!divRef.current) return

        onShowBackdrop()

        await saveGraphImage({ ref: divRef, name: `Ending`})

        onHideBackdrop()
    }

    const handleChangeSummaryEndingOpen = () => {
        //모든 아이템의 랜더링 방지를 위해 해당 아이템만 업데이트 하는 방법으로 한다 (db refetc를 해버리면 모든 아이템을 업데이트 하기때문에...)
        setSummaryEndingOpen(prevState => {
            const summary_ending_open = !prevState
            // db  업데이트
            updateSummaryOpen({
                variables: {
                    survey_no,
                    summary_ending_open
                }
            })

            sessionStorage.setItem(name, summary_ending_open ? '1' : '0')

            return summary_ending_open
        })
    }

    // 완료글이 두개이상일때만 통계를 보여준다
    if (modules.length < 2) return null

    return (
        <QuestionPaper>
            {
                viewMode === 'edit' && (
                    <VisibleLangComponent 
                        __typename={__typename} 
                        summary_question_open={summaryEndingOpen} 
                        onChangeQuestion={handleChangeSummaryEndingOpen} 
                    />
                )
            }
            {
                viewMode === 'view' && (
                    <QuestionNumberStyled>
                        <div>
                            <NumberIconComponent __typename="_ending"/>
                        </div>
                        <div>
                        <QuestionButtonsComponent 
                                __typename="_ending" 
                                order={order} 
                                on={{
                                    changeOrder: handleChange,
                                    download: handleDownload,
                                    image: handleSaveImage
                                }}
                            />
                        </div>
                    </QuestionNumberStyled>
                )
            }

            <QuestionBoxStyled>
                <QuestionBoxForMakeImageStyled ref={divRef}>
                    { viewMode !== 'view' && <SummaryEnding/> }
                    <Bar01Component modules={_modules} order={order} animation={true}/>
                </QuestionBoxForMakeImageStyled>
            </QuestionBoxStyled>
        </QuestionPaper>
    )
}

export default memo(DataEndingItemComponent)