import { memo, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { GET_SURVEY_PAGE_CLASSIC_BUTTON, CREATE_SURVEY_PAGE_CLASSIC_BUTTON, SurveyPageClassicButtonProps } from 'gql/survey_page'
import { getMaxlength } from 'policy'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'

interface Props {
    survey_page_no: SurveyPageClassicButtonProps['survey_page_no'];
}

let timer: ReturnType<typeof setTimeout>
let loadingTimer: ReturnType<typeof setTimeout>

function ClassicNextButtonComponent(props: Props) {
    const { survey_page_no } = props

    const { formatMessage: f } = useIntl()
    
    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const { data, refetch } = useQuery(GET_SURVEY_PAGE_CLASSIC_BUTTON, {
        variables: {
            survey_no,
            survey_page_no
        }
    })

    const [ create, { loading } ] = useMutation(CREATE_SURVEY_PAGE_CLASSIC_BUTTON, {
        onCompleted: () => {
            refetch()
        }
    })

    const [ newValue, setNewValue ] = useState('')

    const [ newLoading, setNewLoading ] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const classic_button_name = String(event.target.value)

        setNewValue(classic_button_name)

        clearTimeout(timer)

        timer = setTimeout(() => {
            create({
                variables: {
                    survey_no,
                    survey_page_no,
                    classic_button_name
                }
            })
        }, 700)
    };
    
    const InputProps = newLoading ? {
        startAdornment: (
            <InputAdornment position="start">
                <CircularProgress size={20} />
            </InputAdornment>
        )
    } : {}

    useEffect(() => {
        // 값이 있을경우 state 동기화
        if (data) {
            if (data.surveyPageClassicButton) setNewValue(data.surveyPageClassicButton.classic_button_name)
        }
    }, [data])

    // 로딩이미지가 너무 빨리 없어져서 조금 딜레이 시켜준다.
    useEffect(() => {
        if (!loading) {
            clearTimeout(loadingTimer)
            loadingTimer = setTimeout(() => {
                setNewLoading(loading)
            }, 700)
        } else setNewLoading(true)
    }, [loading])

    return (
        <TextField 
            InputProps={InputProps}
            inputProps={{ maxLength: getMaxlength('component.Survey.Create.Menu.ClassicNextButton.Input') }}
            size="small" 
            fullWidth 
            variant="outlined" 
            style={{ background: 'white', borderRadius: 3 }} 
            placeholder={f({id: `component.Survey.Live.Page.next`})} 
            value={newValue}
            onChange={handleChange}
        />
    )
}

export default memo(ClassicNextButtonComponent)