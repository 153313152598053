import { useContext } from 'react'
import styled from 'styled-components'
import oc from 'open-color-js'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons'
import { useIntl } from 'react-intl'
import PreviewContext from 'context/preview'
import Tooltip from 'styled/Custom/Tooltip'

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    height: 70px;
    align-items: center;
    z-index: 2;

    & > div {
        width: 100%;
        height: 70px;
        table-layout: fixed;
        position: fixed;
        z-index: 1;
        background: white;
        border-bottom: 1px dashed ${oc.gray3};
    }
    & > div > div {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
    & > div > div > div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    & > div > div > div:first-child {
        width: 50%;
        min-width: 300px;
        border-right: 1px dashed ${oc.gray3};
        
    }

    & > div > div > div:last-child {
        width: 50%;
        min-width: 500px;
        text-align: right;
        padding-right: 15px;
    }

    & > div > div > div:last-child > * + * {
        margin-left: 10px;
    }
    
`

const StyledInput = styled.input.attrs(props => ({
    type: 'text',
    value: props.value,
    maxLength: 50,
    placeholder: props.placeholder
}))`
    background: transparent;
    width: 100%;
    height: 50px;
    outline: 0;
    border: none;
    padding: 14px 17px 17px 20px;
    font-size: 17px;
    color: black;
    &::placeholder {
        color: #999;
        font-size: 17px;
    }
`

export default (props) => {
    const { step, children } = props
    const { formatMessage: f } = useIntl()

    const preview = useContext(PreviewContext)
    
    return (
        <WrapperStyled step={step}>
            <div>
                <div>
                    <div>
                        <StyledInput placeholder={f({id: 'component.Survey.Create.Toolbar.subject'})}/>
                    </div>
                    <div>
                        {children}
                        <ToggleButtonGroup exclusive onChange={preview.on.open}>
                            <Tooltip title={f({id: 'component.Survey.Create.Toolbar.preview'})}>
                            <ToggleButton edge="start" color="inherit" value='pc'>
                                <VisibilityOutlinedIcon/>
                            </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                    </div>
                   
                </div>
            </div>
        </WrapperStyled>
    )
}
