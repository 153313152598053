import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { numberFormat } from 'utils'
import styled from 'styled-components'
import { Typography, List, ListItemText, MobileStepper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { 
    Folder as FolderIcon, 
    KeyboardArrowLeft as KeyboardArrowLeftIcon, 
    KeyboardArrowRight as KeyboardArrowRightIcon,
    LooksOne as LooksOneIconm, 
    FiberManualRecord as FiberManualRecordIcon, 
    SignalCellular3Bar as SignalCellular3BarIcon
} from '@material-ui/icons'
import CreateBox from 'styled/Survey/CreateBox'
import { Title, ListItem, ListItemIcon } from 'styled/Survey/CreateExplainStyle'
import YoutubeStyled from 'styled/Survey/Live/Youtube'
import { maxCount } from '../IpBlocksInput'

const useStyles = makeStyles({
    button: {
        fontSize: '16px',
        whiteSpace: 'pre'
    }
})

const EndButtonImage = styled.img`
    display: block;
    max-width: 550px;
    @media screen and (max-width: 600px) {
        max-width: 100%;
    }
`

export default (props) => {    
    const { explainType } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const Stepper = ({ variant, disabled=false }) => (
        <MobileStepper 
            variant={variant}
            position="static" 
            style={{maxWidth: 500}}
            steps={6} 
            activeStep={3}
            nextButton={
                <Button size="small" className={classes.button}>
                    <Typography>{f({id: `component.Survey.Live.Page.next`})}</Typography>
                    <KeyboardArrowRightIcon/>
                </Button>
            }
            backButton={
                !disabled ? (
                    <Button size="small" className={classes.button}>
                        <KeyboardArrowLeftIcon/>
                        <Typography>{f({id: 'component.Survey.Live.Page.prev'})}</Typography>
                    </Button>
                ) : (
                    <Button size="small" disabled style={{background: 'transparent'}}>&nbsp;</Button>
                )
            }
        >
        </MobileStepper>
    )
    
    return (
        <CreateBox className="explain">
            {
                !explainType ? (
<>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.base.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText 
                                primary={f({id: 'component.Survey.Create.Publish.Preview.base.lists.0'})}
                                secondary={f({id: 'component.Survey.Create.Publish.Preview.base.sublists.0'})}
                            />
                        </ListItem>
                    </List> 
                    </>
                ) : explainType === 'lang' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.lang.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.lang.lists.0'})}/>
                        </ListItem>
                    </List>
                    </>
                ) : explainType === 'progress' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.progress.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.progress.lists.0'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><LooksOneIconm color="primary"/></ListItemIcon>
                            <ListItemText primary={<Stepper variant="text"/>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><SignalCellular3BarIcon color="primary"/></ListItemIcon>
                            <ListItemText primary={<Stepper variant="progress"/>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FiberManualRecordIcon color="primary"/></ListItemIcon>
                            <ListItemText primary={<Stepper variant="dots"/>}/>
                        </ListItem>
                    </List>
                    </>

                ) : explainType === 'prev_button' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.prev_button.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.prev_button.lists.0'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<Stepper variant="text" disabled={true}/>}/>
                        </ListItem>
                    </List>
                    </>

                ) : explainType === 'just' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.just.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.just.lists.0'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.just.lists.1'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.just.lists.2'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.just.lists.3'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.just.lists.4'})}/>
                        </ListItem>
                    </List>
                    <div style={{maxWidth: 900}}>
                        <YoutubeStyled src="https://www.youtube.com/watch?v=jKPou1EIl-s" autoplay={1} mute={0} control={1} screen="wide"/>
                    </div>
                    </>

                )  : explainType === 'alarm' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.alarm.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.alarm.lists.0'})}/>
                        </ListItem>
                    </List>
                    </>

                )  : explainType === 'ip_blocks' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.ip_blocks.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.ip_blocks.lists.0'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.ip_blocks.lists.1'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.ip_blocks.lists.2'}, { count: numberFormat(maxCount) })}/>
                        </ListItem>
                    </List>
                    </>

                ) : explainType === 'end_button' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.end_button.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.end_button.lists.0'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.end_button.lists.1'})}/>
                        </ListItem>
                    </List>
 
                    <EndButtonImage src={`${process.env.REACT_APP_IMG}/survey-web/component/Survey/Create/Publish/Preview/DeskTop/end_button.png`} alt="end_button"/>
                    </>

                ) : explainType === 'allow_ips' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.allow_ips.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.allow_ips.lists.0'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={<Typography color="secondary">{f({id: 'component.Survey.Create.Publish.Preview.allow_ips.lists.1'})}</Typography>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={parseHtmlIntl(f({id: 'component.Survey.Create.Publish.Preview.allow_ips.lists.2'}))}/>
                        </ListItem>
                    </List>
                    </>

                ) : explainType === 'end_date_used' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.end_date.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.end_date.lists.0'})}/>
                        </ListItem>
                    </List>
                    </>

                ) : explainType === 'auth_inputs_used' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.auth_inputs.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.auth_inputs.lists.0'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.auth_inputs.lists.1'})}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.auth_inputs.lists.2'})}/>
                        </ListItem>
                    </List>
                    </>
                ) : explainType === 'open' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.open.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.open.lists.0'})}/>
                        </ListItem>
                    </List>
                    </>
                ) : explainType === 'cut_people' ? (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.cut_people.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText 
                                primary={f({id: 'component.Survey.Create.Publish.Preview.cut_people.lists.0'})}
                                secondary={parseHtmlIntl(f({id: 'component.Survey.Create.Publish.Preview.cut_people.sublists.0'}))}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText 
                                primary={<Typography color="secondary">{f({id: 'component.Survey.Create.Publish.Preview.cut_people.lists.1'})}</Typography>}
                            />
                        </ListItem>
                    </List>
                    </>
                ) : explainType === 'first_question' && (
                    <>
                    <Title>{f({id: 'component.Survey.Create.Publish.Preview.first_question.title'})}</Title>
                    <List >
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText
                                primary={f({id: 'component.Survey.Create.Publish.Preview.first_question.lists.0'})} 
                                secondary={f({id: 'component.Survey.Create.Publish.Preview.first_question.sublists.0'})}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText
                                primary={f({id: 'component.Survey.Create.Publish.Preview.first_question.lists.1'})} 
                                secondary={f({id: 'component.Survey.Create.Publish.Preview.first_question.sublists.1'})}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText
                                primary={f({id: 'component.Survey.Create.Publish.Preview.first_question.lists.2'})} 
                                secondary={f({id: 'component.Survey.Create.Publish.Preview.first_question.sublists.2'})}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary={f({id: 'component.Survey.Create.Publish.Preview.first_question.lists.3'})}/>
                        </ListItem>
                    </List>
                    </>
                ) 
            }
        </CreateBox>
    )
}