import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import { DELETE_SURVEY_PAGE } from 'gql/survey_page'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORORPAGESHOW } from 'reducer/errorPage'
import oc from 'open-color'
import Typography from "@material-ui/core/Typography"
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

interface Props {
    index: number
    survey_page_no: number
    firstPageNo: number
    onClose: () => void
    refetchQuestions: () => void
    refetchPages: () => void
}

const MyBox = withStyles({
    root: {
        padding: '20px 10px 10px 10px',
        marginBottom: 40,
        background: oc.gray[0],
        '& > div': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: 5
        }
    }
})(Box)

function PageRemoveComponent(props: Props) {
    const { index, survey_page_no, firstPageNo, onClose, refetchQuestions, refetchPages } = props

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const history = useHistory()
    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ deleteSurveyPage ] = useMutation(DELETE_SURVEY_PAGE, {
        onCompleted: async() => {
            history.push(`/survey/form/create/${survey_no}#page=${firstPageNo}`)
            refetchPages()
            refetchQuestions()
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORORPAGESHOW })
        }
    })

    function handleRemove() {
        dispatch({ type: BACKDROPSHOW })

        deleteSurveyPage({
            variables: {
                survey_no,
                survey_page_no
            }
        })
    }

    return (
        <MyBox>
            <Typography variant="body2">{parseHtmlIntl(f({id : 'component.Survey.Create.Pages.Remove.title'}, { index }))}</Typography>
            <div>
                <Button color="secondary" onClick={handleRemove}>{f({id : 'component.Survey.Create.Pages.Remove.button.remove'})}</Button>
                <Button onClick={onClose}>{f({id : 'component.Survey.Create.Pages.Remove.button.close'})}</Button>
            </div>    
        </MyBox>
    )    
}

export default PageRemoveComponent