import { Container, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import oc from 'open-color-js'

const MyGrid= withStyles(theme => ({
    root: {
        background: oc.gray0,
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: 'initial'
        }
      
    }
}))(props => <Grid container {...props}/>)

const Component = (props) => {
    const { children } = props

    return (
        <MyGrid>
            <Grid item xs={12} sm={12}>
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        {children}
                    </Grid>
                </Container>
            </Grid>
        </MyGrid>
    )
}

export default Component