import { SurveyModulesStartComponent, SurveyCreateThanksPreviewComponent } from 'component'

export default ({ rows, isChange }) => {

    return (
        <SurveyCreateThanksPreviewComponent {...rows} isChange={isChange}>
            <SurveyModulesStartComponent row={rows}/>
        </SurveyCreateThanksPreviewComponent>        
    )
}
