import { useState, useCallback, createContext } from 'react'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { SurveyPageProps } from 'gql/survey_page'
import { scrollerObj as _scrollerObj } from 'utils/survey'
import CreateBox from 'styled/Survey/CreateBox'
import ItemsComponent from 'component/Survey/Create/Logic/Jump/Lists/Items'
import ButtonBoxComponent from 'component/Survey/Create/Logic/Jump/Lists/ButtonBox'

export const scrollerObj = _scrollerObj()

const scrolls = {
    id: scrollerObj.id,
    to: '',
    botoom: ''
}

type SurveyQuestionModulePropsInKey = { [a: number]: SurveyQuestionModuleProps[] } | undefined

interface ItemContextProps {
    questions: SurveyQuestionModuleProps[];
    questionsKeyPageNo: SurveyQuestionModulePropsInKey;
    questionsKeyQuestionNo: SurveyQuestionModulePropsInKey;
    pages: SurveyPageProps[];
}

export const ItemsContext = createContext<ItemContextProps>({
    questions: [],
    questionsKeyPageNo: undefined,
    questionsKeyQuestionNo: undefined,
    pages: []
})

export type SelectedPage = '' | number

export type OnChangeSelectedPage = (page: SelectedPage) => void

function JumpListsComponent() {
    const [ selectedPage, setSelectedPage ] = useState<SelectedPage>('')

    const handleChangeSelectedPage = useCallback((page: SelectedPage) => {
        setSelectedPage(page)
    }, [])

    return (
        <CreateBox scrolls={scrolls} className="jumpList">
            <ButtonBoxComponent selectedPage={selectedPage} onChangeSelectedPage={handleChangeSelectedPage}/>
            <ItemsComponent selectedPage={selectedPage}/>
        </CreateBox>
    )
}

export default JumpListsComponent
