import { useContext } from 'react'
import { FormControlLabel, Box, IconButton, Typography, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SurveyEditCommentStyled } from 'styled/Input'
import PropsContext from 'context/props'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import oc from 'open-color'
import RatingIcon from 'styled/Survey/RatingIcon'

const AgreeSwitchLabel = withStyles({
    root: {
        marginTop: 15, 
        marginLeft: 2, 
        marginBottom: 5
    }
})(FormControlLabel)

const AgreeIconBox = withStyles({
    root: {
        display: 'block',
        borderTop: `1px solid ${oc.gray[3]}`,
        borderBottom: `1px solid ${oc.gray[3]}`,
        background: `${oc.gray[3]}`,
        borderRadius: 5,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 15,
            borderRadius: 3,
            
        }
    }
})(Box)

export default () => {
    const { rows, refButtonName, on, onEdit } = useContext(PropsContext)
    const { button_name, agree, agree_icon_type } = rows
    const { formatMessage: f } = useIntl()

    if (typeof rows.__typename !== 'string') return null
    
    return (
        <>
        <SurveyEditCommentStyled 
            placeholder={f({id: 'component.Survey.Create.Thanks.Edit.ButtonStart.button_name'})} 
            helper={f({id: 'component.Survey.Create.Thanks.Edit.ButtonStart.button_name_helper'})}
            defaultValue={button_name} 
            inputRef={refButtonName} 
            inputProps={{maxLength: getMaxlength('component.Survey.Create.Thanks.Edit.ButtonStart.button_name')}}
            name="button_name"
            onChange={onEdit.changeButton}
        />
        <AgreeSwitchLabel 
            control={<Switch checked={agree} onChange={on.changeAgree} color="primary" size="small"/>}
            label={<Typography variant="body2">{f({id: 'component.Survey.Create.Thanks.Edit.ButtonStart.agree.label'})}</Typography>}
        />
        {
            agree && (
                <AgreeIconBox>
                    <div>
                        <IconButton 
                            onClick={() => on.changeAgreeIconType('assign')} 
                            color={agree_icon_type === 'assign' ? 'primary' : 'default'}
                        >
                            <RatingIcon selected={agree_icon_type === 'assign'} icon_type='assign'/>
                        </IconButton>
                        <IconButton   
                            onClick={() => on.changeAgreeIconType('notifi')} 
                            color={agree_icon_type === 'notifi' ? 'primary' : 'default'}
                        >
                            <RatingIcon selected={agree_icon_type === 'notifi'} icon_type='notifi'/>
                        </IconButton>
                        <IconButton  
                            onClick={() => on.changeAgreeIconType('emoji')} 
                            color={agree_icon_type === 'emoji' ? 'primary' : 'default'}
                        >
                            <RatingIcon selected={agree_icon_type === 'emoji'} icon_type='emoji'/>
                        </IconButton>
                        <IconButton   
                            onClick={() => on.changeAgreeIconType('thumbup')} 
                            color={agree_icon_type === 'thumbup' ? 'primary' : 'default'}
                        >
                            <RatingIcon selected={agree_icon_type === 'thumbup'} icon_type='thumbup'/>
                        </IconButton>
                        <IconButton  
                            onClick={() => on.changeAgreeIconType('cloudDone')} 
                            color={agree_icon_type === 'cloudDone' ? 'primary' : 'default'}
                        >
                            <RatingIcon selected={agree_icon_type === 'cloudDone'} icon_type='cloudDone'/>
                        </IconButton>
                    </div>
                </AgreeIconBox>
            )
        }
     
        </>
    )
}   