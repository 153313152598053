import { memo, useState, useEffect, ChangeEvent } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { UPDATE_SURVEY_ENDING_LABEL } from 'gql/survey_ending'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { OnClose } from './EndingsItemMore'
import { getMaxlength } from 'policy'

interface Props {
    survey_ending_no: number;
    label: string;
    onClose: OnClose;
    refetchEndings: () => void
}

const MyBox = withStyles({
    root: {
        display: 'flex', 
        alignItems: 'flex-end',
        padding: 10,
        '& > * + *': {
            marginLeft: 5
        }
    }
})(Box)

function EndingsLabelComponent(props: Props) {
    const { survey_ending_no, label, refetchEndings, onClose } = props

    const { formatMessage: f } = useIntl()
    const dispatch = useDispatch()

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    const [ _label, setLabel ] = useState<string>(label)

    const [ updateSurveyEndingLabel ] = useMutation(UPDATE_SURVEY_ENDING_LABEL, {
        onCompleted: () => {
            refetchEndings()
            dispatch({ type: BACKDROPHIDE })
        },
        onError: () => {
            dispatch({ type: BACKDROPHIDE })
            dispatch({ type: ERRORPAGESHOW })
        }
    })

    function handleClick() {
        dispatch({ type: BACKDROPSHOW })
        updateSurveyEndingLabel({ 
            variables: {
                survey_no,
                survey_ending_no,
                label: _label
            }
        })
        onClose()
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setLabel(e.target.value)
    }

    useEffect(() => {
        if (survey_ending_no) {
            setLabel(label)
        }
    }, [survey_ending_no, label])

    return (
        <MyBox>
            <TextField 
                value={_label} 
                label={f({id: 'component.Survey.Create.Menu.EndingsItemLabel.label'})}
                onChange={handleChange} 
                inputProps={{ maxLength: getMaxlength('component.Survey.Create.Menu.EndingsItemLabel') }}
            />
            <Button variant="contained" disableElevation disabled={label === _label} onClick={handleClick}>
                {f({id: 'component.Survey.Create.Menu.EndingsItemLabel.save'})}
            </Button>
        </MyBox>
    )
   
}

export default memo(EndingsLabelComponent)