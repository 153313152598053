import { memo } from "react"
import { useIntl } from 'react-intl'
import { SurveyQuestionModuleProps, ModuleRank } from 'gql/survey_question_modules'
import { JumpNo } from 'gql/jump'
import { MySelect, MyTypography, OnChangeChildsProps } from './ItemRules'
import { MyBox } from './SelectMatrixModule'
import SelectRankAnswerComponent from './SelectRankAnswer'
import { MyMenuItem } from './SelectModule'

interface Props extends OnChangeChildsProps {
    question: SurveyQuestionModuleProps;
    typename: string;
    jump_rule_child_no: JumpNo;
    survey_module_nos: number[];
    survey_module_answer_no: number;
}

function SelectRankModuleComponent(props: Props) {
    const { question, jump_rule_child_no, survey_module_nos, onChange } = props

    const { formatMessage: f } = useIntl()

    const { _modules } = question

    const survey_module_no = survey_module_nos.length > 0 ? Number(survey_module_nos[0]) : 0

    return (
        <MyBox>
            <MySelect
                error={survey_module_no === 0}
                displayEmpty 
                value={survey_module_no || ''} 
                style={{display: 'flex'}}
                label={f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectRankModule.label'})}
                onChange={(e) => {
                    onChange('survey_module_nos', jump_rule_child_no, [ Number(e.target.value) ])
                }}
            >
                {
                    _modules.map((c: ModuleRank) => {
                        const { indexs, survey_module_no } = c
                        const label = `${indexs} ${f({id: 'component.Survey.Create.Logic.Jump.Lists.SelectRankModule.rank'})}`

                        return (
                            <MyMenuItem key={survey_module_no} value={survey_module_no}>
                                <MyTypography>{label}</MyTypography>
                            </MyMenuItem>
                        )
                    })
                }
            </MySelect>
            <SelectRankAnswerComponent {...props}/>
        </MyBox>
    )
}

export default memo(SelectRankModuleComponent)