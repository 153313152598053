import { memo } from 'react'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import { OnCreateProps } from './ItemRules'

function ButtonCreateItemComponent(props: OnCreateProps) {
    const { onCreate } = props

    const { formatMessage: f } = useIntl()

    return <Button color="primary" size="small" onClick={onCreate}>{f({id: 'component.Survey.Create.Logic.Jump.Lists.ButtonCreateItem.button.add'})}</Button>
}

export default memo(ButtonCreateItemComponent)