import { memo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ColumnValueComponent from './ColumnValue'
import { Props as ParentProps } from './Body'
import { useStyles as useTextItemStyles } from './TextItem'

interface Props {
    survey_question_no: number;
    answer: ParentProps['answer'];
}

export const useStyles = makeStyles(theme => ({
    commentBox: {
        paddingLeft: 50,
        paddingRight: 10,
        marginTop: 8
    },
    comment: {
        borderTop: `1px dashed ${theme.palette.grey[400]}`,
        paddingTop: 8
    }
}))

function ChoiceItemsComponent(props: Props) {
    const { survey_question_no, answer } = props

    const classes = useStyles()
    const classesTextItem = useTextItemStyles({})

    const value = answer[`Q${survey_question_no}`]

    if (!value) return null

    const { choice, comment } = value

    const commentValue = comment[survey_question_no] || ''
    
    return (
        <>
        <div className={classesTextItem.root}>
            {
                choice.filter(c => c[0] !== '').map((c, i) => {
                    const [ _, text ] = c
                  
                    return <ColumnValueComponent key={`${survey_question_no}-${i}`} text={text} />
                })
            }
        </div>
        {
            commentValue && <div className={classes.commentBox}><div className={classes.comment}><ColumnValueComponent text={commentValue} /></div></div>
        }
        </>
    )
}

export default memo(ChoiceItemsComponent)
