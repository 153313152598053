import { memo } from 'react'
import oc from 'open-color'
import { Divider, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MyList } from '../Lists/ButtonBox'
import HelpComponent from './Help'
import InputInitComponent from './InputInit'
import ButtonSaveComponent from './ButtonSave'

export const ContainerBox = withStyles({
    root: {
        backgroundColor: oc.gray[5], 
        position: 'sticky', 
        bottom: 0, left:0, 
        right: 0, 
        zIndex: 1
    }
})(Box)

const MyBox = withStyles({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '2px 5px',
        justifyContent: 'space-between',
        '& > * + *': {
            marginLeft: 5
        },
        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            '& > * + *': {
                marginLeft: 20
            }
        },
        '& > div:last-child': {
            display: 'flex',
            alignItems: 'center',
            '& > * + *': {
                marginLeft: 20
            }
        }
    }   
    
})(Box)

function ButtonBoxComponent() {
    return (
        <ContainerBox>
            <MyList dense disablePadding>
                <MyBox style={{alignItems: 'flex-end'}}>
                    <div>
                        <div>
                            <HelpComponent/>
                        </div>
                    </div>     
                    <div>
                        <InputInitComponent/>
                        <ButtonSaveComponent/>
                    </div>
                </MyBox>
            </MyList>
            <Divider/>
        </ContainerBox>
    )
}

export default memo(ButtonBoxComponent)