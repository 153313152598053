import { useState, useCallback, useEffect, useContext, memo } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { useMutation } from '@apollo/client'
import { CREATE_JUMP_FLOW, JumpFlowProps } from 'gql/jump_flow'
import { ReactFlowProvider } from 'react-flow-renderer'
import { withSurveyFetchData } from 'hoc'
import { ListsContext } from 'container/Survey/Create/Logic/Jump/ListsContext'
import Component from './Component'

interface Props {
    propsFetchData: {
        jumpFlow: JumpFlowProps;
        refetchJumpFlow: () => void;
    }
}

let timer: ReturnType<typeof setTimeout> 

function Provider(props: Props) {
    const { propsFetchData } = props
    const { jumpFlow, refetchJumpFlow } = propsFetchData

    const { jump } = useContext(ListsContext)

    const params = useParams<{survey_no: string}>()
    const survey_no = Number(params.survey_no)

    const eqaulState = useSelector((state: RootState) => state.surveyCreateLogicJumpEqual)

    const [ rfInstance, setRfInstance ] = useState<any>(null)

    const [ createJumpFlow ] = useMutation(CREATE_JUMP_FLOW)

    // 해당 플로우 모양 저장
    const save = useCallback((instance: any, is: boolean) => {
        clearTimeout(timer)

        timer = setTimeout(() => {
            if (instance && is) {
                const flow = instance.toObject()
    
                createJumpFlow({ 
                    variables: {
                        survey_no,
                        instance: flow
                    }
                })
            }
        }, 300)
    }, [survey_no, createJumpFlow])

    // 플로우 로드시 인스턴스값을 가져온다
    const handleChangeInstance = useCallback((reactFlowInstance) => {
        setRfInstance(reactFlowInstance)
    }, [])

    // 노드 움직일시 해당 플로우저장 (오리진값과 state값이 동일할때만 저장한다.)
    const handleNodeDragStop = useCallback(() => {
        save(rfInstance, eqaulState.is)
    }, [rfInstance, eqaulState, save])


    // 값 저장시 해당 플로우 모양도 저장한다
    useEffect(() => {
        save(rfInstance, eqaulState.is)
    }, [rfInstance, eqaulState.is, save])
    

    // jump값이 변경되면, flow값을 다시 리패치 한다.
    useEffect(() => {
        if (refetchJumpFlow) {
           refetchJumpFlow()
        }
    }, [jump, refetchJumpFlow])

    const instance = jumpFlow ? jumpFlow.instance : null

    return (
        <ReactFlowProvider>
            <Component instance={instance} onChangeInstance={handleChangeInstance} onNodeDragStop={handleNodeDragStop}/>
        </ReactFlowProvider>
    )
}

export default withSurveyFetchData('')({jumpFlow: true})(false)(memo(Provider))